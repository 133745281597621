export enum ProjectFileType {
  // Summary:
  //     Unknown file.
  Unknown = 0,

  // Summary:
  //     Sdl xliff file
  SdlXliff = 1,

  // Summary:
  //     Memoq xliff file.
  MemoqXliff = 2,

  // Summary:
  //     Memsource xliff file.
  MemsourceXliff = 3,

  // Summary:
  //     Xliff 2.0 file.
  Xliff20 = 4,

  // Summary:
  //     Xliff 1.0, 1.1 or 1.2 file.
  Xliff = 5,
}
