import { ReportExportModel, ReportModel } from '@generated/api';
import { ReportReturnMode } from '@shared/models';

export enum ActionTypes {
  LOAD_REPORT = '[Report] Load Report',
  LOAD_REPORT_SUCCESS = '[Report] Load Report Success',
  LOAD_REPORT_FAIL = '[Report] Load Report Failuer',
  GENERATE_REPORT = '[Report] Generate Report',
  CREATE_REPORT = '[Report] Create Report',
  CREATE_REPORT_SUCCESS = '[Report] Create Report Success',
  CREATE_REPORT_FAILURE = '[Report] Create Report Failure',
  CREATE_REPORT_LINK = '[Report] Create Report Link',
  EXPORT_REPORT = '[Report] Download Report',
  DOWNLOAD_REPORT_EXPORTED_FILE = '[Report] Download Report Exported File',
  RESET_REPORT_STATE = '[Report] Reset State',
}

export class LoadReport {
  static readonly type = ActionTypes.LOAD_REPORT;
  constructor(public projectId: string) {}
}

export class LoadReportSuccess {
  static readonly type = ActionTypes.LOAD_REPORT_SUCCESS;
  constructor(public report: ReportModel) {}
}

export class LoadReportFail {
  static readonly type = ActionTypes.LOAD_REPORT_FAIL;
  constructor(public error: any) {}
}

export class GenerateReport {
  static readonly type = ActionTypes.GENERATE_REPORT;
  constructor(public id: string, public returnMode: ReportReturnMode = ReportReturnMode.Default) {}
}

export class CreateReport {
  static readonly type = ActionTypes.CREATE_REPORT;
  constructor(public projectId: string) {}
}

export class CreateReportSuccess {
  static readonly type = ActionTypes.CREATE_REPORT_SUCCESS;
  constructor() {}
}

export class CreateReportFail {
  static readonly type = ActionTypes.CREATE_REPORT_FAILURE;
  constructor(public error: any) {}
}

export class CreateReportLink {
  static readonly type = ActionTypes.CREATE_REPORT_LINK;
  constructor(public id: string) {}
}

export class ExportReport {
  static readonly type = ActionTypes.EXPORT_REPORT;
  constructor(public exportModel?: ReportExportModel) {}
}

export class DownloadReportExportedFile {
  static readonly type = ActionTypes.DOWNLOAD_REPORT_EXPORTED_FILE;
  constructor() {}
}

export class ResetReportState {
  static readonly type = ActionTypes.RESET_REPORT_STATE;
}
