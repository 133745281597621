import { Injectable } from '@angular/core';
import { LPAuthModalService } from '@shared/components/localization-platforms/lp-auth/lp-auth-modal.service';
import { LPChooseFilesModalService } from '@shared/components/localization-platforms/lp-choose-files/lp-choose-files-modal.service';

@Injectable({
  providedIn: 'root',
})
export class LPComponentsService {
  constructor(
    private lpAuthModalService: LPAuthModalService,
    private lpChooseFilesModalService: LPChooseFilesModalService
  ) {}

  openAuthModal(platformId: string) {
    return this.lpAuthModalService.openAuthModal(platformId);
  }

  openChooseFilesModal(platformId: string, connectionId: string) {
    this.lpChooseFilesModalService.openChooseFilesModal(
      platformId,
      connectionId
    );
  }
}
