import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ShortcutsService } from 'src/app/core/services';
import { GroupedShortcutsReference } from 'src/app/core/services/shortcuts';

@Component({
  selector: 'app-shortcuts-reference',
  templateUrl: './shortcuts-reference.component.html',
  styleUrls: ['./shortcuts-reference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutsReferenceComponent implements OnInit {
  public groupedShortcutsReference: GroupedShortcutsReference;

  constructor(private readonly shortcitsService: ShortcutsService) {}

  ngOnInit(): void {
    this.initShortcutsMap();
  }

  public asInOrder(): number {
    return 1;
  }

  private initShortcutsMap(): void {
    this.groupedShortcutsReference = this.shortcitsService.getGroupedShortcutsReference();
  }
}
