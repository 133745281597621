import { AgGridColumn, GridColumnParams } from '../../../../types/ag-grid';
import { TargetLanguageCellRendererComponent } from '@report/components/report-grid/components';
import { TranslationUnitModel } from '@generated/api';

type ColumnDepentOnTranslationUnit = GridColumnParams<{
  translationUnit: TranslationUnitModel;
}>;

export const TARGET_LANGUAGE_GROUP_COLUMN: Partial<AgGridColumn> = {
  headerName: 'Target language',
  colId: 'targetLanguage',
  field: 'targetLanguage',
  filter: true,
  lockPinned: true,
  lockPosition: 'left',
  flex: 0,
  minWidth: 100,
  width: 172,
  suppressColumnsToolPanel: false,
  showRowGroup: false,
  rowGroupIndex: 2,
  cellRenderer: TargetLanguageCellRendererComponent.rendererName,
  sortable: true,
  valueGetter: (params: ColumnDepentOnTranslationUnit) => params.data?.translationUnit.projectFile.targetLanguage.name,
  rowGroup: true,
};
