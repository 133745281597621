import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DeleteLocalizationPlatformConnection,
  LocalizationPlatformFilesIsAttached,
  LocalizationPlatformsState,
  SetLocalizationPlatformConnection,
  SetLocalizationPlatformProjectFiles,
  SetLocalizationPlatformProjects,
} from '@store/localization-platforms-store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LPChooseFilesComponent } from '@shared/components/localization-platforms/lp-choose-files/lp-choose-files.component';
import { LocalizationPlatformsProject } from '@shared/models';
import { LPChooseFilesComponentParams } from '@shared/components/localization-platforms/models';
import { lpAssets } from '@shared/components/localization-platforms/constants/lp-assets';
import { LPAuthModalService } from '@shared/components/localization-platforms/lp-auth/lp-auth-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectState, RenameProject } from '@store/project-store';
import { LocalizationPlatformFileModel, LocalizationPlatformsService, ProjectFileInfo } from '@generated/api';
import { isAuthenticationError } from '@shared/tools';
import { LoadGlossaryList, LoadProjectFiles, ProjectFilesState, SetProjectFiles } from '@store';

@Injectable({
  providedIn: 'root',
})
export class LPChooseFilesModalService {
  private modalRef: MatDialogRef<LPChooseFilesComponent>;
  private projectsOrFilesLoading = new BehaviorSubject(false);

  constructor(
    // TODO перенести в стейт
    private localizationPlatformsService: LocalizationPlatformsService,
    private lpAuthModalService: LPAuthModalService,
    private store: Store,
    private dialog: MatDialog
  ) {}

  openChooseFilesModal(platformId: string, connectionId: string) {
    this.projectsOrFilesLoading.next(false);
    this.loadProjects(platformId, connectionId);
    const projects$ = this.store.select(LocalizationPlatformsState.projectsByConnection(connectionId));
    const projectId = new BehaviorSubject<string>(null);
    const files$ = projectId.pipe(
      switchMap((id) => {
        if (id) {
          return this.store.select(LocalizationPlatformsState.filesByProject(id));
        }
        return of([]);
      })
    );
    const projectSelectedCallback = (project: LocalizationPlatformsProject | null) => {
      if (project) {
        projectId.next(project.id);
        this.loadFiles(platformId, connectionId, project.id);
      } else {
        projectId.next(null);
      }
    };
    const filesSelectedCallback = (project: LocalizationPlatformsProject, files: LocalizationPlatformFileModel[]) => {
      this.renameProjectIfHasDefaultName(project.name);
      this.modalRef.close();
      this.attachFiles(platformId, connectionId, files);
    };
    const params: LPChooseFilesComponentParams = {
      projects: this.convertObservableToBehaviorSubject(projects$, []),
      files: this.convertObservableToBehaviorSubject(files$, []),
      platformAssets: lpAssets[platformId],
      logoutCallback: () => this.logout(platformId),
      filesSelectedCallback,
      projectSelectedCallback,
      loading: this.projectsOrFilesLoading,
    };
    this.modalRef = this.dialog.open(LPChooseFilesComponent, {
      data: params,
    });
  }

  private loadProjects(platformId: string, connectionId: string) {
    this.projectsOrFilesLoading.next(true);
    this.localizationPlatformsService
      .apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json({ connectionId })
      .subscribe({
        next: (projects) => {
          this.projectsOrFilesLoading.next(false);
          this.store.dispatch(new SetLocalizationPlatformProjects(connectionId, projects));
        },
        error: (error: HttpErrorResponse) => this.httpErrorHandler(platformId, error),
      });
  }

  private loadFiles(platformId: string, connectionId: string, projectId: string) {
    this.projectsOrFilesLoading.next(true);
    this.localizationPlatformsService
      .apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json({
        connectionId,
        projectId,
      })
      .subscribe({
        next: (files) => {
          this.store.dispatch(new SetLocalizationPlatformProjectFiles(projectId, files));
          this.projectsOrFilesLoading.next(false);
        },
        error: (error: HttpErrorResponse) => this.httpErrorHandler(platformId, error),
      });
  }

  private httpErrorHandler(platformId: string, error: HttpErrorResponse) {
    this.projectsOrFilesLoading.next(false);
    if (isAuthenticationError(error)) {
      this.modalRef.close();
      this.store.dispatch(new DeleteLocalizationPlatformConnection(platformId));
      this.openAuthModal(platformId);
    }
  }

  private logout(platformId: string) {
    this.modalRef.close();
    this.store.dispatch(new DeleteLocalizationPlatformConnection(platformId));
  }

  private renameProjectIfHasDefaultName(platformProjectName: string) {
    if (!platformProjectName) {
      return;
    }
    const project = this.store.selectSnapshot(ProjectState.project);
    this.store.dispatch(new RenameProject(project.id, platformProjectName));
  }

  private convertObservableToBehaviorSubject<T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> {
    const subject = new BehaviorSubject(initValue);

    observable.subscribe({
      complete: () => subject.complete(),
      error: (x) => subject.error(x),
      next: (x) => subject.next(x),
    });

    return subject;
  }

  openAuthModal(platformId: string) {
    this.lpAuthModalService.openAuthModal(platformId).subscribe((newConnectionId) => {
      if (!newConnectionId) {
        return;
      }
      this.store.dispatch(new SetLocalizationPlatformConnection(platformId, newConnectionId));
      this.openChooseFilesModal(platformId, newConnectionId);
    });
  }

  private attachFiles(platformId: string, connectionId: string, files: LocalizationPlatformFileModel[]) {
    const beforeProjectsFiles = this.store.selectSnapshot(ProjectFilesState.projectFiles);
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const list: ProjectFileInfo[] = files.map((file) => ({
      name: file.name,
      id: file.id,
      isProtected: false,
      localizationPlatform: file.localizationPlatform,
      languagePair: null,
    }));
    this.store.dispatch(new SetProjectFiles(projectId, [...beforeProjectsFiles, ...list]));
    this.store.dispatch(new LocalizationPlatformFilesIsAttached(true));
    const filesIds = files.map((f) => f.id);
    const project = this.store.selectSnapshot(ProjectState.project);
    this.localizationPlatformsService
      .apiLocalizationPlatformsFilesAttachtoprojectPost({
        body: { connectionId, projectId: project.id, files: filesIds },
      })
      .subscribe({
        next: () => {
          this.store.dispatch(new LocalizationPlatformFilesIsAttached(false));
          this.store.dispatch(new LoadProjectFiles(project.id));
          this.store.dispatch(new LoadGlossaryList(project.qaSettingsId));
        },
        error: (error: HttpErrorResponse) => {
          this.store.dispatch(new LocalizationPlatformFilesIsAttached(false));
          this.store.dispatch(new SetProjectFiles(projectId, beforeProjectsFiles));
          this.httpErrorHandler(platformId, error);
        },
      });
  }
}
