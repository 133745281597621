import { SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { AgGridColumn } from 'src/types/ag-grid';
import { GridHeaderComponent } from '@shared/components/grid-components';

const FILTER_PARAMS = {
  cellHeight: 24,
  buttons: ['reset'],
  closeOnApply: true,
  newRowsAction: 'keep',
};

export const REPORT_DEFAULT_COLUMNS_DEF: Partial<AgGridColumn> = {
  flex: 1,
  minWidth: 80,
  sortable: true,
  resizable: true,
  lockPinned: true,
  filter: 'agSetColumnFilter',
  filterParams: FILTER_PARAMS,
  suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
    params.event.preventDefault();
    params.event.stopPropagation();
    return true;
  },
  headerComponent: GridHeaderComponent.rendererName,
};
