import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { DigitsToTextComponent } from '@shared/components/digit-to-text/digit-to-text.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { GridComponentsModule } from '@shared/components/grid-components/grid-components.module';
import { ModalModule } from '@shared/components/modal';
import { DirectivesModule } from '@shared/directives';
import { TooltipModule } from '@shared/components/tooltip';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridComponentsModule,
    AgGridModule,
    SearchInputModule,
    ModalModule,
    DirectivesModule,
    TooltipModule,
    ActionIconModule,
  ],
  declarations: [DigitsToTextComponent],
  exports: [DigitsToTextComponent],
})
export class DigitToTextModule {}
