import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { ProjectInfo } from '@generated/api';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { Account } from '@shared/models';
import { OrganizationsState } from '@store/organizations-store/organizations.state';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { AuthState } from '..';
import { PinProject } from './projects-pin.actions';

interface StateModel {
  [orgId: string]: { [key: string]: Array<ProjectInfo & { isPinned: true }> };
}

const defaultOrganizationStoreName = 'personal';

@AsyncStorage
@StateRepository()
@State<StateModel>({
  name: 'pinnedProjects',
  defaults: {},
})
@Injectable()
export class PinnedProjectsState {
  // TODO: временная привязка к аккаунту.
  // Заменить после того как появится хранение закрепленных проектов на сервере.
  constructor(private store: Store) {}

  @Selector([PinnedProjectsState, AuthState.account, OrganizationsState.joinedOrganizationId])
  public static projectsPins(
    state: StateModel,
    account: Account,
    orgId: string = defaultOrganizationStoreName
  ): Array<ProjectInfo & { isPinned: true }> {
    return state[orgId ?? defaultOrganizationStoreName]?.[account.id] ?? [];
  }

  @Selector([PinnedProjectsState, AuthState.account, OrganizationsState.joinedOrganizationId])
  public static projectsPinsIds(
    state: StateModel,
    account: Account,
    orgId: string = defaultOrganizationStoreName
  ): string[] {
    return state[orgId]?.[account.id]?.map((project) => project.id);
  }

  @Action(PinProject)
  public pinProject(ctx: StateContext<StateModel>, action: PinProject): void {
    const accountId = this.store.selectSnapshot(AuthState.account)?.id;
    const orgId = this.store.selectSnapshot(OrganizationsState.joinedOrganizationId) ?? defaultOrganizationStoreName;

    const org = ctx.getState()[orgId];
    if (!org) {
      ctx.setState(
        patch({
          [orgId]: {
            [accountId]: [],
          },
        })
      );
    }

    const patchFn = action.pinStatus
      ? append([{ ...action.project, isPinned: true }])
      : removeItem<ProjectInfo>((project) => project.id === action.project.id);

    ctx.setState(
      patch({
        [orgId]: patch({
          [accountId]: patchFn,
        }),
      })
    );
  }
}
