import { VALID_HORIZ_ALIGN, VALID_SCROLL, VALID_VERT_ALIGN } from './types';

export function getUnanchoredPopoverError(): Error {
  return Error('Popover does not have an anchor.');
}

export function getInvalidPopoverAnchorTypeError(): Error {
  return Error(
    'Popover#anchor must be an instance of PopoverAnchor, ElementRef, or HTMLElement.'
  );
}

export function getInvalidPopoverError(): Error {
  return Error('PopoverAnchor#popoverAnchor must be an instance of Popover.');
}

export function getInvalidPopoverAnchorError(): Error {
  return Error(
    'PopoverAnchor must be associated with a ' +
      'Popover component. ' +
      'Examples: <app-popover [anchor]="popoverAnchorTemplateRef"> or ' +
      '<button [appPopoverAnchor]="popoverTemplateRef">'
  );
}

export function getInvalidHorizontalAlignError(alignment): Error {
  return Error(
    generateGenericError('horizontalAlign/xAlign', alignment, VALID_HORIZ_ALIGN)
  );
}

export function getInvalidVerticalAlignError(alignment): Error {
  return Error(
    generateGenericError('verticalAlign/yAlign', alignment, VALID_VERT_ALIGN)
  );
}

export function getInvalidScrollStrategyError(strategy): Error {
  return Error(generateGenericError('scrollStrategy', strategy, VALID_SCROLL));
}

function generateGenericError(
  apiName: string,
  invalid: any,
  valid: string[]
): string {
  return `Invalid ${apiName}: '${invalid}'. Valid options are ${valid
    .map((v) => `'${v}'`)
    .join(', ')}.`;
}
