import { Injector, Pipe, PipeTransform, ProviderToken } from '@angular/core';

export type OmitFirstArg<T extends unknown[]> = T extends [unknown, ...infer U] ? U : never;

/*
 * This pipe can use other pipes as dynamically argument. It could be usefull for dynamic injection
 *  for example for AG grid cell renderers.
 *  original code and problem description here: https://stackoverflow.com/a/70939976/8232825
 */
@Pipe({
  name: 'dynamicPipe',
  pure: true,
})
export class DynamicPipe<P extends PipeTransform> implements PipeTransform {
  constructor(private injector: Injector) {}

  public transform(
    value: Parameters<P['transform']>[1],
    pipeToken: ProviderToken<PipeTransform>,
    pipeArgs?: OmitFirstArg<Parameters<P['transform']>>
  ): ReturnType<P['transform']> | unknown {
    if (!pipeToken) {
      return value;
    }
    const pipe = this.injector.get(pipeToken);
    if (!pipe) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.warn(`Pipe token is not provided or incorrect: ${pipeToken}`);
    }
    return pipe?.transform(value, ...(pipeArgs || [])) || value;
  }
}
