import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from './overlay/overlay.module';
import { TooltipComponent } from './tooltip.component';
import { AdDirective } from './ad.directive';
import { TooltipService } from './tooltip.service';
import { TooltipProperties } from './interfaces';
import { TooltipElementDirective } from './tooltip-element.directive';
import { TooltipTriggerDirective } from './tooltip-trigger.directive';
import { TooltipPropertiesService } from './tooltip-properties.service';
import { TooltipTruncatedDirective } from './tooltip-truncated.directive';
import { TooltipInputDirective } from '@shared/components/tooltip/tooltip-input.directive';

@NgModule({
  declarations: [
    TooltipComponent,
    AdDirective,
    TooltipElementDirective,
    TooltipTriggerDirective,
    TooltipTruncatedDirective,
    TooltipInputDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [
    TooltipElementDirective,
    TooltipTriggerDirective,
    TooltipTruncatedDirective,
    TooltipInputDirective,
  ],
  providers: [TooltipService],
})
export class TooltipModule {
  public static forRoot(
    initProperties: TooltipProperties
  ): ModuleWithProviders<TooltipModule> {
    return {
      ngModule: TooltipModule,
      providers: [
        {
          provide: TooltipPropertiesService,
          useValue: initProperties,
        },
      ],
    };
  }
}
