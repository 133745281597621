<div class="wrapper" [class.show-ellipsis]="isTextOverflow">
  <div
    #content
    class="segment-content"
    [class.rtl]="rtl"
    [class.multiline]="multiline"
    [class.show-invisible]="showInvisible"
  >
    <ng-container *ngIf="renderItems?.length">
      <ng-container *ngFor="let item of displayedRenderItems">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="renderItemTypes.RAW_HTML">
            <span class="render-item row-text" [innerHTML]="item.content.text | safe: 'html'"></span>
          </ng-container>

          <ng-container *ngSwitchCase="renderItemTypes.HIGHLIGHT">
            <span
              class="render-item row-text"
              [ngClass]="{ 'vr-highlight vr-autofix': !disableErrorHighlight }"
              [innerHTML]="item.content.text | safe: 'html'"
            ></span>
          </ng-container>

          <span class="render-item tag-wrapper">
            <vr-tag
              *ngSwitchCase="renderItemTypes.TAG"
              [rtl]="rtl"
              [text]="item.content.text"
              [shortText]="$any(item.content).shortText"
              [tagType]="$any(item.content).tagType"
              [tagView]="$any(tagView)"
              [withoutPair]="$any(item.content).withoutPair"
              [selectionRanges]="!disableErrorHighlight && $any(item.content).selectionRanges"
              [fullTagHighlighted]="!disableErrorHighlight && $any(item.content).fullTagHighlighted"
            ></vr-tag>
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
