/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateLocaleQaSettingsRequest } from '../models/create-locale-qa-settings-request';
import { LocaleCheckSettings } from '../models/locale-check-settings';
import { LocaleQaSettingsModel } from '../models/locale-qa-settings-model';
import { Operation } from '../models/operation';
import { QaSettingsInfo } from '../models/qa-settings-info';

@Injectable({
  providedIn: 'root',
})
export class LocaleQaSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLocaleQaSettingsIdGet
   */
  static readonly ApiLocaleQaSettingsIdGetPath = '/api/LocaleQASettings/{id}';

  /**
   * Get LocaleQASettings collections by QASettingsId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsIdGet$Plain$Response(params: {

    /**
     * QASettingsID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocaleQaSettingsModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocaleQaSettingsModel>>;
      })
    );
  }

  /**
   * Get LocaleQASettings collections by QASettingsId.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsIdGet$Plain(params: {

    /**
     * QASettingsID
     */
    id: string;
    'api-version'?: string;
  }): Observable<Array<LocaleQaSettingsModel>> {

    return this.apiLocaleQaSettingsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocaleQaSettingsModel>>) => r.body as Array<LocaleQaSettingsModel>)
    );
  }

  /**
   * Get LocaleQASettings collections by QASettingsId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsIdGet$Json$Response(params: {

    /**
     * QASettingsID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocaleQaSettingsModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocaleQaSettingsModel>>;
      })
    );
  }

  /**
   * Get LocaleQASettings collections by QASettingsId.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsIdGet$Json(params: {

    /**
     * QASettingsID
     */
    id: string;
    'api-version'?: string;
  }): Observable<Array<LocaleQaSettingsModel>> {

    return this.apiLocaleQaSettingsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocaleQaSettingsModel>>) => r.body as Array<LocaleQaSettingsModel>)
    );
  }

  /**
   * Path part for operation apiLocaleQaSettingsIdPatch
   */
  static readonly ApiLocaleQaSettingsIdPatchPath = '/api/LocaleQASettings/{id}';

  /**
   * Partially update locale QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsIdPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsIdPatch$Plain$Response(params: {

    /**
     * LocaleQASettingsID
     */
    id: string;
    'api-version'?: string;

    /**
     * JsonPatchDocument
     */
    body?: Array<Operation>
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Partially update locale QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsIdPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsIdPatch$Plain(params: {

    /**
     * LocaleQASettingsID
     */
    id: string;
    'api-version'?: string;

    /**
     * JsonPatchDocument
     */
    body?: Array<Operation>
  }): Observable<QaSettingsInfo> {

    return this.apiLocaleQaSettingsIdPatch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Partially update locale QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsIdPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsIdPatch$Json$Response(params: {

    /**
     * LocaleQASettingsID
     */
    id: string;
    'api-version'?: string;

    /**
     * JsonPatchDocument
     */
    body?: Array<Operation>
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Partially update locale QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsIdPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsIdPatch$Json(params: {

    /**
     * LocaleQASettingsID
     */
    id: string;
    'api-version'?: string;

    /**
     * JsonPatchDocument
     */
    body?: Array<Operation>
  }): Observable<QaSettingsInfo> {

    return this.apiLocaleQaSettingsIdPatch$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Path part for operation apiLocaleQaSettingsDefaultGet
   */
  static readonly ApiLocaleQaSettingsDefaultGetPath = '/api/LocaleQASettings/default';

  /**
   * Get default specific locale check settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsDefaultGet$Plain$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<{
[key: string]: LocaleCheckSettings;
}>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: LocaleCheckSettings;
        }>;
      })
    );
  }

  /**
   * Get default specific locale check settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsDefaultGet$Plain(params?: {
    'api-version'?: string;
  }): Observable<{
[key: string]: LocaleCheckSettings;
}> {

    return this.apiLocaleQaSettingsDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: LocaleCheckSettings;
}>) => r.body as {
[key: string]: LocaleCheckSettings;
})
    );
  }

  /**
   * Get default specific locale check settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsDefaultGet$Json$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<{
[key: string]: LocaleCheckSettings;
}>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: LocaleCheckSettings;
        }>;
      })
    );
  }

  /**
   * Get default specific locale check settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocaleQaSettingsDefaultGet$Json(params?: {
    'api-version'?: string;
  }): Observable<{
[key: string]: LocaleCheckSettings;
}> {

    return this.apiLocaleQaSettingsDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: LocaleCheckSettings;
}>) => r.body as {
[key: string]: LocaleCheckSettings;
})
    );
  }

  /**
   * Path part for operation apiLocaleQaSettingsPost
   */
  static readonly ApiLocaleQaSettingsPostPath = '/api/LocaleQASettings';

  /**
   * Creates a default locale QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Profiles.Models.Requests.CreateLocaleQASettingsRequest
     */
    body?: CreateLocaleQaSettingsRequest
  }): Observable<StrictHttpResponse<LocaleQaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocaleQaSettingsModel>;
      })
    );
  }

  /**
   * Creates a default locale QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Profiles.Models.Requests.CreateLocaleQASettingsRequest
     */
    body?: CreateLocaleQaSettingsRequest
  }): Observable<LocaleQaSettingsModel> {

    return this.apiLocaleQaSettingsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocaleQaSettingsModel>) => r.body as LocaleQaSettingsModel)
    );
  }

  /**
   * Creates a default locale QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocaleQaSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Profiles.Models.Requests.CreateLocaleQASettingsRequest
     */
    body?: CreateLocaleQaSettingsRequest
  }): Observable<StrictHttpResponse<LocaleQaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, LocaleQaSettingsService.ApiLocaleQaSettingsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocaleQaSettingsModel>;
      })
    );
  }

  /**
   * Creates a default locale QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocaleQaSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocaleQaSettingsPost$Json(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Profiles.Models.Requests.CreateLocaleQASettingsRequest
     */
    body?: CreateLocaleQaSettingsRequest
  }): Observable<LocaleQaSettingsModel> {

    return this.apiLocaleQaSettingsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocaleQaSettingsModel>) => r.body as LocaleQaSettingsModel)
    );
  }

}
