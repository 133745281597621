<app-ui-dropdown-menu (menuClosed)="onClosed()" [disabled]="disabled">
  <app-action-icon
    [appTooltip]="tooltip"
    [appTooltipDisabled]="!tooltip || disabled"
    icon="open-in-new"
    dropdown-trigger
    theme="subtle"
    size="sm"
  ></app-action-icon>
  <div dropdown-content class="popup-content-wrapper" appDropdownMenuPreventClick>
    <div class="header">
      {{ headerTitle }}
    </div>
    <app-ui-search-input
      size="large"
      [formControl]="searchControl"
      [appDisableControl]="listIsEmpty || disabled"
    ></app-ui-search-input>
    <div class="chip-list-wrapper">
      <app-chip-list
        [ngModel]="filteredList"
        [disabled]="disabled"
        [inputPlaceholder]="inputPlaceholder"
        (added)="addNewItems($event)"
        (updated)="updateItem($event)"
        (removed)="deleteItems($event)"
      >
        <ng-container list-placeholder>
          <ng-container *ngIf="searchControl.value"> No matches </ng-container>
        </ng-container>
      </app-chip-list>
    </div>
  </div>
</app-ui-dropdown-menu>
