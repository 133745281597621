<div class="app-measure-cell-editor">
  <input
    appPopoverAnchor
    #popoverAnchor="popoverAnchor"
    [formControl]="control"
    #input
    class="grid-cell-input"
    (keydown.enter)="onEnter()"
  />

  <app-popover #validationPopover [anchor]="popoverAnchor" horizontalAlign="after" verticalAlign="center">
    {{ errorMsg }}
  </app-popover>
</div>
