import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { SpinnerSize } from '../models/spinner-size.model';

/**
 * Spinner component.
 * It's just a plain spinner thing. For loader overlay, please, use loader component
 * You can control size and color by host element size and color manipulation.
 *
 * Usage:
 * ```
 * <app-spinner [style.width.px]="spinnerSize" [style.height.px]="spinnerSize" [style.color]="spinnerColor"></app-spinner>
 * <app-spinner size="normal"></app-spinner>
 * ```
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input()
  set size(value: SpinnerSize) {
    this.buttonClass = value === SpinnerSize.Button;
    this.smallClass = value === SpinnerSize.Small;
    this.normalClass = value === SpinnerSize.Normal;
    this.largeClass = value === SpinnerSize.Large;
  }

  @HostBinding(`class.${SpinnerSize.Button}`)
  buttonClass: boolean;

  @HostBinding(`class.${SpinnerSize.Small}`)
  smallClass: boolean;

  @HostBinding(`class.${SpinnerSize.Normal}`)
  normalClass: boolean;

  @HostBinding(`class.${SpinnerSize.Large}`)
  largeClass: boolean;
}
