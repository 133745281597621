import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { AuthStorage } from './auth.storage';
import { InviteTokenService } from '../../services';

function auth(authService: AuthService): () => Promise<any> {
  return () => authService.init();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.allowedUrls,
        sendAccessToken: environment.sendAccessToken,
      },
    }),
  ],
  providers: [
    InviteTokenService,
    { provide: OAuthStorage, useClass: AuthStorage },
    {
      provide: APP_INITIALIZER,
      useFactory: auth,
      deps: [AuthService],
      multi: true,
    },
    AuthService,
  ],
})
export class AuthModule {}
