import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipListAdvancedComponent } from './chip-list-advanced';
import { SearchInputModule } from '@shared/components/search-input';
import { ActionIconModule } from '@shared/components/action-icon';
import { IconModule } from '@shared/components/icon';
import { TooltipModule } from '@shared/components/tooltip';
import { ChipsInputComponent } from './chips-input/chips-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { FileChooserModule } from '@shared/components/file-chooser';
import { FileService } from '@shared/services/file.service';
import { NotificationModule } from '@shared/components/notification';
import { ChipModule } from '@shared/components/chip';
import { DirectivesModule } from '@shared/directives';
import { ChipListComponent } from './chip-list';
import { ChipListPopupComponent } from './chip-list-popup';
import { DropdownMenuModule } from '@shared/components/dropdown-menu';
import { EmptyMessageModule } from '../empty-message/empty-message.module';

@NgModule({
  declarations: [ChipListAdvancedComponent, ChipsInputComponent, ChipListComponent, ChipListPopupComponent],
  exports: [ChipListAdvancedComponent, ChipListComponent, ChipListPopupComponent],
  imports: [
    CommonModule,
    SearchInputModule,
    ActionIconModule,
    IconModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualScrollerModule,
    FileChooserModule,
    NotificationModule,
    ChipModule,
    DirectivesModule,
    DropdownMenuModule,
    EmptyMessageModule,
  ],
  providers: [FileService],
})
export class ChipListModule {}
