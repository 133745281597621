import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  static defaultCompare(a: any, b: any, reverse: boolean = false) {
    let result: number;
    if (a === b) {
      result = 0;
    } else if (a == null) {
      result = 1;
    } else if (b == null) {
      result = -1;
    } else {
      result = a > b ? 1 : -1;
    }
    return reverse ? -result : result;
  }

  transform(value: any | any[], expression?: string, reverse?: boolean): any {
    if (!value) {
      return value;
    }

    var res = value.sort((a: any, b: any): number => {
      return SortByPipe.defaultCompare(a[expression], b[expression], reverse);
    });

    return res;
  }
}
