import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslationDestination } from '@shared/models/search-in-report-files';
import { deepEqual } from '@shared/tools';
import { SearchType } from '../search-pattern/search-type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchOptions } from '@generated/api';

export interface SourceTargetPatternsPayload {
  source: SearchOptions;
  target: SearchOptions;
  hasError?: boolean;
}

@Component({
  selector: 'app-source-target-patterns',
  templateUrl: './source-target-patterns.component.html',
  styleUrls: ['./source-target-patterns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceTargetPatternsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  @Input() isTooltipVisible: boolean = true;
  @Input() sourceLabel: string;
  @Input() targetLabel: string;
  @Input()
  set options(options: SourceTargetPatternsPayload) {
    this.setChangedOptions(options);
  }

  @Output()
  public readonly changed: EventEmitter<SourceTargetPatternsPayload> = new EventEmitter();

  @Output()
  public readonly enterKeyPressed: EventEmitter<void> = new EventEmitter();

  public source = new FormControl();
  public target = new FormControl();

  public searchType = SearchType;

  constructor() {}

  ngOnInit(): void {
    this.source.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: SearchOptions) => {
      this.checkCrossSegmentsBackReferencesSetOnce(value, 'source');
      this.patternsChanged();
    });

    this.target.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: SearchOptions) => {
      this.checkCrossSegmentsBackReferencesSetOnce(value, 'target');
      this.patternsChanged();
    });
  }

  get hasError(): boolean {
    return !!this.target.errors || !!this.source.errors;
  }

  // NOTE: crossSegmentsBackReferences can only be enabled for one search-pattern
  private checkCrossSegmentsBackReferencesSetOnce(
    newSearchPattern: SearchOptions,
    checkDestination: TranslationDestination
  ): void {
    if (!newSearchPattern.crossSegmentsBackReferences) {
      return;
    }
    const oppositeDirection = checkDestination === 'target' ? 'source' : 'target';
    this[oppositeDirection].patchValue(
      {
        ...this[oppositeDirection].value,
        crossSegmentsBackReferences: false,
      },
      { emitEvent: false }
    );
  }

  private patternsChanged(): void {
    this.changed.emit({
      source: this.source.value,
      target: this.target.value,
      hasError: this.hasError,
    });
  }

  private setChangedOptions(options?: SourceTargetPatternsPayload): void {
    if (!deepEqual(options.source, this.source.value)) {
      this.source.patchValue(options.source, { emitEvent: false });
    }
    if (!deepEqual(options.target, this.target.value)) {
      this.target.patchValue(options.target, { emitEvent: false });
    }
  }

  public enterKeyPressedHandler(): void {
    this.enterKeyPressed.emit();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
