import { SelectionRange, UniversalSelectionRange } from '@shared/models';

/**
 * Gets the total length of the selection range
 */
export function getSelectionRangesTotalLength(selectionRanges: UniversalSelectionRange[]) {
  return selectionRanges.reduce((acc, cur) => acc + (cur.range?.length ?? cur.length), 0);
}

/**
 * Finds intersect selection ranges.
 *
 * There is duplication cause consistency additional data and quality issue have
 * different model for selection range.
 */
export function intersectSelectionRanges(
  selectionRanges: UniversalSelectionRange[],
  selectionRange: SelectionRange
): SelectionRange[] {
  const filteredSelectionRanges: SelectionRange[] = [];

  selectionRanges.forEach((r) => {
    const end = r.range?.end ?? r.end;
    const start = r.range?.start ?? r.start;
    if (selectionRange.start < end && selectionRange.end > start) {
      const range = r.range || r;
      filteredSelectionRanges.push({
        start: range.start,
        end: range.end,
        length: range.length,
        fix: r.fix,
        isFixAvailable: r.isFixAvailable,
        autofixRange: r.autofixRange,
        autofixType: r.autofixType,
      });
    }
  });

  return filteredSelectionRanges;
}

/**
 * Normalizes selection ranges
 */
export function normalizeSelectionRanges(
  selectionRanges: UniversalSelectionRange[],
  shiftCount: number,
  min: number,
  max: number
): SelectionRange[] {
  return selectionRanges.map((r) => {
    let start = r.range?.start ?? r.start;
    let end = r.range?.end ?? r.end;
    start = Math.max(r.start - shiftCount, min);
    end = Math.min(r.end - shiftCount, max);
    return {
      start,
      end,
      length: end - start,
      fix: r.fix,
      isFixAvailable: r.isFixAvailable,
      autofixRange: r.autofixRange,
      autofixType: r.autofixType,
    };
  });
}

/**
 * Check ranges intersections
 *
 * @param aRange = [startIndex, endIndex]
 * @param bRange = [startIndex, endIndex]
 */
export function checkRangesIntersection(aRange: [number, number], bRange: [number, number]): boolean {
  const pointInRange = (range, point): boolean => range[0] < point && range[1] > point;
  return (
    pointInRange(aRange, bRange[0]) ||
    pointInRange(aRange, bRange[1]) ||
    pointInRange(bRange, aRange[0]) ||
    pointInRange(bRange, aRange[1]) ||
    (bRange[0] === aRange[0] && bRange[1] === aRange[1])
  );
}
