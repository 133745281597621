import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';
import { NavigatorService } from '../navigator.service';
import { getDefaultGroupedShortcutsReference } from './default-shortcut.constants';
import { KeyCodes } from './key-codes.model';
import {
  GroupedShortcutsReference,
  KeybindingEvent,
  KeybindingGroups,
  Shortcut,
  ShortcutsReference,
} from './shortcuts.model';

interface KeybindingAction {
  group: KeybindingGroups;
  event: KeybindingEvent;
  // Identifier for select from multiple instance
  id?: string;
  originalEvent: KeyboardEvent;
}

/*
 * Service for global shortcuts keybinding
 */
@Injectable()
export class ShortcutsService {
  private groupedShortcutsReference: GroupedShortcutsReference;
  private keybindingEvents: { [key in KeybindingGroups]?: { [key: string]: KeybindingEvent } } = {};
  public eventShortuts: { [key: string]: string | string[] } = {};
  private readonly keyMapSeparator = '+';

  private readonly keybindingAction$: Subject<KeybindingAction> = new Subject<KeybindingAction>();
  private ignoredSpecialKeys = /^(Left)?(Shift|Alt|Ctrl|Meta|Command|Super)(Left)?$/;

  constructor(private navigatorService: NavigatorService) {
    this.initGroupedShortcutsReference();
    this.collectDefaultShortcuts();
  }

  private initGroupedShortcutsReference(): void {
    const isMac = this.navigatorService.isMac;
    this.groupedShortcutsReference = getDefaultGroupedShortcutsReference(isMac);
  }

  public getGroupedShortcutsReference(): GroupedShortcutsReference {
    return this.groupedShortcutsReference;
  }

  public watchGroup(groupName: KeybindingGroups, id?: string): Observable<KeybindingEvent> {
    return this.keybindingAction$.pipe(
      filter((v) => v.group === groupName && (!id || id === v.id)),
      map((v) => v.event)
    );
  }

  public watchGroupWithMeta(groupName: KeybindingGroups): Observable<KeybindingAction> {
    return this.keybindingAction$.pipe(filter((v) => v.group === groupName));
  }

  public handleKeydownEvent(group: KeybindingGroups | KeybindingGroups[], event: KeyboardEvent, id?: string): void {
    // NOTE: with the current implementation the order of key combination is important.
    // But this approach has fewer iterations
    const isKeybindingMap = Array.isArray(group);
    if (!isKeybindingMap) {
      this.emmitKeybindingEvent(group, event, id);
      return;
    }
    for (const g of group) {
      const matched = this.emmitKeybindingEvent(g, event, id);
      if (matched) {
        return;
      }
    }
  }

  private emmitKeybindingEvent(group: KeybindingGroups, event: KeyboardEvent, id?: string): boolean {
    const eventKey = this.createEventKey(event);
    const keybindingEvent = this.keybindingEvents[group][eventKey];

    if (keybindingEvent) {
      this.keybindingAction$.next({ group, event: keybindingEvent, originalEvent: event, id });
      return true;
    }
  }

  public getShortcutKeyByEvent(event: KeybindingEvent): string | string[] {
    const key = this.eventShortuts[event];
    return key;
  }

  private createEventKey(event: KeyboardEvent): string {
    const orderedKeyCombination: string[] = [];

    if (event.shiftKey) {
      orderedKeyCombination.push(KeyCodes.KEY_SHIFT);
    }
    if (event.ctrlKey) {
      orderedKeyCombination.push(KeyCodes.KEY_CONTROL);
    }
    if (event.metaKey) {
      orderedKeyCombination.push(KeyCodes.KEY_META);
    }
    if (event.altKey) {
      orderedKeyCombination.push(KeyCodes.KEY_ALT);
    }
    if (!this.ignoredSpecialKeys.test(event.code)) {
      orderedKeyCombination.push(event.code);
    }
    const eventKey = orderedKeyCombination.join(this.keyMapSeparator);
    return eventKey;
  }

  private collectDefaultShortcuts(): void {
    Object.keys(this.groupedShortcutsReference).forEach((group: KeybindingGroups) => {
      const references = this.groupedShortcutsReference[group];
      references.flat(1).forEach((reference: ShortcutsReference) => {
        this.aggreagateShortcutsFromReference(group, reference);
        reference.children?.flat(1).forEach((r) => this.aggreagateShortcutsFromReference(group, r));
      });
    });
  }

  private aggreagateShortcutsFromReference(group: KeybindingGroups, reference: ShortcutsReference): void {
    reference.shortcuts.forEach((shortcut: Shortcut) => {
      const shortcutKey = typeof shortcut.key === 'string' ? shortcut.key : shortcut.key.join(this.keyMapSeparator);
      if (!this.keybindingEvents[group]) {
        this.keybindingEvents[group] = {};
      }
      this.eventShortuts[shortcut.event] = shortcut.key;
      this.keybindingEvents[group][shortcutKey] = shortcut.event;
    });
  }
}
