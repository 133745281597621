import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { OptionComponent } from '@shared/components/select-v2/option';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { OptgroupComponent } from '@shared/components/select-v2/optgroup';

@Component({
  template: '',
})
export class BaseSelectComponent<ValueType, OptionType> implements AfterContentInit, OnDestroy, ControlValueAccessor {
  public displayedOptions: OptionType[] = [];
  public groupBy = 'group';

  protected inputOptions: OptionType[] = [];
  protected ngContentOptions: OptionType[] = [];

  private destroyed$ = new Subject<void>();
  #bindToIndex: boolean;

  @Input() placeholder = '';
  @Input() tabIndex: number = 0;
  @Input() labelKey = 'text';
  @Input() valueKey = 'value';
  @Input() disabled = false;
  @Input() searchable = false;
  @Input() optionTemplate: TemplateRef<any>;
  @Input() appendTo: string;
  @Input() value: ValueType;
  @Input() compareWith: (a: any, b: any) => boolean;

  @Input() set bindToIndex(value: boolean) {
    this.#bindToIndex = value;
    this.valueKey = 'index';

    if (this.bindToIndex) {
      this.initOptions();
    }
  }

  get bindToIndex(): boolean {
    return this.#bindToIndex;
  }

  @Output() valueChange = new EventEmitter<ValueType>();

  @Input() set options(value: OptionType[]) {
    this.inputOptions = value;
    this.initOptions();
  }

  @ContentChildren(OptionComponent) optionComponents: QueryList<OptionComponent>;
  @ContentChildren(OptgroupComponent) optgroupComponents: QueryList<OptgroupComponent>;

  protected onChange: (value: ValueType) => void;

  ngAfterContentInit() {
    this.subscribeOptionComponents(this.optionComponents);
    this.subscribeOptgroupComponents();
  }

  private initOptions(): void {
    if (this.bindToIndex) {
      this.inputOptions = this.inputOptions.map((option, index) => ({ ...option, index }));
    }
    this.prepareOptions();
  }

  public groupLabel(item: OptionType): string {
    if (this.groupBy) {
      return item[this.groupBy];
    }
    return item as unknown as string;
  }

  public labelGetter(item: OptionType): string {
    if (this.labelKey) {
      return item[this.labelKey];
    }
    return item as unknown as string;
  }

  public valueGetter(item: OptionType): string {
    if (this.valueKey) {
      return item[this.valueKey];
    }
    return item as unknown as string;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: ValueType): void {
    this.value = value;
  }

  private prepareOptions(): void {
    if (this.ngContentOptions.length) {
      this.displayedOptions = this.ngContentOptions.slice();
    } else {
      this.displayedOptions = this.inputOptions.slice();
    }
  }

  private subscribeOptionComponents(optionComponents: QueryList<OptionComponent>, optionsGroup?: string): void {
    optionComponents.changes
      .pipe(startWith(optionComponents), takeUntil(this.destroyed$))
      .subscribe((list: OptionComponent[]) => {
        this.ngContentOptions = list.map((optionComponent) =>
          this.convertOptionComponentToOption(optionComponent, optionsGroup)
        );
        this.prepareOptions();
      });
  }

  private subscribeOptgroupComponents(): void {
    this.optgroupComponents.changes
      .pipe(startWith(this.optgroupComponents), takeUntil(this.destroyed$))
      .subscribe((optgroupComponents: OptgroupComponent[]) => {
        optgroupComponents.forEach((optgroupComponent) => {
          this.subscribeOptionComponents(optgroupComponent.optionComponents, optgroupComponent.label);
        });
      });
  }

  private convertOptionComponentToOption(optionComponent: OptionComponent, group?: string): OptionType {
    return {
      [this.labelKey]: optionComponent.label,
      [this.valueKey]: optionComponent.value,
      disabled: optionComponent.disabled,
      [this.groupBy]: group,
    } as unknown as OptionType;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
