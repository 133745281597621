import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Disable Reactive Forms Control by condition.
 * Use this Directive instead of `[disabled]="condition"`.
 */
@Directive({
  selector: '[appDisableControl]',
})
export class DisableControlDirective implements OnChanges, OnDestroy {
  @Input() appDisableControl: boolean;
  @Input() appDisableControlEmitChange: boolean = true;

  @Output()
  public readonly appDisableControlChange = new EventEmitter<boolean>();

  private readonly destroyed$ = new Subject<void>();

  private subscription$: Subscription;

  constructor(private ngControl: NgControl) {}

  private tryWatchControlChanges(): void {
    const isOutputListened = this.appDisableControlChange.observers.length;
    if (this.subscription$ || !isOutputListened) {
      return;
    }

    this.subscription$ = this.ngControl.valueChanges?.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const disabled = this.ngControl.disabled;
      if (this.appDisableControl === disabled) {
        return;
      }
      this.appDisableControlChange.emit(disabled);
      this.appDisableControl = disabled;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // We use ngOnChanges instead of plain setter because of this: https://github.com/angular/angular/issues/35330
    // ngControl.control is just not initialized in setter!
    // Seems bug will not be fixed soon.
    if (!changes?.appDisableControl || !this.ngControl) {
      return;
    }
    this.tryWatchControlChanges();
    const method: keyof AbstractControl = this.appDisableControl ? 'disable' : 'enable';

    this.ngControl.control[method]({
      emitEvent: this.appDisableControlEmitChange,
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.unsubscribe();
  }
}
