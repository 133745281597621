<app-action-icon
  [active]="flag"
  class="has-comment"
  icon="chat"
  (clickButton)="toggle(true)"
  appTooltip="With comments"
  appTooltipPlacement="bottom"
  theme="subtle"
></app-action-icon>
<app-action-icon
  [active]="flag === false"
  class="no-comment"
  icon="chat"
  (clickButton)="toggle(false)"
  appTooltip="No comments"
  appTooltipPlacement="bottom"
  theme="subtle"
></app-action-icon>
