import { Injectable } from '@angular/core';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { switchMap } from 'rxjs/operators';
import { LoadReferences, SetReferences, LoadReferencesIfNotExist } from './reference.actions';
import { ProfileReferenceDataModel, QaSettingsService } from '@generated/api';

interface ReferenceStateModel {
  references?: ProfileReferenceDataModel;
}

@AsyncStorage
@StateRepository()
@State<ReferenceStateModel>({
  name: 'references',
  defaults: null,
})
@Injectable()
export class ReferenceState {
  constructor(private qaSettingsService: QaSettingsService) {}

  @Selector()
  static references(state: ReferenceStateModel): ProfileReferenceDataModel {
    return state?.references;
  }

  // TODO: необходимо версионирование со стороны бекенда
  // чтобы не загружать каждый раз запись а брать из кеша.
  @Action(LoadReferences)
  public loadReferences(ctx: StateContext<ReferenceStateModel>) {
    return this.qaSettingsService
      .apiQaSettingsReferencesGet$Json({
        'api-version': '1.1',
      })
      .pipe(switchMap((res) => ctx.dispatch(new SetReferences(res))));
  }

  @Action(LoadReferencesIfNotExist)
  public loadReferencesIfNotExist(ctx: StateContext<ReferenceStateModel>) {
    if (ctx.getState()?.references) {
      return;
    }
    ctx.dispatch(new LoadReferences());
  }

  @Action(SetReferences)
  public setReferences(ctx: StateContext<ReferenceStateModel>, action: SetReferences) {
    return ctx.setState({
      references: action.references,
    });
  }
}
