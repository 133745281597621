import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { take } from 'rxjs';
import { ShortcutsReferenceComponent } from '../shortcuts-reference/shortcuts-reference.component';
import { SidebarOptions, SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-shortcuts-helper-btn',
  templateUrl: './shortcuts-helper-btn.component.html',
  styleUrls: ['./shortcuts-helper-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutsHelperBtnComponent implements OnInit {
  public shortcutsHelperOpened = false;
  private sidebarOptions: SidebarOptions;

  constructor(private readonly sidebarService: SidebarService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initSidebarOptions();
  }

  public toggleShortcutsHelper(): void {
    this.shortcutsHelperOpened = !this.shortcutsHelperOpened;
    const sidebarComponent = this.sidebarService.toggle(this.sidebarOptions);
    sidebarComponent?.instance.closed.pipe(take(1)).subscribe(() => {
      this.shortcutsHelperOpened = false;
      this.cdr.markForCheck();
    });
  }

  private initSidebarOptions(): void {
    this.sidebarOptions = {
      title: 'Keyboard shortcuts',
      component: ShortcutsReferenceComponent,
    };
  }
}
