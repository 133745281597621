import { ProjectInfo } from '@generated/api';
import { Pagination } from '@shared/models';

const defaultPagination: Pagination = { page: 1, pageSize: 10 };

/**
 * Action to reload projects for the current session and select the first one
 */
export class ReloadProjects {
  static readonly type = '[Projects] Reload projects';
  constructor() {}
}

export class LoadProjects {
  static readonly type = '[Projects] Load Projects';
  constructor(public readonly pagination: Pagination = defaultPagination, public readonly searchQuery?: string) {}
}

export class AddProject {
  static readonly type = '[Projects] Add Project';
  constructor(public name: string = 'Untitled project') {}
}

export class PushProject {
  static readonly type = '[Projects] Push Project';
  constructor(public project: ProjectInfo) {}
}

export class UpdateProjectName {
  static readonly type = '[Projects] Update Project name in list';
  constructor(public id: string, public name: string) {}
}

export class UpdateProject {
  static readonly type = '[Projects] Update project';
  constructor(public project: ProjectInfo) {}
}

export class ResetProjects {
  static readonly type = '[Projects] Reset projects';
  constructor() {}
}

export class ResetProjectsPagination {
  static readonly type = '[Projects] Reset projects pagination';
  constructor() {}
}

export class ShiftStoredProjects {
  static readonly type = '[Projects] Shift stored projects';
  constructor(public readonly project: ProjectInfo) {}
}

export class CleanStoredProjectInfo {
  static readonly type = '[Projects] Clean stored project info';
  constructor(public readonly project: ProjectInfo) {}
}
