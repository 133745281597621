import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationsManagerComponent } from './organizations-manager/organizations-manager.component';
import { IconModule } from '../icon';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OrganizationCreatorComponent } from './organization-creator/organization-creator.component';
import { ModalModule } from '../modal';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button';
import { ActionIconModule } from '../action-icon';
import { OrganizationInviteComponent } from './organization-invite/organization-invite.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderModule } from '../loader';
import { TooltipModule } from '../tooltip';

@NgModule({
  declarations: [
    OrganizationsManagerComponent,
    OrganizationsListComponent,
    OrganizationCreatorComponent,
    OrganizationInviteComponent,
  ],
  imports: [
    CommonModule,
    LoaderModule,
    IconModule,
    MatDialogModule,
    ModalModule,
    ReactiveFormsModule,
    ButtonModule,
    ActionIconModule,
    MatChipsModule,
    MatFormFieldModule,
    TooltipModule,
  ],
  exports: [OrganizationsManagerComponent],
})
export class OrganizationsManagerModule {}
