<div class="shortcut">
  <div class="shortcut-description" [ngClass]="'left-offset-' + groupLevel">
    <ng-container *ngFor="let ref of shortcutReferences; let last = last">
      <ng-container *ngIf="!ref.suppressVisualReference">
        {{ ref.description }}
        <span *ngIf="!last">/</span>
      </ng-container>
    </ng-container>
  </div>
  <div class="shortcut-keys">
    <ng-container *ngFor="let ref of shortcutReferences; let last = last">
      <ng-container *ngIf="!ref.suppressVisualReference">
        <ng-container *ngFor="let sc of ref.shortcuts" class="shortcut-key">
          <ng-container *ngIf="!sc.suppressVisualReference">
            <ng-container *ngIf="sc.numbers; else noNumberTemplate">
              <span class="shortcut-key" [ngClass]="{ 'single-symbol': ($any(sc.key) | prettyKey)?.length === 1 }">{{
                $any(sc.key) | prettyKey
              }}</span>
              <span class="shortcut-key single-symbol">1</span>
              /
              <span class="shortcut-key">{{ $any(sc.key) | prettyKey }}</span>
              <span class="shortcut-key single-symbol">2</span>
              ...
            </ng-container>

            <ng-template #noNumberTemplate>
              <ng-container *ngIf="isString(sc.key); else keybindingListTemplate">
                <span class="shortcut-key" [ngClass]="{ 'single-symbol': ($any(sc.key) | prettyKey)?.length === 1 }">
                  {{ $any(sc.key) | prettyKey }}
                </span>
              </ng-container>
              <ng-template #keybindingListTemplate>
                <span
                  class="shortcut-key"
                  *ngFor="let key of sc.key"
                  [ngClass]="{ 'single-symbol': ($any(key) | prettyKey)?.length === 1 }"
                >
                  {{ key | prettyKey }}
                </span>
              </ng-template>
            </ng-template>
            <span *ngIf="!last">/</span>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngFor="let ref of shortcutReferences">
  <ng-container *ngIf="ref.children">
    <ng-container *ngFor="let childrenGroup of ref.children">
      <app-shortcut-preview [shortcutReferences]="childrenGroup" [groupLevel]="groupLevel + 1" class="shortcut-preview">
      </app-shortcut-preview>
    </ng-container>
  </ng-container>
</ng-container>
