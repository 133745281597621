import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type NumberBoxWidthClass = 'w2' | 'w3' | 'w4' | 'w5';
export type NumberBoxWidth = 2 | 3 | 4 | 5;
export type NumberBoxLabelPosition = 'left' | 'right';

@Component({
  selector: 'app-number-box',
  templateUrl: './number-box.component.html',
  styleUrls: ['./number-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberBoxComponent),
      multi: true,
    },
  ],
})
export class NumberBoxComponent implements ControlValueAccessor {
  /**
   * Label position
   */
  @Input() labelPosition: NumberBoxLabelPosition = 'left';

  @Input() disabled = false;

  @Input() required = false;

  @Input() readonly = false;

  @Input() hideLabel = false;

  @Input() fullWidth = false;

  @Output() changed = new EventEmitter<number>();

  /**
   * Minimum value.
   * If you try to enter a value less than the min, it will automatically be changed to the min value.
   * Use form validation to apply different behaviour.
   */
  @Input()
  set min(val: number | undefined) {
    if (!Number.isFinite(val) || val < 0) {
      val = 0;
    }
    this.minInternal = val;
    this.checkActiveArrows();
    this.applyValue(this.valueInternal);
  }

  /**
   * Maximum value.
   * If you try to enter a value greater than the max, it will automatically be changed to the max value.
   * Use form validation to apply different behaviour.
   */
  @Input()
  set max(val: number | undefined) {
    if (!Number.isFinite(val) || val < 0) {
      val = 0;
    }
    this.maxInternal = val;
    this.checkActiveArrows();
    this.applyValue(this.valueInternal);
  }

  /**
   * NumberBox width, in number of digits
   */
  @Input()
  set width(val: NumberBoxWidth) {
    if (val === 2) {
      this.widthClass = 'w2';
    } else if (val === 3) {
      this.widthClass = 'w3';
    } else if (val === 4) {
      this.widthClass = 'w4';
    } else if (val === 5) {
      this.widthClass = 'w5';
    } else {
      this.widthClass = 'w2';
    }
  }

  @Input()
  set value(val) {
    if (val !== this.valueInternal) {
      this.applyValue(val);
      this.checkActiveArrows();

      this.onChange(this.valueInternal);
      this.changed.emit(this.valueInternal);
      this.onTouched();
    }
  }

  public get value(): number {
    return this.valueInternal;
  }

  widthClass: NumberBoxWidthClass = 'w2';
  activeUp = true;
  activeDown = true;
  minInternal: number | undefined;
  maxInternal: number | undefined;

  elementId = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);

  private valueInternal: number = undefined;

  @ViewChild('input') inputElement: ElementRef;

  constructor() {}

  private applyValue(value: number): void {
    if (!Number.isFinite(value)) {
      this.valueInternal = undefined;
    } else if (Number.isFinite(this.maxInternal) && value >= this.maxInternal) {
      this.valueInternal = this.maxInternal;
    } else if (Number.isFinite(this.minInternal) && value <= this.minInternal) {
      this.valueInternal = this.minInternal;
    } else if (value < 0) {
      this.valueInternal = 0;
    } else {
      this.valueInternal = value;
    }

    // Ignore non-number characters and characters outside of min and max
    if (this.inputElement?.nativeElement) {
      if (!Number.isFinite(this.valueInternal)) {
        this.inputElement.nativeElement.value = '';
      } else {
        this.inputElement.nativeElement.value = this.valueInternal.toString(10);
      }
    }
  }

  /**
   * Set focus to input element
   */
  public focus(): void {
    this.inputElement?.nativeElement?.focus();
  }

  public startEdit(): void {
    const el = this.inputElement?.nativeElement;

    if (!el) {
      return;
    }

    el.focus();
    el.select();
  }

  // region Arrows

  private checkActiveArrows(): void {
    if (this.disabled || this.readonly) {
      this.activeUp = false;
      this.activeDown = false;
      return;
    }
    if (!Number.isFinite(this.value)) {
      this.activeUp = true;
      this.activeDown = true;
      return;
    }

    this.activeUp = Number.isFinite(this.maxInternal)
      ? this.value < this.maxInternal
      : true;
    this.activeDown = Number.isFinite(this.minInternal)
      ? this.value > this.minInternal
      : true;
  }

  public onUpArrowClick(): void {
    this.checkActiveArrows();
    if (this.disabled || this.readonly || this.activeUp !== true) {
      return;
    }
    if (!Number.isFinite(this.value)) {
      if (Number.isFinite(this.minInternal)) {
        this.value = this.minInternal;
      } else {
        this.value = 0;
      }
    } else {
      this.value++;
    }
    this.focus();
  }

  public onDownArrowClick(): void {
    this.checkActiveArrows();
    if (this.disabled || this.readonly || this.activeDown !== true) {
      return;
    }
    if (!Number.isFinite(this.value)) {
      if (Number.isFinite(this.minInternal)) {
        this.value = this.minInternal;
      } else {
        this.value = 0;
      }
    } else {
      this.value--;
    }
    this.focus();
  }

  // endregion

  // region ControlValueAccessor feature

  onChange: any = () => {};

  onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value): void {
    this.value = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = !!isDisabled;
    this.checkActiveArrows();
  }

  // endregion
}
