import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CommonHubService } from '@shared/services';
import { PotentialUntranslatablesCompletedEvent, SearchInReportFilesResultItem } from '@shared/models';
import { ProjectState } from '@store';
import { Observable, tap } from 'rxjs';
import {
  ShowPotentialUntranslatables,
  LoadPotentialUntranslatables,
  LoadPotentialUntranslatablesCount,
  PatchPotentialUntranslatables,
  RemovePotentialUntranslatables,
  LoadPotentialUntranslatablesContext,
} from './potential-untranslatables.actions';
import {
  PotentialUntranslatableItem,
  PotentialUntranslatableItemDataSourceResponse,
  PotentialUntranslatablesService,
  SearchMode,
  SearchType,
  TranslationUnitsService,
} from '@generated/api';
import { map } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { decrement, removeItems } from '@store/custom-operators';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';
import { SearchInProjectFilesResultItemMapper } from '@shared/mappers';

// TODO: хороший паттерн, нужно сделать для всех стейтов зависящих от проектов
interface StateModelByProject<T> {
  [projectId: string]: T;
}

export interface PotentialUntranslatablesStateModel {
  showPotentialUntranslatables: boolean;
  count: number;
  list: PotentialUntranslatableItem[];
  context: SearchInReportFilesResultItem[];
}

@AsyncStorage
@State<StateModelByProject<PotentialUntranslatablesStateModel>>({
  name: 'potentialUntranslatables',
  defaults: {},
})
@Injectable()
export class PotentialUntranslatablesState {
  constructor(
    private store: Store,
    private commonHub: CommonHubService,
    private potentialUntranslatablesService: PotentialUntranslatablesService,
    private translationUnitsService: TranslationUnitsService,
    private searchInProjectFilesResultItemMapper: SearchInProjectFilesResultItemMapper
  ) {
    this.commonHub.watch(PotentialUntranslatablesCompletedEvent).subscribe((data) => {
      const show = this.store.selectSnapshot(PotentialUntranslatablesState.show);
      this.store.dispatch(
        new PatchPotentialUntranslatables(data.projectId, {
          count: data.count,
          showPotentialUntranslatables: !!data.count || show,
        })
      );
    });
  }

  @Selector([PotentialUntranslatablesState, ProjectState.projectId])
  public static list(
    state: StateModelByProject<PotentialUntranslatablesStateModel>,
    projectId: string
  ): PotentialUntranslatableItem[] {
    return (state && state[projectId]?.list) || [];
  }

  @Selector([PotentialUntranslatablesState, ProjectState.projectId])
  public static count(state: StateModelByProject<PotentialUntranslatablesStateModel>, projectId: string): number {
    return (state && state[projectId]?.count) || 0;
  }

  @Selector([PotentialUntranslatablesState, ProjectState.projectId])
  public static show(state: StateModelByProject<PotentialUntranslatablesStateModel>, projectId: string): boolean {
    return (state && state[projectId]?.showPotentialUntranslatables) || false;
  }

  @Selector([PotentialUntranslatablesState, ProjectState.projectId])
  public static context(
    state: StateModelByProject<PotentialUntranslatablesStateModel>,
    projectId: string
  ): SearchInReportFilesResultItem[] {
    return (state && state[projectId]?.context) || [];
  }

  @Action(LoadPotentialUntranslatablesCount)
  public loadPotentialUntranslatablesCount(ctx: StateContext<PotentialUntranslatablesStateModel>): Observable<any> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    return this.potentialUntranslatablesService
      .apiProjectsProjectIdPotentialUntranslatablesGet$Json({ projectId, pageSize: 0 })
      .pipe(
        tap((response: PotentialUntranslatableItemDataSourceResponse) => {
          ctx.dispatch(new PatchPotentialUntranslatables(projectId, { count: response.total }));
        }),
        map((data) => data.total)
      );
  }

  @Action(LoadPotentialUntranslatables)
  public loadPotentialUntranslatables(
    ctx: StateContext<PotentialUntranslatablesStateModel>
  ): Observable<PotentialUntranslatableItem[]> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    return this.potentialUntranslatablesService
      .apiProjectsProjectIdPotentialUntranslatablesGet$Json({ projectId, pageSize: 999999 })
      .pipe(
        tap((response: PotentialUntranslatableItemDataSourceResponse) => {
          ctx.dispatch(
            new PatchPotentialUntranslatables(projectId, {
              list: response.data,
              count: response.total,
            })
          );
        }),
        map((data) => data.data)
      );
  }

  @Action(LoadPotentialUntranslatablesContext)
  public LoadPotentialUntranslatablesContext(
    ctx: StateContext<PotentialUntranslatablesStateModel>,
    action: LoadPotentialUntranslatablesContext
  ): Observable<SearchInReportFilesResultItem[]> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.dispatch(new PatchPotentialUntranslatables(projectId, { context: [] }));
    return this.translationUnitsService
      .apiTranslationUnitsSearchPost$Json({
        body: {
          projectId,
          searchMode: SearchMode.SourceNotTarget,
          targetOptions: null,
          sourceOptions: {
            searchType: SearchType.FullText,
            caseSensitive: false,
            pattern: action.text,
          },
        },
      })
      .pipe(
        map((data) => this.searchInProjectFilesResultItemMapper.mapItems(data)),
        tap((data) => ctx.dispatch(new PatchPotentialUntranslatables(projectId, { context: data })))
      );
  }

  @Action(PatchPotentialUntranslatables)
  public patchPotentialUntranslatables(
    ctx: StateContext<StateModelByProject<PotentialUntranslatablesStateModel>>,
    action: PatchPotentialUntranslatables
  ): void {
    const state = ctx.getState() || {};
    const stateByProject = state[action.projectId] || {
      showPotentialUntranslatables: true,
      list: [],
      count: 0,
      context: [],
    };
    ctx.patchState({
      [action.projectId]: { ...stateByProject, ...action.state },
    });
  }

  @Action(ShowPotentialUntranslatables)
  public hidePotentialUntranslatables(
    ctx: StateContext<StateModelByProject<PotentialUntranslatablesStateModel>>,
    action: ShowPotentialUntranslatables
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.dispatch(new PatchPotentialUntranslatables(projectId, { showPotentialUntranslatables: action.payload }));
  }

  @Action(RemovePotentialUntranslatables)
  public removePotentialUntranslatables(
    ctx: StateContext<StateModelByProject<PotentialUntranslatablesStateModel>>,
    action: RemovePotentialUntranslatables
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          list: removeItems<PotentialUntranslatableItem>((item) => action.items.includes(item.text)),
          count: decrement(action.items.length),
        }),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(
    ctx: StateContext<StateModelByProject<PotentialUntranslatablesStateModel>>,
    action: CleanStoredProjectInfo
  ): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
