<form *ngIf="filterForm" class="protection-filter-form" [formGroup]="filterForm">
  <div class="header">
    <app-ui-search-input *ngIf="searchable" [formControl]="searchText" placeholder="Search..."></app-ui-search-input>
    <mat-checkbox
      *ngIf="searchMatched('select all')"
      [checked]="allFiltersEnabled"
      [indeterminate]="someFilterActive"
      (change)="toggleAllFilters($event)"
      >Select all</mat-checkbox
    >
  </div>
  <div class="body">
    <ng-container *ngIf="displayedFilterNames.length; else noMatchTemplate">
      <ng-container *ngIf="params.valueFormatterPipe; else listWithoutFormatterTemplate">
        <ng-container *ngFor="let name of displayedFilterNames">
          <mat-checkbox [formControlName]="name">{{ name | dynamicPipe: params.valueFormatterPipe }}</mat-checkbox>
        </ng-container>
      </ng-container>
      <ng-template #listWithoutFormatterTemplate>
        <ng-container *ngFor="let name of displayedFilterNames">
          <mat-checkbox [formControlName]="name">{{ name }}</mat-checkbox>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-template #noMatchTemplate>
      <div class="no-matches">{{ noFiltersText }}</div>
    </ng-template>
  </div>
  <div class="footer">
    <app-button size="sm" *ngIf="params.resetable" theme="outlined" (click)="resetFilter()">{{
      resetButtonText
    }}</app-button>
    <app-button size="sm" *ngIf="!params.autoFilterApply" theme="primary" (click)="applyFilter()">{{
      applyButtonText
    }}</app-button>
  </div>
</form>
