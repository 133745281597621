import { Segment } from '@generated/api';
import { RenderItem } from '@shared/models';

export const NO_VALUE_VIEW = '--';
export const ELLIPSIS_VIEW = '...';

export interface RowParams {
  value: string;
  data: {
    isExpatendedIdenticalRows?: boolean;
    identicalRows?: any[];
  };
}

export const isRowExpandableAndCollapsed = (params: RowParams): boolean =>
  !params.data?.isExpatendedIdenticalRows && !!params.data?.identicalRows?.length;

export const gridCellRenderer = (params: RowParams): string => params.value || NO_VALUE_VIEW;

export const gridMatchRenderer = (params: RowParams): string => {
  const groupView = identicalRowGroupRendering(params);
  if (groupView) {
    return groupView;
  }
  return !!params.value ? (isNaN(parseInt(params.value, 10)) ? params.value : params.value + '%') : NO_VALUE_VIEW;
};

export const positionCellRenderer = (params: RowParams): string => {
  const groupView = identicalRowGroupRendering(params);
  if (groupView) {
    return groupView;
  }
  return gridCellRenderer(params);
};

const identicalRowGroupRendering = (params: RowParams): string | void => {
  if (isRowExpandableAndCollapsed(params)) {
    return ELLIPSIS_VIEW;
  }
};

export const buildSegmentComporator =
  <T>(segmentGetter: (T) => Segment) =>
  (valueA: RenderItem[], valueB: RenderItem[], nodeA: { data: T }, nodeB: { data: T }) => {
    if (!nodeA?.data || !nodeB?.data) {
      return 0;
    }
    const aSegment = segmentGetter(nodeA.data);
    const bSegment = segmentGetter(nodeB.data);
    const collator = new Intl.Collator(undefined, {
      numeric: false,
      sensitivity: 'base',
    });
    return collator.compare(aSegment.originalText, bSegment.originalText);
  };
