import { Injectable } from '@angular/core';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { State, Action, Selector, createSelector, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import {
  DownloadProjectFiles,
  DeleteProjectFiles,
  LoadProjectFiles,
  ChangeProjectFileLanguages,
  ResetProjectFiles,
  ChangeProjectFilesProtection,
  SetProjectFiles,
  RemoveProjectFiles,
  LoadProjectFile,
  SetSelectedProjectFiles,
} from './project-files.actions';
import { tap, switchMap } from 'rxjs/operators';
import { SetLanguagesFromFiles } from '@store/language-store/language.actions';
import { FileId, ProjectFilesChanged, ProjectFileCreated, ProjectFilesRemoved } from '@shared/models';
import { ProjectState } from '@store/project-store/project.state';
import { SetSpellingPopupShown } from '@store/report-view-store/report-view.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import {
  Language,
  LanguageModel,
  LocalizationPlatformInfo,
  ProjectFileInfo,
  ProjectFilesService,
} from '@generated/api';
import { CommonHubService } from '@shared/services';
import { RemoveQualityIssuesByFileId } from '@store/quality-issues-store/quality-issues.actions';
import { RemoveTranslationUnitChangesByFileId } from '@store/translation-unit-changes-store/translation-unit-changes.actions';
import { RemoveSearchResultByByFileId } from '@store/search-in-report-files/search-in-report-files.actions';
import { BlobDownloadService } from '@shared/services/blob-download.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { mapItems, replaceOrAppendItem } from '@store/custom-operators';
import { AvailableLanguagesState } from '@store/available-languages';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

export class ProjectFilesStateModel {
  [projectId: string]: {
    projectFiles: ProjectFileInfo[];
    selected: string[];
    hasLocalizationPlatformFiles: boolean;
  };
}

@AsyncStorage
@StateRepository()
@State<ProjectFilesStateModel>({
  name: 'projectFiles',
  defaults: {},
})
@Injectable()
export class ProjectFilesState {
  constructor(
    private projectFiles: ProjectFilesService,
    private store: Store,
    private commonHub: CommonHubService,
    private blobDownloadService: BlobDownloadService
  ) {
    this.commonHub.watch(ProjectFilesRemoved).subscribe((data) => {
      data.projectFileIds.forEach((id) => {
        this.store.dispatch(new RemoveQualityIssuesByFileId(data.projectId, id));
        this.store.dispatch(new RemoveTranslationUnitChangesByFileId(data.projectId, id));
        this.store.dispatch(new RemoveSearchResultByByFileId(data.projectId, id));
      });
      this.store.dispatch(new RemoveProjectFiles(data.projectId, data.projectFileIds));
    });

    this.commonHub.watch(ProjectFileCreated).subscribe((data) => {
      this.store.dispatch(new LoadProjectFile(data.projectId, data.projectFileId));
    });

    this.commonHub.watch(ProjectFilesChanged).subscribe((data) => {
      const projectId = this.store.selectSnapshot(ProjectState.projectId);
      if (data.projectFileIds.length === 1) {
        this.store.dispatch(new LoadProjectFile(projectId, data.projectFileIds[0]));
        return;
      }
      this.store.dispatch(new LoadProjectFiles(projectId));
    });
  }

  @Selector([ProjectFilesState, ProjectState.projectId])
  public static projectFiles(state: ProjectFilesStateModel, projectId: string): ProjectFileInfo[] {
    return (state && state[projectId]?.projectFiles) || [];
  }

  public static fileById(fileId: FileId): (files: ProjectFileInfo[]) => ProjectFileInfo {
    return createSelector([ProjectFilesState.projectFiles], (projectFileInfos: ProjectFileInfo[]) =>
      projectFileInfos.find((files) => files.id === fileId)
    );
  }

  @Selector([ProjectFilesState, ProjectState.projectId])
  public static selected(state: ProjectFilesStateModel, projectId: string): string[] {
    return (state && state[projectId]?.selected) || [];
  }

  @Selector([ProjectFilesState.projectFiles])
  public static count(projectFileInfos: ProjectFileInfo[]): number {
    return projectFileInfos.length;
  }

  @Selector([ProjectFilesState.projectFiles])
  public static sectionsCount(projectFileInfos: ProjectFileInfo[]): number {
    if (!projectFileInfos?.length) {
      return 0;
    }
    return new Set(projectFileInfos.map((i) => `${i.languagePair?.source.code}${i.languagePair?.target.code}`)).size;
  }

  @Selector([ProjectFilesState, ProjectState.projectId])
  public static hasLocalizationPlatformFiles(state: ProjectFilesStateModel, projectId: string): boolean {
    return state && state[projectId]?.hasLocalizationPlatformFiles;
  }

  @Selector([ProjectFilesState.projectFiles])
  public static getLocalizationPlatform(projectFiles: ProjectFileInfo[]): LocalizationPlatformInfo {
    if (!projectFiles.length) {
      return null;
    }
    return projectFiles[0].localizationPlatform;
  }

  @Selector([ProjectFilesState.projectFiles])
  public static isMultilanguageProject(projectFiles: ProjectFileInfo[]): boolean {
    return new Set(projectFiles.map((pf) => pf.languagePair?.target.code)).size > 1;
  }

  @Action(LoadProjectFiles)
  public loadProjectFiles(
    ctx: StateContext<ProjectFilesStateModel>,
    action: LoadProjectFiles
  ): Observable<ProjectFileInfo[]> {
    return this.projectFiles
      .apiProjectsProjectIdProjectFilesGet$Json({
        projectId: action.projectId,
      })
      .pipe(
        tap((projectFiles) => {
          ctx.dispatch(new SetProjectFiles(action.projectId, projectFiles));
        })
      );
  }

  @Action(LoadProjectFile)
  public LoadProjectFile(
    ctx: StateContext<ProjectFilesStateModel>,
    action: LoadProjectFile
  ): Observable<ProjectFileInfo> {
    return this.projectFiles
      .apiProjectsProjectIdProjectFilesIdGet$Json({
        projectId: action.projectId,
        id: action.fileId,
      })
      .pipe(
        tap((projectFile) => {
          const projectId = this.store.selectSnapshot(ProjectState.projectId);
          ctx.setState(
            patch({
              [projectId]: patch({
                projectFiles: replaceOrAppendItem<ProjectFileInfo>((file) => file.id === action.fileId, projectFile),
              }),
            })
          );
          ctx.dispatch(new SetLanguagesFromFiles(ctx.getState()[projectId].projectFiles));
        })
      );
  }

  @Action(SetProjectFiles)
  public addProjectFiles(ctx: StateContext<ProjectFilesStateModel>, action: SetProjectFiles): void {
    const state = ctx.getState() || {};
    const hasLocalizationPlatformFiles = this.checkHasLocalizationPlatformsFiles(action.files);
    ctx.patchState({
      [action.projectId]: {
        ...state[action.projectId],
        projectFiles: action.files,
        hasLocalizationPlatformFiles,
      },
    });
    ctx.dispatch(new SetLanguagesFromFiles(action.files));
  }

  @Action(ChangeProjectFileLanguages)
  public changeProjectFileLanguages(
    ctx: StateContext<ProjectFilesStateModel>,
    action: ChangeProjectFileLanguages
  ): Observable<string[]> {
    this.store.dispatch(new SetSpellingPopupShown(false));
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    return this.projectFiles
      .apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json({
        projectId,
        body: {
          filesIds: action.fileIds,
          sourceLanguageId: action.sourceLanguageId,
          targetLanguageId: action.targetLanguageId,
        },
      })
      .pipe(
        tap(() => {
          const sourceLanguage = this.convertLanguage(
            this.store.selectSnapshot(AvailableLanguagesState.languageById(action.sourceLanguageId))
          );
          const targetLanguage = this.convertLanguage(
            this.store.selectSnapshot(AvailableLanguagesState.languageById(action.targetLanguageId))
          );
          ctx.setState(
            patch({
              [projectId]: patch({
                projectFiles: mapItems<ProjectFileInfo>((file) => {
                  if (action.fileIds.includes(file.id)) {
                    return {
                      ...file,
                      sourceLanguageId: action.sourceLanguageId,
                      sourceLanguage,
                      targetLanguageId: action.targetLanguageId,
                      targetLanguage,
                      languagePair: {
                        source: sourceLanguage,
                        target: targetLanguage,
                      },
                    };
                  }
                  return file;
                }),
              }),
            })
          );
          ctx.dispatch(new SetLanguagesFromFiles(ctx.getState()[projectId].projectFiles));
        })
      );
  }

  @Action(DeleteProjectFiles)
  public delete(ctx: StateContext<ProjectFilesStateModel>): Observable<void> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const fileIds = this.store.selectSnapshot(ProjectFilesState.selected);
    return this.projectFiles
      .apiProjectFilesIdDelete({
        id: projectId,
        body: { fileIds },
      })
      .pipe(switchMap(() => ctx.dispatch(new RemoveProjectFiles(projectId, fileIds))));
  }

  @Action(ChangeProjectFilesProtection, { cancelUncompleted: true })
  public protect(
    ctx: StateContext<ProjectFilesStateModel>,
    { fileIds, isProtected }: ChangeProjectFilesProtection
  ): Observable<string[]> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState() || {};
    let projectFiles = state[projectId]?.projectFiles || [];
    projectFiles = projectFiles.map((file) => ({
      ...file,
      isProtected: fileIds.includes(file.id) ? isProtected : file.isProtected,
    }));
    ctx.patchState({
      [projectId]: {
        ...(state[projectId] || {
          selected: [],
          hasLocalizationPlatformFiles: false,
        }),
        projectFiles,
      },
    });
    return this.projectFiles.apiProjectsProjectIdProjectFilesProtectedPost$Json({
      projectId,
      body: { ids: fileIds, isProtected },
    });
  }

  @Action(DownloadProjectFiles)
  public download(): Observable<HttpResponse<Blob>> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const fileIds = this.store.selectSnapshot(ProjectFilesState.selected);
    return this.projectFiles
      .apiProjectFilesDownloadPost$Response({
        body: {
          projectId,
          fileIds,
        },
      })
      .pipe(
        tap((rspns: HttpResponse<Blob>) => {
          this.blobDownloadService.downloadFromResponse(rspns);
        })
      );
  }

  @Action(ResetProjectFiles)
  public resetProjectFiles(ctx: StateContext<ProjectFilesStateModel>): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState({
      [projectId]: {
        projectFiles: [],
        selected: [],
        hasLocalizationPlatformFiles: false,
      },
    });
  }

  @Action(RemoveProjectFiles)
  public removeProjectFiles(
    ctx: StateContext<ProjectFilesStateModel>,
    { projectId, fileIds }: RemoveProjectFiles
  ): void {
    const state = ctx.getState() || {};
    const projectFiles = state[projectId]?.projectFiles.filter((a) => !fileIds.includes(a.id)) || [];
    const selected = state[projectId]?.selected?.filter((id) => !fileIds.includes(id)) || [];
    const hasLocalizationPlatformFiles = this.checkHasLocalizationPlatformsFiles(projectFiles);

    ctx.patchState({
      [projectId]: {
        ...state[projectId],
        projectFiles,
        selected,
        hasLocalizationPlatformFiles,
      },
    });
    ctx.dispatch(new SetLanguagesFromFiles(projectFiles));
  }

  @Action(SetSelectedProjectFiles)
  public setSelectedProjectFiles(
    ctx: StateContext<ProjectFilesStateModel>,
    { fileIds }: SetSelectedProjectFiles
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          selected: fileIds,
        }),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<ProjectFilesStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }

  private checkHasLocalizationPlatformsFiles(files: ProjectFileInfo[]): boolean {
    if (!files || !files.length) {
      return false;
    }
    return files.map((file) => file.localizationPlatform).some((platform) => platform.name !== 'Verifika');
  }

  private convertLanguage(langModel: LanguageModel): Language {
    return {
      id: langModel.id,
      code: langModel.code,
      name: langModel.name,
      rightToLeft: langModel.rightToLeft,
      parentId: null,
      parent: null,
    };
  }
}
