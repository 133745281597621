<app-ui-dropdown-menu
  *ngIf="(qaSettingsList$ | async)?.length"
  #profileDropdown
  xPosition="after"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  popupClass="header-profiles-popup"
  triggerPlacement="left"
  [disabled]="!(app.online$ | async)"
  data-test-id="profiles-menu"
>
  <div dropdown-label class="dropdown-label">
    <app-spinner *ngIf="profileChanging" class="loader" [size]="spinnerSize.Small"></app-spinner>
    <app-ui-text-editable
      #textEditable="appTextEditable"
      [appDisableControl]="!(app.online$ | async)"
      appTooltip="Profile contains changes. If you want to save them, click Save in the menu."
      [appTooltipDisplay]="!(textEditable.isEdit$ | async) && (!qaSettings?.template || (qaSettingsHasChanges | async))"
      appTooltipPlacement="right"
      [formControl]="profileNameControl"
      [showMarker]="qaSettings?.template && (qaSettingsHasChanges | async)"
      [errors]="{
        exist: 'Profile with such a name already exists. Please use another name.'
      }"
      class="dropdown-menu-text-editable"
      placeholder="Enter profile name"
      size="lg"
      (startEditing)="startEditingName()"
      (submitText)="renameProfile($event)"
    >
    </app-ui-text-editable>
  </div>

  <app-action-icon
    dropdown-trigger
    [active]="profileDropdown.isOpen"
    class="trigger-icon"
    icon="more-vert"
    theme="circle"
    size="lg"
  ></app-action-icon>

  <div dropdown-content class="container" [style.width.px]="popupWidth" appDropdownMenuPreventClick>
    <div class="header">
      <app-button (clickButton)="createNew()" class="new-profile-btn" theme="outlined">New</app-button>
      <app-ui-file-chooser theme="outlined" [accepted]="['.vprofile']" (selectFiles)="upload($event)">
        Upload
      </app-ui-file-chooser>
      <app-action-icon
        class="profile-version"
        theme="circle"
        icon="error"
        size="xl"
        appTooltip="Currently Verifika Web supports profiles created in Verifika Desktop 3.2.5 or newer versions. Support for the rest versions will be added soon."
        [appTooltipShowDelay]="0"
        appTooltipPlacement="bottom-right"
      ></app-action-icon>
    </div>

    <div class="selected">
      <div class="selected-title">
        Selected profile
        <span *ngIf="!qaSettings?.template"> (deleted) </span>
        <span *ngIf="(qaSettingsHasChanges | async) && qaSettings?.template"> (contains unsaved changes) </span>
      </div>
      <div class="item">
        <div
          class="item-content selected-item-content"
          appTooltip="Default Profile"
          appTooltipPlacement="left"
          [appTooltipDisplay]="qaSettings?.template?.isDefault"
          [appTooltipShowDelay]="0"
        >
          <app-ui-icon
            size="small"
            [icon]="qaSettings?.template?.isDefault ? 'insert-drive-file' : 'v-profile'"
            class="item-icon"
          ></app-ui-icon>
          <span class="item-text" [class.has-changes]="qaSettings?.template && (qaSettingsHasChanges | async)">{{
            qaSettings?.name
          }}</span>
        </div>
        <app-button
          (clickButton)="save()"
          [disabled]="qaSettings?.template && !(qaSettingsHasChanges | async)"
          appTooltip="Save"
          [appTooltipShowDelay]="0"
          appTooltipPlacement="top"
          theme="primary"
          class="item-action save-btn"
          >Save</app-button
        >
        <app-action-icon
          size="xl"
          [matMenuTriggerFor]="selectedMenu"
          icon="more-vert"
          theme="subtle"
          class="item-action"
        ></app-action-icon>
        <div class="item-actions"></div>
      </div>
    </div>

    <div class="search">
      <app-ui-search-input [formControl]="profileSearchControl" size="large"></app-ui-search-input>
    </div>

    <div class="profile-list" [class.show-all]="isShowAll$ | async">
      <ng-container *ngIf="(qaSettingsListView$ | async).length; else emptyList">
        <ng-container *ngFor="let profile of qaSettingsListView$ | async">
          <div (click)="select(profile)" class="item hoverable" mat-menu-item>
            <div
              class="item-content"
              appTooltip="Default Profile"
              [appTooltipDisplay]="profile?.isDefault"
              appTooltipPlacement="left"
              [appTooltipShowDelay]="0"
            >
              <app-ui-icon
                size="small"
                class="item-icon"
                [icon]="profile.isDefault ? 'insert-drive-file' : 'v-profile'"
              ></app-ui-icon>
              <span class="item-text" [innerHTML]="profile.name | markFiltered: profileSearchControl.value"></span>
            </div>

            <app-action-icon
              (click)="preventClick($event)"
              [matMenuTriggerFor]="subMenu"
              [matMenuTriggerData]="{ profile: profile }"
              theme="subtle"
              icon="more-vert"
              size="xl"
              class="item-action"
            ></app-action-icon>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #emptyList>
        <div class="empty-list">No matches</div>
      </ng-template>
    </div>

    <div *ngIf="(qaSettingsListView$ | async)?.length > 5" class="profile-more" (click)="toggleShowAll()">
      <app-ui-icon size="small" [icon]="(isShowAll$ | async) ? 'expand' : 'collapse'"></app-ui-icon>
    </div>
  </div>
</app-ui-dropdown-menu>

<mat-menu #selectedMenu="matMenu" class="menu-panel">
  <ng-template matMenuContent let-profile="profile">
    <div (click)="resetSettings()" class="list-item" role="button">
      <app-ui-icon class="list-item__icon" icon="settings" size="small"></app-ui-icon>
      <span>Reset settings to default</span>
    </div>
    <div (click)="clearAllSettings()" class="list-item" role="button">
      <app-ui-icon class="list-item__icon" size="small" icon="insert-drive-file-mod"></app-ui-icon>
      <span>Clear all settings</span>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu" class="menu-panel">
  <ng-template matMenuContent let-profile="profile">
    <div (click)="setAsDefault(profile)" class="list-item" role="button">
      <app-ui-icon class="list-item__icon" size="small" icon="insert-drive-file"></app-ui-icon>
      <span>Set as default</span>
    </div>
    <div (click)="makeCopy(profile)" class="list-item" role="button">
      <app-ui-icon class="list-item__icon" size="small" icon="copy"></app-ui-icon>
      <span>Make a copy</span>
    </div>
    <div
      (click)="delete(profile)"
      *ngIf="(qaSettingsListView$ | async).length > 1 && !profile.isDefault"
      class="list-item list-item--danger"
      role="button"
    >
      <app-ui-icon class="list-item__icon" size="small" icon="delete-outline"></app-ui-icon>
      <span>Delete</span>
    </div>
  </ng-template>
</mat-menu>
