import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { trackById } from '@shared/tools';
import { LanguageState } from '@store/language-store';
import { Store } from '@ngxs/store';
import { AvailableLanguagesState } from '@store/available-languages';
import { LanguageModel } from '@generated/api';

interface LanguageItems extends LanguageModel {
  disabled?: boolean;
  group?: string;
}

/**
 * Specific language component.
 * Show the list of available languages.
 * In case project files are added, will show target and source languages in special section
 *
 *
 * Usage:
 * ````
 * <app-ui-specific-language [formControl]="langControl"></app-ui-specific-language>
 * <app-ui-specific-language [(ngModel)]="lang"></app-ui-specific-language>
 * <app-ui-specific-language [value]="null" (changeValue)="handleChange($event)"></app-ui-specific-language>
 * ````
 */

@Component({
  selector: 'app-ui-specific-language',
  templateUrl: './specific-language.component.html',
  styleUrls: ['./specific-language.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecificLanguageComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificLanguageComponent implements OnInit, OnDestroy {
  private _disabledLanguage: LanguageModel;
  private languagesMap: { [key: number]: LanguageModel } = {};
  private destroyed$: Subject<void> = new Subject<void>();
  public disabled = false;
  private targetLanguages: LanguageModel[] = [];
  private sourceLanguages: LanguageModel[] = [];
  private availableLanguages: LanguageModel[] = [];
  public languageItems: LanguageItems[] = [];
  public trackById = trackById;

  @Input() tabIndex: number = 0;

  @Input() label = 'Language specific';

  @Input() placeholder = 'Select a language...';

  @Input() set disabledLanguage(value: LanguageModel) {
    this._disabledLanguage = value;
    this.prepareLanguageItems();
  }
  get disabledLanguage(): LanguageModel {
    return this._disabledLanguage;
  }

  @Input() value: LanguageModel = null;
  @Output() valueChange = new EventEmitter<LanguageModel>();

  get selectedId(): number {
    return this.value?.id;
  }

  set selectedId(id: number) {
    this.value = this.languagesMap[id];
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  private prepareLanguageItems(): void {
    this.languageItems = [];
    this.languageItems.push(
      ...this.sourceLanguages.map((item) => ({
        ...item,
        group: 'Source languages',
        disabled: item.id === this.disabledLanguage?.id,
      }))
    );
    this.languageItems.push(
      ...this.targetLanguages.map((item) => ({
        ...item,
        group: 'Target languages',
        disabled: item.id === this.disabledLanguage?.id,
      }))
    );
    this.languageItems.push(
      ...this.availableLanguages.map((item) => ({
        ...item,
        group: this.languageItems.length ? 'Other' : '',
        disabled: item.id === this.disabledLanguage?.id,
      }))
    );
    this.languagesMap = {};
    this.languageItems.forEach((item) => (this.languagesMap[item.id] = item));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  onChange: (_: LanguageModel) => void;

  // region ControlValueAccessor feature

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(value: LanguageModel): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initSubscriptions(): void {
    this.store
      .select(AvailableLanguagesState.languages)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((list) => {
        this.availableLanguages = list;
        this.prepareLanguageItems();
      });

    this.store
      .select(LanguageState.sourceLanguages)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((list) => {
        this.sourceLanguages = list;
        this.prepareLanguageItems();
      });

    this.store
      .select(LanguageState.targetLanguages)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((list) => {
        this.targetLanguages = list;
        this.prepareLanguageItems();
      });
  }

  public valueChanged(id: number): void {
    this.selectedId = id;
    this.propagateChanges();
  }

  private propagateChanges(): void {
    this.valueChange.emit(this.value);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  // endregion
}
