import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { GridApi, RowNode, ICellEditorParams, ICellEditorAngularComp } from 'src/types/ag-grid';
import { Column } from '@ag-grid-community/core';
import { GridHelperService } from '@shared/services/grid-helper.service';

@Component({
  template: '',
})
export abstract class BaseCellEditorComponent<T> implements ICellEditorAngularComp, OnDestroy {
  destroyed$: Subject<void> = new Subject<void>();
  rowData: T;
  rowNode: RowNode;
  gridApi: GridApi;
  column: Column;
  abstract control: FormControl;
  abstract errorDescriptions: { [key: string]: string };
  protected abstract readonly gridHelperService: GridHelperService;

  public agInit(params: ICellEditorParams): void {
    this.gridHelperService.initApi(params.api, params.columnApi);
    this.rowData = params.data;
    this.gridApi = params.api;
    this.rowNode = params.node;
    this.column = params.column;
    this.control.setValue(params.value);

    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  protected abstract initSubscriptions(): void;

  public getValue(): string {
    return this.control.value;
  }

  protected getError(): string {
    const errors = Object.keys(this.control.errors || []);

    if (!errors || !errors.length) {
      return '';
    }
    return this.errorDescriptions[errors[0]] || errors[0];
  }

  protected getTableDataExceptCurrentRow(): Array<T> {
    return this.getRows()
      .filter((item) => this.rowNode !== item)
      .map((item) => item.data);
  }

  protected getRows(): RowNode[] {
    const rows = [];

    if (!this.gridApi) {
      return [];
    }

    this.gridApi.forEachNode((node: RowNode) => {
      rows.push(node);
    });
    return rows;
  }
}
