/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttachFilesModel } from '../models/attach-files-model';
import { AuthenticationModel } from '../models/authentication-model';
import { LocalizationPlatformConnectionModel } from '../models/localization-platform-connection-model';
import { LocalizationPlatformFileModel } from '../models/localization-platform-file-model';
import { LocalizationPlatformModel } from '../models/localization-platform-model';
import { LocalizationPlatformProjectModel } from '../models/localization-platform-project-model';
import { UploadFileModel } from '../models/upload-file-model';

@Injectable({
  providedIn: 'root',
})
export class LocalizationPlatformsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLocalizationPlatformsGet
   */
  static readonly ApiLocalizationPlatformsGetPath = '/api/LocalizationPlatforms';

  /**
   * Getting all available localization platforms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsGet$Plain$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformModel>>;
      })
    );
  }

  /**
   * Getting all available localization platforms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsGet$Plain(params?: {
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformModel>> {

    return this.apiLocalizationPlatformsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformModel>>) => r.body as Array<LocalizationPlatformModel>)
    );
  }

  /**
   * Getting all available localization platforms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsGet$Json$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformModel>>;
      })
    );
  }

  /**
   * Getting all available localization platforms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsGet$Json(params?: {
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformModel>> {

    return this.apiLocalizationPlatformsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformModel>>) => r.body as Array<LocalizationPlatformModel>)
    );
  }

  /**
   * Path part for operation apiLocalizationPlatformsPlatformIdConnectPost
   */
  static readonly ApiLocalizationPlatformsPlatformIdConnectPostPath = '/api/LocalizationPlatforms/{platformId}/connect';

  /**
   * Connect to localization platform using user's credentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsPlatformIdConnectPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsPlatformIdConnectPost$Plain$Response(params: {

    /**
     * Localization platform identifier
     */
    platformId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Integrations.Abstractions.Models.AuthenticationModel>Authentication model
     */
    body?: AuthenticationModel
  }): Observable<StrictHttpResponse<LocalizationPlatformConnectionModel>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsPlatformIdConnectPostPath, 'post');
    if (params) {
      rb.path('platformId', params.platformId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocalizationPlatformConnectionModel>;
      })
    );
  }

  /**
   * Connect to localization platform using user's credentials.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsPlatformIdConnectPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsPlatformIdConnectPost$Plain(params: {

    /**
     * Localization platform identifier
     */
    platformId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Integrations.Abstractions.Models.AuthenticationModel>Authentication model
     */
    body?: AuthenticationModel
  }): Observable<LocalizationPlatformConnectionModel> {

    return this.apiLocalizationPlatformsPlatformIdConnectPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocalizationPlatformConnectionModel>) => r.body as LocalizationPlatformConnectionModel)
    );
  }

  /**
   * Connect to localization platform using user's credentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsPlatformIdConnectPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsPlatformIdConnectPost$Json$Response(params: {

    /**
     * Localization platform identifier
     */
    platformId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Integrations.Abstractions.Models.AuthenticationModel>Authentication model
     */
    body?: AuthenticationModel
  }): Observable<StrictHttpResponse<LocalizationPlatformConnectionModel>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsPlatformIdConnectPostPath, 'post');
    if (params) {
      rb.path('platformId', params.platformId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocalizationPlatformConnectionModel>;
      })
    );
  }

  /**
   * Connect to localization platform using user's credentials.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsPlatformIdConnectPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsPlatformIdConnectPost$Json(params: {

    /**
     * Localization platform identifier
     */
    platformId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Integrations.Abstractions.Models.AuthenticationModel>Authentication model
     */
    body?: AuthenticationModel
  }): Observable<LocalizationPlatformConnectionModel> {

    return this.apiLocalizationPlatformsPlatformIdConnectPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocalizationPlatformConnectionModel>) => r.body as LocalizationPlatformConnectionModel)
    );
  }

  /**
   * Path part for operation apiLocalizationPlatformsConnectionsConnectionIdProjectsGet
   */
  static readonly ApiLocalizationPlatformsConnectionsConnectionIdProjectsGetPath = '/api/LocalizationPlatforms/connections/{connectionId}/projects';

  /**
   * Getting a description of all projects from the localization platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Plain$Response(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformProjectModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsConnectionsConnectionIdProjectsGetPath, 'get');
    if (params) {
      rb.path('connectionId', params.connectionId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformProjectModel>>;
      })
    );
  }

  /**
   * Getting a description of all projects from the localization platform.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Plain(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformProjectModel>> {

    return this.apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformProjectModel>>) => r.body as Array<LocalizationPlatformProjectModel>)
    );
  }

  /**
   * Getting a description of all projects from the localization platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json$Response(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformProjectModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsConnectionsConnectionIdProjectsGetPath, 'get');
    if (params) {
      rb.path('connectionId', params.connectionId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformProjectModel>>;
      })
    );
  }

  /**
   * Getting a description of all projects from the localization platform.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformProjectModel>> {

    return this.apiLocalizationPlatformsConnectionsConnectionIdProjectsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformProjectModel>>) => r.body as Array<LocalizationPlatformProjectModel>)
    );
  }

  /**
   * Path part for operation apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet
   */
  static readonly ApiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGetPath = '/api/LocalizationPlatforms/connections/{connectionId}/projects/{projectId}/files';

  /**
   * Getting a description of all files from the project from the localization platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Plain$Response(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformFileModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGetPath, 'get');
    if (params) {
      rb.path('connectionId', params.connectionId, {});
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformFileModel>>;
      })
    );
  }

  /**
   * Getting a description of all files from the project from the localization platform.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Plain(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformFileModel>> {

    return this.apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformFileModel>>) => r.body as Array<LocalizationPlatformFileModel>)
    );
  }

  /**
   * Getting a description of all files from the project from the localization platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json$Response(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<LocalizationPlatformFileModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGetPath, 'get');
    if (params) {
      rb.path('connectionId', params.connectionId, {});
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocalizationPlatformFileModel>>;
      })
    );
  }

  /**
   * Getting a description of all files from the project from the localization platform.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json(params: {

    /**
     * Localization platform connection identifier
     */
    connectionId: string;

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<Array<LocalizationPlatformFileModel>> {

    return this.apiLocalizationPlatformsConnectionsConnectionIdProjectsProjectIdFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocalizationPlatformFileModel>>) => r.body as Array<LocalizationPlatformFileModel>)
    );
  }

  /**
   * Path part for operation apiLocalizationPlatformsFilesAttachtoprojectPost
   */
  static readonly ApiLocalizationPlatformsFilesAttachtoprojectPostPath = '/api/LocalizationPlatforms/files/attachtoproject';

  /**
   * Download bilingual files from the localization platform and attach to the project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsFilesAttachtoprojectPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsFilesAttachtoprojectPost$Response(params?: {
    'api-version'?: string;

    /**
     * List of file identifiers
     */
    body?: AttachFilesModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsFilesAttachtoprojectPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download bilingual files from the localization platform and attach to the project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsFilesAttachtoprojectPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsFilesAttachtoprojectPost(params?: {
    'api-version'?: string;

    /**
     * List of file identifiers
     */
    body?: AttachFilesModel
  }): Observable<void> {

    return this.apiLocalizationPlatformsFilesAttachtoprojectPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiLocalizationPlatformsFileUploadPut
   */
  static readonly ApiLocalizationPlatformsFileUploadPutPath = '/api/LocalizationPlatforms/file/upload';

  /**
   * Upload bilingual files to localization platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocalizationPlatformsFileUploadPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsFileUploadPut$Response(params?: {
    'api-version'?: string;

    /**
     * Upload file model
     */
    body?: UploadFileModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationPlatformsService.ApiLocalizationPlatformsFileUploadPutPath, 'put');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload bilingual files to localization platform.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocalizationPlatformsFileUploadPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocalizationPlatformsFileUploadPut(params?: {
    'api-version'?: string;

    /**
     * Upload file model
     */
    body?: UploadFileModel
  }): Observable<void> {

    return this.apiLocalizationPlatformsFileUploadPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
