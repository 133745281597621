import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import type { AccountRole } from '@shared/models';
import { AuthState } from '@store/auth/auth.state';

@Directive({
  selector: '[whenRole]',
})
export class RoleDirective implements OnInit {
  #whenRole: AccountRole | AccountRole[];

  @Input()
  set whenRole(val: AccountRole | AccountRole[]) {
    this.#whenRole = val;
    this.#renderTemplate();
  }

  @Input()
  public whenRoleElse?: TemplateRef<unknown>;

  get #roleSatisfied(): boolean {
    return this.store.selectSnapshot(AuthState.hasRole(this.#whenRole));
  }

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly vcr: ViewContainerRef,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.#renderTemplate();
  }

  #renderTemplate(): void {
    this.vcr.clear();
    if (this.#roleSatisfied) {
      this.vcr.createEmbeddedView(this.templateRef);
      return;
    }

    if (!this.whenRoleElse) {
      return;
    }
    this.vcr.createEmbeddedView(this.whenRoleElse);
  }
}
