import { ReportGridsStateModel } from './report-grids-state.model';
import { SortDirection, ReportTab } from '@shared/models';

// TODO VW-1276: заменить строковые литералы на field поле из описания колонок
// добавить keyof тип для каждого списка из колонок отчета
//
// <2022-06-14 Tue> Этот стейт нужно перенести в модуль ag-grid.
// Т.к. сохранение состояния таблиц необходимо не только для отчетов
// но и для всех таблиц в проекте, включая те, которые еще не созданы.
// https://verifika.youtrack.cloud/issue/VW-1081
export const REPORT_GRIDS_DEFAULT_STATE: ReportGridsStateModel = {
  [ReportTab.Formal]: {
    columns: [
      {
        colId: 'targetLanguage',
      },
      {
        colId: 'isIgnored',
      },
      {
        colId: 'protected',
      },
      {
        colId: 'source',
        sort: SortDirection.ASC,
      },
      {
        colId: 'target',
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'position',
      },
      {
        colId: 'status',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'comment',
        hide: true,
      },
      {
        colId: 'recordId',
        hide: true,
      },
      {
        colId: 'issueKind',
        hide: true,
      },
      {
        colId: 'resource',
        hide: true,
      },
      {
        colId: 'reportId',
        hide: true,
      },
    ],
  },
  [ReportTab.Consistency]: {
    columns: [
      {
        colId: 'targetLanguage',
      },
      {
        colId: 'isIgnored',
      },
      {
        colId: 'protected',
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
        sort: SortDirection.ASC,
      },
      {
        colId: 'identicalRows',
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'position',
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'status',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'comment',
        hide: true,
      },
      {
        colId: 'recordId',
        hide: true,
      },
      {
        colId: 'issueKind',
        hide: true,
      },
      {
        colId: 'subGroup',
        hide: true,
      },
    ],
  },
  [ReportTab.Terminology]: {
    columns: [
      {
        colId: 'targetLanguage',
      },
      {
        colId: 'isIgnored',
      },
      {
        colId: 'protected',
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
        sort: SortDirection.ASC,
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'position',
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'status',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'comment',
        hide: true,
      },
      {
        colId: 'recordId',
        hide: true,
      },
      {
        colId: 'additionalData.potentialWordForm',
        hide: true,
      },
      {
        colId: 'sourceTerm',
        hide: true,
      },
      {
        colId: 'additionalData.term.name',
        hide: true,
      },
      {
        colId: 'issueKind',
        hide: true,
      },
      {
        colId: 'resource',
        hide: true,
      },
      {
        colId: 'reportId',
        hide: true,
      },
    ],
  },
  [ReportTab.Spelling]: {
    columns: [
      {
        colId: 'targetLanguage',
      },
      {
        colId: 'isIgnored',
      },
      {
        colId: 'protected',
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
        sort: SortDirection.ASC,
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'position',
      },
      {
        colId: 'status',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'comment',
        hide: true,
      },
      {
        colId: 'recordId',
        hide: true,
      },
      {
        colId: 'issueKind',
        hide: true,
      },
      {
        colId: 'resource',
        hide: true,
      },
      {
        colId: 'reportId',
        hide: true,
      },
      {
        colId: 'subGroup',
        hide: true,
      },
    ],
  },
  [ReportTab.Custom]: {
    columns: [
      {
        colId: 'targetLanguage',
      },
      {
        colId: 'isIgnored',
      },
      {
        colId: 'protected',
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
        sort: SortDirection.ASC,
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'position',
      },
      {
        colId: 'status',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'comment',
        hide: true,
      },
      {
        colId: 'recordId',
        hide: true,
      },
      {
        colId: 'issueKind',
        hide: true,
      },
      {
        colId: 'resource',
        hide: true,
      },
      {
        colId: 'reportId',
        hide: true,
      },
    ],
  },
  [ReportTab.Search]: {
    columns: [
      {
        colId: 'protected',
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
        sort: SortDirection.ASC,
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'position',
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'targetLanguage',
        hide: true,
      },
    ],
  },
  [ReportTab.History]: {
    columns: [
      {
        colId: 'selected',
      },
      {
        colId: 'createdOn',
        sort: SortDirection.DESC,
      },
      {
        colId: 'protected',
      },
      {
        colId: 'issueTypeName',
        hide: true,
      },
      {
        colId: 'source',
      },
      {
        colId: 'target',
      },
      {
        colId: 'changedRenderItems',
      },
      {
        colId: 'position',
      },
      {
        colId: 'fileName',
      },
      {
        colId: 'match',
        hide: true,
      },
      {
        colId: 'sourceLanguage',
        hide: true,
      },
      {
        colId: 'targetLanguage',
        hide: true,
      },
      {
        colId: 'status',
        hide: true,
      },
    ],
  },
};
