import { Directive, ElementRef, HostListener } from '@angular/core';
import { BaseTooltipTrigger } from '@shared/components/tooltip/base-tooltip-trigger';
import { TooltipService } from '@shared/components/tooltip/tooltip.service';

/**
 * Show tooltip for input element
 */
@Directive({
  selector: '[appTooltipInput]',
  providers: [TooltipService],
})
export class TooltipInputDirective extends BaseTooltipTrigger {
  constructor(protected elementRef: ElementRef, public tooltipService: TooltipService) {
    super();
  }

  @HostListener('focusin', ['$event'])
  @HostListener('mouseenter', ['$event'])
  public onMouseEnter(event): void {
    if (!this.appTooltipDisplay) {
      return;
    }

    const element = this.elementRef?.nativeElement;
    if (!element || document.activeElement === element) {
      return;
    }
    const hasNoScrollbar = element.scrollWidth <= element.clientWidth; // true if no scrollbar
    if (hasNoScrollbar) {
      return;
    }
    this.tooltipText = this.elementRef.nativeElement.value;
    this.load(event);
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hideTooltip();
  }
}
