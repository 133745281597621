/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplySegmentToTranslationUnitsRequest } from '../models/apply-segment-to-translation-units-request';
import { ApplyTranslationUnitsRequest } from '../models/apply-translation-units-request';
import { CommentQualityIssuesCommand } from '../models/comment-quality-issues-command';
import { FixQualityIssueTranslationUnitRequest } from '../models/fix-quality-issue-translation-unit-request';
import { GetQualityIssuesResponseModel } from '../models/get-quality-issues-response-model';
import { IgnoreQualityIssuesRequest } from '../models/ignore-quality-issues-request';
import { Operation } from '../models/operation';
import { QualityIssueInfo } from '../models/quality-issue-info';
import { QualityIssueKind } from '../models/quality-issue-kind';
import { QualityIssueModel } from '../models/quality-issue-model';
import { RecheckTranslationUnitRequest } from '../models/recheck-translation-unit-request';
import { UpdateAndRecheckTranslationUnitsRequest } from '../models/update-and-recheck-translation-units-request';

@Injectable({
  providedIn: 'root',
})
export class QualityIssuesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiQualityIssuesGet
   */
  static readonly ApiQualityIssuesGetPath = '/api/QualityIssues';

  /**
   * Gets quality issues.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesGet$Plain$Response(params?: {

    /**
     * Report ID
     */
    reportId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<GetQualityIssuesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesGetPath, 'get');
    if (params) {
      rb.query('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetQualityIssuesResponseModel>;
      })
    );
  }

  /**
   * Gets quality issues.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesGet$Plain(params?: {

    /**
     * Report ID
     */
    reportId?: string;
    'api-version'?: string;
  }): Observable<GetQualityIssuesResponseModel> {

    return this.apiQualityIssuesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetQualityIssuesResponseModel>) => r.body as GetQualityIssuesResponseModel)
    );
  }

  /**
   * Gets quality issues.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesGet$Json$Response(params?: {

    /**
     * Report ID
     */
    reportId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<GetQualityIssuesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesGetPath, 'get');
    if (params) {
      rb.query('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetQualityIssuesResponseModel>;
      })
    );
  }

  /**
   * Gets quality issues.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesGet$Json(params?: {

    /**
     * Report ID
     */
    reportId?: string;
    'api-version'?: string;
  }): Observable<GetQualityIssuesResponseModel> {

    return this.apiQualityIssuesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetQualityIssuesResponseModel>) => r.body as GetQualityIssuesResponseModel)
    );
  }

  /**
   * Path part for operation apiQualityIssuesGetByIdsPost
   */
  static readonly ApiQualityIssuesGetByIdsPostPath = '/api/QualityIssues/getByIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesGetByIdsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesGetByIdsPost$Plain$Response(params?: {
    'api-version'?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<QualityIssueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesGetByIdsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesGetByIdsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesGetByIdsPost$Plain(params?: {
    'api-version'?: string;
    body?: Array<string>
  }): Observable<Array<QualityIssueModel>> {

    return this.apiQualityIssuesGetByIdsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueModel>>) => r.body as Array<QualityIssueModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesGetByIdsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesGetByIdsPost$Json$Response(params?: {
    'api-version'?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<QualityIssueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesGetByIdsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesGetByIdsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesGetByIdsPost$Json(params?: {
    'api-version'?: string;
    body?: Array<string>
  }): Observable<Array<QualityIssueModel>> {

    return this.apiQualityIssuesGetByIdsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueModel>>) => r.body as Array<QualityIssueModel>)
    );
  }

  /**
   * Path part for operation apiReportsReportIdQualityIssuesPost
   */
  static readonly ApiReportsReportIdQualityIssuesPostPath = '/api/reports/{reportId}/qualityIssues';

  /**
   * Gets quality issues by identifiers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsReportIdQualityIssuesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdQualityIssuesPost$Plain$Response(params: {

    /**
     * Report identifier
     */
    reportId: string;
    'api-version'?: string;

    /**
     * Quality issue identifiers
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<QualityIssueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiReportsReportIdQualityIssuesPostPath, 'post');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueModel>>;
      })
    );
  }

  /**
   * Gets quality issues by identifiers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsReportIdQualityIssuesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdQualityIssuesPost$Plain(params: {

    /**
     * Report identifier
     */
    reportId: string;
    'api-version'?: string;

    /**
     * Quality issue identifiers
     */
    body?: Array<string>
  }): Observable<Array<QualityIssueModel>> {

    return this.apiReportsReportIdQualityIssuesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueModel>>) => r.body as Array<QualityIssueModel>)
    );
  }

  /**
   * Gets quality issues by identifiers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsReportIdQualityIssuesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdQualityIssuesPost$Json$Response(params: {

    /**
     * Report identifier
     */
    reportId: string;
    'api-version'?: string;

    /**
     * Quality issue identifiers
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<QualityIssueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiReportsReportIdQualityIssuesPostPath, 'post');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueModel>>;
      })
    );
  }

  /**
   * Gets quality issues by identifiers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsReportIdQualityIssuesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdQualityIssuesPost$Json(params: {

    /**
     * Report identifier
     */
    reportId: string;
    'api-version'?: string;

    /**
     * Quality issue identifiers
     */
    body?: Array<string>
  }): Observable<Array<QualityIssueModel>> {

    return this.apiReportsReportIdQualityIssuesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueModel>>) => r.body as Array<QualityIssueModel>)
    );
  }

  /**
   * Path part for operation apiQualityIssuesKindsGet
   */
  static readonly ApiQualityIssuesKindsGetPath = '/api/QualityIssues/kinds';

  /**
   * Gets quality issue kinds.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesKindsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesKindsGet$Plain$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<QualityIssueKind>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesKindsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueKind>>;
      })
    );
  }

  /**
   * Gets quality issue kinds.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesKindsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesKindsGet$Plain(params?: {
    'api-version'?: string;
  }): Observable<Array<QualityIssueKind>> {

    return this.apiQualityIssuesKindsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueKind>>) => r.body as Array<QualityIssueKind>)
    );
  }

  /**
   * Gets quality issue kinds.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesKindsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesKindsGet$Json$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<QualityIssueKind>>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesKindsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QualityIssueKind>>;
      })
    );
  }

  /**
   * Gets quality issue kinds.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesKindsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQualityIssuesKindsGet$Json(params?: {
    'api-version'?: string;
  }): Observable<Array<QualityIssueKind>> {

    return this.apiQualityIssuesKindsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QualityIssueKind>>) => r.body as Array<QualityIssueKind>)
    );
  }

  /**
   * Path part for operation apiQualityIssuesIdRecheckPost
   */
  static readonly ApiQualityIssuesIdRecheckPostPath = '/api/QualityIssues/{id}/Recheck';

  /**
   * Checks a specific translation unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesIdRecheckPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdRecheckPost$Response(params: {

    /**
     * QualityIssue ID
     */
    id: string;
    'api-version'?: string;

    /**
     * RecheckTranslationUnitRequest
     */
    body?: RecheckTranslationUnitRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesIdRecheckPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Checks a specific translation unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesIdRecheckPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdRecheckPost(params: {

    /**
     * QualityIssue ID
     */
    id: string;
    'api-version'?: string;

    /**
     * RecheckTranslationUnitRequest
     */
    body?: RecheckTranslationUnitRequest
  }): Observable<void> {

    return this.apiQualityIssuesIdRecheckPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesApplySegmentPost
   */
  static readonly ApiQualityIssuesApplySegmentPostPath = '/api/QualityIssues/ApplySegment';

  /**
   * Apply segment to translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesApplySegmentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesApplySegmentPost$Response(params?: {
    'api-version'?: string;

    /**
     * ApplySegmentToTranslationUnitsRequest
     */
    body?: ApplySegmentToTranslationUnitsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesApplySegmentPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Apply segment to translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesApplySegmentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesApplySegmentPost(params?: {
    'api-version'?: string;

    /**
     * ApplySegmentToTranslationUnitsRequest
     */
    body?: ApplySegmentToTranslationUnitsRequest
  }): Observable<void> {

    return this.apiQualityIssuesApplySegmentPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesApplyTranslationUnitPost
   */
  static readonly ApiQualityIssuesApplyTranslationUnitPostPath = '/api/QualityIssues/ApplyTranslationUnit';

  /**
   * Apply translation unit to translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesApplyTranslationUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesApplyTranslationUnitPost$Response(params?: {
    'api-version'?: string;

    /**
     * ApplyTranslationUnitsRequest
     */
    body?: ApplyTranslationUnitsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesApplyTranslationUnitPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Apply translation unit to translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesApplyTranslationUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesApplyTranslationUnitPost(params?: {
    'api-version'?: string;

    /**
     * ApplyTranslationUnitsRequest
     */
    body?: ApplyTranslationUnitsRequest
  }): Observable<void> {

    return this.apiQualityIssuesApplyTranslationUnitPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesUpdateTranslationUnitsPost
   */
  static readonly ApiQualityIssuesUpdateTranslationUnitsPostPath = '/api/QualityIssues/updateTranslationUnits';

  /**
   * Updates and checks translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesUpdateTranslationUnitsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesUpdateTranslationUnitsPost$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.UpdateAndRecheckTranslationUnitsRequest
     */
    body?: UpdateAndRecheckTranslationUnitsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesUpdateTranslationUnitsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates and checks translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesUpdateTranslationUnitsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesUpdateTranslationUnitsPost(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.UpdateAndRecheckTranslationUnitsRequest
     */
    body?: UpdateAndRecheckTranslationUnitsRequest
  }): Observable<void> {

    return this.apiQualityIssuesUpdateTranslationUnitsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesIgnorePost
   */
  static readonly ApiQualityIssuesIgnorePostPath = '/api/QualityIssues/Ignore';

  /**
   * Ignore errors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesIgnorePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIgnorePost$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.QualityIssues.IgnoreQualityIssuesRequest
     */
    body?: IgnoreQualityIssuesRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesIgnorePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Ignore errors.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesIgnorePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIgnorePost(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.QualityIssues.IgnoreQualityIssuesRequest
     */
    body?: IgnoreQualityIssuesRequest
  }): Observable<void> {

    return this.apiQualityIssuesIgnorePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesCommentPost
   */
  static readonly ApiQualityIssuesCommentPostPath = '/api/QualityIssues/Comment';

  /**
   * Update comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesCommentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesCommentPost$Response(params?: {
    'api-version'?: string;

    /**
     * CommentQualityIssuesCommand
     */
    body?: CommentQualityIssuesCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesCommentPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update comments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesCommentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesCommentPost(params?: {
    'api-version'?: string;

    /**
     * CommentQualityIssuesCommand
     */
    body?: CommentQualityIssuesCommand
  }): Observable<void> {

    return this.apiQualityIssuesCommentPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQualityIssuesIdPatch
   */
  static readonly ApiQualityIssuesIdPatchPath = '/api/QualityIssues/{id}';

  /**
   * Partially update a specific QualityIssue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesIdPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdPatch$Plain$Response(params: {
    id: number;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<QualityIssueInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QualityIssueInfo>;
      })
    );
  }

  /**
   * Partially update a specific QualityIssue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesIdPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdPatch$Plain(params: {
    id: number;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<QualityIssueInfo> {

    return this.apiQualityIssuesIdPatch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QualityIssueInfo>) => r.body as QualityIssueInfo)
    );
  }

  /**
   * Partially update a specific QualityIssue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesIdPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdPatch$Json$Response(params: {
    id: number;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<QualityIssueInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QualityIssueInfo>;
      })
    );
  }

  /**
   * Partially update a specific QualityIssue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesIdPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQualityIssuesIdPatch$Json(params: {
    id: number;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<QualityIssueInfo> {

    return this.apiQualityIssuesIdPatch$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QualityIssueInfo>) => r.body as QualityIssueInfo)
    );
  }

  /**
   * Path part for operation apiQualityIssuesIdTranslationUnitFixPost
   */
  static readonly ApiQualityIssuesIdTranslationUnitFixPostPath = '/api/QualityIssues/{id}/translationUnit/fix';

  /**
   * Fixes a specific translation unit of quality issue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQualityIssuesIdTranslationUnitFixPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesIdTranslationUnitFixPost$Response(params: {

    /**
     * Quality issue identifier
     */
    id: number;
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.QualityIssues.FixQualityIssueTranslationUnitRequest
     */
    body?: FixQualityIssueTranslationUnitRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QualityIssuesService.ApiQualityIssuesIdTranslationUnitFixPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fixes a specific translation unit of quality issue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQualityIssuesIdTranslationUnitFixPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiQualityIssuesIdTranslationUnitFixPost(params: {

    /**
     * Quality issue identifier
     */
    id: number;
    'api-version'?: string;

    /**
     * Verifika.API.Reports.Models.QualityIssues.FixQualityIssueTranslationUnitRequest
     */
    body?: FixQualityIssueTranslationUnitRequest
  }): Observable<void> {

    return this.apiQualityIssuesIdTranslationUnitFixPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
