import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styles: [
    `
      .loader {
        width: 100px;
        height: 100px;
      }
    `,
  ],
})
export class LoadingOverlayComponent implements INoRowsOverlayAngularComp {
  public static readonly rendererName = 'loadingOverlay';
  params: INoRowsOverlayParams;

  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }

  public afterGuiAttached?(): void {}
}
