import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ModalSize } from '@shared/models';
import type { ModalType } from '@shared/components/modal/modal-type';

/**
 * Компонент создает обертку для вывода компонента или template в popup-е
 * А именно добавляет отступы и кнопку/крестик чтобы закрыть окно
 */
@Component({
  selector: 'app-ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() modalType: ModalType = 'default';
  @Input() size: ModalSize = 'medium';
  @Input() disableClose = false;
}
