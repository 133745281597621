import { ReportTab } from '@shared/models';
import { ReportGridState } from '@store/report-grids-store/report-grids-state.model';

enum ActionTypes {
  PATCH_REPORT_GRID_STATE = '[Report-Grid] Set Report Grid State',
  CHANGE_MUlTI_LANG_PROJECT_COLS = '[Report-Grid] Change Multi Lang Project Cols',
  SET_TOOLPANEL_VISIBLE_STATUS = '[Report-Grid] Set Tool Panel Visible Status',
}

export class PatchReportGridState {
  static readonly type = ActionTypes.PATCH_REPORT_GRID_STATE;
  constructor(public reportTab: ReportTab, public data: Partial<ReportGridState>) {}
}

export class ChangeMultiLangProjectCols {
  static readonly type = ActionTypes.CHANGE_MUlTI_LANG_PROJECT_COLS;
  constructor(public isMultiLangProject: boolean, public force?: boolean) {}
}

export class SetToolPanelVisibleStatus {
  static readonly type = ActionTypes.SET_TOOLPANEL_VISIBLE_STATUS;
  constructor(public reportTab: ReportTab, public visibleToolPanel: string) {}
}
