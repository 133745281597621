import { LocaleQaSettingsModel } from '@generated/api';

export enum ActionTypes {
  LOAD_LOCALEQASETTINGS_LIST = '[LocaleQASettings] Load Locale QASettings List',
  ADD_LOCALEQASETTINGS = '[LocaleQASettings] ADD Locale QASettings',
  PATCH_LOCALEQASETTINGS = '[LocaleQASettings] Patch Locale QASettings',
}

export class LoadLocaleQASettingsList {
  static readonly type = ActionTypes.LOAD_LOCALEQASETTINGS_LIST;
  constructor(public qaSettingsId: string) {}
}

export class AddLocaleQASettings {
  static readonly type = ActionTypes.ADD_LOCALEQASETTINGS;
  constructor(public qaSettingsId: string, public languageCode: string) {}
}

export class PatchLocaleQASettings<T> {
  static readonly type = ActionTypes.PATCH_LOCALEQASETTINGS;
  constructor(public id: string, public pathFn: ((obj: LocaleQaSettingsModel) => T) | string, public value: T) {}
}
