import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AddFrequentSymbol } from '@store/special-symbols-store/special-symbols.actions';
import { SpecialSymbolsState } from '@store/special-symbols-store/special-symbols.state';
import { Subject } from 'rxjs';
import { groupedSpecialSymbols, SpecSymbol } from './special-symbols';

@Component({
  selector: 'app-special-symbols',
  templateUrl: './special-symbols.component.html',
  styleUrls: ['./special-symbols.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialSymbolsComponent implements OnInit, OnDestroy {
  public readonly searchField = new FormControl('');
  public isFrequentTab = true;
  public selectedSymbol: SpecSymbol;

  private readonly specSymbols = groupedSpecialSymbols;

  public filteredGroupedSpecSymbols = this.specSymbols;

  @Output()
  public inserted: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  title: string = 'Insert symbols';

  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit(): void {
    this.watchSearchInput();
  }

  get frequentSymbols(): Array<{ symbol: SpecSymbol; countOfUsage: number }> {
    return this.store.selectSnapshot(SpecialSymbolsState.frequentSymbols);
  }

  get symbolsGroups(): string[] {
    return Array.from(this.filteredGroupedSpecSymbols.keys());
  }

  private watchSearchInput(): void {
    this.searchField.valueChanges.subscribe((value: string) => {
      this.isFrequentTab = false;
      this.changeVisibleGroupedSymbols(value);
      this.selectFirstSymbolAfterSearch();
      this.cdr.detectChanges();
    });
  }

  private changeVisibleGroupedSymbols(filter: string): void {
    if (!filter) {
      this.resetVisibleGroupedSymbols();
      return;
    }
    this.filteredGroupedSpecSymbols = Array.from(this.specSymbols.keys()).reduce<Map<string, SpecSymbol[]>>(
      (acc, group) => {
        const filteredSymbols = this.specSymbols
          .get(group)
          .filter((symbol) => this.isSymbolMatchToFilter(symbol, filter.toLowerCase()));
        if (filteredSymbols.length) {
          acc.set(group, filteredSymbols);
        }
        return acc;
      },
      new Map()
    );
  }

  private isSymbolMatchToFilter(symbol: SpecSymbol, filter: string): boolean {
    return !!symbol.searchQueries?.find((q) => q.includes(filter)) || symbol.description.toLowerCase().includes(filter);
  }

  private resetVisibleGroupedSymbols(): void {
    this.filteredGroupedSpecSymbols = this.specSymbols;
  }

  private selectFirstSymbolAfterSearch(): void {
    const filteredGroupNames = Array.from(this.filteredGroupedSpecSymbols.keys());
    if (!filteredGroupNames) {
      return;
    }
    this.selectedSymbol = this.filteredGroupedSpecSymbols.get(filteredGroupNames[0])?.[0];
  }

  public selectSymbol(s: SpecSymbol): void {
    this.selectedSymbol = s;
  }

  public insertSymbol(): void {
    if (this.selectedSymbol) {
      this.inserted.emit(this.selectedSymbol.symbol);
      this.increaseFrequencyUsageOfSymbol();
    }
  }

  private increaseFrequencyUsageOfSymbol(): void {
    this.store.dispatch(new AddFrequentSymbol(this.selectedSymbol));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
