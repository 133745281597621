/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeProjectSettingsRequest } from '../models/change-project-settings-request';
import { CreateProjectRequest } from '../models/create-project-request';
import { ProjectInfo } from '../models/project-info';
import { ProjectInfoDataSourceResponse } from '../models/project-info-data-source-response';
import { UpdateProjectRequest } from '../models/update-project-request';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProjectsGet
   */
  static readonly ApiProjectsGetPath = '/api/Projects';

  /**
   * Get projects for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsGet$Plain$Response(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Get projects for user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsGet$Plain(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<ProjectInfoDataSourceResponse> {

    return this.apiProjectsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfoDataSourceResponse>) => r.body as ProjectInfoDataSourceResponse)
    );
  }

  /**
   * Get projects for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsGet$Json$Response(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Get projects for user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsGet$Json(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<ProjectInfoDataSourceResponse> {

    return this.apiProjectsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfoDataSourceResponse>) => r.body as ProjectInfoDataSourceResponse)
    );
  }

  /**
   * Path part for operation apiProjectsPost
   */
  static readonly ApiProjectsPostPath = '/api/Projects';

  /**
   * Create new project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * CreateProjectRequest
     */
    body?: CreateProjectRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Create new project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * CreateProjectRequest
     */
    body?: CreateProjectRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Create new project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * CreateProjectRequest
     */
    body?: CreateProjectRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Create new project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsPost$Json(params?: {
    'api-version'?: string;

    /**
     * CreateProjectRequest
     */
    body?: CreateProjectRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Path part for operation apiProjectsSearchGet
   */
  static readonly ApiProjectsSearchGetPath = '/api/Projects/search';

  /**
   * Finds projects by search query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsSearchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsSearchGet$Plain$Response(params?: {

    /**
     * Gets or sets search query
     */
    'q'?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsSearchGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Finds projects by search query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsSearchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsSearchGet$Plain(params?: {

    /**
     * Gets or sets search query
     */
    'q'?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<ProjectInfoDataSourceResponse> {

    return this.apiProjectsSearchGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfoDataSourceResponse>) => r.body as ProjectInfoDataSourceResponse)
    );
  }

  /**
   * Finds projects by search query.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsSearchGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsSearchGet$Json$Response(params?: {

    /**
     * Gets or sets search query
     */
    'q'?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsSearchGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Finds projects by search query.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsSearchGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsSearchGet$Json(params?: {

    /**
     * Gets or sets search query
     */
    'q'?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<ProjectInfoDataSourceResponse> {

    return this.apiProjectsSearchGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfoDataSourceResponse>) => r.body as ProjectInfoDataSourceResponse)
    );
  }

  /**
   * Path part for operation apiProjectsIdGet
   */
  static readonly ApiProjectsIdGetPath = '/api/Projects/{id}';

  /**
   * Get a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdGet$Plain$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Get a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdGet$Plain(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Get a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdGet$Json$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Get a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdGet$Json(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Path part for operation apiProjectsIdPut
   */
  static readonly ApiProjectsIdPutPath = '/api/Projects/{id}';

  /**
   * Update a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdPut$Plain$Response(params: {

    /**
     * ProjectID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateProjectRequest
     */
    body?: UpdateProjectRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Update a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdPut$Plain(params: {

    /**
     * ProjectID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateProjectRequest
     */
    body?: UpdateProjectRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Update a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdPut$Json$Response(params: {

    /**
     * ProjectID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateProjectRequest
     */
    body?: UpdateProjectRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Update a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdPut$Json(params: {

    /**
     * ProjectID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateProjectRequest
     */
    body?: UpdateProjectRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Path part for operation apiProjectsIdTouchedPost
   */
  static readonly ApiProjectsIdTouchedPostPath = '/api/Projects/{id}/Touched';

  /**
   * Get and touched a specific project with settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdTouchedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdTouchedPost$Plain$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdTouchedPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Get and touched a specific project with settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdTouchedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdTouchedPost$Plain(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdTouchedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Get and touched a specific project with settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdTouchedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdTouchedPost$Json$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdTouchedPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Get and touched a specific project with settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdTouchedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsIdTouchedPost$Json(params: {

    /**
     * id
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdTouchedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Path part for operation apiProjectsIdChangeQaSettingsPost
   */
  static readonly ApiProjectsIdChangeQaSettingsPostPath = '/api/Projects/{id}/ChangeQASettings';

  /**
   * Change project QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdChangeQaSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdChangeQaSettingsPost$Plain$Response(params: {

    /**
     * Project ID
     */
    id: string;
    'api-version'?: string;

    /**
     * ChangeProjectSettingsRequest
     */
    body?: ChangeProjectSettingsRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdChangeQaSettingsPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Change project QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdChangeQaSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdChangeQaSettingsPost$Plain(params: {

    /**
     * Project ID
     */
    id: string;
    'api-version'?: string;

    /**
     * ChangeProjectSettingsRequest
     */
    body?: ChangeProjectSettingsRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdChangeQaSettingsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

  /**
   * Change project QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsIdChangeQaSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdChangeQaSettingsPost$Json$Response(params: {

    /**
     * Project ID
     */
    id: string;
    'api-version'?: string;

    /**
     * ChangeProjectSettingsRequest
     */
    body?: ChangeProjectSettingsRequest
  }): Observable<StrictHttpResponse<ProjectInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ApiProjectsIdChangeQaSettingsPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectInfo>;
      })
    );
  }

  /**
   * Change project QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsIdChangeQaSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsIdChangeQaSettingsPost$Json(params: {

    /**
     * Project ID
     */
    id: string;
    'api-version'?: string;

    /**
     * ChangeProjectSettingsRequest
     */
    body?: ChangeProjectSettingsRequest
  }): Observable<ProjectInfo> {

    return this.apiProjectsIdChangeQaSettingsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectInfo>) => r.body as ProjectInfo)
    );
  }

}
