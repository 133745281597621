import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { delay, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { TooltipTriggerDirective } from '@shared/components/tooltip';

/**
 * Text edit component
 * Allow to show text and edit it
 *
 * Usage:
 * ````
 * <app-ui-text-editable
 *    placeholder="Enter profile name"
 *    [value]="'test value'"
 *    (changeValue)="change($event)"
 *    (submitText)="submit($event)">
 *    </app-ui-text-editable>
 * ````
 */

export type TextEditableSize = 'md' | 'lg';

@Component({
  selector: 'app-ui-text-editable',
  templateUrl: './text-editable.component.html',
  styleUrls: ['./text-editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appTextEditable',
})
export class TextEditableComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  private destroyed$: Subject<void> = new Subject<void>();
  isEdit$ = new BehaviorSubject<boolean>(false);
  valueControl = new FormControl('');
  previousValue: string = '';

  @Input() value = null;

  @Input() placeholder: string = '';

  @Input() showMarker: boolean;

  @Input() disabled: boolean = false;

  @Input() errors: { [errorKey: string]: string } = {};

  @Input() size: TextEditableSize = 'md';

  @Output() changeValue = new EventEmitter<any>();

  @Output() submitText = new EventEmitter<string>();

  @Output() startEditing = new EventEmitter<void>();

  @ViewChild('inputControl', { static: false, read: ElementRef })
  inputControl: ElementRef;
  @ViewChild('inputControl', { static: false, read: TooltipTriggerDirective })
  inputTooltip: TooltipTriggerDirective;
  @ViewChild('hiddenText', { static: false }) hiddenTextRef: ElementRef;

  @HostBinding('class') get class(): string {
    return `size-${this.size}`;
  }

  constructor(@Optional() @Self() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value && changes?.value.currentValue !== this.valueControl.value) {
      this.valueControl.setValue(changes?.value.currentValue, {
        emitEvent: false,
      });
    }

    if (changes?.disabled && changes?.disabled.currentValue !== this.valueControl.disabled) {
      this.valueControl[changes.disabled.currentValue ? 'disable' : 'enable']();
    }
  }

  get textWidth(): number {
    return this.hiddenTextRef.nativeElement.clientWidth + 2;
  }

  get textHiddenContent(): string {
    return this.valueControl.value;
  }

  get controlError(): string {
    if (!this.ngControl) {
      return '';
    }

    return Object.keys(this.ngControl.errors || {})
      .map((errorKey: string) => this.errors[errorKey] || errorKey)
      .join(', ');
  }

  public startEdit(): void {
    if (this.valueControl.disabled) {
      return;
    }

    this.isEdit$.next(true);
    this.previousValue = this.valueControl.value;
    setTimeout(() => {
      this.inputControl.nativeElement.focus();
      this.inputControl.nativeElement.select();
      this.startEditing.emit();
    }, 10);
  }

  public blur(): void {
    this.submit();
  }

  public escape(): void {
    this.endEdit();
    this.valueControl.setValue(this.previousValue, { emitEvent: false });
  }

  public submit(): void {
    const control = this.valueControl;
    const value = control.value.trim();
    this.endEdit();

    if (this.ngControl?.invalid || !value) {
      control.setValue(this.previousValue, { emitEvent: false });
      return;
    }

    this.submitText.emit(value as string);
  }

  private endEdit(): void {
    if (this.inputTooltip) {
      this.inputTooltip.hide();
    }
    this.isEdit$.next(false);
  }

  private initSubscriptions(): void {
    this.valueControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        map((value) => value?.trim()),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.changeValue.emit(value);
        this.onChange(value);
        this.onTouched();
      });

    this.ngControl?.statusChanges
      .pipe(
        filter(() => !!this.inputTooltip),
        tap(() => this.inputTooltip.hide()),
        delay(300),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        if (this.ngControl.invalid) {
          this.inputTooltip.appTooltip = this.controlError;
          this.inputTooltip.show();
        }
      });
  }

  // region ControlValueAccessor feature

  onChange: any = () => {};

  onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value): void {
    if (value !== this.valueControl.value) {
      this.valueControl.setValue(value);
      this.cdr.markForCheck();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.valueControl[isDisabled ? 'disable' : 'enable']();
  }

  // endregion
}
