import {
  State,
  Selector,
  Action,
  StateContext,
  Store,
  createSelector,
  StateOperator,
  NgxsAfterBootstrap,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';

import type { CommentThread, RenderItem } from '@shared/models';
import {
  LoadProjectCommentThreads,
  CommentCreate,
  CommentUpdate,
  CommentThreadRemove,
  CommentThreadResolve,
  CommentRemove,
  CommentThreadLoad,
  CommentThreadResolved,
  CommentThreadRemoved,
  CommentLoad,
  CommentRemoved,
  CommentAddOrUpdate,
  CommentThreadAdd,
  CommentThreadRestore,
  CommentRestore,
  CommentThreadRemoveByTranslationUnitId,
} from './comments.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { ProjectsState, ProjectState } from '@store';
import { CommonHubService, RenderItemBuilder } from '@shared/services';
import { Observable, throwError } from 'rxjs';
import {
  CommentChangedEvent,
  CommentCreatedEvent,
  CommentRemovedEvent,
  CommentRestoredEvent,
  CommentThreadCreatedEvent,
  CommentThreadRemovedEvent,
  CommentThreadResolvedEvent,
  CommentThreadRestoredEvent,
} from '@shared/models';
import { CommentModel, CommentsService, Segment, ThreadWithCommentsModel } from '@generated/api';
import { removeItems } from '@store/custom-operators';
import { dateTimeComparator } from '@shared/tools';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

interface CommentsStateModel {
  [projectId: string]: CommentThread[];
}

const initialState = {};

@AsyncStorage
@State<CommentsStateModel>({
  name: 'comments',
  defaults: {
    ...initialState,
  },
})
@Injectable()
export class CommentsState implements NgxsAfterBootstrap {
  constructor(
    private store: Store,
    private commentsService: CommentsService,
    private commonHubService: CommonHubService,
    private renderItemBuilder: RenderItemBuilder
  ) {
    this.commonHubService.watch(CommentThreadCreatedEvent).subscribe((data) => {
      this.store.dispatch(new CommentThreadLoad(data.id));
    });
    this.commonHubService.watch(CommentThreadResolvedEvent).subscribe((data) => {
      this.store.dispatch(new CommentThreadResolved(data.id, data.isResolved));
    });
    this.commonHubService.watch(CommentThreadRemovedEvent).subscribe((data) => {
      this.store.dispatch(new CommentThreadRemoved(data.id));
    });
    this.commonHubService.watch(CommentThreadRestoredEvent).subscribe((data) => {
      this.store.dispatch(new CommentThreadLoad(data.id));
    });
    this.commonHubService.watch(CommentCreatedEvent).subscribe((data) => {
      this.store.dispatch(new CommentLoad(data.threadId, data.id, false));
    });
    this.commonHubService.watch(CommentChangedEvent).subscribe((data) => {
      this.store.dispatch(new CommentLoad(data.threadId, data.commentId, true));
    });
    this.commonHubService.watch(CommentRemovedEvent).subscribe((data) => {
      this.store.dispatch(new CommentRemoved(data.threadId, data.id));
    });
    this.commonHubService.watch(CommentRestoredEvent).subscribe((data) => {
      if (data.commentIds.length === 0) {
        this.store.dispatch(new CommentLoad(data.threadId, data.commentIds[0], false));
        return;
      }
      this.store.dispatch(new CommentThreadLoad(data.threadId));
    });
  }

  @Selector([CommentsState, ProjectState.projectId])
  public static projectComments(state: CommentsStateModel, projectId: string): CommentThread[] {
    return (state && state[projectId]) || [];
  }

  @Selector([CommentsState.projectComments])
  public static projectHasOpenComments(threads: CommentThread[]): boolean {
    return threads.some((thread) => !thread.isResolved);
  }

  public static translationUnitComments(translationUnitId: string): (threads: CommentThread[]) => CommentThread[] {
    return createSelector([CommentsState.projectComments], (threads: CommentThread[]) =>
      threads.filter((thread) => thread.translationUnitId === translationUnitId)
    );
  }

  public static threadById(threadId: string): (threads: CommentThread[]) => CommentThread {
    return createSelector([CommentsState.projectComments], (threads: CommentThread[]) =>
      threads.find((thread) => thread.id === threadId)
    );
  }

  public static commentById(threadId: string, commentId: string): (thread: CommentThread) => CommentModel {
    return createSelector([CommentsState.threadById(threadId)], (thread: CommentThread) =>
      thread?.comments.find((item) => item.id === commentId)
    );
  }

  ngxsAfterBootstrap(ctx: StateContext<CommentsStateModel>) {
    this.cleanStorage(ctx);
  }

  private cleanStorage(ctx: StateContext<CommentsStateModel>): void {
    const currentStoredProjects = this.store.selectSnapshot(ProjectsState.storedProjects);
    const commentState = ctx.getState();
    const newState = currentStoredProjects.reduce<CommentsStateModel>((acc, project) => {
      acc[project.id] = commentState[project.id];
      return acc;
    }, {});
    ctx.setState(newState);
  }

  @Action(LoadProjectCommentThreads)
  public loadProjectComments(
    ctx: StateContext<CommentsStateModel>,
    action: LoadProjectCommentThreads
  ): Observable<ThreadWithCommentsModel[]> {
    return this.commentsService.apiCommentsGet$Json({ projectId: action.projectId }).pipe(
      tap((comments: ThreadWithCommentsModel[]) => {
        const sortedComments: ThreadWithCommentsModel[] = comments.sort(
          (a: ThreadWithCommentsModel, b: ThreadWithCommentsModel) =>
            dateTimeComparator(a.comments[0].createdOn, b.comments[0].createdOn)
        );
        ctx.patchState({
          [action.projectId]: sortedComments.map((comment) => this.threadMapper(comment)),
        });
      })
    );
  }

  @Action(CommentThreadLoad)
  public commentThreadLoad(ctx: StateContext<CommentsStateModel>, action: CommentThreadLoad): Observable<CommentModel> {
    const thread = this.store.selectSnapshot(CommentsState.threadById(action.threadId));
    if (thread) {
      return;
    }
    return this.commentsService
      .apiCommentsIdGet$Json({ id: action.threadId })
      .pipe(tap((newThread) => ctx.dispatch(new CommentThreadAdd(this.threadMapper(newThread)))));
  }

  @Action(CommentThreadAdd)
  public commentThreadAdd(ctx: StateContext<CommentsStateModel>, action: CommentThreadAdd): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: append([action.thread]),
      })
    );
  }

  @Action(CommentThreadResolve)
  public commentThreadResolve(ctx: StateContext<CommentsStateModel>, action: CommentThreadResolve): any {
    ctx.dispatch(new CommentThreadResolved(action.threadId, action.isResolved));
    return this.commentsService
      .apiCommentsThreadIdResolvePost({
        threadId: action.threadId,
        body: {
          isResolved: action.isResolved,
        },
      })
      .pipe(
        catchError((err) => {
          ctx.dispatch(new CommentThreadResolved(action.threadId, action.isResolved));
          return throwError(() => err);
        })
      );
  }

  @Action(CommentThreadResolved)
  public commentThreadResolved(ctx: StateContext<CommentsStateModel>, action: CommentThreadResolved): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const updateThreadIsResolved =
      (id: string, isResolved: boolean): StateOperator<CommentThread[]> =>
      (threads) =>
        threads.map((item) => ({ ...item, isResolved: item.id === id ? isResolved : item.isResolved }));
    ctx.setState(
      patch({
        [projectId]: updateThreadIsResolved(action.threadId, action.isResolved),
      })
    );
  }

  @Action(CommentThreadRemove)
  public commentThreadRemove(ctx: StateContext<CommentsStateModel>, action: CommentThreadRemove): Observable<void> {
    const deletedThread = this.store.selectSnapshot(CommentsState.threadById(action.threadId));
    ctx.dispatch(new CommentThreadRemoved(action.threadId));
    return this.commentsService.apiCommentsIdDelete({ id: action.threadId }).pipe(
      catchError((err) => {
        ctx.dispatch(new CommentThreadAdd(deletedThread));
        return throwError(() => err);
      })
    );
  }

  @Action(CommentThreadRemoved)
  public commentThreadRemoved(ctx: StateContext<CommentsStateModel>, action: CommentThreadRemoved): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: removeItem<CommentThread>((thread) => thread.id === action.threadId),
      })
    );
  }

  @Action(CommentThreadRestore)
  public commentThreadRestore(ctx: StateContext<CommentsStateModel>, action: CommentThreadRestore): Observable<void> {
    ctx.dispatch(new CommentThreadAdd(action.thread));
    return this.commentsService
      .apiCommentsThreadIdRestorePost({
        threadId: action.thread.id,
        body: {
          commentIds: action.thread.comments.map((item) => item.id),
        },
      })
      .pipe(
        catchError((err) => {
          ctx.dispatch(new CommentThreadRemoved(action.thread.id));
          return throwError(() => err);
        })
      );
  }

  @Action(CommentAddOrUpdate)
  public commentAddOrUpdate(ctx: StateContext<CommentsStateModel>, action: CommentAddOrUpdate): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const oldComment: CommentModel = this.store.selectSnapshot(
      CommentsState.commentById(action.comment.threadId, action.comment.id)
    );
    if (oldComment) {
      ctx.setState(
        patch({
          [projectId]: updateItem<CommentThread>(
            (item) => item.id === action.comment.threadId,
            patch({
              comments: updateItem<CommentModel>((item) => item.id === action.comment.id, action.comment),
            })
          ),
        })
      );
    } else {
      ctx.setState(
        patch({
          [projectId]: updateItem<CommentThread>(
            (item) => item.id === action.comment.threadId,
            patch({
              comments: append([action.comment]),
            })
          ),
        })
      );
    }
  }

  @Action(CommentLoad)
  public commentLoad(ctx: StateContext<CommentsStateModel>, action: CommentLoad): Observable<CommentModel> {
    const oldComment: CommentModel = this.store.selectSnapshot(
      CommentsState.commentById(action.threadId, action.commentId)
    );
    if (oldComment && !action.force) {
      return;
    }
    return this.commentsService
      .apiCommentsIdGet$Json({ id: action.commentId })
      .pipe(tap((comment: CommentModel) => ctx.dispatch(new CommentAddOrUpdate(comment))));
  }

  // TODO: VW-1219 remove type assertion to any (projectID)
  @Action(CommentCreate)
  public createComments(ctx: StateContext<CommentsStateModel>, action: CommentCreate): Observable<CommentModel> {
    return this.commentsService.apiCommentsPost$Json({ body: action.comment as any }).pipe(
      tap((comment: CommentModel) => {
        if (action.comment.threadId) {
          ctx.dispatch(new CommentAddOrUpdate(comment));
          return;
        }
        ctx.dispatch(new CommentThreadLoad(comment.threadId));
      })
    );
  }

  @Action(CommentUpdate)
  public updateComment(ctx: StateContext<CommentsStateModel>, action: CommentUpdate): Observable<CommentModel> {
    const thread = this.store.selectSnapshot(CommentsState.threadById(action.threadId));
    const comment: CommentModel = thread.comments.find((item) => item.id === action.commentId);
    ctx.dispatch(
      new CommentAddOrUpdate({
        ...comment,
        message: action.newMessage,
        modifiedOn: new Date().toISOString(),
      })
    );
    return this.commentsService
      .apiCommentsIdPut$Json({
        id: action.commentId,
        body: {
          message: action.newMessage,
        },
      })
      .pipe(
        tap((newComment) => ctx.dispatch(new CommentAddOrUpdate(newComment))),
        catchError((err) => {
          ctx.dispatch(new CommentAddOrUpdate(comment));
          return throwError(() => err);
        })
      );
  }

  @Action(CommentRemove)
  public commentRemove(ctx: StateContext<CommentsStateModel>, action: CommentRemove): Observable<void> {
    const thread = this.store.selectSnapshot(CommentsState.threadById(action.threadId));
    const deletedComment = thread.comments.find((comment) => comment.id === action.commentId);
    ctx.dispatch(new CommentRemoved(action.threadId, action.commentId));
    return this.commentsService.apiCommentsIdDelete({ id: action.commentId }).pipe(
      catchError((err) => {
        ctx.dispatch(new CommentAddOrUpdate(deletedComment));
        return throwError(() => err);
      })
    );
  }

  @Action(CommentRemoved)
  public commentRemoved(ctx: StateContext<CommentsStateModel>, action: CommentRemoved): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: updateItem<CommentThread>(
          (item) => item.id === action.threadId,
          patch({
            comments: removeItem<CommentModel>((item) => item.id === action.commentId),
          })
        ),
      })
    );
  }

  @Action(CommentRestore)
  public commentRestore(ctx: StateContext<CommentsStateModel>, action: CommentRestore): Observable<void> {
    ctx.dispatch(new CommentAddOrUpdate(action.comment));
    return this.commentsService
      .apiCommentsThreadIdRestorePost({
        threadId: action.threadId,
        body: {
          commentIds: [action.comment.id],
        },
      })
      .pipe(
        catchError((err) => {
          ctx.dispatch(new CommentRemoved(action.threadId, action.comment.id));
          return throwError(() => err);
        })
      );
  }

  @Action(CommentThreadRemoveByTranslationUnitId)
  public commentThreadRemoveByTranslationUnitId(
    ctx: StateContext<CommentsStateModel>,
    action: CommentThreadRemoveByTranslationUnitId
  ): void {
    const ids = new Set(action.translationUnitIds);
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: removeItems<CommentThread>((thread) => ids.has(thread.translationUnitId)),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<CommentsStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }

  private threadMapper(raw: ThreadWithCommentsModel): CommentThread {
    const renderItems: RenderItem[][] = raw.data.quotes?.map((segment: Segment) =>
      this.renderItemBuilder.build([], segment.elements)
    );
    return {
      ...raw,
      data: {
        quotes: renderItems,
      },
    } as CommentThread;
  }
}
