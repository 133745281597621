import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TooltipTriggerDirective } from '@shared/components/tooltip';
import { uuid } from '@shared/tools';
import { SearchType } from '@shared/components/search-pattern/search-type';
import { SearchPatternValidatorService } from '@shared/components/search-pattern/search-pattern-validator.service';
import { SpinnerSize } from '../loader';
import { SearchOptions } from '@generated/api';
import { SpecialSymbolService } from '@shared/services/special-symbol.service';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';

/**
 * Component implemented as custom form control. Can be used with FormControl or NgModel.
 *
 */
@Component({
  selector: 'app-search-pattern',
  templateUrl: './search-pattern.component.html',
  styleUrls: ['./search-pattern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchPatternComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SearchPatternComponent,
      multi: true,
    },
  ],
})
export class SearchPatternComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() fieldName = 'search';
  @Input() fieldId = `search-${uuid()}`;
  @Input() autocomplete: 'on' | 'off' = 'on';
  @Input() crossSegmentsBackReferencesDestination: string;

  private _crossSegmentsBackReferencesEnabled: boolean;

  @Input()
  set crossSegmentsBackReferencesEnabled(val: boolean) {
    this._crossSegmentsBackReferencesEnabled = val;
  }
  get crossSegmentsBackReferencesEnabled(): boolean {
    return this._crossSegmentsBackReferencesEnabled;
  }

  @Input() placeholder = 'Search in source';

  private _isTooltipVisible = true;
  @Input()
  set isTooltipVisible(isVisible: boolean) {
    this._isTooltipVisible = isVisible;
    this.showErrorTooltip();
  }

  get isTooltipVisible(): boolean {
    return this._isTooltipVisible;
  }

  @Output() enterKeyPressed = new EventEmitter();
  public crossSegmentsBackReferences: boolean = false;

  @ViewChild('powerSearchTooltip', { static: true })
  powerSearchTooltip: TooltipTriggerDirective;

  @ViewChild('regularExpressionTooltip', { static: true })
  regularExpressionTooltip: TooltipTriggerDirective;

  @ViewChild(ContextMenuDirective)
  private contextMenu: ContextMenuDirective;

  public patternControl: FormControl = new FormControl('');
  public regularExpressionPatternInvalid = false;
  public powerSearchPatternInvalid = false;
  public caseSensitive: boolean = false;
  public includeTags: boolean = false;
  public searchType: SearchType;
  public searchTypes = SearchType;
  public SpinnerSize = SpinnerSize;
  public active = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private searchPatternValidatorService: SearchPatternValidatorService,
    private specialSymbolService: SpecialSymbolService
  ) {}

  ngOnInit(): void {}

  public focusInput(focus: boolean, input?: HTMLInputElement): void {
    this.active = focus;
    this.specialSymbolService.focusInput(this.contextMenu, input, this.patternControl);
  }

  public cancel(): void {
    this.caseSensitive = false;
    this.includeTags = false;
    this.crossSegmentsBackReferences = false;
    this.searchType = null;
    this.patternControl.setValue('');
    this.valueChanged();
  }

  public changeSearchType(newType: SearchType): void {
    this.searchType = newType !== this.searchType ? newType : null;
    this.handleCurrentSearchType();
    this.valueChanged();
  }

  private handleCurrentSearchType(): void {
    if (this.searchType !== SearchType.Regex) {
      this.crossSegmentsBackReferences = false;
    }
  }

  public changeCaseSensitive(): void {
    this.caseSensitive = !this.caseSensitive;
    this.valueChanged();
  }

  public changeIncludeTags(): void {
    this.includeTags = !this.includeTags;
    this.valueChanged();
  }

  public changeBackreference(): void {
    this.crossSegmentsBackReferences = !this.crossSegmentsBackReferences;
    this.valueChanged();
  }

  public valueChanged(): void {
    this.hideActiveTooltips();
    this.onChange({
      pattern: this.patternControl.value,
      caseSensitive: this.caseSensitive,
      includeTags: this.includeTags,
      searchType: this.searchType,
      crossSegmentsBackReferences: this.crossSegmentsBackReferences,
    });
  }

  private hideActiveTooltips(): void {
    this.regularExpressionTooltip.hide();
    this.powerSearchTooltip.hide();
  }

  // region ControlValueAccessor feature

  onChange: (_: SearchOptions) => void = () => {};

  onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(searchPattern: SearchOptions): void {
    this.patternControl.setValue(searchPattern?.pattern);
    this.caseSensitive = !!searchPattern?.caseSensitive;
    this.includeTags = !!searchPattern?.includeTags;
    this.searchType = searchPattern?.searchType;
    this.crossSegmentsBackReferences = !!searchPattern?.crossSegmentsBackReferences;

    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // endregion

  // Validator interface feature
  public validate(control: AbstractControl): ValidationErrors | null {
    this.regularExpressionPatternInvalid = false;
    this.powerSearchPatternInvalid = false;
    const searchPattern: SearchOptions = control.value;
    if (this.searchPatternValidatorService.validate(searchPattern)) {
      return;
    }
    if (searchPattern.searchType === SearchType.Regex) {
      this.regularExpressionPatternInvalid = true;
      this.showErrorTooltip();
      return { InvalidRegularExpression: true };
    } else if (searchPattern.searchType === SearchType.PowerSyntax) {
      this.powerSearchPatternInvalid = true;
      this.showErrorTooltip();
      return { InvalidPowerSearchPattern: true };
    }
    return null;
  }

  private showErrorTooltip(): void {
    if (!this.isTooltipVisible) {
      this.regularExpressionTooltip.hide();
      this.powerSearchTooltip.hide();
      return;
    }
    if (this.regularExpressionPatternInvalid) {
      this.regularExpressionTooltip.show();
    } else if (this.powerSearchPatternInvalid) {
      this.powerSearchTooltip.show();
    }
    this.cdr.detectChanges();
  }

  public keyPressedHandler(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.enterKeyPressed.emit();
    }
  }

  public insertSymbol(symbol: string): void {
    this.specialSymbolService.insertSymbol(symbol);
    this.contextMenu.close();
  }
}
