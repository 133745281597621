import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, ButtonModule],
  declarations: [DialogConfirmationComponent],
  exports: [DialogConfirmationComponent],
  entryComponents: [DialogConfirmationComponent],
})
export class DialogConfirmationModule {}
