/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTermbaseRequest } from '../models/create-termbase-request';
import { DefaultUploadGlossariesResult } from '../models/default-upload-glossaries-result';
import { ExportGlossaryRequest } from '../models/export-glossary-request';
import { GlossaryImportConfiguration } from '../models/glossary-import-configuration';
import { Operation } from '../models/operation';
import { TermbaseInfo } from '../models/termbase-info';
import { UpdateTermbaseRequest } from '../models/update-termbase-request';

@Injectable({
  providedIn: 'root',
})
export class TermbasesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTermbasesGet
   */
  static readonly ApiTermbasesGetPath = '/api/Termbases';

  /**
   * Get all termbases by QA Settings ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesGet$Plain$Response(params?: {

    /**
     * QA Settings ID
     */
    qaSettingsId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TermbaseInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesGetPath, 'get');
    if (params) {
      rb.query('qaSettingsId', params.qaSettingsId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TermbaseInfo>>;
      })
    );
  }

  /**
   * Get all termbases by QA Settings ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesGet$Plain(params?: {

    /**
     * QA Settings ID
     */
    qaSettingsId?: string;
    'api-version'?: string;
  }): Observable<Array<TermbaseInfo>> {

    return this.apiTermbasesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TermbaseInfo>>) => r.body as Array<TermbaseInfo>)
    );
  }

  /**
   * Get all termbases by QA Settings ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesGet$Json$Response(params?: {

    /**
     * QA Settings ID
     */
    qaSettingsId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TermbaseInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesGetPath, 'get');
    if (params) {
      rb.query('qaSettingsId', params.qaSettingsId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TermbaseInfo>>;
      })
    );
  }

  /**
   * Get all termbases by QA Settings ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesGet$Json(params?: {

    /**
     * QA Settings ID
     */
    qaSettingsId?: string;
    'api-version'?: string;
  }): Observable<Array<TermbaseInfo>> {

    return this.apiTermbasesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TermbaseInfo>>) => r.body as Array<TermbaseInfo>)
    );
  }

  /**
   * Path part for operation apiTermbasesPost
   */
  static readonly ApiTermbasesPostPath = '/api/Termbases';

  /**
   * Create a new termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * CreateTermbaseRequest
     */
    body?: CreateTermbaseRequest
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Create a new termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesPost$Plain(params?: {
    'api-version'?: string;

    /**
     * CreateTermbaseRequest
     */
    body?: CreateTermbaseRequest
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Create a new termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * CreateTermbaseRequest
     */
    body?: CreateTermbaseRequest
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Create a new termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesPost$Json(params?: {
    'api-version'?: string;

    /**
     * CreateTermbaseRequest
     */
    body?: CreateTermbaseRequest
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Path part for operation apiTermbasesIdGet
   */
  static readonly ApiTermbasesIdGetPath = '/api/Termbases/{id}';

  /**
   * Get a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdGet$Plain$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Get a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdGet$Plain(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Get a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdGet$Json$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Get a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdGet$Json(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Path part for operation apiTermbasesIdPut
   */
  static readonly ApiTermbasesIdPutPath = '/api/Termbases/{id}';

  /**
   * Update a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPut$Plain$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTermbaseRequest
     */
    body?: UpdateTermbaseRequest
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Update a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPut$Plain(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTermbaseRequest
     */
    body?: UpdateTermbaseRequest
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Update a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPut$Json$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTermbaseRequest
     */
    body?: UpdateTermbaseRequest
  }): Observable<StrictHttpResponse<TermbaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermbaseInfo>;
      })
    );
  }

  /**
   * Update a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPut$Json(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTermbaseRequest
     */
    body?: UpdateTermbaseRequest
  }): Observable<TermbaseInfo> {

    return this.apiTermbasesIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TermbaseInfo>) => r.body as TermbaseInfo)
    );
  }

  /**
   * Path part for operation apiTermbasesIdDelete
   */
  static readonly ApiTermbasesIdDeletePath = '/api/Termbases/{id}';

  /**
   * Delete a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdDelete$Plain$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdDelete$Plain(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiTermbasesIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a specific termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdDelete$Json$Response(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesIdDelete$Json(params: {

    /**
     * Termbase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiTermbasesIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiTermbasesIdPatch
   */
  static readonly ApiTermbasesIdPatchPath = '/api/Termbases/{id}';

  /**
   * Partially update a specific Termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPatch$Plain$Response(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Partially update a specific Termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPatch$Plain(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<boolean> {

    return this.apiTermbasesIdPatch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Partially update a specific Termbase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesIdPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPatch$Json$Response(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Partially update a specific Termbase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesIdPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesIdPatch$Json(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<boolean> {

    return this.apiTermbasesIdPatch$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiTermbasesUploadPost
   */
  static readonly ApiTermbasesUploadPostPath = '/api/Termbases/Upload';

  /**
   * Upload Glossary Files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesUploadPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTermbasesUploadPost$Plain$Response(params?: {
    'api-version'?: string;
    body?: {
'qaSettingsId'?: string;
'files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<DefaultUploadGlossariesResult>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefaultUploadGlossariesResult>;
      })
    );
  }

  /**
   * Upload Glossary Files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesUploadPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTermbasesUploadPost$Plain(params?: {
    'api-version'?: string;
    body?: {
'qaSettingsId'?: string;
'files'?: Array<Blob>;
}
  }): Observable<DefaultUploadGlossariesResult> {

    return this.apiTermbasesUploadPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DefaultUploadGlossariesResult>) => r.body as DefaultUploadGlossariesResult)
    );
  }

  /**
   * Upload Glossary Files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTermbasesUploadPost$Json$Response(params?: {
    'api-version'?: string;
    body?: {
'qaSettingsId'?: string;
'files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<DefaultUploadGlossariesResult>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DefaultUploadGlossariesResult>;
      })
    );
  }

  /**
   * Upload Glossary Files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTermbasesUploadPost$Json(params?: {
    'api-version'?: string;
    body?: {
'qaSettingsId'?: string;
'files'?: Array<Blob>;
}
  }): Observable<DefaultUploadGlossariesResult> {

    return this.apiTermbasesUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DefaultUploadGlossariesResult>) => r.body as DefaultUploadGlossariesResult)
    );
  }

  /**
   * Path part for operation apiTermbasesImportPost
   */
  static readonly ApiTermbasesImportPostPath = '/api/Termbases/Import';

  /**
   * Import a specific Glossary File.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesImportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesImportPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * UploadGlossaryCommand
     */
    body?: GlossaryImportConfiguration
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesImportPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Import a specific Glossary File.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesImportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesImportPost$Plain(params?: {
    'api-version'?: string;

    /**
     * UploadGlossaryCommand
     */
    body?: GlossaryImportConfiguration
  }): Observable<string> {

    return this.apiTermbasesImportPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Import a specific Glossary File.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesImportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesImportPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * UploadGlossaryCommand
     */
    body?: GlossaryImportConfiguration
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesImportPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Import a specific Glossary File.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesImportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesImportPost$Json(params?: {
    'api-version'?: string;

    /**
     * UploadGlossaryCommand
     */
    body?: GlossaryImportConfiguration
  }): Observable<string> {

    return this.apiTermbasesImportPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiTermbasesExportPost
   */
  static readonly ApiTermbasesExportPostPath = '/api/Termbases/Export';

  /**
   * Export a specific Glossary File.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesExportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesExportPost$Response(params?: {
    'api-version'?: string;

    /**
     * ExportGlossaryRequest
     */
    body?: ExportGlossaryRequest
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, TermbasesService.ApiTermbasesExportPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export a specific Glossary File.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesExportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesExportPost(params?: {
    'api-version'?: string;

    /**
     * ExportGlossaryRequest
     */
    body?: ExportGlossaryRequest
  }): Observable<Blob> {

    return this.apiTermbasesExportPost$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
