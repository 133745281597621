import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  SortChangedEvent,
  ColumnEvent,
} from '@ag-grid-community/core/dist/cjs/es6/events';
import { CommonReportViewState } from '@store/common-report-view-store';
import { QASettingsState } from '@store/qa-settings-store';
import { PatchReportGridState } from '@store/report-grids-store/report-grids.actions';
import { ReportGridsState } from '@store/report-grids-store/report-grids.state';
import { SetToolPanelVisibleStatus } from '@store/report-grids-store/report-grids.actions';
import { QualityIssue, ReportGridFilterModel, ReportTab } from '@shared/models';
import { ColumnApi, SideBarDef } from '@ag-grid-community/core';
import { GridPatchesComponent } from '@shared/components/grid-filters/grid.patches.component';
import { DOCUMENT } from '@angular/common';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { REPORT_DEFAULT_COLUMNS_DEF } from '@report/components/report-grid/configs/report-default.columns';
import {
  AgGridColumn,
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ToolPanelOrder,
} from 'src/types/ag-grid';
import { ReportGridContext } from '@report/models';
import { TARGET_LANGUAGE_GROUP_COLUMN } from './target-language-group-column';
import { TARGET_SEGMENT_COLUMN } from './target-segment-column';
import { ColumnState } from '@ag-grid-community/core/dist/cjs/es6/columns/columnModel';
import { mapFromFilterState, mapToColumnState, mapToFilterState } from './mappers';
import { ToolPanelVisibleChangedEvent } from '@ag-grid-community/core';
import {
  KeybindingEvent,
  KeybindingGroups,
  ShortcutsService,
  TableKeybindingEvent,
} from 'src/app/core/services/shortcuts';
import { ReportGridService } from '@report/components/report-grid/services/report-grid.service';

// TODO VW-1266 (refactor):  класс нуждается в обособлении от репортов. Т.к. реализует множества обобщений для работы с таблицами.
// Добавить дженерики. Методы, необходимые для работы с quality issue необходимо делегировать через композицию.
@Component({
  template: '',
})
export class BaseReportGridComponent extends GridPatchesComponent implements OnInit, OnDestroy {
  protected readonly uniqueTableId: string;
  protected destroyed$: Subject<void> = new Subject<void>();
  protected segmentColsWidthChanged$: Subject<boolean> = new Subject<boolean>();
  protected reportTab: ReportTab;
  protected store: Store;
  protected shortcutsService: ShortcutsService;
  protected elementRef: ElementRef;
  protected groupLevelForChildrenCalculation: number;
  protected reportGridService: ReportGridService;
  protected editColId = TARGET_SEGMENT_COLUMN.field;

  #loading: boolean;

  get loading(): boolean {
    return this.#loading;
  }

  @Input()
  set loading(value: boolean) {
    this.#loading = value;
    this.checkShowOrHideNoRowsOverlay();
  }

  gridApi: GridApi;
  columnApi: ColumnApi;
  // TODO VW-1266:  реализовать обобщение для base-report (переименовать), таким образом
  // чтобы не было конфликта типов где класс наследник переопределяет типы родителя
  gridOptions: GridOptions<QualityIssue, ReportGridContext> | any;
  columnDefs: Partial<AgGridColumn>[];
  modules = [ClientSideRowModelModule, ColumnsToolPanelModule, SetFilterModule];
  public readonly defaultColDef: Partial<AgGridColumn> = REPORT_DEFAULT_COLUMNS_DEF;
  public targetLanguageGroupVisible = true;

  #toolPanelOrder: ToolPanelOrder[];
  @Input() set toolPanelOrder(order: ToolPanelOrder[]) {
    this.#toolPanelOrder = order;
    if (this.#toolPanelOrder && this.gridApi) {
      this.initToolPanelOrder();
    }
  }

  get toolPanelOrder(): ToolPanelOrder[] {
    return this.#toolPanelOrder;
  }

  public sideBar: SideBarDef;

  protected eventActions: { [key in TableKeybindingEvent]?: () => void } = {
    [TableKeybindingEvent.NEXT_QUALITY_ISSUE]: () => this.reportGridService.selectNext(),
    [TableKeybindingEvent.PREVIOUS_QUALITY_ISSUE]: () => this.reportGridService.selectPrevious(),
  };

  get baseEventActions(): { [key in TableKeybindingEvent]?: () => void } {
    return this.eventActions;
  }

  constructor(injector: Injector) {
    super(injector.get(DOCUMENT), injector.get(ElementRef));
    this.store = injector.get(Store);
    this.elementRef = injector.get(ElementRef);
    this.shortcutsService = injector.get(ShortcutsService);
    this.reportGridService = injector.get(ReportGridService);
  }

  ngOnInit() {
    this.setGridContextParams();
    this.subscribeOnTagView();
    this.subscribeOnWindowResize();
    this.subscribeOnShowInvisibles();
    this.subscribeOnQASettings();
    this.subscribeOnMultiline();
    this.watchKeybindingEvents();
  }

  public onGridReady(event: GridReadyEvent): void {
    super.onGridReady(event);
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.reportGridService.initApi(this.gridApi, this.columnApi, this.gridOptions.context);
    this.checkShowOrHideNoRowsOverlay();
    this.applyColumnState();
    this.applyFilterState();
    this.restoreToolPanelVisibility();
    this.initToolPanelOrder();
  }

  public changeToolPanelVisibility(event: ToolPanelVisibleChangedEvent): void {
    this.store.dispatch(new SetToolPanelVisibleStatus(this.reportTab, event.source));
  }

  protected toggleEdit(): void {
    const selectedRow = this.reportGridService.getSelected();
    const isEditingMode = this.gridApi.getEditingCells()?.length;
    if (isEditingMode) {
      this.gridApi.stopEditing();
      return;
    }
    this.gridApi.startEditingCell({
      rowIndex: selectedRow.rowIndex,
      colKey: this.editColId,
    });
  }

  protected exitEditMode(): void {
    this.gridApi.stopEditing();
  }

  private setGridContextParams(): void {
    const tagView = this.store.selectSnapshot(CommonReportViewState.tagView);
    const qaSettings = this.store.selectSnapshot(QASettingsState.qaSettings);
    const showInvisibles = this.store.selectSnapshot(CommonReportViewState.showInvisibles);
    this.gridOptions.context = {
      ...this.gridOptions.context,
      tagView,
      showInvisibles,
      widthChanged$: this.segmentColsWidthChanged$.pipe(debounceTime(100), takeUntil(this.destroyed$)),
      protection: qaSettings?.checkSettings?.protection,
      invisibleGroupsFields: [TARGET_LANGUAGE_GROUP_COLUMN.colId],
      groupLevelForChildrenCalculation: this.groupLevelForChildrenCalculation,
    };
  }

  private subscribeOnTagView(): void {
    this.store
      .select(CommonReportViewState.tagView)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((t) => {
        this.gridOptions.context.tagView = t;
        if (this.gridApi && !this.gridApi.getModel().isEmpty()) {
          this.gridApi?.redrawRows();
        }
      });
  }

  private subscribeOnWindowResize(): void {
    fromEvent(this.document, 'resize')
      .pipe(debounceTime(50), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.segmentColsWidthChanged$.next(true);
      });
  }

  private subscribeOnQASettings(): void {
    this.store
      .select(QASettingsState.qaSettings)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((settings) => {
        this.gridOptions.context.protection = settings?.checkSettings?.protection;
      });
  }

  private subscribeOnShowInvisibles(): void {
    this.store
      .select(CommonReportViewState.showInvisibles)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.gridOptions.context.showInvisibles = value;
        if (this.gridApi && !this.gridApi.getModel().isEmpty()) {
          this.gridApi?.refreshCells({ force: true });
        }
      });
  }

  private subscribeOnMultiline(): void {
    this.store
      .select(CommonReportViewState.multiline)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.gridOptions.context.multiline = value;
        if (this.gridApi && !this.gridApi.getModel().isEmpty()) {
          this.gridApi?.redrawRows();
        }
      });
  }

  protected watchKeybindingEvents(): void {
    this.shortcutsService
      .watchGroup(KeybindingGroups.TABLE, this.uniqueTableId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: KeybindingEvent) => {
        this.eventActions[event]?.();
      });
  }

  public columnMoved(event: ColumnMovedEvent): void {
    this.columnStateChanged(event);
  }

  public onColumnResized(params: ColumnResizedEvent): void {
    const columns = params.columns;
    if (columns.some((col) => col?.getColId() === 'source' || col?.getColId() === 'target')) {
      this.segmentColsWidthChanged$.next(true);
    }
  }

  public sortChanged(event: SortChangedEvent): void {
    this.columnStateChanged(event);
    this.refreshLanguageGroupColumn();
  }

  private refreshLanguageGroupColumn(): void {
    this.gridApi.refreshCells({
      force: true,
      suppressFlash: true,
      columns: [TARGET_LANGUAGE_GROUP_COLUMN.colId],
    });
  }

  public columnVisible(event: ColumnVisibleEvent): void {
    this.checkVerticalColumnVisible(event);
    this.columnStateChanged(event);
  }

  private checkVerticalColumnVisible(event: ColumnVisibleEvent): void {
    const targetLanguageColumn = event.columns.find((col) => (col as any).colId === TARGET_LANGUAGE_GROUP_COLUMN.colId);
    if (targetLanguageColumn) {
      this.targetLanguageGroupVisible = event.visible;
    }
  }

  public filterChanged(event: FilterChangedEvent): void {
    this.filterStateChanged(event);
    this.checkShowOrHideNoRowsOverlay();
  }

  private columnStateChanged(event: ColumnEvent | SortChangedEvent): void {
    // Suppress save state if event is called by api
    if (event.source === 'api') {
      return;
    }
    this.saveColumnState(event.columnApi.getColumnState());
  }

  private saveColumnState(columnState: ColumnState[]): void {
    const columns = columnState.map(mapToColumnState);
    this.store.dispatch(
      new PatchReportGridState(this.reportTab, {
        columns,
      })
    );
  }

  private filterStateChanged(event: FilterChangedEvent): void {
    const filterModel: ReportGridFilterModel = event.api.getFilterModel();
    this.saveFilterState(filterModel);
  }

  protected saveFilterState(filterModel: ReportGridFilterModel): void {
    const filters = mapToFilterState(filterModel);
    this.store.dispatch(
      new PatchReportGridState(this.reportTab, {
        filters,
      })
    );
  }

  protected applyColumnState(): void {
    const state = this.store.selectSnapshot(ReportGridsState.reportGridState(this.reportTab));
    const columnState = state.columns;
    if (!columnState) {
      return;
    }

    this.columnApi.applyColumnState({
      state: columnState,
      applyOrder: true,
    });
  }

  protected restoreToolPanelVisibility(): void {
    if (!this.sideBar) {
      return;
    }
    this.sideBar = {
      ...this.sideBar,
      defaultToolPanel: this.store.selectSnapshot(ReportGridsState.reportGridState(this.reportTab)).visibleToolPanel,
    };
  }

  private applyFilterState(): void {
    const reportGridState = this.store.selectSnapshot(ReportGridsState.reportGridState(this.reportTab));
    const filterModel = mapFromFilterState(reportGridState?.filters);
    this.gridApi.setFilterModel(filterModel);
  }

  private initToolPanelOrder(): void {
    if (!this.toolPanelOrder) {
      return;
    }
    // NOTE: async action for getting the real column instance (recreated by ag-grid)
    setTimeout(() => {
      const toolPanel = this.gridApi.getToolPanelInstance('columns');
      toolPanel.setColumnLayout(this.toolPanelOrder);
    });
  }

  public checkShowOrHideNoRowsOverlay(): void {
    if (!this.gridApi) {
      return;
    }
    const emptyGrid = this.gridApi.getModel().isEmpty();
    if (this.loading && emptyGrid) {
      this.gridApi.showLoadingOverlay();
      return;
    }
    if (emptyGrid) {
      return;
    }
    if (!this.gridApi.getDisplayedRowCount()) {
      this.gridApi.showNoRowsOverlay();
      return;
    }
    this.gridApi.hideOverlay();
  }

  public gridKeyDown({ event }: { event: KeyboardEvent }): void {
    this.shortcutsService.handleKeydownEvent(
      [KeybindingGroups.TABLE, KeybindingGroups.CONTROL_PANEL],
      event,
      this.uniqueTableId
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
