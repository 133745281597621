<label>{{ label }}</label>

<app-single-select
  [tabIndex]="tabIndex"
  [placeholder]="placeholder"
  [ngModel]="selectedId"
  (ngModelChange)="valueChanged($event)"
  [options]="languageItems"
  labelKey="name"
  valueKey="id"
  [searchable]="true"
>
</app-single-select>
