import { AgGridColumn, GridColumnParams, ICellRendererParams } from '../../../../types/ag-grid';
import { buildSegmentComporator } from '@shared/tools';
import { TranslationUnitModel } from '@generated/api';
import { RenderItem } from '@shared/models';
import { GridSegmentCellRendererComponent } from '@shared/components/grid-components';

export const TARGET_SEGMENT_COLUMN: Partial<AgGridColumn> = {
  headerName: 'Target segment',
  field: 'target',
  colId: 'target',
  filter: false,
  sortable: true,
  flex: 5,
  cellRenderer: GridSegmentCellRendererComponent.rendererName,
  cellClass: ['segment-cell', 'cell-value-large', 'ag-cell--target-segment'],
  comparator: buildSegmentComporator((qi?: { translationUnit: TranslationUnitModel }) => qi?.translationUnit.target),
  valueGetter: (params: GridColumnParams<{ targetRenderItems: RenderItem[] }>) => params.data?.targetRenderItems,
  sort: 'asc',
  autoHeight: true,
  cellRendererParams: {
    rightToLeftGetter: (params: ICellRendererParams) =>
      params.data.translationUnit.projectFile?.targetLanguage?.rightToLeft,
  },
};
