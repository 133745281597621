import { Inject, Injectable, Optional } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { OverlayService } from './overlay/overlay.service';
import { TooltipPropertiesService } from './tooltip-properties.service';

import { TooltipProperties, TooltipState } from './interfaces';
import { defaultProperties as tooltipDefaultProperties } from './default-properties';
import { BehaviorSubject } from 'rxjs';
import { uuid } from '@shared/tools';

@Injectable()
export class TooltipService {
  private properties: TooltipProperties;
  private hideTimeoutId: number;
  private showTimeoutId: number;
  private tooltipId = uuid();
  public tooltipState$ = new BehaviorSubject<TooltipState>(TooltipState.Closed);

  constructor(public overlay: OverlayService, @Optional() @Inject(TooltipPropertiesService) private initProperties) {}

  private get showDelay(): number {
    if (this.properties) {
      return this.properties.trigger === 'click' ? 0 : this.properties.showDelay;
    } else {
      return 0;
    }
  }

  private applyPropertiesDefaults(defaults: TooltipProperties, properties: TooltipProperties): TooltipProperties {
    if (!properties) {
      properties = {};
    }

    for (const property in properties) {
      if (properties[property] === undefined) {
        delete properties[property];
      }
    }

    const defaultProperties = Object.assign({}, defaults, this.initProperties || {});
    return Object.assign(defaultProperties, properties);
  }

  public show(properties: TooltipProperties): void {
    const state = this.tooltipState$.getValue();

    if (state === TooltipState.Closing) {
      this.tooltipState$.next(TooltipState.Opened);
      clearTimeout(this.hideTimeoutId);
      return;
    }

    if (state === TooltipState.Closed) {
      this.tooltipState$.next(TooltipState.Opening);
      this.showTimeoutId = this.showTimeoutId = window.setTimeout(() => this.internalShow(properties), this.showDelay);
      return;
    }
  }

  private internalShow(properties: TooltipProperties): void {
    this.properties = this.applyPropertiesDefaults(tooltipDefaultProperties, properties);
    this.overlay.load(
      {
        id: this.tooltipId,
        mainComponent: TooltipComponent,
        childComponent: this.properties.component,
        width: this.properties.width,
        height: this.properties.height,
        maxWidth: this.properties.maxWidth,
        minWidth: this.properties.minWidth,
        animationDisable: this.properties.animationDisable,
        zIndex: this.properties.zIndex,
        metadata: {
          placement: this.properties.placement,
          autoPlacement: this.properties.autoPlacement,
          alignToCenter: this.properties.alignToCenter,
          event: properties.event,
          element: this.properties.element,
          tooltipText: this.properties.tooltipText,
          targetElement: this.properties.targetElement,
          offset: this.properties.offset,
          theme: this.properties.theme,
          tooltipClass: this.properties.tooltipClass,
          padding: this.properties.padding,
          noArrow: this.properties.noArrow,
          left: this.properties.left,
          top: this.properties.top,
          position: this.properties.position,
          whiteSpace: this.properties.whiteSpace,
          borderRadius: this.properties.borderRadius,
          shadow: this.properties.shadow,
          pointerEvents: this.properties.pointerEvents,
          fontSize: this.properties.fontSize,
          hideOnClick: this.properties.hideOnClick,
        },
      },
      {
        closed: () => this.tooltipClosed,
        mouseIn: this.tooltipMouseIn,
        mouseOut: this.tooltipMouseOut,
      }
    );
    this.tooltipState$.next(TooltipState.Opened);
  }

  public hideWithDelay(): void {
    const state = this.tooltipState$.getValue();
    if (state === TooltipState.Closed || state === TooltipState.Closing) {
      return;
    }

    if (state === TooltipState.Opening) {
      this.tooltipState$.next(TooltipState.Closed);
      clearTimeout(this.showTimeoutId);
      return;
    }

    if (state === TooltipState.Opened) {
      this.tooltipState$.next(TooltipState.Closing);
      this.hideTimeoutId = window.setTimeout(() => this.internalHide(), this.properties?.hideDelay);
    }
  }

  private internalHide(): void {
    this.overlay.close(this.tooltipId);
    this.tooltipState$.next(TooltipState.Closed);
  }

  public hide(): void {
    const state = this.tooltipState$.getValue();
    if (state === TooltipState.Closing) {
      clearTimeout(this.hideTimeoutId);
      this.internalHide();
      return;
    }

    if (state === TooltipState.Opening) {
      clearTimeout(this.showTimeoutId);
      this.tooltipState$.next(TooltipState.Closed);
      return;
    }

    if (state === TooltipState.Opened) {
      this.internalHide();
      return;
    }
  }

  private tooltipMouseIn = (): void => {
    if (this.properties.trigger !== 'hover') {
      return;
    }
    clearTimeout(this.hideTimeoutId);
    this.tooltipState$.next(TooltipState.Opened);
  };

  private tooltipMouseOut = (): void => {
    if (this.properties.trigger !== 'hover') {
      return;
    }
    this.hideWithDelay();
  };

  private tooltipClosed = (): void => {
    this.tooltipState$.next(TooltipState.Closed);
    clearTimeout(this.hideTimeoutId);
  };
}
