<app-ui-modal [disableClose]="true">
  <div class="organization-invite">
    <div class="title">
      <app-ui-icon icon="user"></app-ui-icon>
      Invite colleagues
    </div>
    <div class="description">
      Work together with your colleagues and linguists by sharing a single workspace for your QA projects.
    </div>
    <div class="modal-content">
      <mat-form-field class="email-chips" appearance="fill">
        <mat-chip-list #chipList aria-label="Email, comma separated">
          <mat-chip
            *ngFor="let email of emails.value; let i = index"
            (removed)="removeEmail(i)"
            [selectable]="true"
            [ngClass]="{
              selected: i === selectedEmailIndex,
              error: emails.controls[i].invalid
            }"
            class="email-chip"
          >
            {{ email }}
            <button matChipRemove>
              <ng-container [ngSwitch]="true">
                <app-ui-icon
                  *ngSwitchCase="emails.controls[i].invalid && i === selectedEmailIndex"
                  icon="circle-cross"
                  color="white"
                  size="xs"
                ></app-ui-icon>
                <app-ui-icon
                  *ngSwitchCase="emails.controls[i].invalid && i !== selectedEmailIndex"
                  icon="circle-cross"
                  size="xs"
                  color="red"
                ></app-ui-icon>
                <app-ui-icon
                  *ngSwitchCase="emails.controls[i].valid && i === selectedEmailIndex"
                  icon="circle-cross"
                  size="xs"
                  color="primary-dark"
                ></app-ui-icon>
                <app-ui-icon *ngSwitchDefault icon="circle-cross" size="xs" color="gray"></app-ui-icon>
              </ng-container>
            </button>
          </mat-chip>
          <input
            #emailInput
            placeholder="Email, comma separated"
            type="text"
            (keydown.backspace)="trySelectPreviousEmail()"
            (focus)="resetSelectedEmailIndex()"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="addEmail($event)"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="footer">
      <app-button (clickButton)="cancel()">Later</app-button>
      <app-button (clickButton)="invite()" [disabled]="!emails.length || emails.invalid" theme="primary"
        >Invite</app-button
      >
    </div>
  </div>
</app-ui-modal>
