import { StateRepository } from '@angular-ru/ngxs/decorators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcceptInviteResult, InvitedMember, InvitesService, Organization, OrganizationsService } from '@generated/api';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { isForbiddenError } from '@shared/tools';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { SetCurrentProject } from '@store/project-store';
import { ReloadProjects } from '@store/projects-store/projects.actions';
import { catchError, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/modules/auth';
import { LoadBalance, LoadSubscriptionDetails, LoadSubscriptionProducts } from '../payment-store';
import {
  TryAcceptInvite,
  CreateOrganization,
  InviteUsers,
  JoinOrganization,
  LoadOrganizations,
  SelectOrganizationById,
  OrganizationCreated,
} from './organizations.actions';

interface StateModel {
  organizations: Organization[];
  currentOrganization?: Organization;
  joinedOrganizationId: string;
  inviteToken?: string;
}

@AsyncStorage
@StateRepository()
@State<StateModel>({
  name: 'organizations',
  defaults: {
    organizations: [],
    currentOrganization: undefined,
    joinedOrganizationId: null,
    inviteToken: null,
  },
})
@Injectable()
export class OrganizationsState {
  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly authService: AuthService,
    private readonly invitesSerivce: InvitesService
  ) {}

  @Selector()
  public static organizations(state: StateModel): Organization[] {
    return state.organizations;
  }

  @Selector()
  public static currentOrganization(state: StateModel): Organization {
    return state.currentOrganization;
  }

  @Selector()
  public static joinedOrganizationId(state: StateModel): string {
    return state.joinedOrganizationId;
  }

  @Selector()
  public static inviteToken(state: StateModel): string {
    return state.inviteToken;
  }

  @Action(LoadOrganizations)
  public loadOrganisations(ctx: StateContext<StateModel>): Observable<any> {
    return this.organizationsService.apiOrganizationsGet$Json().pipe(
      tap((organizations: Organization[]) => {
        ctx.setState(patch({ organizations }));
      })
    );
  }

  @Action(CreateOrganization)
  public createOrganization(ctx: StateContext<StateModel>, action: CreateOrganization): Observable<any> {
    return this.organizationsService
      .apiOrganizationsPost$Json({
        body: action,
      })
      .pipe(
        tap((organization: Organization) => {
          ctx.setState(
            patch({
              organizations: append([organization]),
            })
          );
        }),
        switchMap((org) => ctx.dispatch(new OrganizationCreated(org.name, org.id)))
      );
  }

  @Action(JoinOrganization)
  public joinOrganization(ctx: StateContext<StateModel>, action: JoinOrganization): any {
    ctx.setState(
      patch({
        joinedOrganizationId: action.id,
      })
    );
    this.authService.joinOrganization();
  }

  @Action(SelectOrganizationById)
  public selectOrganizationById(ctx: StateContext<StateModel>, action: SelectOrganizationById): Observable<any> {
    const organization = ctx.getState().organizations.find((o) => o.id === action.id);
    ctx.setState(
      patch({
        currentOrganization: organization,
      })
    );

    ctx.dispatch(new LoadBalance());
    ctx.dispatch(new LoadSubscriptionDetails());
    return ctx.dispatch(new ReloadProjects());
  }

  @Action(InviteUsers)
  public inviteUsers(ctx: StateContext<StateModel>, action: InviteUsers): Observable<any> {
    const defaultRole = 'Employee';

    const inviteMembers = action.emails.map((email) => {
      const role: InvitedMember = { email, roleName: defaultRole };
      return role;
    });

    return this.invitesSerivce.apiInvitesPost({
      body: {
        members: inviteMembers,
      },
    });
  }
  @Action(TryAcceptInvite)
  public acceptInvite(ctx: StateContext<StateModel>, action: TryAcceptInvite): Observable<any> {
    const inviteToken = ctx.getState().inviteToken ?? action.inviteToken;

    if (!inviteToken) {
      return;
    }

    ctx.dispatch(new SetCurrentProject(null));
    ctx.setState(patch({ inviteToken: action.inviteToken }));

    return this.invitesSerivce
      .apiInvitesAcceptPost$Json({
        body: {
          token: inviteToken,
        },
      })
      .pipe(
        // NOTE: https://verifika.youtrack.cloud/issue/VW-1361/Organizacii-i-dobavlenie-novyh-polzovatelej#focus=Comments-83-27060.0-0
        // NOTE: be careful, this method can forward to recursive redirect to the login page
        catchError((err: HttpErrorResponse) => {
          if (isForbiddenError(err)) {
            this.authService.login();
          }
          return throwError(() => err);
        }),
        tap(() => {
          ctx.setState(patch({ inviteToken: null }));
        }),
        switchMap((rspns: AcceptInviteResult) => ctx.dispatch(new LoadOrganizations()).pipe(map(() => rspns))),
        switchMap((rspns: AcceptInviteResult) => ctx.dispatch(new JoinOrganization(rspns.organizationId)))
      );
  }
}
