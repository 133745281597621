import { IssueType, ReportProgressStatus } from '@generated/api';
import { SubscriptionStatus } from '@generated/payment-api';

enum HubEventTypes {
  RECEIVE_PROCESSING_STATE = 'progress',
  TRANSLATION_UNIT_CHANGE_ADDED = 'translation-unit-change-added',
  TRANSLATION_UNIT_CHANGE_DELETED = 'translation-unit-changes-deleted',
  PROJECT_CHANGED = 'project-changed',
  QUALITY_ISSUE_CHANGED = 'quality-issues-changed',
  REPORT_EXPORT_COMPLETED = 'report-export-completed',
  COMMENT_THREAD_CREATED = 'comment-thread-created',
  COMMENT_THREAD_REMOVED = 'comment-thread-removed',
  COMMENT_THREAD_RESOLVED = 'comment-thread-resolved',
  COMMENT_THREAD_RESTORED = 'comment-thread-restored',
  COMMENT_CREATED = 'comment-created',
  COMMENT_CHANGED = 'comment-changed',
  COMMENT_REMOVED = 'comment-removed',
  COMMENT_RESTORED = 'comments-restored',
  PROJECT_FILES_REMOVED = 'project-files-removed',
  PROJECT_FILE_CREATED = 'project-file-created',
  PROJECT_FILES_CHANGED = 'project-files-changed',
  PROJECT_CREATED = 'project-created',
  QASETTINGS_CHANGED = 'qasettings-changed',
  QASETTINGS_REMOVED = 'qasettings-removed',
  QASETTINGS_CREATED = 'qasettings-created',
  QUALITY_ISSUES_IGNORED = 'quality-issues-ignored',
  POTENTIAL_UNTRANSLATABLES_COMPLETED = 'potential-untranslatables-completed',
  SUBSCRIPTION_CHANGED = 'subscription-changed',
  BALANCE_CHANGED = 'balance-changed',
}

export interface CommonHubEvent {
  type: HubEventTypes;
  skipCurrentTabEvent?: boolean;
  payload: any;
}

export class RecieveProgressingStateEvent {
  static readonly type = HubEventTypes.RECEIVE_PROCESSING_STATE;
  static readonly payload: {
    reportId: string;
    projectId: string;
    projectVersion: string;
    status: ReportProgressStatus;
    data: {
      groupId?: string;
      issueType: IssueType;
    };
  };
}

export class QualityIssueChangedEvent {
  static readonly type = HubEventTypes.QUALITY_ISSUE_CHANGED;

  static readonly payload: {
    createdIssueIds: string[];
    deletedIssueIds: string[];
    existedIssueIds: string[];
    groupId: number;
    issueType: number;
  };
}

export class TranslationUnitChangeAddedEvent {
  static readonly type = HubEventTypes.TRANSLATION_UNIT_CHANGE_ADDED;
  static readonly payload: { id: string };
}

export class TranslationUnitChangeDeletedEvent {
  static readonly type = HubEventTypes.TRANSLATION_UNIT_CHANGE_DELETED;
  static readonly payload: { ids: string[] };
}

export class ProjectChangedEvent {
  static readonly type = HubEventTypes.PROJECT_CHANGED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string };
}

export class ReportExportCompleted {
  static readonly type = HubEventTypes.REPORT_EXPORT_COMPLETED;
  static readonly payload: {
    reportId: string;
    completed: boolean;
    errors: string;
  };
}

export class CommentThreadCreatedEvent {
  static readonly type = HubEventTypes.COMMENT_THREAD_CREATED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string; createdOn: string };
}

export class CommentThreadRemovedEvent {
  static readonly type = HubEventTypes.COMMENT_THREAD_REMOVED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string };
}

export class CommentThreadResolvedEvent {
  static readonly type = HubEventTypes.COMMENT_THREAD_RESOLVED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string; isResolved: boolean };
}

export class CommentThreadRestoredEvent {
  static readonly type = HubEventTypes.COMMENT_THREAD_RESTORED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string };
}

export class CommentCreatedEvent {
  static readonly type = HubEventTypes.COMMENT_CREATED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string; threadId: string; createdOn: string };
}

export class CommentChangedEvent {
  static readonly type = HubEventTypes.COMMENT_CHANGED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { commentId: string; threadId: string; modifiedOn: string };
}

export class CommentRemovedEvent {
  static readonly type = HubEventTypes.COMMENT_REMOVED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { id: string; threadId: string };
}

export class CommentRestoredEvent {
  static readonly type = HubEventTypes.COMMENT_RESTORED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { commentIds: string[]; threadId: string };
}

export class ProjectFilesRemoved {
  static readonly type = HubEventTypes.PROJECT_FILES_REMOVED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { projectId: string; projectFileIds: string[] };
}

export class ProjectFileCreated {
  static readonly type = HubEventTypes.PROJECT_FILE_CREATED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { projectId: string; projectFileId: string };
}

export class ProjectFilesChanged {
  static readonly type = HubEventTypes.PROJECT_FILES_CHANGED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: { projectFileIds: string[] };
}

export class ProjectCreatedEvent {
  static readonly type = HubEventTypes.PROJECT_CREATED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: any;
}

export class QaSettingsChangedEvent {
  static readonly type = HubEventTypes.QASETTINGS_CHANGED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: {
    checkSettingsHash: string;
    currentUserId: string;
    id: string;
    modifiedOn: string;
    projectId: string;
  };
}

export class QaSettingsCreatedEvent {
  static readonly type = HubEventTypes.QASETTINGS_CREATED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: {
    currentUserId: string;
    id: string;
    createOn: string;
    projectId: string;
  };
}

export class QaSettingsRemovedEvent {
  static readonly type = HubEventTypes.QASETTINGS_REMOVED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: {
    currentUserId: string;
    qaSettingsId: string;
    projectId: string;
  };
}

export class QualityIssuesIgnoredEvent {
  static readonly type = HubEventTypes.QUALITY_ISSUES_IGNORED;
  static readonly skipCurrentTabEvent = true;
  static readonly payload: {
    ignoredIssues: {
      qualityIssueId: string;
      isIgnored: boolean;
    }[];
    issueType: IssueType;
  };
}

export class PotentialUntranslatablesCompletedEvent {
  static readonly type = HubEventTypes.POTENTIAL_UNTRANSLATABLES_COMPLETED;
  static readonly payload: {
    projectId: string;
    count: number;
  };
}

export class SubscriptionChangedEvent {
  static readonly type = HubEventTypes.SUBSCRIPTION_CHANGED;
  static readonly payload: {
    id: string;
    status: SubscriptionStatus;
    createdOn: string;
    nextChargeDate: string;
  };
}

export class BalanceChangedEvent {
  static readonly type = HubEventTypes.BALANCE_CHANGED;
  static readonly payload: void;
}
