<ng-container *ngIf="app.loaded$ | async">
  <app-header
    offlineTooltipText="You can continue with setting up checks.&#13;Changes will be saved once connection is restored."
  >
    <ng-container leftActions>
      <app-header-projects></app-header-projects>
      <app-header-profiles></app-header-profiles>
    </ng-container>
    <ng-container rightActions>
      <ng-container *ngIf="!(subscriptionDetails$ | async)?.product">
        <app-subscription-status *whenRole="'registered'" [showProgress]="true" type="short"></app-subscription-status>
      </ng-container>
      <app-action-icon
        *whenRole="'registered'"
        icon="settings"
        theme="circle"
        size="xl"
        routerLink="/organization/subscription/overview"
      ></app-action-icon>
      <app-help-button></app-help-button>
    </ng-container>
  </app-header>
  <main role="main">
    <router-outlet></router-outlet>
  </main>
</ng-container>
