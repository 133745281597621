import { TagView } from '@shared/models';

enum ActionTypes {
  TOGGLE_SHOW_INVISIBLES = '[Report View] Toggle show invisibles',
  TOGGLE_MULTILINE = '[Report View] Toggle multiline',
  SET_TAG_VIEW = '[Report View] Set tag view',
  TOGGLE_SHOW_COMMENT = '[Report View] Toggle show comment',
}

export class ToggleShowInvisibles {
  static readonly type = ActionTypes.TOGGLE_SHOW_INVISIBLES;
  constructor(public status: boolean) {}
}

export class ToggleMultiline {
  static readonly type = ActionTypes.TOGGLE_MULTILINE;
  constructor(public value: boolean) {}
}

export class SetTagView {
  static readonly type = ActionTypes.SET_TAG_VIEW;
  constructor(public tagView: TagView) {}
}

export class ToggleShowComment {
  static readonly type = ActionTypes.TOGGLE_SHOW_COMMENT;
  constructor(public status?: boolean) {}
}
