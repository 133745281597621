/* tslint:disable */
/* eslint-disable */
export enum FilterPosition {
  FollowingHighlight = 0,
  PrecedingHighlight = 1,
  BeforeHighlight = 2,
  InsideHighlight = 3,
  AfterHighlight = 4,
  OutsideHighlight = 5,
  Any = 6
}
