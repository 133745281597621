<div class="wrapper">
  <div class="header">
    <div class="filter-title">Comments</div>
    <app-single-select [formControl]="filterControl">
      <app-option [value]="threadTypes.All">All</app-option>
      <app-option [value]="threadTypes.Open">Open</app-option>
      <app-option [value]="threadTypes.Resolved">Resolved</app-option>
    </app-single-select>
  </div>
  <div class="threads">
    <app-comment-thread
      *ngFor="let thread of threads$ | async"
      [issueKindText]="thread.issueKindText"
      [quotes]="thread.data?.quotes"
      [comments]="thread.comments"
      [isUserDefined]="thread.isUserDefined"
      [isResolved]="thread.isResolved"
      [expanded]="true"
      (threadDeleted)="deleteThread(thread)"
      (commentDeleted)="deleteComment($event)"
      (commentAdded)="addComment($event, thread)"
      (commentEdited)="editComment($event)"
      (isResolvedChanged)="isResolvedChanged(thread.id, $event)"
      (threadClicked)="threadClicked(thread)"
    ></app-comment-thread>
    <div class="empty">
      <app-ui-icon icon="chat" size="normal"></app-ui-icon>
      {{ emptyPlaceholderText[this.filterControl.value] }}
    </div>
  </div>
</div>
