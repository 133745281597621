import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import type { IHeaderAngularComp, IHeaderParams } from 'src/types/ag-grid';
import { IconDescription } from '@shared/components/icon';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridHeaderComponent implements IHeaderAngularComp, OnDestroy {
  static rendererName = 'HeaderComponent';

  public params: IHeaderParams;
  public filterOpened: boolean = false;
  public sortOrder: 'asc' | 'desc';
  public filtered = false;
  public icon: IconDescription;

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLHtmlElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.filterOpened = false;
    }
  }

  constructor(private readonly cdr: ChangeDetectorRef, private elementRef: ElementRef<HTMLHtmlElement>) {}

  ngOnDestroy() {
    if (this.params.iconGetter) {
      this.params.column.removeEventListener('filterChanged', this.prepareIcon);
    }
  }

  public agInit(params: IHeaderParams): void {
    this.params = params;
    if (this.params.iconGetter) {
      this.prepareIcon();
      this.params.column.addEventListener('filterChanged', this.prepareIcon);
    }
  }

  public refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.cdr.markForCheck();
    return true;
  }

  public onSortRequested(event: MouseEvent): void {
    if (!this.params.enableSorting) {
      return;
    }

    if (!this.sortOrder) {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : undefined;
    }
    this.params.setSort(this.sortOrder, !!event.shiftKey);
    this.filterOpened = false;
  }

  public toggleFilter(): void {
    if (!this.params.enableMenu) {
      return;
    }
    this.toggleFilterVisibility();
    if (this.filterOpened) {
      this.params.showColumnMenu(this.elementRef.nativeElement.parentElement);
    }
  }

  private toggleFilterVisibility(): void {
    this.filterOpened = !this.filterOpened;
  }

  private prepareIcon = (): void => {
    const filters = this.params.api.getFilterModel();
    const colId = this.params.column.getColId();
    const colFilterValue = filters[colId];
    this.icon = this.params.iconGetter(colFilterValue);
  };
}
