import { PartOfSegment } from '@shared/components/segment-editor/models/part-of-segment';
import { SegmentAction } from './segment-action';
import { SegmentActionsTypes } from './segment-actions-types.enum';

export class InsertSegmentAction extends SegmentAction {
  constructor(public partOfSegments: PartOfSegment, public start: number) {
    super(SegmentActionsTypes.Insert);
    if (!this.partOfSegments.tags) {
      this.partOfSegments.tags = [];
    }
  }
}
