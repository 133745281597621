import { Component, ChangeDetectionStrategy, Input, forwardRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseSelectComponent } from '@shared/components/select-v2/base-select.component';
import { propComparator } from '@shared/tools';

type ValueType = string | number | boolean;

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectComponent),
      multi: true,
    },
  ],
})
// TODO: VW-907
export class SingleSelectComponent<OptionType> extends BaseSelectComponent<ValueType, OptionType> {
  @Input() groupBy: string;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() sort: keyof OptionType | ((a: OptionType, b: OptionType) => number);

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public displayedOptionsChanged(): void {
    if (this.sort) {
      const comparator = typeof this.sort === 'function' ? this.sort : propComparator<OptionType>(this.sort);
      this.displayedOptions = this.displayedOptions.sort(comparator);
    }
  }

  public writeValue(value: ValueType): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  private propagateChanges(): void {
    this.valueChange.emit(this.value);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  public valueChanged(event: ValueType): void {
    this.value = this.valueKey != null ? event : event[this.valueKey];
    this.propagateChanges();
  }
}
