<app-ui-modal modalType="withHeader" size="large">
  <div class="modal-title">
    <app-ui-icon class="title-icon" icon="logo" size="large"></app-ui-icon>
    <span class="title-text">Context viewer </span>
    <div class="vertical-line"></div>
    <span class="title-text">{{ fileName }}</span>
  </div>
  <div class="modal-content" [appLoader]="showLoader">
    <ag-grid-angular
      *ngIf="gridOptions && showGrid"
      class="ag-theme-tu context-viewer-grid"
      [gridOptions]="$any(gridOptions)"
      [columnDefs]="$any(columnDefs)"
      [defaultColDef]="defaultColDef"
      [rowData]="translationUnits"
      [animateRows]="false"
      [modules]="modules"
      (gridReady)="onGridReady($any($event))"
      (cellKeyDown)="gridKeyDown($any($event))"
      (columnResized)="columnResized$.next($event)"
      (cellValueChanged)="cellValueChanged($event)"
      (firstDataRendered)="firstDataRendered()"
      (cellClicked)="cellClicked($any($event))"
    >
    </ag-grid-angular>
    <div class="info-message" *ngIf="offline">
      <div>Context viewer is unavailable offline.</div>
      <div>Please try to open it once connection is restored.</div>
    </div>
    <div class="info-message" *ngIf="loadingError && !offline">
      <div>Context viewer failed to connect to server.</div>
      <div>Please close this window and try to open it again.</div>
    </div>
  </div>
</app-ui-modal>
