import { PipesModule } from './../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '../tooltip';
import { TagComponent } from '@shared/components/tag/tag.component';

@NgModule({
  imports: [CommonModule, TooltipModule, PipesModule],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagModule {}
