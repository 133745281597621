import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonReportViewState, ReportGridsState, SetTagView, ToggleMultiline, ToggleShowInvisibles } from '@store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShortcutsService } from '../../../../core/services';
import { TagView } from '@shared/models';
import { ControlPanelKeybindingEvent } from '../../../../core/services/shortcuts';
import { buildReportExportModel } from '@report/modules/export';
import { ExportReport } from '@store/report-store/report.actions';

@Component({
  selector: 'app-report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsComponent implements OnInit, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public showInvisibles = false;
  public tagView: TagView;
  public multiline = false;
  public view = TagView;
  controlPanelKeybindingEvent = ControlPanelKeybindingEvent;

  constructor(private store: Store, public shortcutsService: ShortcutsService) {}

  ngOnInit(): void {
    this.watchInvisibles();
    this.watchMultiline();
    this.watchTags();
  }

  watchInvisibles() {
    this.store
      .select(CommonReportViewState.showInvisibles)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.showInvisibles = value;
      });
  }

  watchTags() {
    this.store
      .select(CommonReportViewState.tagView)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.tagView = value;
      });
  }

  watchMultiline() {
    this.store
      .select(CommonReportViewState.multiline)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.multiline = value;
      });
  }

  changeShowInvisibles(newValue: boolean): void {
    this.store.dispatch(new ToggleShowInvisibles(newValue));
  }

  changeMultiline(newValue: boolean): void {
    this.store.dispatch(new ToggleMultiline(newValue));
  }

  changeTagsView(newValue: TagView): void {
    this.store.dispatch(new SetTagView(newValue));
  }

  export() {
    const gridsState = this.store.selectSnapshot(ReportGridsState.reportGridsState());
    const exportModel = buildReportExportModel(gridsState);
    this.store.dispatch(new ExportReport(exportModel));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
