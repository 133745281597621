import { TooltipProperties } from './interfaces';

export const defaultProperties: TooltipProperties = {
  theme: 'dark',
  placement: 'bottom',
  autoPlacement: true,
  alignToCenter: true,
  overlayBackdrop: false,
  // width: '300px',
  maxWidth: '500px',
  height: 'auto',
  offset: 0,
  animationDisable: false,
  zIndex: 1000,
  hideDelay: 100,
  showDelay: 300,
  trigger: 'hover',
  display: true,
  hideOnClick: true,
};
