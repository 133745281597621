import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommentThread } from '@shared/models';
import { Observable, Subject, switchMap } from 'rxjs';
import {
  CommentCreate,
  CommentRemove,
  CommentRestore,
  CommentsState,
  CommentThreadRemove,
  CommentThreadResolve,
  CommentThreadRestore,
  CommentUpdate,
  UpdateQualityIssueCommentStatus,
} from '@store';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UpdateSearchResultCommentStatus } from '@store/search-in-report-files';
import { MatDialogRef } from '@angular/material/dialog';
import { CommentModel } from '@generated/api';
import { NotificationService } from '@shared/components/notification';

enum ThreadTypes {
  Resolved = 1,
  Open = 2,
  All = 3,
}

@Component({
  selector: 'app-comments-popup',
  templateUrl: './comments-popup.component.html',
  styleUrls: ['./comments-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsPopupComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject<void>();
  public threads$: Observable<CommentThread[]>;

  public filterControl = new FormControl(ThreadTypes.All);

  public threadTypes = ThreadTypes;

  public emptyPlaceholderText = {
    [ThreadTypes.All]: 'No comments yet',
    [ThreadTypes.Open]: 'No Open comments',
    [ThreadTypes.Resolved]: 'No Resolved comments',
  };

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<any>,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.watchCommentThreads();
  }

  private watchCommentThreads(): void {
    this.threads$ = this.store.select(CommentsState.projectComments).pipe(
      switchMap((treads) =>
        this.filterControl.valueChanges.pipe(
          startWith(this.filterControl.value),
          map((filterValue: ThreadTypes) =>
            treads.filter(
              (thread) =>
                filterValue === ThreadTypes.All ||
                (filterValue === ThreadTypes.Resolved && thread.isResolved) ||
                (filterValue === ThreadTypes.Open && !thread.isResolved)
            )
          )
        )
      )
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public deleteThread(thread: CommentThread): void {
    this.store.dispatch(new CommentThreadRemove(thread.id));
    const tuHasAnotherThread = !!this.store.selectSnapshot(
      CommentsState.translationUnitComments(thread.translationUnitId)
    ).length;
    if (!tuHasAnotherThread) {
      this.store.dispatch(new UpdateQualityIssueCommentStatus(thread.translationUnitId, false));
      this.store.dispatch(new UpdateSearchResultCommentStatus(thread.translationUnitId, false));
    }

    const toastRef = this.notificationService.toast({
      text: 'Comment deleted.',
      cancelable: true,
      icon: 'delete-outline',
      actionBtn: {
        title: 'Undo',
        icon: 'undo',
      },
    });
    toastRef.onAction().subscribe(() => {
      this.store.dispatch(new CommentThreadRestore(thread));
      if (!tuHasAnotherThread) {
        this.store.dispatch(new UpdateQualityIssueCommentStatus(thread.translationUnitId, true));
        this.store.dispatch(new UpdateSearchResultCommentStatus(thread.translationUnitId, true));
      }
    });
  }

  public deleteComment(comment: CommentModel): void {
    this.store.dispatch(new CommentRemove(comment.threadId, comment.id));
    const toastRef = this.notificationService.toast({
      text: 'Reply deleted.',
      cancelable: true,
      icon: 'delete-outline',
      actionBtn: {
        title: 'Undo',
        icon: 'undo',
      },
    });
    toastRef.onAction().subscribe(() => {
      this.store.dispatch(new CommentRestore(comment.threadId, comment));
    });
  }

  public addComment(message: string, thread: CommentThread): void {
    this.store.dispatch(
      new CommentCreate({
        threadId: thread.id,
        projectId: thread.projectId,
        translationUnitId: thread.translationUnitId,
        message,
      })
    );
  }

  public editComment(comment: CommentModel): void {
    this.store.dispatch(new CommentUpdate(comment.threadId, comment.id, comment.message));
  }

  public isResolvedChanged(id: string, isResolved: boolean): void {
    this.store.dispatch(new CommentThreadResolve(id, isResolved));
  }

  public threadClicked(thread: CommentThread): void {
    this.dialogRef.close(thread);
  }
}
