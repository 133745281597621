import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { OverlayService } from './overlay/overlay.service';
import type { OverlayProperties } from './overlay/interfaces';
import { AdDirective } from './ad.directive';
import { TooltipProperties } from '@shared/components/tooltip/interfaces';

/**
 * It's a payed version of http://ivylab.space/tooltip (distributed as zip).
 * But with several modifications.
 */
@Component({
  selector: 'app-tooltip-container',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  private propertiesInternal: OverlayProperties;
  minTimeout: number = 0;

  @HostBinding('style.top') hostTop: string;
  @HostBinding('style.left') hostLeft: string;
  @HostBinding('style.display') hostDisplay: 'none' | 'block';
  @HostBinding('class.tooltip-light') hostLight: boolean;
  @ViewChild(AdDirective, { static: true }) adHost: AdDirective;

  @HostBinding('style.padding')
  get hostPadding(): string {
    return this.properties.metadata.padding;
  }

  @HostBinding('style.white-space')
  get hostWhiteSpace(): string {
    return this.properties.metadata.whiteSpace;
  }

  @HostBinding('style.border-radius')
  get hostBorderRadius(): string {
    return this.properties.metadata.borderRadius;
  }

  @HostBinding('style.box-shadow')
  get hostBoxShadow(): string {
    return this.properties.metadata.shadow ? this.properties.metadata.shadow : 'none';
  }

  @HostBinding('style.pointer-events')
  get hostPointerEvents(): TooltipProperties['pointerEvents'] {
    return this.properties.metadata.pointerEvents;
  }

  @HostBinding('style.font-size')
  get hostFontSize(): string {
    return this.properties.metadata.fontSize;
  }

  @HostBinding('class.tooltip-noarrow')
  get hostNoArrow(): boolean {
    return this.properties.metadata.noArrow;
  }

  @Input() set overlayProperties(properties: OverlayProperties) {
    this.propertiesInternal = properties;
  }

  get properties(): OverlayProperties {
    return this.propertiesInternal;
  }

  get component(): any {
    return this.properties.childComponent;
  }

  get element(): HTMLElement {
    return this.properties.metadata.element;
  }

  get tooltipText(): string {
    return this.properties.metadata.tooltipText;
  }

  get srcElement(): HTMLElement {
    if (this.properties.metadata.event) {
      return this.properties.metadata.event.srcElement;
    }
  }

  get targetElement(): HTMLElement {
    return this.properties.metadata.targetElement;
  }

  get placement(): string {
    return this.properties.metadata.placement;
  }

  get isAlignToCenter(): boolean {
    return this.properties.metadata.alignToCenter;
  }

  get isThemeLight(): boolean {
    return this.properties.metadata.theme === 'light';
  }

  get isArrow(): boolean {
    return this.isThemeLight && !this.hostNoArrow;
  }

  get autoPlacement(): boolean {
    return this.properties.metadata.autoPlacement;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public overlay: OverlayService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.component) {
        this.loadComponent();
      } else if (this.element) {
        this.appendElement();
      } else if (this.tooltipText) {
        this.appendText();
      }

      this.setThemeClass();
      this.setPosition(this.properties);
      this.checkAndSetPosition();
    }, this.minTimeout);
  }

  private loadComponent(): void {
    const adItem = this.properties;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.childComponent as Type<any>);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }

  private appendElement(): void {
    this.element.style.display = '';
    this.elementRef.nativeElement.appendChild(this.element);
  }

  private appendText(): void {
    this.elementRef.nativeElement.innerHTML += this.tooltipText;
  }

  private setPlacementClass(placement: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-' + placement);
  }

  private setThemeClass(): void {
    this.hostLight = this.isThemeLight;
  }

  private getPositionProperty(): { left: number; top: number } {
    const element = this.srcElement || this.targetElement;

    if (this.properties.metadata.position) {
      return this.properties.metadata.position;
    }

    if (element) {
      return element.getBoundingClientRect();
    } else {
      return {
        left: this.properties.metadata.left,
        top: this.properties.metadata.top,
      };
    }
  }

  private checkAndSetPosition(): void {
    if (this.setPosition(this.placement)) {
      this.setPlacementClass(this.placement);
    } else {
      const placements = [
        'top-left',
        'top',
        'top-right',
        'right-top',
        'right',
        'right-bottom',
        'bottom-right',
        'bottom',
        'bottom-left',
        'left-bottom',
        'left',
        'left-top',
      ];

      for (const placement of placements) {
        if (this.setPosition(placement)) {
          this.setPlacementClass(placement);
          return;
        }
      }

      /* Set original placement */
      /*
      if (!isPlacementSet) {
          this.setPosition(this.placement, true);
          this.setPlacementClass(this.placement);
      }
      */
    }
  }

  private setPosition(placement, disableAutoPlacement: boolean = false): boolean {
    const arrowHeight = 8;
    const element = this.properties.metadata.position ? false : this.srcElement || this.targetElement;
    const elementPosition = this.getPositionProperty();
    const elementHeight = element ? element.offsetHeight : 0;
    const elementWidth = element ? element.offsetWidth : 0;
    const tooltipHeight = this.elementRef.nativeElement.clientHeight;
    const tooltipWidth = this.elementRef.nativeElement.clientWidth;
    const scrollY = window.pageYOffset;
    const offset = this.properties.metadata.offset + arrowHeight;
    const arrowOffset = 16;
    const arrowWidth = 16;
    const offsetArrowCenter = arrowOffset + arrowWidth / 2;
    let hostTop;
    let hostLeft;

    // Top - bottom
    if (placement === 'top' || 'top-left' || 'top-right') {
      hostTop = elementPosition.top + scrollY - (tooltipHeight + offset);
    }

    if (placement === 'top-right' || placement === 'bottom-right') {
      if (this.isAlignToCenter) {
        hostLeft = elementPosition.left + elementWidth / 2 - offsetArrowCenter;
      } else {
        hostLeft = elementPosition.left;
      }
    }

    if (placement === 'top-left' || placement === 'bottom-left') {
      if (this.isAlignToCenter) {
        hostLeft = elementPosition.left - (tooltipWidth - elementWidth) - (elementWidth / 2 - offsetArrowCenter);
      } else {
        hostLeft = elementPosition.left - (tooltipWidth - elementWidth);
      }
    }

    if (placement === 'bottom' || placement === 'bottom-right' || placement === 'bottom-left') {
      hostTop = elementPosition.top + scrollY + elementHeight + offset;
    }

    if (placement === 'top' || placement === 'bottom') {
      hostLeft = elementPosition.left + elementWidth / 2 - tooltipWidth / 2;
    }

    // Left - right
    if (placement === 'left' || placement === 'left-bottom' || placement === 'left-top') {
      hostLeft = elementPosition.left - tooltipWidth - offset;
    }

    if (placement === 'right' || placement === 'right-bottom' || placement === 'right-top') {
      hostLeft = elementPosition.left + elementWidth + offset;
    }

    if (placement === 'left' || placement === 'right') {
      hostTop = elementPosition.top + scrollY + elementHeight / 2 - tooltipHeight / 2;
    }

    if (placement === 'right-bottom' || placement === 'left-bottom') {
      if (this.isAlignToCenter) {
        hostTop = elementPosition.top + scrollY + (elementHeight / 2 - offsetArrowCenter);
      } else {
        hostTop = elementPosition.top + scrollY;
      }
    }

    if (placement === 'right-top' || placement === 'left-top') {
      if (this.isAlignToCenter) {
        hostTop =
          elementPosition.top + scrollY + elementHeight - tooltipHeight - (elementHeight / 2 - offsetArrowCenter);
      } else {
        hostTop = elementPosition.top + scrollY + elementHeight - tooltipHeight;
      }
    }

    if (this.autoPlacement && !disableAutoPlacement) {
      const checkPositionResult = this.checkPosition({
        top: hostTop,
        left: hostLeft,
        width: tooltipWidth,
        height: tooltipHeight,
      });

      if (!checkPositionResult) {
        return false;
      }
    }

    if (!hostTop && !hostLeft) {
      // fixing rare case when anchor element removed from DOM in 30ms overlay animation timeout
      this.hostDisplay = 'none';
      return true;
    }
    this.hostDisplay = 'block';

    this.hostTop = hostTop + 'px';
    this.hostLeft = hostLeft + 'px';
    return true;
  }

  private checkPosition(properties: { top: number; left: number; width: number; height: number }): boolean {
    const scrollY = window.pageYOffset;

    const topEdge = properties.top - scrollY;
    const bottomEdge = properties.top + properties.height;
    const leftEdge = properties.left;
    const rightEdge = properties.left + properties.width;
    const bodyHeight = window.innerHeight + scrollY;
    const bodyWidth = document.body.clientWidth;

    if (topEdge < 0 || bottomEdge > bodyHeight || leftEdge < 0 || rightEdge > bodyWidth) {
      return false;
    } else {
      return true;
    }
  }

  private changePosition(position): void {
    this.hostTop = position.top;
    this.hostLeft = position.left;
  }
}
