import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

import { OverlayContainerComponent } from './overlay-container.component';
import type { ContainerProperties, OverlayEventHandlers, OverlayProperties } from './interfaces';
import { defaultProperties as overlayDefaultProperties } from './default-properties';

@Injectable()
export class OverlayService {
  public componentRef: ComponentRef<ContainerProperties>;
  private eventHandlers: OverlayEventHandlers;
  private overlayId: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  private appendComponentToBody(
    properties: OverlayProperties,
    eventHandlers: OverlayEventHandlers,
    component: Type<ContainerProperties> = OverlayContainerComponent
  ): void {
    if (this.componentRef) {
      this.close(this.overlayId);
    }

    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    this.componentRef = componentRef;
    this.eventHandlers = eventHandlers;
    this.overlayId = properties.id;

    componentRef.instance.properties = properties;
    componentRef.instance.overlayClose.subscribe(() => this.close(properties.id));
    componentRef.instance.overlayMouseIn.subscribe(() => eventHandlers.mouseIn());
    componentRef.instance.overlayMouseOut.subscribe(() => eventHandlers.mouseOut());
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Add to body
    document.body.appendChild(domElem);
  }

  public load(properties: OverlayProperties, eventHandlers: OverlayEventHandlers): void {
    properties = this.applyPropertiesDefaults(overlayDefaultProperties, properties);
    this.appendComponentToBody(properties, eventHandlers);
  }

  private applyPropertiesDefaults(defaults, properties): OverlayProperties {
    if (!properties) {
      properties = {};
    }
    if (!properties.index) {
      properties.index = 0;
    }
    const defaultProperties = Object.assign({}, defaults);
    return Object.assign(defaultProperties, properties);
  }

  public close(id: string): void {
    if (!this.componentRef || id !== this.overlayId) {
      return;
    }

    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.componentRef = null;
    this.eventHandlers.closed();
  }
}
