<div class="dropdown" (click)="open()">
  <ng-container *ngIf="triggerPlacement === 'left'">
    <ng-container [ngTemplateOutlet]="triggerTemplate"></ng-container>
  </ng-container>

  <div class="dropdown-container" appDropdownMenuPreventClick>
    <ng-content select="[dropdown-label]"></ng-content>
  </div>

  <ng-container *ngIf="triggerPlacement === 'right'">
    <ng-container [ngTemplateOutlet]="triggerTemplate"></ng-container>
  </ng-container>
</div>

<mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition" class="menu-panel {{ popupClass }}">
  <ng-content select="[dropdown-content]"></ng-content>
</mat-menu>

<ng-template #triggerTemplate>
  <ng-content select="[dropdown-trigger]"></ng-content>
</ng-template>

<!-- hack to not open menu when disabled=true. matMenuTriggerFor does`t have disabled property-->
<div class="fake-trigger" (menuOpened)="opened()" (menuClosed)="closed()" [matMenuTriggerFor]="menu"></div>
