import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

const CONTENT_DISPOSITION_REGEX_ALL = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/g;
const CONTENT_DISPOSITION_REGEX = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;

@Injectable()
export class BlobDownloadService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public download(blob: Blob, type: string): void {
    const file = new Blob([blob], {
      type,
    });
    const link = this.document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
  }

  public downloadFromResponse(response: HttpResponse<Blob>): void {
    const downloadLink = document.createElement('a');
    const file = new Blob([response.body], {
      type: response.headers.get('Content-Type'),
    });
    downloadLink.href = URL.createObjectURL(file);
    const disposition = response.headers.get('content-disposition');
    if (disposition) {
      const names = disposition.match(CONTENT_DISPOSITION_REGEX_ALL);
      const count = names.length || 0;
      if (count) {
        const matches = CONTENT_DISPOSITION_REGEX.exec(names[count - 1]);
        if (matches != null && matches[2]) {
          const fileName = decodeURIComponent(matches[2].replace(/['"]/g, ''));
          downloadLink.setAttribute('download', fileName);
        }
      }
    }
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }
}
