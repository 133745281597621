import { StateRepository } from '@angular-ru/ngxs/decorators';
import type { FileUploadState } from '@shared/models';
import { ApiHttpError, FileUploadStatus, QaSettingsCreatedEvent, QaSettingsRemovedEvent } from '@shared/models';
import { Actions, ofActionDispatched, StateContext } from '@ngxs/store';
import { Action, createSelector, Selector, State, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  AddTemplate,
  AddTemplateFail,
  AddTemplateSuccess,
  CloneTemplate,
  CreateTemplateFromProjectProfile,
  DeleteTemplate,
  LoadTemplateList,
  LoadTemplateListFail,
  LoadTemplateListSuccess,
  RemoveTemplate,
  SetDefault,
  UpdateTemplate,
  UpdateTemplateFail,
  UpdateTemplateSuccess,
  UploadTemplate,
} from './template-list.actions';
import { ProjectState } from '@store/project-store/project.state';
import { ToggleProjectQASettings } from '@store/project-store/project.actions';
import { RenameTemplate } from '../template-list-store/template-list.actions';
import { QASettingsMapper } from '@store/qa-settings-store/qa-settings.mapper';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { uuid } from '@shared/tools';
import {
  AddOrUpdateUploadingFile,
  CancelUploadingAllFiles,
  RemoveUploadingFile,
} from '@store/uploading-files/uploading-files.actions';
import { Observable, of, throwError } from 'rxjs';
import { append, removeItem, updateItem } from '@ngxs/store/operators';
import { QaSettingsInfo, QaSettingsModel, QaSettingsService } from '@generated/api';
import { mapItems } from '@store/custom-operators';
import { CommonHubService } from '@shared/services';

@AsyncStorage
@StateRepository()
@State<QaSettingsInfo[]>({
  name: 'templateList',
  defaults: [],
})
@Injectable()
export class QASettingsTemplatesState {
  constructor(
    private qaSettingsService: QaSettingsService,
    private store: Store,
    private mapper: QASettingsMapper,
    private actions$: Actions,
    private commonHub: CommonHubService
  ) {
    this.watchQaSettingsCreatedEvent();
    this.watchQaSettingsRemovedEvent();
  }

  private watchQaSettingsCreatedEvent(): void {
    this.commonHub.watch(QaSettingsCreatedEvent).subscribe((data) => {
      if (!data.projectId) {
        this.store.dispatch(new LoadTemplateList());
      }
    });
  }

  private watchQaSettingsRemovedEvent(): void {
    this.commonHub.watch(QaSettingsRemovedEvent).subscribe((data) => {
      if (!data.projectId) {
        this.store.dispatch(new RemoveTemplate(data.qaSettingsId));
      }
    });
  }

  @Selector()
  public static sortedList(state: QaSettingsInfo[]): QaSettingsInfo[] {
    return [...state].sort((a, b) => +new Date(b.modifiedOn) - +new Date(a.modifiedOn));
  }

  @Selector()
  public static last(state: QaSettingsInfo[]): QaSettingsInfo {
    return state[state.length - 1];
  }

  public static byId(id: string): (state: QaSettingsInfo[]) => QaSettingsInfo {
    return createSelector(
      [QASettingsTemplatesState],
      (state: QaSettingsInfo[]) => id && state.find((item: QaSettingsInfo) => item.id === id)
    );
  }

  @Action(LoadTemplateList)
  public loadQASettingsTemplateList(ctx: StateContext<QaSettingsInfo[]>): Observable<void> {
    return this.qaSettingsService.apiQaSettingsGet$Json({ 'api-version': '1.1' }).pipe(
      tap((qaSettingsList: QaSettingsInfo[]) => {
        ctx.setState(qaSettingsList);
      }),
      mergeMap(() => ctx.dispatch(new LoadTemplateListSuccess())),
      catchError((error) => ctx.dispatch(new LoadTemplateListFail(error)))
    );
  }

  @Action(AddTemplate)
  public addQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { name, toggleProject }: AddTemplate
  ): Observable<void> {
    return this.qaSettingsService
      .apiQaSettingsPost$Json({
        'api-version': '1.1',
        body: { name },
      })
      .pipe(
        tap((qaSettings: QaSettingsModel) => {
          const template = this.mapper.formatToQASettingsInfo(qaSettings);
          const project = this.store.selectSnapshot(ProjectState.project);

          ctx.setState(append([template]));

          if (toggleProject) {
            ctx.dispatch(new ToggleProjectQASettings(project.id, template.id));
          }
        }),
        mergeMap(() => ctx.dispatch(new AddTemplateSuccess())),
        catchError((error) => ctx.dispatch(new AddTemplateFail(error)))
      );
  }

  @Action(UpdateTemplate)
  public updateQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { fromQASettingsId, toQASettingsId }: UpdateTemplate
  ): Observable<void> {
    return this.qaSettingsService
      .apiQaSettingsCopyPost$Json({
        'api-version': '1.1',
        body: { fromQASettingsId, toQASettingsId },
      })
      .pipe(
        tap((qaSettings) => {
          const template = this.mapper.formatToQASettingsInfo(qaSettings);
          ctx.setState(updateItem<QaSettingsInfo>((item) => item.id === qaSettings.id, template));
        }),
        mergeMap(() => ctx.dispatch(new UpdateTemplateSuccess())),
        catchError((error) => ctx.dispatch(new UpdateTemplateFail(error)))
      );
  }

  @Action(UploadTemplate)
  public uploadQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { file, toggleProject }: UploadTemplate
  ): Observable<void> {
    const newUploadingFileId = uuid();
    const name = file.name.replace('.vprofile', '');
    ctx.dispatch(
      new AddOrUpdateUploadingFile({
        id: newUploadingFileId,
        name,
        status: FileUploadStatus.start,
        progress: 0,
      })
    );
    return this.qaSettingsService.apiQaSettingsUploadPost$Json$Response({ 'api-version': '1.1', body: { file } }).pipe(
      takeUntil(this.actions$.pipe(ofActionDispatched(CancelUploadingAllFiles))),
      tap((event) => {
        const uploadProgress = this.getFileUploadProgress(event, name, newUploadingFileId);
        if (uploadProgress) {
          ctx.dispatch(new AddOrUpdateUploadingFile(uploadProgress));
        }
      }),
      filter((event) => event.type === HttpEventType.Response),
      map((event: HttpResponse<QaSettingsModel>) => event.body),
      tap((qaSettings) => ctx.setState(append([this.mapper.formatToQASettingsInfo(qaSettings)]))),
      mergeMap((qaSettings) => {
        if (toggleProject) {
          const project = this.store.selectSnapshot(ProjectState.project);
          return ctx.dispatch(new ToggleProjectQASettings(project.id, qaSettings.id));
        }
        return of(null);
      }),
      tap(() => this.store.dispatch(new RemoveUploadingFile(newUploadingFileId))),
      catchError((error: ApiHttpError) => {
        ctx.dispatch(
          new AddOrUpdateUploadingFile({
            id: newUploadingFileId,
            name,
            errorTitle: error?.error?.code === 4110 ? 'Not supported' : 'Load failed',
            errorMessage: error?.error?.message,
            progress: 0,
            status: FileUploadStatus.error,
          })
        );
        return throwError(() => error);
      })
    );
  }

  @Action(SetDefault)
  public setDefault(ctx: StateContext<QaSettingsInfo[]>, { templateId }: SetDefault): Observable<void> {
    return this.qaSettingsService
      .apiQaSettingsIdSetupDefaultPost({
        'api-version': '1.1',
        id: templateId,
      })
      .pipe(
        tap(() => {
          ctx.setState(
            mapItems((qaSettings) => ({
              ...qaSettings,
              isDefault: qaSettings.id === templateId,
            }))
          );
        })
      );
  }

  @Action(DeleteTemplate)
  public deleteQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { templateId }: DeleteTemplate
  ): Observable<void> {
    return this.qaSettingsService
      .apiQaSettingsIdDelete({
        'api-version': '1.1',
        id: templateId,
      })
      .pipe(
        tap(() => {
          ctx.dispatch(new RemoveTemplate(templateId));
        })
      );
  }

  @Action(RenameTemplate)
  public renameQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { templateId, name }: RenameTemplate
  ): Observable<QaSettingsInfo> {
    return this.qaSettingsService
      .apiQaSettingsIdRenamePost$Json({
        'api-version': '1.1',
        id: templateId,
        body: {
          name,
        },
      })
      .pipe(
        tap(() => {
          ctx.setState(
            mapItems((qaSettings) => ({
              ...qaSettings,
              name: qaSettings.id === templateId ? name : qaSettings.name,
            }))
          );
        })
      );
  }

  @Action(RemoveTemplate)
  public removeTemplate(ctx: StateContext<QaSettingsInfo[]>, { templateId }: RemoveTemplate): void {
    ctx.setState(removeItem<QaSettingsInfo>((qaSettings) => qaSettings.id === templateId));
  }

  @Action(CloneTemplate)
  public cloneQASettingsTemplate(
    ctx: StateContext<QaSettingsInfo[]>,
    { templateId, name }: CloneTemplate
  ): Observable<QaSettingsModel> {
    return this.qaSettingsService
      .apiQaSettingsIdClonePost$Json({
        'api-version': '1.1',
        id: templateId,
        body: {
          name: name || undefined,
        },
      })
      .pipe(
        tap((qaSettings) => {
          const template = this.mapper.formatToQASettingsInfo(qaSettings);
          ctx.setState(append([template]));
        })
      );
  }

  @Action(CreateTemplateFromProjectProfile)
  public createQASettingsTemplateFromProjectProfile(
    ctx: StateContext<QaSettingsInfo[]>,
    { qaSettingsId, name }: CreateTemplateFromProjectProfile
  ): Observable<QaSettingsModel> {
    return this.qaSettingsService
      .apiQaSettingsIdClonePost$Json({
        'api-version': '1.1',
        id: qaSettingsId,
        body: {
          name,
        },
      })
      .pipe(
        tap((qaSettings) => {
          const template = this.mapper.formatToQASettingsInfo(qaSettings);
          ctx.setState(append([template]));
        })
      );
  }

  private getFileUploadProgress(
    event: HttpEvent<QaSettingsModel>,
    fileName: string,
    fileId: string
  ): FileUploadState | null {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          id: fileId,
          status: FileUploadStatus.start,
          progress: 0,
          name: fileName,
        };
      case HttpEventType.UploadProgress:
        const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
        return {
          id: fileId,
          status: FileUploadStatus.progress,
          progress: percentDone,
          name: fileName,
        };
      case HttpEventType.Response:
        return {
          id: fileId,
          status: FileUploadStatus.finished,
          progress: 100,
          name: fileName,
        };
      default:
        return null;
    }
  }
}
