<div class="chips-input-container" [class.edit-mode]="inputMode === inputModes.Edit">
  <div class="input-group">
    <input
      *ngIf="inputMode !== inputModes.Delete; else elseTemplate"
      #inputElement
      class="input input-lg"
      [class.rtl]="rtl"
      (keydown)="keyDownHandler($event)"
      (paste)="inputPasteHandler($event)"
      [ngModel]="inputValue"
      (ngModelChange)="inputValueChanged($event)"
      [disabled]="disabled"
      [placeholder]="placeholder"
    />
    <app-action-icon
      [disabled]="inputIconDisabled"
      (clickButton)="propagateChanges()"
      class="suffix-icon input-icon"
      [icon]="inputIcons[inputMode]"
      theme="subtle"
    ></app-action-icon>
  </div>
</div>

<ng-template #elseTemplate>
  <input class="input input-lg" disabled [value]="selectedChips.length + ' items selected'" />
</ng-template>
