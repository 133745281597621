<div class="container">
  <div class="pinned-projects">
    <div class="header">
      <app-button
        (click)="createProject()"
        [disabled]="uploadingInPorgress$ | async"
        prefixIcon="add"
        class="header-btn"
        theme="primary"
      >
        New project
      </app-button>
    </div>

    <app-card>
      <app-card-title>
        <app-ui-icon icon="pin" size="xs" color="gray"></app-ui-icon>
        <div class="title">MY PINNED PROJECTS</div>
      </app-card-title>
      <div class="project-list" [class.show-all]="isShowAll">
        <app-search-list
          [items]="pinnedProjects$ | async"
          [searchControl]="projectSearchControl"
          [searchEnabled]="false"
          [itemTemplate]="projectItemTemplate"
          [itemSize]="40"
          [listHeight]="320"
          [pageSize]="10"
          [emptyListTemplate]="pinProjectsEmptyTemplate"
          nameKey="name"
        >
        </app-search-list>
      </div>
    </app-card>
  </div>

  <div class="available-projects">
    <div class="header">
      <app-ui-search-input [formControl]="projectSearchControl" placeholder="Search projects..."></app-ui-search-input>
      <app-action-icon (click)="close()" icon="clear" theme="subtle" size="xl"></app-action-icon>
    </div>
    <app-card>
      <app-card-title>
        <div class="title">ALL PROJECTS</div>
        <div class="description">Last modified</div>
      </app-card-title>
      <div class="project-list" [class.show-all]="isShowAll">
        <app-search-list
          [items]="projects"
          [searchControl]="projectSearchControl"
          [searchEnabled]="false"
          [itemTemplate]="projectItemTemplate"
          [itemSize]="40"
          [listHeight]="320"
          [totalItems]="totalProjects"
          [externalSearch]="true"
          [loading]="loading$ | async"
          (loadData)="loadMoreProjects($event)"
          nameKey="name"
        >
        </app-search-list>
      </div>
    </app-card>
  </div>
</div>

<ng-template #projectItemTemplate let-p="item" let-index="index">
  <div
    (click)="selectProject(p)"
    class="project-item"
    [ngClass]="{
      disabled: uploadingInPorgress$ | async,
      'delete-candidate': p.id === deleteCandidate?.id,
      active: p.id === project?.id
    }"
  >
    <div [appTooltipTruncated]="(uploadingInPorgress$ | async) ? uploadingWarning : project.name" class="name">
      {{ p.name }}
    </div>
    <div class="actions">
      <app-action-icon
        (click)="$event.stopPropagation(); toggleProjectPin(p)"
        [icon]="p.isPinned ? 'pin' : 'pin-outlined'"
        theme="circle"
        size="xl"
      ></app-action-icon>

      <app-ui-dropdown-menu appDropdownMenuPreventClick>
        <app-action-icon
          dropdown-trigger
          icon="more-vert"
          theme="circle"
          class="action-btn"
          size="xl"
        ></app-action-icon>
        <div dropdown-content>
          <div class="list-item" (click)="copyProjectLink(p)" role="button">
            <app-ui-icon class="list-item__icon" icon="copy" size="small" role="button"> </app-ui-icon>
            <span>Copy link to project</span>
          </div>
          <div class="list-item list-item--danger" (click)="deleteProject(p)" role="button">
            <app-ui-icon class="list-item__icon" icon="delete-outline" size="small"> </app-ui-icon>
            <span>Delete</span>
          </div>
        </div>
      </app-ui-dropdown-menu>
    </div>
  </div>
</ng-template>

<ng-template #pinProjectsEmptyTemplate>
  <div class="empty-pin-list-message">
    <div>Pin a project you need in all projects</div>
    <app-ui-icon icon="pin-outlined"></app-ui-icon>
  </div>
</ng-template>
