export enum ReportType {
  Formal = 'Formal',
  Consistency = 'Consistency',
  Terminology = 'Terminology',
  Custom = 'Custom',
  Spelling = 'Spelling',
  History = 'HistoryOfChanges',
}

export const ReportTypes = [
  ReportType.Formal,
  ReportType.Consistency,
  ReportType.Terminology,
  ReportType.Spelling,
  ReportType.Custom,
  ReportType.History,
];
