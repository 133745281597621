import { Injectable } from '@angular/core';
import { RenderItemBuilder } from '@shared/services';
import { RawSearchInReportFilesResultItem, SearchInReportFilesResultItem } from '@shared/models/search-in-report-files';
import { Store } from '@ngxs/store';
import { getMatchForRow } from '@report/utils';

@Injectable({
  providedIn: 'root',
})
export class SearchInProjectFilesResultItemMapper {
  constructor(private renderItemBuilder: RenderItemBuilder, private store: Store) {}

  public mapItems(searchInReportFilesResultItems: RawSearchInReportFilesResultItem[]): SearchInReportFilesResultItem[] {
    return searchInReportFilesResultItems.map((c) => this.mapItem(c));
  }

  public mapItem(searchInReportFilesResultItem: RawSearchInReportFilesResultItem): SearchInReportFilesResultItem {
    return {
      ...searchInReportFilesResultItem,
      id: searchInReportFilesResultItem.translationUnit.id,
      translationUnit: searchInReportFilesResultItem.translationUnit,
      sourceRenderItems: this.renderItemBuilder.build(
        searchInReportFilesResultItem.sourceRanges,
        searchInReportFilesResultItem.translationUnit.source.elements
      ),
      targetRenderItems: this.renderItemBuilder.build(
        searchInReportFilesResultItem.targetRanges,
        searchInReportFilesResultItem.translationUnit.target.elements
      ),
      match: getMatchForRow(searchInReportFilesResultItem.translationUnit.properties),
    };
  }
}
