import { IssueType } from '@generated/api';
import { ReportTab } from './report-tab.model';

export const IssueTypeMap = {
  Formal: IssueType.Formal,
  Terminology: IssueType.Terminology,
  Custom: IssueType.Custom,
  Spelling: IssueType.Spelling,
  Grammar: IssueType.Grammar,
  Consistency: IssueType.Consistency,
};

export const IssueTypeNames = {
  0: 'Common',
  1: 'Terminology',
  2: 'Custom',
  3: 'Spelling',
  4: 'Grammar',
  5: 'Consistency',
};

export const IssueTypeToReportTab: Partial<Record<IssueType, ReportTab>> = {
  [IssueType.Formal]: ReportTab.Formal,
  [IssueType.Terminology]: ReportTab.Terminology,
  [IssueType.Custom]: ReportTab.Custom,
  [IssueType.Spelling]: ReportTab.Spelling,
  [IssueType.Consistency]: ReportTab.Consistency,
};
