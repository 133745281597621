import { Component, ChangeDetectionStrategy, Input, AfterContentInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent implements AfterContentInit {
  @Input() value: string | number | boolean;
  @Input() disabled: boolean;
  label: string;

  constructor(private elt: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    this.label = this.elt.nativeElement.innerHTML;
  }
}
