import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Organization } from '@generated/api';
import { Select, Store } from '@ngxs/store';
import { NotificationService } from '@shared/components/notification';
import { Account } from '@shared/models';
import { AppService } from '@shared/services';
import { Logout } from '@store/auth/auth.actions';
import { AuthState } from '@store/index';
import { InviteUsers, JoinOrganization, LoadOrganizations } from '@store/organizations-store/organizations.actions';
import { OrganizationsState } from '@store/organizations-store/organizations.state';
import { distinctUntilChanged, filter, Observable, Subject, takeUntil } from 'rxjs';
import { OrganizationCreatorComponent } from '../organization-creator/organization-creator.component';
import { OrganizationInviteComponent } from '../organization-invite/organization-invite.component';

@Component({
  selector: 'app-organizations-manager',
  templateUrl: './organizations-manager.component.html',
  styleUrls: ['./organizations-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsManagerComponent implements OnInit, OnDestroy {
  @Select(AuthState.account)
  public readonly userInfo$: Observable<Account>;

  @Select(OrganizationsState.organizations)
  public readonly organizations$: Observable<Organization[]>;

  @Select(OrganizationsState.currentOrganization)
  public readonly currentOrganization$: Observable<Organization>;

  private readonly destroyed$: Subject<void> = new Subject();

  constructor(
    public readonly app: AppService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadOrganizations();
  }

  private loadOrganizations(): void {
    this.userInfo$.pipe(
      takeUntil(this.destroyed$),
      filter((user) => user.hasRole('registered')),
      distinctUntilChanged((curr, prev) => curr.id === prev.id)
    );
    this.store.dispatch(new LoadOrganizations());
  }

  public logout(): void {
    this.store.dispatch(new Logout());
    this.app.restart();
  }

  public createOrganization(): void {
    const dialogRef = this.dialog.open(OrganizationCreatorComponent, {
      hasBackdrop: false,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        filter((r) => !!r)
      )
      .subscribe((result: Organization) => {
        this.openInviteDialog(result.id);
      });
  }

  public inviteUser(organization: Organization): void {
    this.openInviteDialog(organization.id);
  }

  private openInviteDialog(organizationId: string): void {
    const dialogRef = this.dialog.open(OrganizationInviteComponent, {
      hasBackdrop: false,
      data: { organizationId },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((emails) => emails?.length),
        takeUntil(this.destroyed$)
      )
      .subscribe((emails: string[]) => {
        this.store
          .dispatch(new InviteUsers(emails))
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.showInviteSentPopup();
          });
      });
  }

  private showInviteSentPopup(): void {
    this.notificationService.toast({
      icon: 'check',
      text: 'Invitations are successfully sent!',
      cancelable: true,
    });
  }

  public selectOrganization(organization?: Organization): void {
    this.store.dispatch(new JoinOrganization(organization?.id));
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
