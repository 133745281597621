import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentRendererComponent } from './segment-renderer.component';
import { TagModule } from '@shared/components/tag';
import { PipesModule } from '@shared/pipes';

@NgModule({
  declarations: [SegmentRendererComponent],
  exports: [SegmentRendererComponent],
  imports: [CommonModule, TagModule, PipesModule],
})
export class SegmentRendererModule {}
