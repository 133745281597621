import { TooltipModule } from '@shared/components/tooltip/tooltip.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpecificLanguageComponent } from './specific-language.component';
import { SelectModule } from '@shared/components/select-v2';

@NgModule({
  declarations: [SpecificLanguageComponent],
  imports: [CommonModule, ReactiveFormsModule, TooltipModule, SelectModule, FormsModule],
  exports: [SpecificLanguageComponent],
})
export class SpecificLanguageModule {}
