import { Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State, Action, Selector, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  CreateReport,
  CreateReportSuccess,
  CreateReportFail,
  CreateReportLink,
  LoadReport,
  GenerateReport,
  LoadReportSuccess,
  LoadReportFail,
  ExportReport,
  DownloadReportExportedFile,
  ResetReportState,
} from './report.actions';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { ResetQualityIssues } from '@store/quality-issues-store/quality-issues.actions';
import { ResetReportProcessingState } from '@store/report-processing-store/report-processing.actions';
import { ReportModel, ReportsService } from '@generated/api';
import { BlobDownloadService } from '@shared/services/blob-download.service';
import { Observable } from 'rxjs';

// NOTE: this state is preserved in session storage,
// cause active report can be different for each tab
@Persistence({
  existingEngine: sessionStorage,
})
@StateRepository()
@State<ReportModel>({
  name: 'report',
  defaults: {},
})
@Injectable()
export class ReportState extends NgxsDataRepository<ReportModel> {
  constructor(
    private reportService: ReportsService,
    private store: Store,
    private blobDownloadService: BlobDownloadService
  ) {
    super();
  }

  @Selector()
  public static report(state: ReportModel): ReportModel {
    return state;
  }

  @Selector()
  public static reportId(state: ReportModel): string {
    return state?.id;
  }

  @Action(LoadReport)
  public loadReport(ctx: StateContext<ReportModel>, action: LoadReport): Observable<void> {
    return this.reportService.apiReportsGet$Json({ projectId: action.projectId }).pipe(
      tap((report) => {
        ctx.setState(report);
      }),
      mergeMap((report) => ctx.dispatch(new LoadReportSuccess(report))),
      catchError((error) => ctx.dispatch(new LoadReportFail(error)))
    );
  }

  @Action(GenerateReport)
  public generateReport(ctx: StateContext<ReportModel>, action: GenerateReport): Observable<void> {
    return this.reportService.apiReportsIdGeneratePost({ id: action.id, body: { id: action.id } });
  }

  @Action(ResetReportState)
  public resetReportState(ctx: StateContext<ReportModel>): void {
    ctx.setState({});
    this.store.dispatch(new ResetQualityIssues());
    this.store.dispatch(new ResetReportProcessingState());
  }

  @Action(CreateReport)
  public createReport(ctx: StateContext<ReportModel>, action: CreateReport): Observable<void> {
    return this.reportService.apiReportsPost$Json({ body: { projectId: action.projectId } }).pipe(
      tap((response) => {
        ctx.setState(response);
      }),
      mergeMap(() => ctx.dispatch(new CreateReportSuccess())),
      catchError((error) => ctx.dispatch(new CreateReportFail(error)))
    );
  }

  @Action(CreateReportLink)
  public createReportLink(ctx: StateContext<ReportModel>, action: CreateReportLink): Observable<void> {
    return this.reportService.apiReportsIdGenerateLinkPost({ id: action.id });
  }

  @Action(ExportReport)
  public exportReport(ctx: StateContext<ReportModel>, action: ExportReport): Observable<void> {
    const state = ctx.getState();
    return this.reportService.apiReportsReportIdExportPost({
      reportId: state.id,
      body: action.exportModel,
    });
  }

  @Action(DownloadReportExportedFile)
  public downloadReportExportedFile(ctx: StateContext<ReportModel>): void {
    const state = ctx.getState();
    this.reportService
      .apiReportsReportIdExportGet$Response({
        reportId: state.id,
      })
      .subscribe((blob) => {
        this.blobDownloadService.downloadFromResponse(blob);
      });
  }
}
