import { StateRepository } from '@angular-ru/ngxs/decorators';
import type { StateContext } from '@ngxs/store';
import { Action, createSelector, NgxsOnInit, State, Store } from '@ngxs/store';
import { IssueTypeMap, QualityIssue, RawQualityIssue } from '@shared/models/report';
import { Injectable } from '@angular/core';
import {
  AddedQualityIssues,
  AddQualityIssues,
  ApplySegment,
  ApplyTranslationUnit,
  BulkUpdateIgnoreStatus,
  ClearQualityIssues,
  CollapseAllIdenticalQualityIssues,
  IgnoreQualityIssues,
  IgnoreQualityIssuesFail,
  IgnoreQualityIssuesSuccess,
  LoadQualityIssues,
  LoadQualityIssuesByIds,
  LoadQualityIssuesFail,
  RecheckTranslationUnit,
  RecheckTranslationUnitFail,
  RecheckTranslationUnitSuccess,
  RemovedQualityIssues,
  RemoveQualityIssues,
  RemoveQualityIssuesByFileId,
  ResetQualityIssues,
  RestoreQualityIssues,
  SetExpandQualityIssueStatus,
  UpdatedQualityIssues,
  UpdateQualityIssueCommentStatus,
} from './quality-issues.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { append, patch } from '@ngxs/store/operators';
import { CommonHubService } from '@shared/services';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { QualityIssueDAO } from 'src/app/core/daos';
import { ProjectState } from '@store/project-store';
import { QualityIssueMapper } from '@shared/mappers/quality-issue.maper';
import { QualityIssueChangedEvent, QualityIssuesIgnoredEvent } from '@shared/models';
import { BulkChangeReportPartProcessingStatus } from '@store/report-processing-store/report-processing.actions';
import { GetQualityIssuesResponseModel, IssueType, QualityIssuesService } from '@generated/api';
import { removeItems } from '@store/custom-operators';
import { CommentThreadRemoveByTranslationUnitId } from '@store/comments';
import { QASettingsState } from '@store/qa-settings-store/qa-settings.state';
import { ReportState } from '@store/report-store/report.state';
import { Observable } from 'rxjs';

export type QualityIssuesStateModel = {
  [key in IssueType]?: QualityIssue[];
};

export const DEFAULT_STATE: QualityIssuesStateModel = {
  [IssueTypeMap.Formal]: [],
  [IssueTypeMap.Consistency]: [],
  [IssueTypeMap.Terminology]: [],
  [IssueTypeMap.Custom]: [],
  [IssueTypeMap.Grammar]: [],
  [IssueTypeMap.Spelling]: [],
};

@StateRepository()
@State<QualityIssuesStateModel>({
  name: 'qualityIssues',
  defaults: { ...DEFAULT_STATE },
})
@Injectable()
export class QualityIssuesState extends NgxsDataRepository<QualityIssue[]> implements NgxsOnInit {
  constructor(
    private store: Store,
    private qualityIssueService: QualityIssuesService,
    private qualityIssueMapper: QualityIssueMapper,
    private qualityIssueDAO: QualityIssueDAO,
    private commonHub: CommonHubService
  ) {
    super();
    this.watchQualityIssueChanged();
    this.watchQualityIssuesIgnored();
  }

  private watchQualityIssueChanged(): void {
    this.commonHub.watch(QualityIssueChangedEvent).subscribe((data) => {
      if (data.deletedIssueIds?.length) {
        this.store.dispatch(new RemoveQualityIssues(data.issueType, data.deletedIssueIds));
      }
      if (data.createdIssueIds?.length || data.existedIssueIds?.length) {
        const reportId = this.store.selectSnapshot(ReportState.reportId);
        this.store.dispatch(
          new LoadQualityIssuesByIds(reportId, data.issueType, data.createdIssueIds, data.existedIssueIds)
        );
      }
    });
  }

  private watchQualityIssuesIgnored(): void {
    this.commonHub.watch(QualityIssuesIgnoredEvent).subscribe((data) => {
      this.store.dispatch(new BulkUpdateIgnoreStatus(data.issueType, data.ignoredIssues));
    });
  }

  public static getQualityIssues(issueType: IssueType): (arg: QualityIssuesStateModel) => QualityIssue[] {
    return createSelector([QualityIssuesState], (state: QualityIssuesStateModel) => {
      if (!state) {
        return [];
      }
      return state[issueType] || [];
    });
  }

  public static getQualityIssue(
    issueType: IssueType,
    qualityIssueId: string
  ): (arg: QualityIssuesStateModel) => QualityIssue {
    return createSelector([QualityIssuesState], (state: QualityIssuesStateModel) =>
      state[issueType].find((a) => a.id === qualityIssueId)
    );
  }

  public static findQualityIssue(
    issueType: IssueType,
    filterFunc: (qi: QualityIssue) => boolean
  ): (arg: QualityIssuesStateModel) => QualityIssue {
    return createSelector([QualityIssuesState], (state: QualityIssuesStateModel) => state[issueType].find(filterFunc));
  }

  public static filterQualityIssue(
    issueType: IssueType,
    filterFunc: (qi: QualityIssue) => boolean
  ): (arg: QualityIssuesStateModel) => QualityIssue[] {
    return createSelector(
      [QualityIssuesState],
      (state: QualityIssuesStateModel) => state[issueType].filter(filterFunc) || []
    );
  }

  public static getQualityIssuesByIssueKind(
    issueType: IssueType,
    issueKind: string
  ): (arg: QualityIssuesStateModel) => QualityIssue[] {
    return createSelector(
      [QualityIssuesState],
      (state: QualityIssuesStateModel) => state[issueType]?.filter((a) => a.issueKind === issueKind) || []
    );
  }

  public static count(issueType: IssueType): (arg: QualityIssuesStateModel) => number {
    return createSelector(
      [QualityIssuesState],
      (state: QualityIssuesStateModel) => (state && state[issueType]?.length) || 0
    );
  }

  public static errorCount(issueType: IssueType): (arg: QualityIssuesStateModel) => number {
    return createSelector(
      [QualityIssuesState],
      issueType === IssueType.Consistency
        ? (state: QualityIssuesStateModel): number =>
            new Set(state?.[IssueType.Consistency]?.filter((d) => !d.isIgnored).map((q) => q.id)).size
        : (state: QualityIssuesStateModel): number => state?.[issueType]?.filter((d) => !d.isIgnored).length || 0
    );
  }

  public ngxsOnInit(ctx?: StateContext<any>): void {
    // NOTE: мутация стирает предыдущее состояние из персистент стейта
    // убрать или обработать после перехода на частичное восстановление состояния
    ctx.dispatch(new ClearQualityIssues());
  }

  @Action(ClearQualityIssues)
  public clearQualityIssues(ctx: StateContext<QualityIssuesStateModel>): void {
    ctx.setState({ ...DEFAULT_STATE });
    const currentProject = this.store.selectSnapshot(ProjectState.project);
    if (!currentProject) {
      return;
    }
    this.qualityIssueDAO.clearIssues(currentProject.id);
  }

  @Action(AddQualityIssues)
  public addQualityIssues(ctx: StateContext<QualityIssuesStateModel>, action: AddQualityIssues): Observable<void> {
    const suppressAutofix = this.store.selectSnapshot(QASettingsState.qaSettings)?.checkSettings?.protection
      ?.suppressAutofix;

    const qualityIssues = this.qualityIssueMapper.mapQualityIssuesByType(
      action.qualityIssues,
      action.issueType,
      suppressAutofix
    );
    ctx.setState(
      patch({
        [action.issueType]: append(qualityIssues),
      })
    );
    const project = this.store.selectSnapshot(ProjectState.project);
    this.qualityIssueDAO.bulkCreateOrUpdate(project.id, action.issueType, qualityIssues).subscribe();

    return ctx.dispatch(new AddedQualityIssues(action.issueType, qualityIssues, action.bulkInsert));
  }

  @Action(RemoveQualityIssues)
  public removeQualityIssues(
    ctx: StateContext<QualityIssuesStateModel>,
    action: RemoveQualityIssues
  ): Observable<void> {
    const idsSet = new Set(action.qualityIssueIds);
    const predicate = (a: QualityIssue): boolean => idsSet.has(a.id);
    const removed = this.store.selectSnapshot(QualityIssuesState.filterQualityIssue(action.issueType, predicate));
    ctx.setState(
      patch({
        [action.issueType]: removeItems<QualityIssue>(predicate),
      })
    );
    const currentProject = this.store.selectSnapshot(ProjectState.project);
    this.qualityIssueDAO.bulkRemove(currentProject.id, action.issueType, action.qualityIssueIds).subscribe();

    // чтобы убрать identicalRows в гриде Consistency
    if (action.issueType === IssueType.Consistency) {
      const identicalRows: QualityIssue[] = [];
      removed.forEach((qi) => {
        if (qi.isExpatendedIdenticalRows && qi.identicalRows?.length) {
          identicalRows.push(...qi.identicalRows);
        }
      });
      removed.push(...identicalRows);
    }
    return ctx.dispatch(new RemovedQualityIssues(action.issueType, removed));
  }

  @Action(RemoveQualityIssuesByFileId)
  public removeQualityIssuesByFileId(
    ctx: StateContext<QualityIssuesStateModel>,
    action: RemoveQualityIssuesByFileId
  ): void {
    const state = ctx.getState();
    const predicate = (a: QualityIssue): boolean => a.translationUnit.projectFileId === action.fileId;
    const translationUnitIds: string[] = [];
    Object.keys(state).forEach((key: string) => {
      const issueType: IssueType = parseInt(key, 10);
      const qualityIssues = this.store.selectSnapshot(QualityIssuesState.filterQualityIssue(issueType, predicate));
      if (!qualityIssues.length) {
        return;
      }
      translationUnitIds.push(...qualityIssues.map((qi) => qi.translationUnit.id));
      ctx.dispatch(
        new RemoveQualityIssues(
          issueType,
          qualityIssues.map((qi) => qi.id)
        )
      );
    });
    ctx.dispatch(new CommentThreadRemoveByTranslationUnitId(action.projectId, translationUnitIds));
  }

  @Action(LoadQualityIssues)
  public loadQualityIssues(ctx: StateContext<QualityIssuesStateModel>, action: LoadQualityIssues): Observable<void> {
    return this.qualityIssueService.apiQualityIssuesGet$Json(action).pipe(
      map(({ qualityIssues, statuses }: GetQualityIssuesResponseModel) => {
        ctx.dispatch(new ResetQualityIssues());
        ctx.dispatch(new BulkChangeReportPartProcessingStatus(statuses));
        return this.qualityIssueMapper.groupQualityIssuesByType(qualityIssues);
      }),
      mergeMap((grouppedRawQualityIssues: Record<IssueType, RawQualityIssue[]>) => {
        const issueTypes = Object.values(IssueTypeMap);
        return issueTypes.map((issueType) => {
          const typedQualityIssues = grouppedRawQualityIssues[issueType];
          ctx.dispatch(new AddQualityIssues(issueType, typedQualityIssues, true));
        });
      }),
      catchError((error: string) => ctx.dispatch(new LoadQualityIssuesFail(error)))
    );
  }

  @Action(LoadQualityIssuesByIds)
  public loadQualityIssuesByIds(
    ctx: StateContext<QualityIssuesStateModel>,
    action: LoadQualityIssuesByIds
  ): Observable<void> {
    let loadCandidatesIds = action.ids;

    // TODO: удалить после VW-759. Будет реализация без existedIds.
    if (action.possibleExistedIds?.length) {
      loadCandidatesIds = this.getNotLoadedQualityIssuesIds(action.issueType, action.possibleExistedIds);
    }

    if (!loadCandidatesIds.length) {
      return;
    }

    return this.qualityIssueService
      .apiReportsReportIdQualityIssuesPost$Json({
        reportId: action.reportId,
        body: loadCandidatesIds,
      })
      .pipe(mergeMap((qualityIssues) => ctx.dispatch(new AddQualityIssues(action.issueType, qualityIssues))));
  }

  private getNotLoadedQualityIssuesIds(issueType: IssueType, possibleExistedIds: string[]): string[] {
    const existedIssueIds = new Set(
      this.store.selectSnapshot(QualityIssuesState.getQualityIssues(issueType)).map((q) => q.id)
    );
    return possibleExistedIds.filter((id) => {
      const qualityIssueAlreadyLoaded = existedIssueIds.has(id);
      if (qualityIssueAlreadyLoaded) {
        // TODO: временный варнинг. Проверка бывает ли ситуация когда QI с existedId был загружен.
        console.warn('[quality-issues.state.ts] quality issues already have been loaded: ', id);
        return false;
      }
      return true;
    });
  }

  @Action(RecheckTranslationUnit)
  public recheck(ctx: StateContext<QualityIssuesStateModel>, action: RecheckTranslationUnit): Observable<void> {
    return this.qualityIssueService
      .apiQualityIssuesIdRecheckPost({
        id: action.reportId,
        body: action,
      })
      .pipe(
        mergeMap(() => ctx.dispatch(new RecheckTranslationUnitSuccess(action.translationUnitId))),
        catchError((error: Error) => ctx.dispatch(new RecheckTranslationUnitFail(error)))
      );
  }

  // for consistency need positive update
  @Action(ApplySegment)
  public recheckTranslationUnitInConsistency(
    ctx: StateContext<QualityIssuesStateModel>,
    action: ApplySegment
  ): Observable<void> {
    let qualityIssue = action.qualityIssue;
    const toTranslationUnitIds: string[] = [qualityIssue.translationUnit.id];
    if (!qualityIssue.isExpatendedIdenticalRows && qualityIssue.identicalRows?.length) {
      qualityIssue.identicalRows.forEach((value) => toTranslationUnitIds.push(value.translationUnit.id));
    }

    if (!qualityIssue.additionalData) {
      qualityIssue = this.store.selectSnapshot(
        QualityIssuesState.findQualityIssue(
          action.qualityIssue.issueType,
          (item) => item.id === qualityIssue.id && !!item.additionalData
        )
      );
    }
    ctx.dispatch(new RemoveQualityIssues(IssueType.Consistency, [qualityIssue.id]));
    return this.qualityIssueService
      .apiQualityIssuesApplySegmentPost({
        body: {
          reportId: action.reportId,
          targetSegment: action.targetSegment,
          toTranslationUnitIds: [...new Set(toTranslationUnitIds)],
        },
      })
      .pipe(
        catchError(() =>
          ctx.dispatch(
            new AddQualityIssues(IssueType.Consistency, [{ ...qualityIssue, isExpatendedIdenticalRows: false }])
          )
        )
      );
  }

  @Action(ApplyTranslationUnit)
  public applyTranslationUnit(
    ctx: StateContext<QualityIssuesStateModel>,
    action: ApplyTranslationUnit
  ): Observable<void> {
    const toTranslationUnitIds: string[] = [];
    const qualityIssues = this.store.selectSnapshot(
      QualityIssuesState.filterQualityIssue(action.qualityIssue.issueType, (item) => item.id === action.qualityIssue.id)
    );
    qualityIssues.forEach((item: QualityIssue) => {
      if (item.translationUnit.id !== action.qualityIssue.translationUnit.id) {
        toTranslationUnitIds.push(item.translationUnit.id);
        if (item.identicalRows && item.identicalRows?.length) {
          item.identicalRows.forEach((value) => toTranslationUnitIds.push(value.translationUnit.id));
        }
      }
    });

    const mainQIInGroup = this.store.selectSnapshot(
      QualityIssuesState.findQualityIssue(
        action.qualityIssue.issueType,
        (item) => item.id === action.qualityIssue.id && !!item.additionalData
      )
    );

    ctx.dispatch(new RemoveQualityIssues(action.qualityIssue.issueType, [action.qualityIssue.id]));

    return this.qualityIssueService
      .apiQualityIssuesApplyTranslationUnitPost({
        body: {
          toTranslationUnitIds: [...new Set(toTranslationUnitIds)],
          reportId: action.reportId,
          fromTranslationUnitId: action.qualityIssue.translationUnit.id,
          // issueType: action.qualityIssue.issueType,
        },
      })
      .pipe(
        catchError(() =>
          ctx.dispatch(
            new AddQualityIssues(IssueType.Consistency, [{ ...mainQIInGroup, isExpatendedIdenticalRows: false }])
          )
        )
      );
  }

  @Action(IgnoreQualityIssues)
  public ignoreQualityIssues(
    ctx: StateContext<QualityIssuesStateModel>,
    { reportId, ids, isIgnored, issueType }: IgnoreQualityIssues
  ): Observable<void> {
    const qualityIssues = ctx.getState()[issueType];

    // initial state
    const ignoringQualityIssues = qualityIssues.filter((item: QualityIssue) => ids.includes(item.id));

    let ignoredQualityIssues: QualityIssue[] = [];
    // updated state
    ignoredQualityIssues = ignoringQualityIssues.map((item: QualityIssue) => ({
      ...item,
      isIgnored,
    }));

    // optimistic update
    this._updateQualityIssues(ctx, issueType, ignoredQualityIssues);

    return this.qualityIssueService
      .apiQualityIssuesIgnorePost({
        body: {
          reportId,
          ignoreQualityIssues: ids.map((qualityIssueId) => ({
            qualityIssueId,
            isIgnored,
          })),
        },
      })
      .pipe(
        mergeMap(() => ctx.dispatch([new IgnoreQualityIssuesSuccess(qualityIssues)])),
        catchError((error: string) => {
          // Restore initial state
          this._updateQualityIssues(ctx, issueType, ignoringQualityIssues);
          return ctx.dispatch(new IgnoreQualityIssuesFail(error));
        })
      );
  }

  @Action(RestoreQualityIssues)
  public bulkSetQualityIssues(ctx: StateContext<QualityIssuesStateModel>): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    this.qualityIssueDAO.getProjectQualityIssues(projectId).subscribe((qi) => {
      ctx.setState(patch(qi));
    });
  }

  @Action(ResetQualityIssues)
  public resetQualityIssues(ctx: StateContext<QualityIssuesStateModel>): void {
    ctx.setState(DEFAULT_STATE);
  }

  @Action(SetExpandQualityIssueStatus)
  public setExpandedQualityIssueStatus(
    ctx: StateContext<QualityIssuesStateModel>,
    action: SetExpandQualityIssueStatus
  ): void {
    const id = action.qualityIssue.id;
    const translationUnitId = action.qualityIssue.parentTranslationUnitId || action.qualityIssue.translationUnit.id;

    const consistencyQualityIssues = this.store.selectSnapshot(
      QualityIssuesState.getQualityIssues(IssueType.Consistency)
    );

    const parentQualityIssue = consistencyQualityIssues.find(
      (qi) => qi.id === id && qi.identicalRows?.length && qi.translationUnit.id === translationUnitId
    );

    const identicalQualityIssues = parentQualityIssue.identicalRows?.map((qi: QualityIssue) => ({
      ...qi,
      isExpatendedIdenticalRows: action.expanded,
      isIgnored: parentQualityIssue.isIgnored,
    }));
    const updatedQualityIssue: QualityIssue = {
      ...parentQualityIssue,
      isExpatendedIdenticalRows: action.expanded,
      identicalRows: identicalQualityIssues,
    };
    this._updateQualityIssues(ctx, IssueType.Consistency, [updatedQualityIssue]);
    const StoreAction = action.expanded ? AddedQualityIssues : RemovedQualityIssues;
    ctx.dispatch(new StoreAction(IssueType.Consistency, identicalQualityIssues));
  }

  @Action(UpdateQualityIssueCommentStatus)
  public updateQualityIssueCommentStatus(
    ctx: StateContext<QualityIssuesStateModel>,
    action: UpdateQualityIssueCommentStatus
  ): void {
    const state = ctx.getState();
    Object.values(IssueType).forEach((issueType: IssueType) => {
      if (!state[issueType]) {
        return;
      }
      const qualityIssuesWithSameTranslationUnit: QualityIssue[] = state[issueType].reduce(
        (qualityIssues: QualityIssue[], q: QualityIssue) => {
          if (q.translationUnit.id === action.translationUnitId) {
            qualityIssues.push({
              ...q,
              translationUnit: {
                ...q.translationUnit,
                hasComments: action.hasComments,
              },
            });
          }
          return qualityIssues;
        },
        []
      );

      this._updateQualityIssues(ctx, issueType, qualityIssuesWithSameTranslationUnit);
    });
  }

  // Now it works only for consistency quality issues
  @Action(CollapseAllIdenticalQualityIssues)
  public collapseAllIdenticalQualityIssues(ctx: StateContext<QualityIssuesStateModel>): void {
    const consistencyQualityIssues = this.store
      .selectSnapshot(QualityIssuesState.getQualityIssues(IssueType.Consistency))
      .map((qi) => ({ ...qi, isExpatendedIdenticalRows: false }));

    ctx.setState(
      patch({
        [IssueType.Consistency]: consistencyQualityIssues,
      })
    );
  }

  @Action(BulkUpdateIgnoreStatus)
  public bulkUpdateIgnoreStatus(
    ctx: StateContext<QualityIssuesStateModel>,
    action: BulkUpdateIgnoreStatus
  ): QualityIssuesStateModel {
    const state = ctx.getState();

    const updatedQualityIssues = state[action.issueType].map((qi) => {
      const ignoredIssue = action.ignoredIssues.find((issue) => issue.qualityIssueId === qi.id);
      if (ignoredIssue) {
        return { ...qi, isIgnored: ignoredIssue.isIgnored };
      }
      return qi;
    });

    const currentProject = this.store.selectSnapshot(ProjectState.project);
    this.qualityIssueDAO.bulkCreateOrUpdate(currentProject.id, action.issueType, updatedQualityIssues).subscribe();

    const updatedState = ctx.setState(
      patch({
        [action.issueType]: updatedQualityIssues,
      })
    );

    ctx.dispatch(new UpdatedQualityIssues(action.issueType, updatedQualityIssues));
    return updatedState;
  }

  private _updateQualityIssues(
    ctx: StateContext<QualityIssuesStateModel>,
    issueType: IssueType,
    qualityIssues: QualityIssue[]
  ): QualityIssuesStateModel {
    const state = ctx.getState();
    const updatedQualityIssues = state[issueType].map((qualityIssue) => {
      const index = qualityIssues.findIndex(
        (a) => a.id === qualityIssue.id && a.translationUnit.id === qualityIssue.translationUnit.id
      );
      if (index >= 0) {
        qualityIssue = qualityIssues[index];
      }
      return qualityIssue;
    });

    const currentProject = this.store.selectSnapshot(ProjectState.project);
    this.qualityIssueDAO.bulkCreateOrUpdate(currentProject.id, issueType, updatedQualityIssues).subscribe();

    const updatedState = ctx.setState(
      patch({
        [issueType]: updatedQualityIssues,
      })
    );
    ctx.dispatch(new UpdatedQualityIssues(issueType, qualityIssues));
    return updatedState;
  }
}
