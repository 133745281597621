import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: '',
})
export class BaseButtonComponent {
  @Input() disabled = false;
  @Input() stopPropagation = false;
  @Input() active = false;
  @Output() clickButton = new EventEmitter<MouseEvent>();

  public onClick(event: MouseEvent): void {
    if (this.stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!this.disabled) {
      this.clickButton.emit(event);
    }
  }
}
