<button
  [type]="type"
  [disabled]="disabled"
  [ngClass]="[theme, 'size-' + size, active ? 'active' : '']"
  (click)="onClick($event)"
>
  <app-spinner class="loader" *ngIf="loading" [size]="spinnerSize.Button"> </app-spinner>

  <app-ui-icon class="button-prefix-icon" *ngIf="prefixIcon && !loading" [icon]="prefixIcon" [color]="prefixIconColor">
  </app-ui-icon>

  <ng-content></ng-content>

  <app-ui-icon class="button-suffix-icon" *ngIf="suffixIcon && !loading" [icon]="suffixIcon" [color]="suffixIconColor">
  </app-ui-icon>
</button>
