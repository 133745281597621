import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionIconComponent } from './action-icon.component';
import { IconModule } from '@shared/components/icon';

@NgModule({
  declarations: [ActionIconComponent],
  exports: [ActionIconComponent],
  imports: [CommonModule, IconModule],
})
export class ActionIconModule {}
