import { Pipe, PipeTransform } from '@angular/core';
import { regExpEscape } from '@shared/tools';

@Pipe({
  name: 'markFiltered',
})
export class MarkFilteredPipe implements PipeTransform {
  transform(value: any, searchValue?: string): any[] {
    if (!value || !searchValue) {
      return value;
    }
    const re = new RegExp(regExpEscape(searchValue), 'gi');
    return value.replace(re, '<span class="highlighted">$&</span>');
  }
}
