import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoRowsOverlayComponent } from '@shared/components/grid-components/no-rows-overlay/no-rows-overlay.component';
import { IconModule } from '../icon';
import { TooltipModule } from '../tooltip';
import { NumberBoxModule } from '../number-box';
import { gridEditorComponents } from './cell-edtiors';
import { gridCellComponents } from './cell-renderers';
import { PopoverModule } from '@shared/components/popover';
import { PipesModule } from '@shared/pipes';
import { SegmentCellEditorComponent } from './segment-cell-editor';
import { SegmentEditorModule } from '@shared/components/segment-editor';
import { SegmentRendererModule } from '@shared/components/segment-renderer';
import { CommentCellRendererComponent } from './comment-cell-renderer/comment-cell-renderer.component';
import { ContextViewerRendererComponent } from './context-viewer-renderer';
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { SimpleCellEditorComponent } from '@shared/components/grid-components/cell-edtiors';
import { ChipListModule } from '@shared/components/chip-list';
import { GridHelperService } from '@shared/services/grid-helper.service';
import { EmptyMessageModule } from '../empty-message/empty-message.module';

const components = [
  GridHeaderComponent,
  NoRowsOverlayComponent,
  CommentCellRendererComponent,
  ...gridCellComponents,
  ...gridEditorComponents,
  SegmentCellEditorComponent,
  ContextViewerRendererComponent
];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TooltipModule,
    PopoverModule,
    NumberBoxModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    PipesModule,
    SegmentRendererModule,
    SegmentEditorModule,
    ChipListModule,
    EmptyMessageModule,
  ],
  declarations: [...components, SimpleCellEditorComponent],
  providers: [GridHelperService],
  exports: [...components],
})
export class GridComponentsModule {}
