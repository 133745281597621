import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { MeasurementUnitsComponent } from '@shared/components/measurement-units/measurement-units.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GridComponentsModule } from '@shared/components/grid-components/grid-components.module';
import { DirectivesModule } from '@shared/directives';
import { ModalModule } from '@shared/components/modal';
import { TooltipModule } from '@shared/components/tooltip';
import { ActionIconModule } from '@shared/components/action-icon';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridComponentsModule,
    AgGridModule,
    SearchInputModule,
    DirectivesModule,
    ModalModule,
    TooltipModule,
    ActionIconModule,
    ButtonModule,
  ],
  declarations: [MeasurementUnitsComponent],
  exports: [MeasurementUnitsComponent],
})
export class MeasurementUnitsModule {}
