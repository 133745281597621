import { IssueType, Segment } from '@generated/api';
import { QualityIssue, RawQualityIssue, FileId } from '@shared/models';

enum ActionTypes {
  CLEAR_QUALITY_ISSUES = '[QualityIssue] Clear Quality Issues',
  ADD_QUALITY_ISSUES = '[QualityIssue] Add Quality Issues',
  REMOVE_QUALITY_ISSUES = '[QualityIssue] Remove Quality Issues',
  REMOVE_QUALITY_ISSUES_BY_FILE_ID = '[QualityIssue] Remove Quality Issues By File Id',
  IGNORE_QUALITY_ISSUES = '[QualityIssue] Ignore Quality Issues',
  IGNORE_QUALITY_ISSUES_SUCCESS = '[QualityIssue] Ignore Quality Issues Success',
  IGNORE_QUALITY_ISSUES_FAILURE = '[QualityIssue] Ignore Quality Issues Failure',
  APPLY_TRANSLATION_UNIT = '[QualityIssue] Apply Translation Unit',
  APPLY_SEGMENT = '[QualityIssue] Apply Segment',
  RECHECK_TRANSLATION_UNIT = '[QualityIssue] Recheck Translation Unit',
  RECHECK_TRANSLATION_UNIT_SUCCESS = '[QualityIssue] Recheck Translation Unit Success',
  RECHECK_TRANSLATION_UNIT_FAIL = '[QualityIssue] Recheck Translation Unit Fail',
  UPDATED_QUALITY_ISSUES = '[QualityIssue] Updated Quality Issues',
  ADDED_QUALITY_ISSUES = '[QualityIssue] Added Quality Issues',
  REMOVED_QUALITY_ISSUES = '[QualityIssue] Removed Quality Issues',
  LOAD_QUALITY_ISSUES = '[QualityIssue] Load Quality Issues',
  LOAD_QUALITY_ISSUES_SUCCESS = '[QualityIssue] Load Quality Issues Success',
  LOAD_QUALITY_ISSUES_FAILURE = '[QualityIssue] Load Quality Issues Failure',
  PROCESS_NEW_QUALITY_ISSUES = '[QualityIssue] Process New Quality Issues',
  RESTORE_QUALITY_ISSUES = '[QualityIssue] Restore from cache',
  RESET_QUALITY_ISSUES = '[QualityIssue] Reset Quality Issues',
  SET_QUALITY_ISSUE_EXPAND_STATUS = '[QualityIssue] Set Quality Issue Expand Status',
  COLLAPSE_ALL_IDENTICAL_QUALITY_ISSUES = '[QualityIssue] Collapsse All Identical Quality Issues',
  LOAD_QUALITY_ISSUES_BY_IDS = '[QualityIssue] Load By Ids',
  UPDATE_COMMENT_STATUS = '[QualityIssue] Update Comment Status',
  BUILK_UPDATE_IGNORE_STATUS = '[QualityIssue] Bulk update ignore status',
}

export class ClearQualityIssues {
  static readonly type = ActionTypes.CLEAR_QUALITY_ISSUES;
  constructor() {}
}

export class AddQualityIssues {
  static readonly type = ActionTypes.ADD_QUALITY_ISSUES;
  constructor(
    public issueType: IssueType,
    public qualityIssues: RawQualityIssue[] | QualityIssue[],
    public bulkInsert?: boolean
  ) {}
}

export class RemoveQualityIssues {
  static readonly type = ActionTypes.REMOVE_QUALITY_ISSUES;
  constructor(public issueType: IssueType, public qualityIssueIds: string[]) {}
}

export class RemoveQualityIssuesByFileId {
  static readonly type = ActionTypes.REMOVE_QUALITY_ISSUES_BY_FILE_ID;
  constructor(public projectId: string, public fileId: FileId) {}
}

export class IgnoreQualityIssues {
  static readonly type = ActionTypes.IGNORE_QUALITY_ISSUES;
  constructor(
    public reportId: string,
    public issueType: IssueType,
    public ids: string[],
    public isIgnored: boolean,
    public isOneGroup: boolean
  ) {}
}

export class IgnoreQualityIssuesSuccess {
  static readonly type = ActionTypes.IGNORE_QUALITY_ISSUES_SUCCESS;
  constructor(public qualityIssues: QualityIssue[]) {}
}

export class IgnoreQualityIssuesFail {
  static readonly type = ActionTypes.IGNORE_QUALITY_ISSUES_FAILURE;
  constructor(public error: string) {}
}

export class RecheckTranslationUnit {
  static readonly type = ActionTypes.RECHECK_TRANSLATION_UNIT;
  constructor(
    public reportId: string,
    public issueType: IssueType,
    public translationUnitId: string,
    public targetSegment: Segment
  ) {}
}

export class ApplySegment {
  static readonly type = ActionTypes.APPLY_SEGMENT;
  constructor(public reportId: string, public qualityIssue: QualityIssue, public targetSegment: Segment) {}
}

export class RecheckTranslationUnitSuccess {
  static readonly type = ActionTypes.RECHECK_TRANSLATION_UNIT_SUCCESS;
  constructor(public translationUnitId: string) {}
}

export class RecheckTranslationUnitFail {
  static readonly type = ActionTypes.RECHECK_TRANSLATION_UNIT_FAIL;
  constructor(public error: Error) {}
}

export class ApplyTranslationUnit {
  static readonly type = ActionTypes.APPLY_TRANSLATION_UNIT;
  constructor(public reportId: string, public qualityIssue: QualityIssue) {}
}

export class UpdatedQualityIssues {
  static readonly type = ActionTypes.UPDATED_QUALITY_ISSUES;
  constructor(public issueType: IssueType, public qualityIssues: QualityIssue[]) {}
}

export class AddedQualityIssues {
  static readonly type = ActionTypes.ADDED_QUALITY_ISSUES;
  constructor(public issueType: IssueType, public qualityIssues: QualityIssue[], public bulkInsert?: boolean) {}
}

export class RemovedQualityIssues {
  static readonly type = ActionTypes.REMOVED_QUALITY_ISSUES;
  constructor(public issueType: IssueType, public qualityIssues: QualityIssue[]) {}
}

export class LoadQualityIssues {
  static readonly type = ActionTypes.LOAD_QUALITY_ISSUES;
  constructor(public reportId: string) {}
}

export class LoadQualityIssuesFail {
  static readonly type = ActionTypes.LOAD_QUALITY_ISSUES_FAILURE;
  constructor(public error: string) {}
}

export class ProcessNewQualityIssues {
  static readonly type = ActionTypes.PROCESS_NEW_QUALITY_ISSUES;
  constructor(
    public translationUnits: {
      issueType: IssueType;
      translationUnitId: string;
    }[],
    public qualityIssues: QualityIssue[]
  ) {}
}

export class RestoreQualityIssues {
  static readonly type = ActionTypes.RESTORE_QUALITY_ISSUES;
  constructor() {}
}

export class ResetQualityIssues {
  static readonly type = ActionTypes.RESET_QUALITY_ISSUES;
}

export class SetExpandQualityIssueStatus {
  static readonly type = ActionTypes.SET_QUALITY_ISSUE_EXPAND_STATUS;
  constructor(public qualityIssue: QualityIssue, public expanded: boolean) {}
}

export class CollapseAllIdenticalQualityIssues {
  static readonly type = ActionTypes.COLLAPSE_ALL_IDENTICAL_QUALITY_ISSUES;
}

export class LoadQualityIssuesByIds {
  static readonly type = ActionTypes.LOAD_QUALITY_ISSUES_BY_IDS;
  constructor(
    public reportId: string,
    public issueType: IssueType,
    public ids: string[] = [],
    public possibleExistedIds?: string[]
  ) {}
}

export class UpdateQualityIssueCommentStatus {
  static readonly type = ActionTypes.UPDATE_COMMENT_STATUS;
  constructor(public translationUnitId: string, public hasComments: boolean) {}
}

export class BulkUpdateIgnoreStatus {
  static readonly type = ActionTypes.BUILK_UPDATE_IGNORE_STATUS;
  constructor(
    public readonly issueType: IssueType,
    public readonly ignoredIssues: Array<{
      qualityIssueId: string;
      isIgnored: boolean;
    }>
  ) {}
}
