import { Predicate } from '@ngxs/store/operators/internals';
import { StateOperator } from '@ngxs/store';
import { RepairType } from '@ngxs/store/operators/utils';

export function replaceOrAppendItem<T>(predicate: Predicate<T>, newItem: T): StateOperator<RepairType<T>[]> {
  return (baseList: RepairType<T>[]): RepairType<T>[] => {
    let updated = false;
    const newList = baseList.map((item) => {
      if (predicate(item)) {
        updated = true;
        return newItem as RepairType<T>;
      }
      return item ;
    });
    if (!updated) {
      newList.push(newItem as RepairType<T>);
    }
    return newList;
  };
}
