<div class="search-list">
  <app-ui-search-input
    *ngIf="searchControl && searchEnabled"
    class="search-list-input"
    [formControl]="searchControl"
    [placeholder]="placeholder"
    autocomplete="off"
  >
  </app-ui-search-input>
  <div
    *ngIf="loading && !filteredItems?.length; else virtualScrollTemplate"
    class="spinner-container"
    [ngStyle]="{ height: listHeight + 'px' }"
  >
    <app-spinner></app-spinner>
  </div>
</div>

<ng-template #virtualScrollTemplate>
  <ng-container *ngIf="filteredItems.length; else noItemsTemplate">
    <virtual-scroller
      [ngStyle]="{ height: listHeight + 'px' }"
      [bufferAmount]="pageSize"
      [scrollDebounceTime]="scrollDebounceTime"
      [childWidth]="itemSize"
      #scroll
      class="search-results"
      [items]="filteredItems"
      (vsEnd)="onScrolledEnd($event)"
    >
      <ng-container *ngIf="itemTemplate; else internalRenderingTemplate">
        <ng-container *ngFor="let item of scroll.viewPortItems; let index = index">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                item: item,
                index: index,
                selected: selectedItemsMap[item[uniqueKey]] ?? item[uniqueKey] === selectedItem?.[uniqueKey]
              }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-template #internalRenderingTemplate>
        <ng-container *ngIf="selectMode === 'multiple'; else singleSelectListTemplate">
          <div
            *ngFor="let item of scroll.viewPortItems"
            class="search-list-item multiple"
            [style.height.px]="itemSize"
            [appTooltipTruncated]="item[nameKey]"
            appTooltipPlacement="bottom-right"
          >
            <mat-checkbox [checked]="selectedItemsMap[item[uniqueKey]]" (change)="selectItem(item, $event)">
              <span [innerHtml]="item[nameKey] | markFiltered: searchText"></span>
            </mat-checkbox>
          </div>
        </ng-container>
        <!-- Dual template for less *ngIf checking inside child nodes -->
        <ng-template #singleSelectListTemplate>
          <div
            *ngFor="let item of scroll.viewPortItems"
            class="search-list-item single"
            [style.height.px]="itemSize"
            [class.selected]="selectedItem && item[uniqueKey] === selectedItem[uniqueKey]"
            (click)="selectItem(item)"
            [appTooltipTruncated]="item[nameKey]"
            appTooltipPlacement="bottom-right"
          >
            <span [innerHtml]="item[nameKey] | markFiltered: searchText"></span>
          </div>
        </ng-template>
      </ng-template>
      <div *ngIf="selectMode === 'multiple'" class="select-all-wrapper">
        <mat-checkbox
          [indeterminate]="!isAllSelected && someItemSelected"
          [checked]="isAllSelected"
          (change)="toggleAllSelection()"
          >{{ selecAllText }}</mat-checkbox
        >
      </div>
      <div *ngIf="loading && filteredItems.length" class="list-data-loader">
        <app-spinner [size]="spinnerSizes.Small"></app-spinner>
      </div>
    </virtual-scroller>
  </ng-container>
</ng-template>

<ng-template #noItemsTemplate>
  <div [ngStyle]="{ height: listHeight + 'px' }" class="no-items">
    <ng-container *ngIf="emptyListTemplate && !searchControl.value; else nativeNoItemsTemplate">
      <ng-container *ngTemplateOutlet="emptyListTemplate"></ng-container>
    </ng-container>
    <ng-template #nativeNoItemsTemplate>
      {{ searchText || searchControl.value ? notFoundText : 'List is empty' }}
    </ng-template>
  </div>
</ng-template>
