<div #wrapperDivElement class="wrapper" [class.without-background]="!isTarget">
  <app-segment-editor
    #editor
    [readonly]="!isTarget"
    [selection]="selectionRanges"
    [isTarget]="isTarget"
    [showInvisibles]="showInvisibles"
    [tagView]="tagView"
    [multiline]="true"
    [segment]="segment"
    [rightToLeft]="rightToLeft"
    (outsideClicked)="targetChangesHandler()"
    (keyup)="setCellHeight()"
    (segmentChange)="segmentChanged($event)"
    [sharingRoomKey]="editorsSharingRoomKey"
    (click)="onClickHandler($event)"
    (keyDown)="onKeyDown($event)"
    (focus)="onClickHandler($event)"
  >
  </app-segment-editor>
</div>
