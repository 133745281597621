import { KeyCodes } from './key-codes.model';
import {
  ControlPanelKeybindingEvent,
  EditorKeybindingEvent,
  GroupedShortcutsReference,
  KeybindingGroups,
  TableKeybindingEvent,
} from './shortcuts.model';

export const getDefaultGroupedShortcutsReference = (isMac?: boolean): GroupedShortcutsReference => {
  const defaultGroupedShortcutsReference: GroupedShortcutsReference = {
    [KeybindingGroups.TABLE]: [
      [
        {
          description: 'Move between segments',
          shortcuts: [
            {
              key: KeyCodes.KEY_UP,
              event: TableKeybindingEvent.PREVIOUS_QUALITY_ISSUE,
            },
            {
              key: KeyCodes.KEY_DOWN,
              event: TableKeybindingEvent.NEXT_QUALITY_ISSUE,
            },
          ],
        },
      ],
      [
        {
          description: 'Collapse group',
          shortcuts: [
            {
              key: KeyCodes.KEY_LEFT,
              event: TableKeybindingEvent.COLLAPSE_GROUP,
            },
          ],
        },
        {
          description: 'all groups',
          shortcuts: [
            {
              key: [KeyCodes.KEY_SHIFT, KeyCodes.KEY_LEFT],
              event: TableKeybindingEvent.COLLAPSE_ALL_GROUPS,
            },
          ],
        },
      ],
      [
        {
          description: 'Expand group',
          shortcuts: [
            {
              key: KeyCodes.KEY_RIGHT,
              event: TableKeybindingEvent.EXPAND_GROUP,
            },
          ],
        },
        {
          description: 'all groups',
          shortcuts: [
            {
              key: [KeyCodes.KEY_SHIFT, KeyCodes.KEY_RIGHT],
              event: TableKeybindingEvent.EXPAND_ALL_GROUPS,
            },
          ],
        },
      ],
      [
        {
          description: 'Ignore QA issue',
          shortcuts: [
            {
              key: KeyCodes.KEY_SPACE,
              event: TableKeybindingEvent.IGNORE_ISSUE,
            },
          ],
        },
      ],
      [
        {
          description: 'Ignore group',
          shortcuts: [
            {
              event: TableKeybindingEvent.IGNORE_GROUP,
              key: isMac
                ? [KeyCodes.KEY_ALT, KeyCodes.KEY_SPACE]
                : [KeyCodes.KEY_SHIFT, KeyCodes.KEY_CONTROL, KeyCodes.KEY_SPACE],
            },
          ],
        },
      ],
      // TODO: VW-1093 вернуть после реализации логики
      // [
      //   {
      //     description: 'Apply autofix to group',
      //     shortcuts: [
      //       {
      //         key: isMac ? [KeyCodes.KEY_ALT, KeyCodes.KEY_A] : [KeyCodes.KEY_SHIFT, KeyCodes.KEY_ALT, KeyCodes.KEY_A],
      //         event: TableKeybindingEvent.APPLY_AUTOFIX_TO_GROUP,
      //       },
      //     ],
      //   },
      // ]
      [
        {
          description: 'Enter segment edit mode',
          shortcuts: [
            {
              key: KeyCodes.KEY_ENTER,
              event: TableKeybindingEvent.ENTER_SEGMENT_EDIT_MODE,
            },
          ],
        },
      ],
      [
        {
          description: 'Exit segment edit mode',
          shortcuts: [
            {
              key: KeyCodes.KEY_ESCAPE,
              event: TableKeybindingEvent.EXIT,
            },
          ],
        },
      ],
    ],
    [KeybindingGroups.EDITOR]: [
      [
        {
          description: 'Confirm edit',
          shortcuts: [
            {
              key: KeyCodes.KEY_ENTER,
              event: EditorKeybindingEvent.CONFIRM_EDIT,
            },
            {
              key: KeyCodes.KEY_NUMPAD_ENTER,
              event: EditorKeybindingEvent.CONFIRM_EDIT,
              suppressVisualReference: true,
            },
          ],
        },
      ],
      [
        {
          description: 'Open autofix menu',
          shortcuts: [
            {
              key: [KeyCodes.KEY_ALT, KeyCodes.KEY_A],
              event: EditorKeybindingEvent.OPEN_AUTOFIX_MENU,
            },
          ],
          children: [
            [
              {
                description: 'Select autofix option',
                shortcuts: [
                  {
                    key: KeyCodes.KEY_UP,
                    event: EditorKeybindingEvent.SELECT_PREVIOUS_AUTOFIX_OPTION,
                  },
                  {
                    key: KeyCodes.KEY_DOWN,
                    event: EditorKeybindingEvent.SELECT_NEXT_AUTOFIX_OPTION,
                  },
                ],
              },
            ],
            [
              {
                description: 'Apply autofix',
                shortcuts: [
                  {
                    key: KeyCodes.KEY_NUMPAD_ENTER,
                    event: EditorKeybindingEvent.APPLY_AUTOFIX,
                    suppressVisualReference: true,
                  },
                  {
                    key: KeyCodes.KEY_ENTER,
                    event: EditorKeybindingEvent.APPLY_AUTOFIX,
                  },
                ],
              },
            ],
          ],
        },
      ],
      [
        {
          description: 'Quick tag insert',
          shortcuts: [
            {
              key: KeyCodes.KEY_ALT,
              numbers: true,
              event: EditorKeybindingEvent.QUICK_TAG_INSERT,
            },
          ],
        },
      ],
      [
        {
          description: 'Open special symbols menu',
          shortcuts: [
            {
              key: [KeyCodes.KEY_ALT, KeyCodes.KEY_S],
              event: EditorKeybindingEvent.OPEN_SPECIAL_SYMBOLS_MENU,
            },
          ],
        },
      ],
      [
        {
          description: 'Exit from editor',
          suppressVisualReference: true,
          shortcuts: [
            {
              key: KeyCodes.KEY_ESCAPE,
              event: EditorKeybindingEvent.EXIT,
            },
          ],
        },
      ],
    ],
    [KeybindingGroups.CONTROL_PANEL]: [
      [
        {
          description: 'Display non-printing characters',
          shortcuts: [
            {
              key: KeyCodes.KEY_N,
              event: ControlPanelKeybindingEvent.DISPLAY_NON_PRINTING_CHARACTERS,
            },
          ],
        },
      ],
      [
        {
          description: 'Switch tags display',
          shortcuts: [
            {
              key: KeyCodes.KEY_T,
              event: ControlPanelKeybindingEvent.SWITCH_TAGS_DISPLAY,
            },
          ],
        },
      ],
      [
        {
          description: 'Add comment',
          shortcuts: [
            {
              key: KeyCodes.KEY_C,
              event: ControlPanelKeybindingEvent.ADD_COMMENT,
            },
          ],
        },
      ],
      [
        {
          description: 'View segment in context',
          shortcuts: [
            {
              key: KeyCodes.KEY_V,
              event: ControlPanelKeybindingEvent.VIEW_SEGMENT_IN_CONTEXT,
            },
          ],
        },
      ],
      [
        {
          description: 'Export QA report to Excel',
          shortcuts: [
            {
              key: KeyCodes.KEY_E,
              event: ControlPanelKeybindingEvent.EXPORT_QA_REPORT_TO_EXCEL,
            },
          ],
        },
      ],
      [
        {
          description: 'Copy link to report',
          shortcuts: [
            {
              key: KeyCodes.KEY_L,
              event: ControlPanelKeybindingEvent.COPY_LINK_TO_REPORT,
            },
          ],
        },
      ],
    ],
  };

  return defaultGroupedShortcutsReference;
};
