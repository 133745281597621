import { IAfterGuiAttachedParams } from '@ag-grid-community/core';
import { IDoesFilterPassParams, IFilterAngularComp, IFilterParams, ValueGetter } from 'src/types/ag-grid';
import { ReportGridColumnFilterModel } from '@shared/models';

export abstract class BooleanFilterBase implements IFilterAngularComp {
  protected params: IFilterParams;
  protected valueGetter: ValueGetter;

  protected guiAttachedParams?: IAfterGuiAttachedParams;

  public flag: boolean = null;

  public toggle(val: boolean | null): void {
    if (this.flag === val) {
      this.flag = null;
    } else {
      this.flag = val;
    }
    this.params.filterChangedCallback();
    this.guiAttachedParams?.hidePopup?.();
  }

  public agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  public isFilterActive(): boolean {
    return this.flag != null;
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.valueGetter(params) === this.flag;
  }

  public getModel(): ReportGridColumnFilterModel<boolean> {
    if (this.flag === undefined || this.flag === null) {
      return null;
    }
    return { value: this.flag };
  }

  public setModel(model: ReportGridColumnFilterModel<boolean>): void {
    this.flag = model?.value;
    this.guiAttachedParams?.hidePopup?.();
  }

  public afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    this.guiAttachedParams = params;
  }
}
