export enum AutofixType {
  Remove = 'Remove',
  Add = 'Add',
  Replace = 'Replace with',
}

export enum InsertAutofixPosition {
  Left = 'left',
  Right = 'right',
}

export class FixableSelectionRange {
  fix?: string; // symbol for inserting
  isFixAvailable?: boolean; // Is auto-correction available?
  autofixType?: AutofixType;
  autofixRange?: SelectionRange;

  // We can't render range with a zero position, which means that we need to have
  // a separate pointer to autofix insertion
  insertPosition?: InsertAutofixPosition;
}

export class SelectionRange extends FixableSelectionRange {
  public start?: number;
  public length?: number;
  public end?: number;

  constructor(start: number, end: number) {
    super();
    this.start = start;
    this.end = end;
    this.length = this.end - this.start;
  }
}

export class SelectionHighlight extends FixableSelectionRange {
  range: SelectionRange;
}

// NOTE: Merged types between, this type should be removed after
// quality issue model refactoring
// @type {SelectionRange}
// @type {SelectionHighlight}
export interface UniversalSelectionRange extends FixableSelectionRange {
  range?: { start: number; end: number; length: number };
  start?: number;
  end?: number;
  length?: number;
}
