import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionStatus } from '@generated/payment-api';

@Pipe({
  name: 'subscriptionPrettyStatus',
})
export class SubscriptionPrettyStatusPipe implements PipeTransform {
  public transform(value: SubscriptionStatus): string {
    if (!value) {
      return '';
    }
    return SubscriptionStatus[value];
  }
}
