import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@shared/pipes';
import { IconModule } from '../icon';
import { SubscriptionStatusComponent } from './subscription-status.component';

@NgModule({
  imports: [CommonModule, IconModule, PipesModule],
  declarations: [SubscriptionStatusComponent],
  exports: [SubscriptionStatusComponent],
})
export class SubscriptionStatusModule {}
