import { PotentialUntranslatablesStateModel } from '@store/potential-untranslatables/potential-untranslatables.state';

export class LoadPotentialUntranslatables {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Load Potential Untranslatables';
}

export class LoadPotentialUntranslatablesCount {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Load Potential Untranslatables Count';
}

export class LoadPotentialUntranslatablesContext {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Load Potential Untranslatables Context';
  constructor(public text: string) {}
}

export class RemovePotentialUntranslatables {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Remove Potential Untranslatables';
  constructor(public items: string[]) {}
}

export class PatchPotentialUntranslatables {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Patch Load Potential Untranslatables';
  constructor(public projectId: string, public state: Partial<PotentialUntranslatablesStateModel>) {}
}

export class ShowPotentialUntranslatables {
  static readonly type = '[POTENTIAL_UNTRANSLATABLES] Hide Load Potential Untranslatables';
  constructor(public payload: boolean) {}
}
