import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { ContextMenuService } from './context-menu.service';

@Directive({
  selector: '[appContextClick]',
})
export class ContextMenuDirective implements OnDestroy, OnInit {
  private destroyed$: Subject<void> = new Subject<void>();

  @Input()
  appContextClick: TemplateRef<any>;

  @Output()
  contextMenuOpened: EventEmitter<void> = new EventEmitter();

  @Output()
  contextMenuClosed: EventEmitter<void> = new EventEmitter();

  @Input()
  public contextOnSelection: boolean;

  @Input()
  public contextMenuEvent = 'contextmenu';

  @Input()
  public contextMenuOpenManual = false;

  @Input()
  public contextCloseOnScroll = false;

  @Input()
  public contextCloseOnClick: boolean = false;

  @Input()
  public contextAttachToElement: HTMLElement;

  @Input()
  // VW-1245 TODO: грязный костыль, в нормальной версии нужно будет добавить контейнер,
  // к которому можно опционально прикрепить контекстное меню
  public contextYOffset: number = 0;

  @Input()
  public contextXOffset: number = 0;

  @Input()
  public contextCloseIcon = false;

  constructor(
    private hostElement: ElementRef<HTMLElement>,
    private contextMenuService: ContextMenuService,
    private readonly viewRef: ViewContainerRef
  ) {}

  @HostListener('document:scroll', ['$event'])
  public onScroll(): void {
    if (this.contextCloseOnScroll) {
      this.close();
    }
  }

  @HostListener('document:mousewheel', ['$event'])
  public onMouseWheel(): void {
    if (this.contextCloseOnScroll) {
      this.close();
    }
  }

  @HostListener('click', ['$event'])
  public clickInside(event: MouseEvent): void {
    if (this.contextMenuService.isOpened) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.contextCloseOnClick) {
      this.close();
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public clickOutside(event: MouseEvent, targetEl: HTMLHtmlElement): void {
    const clickedInside = this.contextMenuService.isElementInsideContextMenu(targetEl);
    if (!clickedInside) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.watchProvidedEvent();
  }

  public get isOpened(): boolean {
    return this.contextMenuService.isOpened;
  }

  private watchProvidedEvent(): void {
    fromEvent<MouseEvent>(this.hostElement.nativeElement, this.contextMenuEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        if (this.contextMenuOpenManual) {
          return;
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        if (!this.contextOnSelection || window.getSelection().toString()?.length) {
          this.open({ event });
        }
      });
  }

  public close(): void {
    this.contextMenuService.close();
    this.contextMenuClosed.emit();
  }

  public open(params?: { event?: MouseEvent; coords?: [number, number] }): void {
    this.contextMenuService.close();
    this.configureContextMenuService();
    this.contextMenuService.createContextMenu({
      hostElement: this.hostElement,
      templateRef: this.appContextClick,
      viewRef: this.viewRef,
      event: params?.event,
      coords: params?.coords,
    });
  }

  private configureContextMenuService(): void {
    this.contextMenuService.contextCloseOnScroll = this.contextCloseOnScroll;
    this.contextMenuService.contextCloseOnClick = this.contextCloseOnClick;
    this.contextMenuService.contextAttachToElement = this.contextAttachToElement;
    this.contextMenuService.contextYOffset = this.contextYOffset;
    this.contextMenuService.contextXOffset = this.contextXOffset;
    this.contextMenuService.contextCloseIcon = this.contextCloseIcon;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
