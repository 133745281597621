import { Directive } from '@angular/core';

/**
 * Title of a card.
 */
@Directive({
  selector: `app-card-title, [app-card-title], [appCardTitle]`,
  host: {
    class: 'card-title',
  },
})
export class CardTitleDirective {}
