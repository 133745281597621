import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberBoxComponent } from './number-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [NumberBoxComponent],
  exports: [NumberBoxComponent],
})
export class NumberBoxModule {}
