import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { IconPayload } from '@generated/icons/icons.model';
import { NotificationComponent } from './notification.component';

export interface NotificationAction {
  title: string;
  icon?: IconPayload;
}

export interface ToastMessage {
  text?: string;
  icon?: IconPayload;
  cancelable?: boolean;
  template?: TemplateRef<any>;
  config?: MatSnackBarConfig;
  actionBtn?: NotificationAction;
  wrap?: boolean;
}

export interface NotificationMessage extends ToastMessage {
  title?: string;
}
@Injectable()
export class NotificationService {
  public snackBarRef: MatSnackBarRef<any>;

  constructor(private snackBar: MatSnackBar) {}

  public notify(msg: NotificationMessage): MatSnackBarRef<any> {
    if (msg.template) {
      this.snackBarRef = this.snackBar.openFromTemplate(msg.template, msg.config);
      return this.snackBarRef;
    }
    this.snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
      ...msg.config,
      data: msg,
    });
    return this.snackBarRef;
  }

  public toast(message: ToastMessage): MatSnackBarRef<any> {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    };
    return this.notify({
      ...message,
      config: {
        ...config,
        ...message.config,
      },
    });
  }

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
