import { ChangeDetectorRef, OnDestroy, OnInit, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { Actions, Store } from '@ngxs/store';
import { AppService } from '@shared/services';
import { LoggerService } from '../../core/logger';

@Component({
  template: '',
})
export abstract class BaseLayoutComponent implements OnInit, OnDestroy {
  protected destroyed$ = new Subject<void>();

  constructor(
    public app: AppService,
    protected store: Store,
    protected cdr: ChangeDetectorRef,
    protected actions$: Actions,
    protected loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.preloadData();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  loadComplete(): void {
    this.app.loaded$.next(true);
    this.cdr.detectChanges();
    setTimeout(() => {
      this.app.loading$.next(false);
      this.cdr.detectChanges();
    }, 300);
  }

  abstract preloadData();
}
