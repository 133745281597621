import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationService } from '@shared/components/notification';
import { AppService } from '@shared/services';
import { ApplicationUpdateService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private notificationService: NotificationService,
    private appService: AppService,
    private appUpdateService: ApplicationUpdateService
  ) {}

  ngOnInit() {
    this.checkUpdates();
    this.watchApplicationUpdates();
    this.watchUnrecoverableChanges();
  }

  public updateApplication(): void {
    this.appUpdateService.applyUpdate()?.then(() => {
      window.location.reload();
    });
    this.notificationService.dismiss();
  }

  private checkUpdates(): void {
    if (this.appUpdateService.updateAvailable) {
      this.showUpdateMessage();
    }
  }

  private watchApplicationUpdates(): void {
    this.appUpdateService.watchApplicationUpdates();
    this.appUpdateService.watchAvaiableUpdates().subscribe(() => {
      this.showUpdateMessage();
    });
  }

  private watchUnrecoverableChanges(): void {
    this.appUpdateService.watchUnrecoverableUpdates().subscribe(() => {
      this.handleUnrecoverableState();
    });
  }

  private handleUnrecoverableState(): void {
    console.debug('Unrecoverable changes!');
    if (!this.appService.online) {
      this.showUnrecoverableMessage();
      return;
    }
    if (this.appService.lastStartedRoute) {
      console.debug('Replace location after unrecoverable changes!');
      window.location.replace(this.appService.lastStartedRoute);
      return;
    }
    console.debug('Location reload after unrecoverable changes!');
    window.location.reload();
  }

  private showUnrecoverableMessage(): void {
    this.notificationService.toast({
      text: 'This page needs an internet network',
    });
  }

  private showUpdateMessage(): void {
    this.notificationService
      .notify({
        title: 'New version is here!',
        text: 'Please click Refresh to update Verifika. Don’t worry, we will keep your data safe.',
        actionBtn: {
          title: 'Refresh',
        },
        config: {
          duration: undefined,
        },
      })
      .onAction()
      .subscribe(() => {
        this.updateApplication();
      });
  }
}
