import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';

export type ChipTheme = 'filled' | 'outlined';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent implements OnInit {
  @Input() text: string;
  @Input() selected = false;
  @Input() theme: ChipTheme = 'filled';
  @Input() icon: IconPayload = 'clear-circle';
  @Input() iconClickable = true;
  @Input() rtl = false;
  @Input() truncatedTooltipDisabled = false;
  @Output() iconClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public iconClickHandler(event: MouseEvent): void {
    if (!this.iconClickable) {
      return;
    }
    event.stopPropagation();
    this.iconClicked.emit();
  }
}
