import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, OnDestroy } from '@angular/core';
import CodeMirror, { MarkerRange } from 'codemirror';
import { AutofixType, SelectionRange, TagSegmentElement, TagType, TagView } from '@shared/models';
import {
  PartOfSegment,
  TagMarkerAdditionalInfo,
  TagWithStatus,
  CopiedTag,
  InsertSegmentAction,
  DeleteSegmentAction,
} from '@shared/components/segment-editor/models';
import { fromEvent, Subject } from 'rxjs';
import { TagComponent } from '@shared/components/tag';
import { getMarkerAdditionalInfo, getTagsNumber } from '@shared/components/segment-editor/utils';
import { uuid } from '@shared/tools';

type MarkerClickEventListener = (tagMarkerAdditionalInfo: TagMarkerAdditionalInfo, event: MouseEvent) => void;
type MarkerContextmenuEventListener = (tagMarkerAdditionalInfo: TagMarkerAdditionalInfo, event: MouseEvent) => void;

interface RelativeSelectionRangeFix {
  autofixType?: AutofixType;
  fix: string;
  relativeStart: number;
  relativeEnd: number;
}

@Injectable()
export class EditorHelperService implements OnDestroy {
  private destroyed$ = new Subject<void>();
  private markerClickEventListeners: MarkerClickEventListener[] = [];
  private markerContextmenuEventListeners: MarkerContextmenuEventListener[] = [];
  private editor: CodeMirror.EditorFromTextArea;
  private rtl = false;

  public tagContextMenuClicked$: Subject<[MouseEvent, TagWithStatus]> = new Subject<any>();

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  initEditor(editor: CodeMirror.EditorFromTextArea) {
    this.editor = editor;
  }

  setRtl(rtl: boolean) {
    this.rtl = rtl;
  }

  onMarkerClick(callback: MarkerClickEventListener) {
    this.markerClickEventListeners.push(callback);
  }

  onMarkerContextMenu(callback: MarkerClickEventListener) {
    this.markerContextmenuEventListeners.push(callback);
  }

  createTagElement(
    tagWithStatus: TagWithStatus,
    tagView: TagView,
    attributes?: { [name: string]: string }
  ): [HTMLElement, TagComponent] {
    const tagEl = document.createElement('span');

    if (attributes) {
      Object.keys(attributes).forEach((attr) => {
        tagEl.setAttribute(attr, attributes[attr]);
      });
    }

    // Create the component and wire it up with the element
    const factory = this.componentFactoryResolver.resolveComponentFactory(TagComponent);
    const tagComponentRef = factory.create(this.injector, [], tagEl);

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(tagComponentRef.hostView);

    // Listen to the change content or destroy component event
    this.destroyed$.subscribe(() => {
      tagEl.parentNode.removeChild(tagEl);
      this.applicationRef.detachView(tagComponentRef.hostView);
    });

    // Set the data
    tagComponentRef.instance.rtl = this.rtl;
    tagComponentRef.instance.selectionRanges = tagWithStatus.selectionRanges;
    tagComponentRef.instance.text = tagWithStatus.tag.text;
    tagComponentRef.instance.shortText = tagWithStatus.tag.shortText;
    tagComponentRef.instance.tagType = tagWithStatus.tag.tagType;
    tagComponentRef.instance.tagView = tagView;
    tagComponentRef.instance.withoutPair = tagWithStatus.withoutPair;
    tagComponentRef.instance.fullTagHighlighted = tagWithStatus.fullTagHighlighted;
    tagComponentRef.instance.isAutofixable = !!tagWithStatus.selectionRanges.find((r) => r.isFixAvailable);
    tagComponentRef.instance.draggable = true;

    fromEvent(tagEl, 'click').subscribe((event: MouseEvent) => {
      this.callClickCallbacks(
        {
          tagWithStatus,
          tagComponent: tagComponentRef.instance,
        },
        event
      );
    });

    fromEvent(tagEl, 'contextmenu').subscribe((event: MouseEvent) => {
      this.tagContextMenuClicked$.next([event, tagWithStatus]);
      this.callContextmenuCallbacks(
        {
          tagWithStatus,
          tagComponent: tagComponentRef.instance,
        },
        event
      );
    });

    return [tagEl, tagComponentRef.instance];
  }

  private callClickCallbacks(tagCom: TagMarkerAdditionalInfo, event: MouseEvent) {
    this.markerClickEventListeners.forEach((callback) => callback(tagCom, event));
  }

  private callContextmenuCallbacks(tagCom: TagMarkerAdditionalInfo, event: MouseEvent) {
    this.markerContextmenuEventListeners.forEach((callback) => callback(tagCom, event));
  }

  makeTagMarker(
    startPos: CodeMirror.Position,
    endPos: CodeMirror.Position,
    tagWithStatus: TagWithStatus,
    tagView: TagView
  ) {
    const markerId = uuid();
    const tagElAttributes = {
      markerId,
    };

    const [tagEl, tagComponent] = this.createTagElement(tagWithStatus, tagView, tagElAttributes);

    const markerAdditionalInfo: TagMarkerAdditionalInfo = {
      tagWithStatus,
      tagComponent,
    };

    const options: CodeMirror.TextMarkerOptions = {
      replacedWith: tagEl,
      readOnly: false,
      atomic: true,
      handleMouseEvents: false,
      title: tagWithStatus.tag.text,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      additionalInfo: markerAdditionalInfo,
      attributes: { markerId },
    };
    return this.editor.markText(startPos, endPos, options);
  }

  applyTags(tagsWithStatuses: TagWithStatus[], tagView: TagView) {
    for (const tagWithStatus of tagsWithStatuses) {
      const tag = tagWithStatus.tag;
      const startPos = this.editor.posFromIndex(tag.start);
      const endPos = this.editor.posFromIndex(tag.end);
      this.makeTagMarker(startPos, endPos, tagWithStatus, tagView);
    }
  }

  public getAllMarkers(): CodeMirror.TextMarker[] {
    const doc = this.editor.getDoc();
    return doc.getAllMarks();
  }

  public getAllTagMarkers(): CodeMirror.TextMarker[] {
    return this.getAllMarkers().filter((marker) => !!getMarkerAdditionalInfo(marker));
  }

  public getAutofixMarkers(): CodeMirror.TextMarker[] {
    return this.getAllMarkers().filter((m) => m.attributes?.fix != null);
  }

  public isAutofixable(): boolean {
    return !!this.getAutofixMarkers().length;
  }

  getTagMarkerById(id: string): CodeMirror.TextMarker {
    return this.getAllMarkers().find((marker) => marker.attributes.markerId === id);
  }

  deleteRange(startPosition: CodeMirror.Position, endPosition: CodeMirror.Position): DeleteSegmentAction {
    const startIndex = this.editor.indexFromPos(startPosition);
    const endIndex = this.editor.indexFromPos(endPosition);
    this.editor.replaceRange('', startPosition, endPosition);
    return new DeleteSegmentAction(startIndex, endIndex - startIndex);
  }

  insertPartOfSegment(
    position: CodeMirror.Position,
    partOfSegment: PartOfSegment,
    tagView: TagView
  ): InsertSegmentAction {
    if (!partOfSegment) {
      return;
    }
    this.editor.getDoc().replaceRange(partOfSegment.text, position);
    const offset: number = this.editor.indexFromPos(position);
    const tagWithStatuses: TagWithStatus[] = partOfSegment.tags.map((tag) => ({
      fullTagHighlighted: false,
      selectionRanges: [],
      withoutPair: false,
      tag: {
        ...tag,
        start: offset + tag.start,
        end: offset + tag.end,
      },
    }));
    this.applyTags(tagWithStatuses, tagView);
    this.editor.setSelection(this.editor.posFromIndex(offset + partOfSegment.text.length));
    setTimeout(() => {
      this.editor.refresh();
      this.editor.focus();
    }, 0);
    return new InsertSegmentAction(partOfSegment, this.editor.indexFromPos(position));
  }

  insertPartOfSegmentBeforeSelection(
    range: CodeMirror.Range,
    partOfSegment: PartOfSegment,
    tagView: TagView
  ): InsertSegmentAction {
    const segmentInsertAction = this.insertPartOfSegment(range.from(), partOfSegment, tagView);
    this.editor.setSelection(
      this.offsetPosition(range.from(), partOfSegment.text.length),
      this.offsetPosition(range.to(), partOfSegment.text.length)
    );
    return segmentInsertAction;
  }

  insertPartOfSegmentsAroundSelection(
    range: CodeMirror.Range,
    startPartOfSegment: PartOfSegment,
    endPartOfSegment: PartOfSegment,
    tagView: TagView
  ): InsertSegmentAction[] {
    const insertSegmentAction: InsertSegmentAction[] = [];
    insertSegmentAction.push(
      this.insertPartOfSegment(range.to(), endPartOfSegment, tagView),
      this.insertPartOfSegment(range.from(), startPartOfSegment, tagView)
    );
    this.editor.setSelection(
      this.offsetPosition(range.from(), startPartOfSegment.text.length),
      this.offsetPosition(range.to(), startPartOfSegment.text.length)
    );
    return insertSegmentAction;
  }

  private getPartOfSegmentFromSelectionRange(range: CodeMirror.Range): PartOfSegment {
    const doc = this.editor.getDoc();
    const tagMarkers = doc.findMarks(range.from(), range.to()).filter((marker) => !!getMarkerAdditionalInfo(marker));
    const offset = this.editor.indexFromPos(range.from());
    const selectedTags: TagSegmentElement[] = tagMarkers.map((tagMarker) => {
      const markerPosition: CodeMirror.MarkerRange = tagMarker.find() as CodeMirror.MarkerRange;
      const markerStartIndex = this.editor.indexFromPos(markerPosition.from);
      const markerEndIndex = this.editor.indexFromPos(markerPosition.to);
      return {
        ...getMarkerAdditionalInfo(tagMarker).tagWithStatus.tag,
        start: markerStartIndex - offset,
        end: markerEndIndex - offset,
      };
    });

    return {
      text: this.editor.getRange(range.from(), range.to()),
      tags: selectedTags,
    };
  }

  getPartOfSegment(): PartOfSegment | null {
    const doc = this.editor.getDoc();
    if (!doc.somethingSelected()) {
      return null;
    }
    const selectionRanges = doc.listSelections();
    const segment: PartOfSegment = {
      text: '',
      tags: [],
    };
    selectionRanges.forEach((range) => {
      const rangeSegment = this.getPartOfSegmentFromSelectionRange(range);
      segment.text += rangeSegment.text;
      segment.tags.push(...rangeSegment.tags);
    });

    if (!segment.text && !segment.tags.length) {
      return null;
    }

    return segment;
  }

  offsetPosition(position: CodeMirror.Position, offset: number): CodeMirror.Position {
    return {
      ...position,
      ch: position.ch + offset,
    };
  }

  showOrHideTagNumber(show: boolean, tagPairsHasOneNumber: boolean) {
    const doc = this.editor.getDoc();
    const allMarkers: CodeMirror.TextMarker[] = doc.getAllMarks();

    const tagMarkers = allMarkers.filter((marker) => {
      const markerAdditionalInfo = getMarkerAdditionalInfo(marker);
      return markerAdditionalInfo && markerAdditionalInfo.tagWithStatus.selectionRanges.length;
    });

    const tagNumber = getTagsNumber(tagMarkers.map((marker) => getMarkerAdditionalInfo(marker).tagWithStatus.tag));

    tagMarkers.forEach((marker, index) => {
      const markerAdditionalInfo = getMarkerAdditionalInfo(marker);
      if (tagPairsHasOneNumber) {
        markerAdditionalInfo.tagComponent.tagNumber = show ? tagNumber[index] : null;
      } else {
        markerAdditionalInfo.tagComponent.tagNumber = show ? index + 1 : null;
      }
      markerAdditionalInfo.tagComponent.cdr.detectChanges();
    });
  }

  getTagByNumber(tagNumber: number): TagSegmentElement {
    const doc = this.editor.getDoc();
    const markers: CodeMirror.TextMarker[] = doc.getAllMarks();
    const marker = markers.find((item) => {
      const markerAdditionalInfo = getMarkerAdditionalInfo(item);
      return markerAdditionalInfo && markerAdditionalInfo.tagComponent.tagNumber === tagNumber;
    });
    return marker && getMarkerAdditionalInfo(marker).tagWithStatus.tag;
  }

  setSelectionOnTag(e: MouseEvent) {
    this.editor.focus();
    const markerId = (e.currentTarget as HTMLElement).getAttribute('markerId');
    const marker = this.getTagMarkerById(markerId);
    if (marker) {
      const markerPos = marker.find() as CodeMirror.MarkerRange;
      this.editor.setSelection(markerPos.from, markerPos.to);
    }
  }

  public applyHighlighting(selections: SelectionRange[]): void {
    selections.forEach((s) => {
      this.changeTagColorBySelectionRange(s);
      this.makeHighlightMarker(s.start, s.end, {
        fix: s.fix,
        autofixType: s.autofixType,
        relativeStart: s.autofixRange?.start,
        relativeEnd: s.autofixRange?.end,
      });
    });
  }

  public clearHighlight(remainingSelectionRanges: SelectionRange[]): void {
    const markers = this.getAllMarkers();
    markers.forEach((m) => {
      const markerPosition = m.find() as MarkerRange;
      const start = markerPosition.from.ch;
      const end = markerPosition.to.ch;
      const selectionRangePreserved = remainingSelectionRanges.find((s) => s.start <= start && s.end >= end);

      if (selectionRangePreserved) {
        return;
      }

      if (m.atomic) {
        const additionalInfo = getMarkerAdditionalInfo(m);
        additionalInfo.tagComponent.deselectTag();
        return;
      }
      m.clear();
    });
  }

  makeHighlightMarker(start: number, end: number, attributes?: RelativeSelectionRangeFix) {
    const cmStartPos = this.editor.posFromIndex(start);
    const cmEndPos = this.editor.posFromIndex(end);

    return this.editor.markText(cmStartPos, cmEndPos, {
      className: `vr-highlight ${attributes.fix != null ? 'vr-autofix' : ''}`,
      attributes: attributes as any,
    });
  }

  focusFirstHighlightMarker() {
    const highlightMarkers = this.editor.getAllMarks().filter((marker) => !getMarkerAdditionalInfo(marker));
    if (highlightMarkers.length) {
      const position = highlightMarkers[0].find() as CodeMirror.MarkerRange;
      this.editor.setSelection(position.from, position.to);
    }
  }

  changeTagView(tagView: TagView) {
    const doc = this.editor.getDoc();
    const allMarkers: CodeMirror.TextMarker[] = doc.getAllMarks();
    allMarkers
      .filter((marker) => !!getMarkerAdditionalInfo(marker))
      .forEach((marker) => {
        const tagMarkerAdditionalInfo = getMarkerAdditionalInfo(marker);
        tagMarkerAdditionalInfo.tagComponent.tagView = tagView;
        tagMarkerAdditionalInfo.tagComponent.cdr.detectChanges();
      });
  }

  private changeTagColorBySelectionRange(selectionRange: SelectionRange) {
    if (!selectionRange.isFixAvailable) {
      return;
    }
    const tagMarkers = this.getAllTagMarkers();
    tagMarkers.forEach((marker) => {
      const markerPosition = marker.find() as MarkerRange;
      const start = markerPosition.from.ch;
      const end = markerPosition.to.ch;
      if (start >= selectionRange.start && end <= selectionRange.end) {
        const tagMarkerAdditionalInfo = getMarkerAdditionalInfo(marker);
        tagMarkerAdditionalInfo.tagComponent.isAutofixable = true;
        tagMarkerAdditionalInfo.tagComponent.fullTagHighlighted = true;
        tagMarkerAdditionalInfo.tagComponent.cdr.detectChanges();
      }
    });
  }

  changeTagColorIfSelection(selectionsRanges: CodeMirror.Range[], somethingSelected: boolean) {
    const doc = this.editor.getDoc();
    const allMarkers: CodeMirror.TextMarker[] = doc.getAllMarks();
    allMarkers
      .filter((marker) => !!getMarkerAdditionalInfo(marker))
      .forEach((marker) => {
        const tagMarkerAdditionalInfo = getMarkerAdditionalInfo(marker);
        tagMarkerAdditionalInfo.tagComponent.selected = false;
        tagMarkerAdditionalInfo.tagComponent.cdr.detectChanges();
      });
    if (somethingSelected) {
      const selectedMarkers: CodeMirror.TextMarker[] = [];
      selectionsRanges.forEach((range) => {
        selectedMarkers.push(...doc.findMarks(range.from(), range.to()));
      });
      selectedMarkers
        .filter((marker) => !!getMarkerAdditionalInfo(marker))
        .forEach((marker) => {
          const tagMarkerAdditionalInfo = getMarkerAdditionalInfo(marker);
          tagMarkerAdditionalInfo.tagComponent.selected = true;
          tagMarkerAdditionalInfo.tagComponent.cdr.detectChanges();
        });
    }
  }

  checkSomethingSelectedInSelectionChange(obj: CodeMirror.EditorSelectionChange) {
    let somethingSelected = true;
    if (obj.ranges.length === 1) {
      const from = obj.ranges[0].from();
      const to = obj.ranges[0].to();
      if (from.line === to.line && from.ch === to.ch) {
        somethingSelected = false;
      }
    }
    return somethingSelected;
  }

  insertCopiedTags(copiedTag: CopiedTag, tagView: TagView): InsertSegmentAction[] {
    const insertSegmentActions: InsertSegmentAction[] = [];
    const doc = this.editor.getDoc();
    this.editor.startOperation();
    doc
      .listSelections()
      .reverse()
      .forEach((range) => {
        if (this.rangeIsCursor(range)) {
          insertSegmentActions.push(
            this.insertPartOfSegment(range.from(), this.tagToPartOfSegment(copiedTag.tag), tagView)
          );
        } else {
          if (copiedTag.tag.tagType === TagType.Standalone) {
            const insertSegmentAction = this.insertPartOfSegmentBeforeSelection(
              range,
              this.tagToPartOfSegment(copiedTag.tag),
              tagView
            );
            insertSegmentActions.push(insertSegmentAction);
          } else {
            let startTag: TagSegmentElement;
            let endTag: TagSegmentElement;
            if (copiedTag.tag.tagType === TagType.Start) {
              startTag = copiedTag.tag;
              endTag = copiedTag.tagPair;
            } else {
              startTag = copiedTag.tagPair;
              endTag = copiedTag.tag;
            }
            insertSegmentActions.push(
              ...this.insertPartOfSegmentsAroundSelection(
                range,
                this.tagToPartOfSegment(startTag),
                this.tagToPartOfSegment(endTag),
                tagView
              )
            );
          }
        }
      });
    this.editor.endOperation();
    return insertSegmentActions;
  }

  rangeIsCursor(range: CodeMirror.Range): boolean {
    return range.head.line === range.anchor.line && range.head.ch === range.anchor.ch;
  }

  tagToPartOfSegment(tag: TagSegmentElement): PartOfSegment {
    return {
      text: tag.text,
      tags: [
        {
          ...tag,
          start: 0,
          end: tag.length,
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
