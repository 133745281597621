import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import {
  ChangeMultiLangProjectCols,
  PatchReportGridState,
  SetToolPanelVisibleStatus,
} from '@store/report-grids-store/report-grids.actions';
import { patch } from '@ngxs/store/operators';
import { ReportGridsStateModel, ReportGridState } from './report-grids-state.model';
import { REPORT_GRIDS_DEFAULT_STATE } from './report-grids-default-state';
import { ReportTab } from '@shared/models';
import { TARGET_LANGUAGE_GROUP_COLUMN } from '@report/modules/base/target-language-group-column';
import { ProjectFilesState } from '../project-files-store';
import { distinctUntilChanged } from 'rxjs';

@AsyncStorage
@StateRepository()
@State<ReportGridsStateModel>({
  name: 'reportGridV3',
  defaults: REPORT_GRIDS_DEFAULT_STATE,
})
@Injectable()
export class ReportGridsState {
  constructor(store: Store) {
    // NOTE: this operation does not need to update the state in real time because it is always accompanied
    // with a page reload and will therefore recreate an instance of ngxs    store
    store
      .select(ProjectFilesState.isMultilanguageProject)
      .pipe(distinctUntilChanged())
      .subscribe((isMultilanguageProject) => {
        store.dispatch(new ChangeMultiLangProjectCols(isMultilanguageProject, true));
      });
  }

  public static reportGridsState(): (_: ReportGridsStateModel) => ReportGridsStateModel {
    return createSelector([ReportGridsState], (state: ReportGridsStateModel) => ({ ...state }));
  }

  public static reportGridState(reportTab: ReportTab): (_: ReportGridsStateModel) => ReportGridState {
    return createSelector([ReportGridsState], (state: ReportGridsStateModel) => ({ ...state?.[reportTab] }));
  }

  @Action(PatchReportGridState)
  public patchReportGridState(ctx: StateContext<ReportGridsStateModel>, action: PatchReportGridState): void {
    ctx.setState(
      patch({
        [action.reportTab]: patch({
          ...action.data,
        }),
      })
    );
  }

  @Action(ChangeMultiLangProjectCols)
  public changeMultiLangProjectCols(
    ctx: StateContext<ReportGridsStateModel>,
    action: ChangeMultiLangProjectCols
  ): void {
    [ReportTab.Formal, ReportTab.Consistency, ReportTab.Terminology, ReportTab.Spelling, ReportTab.Custom].forEach(
      (reportTab) => {
        const reportTabCols = ctx.getState()[reportTab].columns.slice();
        const targetLangColIndex = reportTabCols.findIndex((item) => item.colId === TARGET_LANGUAGE_GROUP_COLUMN.colId);
        const targetLang = reportTabCols[targetLangColIndex];

        if (!action.force && (!targetLang || targetLang.hide !== undefined)) {
          return;
        }

        reportTabCols[targetLangColIndex] = {
          ...targetLang,
          hide: !action.isMultiLangProject,
        };
        ctx.dispatch(
          new PatchReportGridState(reportTab, {
            columns: reportTabCols,
          })
        );
      }
    );
  }

  @Action(SetToolPanelVisibleStatus)
  public setToolPanelVisibleStatus(ctx: StateContext<ReportGridsStateModel>, action: SetToolPanelVisibleStatus): void {
    this.patchReportGridState(
      ctx,
      new PatchReportGridState(action.reportTab, { visibleToolPanel: action.visibleToolPanel })
    );
  }
}
