/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeTermModel } from '../models/change-term-model';
import { CreateTermRequest } from '../models/create-term-request';
import { TermModelDataSourceResponse } from '../models/term-model-data-source-response';
import { UpdateTermRequest } from '../models/update-term-request';
import { UpdateTermSettingsRequest } from '../models/update-term-settings-request';

@Injectable({
  providedIn: 'root',
})
export class TermsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsGet
   */
  static readonly ApiTermbasesTermbaseIdTermsGetPath = '/api/Termbases/{termbaseId}/Terms';

  /**
   * Get all terms by Termbase ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsGet$Plain$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TermModelDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsGetPath, 'get');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('sourceLanguageId', params.sourceLanguageId, {});
      rb.query('targetLanguageId', params.targetLanguageId, {});
      rb.query('term', params.term, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermModelDataSourceResponse>;
      })
    );
  }

  /**
   * Get all terms by Termbase ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsGet$Plain(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<TermModelDataSourceResponse> {

    return this.apiTermbasesTermbaseIdTermsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TermModelDataSourceResponse>) => r.body as TermModelDataSourceResponse)
    );
  }

  /**
   * Get all terms by Termbase ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsGet$Json$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TermModelDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsGetPath, 'get');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('sourceLanguageId', params.sourceLanguageId, {});
      rb.query('targetLanguageId', params.targetLanguageId, {});
      rb.query('term', params.term, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermModelDataSourceResponse>;
      })
    );
  }

  /**
   * Get all terms by Termbase ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsGet$Json(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<TermModelDataSourceResponse> {

    return this.apiTermbasesTermbaseIdTermsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TermModelDataSourceResponse>) => r.body as TermModelDataSourceResponse)
    );
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsPost
   */
  static readonly ApiTermbasesTermbaseIdTermsPostPath = '/api/Termbases/{termbaseId}/Terms';

  /**
   * Create a new term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsPost$Plain$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * CreateTermRequest
     */
    body?: CreateTermRequest
  }): Observable<StrictHttpResponse<ChangeTermModel>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsPostPath, 'post');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeTermModel>;
      })
    );
  }

  /**
   * Create a new term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsPost$Plain(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * CreateTermRequest
     */
    body?: CreateTermRequest
  }): Observable<ChangeTermModel> {

    return this.apiTermbasesTermbaseIdTermsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeTermModel>) => r.body as ChangeTermModel)
    );
  }

  /**
   * Create a new term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsPost$Json$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * CreateTermRequest
     */
    body?: CreateTermRequest
  }): Observable<StrictHttpResponse<ChangeTermModel>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsPostPath, 'post');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeTermModel>;
      })
    );
  }

  /**
   * Create a new term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsPost$Json(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * CreateTermRequest
     */
    body?: CreateTermRequest
  }): Observable<ChangeTermModel> {

    return this.apiTermbasesTermbaseIdTermsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeTermModel>) => r.body as ChangeTermModel)
    );
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsDelete
   */
  static readonly ApiTermbasesTermbaseIdTermsDeletePath = '/api/Termbases/{termbaseId}/Terms';

  /**
   * Delete multiple terms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsDelete$Plain$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * Term IDs
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsDeletePath, 'delete');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete multiple terms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsDelete$Plain(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * Term IDs
     */
    body?: Array<string>
  }): Observable<boolean> {

    return this.apiTermbasesTermbaseIdTermsDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete multiple terms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsDelete$Json$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * Term IDs
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsDeletePath, 'delete');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete multiple terms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsDelete$Json(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * Term IDs
     */
    body?: Array<string>
  }): Observable<boolean> {

    return this.apiTermbasesTermbaseIdTermsDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsUpdateTermSettingsPost
   */
  static readonly ApiTermbasesTermbaseIdTermsUpdateTermSettingsPostPath = '/api/Termbases/{termbaseId}/Terms/UpdateTermSettings';

  /**
   * Updates filtered terms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsUpdateTermSettingsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsUpdateTermSettingsPost$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    'api-version'?: string;

    /**
     * UpdateTermSettingsRequest
     */
    body?: UpdateTermSettingsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsUpdateTermSettingsPostPath, 'post');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('sourceLanguageId', params.sourceLanguageId, {});
      rb.query('targetLanguageId', params.targetLanguageId, {});
      rb.query('term', params.term, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates filtered terms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsUpdateTermSettingsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsUpdateTermSettingsPost(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;
    sourceLanguageId?: number;
    targetLanguageId?: number;
    term?: string;
    'api-version'?: string;

    /**
     * UpdateTermSettingsRequest
     */
    body?: UpdateTermSettingsRequest
  }): Observable<void> {

    return this.apiTermbasesTermbaseIdTermsUpdateTermSettingsPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsTermIdPut
   */
  static readonly ApiTermbasesTermbaseIdTermsTermIdPutPath = '/api/Termbases/{termbaseId}/Terms/{termId}';

  /**
   * Update a specific term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsTermIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsTermIdPut$Plain$Response(params: {

    /**
     * Term identifier
     */
    termId: string;

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * UpdateTermRequest
     */
    body: UpdateTermRequest
  }): Observable<StrictHttpResponse<ChangeTermModel>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsTermIdPutPath, 'put');
    if (params) {
      rb.path('termId', params.termId, {});
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeTermModel>;
      })
    );
  }

  /**
   * Update a specific term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsTermIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsTermIdPut$Plain(params: {

    /**
     * Term identifier
     */
    termId: string;

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * UpdateTermRequest
     */
    body: UpdateTermRequest
  }): Observable<ChangeTermModel> {

    return this.apiTermbasesTermbaseIdTermsTermIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeTermModel>) => r.body as ChangeTermModel)
    );
  }

  /**
   * Update a specific term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsTermIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsTermIdPut$Json$Response(params: {

    /**
     * Term identifier
     */
    termId: string;

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * UpdateTermRequest
     */
    body: UpdateTermRequest
  }): Observable<StrictHttpResponse<ChangeTermModel>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsTermIdPutPath, 'put');
    if (params) {
      rb.path('termId', params.termId, {});
      rb.path('termbaseId', params.termbaseId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeTermModel>;
      })
    );
  }

  /**
   * Update a specific term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsTermIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTermbasesTermbaseIdTermsTermIdPut$Json(params: {

    /**
     * Term identifier
     */
    termId: string;

    /**
     * Termbase identifier
     */
    termbaseId: string;
    'api-version'?: string;

    /**
     * UpdateTermRequest
     */
    body: UpdateTermRequest
  }): Observable<ChangeTermModel> {

    return this.apiTermbasesTermbaseIdTermsTermIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeTermModel>) => r.body as ChangeTermModel)
    );
  }

  /**
   * Path part for operation apiTermbasesTermbaseIdTermsIdDelete
   */
  static readonly ApiTermbasesTermbaseIdTermsIdDeletePath = '/api/Termbases/{termbaseId}/Terms/{id}';

  /**
   * Delete a specific term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsIdDelete$Plain$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;

    /**
     * Term identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsIdDeletePath, 'delete');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsIdDelete$Plain(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;

    /**
     * Term identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiTermbasesTermbaseIdTermsIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a specific term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTermbasesTermbaseIdTermsIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsIdDelete$Json$Response(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;

    /**
     * Term identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TermsService.ApiTermbasesTermbaseIdTermsIdDeletePath, 'delete');
    if (params) {
      rb.path('termbaseId', params.termbaseId, {});
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTermbasesTermbaseIdTermsIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTermbasesTermbaseIdTermsIdDelete$Json(params: {

    /**
     * Termbase identifier
     */
    termbaseId: string;

    /**
     * Term identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiTermbasesTermbaseIdTermsIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
