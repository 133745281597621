import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp, ICellRendererParams } from 'src/types/ag-grid';
import { Observable, Subject, takeUntil } from 'rxjs';

export interface SearchCellRendererParams extends ICellRendererParams {
  searched: () => string | Observable<string>;
  defaultText?: string;
  placeholder?: string;
}

@Component({
  selector: 'app-searched-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './searched-cell-renderer.component.html',
})
export class SearchedCellRendererComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams & SearchCellRendererParams;
  public value: string;
  public searchedValue: string;
  public defaultText: string;
  public placeholder: string;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  public agInit(params: ICellRendererParams & SearchCellRendererParams): void {
    this.params = params;
    this.defaultText = params.defaultText ?? '';
    this.value = params.value?.toString();
    this.placeholder = params.placeholder ?? '';
    const search = this.params.searched();
    if ((search && typeof search === 'string') || !search) {
      this.searchedValue = search as string;
      return;
    }
    this.watchSearch(search as Observable<string>);
  }

  public refresh(): boolean {
    return false;
  }

  private watchSearch(search$: Observable<string>): void {
    search$.pipe(takeUntil(this.destroyed$)).subscribe((searchText) => {
      this.searchedValue = searchText || '';
      this.cdr.detectChanges();
    });
  }
}
