import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProjectInfo } from '@generated/api';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ProjectsState, ProjectState } from '@store/index';
import { AddProject, LoadProjects, ResetProjectsPagination } from '@store/projects-store/projects.actions';
import { UploadingFilesState } from '@store/uploading-files';
import { Clipboard } from '@angular/cdk/clipboard';
import { debounceTime, filter, finalize, Observable, Subject, takeUntil } from 'rxjs';
import { trackById } from '@shared/tools';
import { TouchedProject } from '@store/project-store';
import { NotificationService } from '@shared/components/notification';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent, DialogConfirmationConfig } from '@shared/components/dialog/confirmation';
import { PinnedProjectsState } from '@store/projects-pins/projects-pin.state';
import { PinProject } from '@store/projects-pins/projects-pin.actions';
import { LoadDataQuery } from '@shared/components/search-list/search-list.component';

@Component({
  selector: 'app-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectManagerComponent implements OnInit, OnDestroy {
  @Select(UploadingFilesState.hasInProgressFiles) uploadingInPorgress$: Observable<boolean>;
  @Select(PinnedProjectsState.projectsPins) pinnedProjects$: Observable<ProjectInfo[]>;
  @Select(ProjectsState.loading) loading$: Observable<boolean>;

  @Output()
  public closed = new EventEmitter<void>();

  public readonly projectSearchControl = new FormControl();
  public readonly uploadingWarning =
    'You can leave the current project when the files upload is completed. Please wait for the upload completion or cancel it.';

  public isShowAll = false;
  public trackById = trackById;
  public project: ProjectInfo | null = null;
  public deleteCandidate: ProjectInfo | null = null;
  public projects: ProjectInfo[] = [];
  public totalProjects: number;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions,
    private readonly cdr: ChangeDetectorRef,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.watchProjects();
    this.watchPinnedProjects();
    this.watchTotalProjects();
  }

  public initSubscriptions(): void {
    this.store
      .select(ProjectState.project)
      .pipe(
        filter((project) => !!project),
        takeUntil(this.destroyed$)
      )
      .subscribe((project) => {
        this.project = project;
      });

    this.projectSearchControl.setValue('');
  }

  private watchProjects(): void {
    this.store
      .select(ProjectsState.projects)
      .pipe(debounceTime(50), takeUntil(this.destroyed$))
      .subscribe((projects) => {
        const projectsPinnedIds = new Set<string>(this.store.selectSnapshot(PinnedProjectsState.projectsPinsIds));
        this.projects = projects.map((p) => ({
          ...p,
          isPinned: projectsPinnedIds.has(p.id),
        }));
        this.cdr.markForCheck();
      });
  }

  // TODO: Временное решение для установки статуса закрепленного проекта.
  // Убрать когда на стороне бекенда реализуют хранение информации о закрепленных проектов.
  private watchPinnedProjects(): void {
    this.actions.pipe(ofActionSuccessful(PinProject)).subscribe((v: PinProject) => {
      this.projects = this.projects.map((p) => {
        if (p.id === v.project.id) {
          return {
            ...p,
            isPinned: v.pinStatus,
          };
        }
        return p;
      });
      this.cdr.markForCheck();
    });
  }

  private watchTotalProjects(): void {
    this.store
      .select(ProjectsState.total)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((total) => {
        this.totalProjects = total;
      });
  }

  public createProject(): void {
    if (this.store.selectSnapshot(UploadingFilesState.hasInProgressFiles)) {
      return;
    }
    this.store.dispatch(new AddProject()).pipe(takeUntil(this.destroyed$));
  }

  public loadMoreProjects(query: LoadDataQuery): void {
    this.store.dispatch(new LoadProjects(query.pagination, query.searchQuery));
  }

  /** Send signal to exit from the project manager */
  public close(): void {
    this.closed.emit();
  }

  public toggleProjectPin(project: ProjectInfo & { isPinned: boolean }): void {
    this.store.dispatch(new PinProject(project, !project.isPinned));
  }

  public copyProjectLink(project: ProjectInfo): void {
    this.notificationService.toast({
      text: 'Link to project copied to clipboard',
      icon: 'copy',
    });
    this.clipboard.copy(this.buildProjectLink(project));
  }

  public buildProjectLink(project: ProjectInfo): string {
    return `${window.location.origin}/projects/${project.id}`;
  }

  public deleteProject(project: ProjectInfo): void {
    this.deleteCandidate = project;
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: `Delete project ‘${project.name}’?`,
        okText: 'Delete',
        cancelText: 'Cancel',
        theme: 'warn',
      } as DialogConfirmationConfig,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    dialogRef
      .afterClosed()
      .pipe(
        finalize(() => {
          this.deleteCandidate = null;
          this.cdr.markForCheck();
        })
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.toast({
            text: 'Delete project is unavailable now',
            icon: 'copy',
          });
        }
      });
  }

  public selectProject(project: ProjectInfo): void {
    if (this.isActiveProject(project) || this.store.selectSnapshot(UploadingFilesState.hasInProgressFiles)) {
      return;
    }

    this.store.dispatch(new TouchedProject(project.id));

    this.close();
  }

  public toggleShowAll(): void {
    this.isShowAll = !this.isShowAll;
  }

  private isActiveProject(project: ProjectInfo): boolean {
    return project.id === this.project.id;
  }

  public menuClosed(): void {
    this.projectSearchControl.setValue('');
    this.isShowAll = false;
  }

  ngOnDestroy(): void {
    this.resetProjects();
  }

  private resetProjects(): void {
    this.store.dispatch(new ResetProjectsPagination());
    this.store.dispatch(new LoadProjects());
  }
}
