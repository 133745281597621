export enum KeyCodes {
  KEY_UP = 'ArrowUp',
  KEY_DOWN = 'ArrowDown',
  KEY_LEFT = 'ArrowLeft',
  KEY_RIGHT = 'ArrowRight',
  KEY_ALT = 'AltLeft',
  KEY_ALT_RIGHT = 'AltRight',
  KEY_CONTROL = 'LeftControl',
  KEY_SPACE = 'Space',
  KEY_ENTER = 'Enter',
  KEY_NUMPAD_ENTER = 'NumpadEnter',
  KEY_SHIFT = 'LeftShift',
  KEY_META = 'MetaLeft',
  KEY_A = 'KeyA',
  KEY_T = 'KeyT',
  KEY_C = 'KeyC',
  KEY_V = 'KeyV',
  KEY_E = 'KeyE',
  KEY_L = 'KeyL',
  KEY_N = 'KeyN',
  KEY_S = 'KeyS',
  KEY_ESCAPE = 'Escape',
}
