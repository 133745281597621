import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { Action, State } from '@ngxs/store';
import { CommonHubService } from '@shared/services';
import { Observable } from 'rxjs';
import { SystemDAO } from 'src/app/core/daos';
import { ResetPersistentData } from './system.actions';

@StateRepository()
@State({
  name: 'systemState',
})
@Injectable()
export class SystemState {
  constructor(private readonly systemDAO: SystemDAO, private readonly commonHubService: CommonHubService) {}

  @Action(ResetPersistentData)
  public resetPersistentData(): Observable<any> {
    this.commonHubService.closeConnection();
    sessionStorage.clear();
    return this.systemDAO.clearStore();
  }
}
