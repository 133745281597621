import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpButtonComponent } from './help-button.component';
import { ActionIconModule } from '../action-icon';

@NgModule({
  declarations: [HelpButtonComponent],
  imports: [CommonModule, ActionIconModule],
  exports: [HelpButtonComponent],
})
export class HelpButtonModule {}
