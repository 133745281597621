export interface TooltipProperties {
  id?: string;
  event?: MouseEvent;
  targetElement?: HTMLElement;
  element?: HTMLElement;
  tooltipText?: string;
  component?: any;
  width?: string;
  height?: string;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  left?: number;
  top?: number;
  right?: string;
  bottom?: string;
  zIndex?: number;
  placement?: TooltipPlacement;
  autoPlacement?: boolean;
  alignToCenter?: boolean;
  overlayBackdrop?: boolean;
  offset?: number;
  theme?: 'dark' | 'light';
  animationDisable?: boolean;
  tooltipClass?: string | string[];
  padding?: string;
  noArrow?: boolean;
  metadata?: any;
  hideDelay?: number;
  showDelay?: number;
  trigger?: TooltipTrigger;
  position?: { top: number; left: number };
  whiteSpace?: 'nowrap' | 'normal';
  borderRadius?: string;
  shadow?: boolean;
  pointerEvents?: 'auto' | 'none';
  fontSize?: string;
  display?: boolean;
  hideOnClick?: boolean;
}

export type TooltipTrigger = 'hover' | 'click';

export type TooltipPlacement =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'left-top'
  | 'left-bottom'
  | 'right'
  | 'right-top'
  | 'right-bottom';

export enum TooltipState {
  Opening,
  Opened,
  Closing,
  Closed,
}
