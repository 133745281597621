<div class="label-container" [ngClass]="{ disabled: disabled, 'full-width': fullWidth }">
  <label
    *ngIf="!hideLabel"
    [for]="elementId"
    [ngClass]="{
      left: labelPosition === 'left',
      right: labelPosition === 'right'
    }"
  >
    <ng-content></ng-content>
  </label>
  <div class="input-container">
    <input
      #input
      type="number"
      [id]="elementId"
      [class]="widthClass"
      autocomplete="off"
      [(ngModel)]="value"
      [disabled]="disabled"
      [required]="required"
      [readonly]="readonly"
      [min]="minInternal"
      [max]="maxInternal"
    />
    <div class="arrow-container" [ngClass]="{ disabled: disabled }">
      <div class="arrow arrow-up" (click)="onUpArrowClick()" [class.disabled]="!activeUp || disabled || readonly"></div>
      <div class="arrow arrow-down" (click)="onDownArrowClick()" [class.disabled]="!activeDown || disabled || readonly"></div>
    </div>
  </div>
</div>
