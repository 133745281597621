/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BalanceModel } from '../models/balance-model';
import { ChangeQuantityRequest } from '../models/change-quantity-request';
import { OutgoingOrderModel } from '../models/outgoing-order-model';
import { ProductModel } from '../models/product-model';
import { SubscriptionDetails } from '../models/subscription-details';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSubscriptionProductsGet
   */
  static readonly ApiSubscriptionProductsGetPath = '/api/subscription/products';

  /**
   * Gets a list of products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionProductsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionProductsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProductModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionProductsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }

  /**
   * Gets a list of products.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionProductsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionProductsGet$Plain(params?: {
  }): Observable<Array<ProductModel>> {

    return this.apiSubscriptionProductsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductModel>>) => r.body as Array<ProductModel>)
    );
  }

  /**
   * Gets a list of products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionProductsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionProductsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProductModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionProductsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }

  /**
   * Gets a list of products.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionProductsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionProductsGet$Json(params?: {
  }): Observable<Array<ProductModel>> {

    return this.apiSubscriptionProductsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductModel>>) => r.body as Array<ProductModel>)
    );
  }

  /**
   * Path part for operation apiSubscriptionDetailsGet
   */
  static readonly ApiSubscriptionDetailsGetPath = '/api/subscription/details';

  /**
   * Gets subscription details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionDetailsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionDetailsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<SubscriptionDetails>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionDetailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDetails>;
      })
    );
  }

  /**
   * Gets subscription details.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionDetailsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionDetailsGet$Plain(params?: {
  }): Observable<SubscriptionDetails> {

    return this.apiSubscriptionDetailsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDetails>) => r.body as SubscriptionDetails)
    );
  }

  /**
   * Gets subscription details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionDetailsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionDetailsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<SubscriptionDetails>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionDetailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDetails>;
      })
    );
  }

  /**
   * Gets subscription details.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionDetailsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionDetailsGet$Json(params?: {
  }): Observable<SubscriptionDetails> {

    return this.apiSubscriptionDetailsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDetails>) => r.body as SubscriptionDetails)
    );
  }

  /**
   * Path part for operation apiSubscriptionBalanceGet
   */
  static readonly ApiSubscriptionBalanceGetPath = '/api/subscription/balance';

  /**
   * Gets a balance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionBalanceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionBalanceGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BalanceModel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionBalanceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BalanceModel>;
      })
    );
  }

  /**
   * Gets a balance.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionBalanceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionBalanceGet$Plain(params?: {
  }): Observable<BalanceModel> {

    return this.apiSubscriptionBalanceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BalanceModel>) => r.body as BalanceModel)
    );
  }

  /**
   * Gets a balance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionBalanceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionBalanceGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BalanceModel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionBalanceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BalanceModel>;
      })
    );
  }

  /**
   * Gets a balance.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionBalanceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionBalanceGet$Json(params?: {
  }): Observable<BalanceModel> {

    return this.apiSubscriptionBalanceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BalanceModel>) => r.body as BalanceModel)
    );
  }

  /**
   * Path part for operation apiSubscriptionUsagelogGet
   */
  static readonly ApiSubscriptionUsagelogGetPath = '/api/subscription/usagelog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionUsagelogGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionUsagelogGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<OutgoingOrderModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionUsagelogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutgoingOrderModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionUsagelogGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionUsagelogGet$Plain(params?: {
  }): Observable<Array<OutgoingOrderModel>> {

    return this.apiSubscriptionUsagelogGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutgoingOrderModel>>) => r.body as Array<OutgoingOrderModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionUsagelogGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionUsagelogGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<OutgoingOrderModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionUsagelogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutgoingOrderModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionUsagelogGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionUsagelogGet$Json(params?: {
  }): Observable<Array<OutgoingOrderModel>> {

    return this.apiSubscriptionUsagelogGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutgoingOrderModel>>) => r.body as Array<OutgoingOrderModel>)
    );
  }

  /**
   * Path part for operation apiSubscriptionChangeQuantityPost
   */
  static readonly ApiSubscriptionChangeQuantityPostPath = '/api/subscription/changeQuantity';

  /**
   * Updates the subscription quantity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionChangeQuantityPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionChangeQuantityPost$Response(params?: {
    body?: ChangeQuantityRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.ApiSubscriptionChangeQuantityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates the subscription quantity.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionChangeQuantityPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionChangeQuantityPost(params?: {
    body?: ChangeQuantityRequest
  }): Observable<void> {

    return this.apiSubscriptionChangeQuantityPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
