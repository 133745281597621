import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LPAuthComponent } from '@shared/components/localization-platforms/lp-auth/lp-auth.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { lpAssets } from '@shared/components/localization-platforms/constants/lp-assets';
import { LPAuthComponentParams } from '@shared/components/localization-platforms/models';
import { AuthenticationModel, LocalizationPlatformsService } from '@generated/api';
import { HttpErrorResponse } from '@angular/common/http';
import { isAuthenticationError } from '@shared/tools';

@Injectable({
  providedIn: 'root',
})
export class LPAuthModalService {
  private modalRef: MatDialogRef<LPAuthComponent>;
  private componentErrorMessage = new BehaviorSubject('');
  private componentLoading = new BehaviorSubject(false);

  constructor(
    // TODO  Сделать доступ аналогично остальным решениям через стейт.
    private localizationPlatformsService: LocalizationPlatformsService,
    private dialog: MatDialog
  ) {}

  openAuthModal(platformId: string): Observable<string | null> {
    return new Observable((observer) => {
      const params: LPAuthComponentParams = {
        ...lpAssets[platformId],
        errorMessage: this.componentErrorMessage,
        submitCallback: (authCredentials: AuthenticationModel) => this.loginRequest(platformId, authCredentials),
        loading: this.componentLoading,
        platformId,
      };
      this.modalRef = this.dialog.open(LPAuthComponent, { data: params });
      this.modalRef.afterClosed().subscribe((value: string) => {
        observer.next(value);
        observer.complete();
      });
    });
  }

  private loginRequest(platformId: string, authCredentials: AuthenticationModel) {
    this.componentLoading.next(true);
    this.localizationPlatformsService
      .apiLocalizationPlatformsPlatformIdConnectPost$Json({
        platformId,
        body: authCredentials,
      })
      .subscribe({
        next: (response) => {
          this.componentLoading.next(false);
          this.modalRef.close(response.connectionId);
        },
        error: (error: HttpErrorResponse) => {
          this.componentLoading.next(false);
          this.componentErrorMessage.next(this.getErrorMessage(platformId, error));
        },
      });
  }

  private getErrorMessage(platformId: string, error: HttpErrorResponse): string {
    if (isAuthenticationError(error)) {
      return lpAssets[platformId].errorMessage;
    } else {
      return 'Unknown error';
    }
  }
}
