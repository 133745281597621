import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LPAuthComponentParams, LPAuthFormField } from '@shared/components/localization-platforms/models';
import { AuthenticationModel, CloudRegion } from '@generated/api';
import { cloudRegionTitles } from '@shared/components/localization-platforms/constants/lp-assets';

@Component({
  selector: 'app-lp-auth',
  templateUrl: './lp-auth.component.html',
  styleUrls: ['./lp-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LPAuthComponent implements OnInit {
  fields: LPAuthFormField[] = [];
  platformId: string;
  logoPath = '/assets/images/memsource-logo.png';
  submitBtnLabel = 'connect to memsource';
  information = '';
  errorMessage = new BehaviorSubject<string>('');
  loading = new BehaviorSubject<boolean>(false);
  model: AuthenticationModel = {};
  submitCallback = (_: AuthenticationModel): void => {};
  cloudRegionsTitles = cloudRegionTitles;
  cloudRegions: CloudRegion[] = [];

  get modelIsValid(): boolean {
    if (!this.model) {
      return false;
    }
    return !this.fields.some((field) => !this.model[field.fieldName]);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: LPAuthComponentParams
  ) {
    this.fields = data.fields;
    this.logoPath = data.logoPath;
    this.submitBtnLabel = data.submitBtnLabel;
    this.information = data.information;
    this.errorMessage = data.errorMessage;
    this.loading = data.loading;
    this.submitCallback = data.submitCallback;
    this.platformId = data.platformId;
    this.cloudRegions = data.cloudRegions;

    if (this.cloudRegions.length) {
      this.model.cloudRegion = this.cloudRegions[0];
    }
  }

  ngOnInit(): void {}

  public valueChanged(fieldName: string, value: string): void {
    this.model[fieldName] = value;
    this.errorMessage.next('');
  }

  public submitBtnClick(): void {
    this.submitCallback(this.model);
  }
}
