import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BooleanFilterBase } from '../boolean-filter-base';

@Component({
  selector: 'app-filter-comment',
  templateUrl: './filter-comment.component.html',
  styleUrls: ['./filter-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCommentComponent extends BooleanFilterBase {
  static rendererName = 'filterComponentRenderer';
}
