import { Component } from '@angular/core';
import { AuthService } from '../modules/auth';

@Component({
  selector: 'app-login',
  template: '',
})
/**
 * @class
 * SignInCallbackComponent provides a way to verify the
 * user after a redirect from a logged-in identity server without
 * provided information about code challenge.
 */
export class SignInCallbackComponent {
  constructor(auth: AuthService) {
    auth.login(null);
  }
}
