import { LanguageModel } from '@generated/api';

enum ActionTypes {
  SET_LANGUAGES = '[AVAILABLE_LANGUAGES] Set languages',
  LOAD_LANGUAGES = '[AVAILABLE_LANGUAGES] Load languages',
  LOAD_LANGUAGES_IF_NOT_EXIST = '[AVAILABLE_LANGUAGES] Load languages if not exist',
}

export class SetAvailableLanguages {
  static readonly type = ActionTypes.SET_LANGUAGES;

  constructor(public languages: LanguageModel[]) {}
}

export class LoadAvailableLanguages {
  static readonly type = ActionTypes.LOAD_LANGUAGES;
}

export class LoadAvailableLanguagesIfNotExist {
  static readonly type = ActionTypes.LOAD_LANGUAGES_IF_NOT_EXIST;
}
