import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from './search-input.component';
import { IconModule } from '@shared/components/icon/icon.module';
import { TooltipModule } from '@shared/components/tooltip';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  imports: [CommonModule, FormsModule, IconModule, TooltipModule, ActionIconModule],
  declarations: [SearchInputComponent],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
