/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateWordformsBaseRequest } from '../models/create-wordforms-base-request';
import { UpdateWordformsBaseRequest } from '../models/update-wordforms-base-request';
import { WordformsBaseInfo } from '../models/wordforms-base-info';
import { WordformsBaseInfoDataSourceResponse } from '../models/wordforms-base-info-data-source-response';

@Injectable({
  providedIn: 'root',
})
export class WordformsBasesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiWordformsBasesGet
   */
  static readonly ApiWordformsBasesGetPath = '/api/WordformsBases';

  /**
   * Gets all wordforms bases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesGet$Plain$Response(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Gets all wordforms bases.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesGet$Plain(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<WordformsBaseInfoDataSourceResponse> {

    return this.apiWordformsBasesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfoDataSourceResponse>) => r.body as WordformsBaseInfoDataSourceResponse)
    );
  }

  /**
   * Gets all wordforms bases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesGet$Json$Response(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfoDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfoDataSourceResponse>;
      })
    );
  }

  /**
   * Gets all wordforms bases.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesGet$Json(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<WordformsBaseInfoDataSourceResponse> {

    return this.apiWordformsBasesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfoDataSourceResponse>) => r.body as WordformsBaseInfoDataSourceResponse)
    );
  }

  /**
   * Path part for operation apiWordformsBasesPost
   */
  static readonly ApiWordformsBasesPostPath = '/api/WordformsBases';

  /**
   * Create a new wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * CreateWordformsBaseRequest
     */
    body?: CreateWordformsBaseRequest
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Create a new wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesPost$Plain(params?: {
    'api-version'?: string;

    /**
     * CreateWordformsBaseRequest
     */
    body?: CreateWordformsBaseRequest
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Create a new wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * CreateWordformsBaseRequest
     */
    body?: CreateWordformsBaseRequest
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Create a new wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesPost$Json(params?: {
    'api-version'?: string;

    /**
     * CreateWordformsBaseRequest
     */
    body?: CreateWordformsBaseRequest
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Path part for operation apiWordformsBasesDefaultGet
   */
  static readonly ApiWordformsBasesDefaultGetPath = '/api/WordformsBases/Default';

  /**
   * Gets the default wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesDefaultGet$Plain$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Gets the default wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesDefaultGet$Plain(params?: {
    'api-version'?: string;
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Gets the default wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesDefaultGet$Json$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Gets the default wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesDefaultGet$Json(params?: {
    'api-version'?: string;
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Path part for operation apiWordformsBasesIdGet
   */
  static readonly ApiWordformsBasesIdGetPath = '/api/WordformsBases/{id}';

  /**
   * Gets a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdGet$Plain$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Gets a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdGet$Plain(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Gets a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdGet$Json$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Gets a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdGet$Json(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Path part for operation apiWordformsBasesIdPut
   */
  static readonly ApiWordformsBasesIdPutPath = '/api/WordformsBases/{id}';

  /**
   * Update a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesIdPut$Plain$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateWordformsBaseRequest
     */
    body?: UpdateWordformsBaseRequest
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Update a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesIdPut$Plain(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateWordformsBaseRequest
     */
    body?: UpdateWordformsBaseRequest
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Update a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesIdPut$Json$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateWordformsBaseRequest
     */
    body?: UpdateWordformsBaseRequest
  }): Observable<StrictHttpResponse<WordformsBaseInfo>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordformsBaseInfo>;
      })
    );
  }

  /**
   * Update a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsBasesIdPut$Json(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateWordformsBaseRequest
     */
    body?: UpdateWordformsBaseRequest
  }): Observable<WordformsBaseInfo> {

    return this.apiWordformsBasesIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordformsBaseInfo>) => r.body as WordformsBaseInfo)
    );
  }

  /**
   * Path part for operation apiWordformsBasesIdDelete
   */
  static readonly ApiWordformsBasesIdDeletePath = '/api/WordformsBases/{id}';

  /**
   * Delete a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdDelete$Plain$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdDelete$Plain(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiWordformsBasesIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a specific wordforms base.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsBasesIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdDelete$Json$Response(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsBasesService.ApiWordformsBasesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific wordforms base.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsBasesIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsBasesIdDelete$Json(params: {

    /**
     * WordformsBase ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiWordformsBasesIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
