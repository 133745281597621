import { Component, ChangeDetectionStrategy, Input, Inject, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IconPayload } from '@generated/icons/icons.model';
import { NotificationAction, NotificationMessage } from './notificaiton.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() icon: IconPayload;
  @Input() wrap: boolean;
  @Input() cancelable: boolean;
  @Input() action: NotificationAction;

  constructor(
    @Optional() @Inject(MAT_SNACK_BAR_DATA) private data?: NotificationMessage,
    @Optional() private snackBarRef?: MatSnackBarRef<NotificationComponent>
  ) {
    if (this.data) {
      this.title = this.data.title;
      this.text = this.data.text;
      this.icon = this.data.icon;
      this.cancelable = this.data.cancelable;
      this.action = this.data.actionBtn;
      this.wrap = this.data.wrap;
    }
  }

  public actionHandler(): void {
    this.snackBarRef.dismissWithAction();
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }
}
