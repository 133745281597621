import { TranslationUnitProperties } from '@generated/api';

export const getMatchForSorting = (value: string): number => {
  if (value === 'PM') {
    return 102;
  }
  if (value === 'CM') {
    return 101;
  }
  return value ? parseInt(value, 10) : 0;
};

export const getMatchForRow = (properties: TranslationUnitProperties): string => {
  if (!properties) {
    return '';
  }
  if (properties.perfectMatch) {
    return 'PM';
  }
  if (properties.contextMatch) {
    return 'CM';
  }

  const match = properties?.match;
  return match != null ? match.toString() : '';
};
