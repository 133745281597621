import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MainErrorHandler } from './handlers/main-error.handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, OfflineInterceptor, TokenAwaitInterceptor } from './interceptors';
import { StoreModule } from '@store/store.module';
import { LoggerModule } from './logger/logger.module';
import { ApplicationUpdateService, FaviconService, ShortcutsService } from './services';
import { HttpApiModule } from '@generated/api/http-api.module';
import { GoogleAnalyticsModule } from './google-analytics';
import { NotificationModule } from '@shared/components/notification';
import { AuthModule } from './modules/auth';
import { SharedWorkerService } from './services/shared-worker.service';
import { TabIdInterceptor } from './interceptors/tab-id.interceptor';
import { OrganizationAcceptInviteComponent } from './components/organization-accept-invite/organization-accept-invite.component';
import { HttpPaymentApiModule } from '@generated/payment-api';

@NgModule({
  imports: [
    StoreModule,
    AuthModule,
    LoggerModule,
    GoogleAnalyticsModule,
    HttpApiModule,
    HttpPaymentApiModule.forRoot({ rootUrl: '/payment' }),
    NotificationModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: MainErrorHandler }, ApplicationUpdateService],
  declarations: [OrganizationAcceptInviteComponent],
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApplicationUpdateService,
        FaviconService,
        ShortcutsService,
        SharedWorkerService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenAwaitInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TabIdInterceptor, multi: true },
        { provide: ErrorHandler, useClass: MainErrorHandler },
      ],
    };
  }
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
