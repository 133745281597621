import { LockProtectionCellRendererComponent } from '@report/components/report-grid/components/lock-protection-cell-renderer/lock-protection-cell-renderer.component';
import { GetRowIdParams, GridOptions } from 'src/types/ag-grid';
import { GridHeaderComponent, GridSegmentCellRendererComponent, SegmentCellEditorComponent } from '../grid-components';
import { CommentCellRendererComponent } from '../grid-components/comment-cell-renderer/comment-cell-renderer.component';
import { ListFilterComponent } from '@shared/components/grid-filters';

export const buildContextViewerGridOptions = (suppressManualEditing?: boolean): GridOptions => ({
  context: {},
  sideBar: false,
  rowSelection: 'single',
  rowBuffer: 20,
  enableCellTextSelection: true,
  suppressCopyRowsToClipboard: true,
  singleClickEdit: true,
  suppressAnimationFrame: true,
  suppressNoRowsOverlay: true,
  suppressCellFocus: false,
  getRowId: (params: GetRowIdParams) => params.data.id,
  processCellForClipboard: (params) => params.value,
  components: {
    segmentCellRenderer: GridSegmentCellRendererComponent,
    segmentEditorComponent: SegmentCellEditorComponent,
    [CommentCellRendererComponent.rendererName]: CommentCellRendererComponent,
    [LockProtectionCellRendererComponent.rendererName]: LockProtectionCellRendererComponent,
    [ListFilterComponent.rendererName]: ListFilterComponent,
    [GridHeaderComponent.rendererName]: GridHeaderComponent,
  },
  editType: 'fullRow',
  suppressClickEdit: true,
  stopEditingWhenCellsLoseFocus: false,
  navigateToNextCell: () => null,
  getRowClass: (params): string => {
    if (suppressManualEditing && params.data.isProtected) {
      return 'row-protected';
    }
  },
  onCellClicked: (event): void => {
    if (event.colDef.colId === 'targetSegment') {
      const editingCells = event.api.getEditingCells();
      if (editingCells.length && editingCells[0].rowIndex === event.rowIndex) {
        return;
      }
      event.api.startEditingCell({
        rowIndex: event.rowIndex,
        colKey: 'targetSegment',
      });
    }
  },
});
