import { NamedRoute, ReportTab } from '@shared/models';

export const reportNavRoutes: NamedRoute[] = [
  {
    path: 'formal',
    loadChildren: () => import('./modules/formal/formal.module').then((m) => m.ReportFormalModule),
    data: {
      meta: {
        title: 'Common',
        dynamic: true,
        icon: 'task',
        reportGroup: ReportTab.Formal,
      },
    },
  },
  {
    path: 'consistency',
    loadChildren: () => import('./modules/consistency/consistency.module').then((m) => m.ConsistencyModule),
    data: {
      meta: {
        title: 'Consistency',
        dynamic: true,
        icon: 'compare-arrows',
        reportGroup: ReportTab.Consistency,
      },
    },
  },
  {
    path: 'terminology',
    loadChildren: () => import('./modules/terminology/terminology.module').then((m) => m.TerminologyModule),
    data: {
      meta: {
        title: 'Terminology',
        icon: 'book',
        dynamic: true,
        reportGroup: ReportTab.Terminology,
      },
    },
  },
  {
    path: 'spelling',
    loadChildren: () => import('./modules/spelling/spelling.module').then((m) => m.SpellingModule),
    data: {
      meta: {
        title: 'Spelling',
        icon: 'spellcheck',
        dynamic: true,
        reportGroup: ReportTab.Spelling,
      },
    },
  },
  {
    path: 'custom',
    loadChildren: () => import('./modules/user-defined/user-defined.module').then((m) => m.UserDefinedModule),
    data: {
      meta: {
        title: 'User-defined',
        hidden: false,
        icon: 'how-to-req',
        dynamic: true,
        reportGroup: ReportTab.Custom,
      },
    },
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
    data: {
      meta: {
        title: 'Search',
        icon: 'search',
        reportGroup: ReportTab.Search,
      },
    },
  },
  {
    path: 'history',
    loadChildren: () => import('./modules/history/history.module').then((m) => m.HistoryModule),
    data: {
      meta: {
        title: 'History of changes',
        icon: 'clock',
        reportGroup: ReportTab.History,
      },
    },
  },
];
