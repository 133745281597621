import { ReportTab } from '@shared/models';
import { ReportType } from '../configs';

/**
 * Converts export report type to report tab
 */
export const ReportTypeToReportTabMap = {
  [ReportType.Formal]: ReportTab.Formal,
  [ReportType.Consistency]: ReportTab.Consistency,
  [ReportType.Terminology]: ReportTab.Terminology,
  [ReportType.Spelling]: ReportTab.Spelling,
  [ReportType.Custom]: ReportTab.Custom,
  [ReportType.History]: ReportTab.History,
};
