/* tslint:disable */
/* eslint-disable */
export enum IssueType {
  Formal = 0,
  Terminology = 1,
  Custom = 2,
  Spelling = 3,
  Grammar = 4,
  Consistency = 5
}
