import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';
import { iconSizes } from './icon-size.constant';
import { IconColor, iconColors, IconSize } from './icon.model';

/**
 * Icon component.
 * Add svg icon to any place.
 * By default it uses size and color of host element or you can set predefined values by settings inputs size and color
 *
 * Usage:
 * ````
 * <app-ui-icon icon="add"></app-ui-icon>
 * <app-ui-icon icon="add" size="xs" color="primary"></app-ui-icon>
 * ````
 */
@Component({
  selector: 'app-ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  set color(value: IconColor) {
    if (iconColors.includes(value)) {
      this.innerColor = '';
      this.className = value;
      return;
    }
    this.innerColor = value;
    this.className = '';
  }

  @Input()
  public icon: IconPayload;

  #iconSize: IconSize = 'small';

  public className: IconSize = 'inherit';
  public innerColor: IconColor;

  get containerSize(): string {
    return iconSizes[this.#iconSize] || this.#iconSize;
  }

  @Input()
  set size(size: IconSize) {
    this.#iconSize = size;
  }

  get size(): IconSize {
    return this.#iconSize || 'small';
  }
}
