import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';
import { BaseButtonComponent } from '@shared/components/button';

export type ActionIconSize = 'sm' | 'md' | 'lg' | 'xl'; // 20p, 24px, 28px, 32px,
export type ActionIconTheme = 'default' | 'circle' | 'subtle';

@Component({
  selector: 'app-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionIconComponent extends BaseButtonComponent {
  @Input() icon: IconPayload;
  /** Instead of an icon, a symbol can be rendered */
  @Input() character: string;
  @Input() size: ActionIconSize = 'md';
  @Input() theme: ActionIconTheme = 'default';
}
