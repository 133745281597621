import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { uuid } from '@shared/tools';

@Injectable({
  providedIn: 'root',
})
export class TabActivationService {
  public tabId = uuid();
  public visibleStatusChanged$: BehaviorSubject<boolean>;

  get tabVisible$(): Observable<boolean> {
    return this.visibleStatusChanged$.pipe(debounceTime(100));
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initTabVisibleSubject();
    this.listenTabChanged();
    this.listenFocusEvents();
  }

  private initTabVisibleSubject(): void {
    this.visibleStatusChanged$ = new BehaviorSubject<boolean>(
      document.visibilityState === 'visible' && this.document.hasFocus()
    );
    console.debug('FROM INITIAL: ', document.visibilityState === 'visible' && this.document.hasFocus());
  }

  private listenTabChanged(): void {
    fromEvent(this.document, 'visibilitychange').subscribe(() => {
      this.visibleStatusChanged$.next(document.visibilityState === 'visible');
    });
  }

  private listenFocusEvents(): void {
    fromEvent(window, 'focus').subscribe(() => {
      console.log('WINDOW FOCUS');
      this.visibleStatusChanged$.next(true);
    });
    fromEvent(window, 'blur').subscribe(() => {
      console.log('WINDOW BLUR');
      this.visibleStatusChanged$.next(false);
    });
  }
}
