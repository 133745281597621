import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { ChipUpdateEvent } from '@shared/components/chip-list/chip-list-base.component';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChipsInputComponent } from '@shared/components/chip-list/chips-input/chips-input.component';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipListComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('insertTrigger', [transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))])]),
  ],
})
export class ChipListComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() inputPlaceholder = '';
  @Input() rtl = false;

  @Output() added = new EventEmitter<string[]>();
  @Output() removed = new EventEmitter<string[]>();
  @Output() updated = new EventEmitter<ChipUpdateEvent>();

  public list: string[] = [];
  public selectedChipsMap: Record<string, boolean> = {};
  public selectedChips: string[] = [];

  @ViewChild(VirtualScrollerComponent) virtualScroll: VirtualScrollerComponent;
  @ViewChild(ChipsInputComponent) input: ChipsInputComponent;

  constructor(private cdf: ChangeDetectorRef) {}

  public onChange: (_: string[]) => void = () => {};

  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: string[]): void {
    this.list = value || [];
    this.cdf.markForCheck();
  }

  public toggleSelect(item: string): void {
    if (this.disabled) {
      return;
    }
    this.selectedChipsMap[item] = !this.selectedChipsMap[item];
    this.selectedChips = Object.entries(this.selectedChipsMap)
      .filter(([, selected]) => selected)
      .map(([text]) => text);
  }

  public deleteItems(items: string[]): void {
    this.list = this.list.filter((text) => !items.includes(text));
    items.forEach((item) => {
      delete this.selectedChipsMap[item];
    });
    this.selectedChips = Object.entries(this.selectedChipsMap)
      .filter(([, selected]) => selected)
      .map(([text]) => text);
    this.removed.emit(items);
    this.propagateChanges();
  }

  public addNewItems(items: string[]): void {
    const filtered = items.filter((item) => !this.list.includes(item));
    this.list.push(...filtered);
    this.added.emit(items);
    this.propagateChanges();
    this.scrollToBottom();
  }

  public updateItem(oldValue: string, newValue: string): void {
    if (this.list.includes(newValue)) {
      return;
    }
    this.list = this.list.map((text) => {
      if (text === oldValue) {
        return newValue;
      }
      return text;
    });
    this.selectedChipsMap = {};
    this.selectedChips = [];
    this.updated.emit({ newValue, oldValue });
    this.propagateChanges();
    this.scrollToBottom();
  }

  public deleteSelectedItems(): void {
    this.deleteItems(this.selectedChips);
  }

  public updateSelectedItem(newValue: string): void {
    this.updateItem(this.selectedChips[0], newValue);
  }

  public propagateChanges(): void {
    this.onChange(this.list);
  }

  public scrollToBottom(): void {
    if (!this.virtualScroll) {
      return;
    }
    this.virtualScroll.scrollToIndex(this.virtualScroll.items.length);
  }

  public focus(): void {
    this.input.focus();
  }
}
