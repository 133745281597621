import { Inject, Injectable } from '@angular/core';
import { LOG_APPENDER_CONFIG_TOKEN, LogAppender, LogAppenderConfig } from '../../log-appender';
import { LogLevel } from '../../log-level.enum';
import * as Sentry from '@sentry/browser';
import { Event, SeverityLevel } from '@sentry/types';

const SentryLogLevel: Record<LogLevel, SeverityLevel> = {
  [LogLevel.FATAL]: 'fatal',
  [LogLevel.ERROR]: 'error',
  [LogLevel.WARN]: 'warning',
  [LogLevel.INFO]: 'info',
  [LogLevel.DEBUG]: 'debug',
};

@Injectable()
export class SentryService implements LogAppender {
  constructor(@Inject(LOG_APPENDER_CONFIG_TOKEN) public config: LogAppenderConfig) {
    Sentry.configureScope((scope) => {
      scope.setLevel(SentryLogLevel[config.level]);
      scope.addEventProcessor((event: Event) => ({
        ...event,
        environment: location.host,
      }));
    });
  }

  public log(level: LogLevel, message: any, ...optionalParams: any[]): void {
    const noErrorParams = [];
    [message, ...optionalParams].forEach((param) => {
      if (param instanceof Error) {
        this.sentryError(level, param);
      } else {
        noErrorParams.push(param);
      }
    });
    if (noErrorParams.length) {
      this.sentryMessage(level, this.convertParamsToSentryMessage(noErrorParams));
    }
  }

  private sentryMessage(level: LogLevel, message: string): void {
    Sentry.captureMessage(message, SentryLogLevel[level]);
  }

  private sentryError(level: LogLevel, error): void {
    Sentry.captureException(error, {
      level: SentryLogLevel[level],
    });
  }

  private convertParamsToSentryMessage(params: any[]): string {
    return params
      .map((param) => {
        switch (typeof param) {
          case 'bigint':
          case 'number':
          case 'boolean':
          case 'function':
          case 'symbol':
            return param.toString();
          case 'object':
            return JSON.stringify(param);
          default:
            return param;
        }
      })
      .filter((param) => !!param)
      .join(';');
  }
}
