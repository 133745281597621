import { ProjectFileInfo } from '@generated/api';
import { FileId } from '@shared/models';

export enum ActionTypes {
  DOWNLOAD_PROJECTFILES = '[PROJECT_FILES] Download Project Files',
  DELETE_PROJECTFILES = '[PROJECT_FILES] Delete Project Files',
  LOAD_PROJECTFILES = '[PROJECT_FILES] Load Project Files',
  CHANGE_PROJECTFILE_LANGUAGES = '[PROJECT_FILES] Change Project File Languages',
  RESET_PROJECT_FILES = '[PROJECT_FILES] Reset Project Files Languages',
  CHANGE_PROJECT_FILES_PROTECTION = '[PROJECT_FILES] Change Project Files Protection',
  SET_PROJECT_FILES = '[PROJECT_FILES] Set Project Files',
  REMOVE_PROJECT_FILES = '[PROJECT_FILES] Remove Project Files',
  LOAD_PROJECT_FILE = '[PROJECT_FILES] Load Project File',
  SET_SELECTED_PROJECT_FILES = '[PROJECT_FILES] Set Selected Project Files',
}

export class DownloadProjectFiles {
  static readonly type = ActionTypes.DOWNLOAD_PROJECTFILES;
}

export class DeleteProjectFiles {
  static readonly type = ActionTypes.DELETE_PROJECTFILES;
}

export class LoadProjectFiles {
  static readonly type = ActionTypes.LOAD_PROJECTFILES;
  constructor(public projectId: string) {}
}

export class LoadProjectFile {
  static readonly type = ActionTypes.LOAD_PROJECT_FILE;
  constructor(public projectId: string, public fileId: string) {}
}

export class SetProjectFiles {
  static readonly type = ActionTypes.SET_PROJECT_FILES;
  constructor(public projectId: string, public files: ProjectFileInfo[]) {}
}

export class RemoveProjectFiles {
  static readonly type = ActionTypes.REMOVE_PROJECT_FILES;
  constructor(public projectId: string, public fileIds: string[]) {}
}

export class ChangeProjectFileLanguages {
  static readonly type = ActionTypes.CHANGE_PROJECTFILE_LANGUAGES;
  constructor(public fileIds: string[], public sourceLanguageId: number, public targetLanguageId: number) {}
}

export class ResetProjectFiles {
  static readonly type = ActionTypes.RESET_PROJECT_FILES;
}

export class ChangeProjectFilesProtection {
  static readonly type = ActionTypes.CHANGE_PROJECT_FILES_PROTECTION;
  constructor(public fileIds: FileId[], public isProtected: boolean) {}
}

export class SetSelectedProjectFiles {
  static readonly type = ActionTypes.SET_SELECTED_PROJECT_FILES;
  constructor(public fileIds: string[]) {}
}
