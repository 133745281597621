import { Injectable } from '@angular/core';
import { QualityIssue } from '@shared/models';
import { GridHelperService } from '@shared/services/grid-helper.service';
import { RowNode } from 'src/types/ag-grid';

@Injectable()
export class ReportGridService extends GridHelperService<QualityIssue> {
  public selectNextError(currentRow: RowNode<QualityIssue> | null): void {
    if (!currentRow) {
      return;
    }
    const nextRow = this.getNextError(currentRow);
    this.selectRow(nextRow);
  }

  public hasErrors(): boolean {
    return this.getRows().some((row: RowNode<QualityIssue>) => !row.group && !row.data.isIgnored);
  }

  private getNextError(currentRow: RowNode<QualityIssue>): RowNode<QualityIssue> {
    return this.findNextNode(currentRow.rowIndex, (node: RowNode<QualityIssue>) => !node.group && !node.data.isIgnored);
  }

  public selectFirstError(): void {
    const firstError = this.getNextError(this.gridApi.getDisplayedRowAtIndex(0));
    this.gridApi.deselectAll();
    this.selectRow(firstError);
  }
}
