<div id="notification-wrapper">
  <app-ui-icon *ngIf="icon" id="notification-icon" [icon]="icon" size="normal"></app-ui-icon>
  <div id="notification-content">
    <div *ngIf="title" id="notification-title">{{ title }}</div>
    <div *ngIf="text" id="notification-text" [class.wrap]="wrap">{{ text }}</div>
  </div>
  <app-button
    *ngIf="action"
    [prefixIcon]="action.icon || null"
    id="notification-action"
    theme="outlined"
    class="action-btn"
    (clickButton)="actionHandler()"
  >
    {{ action.title }}
  </app-button>
  <app-ui-icon
    *ngIf="cancelable"
    (click)="close()"
    id="notification-close-icon"
    class="close-btn clear-icon"
    icon="clear"
    size="normal"
  ></app-ui-icon>
</div>
