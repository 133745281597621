import { ApplicationRef, ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppService } from '@shared/services';
import { NotificationService } from '@shared/components/notification';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperComponent implements OnInit, OnDestroy {
  @HostBinding('attr.data-test-id') dataTestId = 'app-wrapper';

  private offlineSnack: MatSnackBarRef<any>;
  private destroyed$ = new Subject<void>();

  constructor(public app: AppService, private notificationService: NotificationService, private ref: ApplicationRef) {}

  ngOnInit() {
    this.app.online$
      .pipe(withLatestFrom(this.app.loaded$), takeUntil(this.destroyed$))
      .subscribe(([isOnline, loaded]) => {
        if (loaded) {
          this.toggleSnackbar(isOnline);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private toggleSnackbar(isOnline: boolean | null): void {
    if (isOnline === false) {
      this.notificationService.toast({
        icon: 'offline',
        text: 'You are offline. Some functionality may be unavailable.',
        cancelable: true,
      });
      this.ref.tick();
    } else {
      this.offlineSnack?.dismiss();
    }
  }
}
