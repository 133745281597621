import { SearchInReportFilesFilter, TranslationUnitUpdate } from '@shared/models';
import { SearchInReportFilesResultItem } from '@shared/models/search-in-report-files';
import { FileId } from '@shared/models';

export enum ActionTypes {
  SEARCH_IN_REPORT_FILES = '[Search In Report Files] Find',
  SEARCH_IN_REPORT_FILES_SUCCESS = '[Search In Report Files] Search Success',
  SEARCH_IN_REPORT_FILES_FAILURE = '[Search In Report Files] Search Failure',
  SET_SEARCH_IN_REPORT_FILES_FILTER = '[Search In Report Files] Set Search Filter',
  SET_SEARCH_IN_REPORT_FILES_LOADER = '[Search In Report Files] Set Search Loader',
  UPDATE_TRANSLATION_UNITS = '[Search In Report Files] Update Translation Unit',
  INIT_SEARCH_IN_REPORT_FILES = '[Search In Report Files] Init Search In Report Files',
  CHECK_TRANSLATION_UNITS_MATCH_TO_FILTER = '[Search In Report Files] Check Translation Units Match To Filter',
  SET_SEARCH_RESULT_LIST = '[Search In Report Files] Set Search Result List',
  REMOVE_FROM_SEARCH_RESULT_LIST = '[Search In Report Files] Remove From Search Result List',
  REMOVE_SEARCH_RESULT_BY_FILE_ID = '[Search In Report Files] Remove By File Id',
  UPDATE_SEARCH_RESULT_LIST = '[Search In Report Files] Update Search Result List',
  RESET_TRANSLATION_UNIT_IDS_SEARCH_CANDIDATES = '[Search In Report Files] Reset Translation Unit Ids Search Candidates',
  ADD_TRANSLATION_UNIT_ID_SEARCH_CANDIDATE = '[Search In Report Files] Add translation unit id search candidate',
  UPDATE_COMMENT_STATUS = '[Search In report Files] Update Comment Statuses',
}

export class SearchInReportFiles {
  static readonly type = ActionTypes.SEARCH_IN_REPORT_FILES;
  constructor() {}
}

export class SearchInReportFilesSuccess {
  static readonly type = ActionTypes.SEARCH_IN_REPORT_FILES_SUCCESS;
  constructor() {}
}

export class SearchInReportFilesFail {
  static readonly type = ActionTypes.SEARCH_IN_REPORT_FILES_FAILURE;
  constructor(public error: any) {}
}

export class SetSearchInReportFilesFilter {
  static readonly type = ActionTypes.SET_SEARCH_IN_REPORT_FILES_FILTER;
  constructor(public filter: SearchInReportFilesFilter) {}
}

export class SetSearchInReportFilesLoader {
  static readonly type = ActionTypes.SET_SEARCH_IN_REPORT_FILES_LOADER;
  constructor(public loader: boolean) {}
}

export class UpdateTranslationUnits {
  static readonly type = ActionTypes.UPDATE_TRANSLATION_UNITS;
  constructor(public translationUnitUpdates: TranslationUnitUpdate[]) {}
}

export class InitSearchInReportFiles {
  static readonly type = ActionTypes.INIT_SEARCH_IN_REPORT_FILES;
  constructor(public projectId: string) {}
}

export class CheckTranslationUnitsMatchToFilter {
  static readonly type = ActionTypes.CHECK_TRANSLATION_UNITS_MATCH_TO_FILTER;
  constructor(public translationUnitIds: string[]) {}
}

export class SetSearchResultList {
  static readonly type = ActionTypes.SET_SEARCH_RESULT_LIST;
  constructor(public list: SearchInReportFilesResultItem[]) {}
}

export class RemoveFromSearchResultList {
  static readonly type = ActionTypes.REMOVE_FROM_SEARCH_RESULT_LIST;
  constructor(public ids: string[]) {}
}

export class RemoveSearchResultByByFileId {
  static readonly type = ActionTypes.REMOVE_SEARCH_RESULT_BY_FILE_ID;
  constructor(public projectId: string, public fileId: FileId) {}
}

export class UpdateSearchResultList {
  static readonly type = ActionTypes.UPDATE_SEARCH_RESULT_LIST;
  constructor(public list: SearchInReportFilesResultItem[]) {}
}

export class AddTranslationUnitIdSearchCandidate {
  static readonly type = ActionTypes.ADD_TRANSLATION_UNIT_ID_SEARCH_CANDIDATE;
  constructor(public translationUnitIds: string[]) {}
}

export class ResetTranslationUnitIdsSearchCandidates {
  static readonly type = ActionTypes.RESET_TRANSLATION_UNIT_IDS_SEARCH_CANDIDATES;
  constructor() {}
}

export class UpdateSearchResultCommentStatus {
  static readonly type = ActionTypes.UPDATE_COMMENT_STATUS;
  constructor(public translationUnitId: string, public hasComments: boolean) {}
}
