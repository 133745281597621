import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { LoadUsageLog } from './payment-usage-log.actions';
import { OutgoingOrderModel, SubscriptionsService } from '@generated/payment-api';
import { finalize, Observable, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { CommonHubService } from '@shared/services';
import { BalanceChangedEvent } from '@shared/models';

interface PaymentUsageLogStateModel {
  loading: boolean;
  logs: OutgoingOrderModel[];
}

@AsyncStorage
@State<PaymentUsageLogStateModel>({
  name: 'paymentUsageLog',
  defaults: {
    loading: false,
    logs: [],
  },
})
@Injectable()
export class PaymentUsageLogState {
  @Selector()
  public static loading(state: PaymentUsageLogStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static logs(state: PaymentUsageLogStateModel): any {
    return state.logs;
  }

  constructor(
    private readonly subscriptionService: SubscriptionsService,
    private readonly commonHub: CommonHubService,
    private readonly store: Store
  ) {
    this.watchCommonHubEvents();
  }

  private watchCommonHubEvents(): void {
    this.commonHub.watch(BalanceChangedEvent).subscribe(() => {
      this.store.dispatch(new LoadUsageLog());
    });
  }

  @Action(LoadUsageLog)
  public loadUsageLog(ctx: StateContext<PaymentUsageLogStateModel>): Observable<any> {
    ctx.setState(
      patch({
        loading: true,
      })
    );
    return this.subscriptionService.apiSubscriptionUsagelogGet$Json().pipe(
      tap((logs) => {
        ctx.setState(
          patch({
            logs,
          })
        );
      }),
      finalize(() => {
        ctx.setState(
          patch({
            loading: true,
          })
        );
      })
    );
  }
}
