import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { DropdownModule } from '../dropdown';
import { DropdownMenuComponent } from './dropdown-menu.component';
import { DropdownMenuPreventClickDirective } from './directives';

@NgModule({
  imports: [CommonModule, MatMenuModule, DropdownModule],
  declarations: [DropdownMenuComponent, DropdownMenuPreventClickDirective],
  exports: [DropdownMenuComponent, DropdownMenuPreventClickDirective],
})
export class DropdownMenuModule {}
