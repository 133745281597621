import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DigitsToTextModel } from '@shared/components/digit-to-text';
import { NumberBoxComponent } from '@shared/components/number-box';
import { BaseCellEditorComponent } from '../../../common';
import { takeUntil } from 'rxjs/operators';
import { ProfileCultureDigitsToText } from '@shared/models';
import { PopoverComponent } from '@shared/components/popover';
import { GridHelperService } from '@shared/services/grid-helper.service';

@Component({
  selector: 'app-digit-cell-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './digit-cell-editor.component.html',
  styleUrls: ['./digit-cell-editor.component.scss'],
})
export class DigitCellEditorComponent extends BaseCellEditorComponent<DigitsToTextModel> implements AfterViewInit {
  control = new FormControl(null, [Validators.required, this.sameDigitValidator()]);
  errorDescriptions = {
    required: 'Cannot be empty',
    sameExist: 'There is already such a digit in the table. Please select another one.',
  };

  @ViewChild('numberBox', { static: true, read: NumberBoxComponent })
  numberBox: NumberBoxComponent;
  @ViewChild('validationPopover', { static: true, read: PopoverComponent })
  validationPopover: PopoverComponent;

  errorMsg: string;

  constructor(protected readonly gridHelperService: GridHelperService) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.numberBox.startEdit();
    }, 0);
  }

  public initSubscriptions(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.errorMsg = this.control.invalid ? this.getError() : undefined;

      if (this.errorMsg) {
        this.validationPopover?.open();
      } else {
        this.validationPopover?.close();
      }
    });
  }

  public isCancelAfterEnd(): boolean {
    return this.control.invalid;
  }

  public isPopup(): boolean {
    return false;
  }

  private sameDigitValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const tableData = this.getTableDataExceptCurrentRow();

      if (!tableData.length || control.value === undefined || control.value === null) {
        return null;
      }

      const hasSameName = tableData.some(({ digit }: ProfileCultureDigitsToText) => control.value === digit);
      return hasSameName ? { sameExist: true } : null;
    };
  }

  public onEnter(): void {
    this.gridHelperService.switchToNextEditor(this.rowNode, this.column.getColId());
  }
}
