import { ReportGridColumnFilterModel, ReportGridFilterModel } from '@shared/models/report';
import { FilterState } from '@store/report-grids-store/report-grids-state.model';

/**
 * Converts ag grid filter model to filter state
 */
export function mapToFilterState(filterModel: ReportGridFilterModel): FilterState[] {
  return Object.entries(filterModel)?.map(([key, value]) => mapToColumnFilterState(key, value));
}

function mapToColumnFilterState(colId: string, filterModel: ReportGridColumnFilterModel): FilterState {
  return {
    colId,
    filterType: filterModel.filterType,
    value: filterModel.value,
    values: filterModel.values,
  };
}

/**
 * Converts ag grid filter model from filter state
 */
export function mapFromFilterState(filterState: FilterState[]): ReportGridFilterModel {
  if (!filterState) {
    return null;
  }

  return filterState.reduce((acc, cur) => {
    acc[cur.colId] = cur;
    return acc;
  }, {});
}
