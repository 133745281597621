import { DOCUMENT } from '@angular/common';
import {
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

interface ContextMenuParams {
  hostElement: ElementRef<HTMLElement>;
  templateRef: TemplateRef<any>;
  viewRef: ViewContainerRef;
  event?: MouseEvent;
  coords?: [number, number];
}

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  public closed$: Subject<void> = new Subject<void>();
  public opened$: Subject<void> = new Subject<void>();
  public contextCloseOnScroll: boolean = true;
  public contextCloseOnClick: boolean = true;
  public contextAttachToElement: HTMLElement;
  public contextYOffset: number = 0;
  public contextXOffset: number = 0;
  public contextCloseIcon = false;

  private contextMenuWrapper: HTMLHtmlElement;
  private containerView: EmbeddedViewRef<any>;
  private closeIconTemplate: HTMLElement;
  private renderer: Renderer2;
  // Count of pixels for indent before window border
  private minWindowOffset: number = 16;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get maxDocumentHeight(): number {
    return this.document.body.offsetHeight;
  }

  get maxDocumentWidth(): number {
    return this.document.body.offsetWidth;
  }

  public isElementInsideContextMenu(elem: HTMLHtmlElement): boolean {
    return this.contextMenuWrapper?.contains(elem);
  }

  public close(): void {
    if (this.contextMenuWrapper) {
      this.renderer.removeChild(document.body, this.contextMenuWrapper);
    }
    if (this.containerView) {
      this.containerView.rootNodes.forEach((node) => this.contextMenuWrapper.removeChild(node));
      this.containerView.destroy();
      this.containerView = null;
    }
    this.contextMenuWrapper = null;
    this.closed$.next();
  }

  public get isOpened(): boolean {
    return !!this.contextMenuWrapper;
  }

  public createContextMenu(params: ContextMenuParams): void {
    this.close();
    setTimeout(() => {
      const [x, y] = this.getCoordinates(params);
      this.initContextMenuWrapper(x, y);
      this.containerView = params.viewRef.createEmbeddedView(params.templateRef);
      this.containerView.rootNodes.forEach((node) => this.contextMenuWrapper.appendChild(node));
      this.createCloseIcon();
      this.containerView.detectChanges();
      this.alignContextMenuAfterRender(x, y);
      this.initContextMenuWatchers();
      this.opened$.next();
    });
  }

  private getCoordinates(params: ContextMenuParams): [number, number] {
    if (this.contextAttachToElement) {
      const rect = this.contextAttachToElement.getBoundingClientRect();
      return [rect.left, rect.top + rect.width];
    }

    if (params.coords) {
      return params.coords;
    }

    return [params.event.pageX, params.event.pageY];
  }

  private initContextMenuWrapper(x: number, y: number): void {
    this.contextMenuWrapper = this.renderer.createElement('div');
    this.renderer.addClass(this.contextMenuWrapper, 'context-menu');
    this.renderer.setStyle(this.contextMenuWrapper, 'left', `${x + this.contextXOffset}px`);
    this.renderer.setStyle(this.contextMenuWrapper, 'top', `${y + this.contextYOffset}px`);
    this.renderer.setStyle(this.contextMenuWrapper, 'opacity', '0');
    this.renderer.appendChild(document.body, this.contextMenuWrapper);
  }

  private initContextMenuWatchers(): void {
    if (!this.contextCloseOnClick) {
      return;
    }

    fromEvent(this.contextMenuWrapper, 'click')
      .pipe(takeUntil(this.closed$))
      .subscribe((e) => {
        this.close();
        e.stopPropagation();
        e.preventDefault();
      });
  }

  private createCloseIcon(): void {
    if (!this.contextCloseIcon) {
      return;
    }
    this.closeIconTemplate = this.renderer.createElement('div');
    this.closeIconTemplate.classList.add('context-menu-close-icon');
    this.contextMenuWrapper.appendChild(this.closeIconTemplate);
    fromEvent(this.closeIconTemplate, 'click')
      .pipe(takeUntil(this.closed$))
      .subscribe((event) => {
        this.close();
        event.stopPropagation();
        event.preventDefault();
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private alignContextMenuAfterRender(x: number, _: number): void {
    setTimeout(() => {
      this.renderer.setStyle(this.contextMenuWrapper, 'opacity', '1');

      const { bottom } = this.contextMenuWrapper.getBoundingClientRect();
      const leftOffsetForHorizontalAlignment = x + this.contextXOffset - this.contextMenuWrapper.offsetWidth / 2;
      this.renderer.setStyle(this.contextMenuWrapper, 'left', `${leftOffsetForHorizontalAlignment}px`);

      if (leftOffsetForHorizontalAlignment < this.minWindowOffset) {
        this.renderer.setStyle(this.contextMenuWrapper, 'left', `${this.minWindowOffset}px`);
      }

      if (
        leftOffsetForHorizontalAlignment + this.contextMenuWrapper.offsetWidth >
        this.maxDocumentWidth - this.minWindowOffset
      ) {
        this.renderer.setStyle(
          this.contextMenuWrapper,
          'left',
          `${this.maxDocumentWidth - this.minWindowOffset - this.contextMenuWrapper.offsetWidth}px`
        );
      }

      if (bottom > this.maxDocumentHeight - this.minWindowOffset) {
        this.renderer.setStyle(
          this.contextMenuWrapper,
          'top',
          `${this.maxDocumentHeight - this.minWindowOffset - this.contextMenuWrapper.offsetHeight}px`
        );
      }

      this.containerView.detectChanges();
    });
  }
}
