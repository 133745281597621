<div class="organizations-list">
  <div
    (click)="selectOrganization(null)"
    [ngClass]="{ selected: !selectedOrganizationId }"
    class="organization organization-area"
    role="button"
  >
    <div class="avatar">
      <app-ui-icon icon="user"></app-ui-icon>
    </div>
    <div class="body">
      Private usage
      <div *ngIf="!organizations?.length" (click)="createOrganization()" class="actions" role="button">
        <app-ui-icon icon="add" color="primary-light"></app-ui-icon>
        <div class="create-org-action">Create organization</div>
      </div>
    </div>
  </div>
  <div
    *ngFor="let org of organizations; let i = index"
    (click)="selectOrganization(org)"
    [ngClass]="{ selected: org.id === selectedOrganizationId }"
    class="organization organization-area"
    role="button"
  >
    <div class="avatar">
      <app-ui-icon *ngIf="org.id === selectedOrganizationId" icon="home-work"></app-ui-icon>
    </div>
    <div class="body">
      <div class="user-info">
        <div class="org-name" [appTooltipTruncated]="org.name">{{ org.name }}</div>
        <!-- TODO TMP hack. right now the list of organizations doesn't contain roles -->
        <div *ngIf="org.id === selectedOrganizationId" class="org-roles">{{ roles }}</div>
      </div>
      <div *ngIf="org.id === selectedOrganizationId" class="actions">
        <div (click)="inviteUser(org)" class="invite-user" role="button">
          <app-ui-icon icon="user-add" color="primary-light"></app-ui-icon>
          <div class="create-org-action">Invite</div>
        </div>
      </div>
    </div>
  </div>
</div>
