import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AppService } from '@shared/services';

@Component({
  selector: 'app-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorLayoutComponent implements OnInit {
  constructor(private readonly app: AppService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.app.loaded$.next(true);
      this.cdr.detectChanges();
    }, 300);
  }
}
