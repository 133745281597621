/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PotentialUntranslatableItemDataSourceResponse } from '../models/potential-untranslatable-item-data-source-response';

@Injectable({
  providedIn: 'root',
})
export class PotentialUntranslatablesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProjectsProjectIdPotentialUntranslatablesGet
   */
  static readonly ApiProjectsProjectIdPotentialUntranslatablesGetPath = '/api/projects/{projectId}/potential-untranslatables';

  /**
   * Returns potential untranslatables by project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdPotentialUntranslatablesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdPotentialUntranslatablesGet$Plain$Response(params: {

    /**
     * The project ID
     */
    projectId: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PotentialUntranslatablesService.ApiProjectsProjectIdPotentialUntranslatablesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>;
      })
    );
  }

  /**
   * Returns potential untranslatables by project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdPotentialUntranslatablesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdPotentialUntranslatablesGet$Plain(params: {

    /**
     * The project ID
     */
    projectId: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<PotentialUntranslatableItemDataSourceResponse> {

    return this.apiProjectsProjectIdPotentialUntranslatablesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>) => r.body as PotentialUntranslatableItemDataSourceResponse)
    );
  }

  /**
   * Returns potential untranslatables by project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdPotentialUntranslatablesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdPotentialUntranslatablesGet$Json$Response(params: {

    /**
     * The project ID
     */
    projectId: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PotentialUntranslatablesService.ApiProjectsProjectIdPotentialUntranslatablesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>;
      })
    );
  }

  /**
   * Returns potential untranslatables by project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdPotentialUntranslatablesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdPotentialUntranslatablesGet$Json(params: {

    /**
     * The project ID
     */
    projectId: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<PotentialUntranslatableItemDataSourceResponse> {

    return this.apiProjectsProjectIdPotentialUntranslatablesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PotentialUntranslatableItemDataSourceResponse>) => r.body as PotentialUntranslatableItemDataSourceResponse)
    );
  }

}
