import { Injectable } from '@angular/core';
import { FilesUpload } from '@shared/models';

export enum ImportError {
  FILE_COUNT_ERROR = 'file_count_error',
  FILE_SIZE_ERROR = 'file_size_error',
  FILE_TYPE_ERROR = 'file_type_error',
  ANOTHER_ERROR = 'another_error',
}

/**
 * File import configuration
 */
export interface ImportConfig {
  /**
   * Maximum file size limit, bytes. By default accepts any file size.
   */
  sizeLimit?: number;

  /**
   * List of accepted MIME types. By default accepts all file types.
   * @example `['text/plain']`
   */
  types?: string[];
}

/**
 * How many bits in kilobytes
 */
export const KB_SIZE = 1024;

/**
 * This service can export and import files
 */
@Injectable()
export class FileService {
  constructor() {}

  private makeTextFile(text: string) {
    const data = new Blob([text], { type: 'text/plain' });
    return window.URL.createObjectURL(data);
  }

  /**
   * The method exports text to TXT file
   * @param {string} text The text to be exported to a file
   * @param {string} fileName The name for new file
   */
  exportTxtFile(text: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = this.makeTextFile(text);
    document.body.appendChild(link);

    window.requestAnimationFrame(() => {
      const event = new MouseEvent('click');
      link.dispatchEvent(event);
      document.body.removeChild(link);
    });
  }

  /**
   * The method import from one file
   * @param {FilesUpload} filesUpload There is imported file
   * @param {ImportConfig} config Import configuration
   * @return Promise<string> Text from file
   */
  importFile(filesUpload: FilesUpload, config?: ImportConfig): Promise<string> {
    return new Promise((solve, reject) => {
      const files = filesUpload.files;

      if ((files && files.length > 1) || filesUpload.isFolder) {
        reject(ImportError.FILE_COUNT_ERROR);
      }

      if (config) {
        if (config.sizeLimit && files[0].size > config.sizeLimit) {
          reject(ImportError.FILE_SIZE_ERROR);
        }

        if (
          config.types &&
          config.types.length &&
          config.types.indexOf(files[0].type) === -1
        ) {
          reject(ImportError.FILE_TYPE_ERROR);
        }
      }

      const file = files.pop();
      file
        .text()
        .then((text) => {
          if (text) {
            solve(text);
          }
        })
        .catch((error) => {
          console.warn(error);
          reject(ImportError.ANOTHER_ERROR);
        });
    });
  }
}
