import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { ConfigurableFocusTrapFactory, FocusTrap } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { transformPopover } from './popover.animations';
import {
  getInvalidHorizontalAlignError,
  getInvalidPopoverAnchorError,
  getInvalidPopoverAnchorTypeError,
  getInvalidPopoverError,
  getInvalidScrollStrategyError,
  getInvalidVerticalAlignError,
  getUnanchoredPopoverError,
} from './popover.errors';
import type {
  PopoverHorizontalAlign,
  PopoverOpenOptions,
  PopoverScrollStrategy,
  PopoverVerticalAlign,
} from './types';
import { VALID_HORIZ_ALIGN, VALID_SCROLL, VALID_VERT_ALIGN } from './types';
import { PopoverAnchoringService } from './popover-anchoring.service';

// See http://cubic-bezier.com/#.25,.8,.25,1 for reference.
const DEFAULT_TRANSITION = '200ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'app-popover',
  animations: [transformPopover],
  styleUrls: ['./popover.component.scss'],
  templateUrl: './popover.component.html',
  providers: [PopoverAnchoringService],
})
export class PopoverComponent implements OnInit {
  autoFocusOverride = true;
  restoreFocusOverride = true;

  /** Optional backdrop class. */
  @Input() backdropClass = '';

  /** Emits when the popover is opened. */
  @Output() opened = new EventEmitter<void>();

  /** Emits when the popover is closed. */
  @Output() closed = new EventEmitter<any>();

  /** Emits when the popover has finished opening. */
  @Output() afterOpen = new EventEmitter<void>();

  /** Emits when the popover has finished closing. */
  @Output() afterClose = new EventEmitter<void>();

  /** Emits when the backdrop is clicked. */
  @Output() backdropClicked = new EventEmitter<void>();

  /** Emits when a keydown event is targeted to this popover's overlay. */
  @Output() overlayKeydown = new EventEmitter<KeyboardEvent>();

  /** Reference to template so it can be placed within a portal. */
  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;

  /** Whether the popover is presently open. */
  isOpenInternal = false;

  /** @internal */
  anchoringService: PopoverAnchoringService;
  /** Classes to be added to the popover for setting the correct transform origin. */
  classList: any = {};
  private anchorInternal:
    | PopoverAnchorDirective
    | ElementRef<HTMLElement>
    | HTMLElement;
  private horizontalAlignInternal: PopoverHorizontalAlign = 'center';
  private verticalAlignInternal: PopoverVerticalAlign = 'center';
  private forceAlignmentInternal = false;
  private lockAlignmentInternal = false;
  private autoFocusInternal = true;
  private restoreFocusInternal = true;
  private scrollStrategyInternal: PopoverScrollStrategy = 'reposition';
  private hasBackdropInternal = false;
  private interactiveCloseInternal = true;
  private openTransitionInternal = DEFAULT_TRANSITION;
  private closeTransitionInternal = DEFAULT_TRANSITION;

  /** Reference to the element to build a focus trap around. */
  @ViewChild('focusTrapElement')
  private focusTrapElement: ElementRef;

  /** Reference to the element that was focused before opening. */
  private previouslyFocusedElement: HTMLElement;

  /** Reference to a focus trap around the popover. */
  private focusTrap: FocusTrap;

  constructor(
    private focusTrapFactory: ConfigurableFocusTrapFactory,
    anchoringService: PopoverAnchoringService,
    private viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {
    // `@internal` stripping doesn't seem to work if the property is
    // declared inside the constructor
    this.anchoringService = anchoringService;
  }

  /** Anchor element. */
  @Input()
  get anchor(): PopoverAnchorDirective | ElementRef<HTMLElement> | HTMLElement {
    return this.anchorInternal;
  }

  set anchor(
    val: PopoverAnchorDirective | ElementRef<HTMLElement> | HTMLElement
  ) {
    if (val instanceof PopoverAnchorDirective) {
      val.popoverInternal = this;
      this.anchoringService.anchor(this, val.viewContainerRef, val.elementRef);
      this.anchorInternal = val;
    } else if (val instanceof ElementRef || val instanceof HTMLElement) {
      this.anchoringService.anchor(this, this.viewContainerRef, val);
      this.anchorInternal = val;
    } else if (val) {
      throw getInvalidPopoverAnchorTypeError();
    }
  }

  /** Alignment of the popover on the horizontal axis. */
  @Input()
  get horizontalAlign(): PopoverHorizontalAlign {
    return this.horizontalAlignInternal;
  }

  set horizontalAlign(val: PopoverHorizontalAlign) {
    this.validateHorizontalAlign(val);
    if (this.horizontalAlignInternal !== val) {
      this.horizontalAlignInternal = val;
      this.anchoringService.repositionPopover();
    }
  }

  /** Alignment of the popover on the x axis. Alias for `horizontalAlign`. */
  @Input()
  get xAlign(): PopoverHorizontalAlign {
    return this.horizontalAlign;
  }

  set xAlign(val: PopoverHorizontalAlign) {
    this.horizontalAlign = val;
  }

  /** Alignment of the popover on the vertical axis. */
  @Input()
  get verticalAlign(): PopoverVerticalAlign {
    return this.verticalAlignInternal;
  }

  set verticalAlign(val: PopoverVerticalAlign) {
    this.validateVerticalAlign(val);
    if (this.verticalAlignInternal !== val) {
      this.verticalAlignInternal = val;
      this.anchoringService.repositionPopover();
    }
  }

  /** Alignment of the popover on the y axis. Alias for `verticalAlign`. */
  @Input()
  get yAlign(): PopoverVerticalAlign {
    return this.verticalAlign;
  }

  set yAlign(val: PopoverVerticalAlign) {
    this.verticalAlign = val;
  }

  /** Whether the popover always opens with the specified alignment. */
  @Input()
  get forceAlignment(): boolean {
    return this.forceAlignmentInternal;
  }

  set forceAlignment(val: boolean) {
    const coercedVal = coerceBooleanProperty(val);
    if (this.forceAlignmentInternal !== coercedVal) {
      this.forceAlignmentInternal = coercedVal;
      this.anchoringService.repositionPopover();
    }
  }

  /**
   * Whether the popover's alignment is locked after opening. This prevents the popover
   * from changing its alignement when scrolling or changing the size of the viewport.
   */
  @Input()
  get lockAlignment(): boolean {
    return this.lockAlignmentInternal;
  }

  set lockAlignment(val: boolean) {
    const coercedVal = coerceBooleanProperty(val);
    if (this.lockAlignmentInternal !== coercedVal) {
      this.lockAlignmentInternal = coerceBooleanProperty(val);
      this.anchoringService.repositionPopover();
    }
  }

  /** Whether the first focusable element should be focused on open. */
  @Input()
  get autoFocus(): boolean {
    return this.autoFocusInternal && this.autoFocusOverride;
  }

  set autoFocus(val: boolean) {
    this.autoFocusInternal = coerceBooleanProperty(val);
  }

  /** Whether the popover should return focus to the previously focused element after closing. */
  @Input()
  get restoreFocus(): boolean {
    return this.restoreFocusInternal && this.restoreFocusOverride;
  }

  set restoreFocus(val: boolean) {
    this.restoreFocusInternal = coerceBooleanProperty(val);
  }

  /** How the popover should handle scrolling. */
  @Input()
  get scrollStrategy(): PopoverScrollStrategy {
    return this.scrollStrategyInternal;
  }

  set scrollStrategy(val: PopoverScrollStrategy) {
    this.validateScrollStrategy(val);
    if (this.scrollStrategyInternal !== val) {
      this.scrollStrategyInternal = val;
      this.anchoringService.updatePopoverConfig();
    }
  }

  /** Whether the popover should have a backdrop (includes closing on click). */
  @Input()
  get hasBackdrop(): boolean {
    return this.hasBackdropInternal;
  }

  set hasBackdrop(val: boolean) {
    this.hasBackdropInternal = coerceBooleanProperty(val);
  }

  /** Whether the popover should close when the user clicks the backdrop or presses ESC. */
  @Input()
  get interactiveClose(): boolean {
    return this.interactiveCloseInternal;
  }

  set interactiveClose(val: boolean) {
    this.interactiveCloseInternal = coerceBooleanProperty(val);
  }

  /** Custom transition to use while opening. */
  @Input()
  get openTransition(): string {
    return this.openTransitionInternal;
  }

  set openTransition(val: string) {
    if (val) {
      this.openTransitionInternal = val;
    }
  }

  /** Custom transition to use while closing. */
  @Input()
  get closeTransition(): string {
    return this.closeTransitionInternal;
  }

  set closeTransition(val: string) {
    if (val) {
      this.closeTransitionInternal = val;
    }
  }

  @Input()
  set showIf(val: boolean) {
    if (!!val) {
      // NOTE sometime anchor is initialized only after that component, so this.anchorInternal is undefined
      setTimeout(() => {
        this.open();
      });
    } else {
      this.close();
    }
  }

  ngOnInit() {
    this.setAlignmentClasses();
  }

  /**
   * Open this popover
   */
  open(options: PopoverOpenOptions = {}): void {
    if (this.anchorInternal) {
      this.anchoringService.openPopover(options);
      return;
    }

    throw getUnanchoredPopoverError();
  }

  /** Close this popover. */
  close(value?: any): void {
    this.anchoringService.closePopover(value);
  }

  /**
   * Open this popover
   */
  show(options: PopoverOpenOptions = {}): void {
    return this.open(options);
  }

  /** Close this popover. */
  hide(value?: any): void {
    this.close(value);
  }

  /** Toggle this popover open or closed. */
  toggle(): void {
    this.anchoringService.togglePopover();
  }

  /** Realign the popover to the anchor. */
  realign(): void {
    this.anchoringService.realignPopoverToAnchor();
  }

  /** Gets whether the popover is presently open. */
  isOpen(): boolean {
    return this.isOpenInternal;
  }

  /** Allows programmatically setting a custom anchor. */
  setCustomAnchor(
    viewContainer: ViewContainerRef,
    el: ElementRef<HTMLElement> | HTMLElement
  ): void {
    this.anchorInternal = el;
    this.anchoringService.anchor(this, viewContainer, el);
  }

  /** Gets an animation config with customized (or default) transition values. */
  getAnimation(): { value: any; params: any } {
    return {
      value: 'visible',
      params: {
        openTransition: this.openTransition,
        closeTransition: this.closeTransition,
      },
    };
  }

  /** Callback for when the popover is finished animating in or out. */
  onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'visible') {
      this.trapFocus();
      this.afterOpen.emit();
    } else if (event.toState === 'void') {
      this.restoreFocusAndDestroyTrap();
      this.afterClose.emit();
    }
  }

  /** Apply alignment classes based on alignment inputs. */
  setAlignmentClasses(
    horizAlign = this.horizontalAlign,
    vertAlign = this.verticalAlign
  ) {
    this.classList['popover-before'] =
      horizAlign === 'before' || horizAlign === 'end';
    this.classList['popover-after'] =
      horizAlign === 'after' || horizAlign === 'start';

    this.classList['popover-above'] =
      vertAlign === 'above' || vertAlign === 'end';
    this.classList['popover-below'] =
      vertAlign === 'below' || vertAlign === 'start';

    this.classList['popover-center'] =
      horizAlign === 'center' || vertAlign === 'center';
  }

  /** Move the focus inside the focus trap and remember where to return later. */
  private trapFocus(): void {
    this.savePreviouslyFocusedElement();

    // There won't be a focus trap element if the close animation starts before open finishes
    if (!this.focusTrapElement) {
      return;
    }

    if (!this.focusTrap && this.focusTrapElement) {
      this.focusTrap = this.focusTrapFactory.create(
        this.focusTrapElement.nativeElement
      );
    }

    if (this.autoFocus) {
      this.focusTrap.focusInitialElementWhenReady();
    }
  }

  /** Restore focus to the element focused before the popover opened. Also destroy trap. */
  private restoreFocusAndDestroyTrap(): void {
    const toFocus = this.previouslyFocusedElement;

    // Must check active element is focusable for IE sake
    if (toFocus && 'focus' in toFocus && this.restoreFocus) {
      this.previouslyFocusedElement.focus();
    }

    this.previouslyFocusedElement = null;

    if (this.focusTrap) {
      this.focusTrap.destroy();
      this.focusTrap = undefined;
    }
  }

  /** Save a reference to the element focused before the popover was opened. */
  private savePreviouslyFocusedElement(): void {
    if (this.document) {
      this.previouslyFocusedElement = this.document
        .activeElement as HTMLElement;
    }
  }

  /** Throws an error if the alignment is not a valid horizontalAlign. */
  private validateHorizontalAlign(pos: PopoverHorizontalAlign): void {
    if (VALID_HORIZ_ALIGN.indexOf(pos) === -1) {
      throw getInvalidHorizontalAlignError(pos);
    }
  }

  /** Throws an error if the alignment is not a valid verticalAlign. */
  private validateVerticalAlign(pos: PopoverVerticalAlign): void {
    if (VALID_VERT_ALIGN.indexOf(pos) === -1) {
      throw getInvalidVerticalAlignError(pos);
    }
  }

  /** Throws an error if the scroll strategy is not a valid strategy. */
  private validateScrollStrategy(strategy: PopoverScrollStrategy): void {
    if (VALID_SCROLL.indexOf(strategy) === -1) {
      throw getInvalidScrollStrategyError(strategy);
    }
  }
}

// NOTE PopoverAnchorDirective described here to avoid circular dependency
@Directive({
  selector: '[appPopoverAnchor]',
  exportAs: 'popoverAnchor',
})
export class PopoverAnchorDirective implements AfterViewInit {
  popoverInternal: PopoverComponent;

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef
  ) {}

  @Input('appPopoverAnchor')
  get popover(): PopoverComponent {
    return this.popoverInternal;
  }

  set popover(val: PopoverComponent | string) {
    if (val instanceof PopoverComponent) {
      val.anchor = this;
      return;
    }
    // when a directive is added with no arguments,
    // angular assigns `''` as the argument
    if (val !== '') {
      throw getInvalidPopoverError();
    }
  }

  ngAfterViewInit() {
    if (!this.popover) {
      throw getInvalidPopoverAnchorError();
    }
  }
}
