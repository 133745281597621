enum ActionTypes {
  LOAD_WORDFORMS_BASES = '[WordformsBase] Load wordforms bases',
  LOAD_DEFAULT_WORDFORMS_BASE = '[WordformsBase] Load the default wordforms base',
  CREATE_WORDFORMS_BASE = '[WordformsBase] Create a new wordforms base',
  CREATE_IF_NOT_EXISTS_WORDFORMS_BASE = '[WordformsBase] Create if not exists a default WordformsBase',
  UPDATE_WORDFORMS_BASE = '[WordformsBase] Update a specific wordforms base',
}

export class LoadWordformsBases {
  static readonly type = ActionTypes.LOAD_WORDFORMS_BASES;
  constructor() {}
}

export class LoadDefaultWordformsBase {
  static readonly type = ActionTypes.LOAD_DEFAULT_WORDFORMS_BASE;
  constructor() {}
}

export class CreateWordformsBase {
  static readonly type = ActionTypes.CREATE_WORDFORMS_BASE;
  constructor(public name: string) {}
}

export class UpdateWordformsBase {
  static readonly type = ActionTypes.CREATE_WORDFORMS_BASE;
  constructor(public id: string, public name: string) {}
}
