import { ChangeDetectionStrategy, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { DialogConfirmationConfig } from '@shared/components/dialog/confirmation/types/dialog-confirmation.config.type';

const defValues: DialogConfirmationConfig = {
  okText: 'Yes',
  cancelText: 'Cancel',
  message: '',
  theme: 'primary',
};

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DialogConfirmationComponent),
      multi: true,
    },
  ],
})
export class DialogConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationConfig
  ) {
    // Update view with given values
    this.data = Object.assign(defValues, data);
  }
}
