import { NgModule } from '@angular/core';
import { ActionIconModule } from '@shared/components/action-icon';
import { ReportSettingsBtnComponent } from './report-settings-btn.component';

@NgModule({
  imports: [ActionIconModule],
  declarations: [ReportSettingsBtnComponent],
  exports: [ReportSettingsBtnComponent],
})
export class ReportSettingsBtnModule {}
