import { Injectable } from '@angular/core';
import { Action, Selector, State, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { removeItem, append, patch, updateItem } from '@ngxs/store/operators';
import { FileUploadState, FileUploadStatus } from '@shared/models';
import {
  AddOrUpdateUploadingFile,
  CancelUploadingAllFiles,
  RemoveUploadingFile,
  SetUploadingFiles,
} from './uploading-files.actions';
import { ProjectState } from '@store';

interface UploadingFilesStateModel {
  [projectId: string]: FileUploadState[];
}

@State<UploadingFilesStateModel>({
  name: 'uploadingFiles',
  defaults: {},
})
@Injectable()
export class UploadingFilesState {
  constructor(private store: Store) {}

  @Selector([UploadingFilesState, ProjectState.projectId])
  public static uploadingFiles(state: UploadingFilesStateModel, projectId: string) {
    return (state && state[projectId]) || [];
  }

  @Selector([UploadingFilesState.uploadingFiles])
  public static hasInProgressFiles(fileUploadStates: FileUploadState[]): boolean {
    return fileUploadStates.some((item) => item.status !== FileUploadStatus.error);
  }

  @Action(SetUploadingFiles)
  public setUploadingFiles(ctx: StateContext<UploadingFilesStateModel>, action: SetUploadingFiles) {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: action.files,
      })
    );
  }

  @Action(AddOrUpdateUploadingFile)
  public addUploadingFiles(ctx: StateContext<UploadingFilesStateModel>, action: AddOrUpdateUploadingFile) {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const files = this.store.selectSnapshot(UploadingFilesState.uploadingFiles);
    if (files.some((item) => item.id === action.file.id)) {
      ctx.setState(
        patch({
          [projectId]: updateItem<FileUploadState>((item) => item.id === action.file.id, action.file),
        })
      );
      return;
    }
    ctx.setState(
      patch({
        [projectId]: append([action.file]),
      })
    );
  }

  @Action(RemoveUploadingFile)
  public removeUploadingFiles(ctx: StateContext<UploadingFilesStateModel>, action: RemoveUploadingFile) {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: removeItem<FileUploadState>((item) => item.id === action.fileId),
      })
    );
  }

  @Action(CancelUploadingAllFiles)
  public cancelUploadingAllFiles(ctx: StateContext<UploadingFilesStateModel>) {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: [],
      })
    );
  }
}
