import { Injectable } from '@angular/core';
import { TranslationUnitModel } from '@generated/api';
import { PreparedTranslationUnit } from '@shared/models';
import { RenderItemBuilder } from '@shared/services';

@Injectable()
export class TranslationUnitMapper {
  constructor(private renderItemBuilder: RenderItemBuilder) {}

  public mapTranslationUnits(translationUnits: TranslationUnitModel[]): PreparedTranslationUnit[] {
    return translationUnits.map((tu) => this.mapTranslationUnit(tu));
  }

  public mapTranslationUnit(tu: TranslationUnitModel): PreparedTranslationUnit {
    return {
      ...tu,
      sourceRenderItems: this.renderItemBuilder.build([], tu.source.elements),
      targetRenderItems: this.renderItemBuilder.build([], tu.target.elements),
    };
  }
}
