import { Component, HostBinding } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-grid-tooltip',
  template: '',
  styleUrls: ['./grid-tooltip.component.scss'],
})
export class GridTooltipComponent implements ITooltipAngularComp {
  @HostBinding('innerHtml')
  htmlToDisplay: string;

  agInit(params: ITooltipParams): void {
    this.htmlToDisplay = params?.value?.value ?? params?.value;
  }
}
