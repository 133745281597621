import {
  LoadTranslationUnitChanges,
  LoadTranslationUnitChangesFail,
  LoadTranslationUnitChangesSuccess,
  RollbackTranslationUnitChanges,
  RollbackTranslationUnitChangesFail,
  RollbackTranslationUnitChangesSuccess,
  RemoveTranslationUnitChanges,
  AddTranslationUnitChanges,
  LoadTranslationUnitChange,
  ClearTranslationUniChanges,
  RemoveTranslationUnitChangesByFileId,
} from './translation-unit-changes.actions';
import { TranslationUnitChangeAddedEvent, TranslationUnitChangeDeletedEvent } from '@shared/models';
import { Action, State, Selector, createSelector, Store, NgxsOnInit } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { tap, mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CommonHubService } from '@shared/services';
import { append, patch } from '@ngxs/store/operators';
import { TranslationUnitChange } from '@shared/models/translation-unit-change';
import { TranslationUnitChangeMapper } from '@shared/mappers';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { AddTranslationUnitIdSearchCandidate } from '@store/search-in-report-files';
import { TranslationUnitChangeModel, TranslationUnitChangesService } from '@generated/api';
import { ProjectState } from '@store/project-store';
import { removeItems } from '@store/custom-operators';
import { CommentThreadRemoveByTranslationUnitId } from '@store/comments';
import { Observable } from 'rxjs';

interface TranslationUnitChangesStateModel {
  [projectId: string]: TranslationUnitChange[];
}

@AsyncStorage
@StateRepository()
@State<TranslationUnitChangesStateModel>({
  name: 'translationUnitChanges',
  defaults: {},
})
@Injectable()
export class TranslationUnitChangesState implements NgxsOnInit {
  constructor(
    private store: Store,
    private translationUnitChangeService: TranslationUnitChangesService,
    private translationUnitChangeMapper: TranslationUnitChangeMapper,
    private commonHub: CommonHubService
  ) {
    this.commonHub.watch(TranslationUnitChangeAddedEvent).subscribe((event) => {
      this.store.dispatch(new LoadTranslationUnitChange(event.id));
    });
    this.commonHub.watch(TranslationUnitChangeDeletedEvent).subscribe((event) => {
      this.store.dispatch(new RemoveTranslationUnitChanges(event.ids));
    });
  }

  @Selector([TranslationUnitChangesState, ProjectState.projectId])
  public static translationUnitChanges(
    state: TranslationUnitChangesStateModel,
    reportId: string
  ): TranslationUnitChange[] {
    return (state && state[reportId]) || [];
  }

  public static translationUnitChange(id: string): (state: TranslationUnitChange[]) => TranslationUnitChange {
    return createSelector(
      [TranslationUnitChangesState.translationUnitChanges],
      (state: TranslationUnitChange[]) => id && state.find((item: TranslationUnitChange) => item.id === id)
    );
  }

  public static byTranslationUnitId(id: string): (state: TranslationUnitChange[]) => TranslationUnitChange {
    return createSelector(
      [TranslationUnitChangesState.translationUnitChanges],
      (state: TranslationUnitChange[]) =>
        id && state.find((item: TranslationUnitChange) => item.translationUnit.id === id)
    );
  }

  public static filter(
    predicate: (value: TranslationUnitChange, index: number, array: TranslationUnitChange[]) => unknown
  ): (state: TranslationUnitChange[]) => TranslationUnitChange[] {
    return createSelector([TranslationUnitChangesState.translationUnitChanges], (state: TranslationUnitChange[]) =>
      state.filter(predicate)
    );
  }

  public ngxsOnInit(ctx?: StateContext<any>): void {
    // NOTE: мутация стирает предыдущее состояние из персистент стейта
    // убрать или обработать после перехода на частичное восстановление состояния
    ctx.dispatch(new ClearTranslationUniChanges());
  }

  @Action(LoadTranslationUnitChanges)
  public loadTranslationUnitChanges(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: LoadTranslationUnitChanges
  ): Observable<void | TranslationUnitChangeModel[]> {
    return this.translationUnitChangeService.apiTranslationUnitChangesGet$Json({ projectId: action.projectId }).pipe(
      map((changes) => this.translationUnitChangeMapper.mapTranslationUnitChanges(changes)),
      tap((translationUnitChanges) => {
        ctx.patchState({
          [action.projectId]: translationUnitChanges,
        });
      }),
      mergeMap((translationUnitChanges) => ctx.dispatch(new LoadTranslationUnitChangesSuccess(translationUnitChanges))),
      // eslint-disable-next-line arrow-body-style
      catchError((error) => {
        // TODO VW-1279: нужно обработать корректно ошибку, в каком случае загружаем заново
        // сейчас перехватываются все ошибки, а нужно только для network ошибок
        return ctx.dispatch(new LoadTranslationUnitChangesFail(error));
      })
    );
  }

  @Action(AddTranslationUnitChanges)
  public addTranslationUnitChange(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: AddTranslationUnitChanges
  ): TranslationUnitChange[] {
    const mappedTranslationUnitChanges = this.translationUnitChangeMapper.mapTranslationUnitChanges(
      action.translationUnitChanges
    );
    const projectId = this.store.selectSnapshot(ProjectState.projectId);

    ctx.setState(
      patch({
        [projectId]: append(mappedTranslationUnitChanges),
      })
    );
    return action.translationUnitChanges;
  }

  @Action(RollbackTranslationUnitChanges)
  public rollbackTranslationUnitChanges(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: RollbackTranslationUnitChanges
  ): Observable<void> {
    const ids = action.translationUnitChanges.map((a) => a.id);
    const currentReportTranslationUnits = this.store.selectSnapshot(TranslationUnitChangesState.translationUnitChanges);
    const removedTranslationUnits = currentReportTranslationUnits.filter((a) => !ids.includes(a.id));
    ctx.dispatch(new RemoveTranslationUnitChanges(ids));
    return this.translationUnitChangeService
      .apiTranslationUnitChangesRollbackPost({
        body: {
          ids,
          reportId: action.reportId,
        },
      })
      .pipe(
        mergeMap(() => ctx.dispatch(new RollbackTranslationUnitChangesSuccess())),
        catchError((error) => {
          ctx.dispatch(new AddTranslationUnitChanges(removedTranslationUnits));
          return ctx.dispatch(new RollbackTranslationUnitChangesFail(error));
        })
      );
  }

  @Action(RemoveTranslationUnitChanges)
  public removeTranslationUnitChanges(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: RemoveTranslationUnitChanges
  ): void {
    const currentReportTranslationUnits = this.store.selectSnapshot(TranslationUnitChangesState.translationUnitChanges);
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    this.store.dispatch(new AddTranslationUnitIdSearchCandidate(action.ids));
    ctx.setState(
      patch({
        [projectId]: currentReportTranslationUnits.filter((a) => !action.ids.includes(a.id)),
      })
    );
  }

  @Action(RemoveTranslationUnitChangesByFileId)
  public removeTranslationUnitChangesByFileId(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: RemoveTranslationUnitChangesByFileId
  ): void {
    const predicate = (a: TranslationUnitChange): boolean => a.translationUnit.projectFileId === action.fileId;
    const currentReportTranslationUnits = this.store.selectSnapshot(TranslationUnitChangesState.translationUnitChanges);
    const translationUnitIds: string[] = currentReportTranslationUnits
      .filter(predicate)
      .map((tu) => tu.translationUnit.id);
    ctx.setState(
      patch({
        [action.projectId]: removeItems<TranslationUnitChange>(predicate),
      })
    );
    ctx.dispatch(new CommentThreadRemoveByTranslationUnitId(action.projectId, translationUnitIds));
  }

  @Action(LoadTranslationUnitChange)
  public loadTranslationUnitChange(
    ctx: StateContext<TranslationUnitChangesStateModel>,
    action: LoadTranslationUnitChange
  ): Observable<TranslationUnitChangeModel> {
    const isTranslationUnitAlreadyLoaded = this.store.selectSnapshot(
      TranslationUnitChangesState.translationUnitChange(action.id)
    );
    if (isTranslationUnitAlreadyLoaded) {
      return;
    }
    this.store.dispatch(new AddTranslationUnitIdSearchCandidate([action.id]));
    return this.translationUnitChangeService
      .apiTranslationUnitChangesIdGet$Json({
        id: action.id,
      })
      .pipe(
        tap((translationUnitChange) => {
          ctx.dispatch(new AddTranslationUnitChanges([translationUnitChange]));
        })
      );
  }

  @Action(ClearTranslationUniChanges)
  public clearTranslationUniChanges(
    ctx: StateContext<TranslationUnitChangesStateModel>
  ): TranslationUnitChangesStateModel {
    return ctx.setState({});
  }
}
