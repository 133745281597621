import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

interface Favicon {
  rel: string;
  type: string;
  sizes: string;
  href: string;
}

type Page = 'report' | 'settings';

const favIcons: { [key in Page]: Favicon[] } = {
  settings: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      sizes: '32x32',
      href: '/assets/favicons/default/favicon.ico',
    },
  ],
  report: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      sizes: '32x32',
      href: '/assets/favicons/report/favicon.ico',
    },
  ],
};

const iconDef = {
  rel: 'icon',
  type: 'image/png',
};
[16, 32, 48, 72, 96, 144, 192, 512].forEach((w: number) => {
  const sizes = w + 'x' + w;
  favIcons.settings.push({
    ...iconDef,
    sizes,
    href: '/assets/favicons/default/icon-' + sizes + '.png',
  });
  favIcons.report.push({
    ...iconDef,
    sizes,
    href: '/assets/favicons/report/icon-' + sizes + '.png',
  });
});

const APP_FAVICONS = favIcons;

@Injectable()
export class FaviconService {
  private renderer: Renderer2;
  constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(document.body, null);
  }

  public setReportFavicon(): void {
    this.recreateFavicons('report');
  }

  public setSettingsFavicon(): void {
    this.recreateFavicons('settings');
  }

  private recreateFavicons(page: Page): void {
    this.clearFavicons();
    const headNode = this.document.querySelector('head');
    APP_FAVICONS[page].forEach((i) => {
      this.appendIcon(headNode, i);
    });
  }

  private clearFavicons(): void {
    const icons = this.document.querySelectorAll("link[rel='icon']");
    icons.forEach((i) => i.remove());
  }

  private appendIcon(headNode: HTMLHeadElement, icon: Favicon): void {
    const link = this.renderer.createElement('link');
    link.type = icon.type;
    link.sizes = icon.sizes;
    link.rel = icon.rel;
    link.href = icon.href;
    this.renderer.appendChild(headNode, link);
  }
}
