export enum WorkerEventType {
  KeepAlive = 'keep alive',
  RefreshAccessToken = 'refresh access token',
  AnonymousSignup = 'anonymous signup',
  AuthInfoUpdated = 'auth info updated',
  LoggedOut = 'logged out',
  VerifyAuthInfo = 'verify auth info',
  TokenChanged = 'token changed',
  VerifyUserAlreadySingedIn = 'verify user already signed in',
}

export class RefreshAccessTokenAction {
  public readonly type = WorkerEventType.RefreshAccessToken;
}

export class AnonymousSignupAction {
  public readonly type = WorkerEventType.AnonymousSignup;
}

export class LoggedOutAction {
  public readonly type = WorkerEventType.LoggedOut;
}

export class AuthInfoUpdateAction {
  public readonly type = WorkerEventType.AuthInfoUpdated;
  constructor(public readonly userId: string) {}
}

export class KeepAliveAction {
  public readonly type = WorkerEventType.KeepAlive;
  constructor(public readonly id: ReturnType<typeof setTimeout>) {}
}

export class VerifyAuthInfoAction {
  public readonly type = WorkerEventType.VerifyAuthInfo;
  constructor(public readonly userId: string) {}
}

export class TokenChangedAction {
  public readonly type = WorkerEventType.TokenChanged;
  constructor(public readonly valid: boolean, public readonly force?: boolean) {}
}

export class VerifyUserAlreadySignedInAction {
  public readonly type = WorkerEventType.VerifyUserAlreadySingedIn;
  constructor(public readonly alreadySignedIn?: boolean) {}
}

export type WorkerAction =
  | KeepAliveAction
  | RefreshAccessTokenAction
  | AnonymousSignupAction
  | LoggedOutAction
  | AuthInfoUpdateAction
  | VerifyAuthInfoAction
  | TokenChangedAction
  | VerifyUserAlreadySignedInAction;
