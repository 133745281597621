import { Injectable } from '@angular/core';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { propComparator } from '@shared/tools';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { switchMap } from 'rxjs/operators';
import {
  LoadAvailableLanguages,
  LoadAvailableLanguagesIfNotExist,
  SetAvailableLanguages,
} from './available-languages.actions';
import { LanguageModel, LanguagesService } from '@generated/api';

const UnresolvedLanguageId = 127;

@AsyncStorage
@StateRepository()
@State<LanguageModel[]>({
  name: 'availableLanguages',
  defaults: [],
})
@Injectable()
export class AvailableLanguagesState {
  constructor(private languageService: LanguagesService) {}

  @Selector()
  static languagesIdMap(state?: LanguageModel[]): { [id: number]: LanguageModel } {
    return state?.reduce<{ [id: number]: LanguageModel }>((res, item) => {
      res[item.id] = { ...item };
      return res;
    }, {});
  }

  @Selector()
  static languagesCodeMap(state?: LanguageModel[]): { [code: string]: LanguageModel } {
    return state?.reduce<{ [code: string]: LanguageModel }>((res, item) => {
      res[item.code] = { ...item };
      return res;
    }, {});
  }

  static languageById(id: number) {
    return createSelector(
      [AvailableLanguagesState.languagesIdMap],
      (languages: { [id: number]: LanguageModel }) => languages[id]
    );
  }

  static languageByCode(code: string) {
    return createSelector(
      [AvailableLanguagesState.languagesCodeMap],
      (languages: { [code: string]: LanguageModel }) => languages[code]
    );
  }

  @Selector()
  static languages(state: LanguageModel[]): LanguageModel[] {
    return state || [];
  }

  @Action(LoadAvailableLanguages)
  public loadAvailableLanguages(ctx: StateContext<LanguageModel[]>) {
    return this.languageService
      .apiLanguagesGet$Json()
      .pipe(switchMap((res) => ctx.dispatch(new SetAvailableLanguages(res))));
  }

  @Action(SetAvailableLanguages)
  public setAvailableLanguages(ctx: StateContext<LanguageModel[]>, action: SetAvailableLanguages) {
    const filteredLanguages = action.languages.filter((item) => item.id !== UnresolvedLanguageId);
    const sortedLanguages = filteredLanguages.sort(propComparator('name'));

    return ctx.setState(sortedLanguages);
  }

  @Action(LoadAvailableLanguagesIfNotExist)
  public LoadAvailableLanguagesIfNotExist(ctx: StateContext<LanguageModel[]>) {
    if (ctx.getState()?.length) {
      return;
    }
    return ctx.dispatch(new LoadAvailableLanguages());
  }
}
