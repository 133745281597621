import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslationUnitModel } from '@generated/api';
import { QualityIssue } from '@shared/models';
import { Subject, takeUntil, debounceTime } from 'rxjs';
import {
  IDoesFilterPassParams,
  IFilterAngularComp,
  IFilterParams,
  RowNode,
  ValueGetterParams,
} from 'src/types/ag-grid';
import type { IAfterGuiAttachedParams } from '@ag-grid-community/core/dist/cjs/es5/interfaces/iAfterGuiAttachedParams';
import { getByPath } from '@shared/tools';

@Component({
  selector: 'app-filter-lock-protection',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFilterComponent implements IFilterAngularComp, OnDestroy {
  static rendererName = 'listFilterComponent';

  public searchText: FormControl = new FormControl('');
  public filterForm: FormGroup;
  public resetFilterText: string;
  public params: IFilterParams;
  public displayedFilterNames: string[] = [];

  private filterNames: string[] = [];
  private destroyed$: Subject<void> = new Subject<void>();
  private initialFilterValues: { [key: string]: boolean } = {};
  private prevFilterValues: { [key: string]: boolean } = {};
  private rowDataUpdateHandler: () => void;
  private hidePopup: () => void;

  get resetButtonText(): string {
    return this.params?.resetButtonText || 'Reset';
  }

  get applyButtonText(): string {
    return this.params?.applyButtonText || 'Apply';
  }

  get noFiltersText(): string {
    if (!this.displayedFilterNames.length && !this.filterNames.length) {
      return 'No rows';
    }
    if (!this.displayedFilterNames.length) {
      return 'No matches';
    }
  }

  get searchable(): boolean {
    return this.params?.searchable ?? true;
  }

  get allFiltersEnabled(): boolean {
    if (!this.filterForm) {
      return false;
    }
    const v = this.filterForm.value;
    return this.filterNames.every((key) => v[key]);
  }

  get someFilterActive(): boolean {
    const formValues: string[] = this.filterForm.value;
    const activeFiltersCount = Object.values(formValues).filter((v) => !!v).length;
    const allFiltersCount = Object.values(formValues).length;
    return allFiltersCount !== activeFiltersCount && activeFiltersCount > 0;
  }

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  public agInit(params: IFilterParams): void {
    this.params = params;
    this.rowDataUpdateHandler = (): void => {
      this.initFilterView();
      this.updateFilter();
    };

    this.params.api.addEventListener('rowDataUpdated', this.rowDataUpdateHandler);
    this.initFilterView();
  }

  private initFilterView(): void {
    this.buildFilterNames();
    this.initFilterForm();
    this.watchFormChanges();
    this.cdr.detectChanges();
  }

  private buildFilterNames(): void {
    if (!this.params) {
      return;
    }
    this.filterNames = this.params?.useFilterFromRows
      ? this.getUniqueColumnValue()
      : Object.keys(this.params?.filterValueAccessors || {});

    this.initDisplayedFilterNames();
  }

  private initDisplayedFilterNames(): void {
    this.displayedFilterNames = this.filterNames;
  }

  private getUniqueColumnValue(): string[] {
    const uniqueValues = new Set<string>();
    this.params.api.forEachNode((row) => {
      if (row.group) {
        return;
      }
      const value = this.getColValue(row);
      if (value) {
        uniqueValues.add(value);
      }
    });
    const uniqueRowValues = Array.from(uniqueValues);
    const sortComparator = this.params.comparator || this.params.colDef.comparator;
    if (sortComparator) {
      uniqueRowValues.sort(sortComparator);
    }
    return uniqueRowValues;
  }

  private getColValue(row: ValueGetterParams | IDoesFilterPassParams | RowNode): string {
    const { valueGetter } = this.params.colDef;

    if (!valueGetter && this.params.colDef.field) {
      return row.data[this.params.colDef.field];
    }

    if (!valueGetter) {
      return;
    }

    const value = typeof valueGetter === 'function' ? valueGetter(row) : getByPath(row, valueGetter);
    return value;
  }

  private initFilterForm(): void {
    this.initDefaultFilterValues();
    this.filterForm = this.fb.group(this.initialFilterValues);
  }

  private initDefaultFilterValues(): void {
    if (this.filterForm?.value && Object.keys(this.filterForm.value).length) {
      this.prevFilterValues = this.filterForm?.value;
    }
    this.initialFilterValues =
      this.params.defaultValue ||
      this.filterNames.reduce((acc, key) => ({ ...acc, [key]: this.prevFilterValues?.[key] ?? true }), {});
  }

  private watchFormChanges(): void {
    this.searchText.valueChanges.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe((v) => {
      if (v) {
        this.displayedFilterNames = this.filterNames.filter((name) => this.searchMatched(name));
      } else {
        this.initDisplayedFilterNames();
      }
      this.cdr.detectChanges();
    });

    this.filterForm?.valueChanges.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.params.autoFilterApply) {
        this.updateFilter();
      }
      this.cdr.detectChanges();
    });
  }

  public isFilterActive(): boolean {
    return !this.allFiltersEnabled;
  }

  public toggleAllFilters({ checked }: MatCheckboxChange): void {
    const newValue = Object.keys(this.filterForm.value).reduce((acc, key) => ({ ...acc, [key]: checked }), {});
    this.filterForm.patchValue(newValue);
  }

  public doesFilterPass(params: IDoesFilterPassParams<QualityIssue | TranslationUnitModel>): boolean {
    const filters = this.filterForm?.value;

    if (!filters) {
      return true;
    }

    if (this.params.filterPassFn) {
      return this.params.filterPassFn(params, filters as { [key: string]: boolean });
    }

    const filterPassed = this.filterNames.some((k) => {
      const accessor = this.params.filterValueAccessors
        ? (): boolean => this.params.filterValueAccessors[k]?.(params.node.data)
        : (): boolean => this.getColValue(params) === k;

      const value = accessor();
      return filters[k] && value;
    });
    return filterPassed;
  }

  public searchMatched(filterName: string): boolean {
    const searchText: string = this.searchText.value.toLowerCase();
    return !!filterName.toLowerCase().match(searchText);
  }

  public getModel(): { filterType: string; values: any[] } {
    if (this.allFiltersEnabled) {
      return null;
    }
    return {
      filterType: 'set',
      values: this.filterForm?.value,
    };
  }

  public setModel(filter?: { values: any[] }): void {
    if (!filter?.values) {
      return;
    }
    this.filterNames = Object.keys(filter.values);
    this.initDisplayedFilterNames();
    this.filterForm = this.fb.group(filter.values);
    this.cdr.detectChanges();
  }

  public updateFilter(): void {
    this.params.filterChangedCallback();
  }

  public resetFilter(): void {
    const allAcceptedValues = Object.keys(this.initialFilterValues).reduce((acc, key) => ({ ...acc, [key]: true }), {});
    this.filterForm.patchValue(allAcceptedValues);
    this.updateFilter();
    this.closeFilterPopup();
  }

  public afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.hidePopup = params.hidePopup;
  }

  public applyFilter(): void {
    this.updateFilter();
    this.closeFilterPopup();
  }

  private closeFilterPopup(): void {
    this.hidePopup?.();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    this.params.api.removeEventListener('rowDataUpdated', this.rowDataUpdateHandler);
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
