import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core';
import { RenderItem, RenderItemType, TagView } from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { TextNormalizationService } from '@report/services/text-normalization.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-segment-renderer',
  templateUrl: './segment-renderer.component.html',
  styleUrls: ['./segment-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentRendererComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly destroyed$: Subject<void> = new Subject<void>();
  readonly renderItemTypes = RenderItemType;
  public displayedRenderItems: RenderItem[] = [];
  public isTextOverflow = false;

  private _renderItems: RenderItem[] = [];

  @Input() set renderItems(items: RenderItem[]) {
    this._renderItems = items;
    this.displayRenderItems();
  }

  get renderItems(): RenderItem[] {
    return this._renderItems;
  }

  @Input() rtl = false;
  @Input() multiline = false;
  @Input() tagView = TagView.Medium;
  @Input() widthChanged$: Observable<boolean>;
  @Input() showInvisible = false;
  /*
   *  When error highlighting is disabled component will not style such items
   */
  @Input() disableErrorHighlight: boolean = false;

  @ViewChild('content', { static: false })
  private content: ElementRef<HTMLDivElement>;

  constructor(
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private textNormalizationService: TextNormalizationService
  ) {}

  ngOnInit() {
    this.displayRenderItems();
  }

  ngAfterViewInit() {
    if (!this.multiline) {
      this.checkAreEllipsisNeeds();
      this.widthChanged$?.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.checkAreEllipsisNeeds();
      });
    }
  }

  private displayRenderItems(): void {
    this.displayedRenderItems = this.replaceNonPrintableCharacters(this.renderItems);
  }

  private checkAreEllipsisNeeds(): void {
    setTimeout(() => {
      const didEllipsisExist = this.isTextOverflow;
      this.isTextOverflow = this.elRef.nativeElement.clientWidth < this.content.nativeElement.clientWidth || false;
      if (didEllipsisExist !== this.isTextOverflow) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  // TODO VW-1274: potential place for scroll performance boost
  private replaceNonPrintableCharacters(renderItems: RenderItem[]): RenderItem[] {
    return renderItems.map((i) => {
      if (i.type === RenderItemType.TAG) {
        return i;
      }

      const text = this.textNormalizationService.replaceNonPrintableCharacters(i.content.text);
      const content = { ...i.content, text };
      return { ...i, content };
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
