<ng-container *ngIf="!shortView">
  <div class="header">
    <div class="comment-owner" [appTooltipTruncated]="owner">{{ owner }}</div>
    <div class="actions" (click)="$event.stopPropagation()">
      <ng-content select="[header-action]"></ng-content>

      <app-ui-dropdown-menu>
        <app-action-icon
          dropdown-trigger
          *ngIf="enableActions"
          theme="subtle"
          icon="more-vert"
          size="md"
        ></app-action-icon>

        <div dropdown-content>
          <ng-content select="[actions]"></ng-content>
          <div *ngIf="editable" class="list-item" (click)="editComment()" role="button">
            <app-ui-icon class="list-item__icon" icon="edit" size="normal"> </app-ui-icon>
            <span>Edit</span>
          </div>
          <div (click)="deleteComment()" class="list-item list-item--danger" role="button">
            <app-ui-icon class="list-item__icon" icon="delete-outline" size="normal"> </app-ui-icon>
            <span>Delete</span>
          </div>
        </div>
      </app-ui-dropdown-menu>
    </div>
  </div>
  <div class="comment-created-time">{{ createdOn | prettyDateTime }}</div>
</ng-container>

<div class="comment-meta">
  <ng-content select="[meta]"></ng-content>
</div>

<div class="body">
  <ng-container *ngIf="editableText !== null; else visualModeTemplate">
    <textarea
      (click)="$event.stopPropagation()"
      (keydown.enter)="$event.stopPropagation(); saveComment()"
      (keydown)="$event.stopPropagation()"
      [(ngModel)]="editableText"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      class="comment-editor"
    ></textarea>
    <div class="editor-footer">
      <app-button class="save-btn" size="sm" (click)="saveComment($event)" theme="primary">Save</app-button>
      <app-button class="cancel-btn" size="sm" (click)="stopEditing($event)" theme="outlined">Cancel</app-button>
    </div>
  </ng-container>
  <ng-template #visualModeTemplate
    >{{ message }}<span *ngIf="modified" class="comment-modified"> (edited)</span>
  </ng-template>
</div>
