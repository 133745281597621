import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TagColorTypes } from '@shared/components/tag/tag-color-types';
import { SelectionRange, TagType, TagView } from '@shared/models';
import { HighligthService } from '@shared/services';
import { TooltipTriggerDirective } from '@shared/components/tooltip';

/**
 * Tag component, tags can have one from 3 size type:
 *
 * @example <caption>Short tag</caption>
 *  // Text will not render
 * `<vr-tag text="hello world" [tagView]="TagView.Short"></vr-tag>`
 *
 * @example <caption>Medium tag</caption>
 * // shortText in medium sized component will be rendered till first space
 * `<vr-tag text="hello world" shortText="hello" [tagView]="TagView.Short"></vr-tag>`
 *
 * // text prop will be rendered without any modifications.
 * @example <caption>Long tag</caption>
 * `vr-tag text="hello world" [tagView]="TagView.Long"></vr-tag>`
 *
 * // tag also can be opened, closed and standalone (see storybook for visual example and TagView enum)
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vr-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit, OnDestroy {
  private readonly defaultTagView = TagView.Medium;
  private _withoutPair = false;
  private _tagView: TagView = this.defaultTagView;
  private _tagType: TagType = TagType.Start;
  private _tagNumber: number;
  private _shortText: string;
  private _selectionRanges: SelectionRange[] = [];
  private _text = '';
  private _fullTagHighlighted = false;

  @Input()
  set selectionRanges(value: SelectionRange[]) {
    this._selectionRanges = value;
  }

  @Input()
  set tagNumber(value: number) {
    this._tagNumber = value;
  }

  /*
   *  Autofixable tags have bottom line highlighting
   */
  @Input()
  isAutofixable: boolean;

  get tagNumber(): number {
    return this._tagNumber;
  }

  /**
   * this only works for 'Start' and 'End' tags;
   */
  @Input()
  set withoutPair(value: boolean) {
    this._withoutPair = value;
    this.setColorClassName();
  }

  get withoutPair(): boolean {
    return this._withoutPair;
  }

  /**
   * this only works for Long tags';
   */
  @Input()
  set fullTagHighlighted(value: boolean) {
    this._fullTagHighlighted = value;
    this.setColorClassName();
  }

  get fullTagHighlighted(): boolean {
    return this._fullTagHighlighted;
  }

  @Input() set shortText(value: string) {
    this._shortText = value;
    this.prepareDisplayedText();
  }

  @Input()
  set tagType(value: TagType) {
    this._tagType = value;
    this.setPositionClassName();
  }

  @Input()
  set tagView(tagView: TagView) {
    this._tagView = tagView || this.defaultTagView;
    this.setViewClassName(this._tagView);
    this.setColorClassName();
    this.prepareDisplayedText();
  }
  get tagView(): TagView {
    return this._tagView;
  }

  get hasSelectionRanges(): boolean {
    return !!this._selectionRanges?.length;
  }

  @Input() set text(value: string) {
    this._text = value;
    this.prepareDisplayedText();
  }

  get text(): string {
    return this._text;
  }

  @Input() selected = false;
  @Input() drag: boolean = false;
  @Input() draggable: boolean = false;
  @Input() rtl: boolean = false;

  @HostBinding('style.height') containerHeight = '22px';
  @ViewChild(TooltipTriggerDirective, { static: true }) tooltip: TooltipTriggerDirective;
  @ViewChild('dragPreview') dragPreview: ElementRef<HTMLSpanElement>;

  viewClassName: string;
  positionClassName: string;
  colorClassName: string;
  displayedText: string;
  TagView = TagView;

  constructor(private highligthService: HighligthService, public cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setPositionClassName();
    this.setColorClassName();
  }

  public deselectTag(): void {
    this.selectionRanges = [];
    this.isAutofixable = false;
    this.fullTagHighlighted = false;
    this.cdr.detectChanges();
  }

  private setViewClassName(tagView: TagView): void {
    const sizeClasses = {
      [TagView.Long]: 'long',
      [TagView.Medium]: 'medium',
      [TagView.Short]: 'short',
    };
    this.viewClassName = sizeClasses[tagView];
  }

  private setPositionClassName(): void {
    switch (this._tagType) {
      case TagType.Start: {
        this.positionClassName = this.rtl ? 'left-arrow' : 'right-arrow';
        break;
      }
      case TagType.End: {
        this.positionClassName = this.rtl ? 'right-arrow' : 'left-arrow';
        break;
      }
      default: {
        this.positionClassName = 'without-arrow';
      }
    }
  }

  private setColorClassName(): void {
    const tagClasses = {
      [TagColorTypes.Default]: 'color-default',
      [TagColorTypes.WithoutPair]: 'color-without-pair',
      [TagColorTypes.WithErrors]: 'color-with-errors',
      [TagColorTypes.WithAutofix]: 'color-with-autofix',
    };
    if (this.isAutofixable) {
      this.colorClassName = tagClasses[TagColorTypes.WithAutofix];
      return;
    }
    if (
      (this._tagView !== TagView.Long && this.hasSelectionRanges) ||
      (this._tagView === TagView.Long && this._fullTagHighlighted)
    ) {
      this.colorClassName = tagClasses[TagColorTypes.WithErrors];
    } else if (this._withoutPair) {
      this.colorClassName = tagClasses[TagColorTypes.WithoutPair];
    } else {
      this.colorClassName = tagClasses[TagColorTypes.Default];
    }
  }

  private prepareDisplayedText(): void {
    switch (this._tagView) {
      case TagView.Short: {
        this.displayedText = '';
        break;
      }
      case TagView.Medium: {
        const split = (this._shortText || '').split(' ');
        this.displayedText = split.length > 0 ? split[0] : '';
        break;
      }
      case TagView.Long: {
        this.displayedText = this.highligthService.highlight(this._text, this._selectionRanges);
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeTooltip();
  }

  private closeTooltip(): void {
    this.tooltip?.hide();
  }

  public dragstart(event: DragEvent): void {
    if (!this.draggable) {
      return;
    }
    this.drag = true;
    this.closeTooltip();
    event.dataTransfer.effectAllowed = 'move';
    this.dragPreview.nativeElement.style.display = 'block';
    event.dataTransfer.setDragImage(this.dragPreview.nativeElement, 0, 0);
  }

  public dragend(): void {
    if (!this.draggable) {
      return;
    }
    this.drag = false;
    this.dragPreview.nativeElement.style.display = 'none';
  }
}
