import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '@shared/components/tooltip';
import { CollectionComponent } from '@shared/components/collection/collection.component';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TooltipModule, ActionIconModule],
  declarations: [CollectionComponent],
  exports: [CollectionComponent],
})
export class CollectionModule {}
