/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommentModel } from '../models/comment-model';
import { CreateCommentRequest } from '../models/create-comment-request';
import { ResolveCommentThreadRequest } from '../models/resolve-comment-thread-request';
import { RestoreCommentThreadRequest } from '../models/restore-comment-thread-request';
import { ThreadWithCommentsModel } from '../models/thread-with-comments-model';
import { UpdateCommentRequest } from '../models/update-comment-request';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCommentsGet
   */
  static readonly ApiCommentsGetPath = '/api/Comments';

  /**
   * Gets comment threads on a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsGet$Plain$Response(params?: {

    /**
     * Project identifier
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<ThreadWithCommentsModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThreadWithCommentsModel>>;
      })
    );
  }

  /**
   * Gets comment threads on a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsGet$Plain(params?: {

    /**
     * Project identifier
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<Array<ThreadWithCommentsModel>> {

    return this.apiCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ThreadWithCommentsModel>>) => r.body as Array<ThreadWithCommentsModel>)
    );
  }

  /**
   * Gets comment threads on a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsGet$Json$Response(params?: {

    /**
     * Project identifier
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<ThreadWithCommentsModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThreadWithCommentsModel>>;
      })
    );
  }

  /**
   * Gets comment threads on a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsGet$Json(params?: {

    /**
     * Project identifier
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<Array<ThreadWithCommentsModel>> {

    return this.apiCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ThreadWithCommentsModel>>) => r.body as Array<ThreadWithCommentsModel>)
    );
  }

  /**
   * Path part for operation apiCommentsPost
   */
  static readonly ApiCommentsPostPath = '/api/Comments';

  /**
   * Creates a new comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.CreateCommentRequest
     */
    body?: CreateCommentRequest
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Creates a new comment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.CreateCommentRequest
     */
    body?: CreateCommentRequest
  }): Observable<CommentModel> {

    return this.apiCommentsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Creates a new comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.CreateCommentRequest
     */
    body?: CreateCommentRequest
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Creates a new comment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsPost$Json(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.CreateCommentRequest
     */
    body?: CreateCommentRequest
  }): Observable<CommentModel> {

    return this.apiCommentsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Path part for operation apiCommentsIdGet
   */
  static readonly ApiCommentsIdGetPath = '/api/Comments/{id}';

  /**
   * Gets a comment or thread with comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdGet$Plain$Response(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Gets a comment or thread with comments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdGet$Plain(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<CommentModel> {

    return this.apiCommentsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Gets a comment or thread with comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdGet$Json$Response(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Gets a comment or thread with comments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdGet$Json(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<CommentModel> {

    return this.apiCommentsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Path part for operation apiCommentsIdPut
   */
  static readonly ApiCommentsIdPutPath = '/api/Comments/{id}';

  /**
   * Updates a specific comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsIdPut$Plain$Response(params: {

    /**
     * Comment identifier
     */
    id: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.UpdateCommentRequest
     */
    body?: UpdateCommentRequest
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Updates a specific comment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsIdPut$Plain(params: {

    /**
     * Comment identifier
     */
    id: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.UpdateCommentRequest
     */
    body?: UpdateCommentRequest
  }): Observable<CommentModel> {

    return this.apiCommentsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Updates a specific comment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsIdPut$Json$Response(params: {

    /**
     * Comment identifier
     */
    id: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.UpdateCommentRequest
     */
    body?: UpdateCommentRequest
  }): Observable<StrictHttpResponse<CommentModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentModel>;
      })
    );
  }

  /**
   * Updates a specific comment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsIdPut$Json(params: {

    /**
     * Comment identifier
     */
    id: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.UpdateCommentRequest
     */
    body?: UpdateCommentRequest
  }): Observable<CommentModel> {

    return this.apiCommentsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentModel>) => r.body as CommentModel)
    );
  }

  /**
   * Path part for operation apiCommentsIdDelete
   */
  static readonly ApiCommentsIdDeletePath = '/api/Comments/{id}';

  /**
   * Deletes a specific comment or thread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdDelete$Response(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a specific comment or thread.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommentsIdDelete(params: {

    /**
     * Comment or thread identifier
     */
    id: string;
    'api-version'?: string;
  }): Observable<void> {

    return this.apiCommentsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCommentsThreadIdResolvePost
   */
  static readonly ApiCommentsThreadIdResolvePostPath = '/api/Comments/{threadId}/resolve';

  /**
   * Changes the resolving status of a comment thread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsThreadIdResolvePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsThreadIdResolvePost$Response(params: {

    /**
     * Comment thread identifier
     */
    threadId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.ResolveCommentThreadRequest
     */
    body?: ResolveCommentThreadRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsThreadIdResolvePostPath, 'post');
    if (params) {
      rb.path('threadId', params.threadId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changes the resolving status of a comment thread.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsThreadIdResolvePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsThreadIdResolvePost(params: {

    /**
     * Comment thread identifier
     */
    threadId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.ResolveCommentThreadRequest
     */
    body?: ResolveCommentThreadRequest
  }): Observable<void> {

    return this.apiCommentsThreadIdResolvePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCommentsThreadIdRestorePost
   */
  static readonly ApiCommentsThreadIdRestorePostPath = '/api/Comments/{threadId}/restore';

  /**
   * Restores a deleted comment thread or comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommentsThreadIdRestorePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsThreadIdRestorePost$Response(params: {

    /**
     * Comment thread identifier
     */
    threadId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.ResolveCommentThreadRequest
     */
    body?: RestoreCommentThreadRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.ApiCommentsThreadIdRestorePostPath, 'post');
    if (params) {
      rb.path('threadId', params.threadId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restores a deleted comment thread or comments.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCommentsThreadIdRestorePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCommentsThreadIdRestorePost(params: {

    /**
     * Comment thread identifier
     */
    threadId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Comments.ResolveCommentThreadRequest
     */
    body?: RestoreCommentThreadRequest
  }): Observable<void> {

    return this.apiCommentsThreadIdRestorePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
