import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-comment-cell-renderer',
  template: '<app-ui-icon *ngIf="show" icon="chat" size="small" color="secondary"> </app-ui-icon> ',
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentCellRendererComponent implements ICellRendererAngularComp {
  static rendererName = 'commentCellRenderer';

  public show: boolean;

  public agInit(params: ICellRendererParams | IHeaderParams) {
    const isHeader = !!(params as IHeaderParams)?.displayName;
    this.show = (params as ICellRendererParams).value || isHeader;
  }

  public refresh(): boolean {
    return false;
  }
}
