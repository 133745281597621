import { ProfileReferenceDataModel } from '@generated/api';

enum ActionTypes {
  LOAD_REFERENCES = '[References] Load references',
  SET_REFERENCES = '[References] Set references',
  LOAD_REFERENCES_IF_NOT_EXIST = '[References] Load if not exist',
}

export class LoadReferences {
  static readonly type = ActionTypes.LOAD_REFERENCES;
}

export class SetReferences {
  static readonly type = ActionTypes.SET_REFERENCES;
  constructor(public references: ProfileReferenceDataModel) {}
}

export class LoadReferencesIfNotExist {
  static readonly type = ActionTypes.LOAD_REFERENCES_IF_NOT_EXIST;
}
