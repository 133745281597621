import { CreateTermRequest, LanguageModel, TermModel, UpdateTermRequest } from '@generated/api';

enum ActionTypes {
  SELECT_SOURCE_LANGUAGE = '[GlossaryList] Select Source Language',
  SELECT_TARGET_LANGUAGE = '[GlossaryList] Select Target Language',
  RESET_SOURCE_TARGET_LANGUAGES = '[GlossaryList] Reset source and target languages',
  SET_DEFAULT_SOURCE_TARGET_LANGUAGES = '[GlossaryList] Set Default Source Target Languages',
  ADD_TERM = '[GlossaryList] Add new Term to Terms List',
  REMOVE_TERMS = '[GlossaryList] Remove multiple Terms from Terms List',
  CHANGE_TERM = '[GlossaryList] Change Term in Terms List',
  UPDATE_TERM = '[GlossaryList] Patch Term in Terms List',
  LOAD_TERMS = '[GlossaryList] Load Terms List',
  TERM_REMOVED = '[GlossaryList] Term Removed',
  TERM_UPDATED = '[GlossaryList] Term Updated',
  CHANGE_BULK_SETTINGS = '[GlossaryList] Change Bulk Settings',
}

export class SelectSourceLanguage {
  static readonly type = ActionTypes.SELECT_SOURCE_LANGUAGE;

  constructor(public language: LanguageModel) {}
}

export class SelectTargetLanguage {
  static readonly type = ActionTypes.SELECT_TARGET_LANGUAGE;

  constructor(public language: LanguageModel) {}
}

export class SetDefaultSourceTargetLanguages {
  static readonly type = ActionTypes.SET_DEFAULT_SOURCE_TARGET_LANGUAGES;
  constructor(public sourceLanguages: LanguageModel[], public targetLanguages: LanguageModel[]) {}
}

export class ResetSourceTargetLanguages {
  static readonly type = ActionTypes.RESET_SOURCE_TARGET_LANGUAGES;
}

export class AddTerm {
  static readonly type = ActionTypes.ADD_TERM;

  constructor(public termbaseId: string, public term: CreateTermRequest) {}
}

export class ChangeTerm {
  static readonly type = ActionTypes.CHANGE_TERM;

  constructor(public term: TermModel) {}
}

export class UpdateTerm {
  static readonly type = ActionTypes.UPDATE_TERM;

  constructor(public termbaseId: string, public termId: string, public updatedTerm: UpdateTermRequest) {}
}

export class RemoveTerms {
  static readonly type = ActionTypes.REMOVE_TERMS;
  constructor(public termbaseId: string, public terms: TermModel[]) {}
}

export class LoadTerms {
  static readonly type = ActionTypes.LOAD_TERMS;
  constructor(public termbaseId: string, public sourceLanguageId: number, public targetLanguageId: number) {}
}

export class TermRemoved {
  static readonly type = ActionTypes.TERM_REMOVED;
  constructor(public term: TermModel) {}
}

export class TermUpdated {
  static readonly type = ActionTypes.TERM_UPDATED;
  constructor(public term: TermModel) {}
}

export class ChangeBulkSettings {
  static readonly type = ActionTypes.CHANGE_BULK_SETTINGS;
  constructor(
    public termbaseId: string,
    public sourceLanguageId: number,
    public targetLanguageId: number,
    public term: string,
    public options: { [key: string]: any }
  ) {}
}
