export enum TableKeybindingEvent {
  PREVIOUS_QUALITY_ISSUE = 'Previous qualitty issue',
  NEXT_QUALITY_ISSUE = 'Next quality issue',
  COLLAPSE_ALL_GROUPS = 'Collapse all groups',
  COLLAPSE_GROUP = 'Collapse group',
  EXPAND_GROUP = 'Expand group',
  EXPAND_ALL_GROUPS = 'Expand all groups',
  IGNORE_ISSUE = 'Ignore QA issue',
  IGNORE_GROUP = 'Ignore QA group',
  APPLY_AUTOFIX_TO_GROUP = 'Apply autofix to group',
  ENTER_SEGMENT_EDIT_MODE = 'Enter segment edit mode',
  EXIT = 'Exit',
}

export enum EditorKeybindingEvent {
  CONFIRM_EDIT = 'Confirm edit',
  OPEN_AUTOFIX_MENU = 'Open autofix menu',
  SELECT_NEXT_AUTOFIX_OPTION = 'Select next autofix option',
  SELECT_PREVIOUS_AUTOFIX_OPTION = 'Select previous autofix option',
  APPLY_AUTOFIX = 'Apply autofix',
  QUICK_TAG_INSERT = 'Editor quick tag insert',
  OPEN_SPECIAL_SYMBOLS_MENU = 'Open special symbols menu',
  EXIT = 'Exit',
}

export enum ControlPanelKeybindingEvent {
  DISPLAY_NON_PRINTING_CHARACTERS = 'Display non printing characters',
  SWITCH_TAGS_DISPLAY = 'Switch tags display',
  ADD_COMMENT = 'Add comment',
  VIEW_SEGMENT_IN_CONTEXT = 'View segment in context',
  EXPORT_QA_REPORT_TO_EXCEL = 'Export QA report to Excel',
  COPY_LINK_TO_REPORT = 'Copy link to report',
}

export type KeybindingEvent = TableKeybindingEvent | EditorKeybindingEvent | ControlPanelKeybindingEvent;

export interface Shortcut {
  key: string[] | string;
  /*
   *  All keybinding includes number prefix (1-9)
   */
  numbers?: boolean;
  event: KeybindingEvent;
  suppressVisualReference?: boolean;
}

export interface ShortcutsReference {
  description: string;
  suppressVisualReference?: boolean;
  shortcuts: Shortcut[];
  children?: ShortcutsReference[][];
}

export enum KeybindingGroups {
  TABLE = 'Report table',
  EDITOR = 'Target segment editor',
  CONTROL_PANEL = 'Control panel',
}

export type GroupedShortcutsReference = { [key: string]: ShortcutsReference[][] };
