import { ICellEditorParams } from '@ag-grid-community/core';
import { AgGridColumn, GridColumnParams, EditableCallbackParams } from 'src/types/ag-grid';

import {
  PreparedTranslationUnit,
  RenderItem,
  TranslationUnitProperties,
  UniversalSelectionRange,
} from '@shared/models';
import { COMMENT_COLUMN } from '@report/modules/base/comment-column';
import { gridCellRenderer, gridMatchRenderer } from '@shared/tools';
import { getMatchForRow, getMatchForSorting } from '@report/utils';
import { LanguageModel, Segment, SegmentElement } from '@generated/api';
import { LockProtectionCellRendererComponent } from '@report/components/report-grid/components/lock-protection-cell-renderer/lock-protection-cell-renderer.component';
import { ListFilterComponent } from '@shared/components/grid-filters';
import { protectedFilterValueAccessors } from '@report/modules/base/protection-status-column';

interface ColumnConfigs {
  hideProtected?: boolean;
  editable?: boolean;
  suppressProtectedEditing?: boolean;
  sourceLanguage: LanguageModel;
  targetLanguage: LanguageModel;
  renderItemBuilderFn: (selectionRange: UniversalSelectionRange[], elements: SegmentElement[]) => RenderItem[];
}

// TODO: VW-1283 - унаследоваться от BaseColumns
export const buildContextViewerGridColumns = ({
  hideProtected,
  sourceLanguage,
  editable,
  suppressProtectedEditing,
  targetLanguage,
  renderItemBuilderFn,
}: ColumnConfigs): Partial<AgGridColumn>[] => [
  {
    colId: 'isProtected',
    headerName: '',
    hide: hideProtected,
    field: 'isProtected',
    filter: ListFilterComponent.rendererName,
    filterParams: {
      filterValueAccessors: protectedFilterValueAccessors,
      resetable: true,
      searchable: false,
      autoFilterApply: true,
    },
    cellRenderer: LockProtectionCellRendererComponent.rendererName,
    sortable: true,
    lockPosition: 'left',
    suppressMovable: true,
    resizable: false,
    cellClass: ['report-protected-cell', 'cell-d-flex-center'],
    headerComponentParams: {
      iconGetter: () => ({ name: 'shield-bordered', color: 'primary' }),
    },
    width: 38,
  },
  {
    colId: 'sourceSegment',
    headerName: sourceLanguage?.name || 'Source segment',
    resizable: true,
    suppressMovable: true,
    cellRenderer: 'segmentCellRenderer',
    width: hideProtected ? 319 : 303,
    wrapText: true,
    autoHeight: true,
    field: 'source',
    cellEditor: 'segmentEditorComponent',
    editable: (params: EditableCallbackParams<PreparedTranslationUnit>) =>
      editable && !(params.data.isProtected && suppressProtectedEditing),
    cellClass: ['segment-cell', 'ag-cell--target-segment', 'cell-value-large'],
    cellEditorParams: {
      isTarget: false,
      originalSegmentGetter: (params: ICellEditorParams) => params.data.source,
      rightToLeftGetter: () => sourceLanguage?.rightToLeft || false,
    },
    valueGetter: (params: GridColumnParams<PreparedTranslationUnit>) => params.data?.sourceRenderItems,
    cellRendererParams: {
      rightToLeftGetter: () => sourceLanguage?.rightToLeft || false,
    },
  },
  {
    colId: 'targetSegment',
    headerName: targetLanguage?.name || 'Target segment',
    field: 'target',
    valueSetter: (params: any): boolean => {
      if (params.data.target?.text === params.newValue?.text) {
        return false;
      }
      const newTargetSegment: Segment = params.newValue;
      params.data.target = newTargetSegment;
      params.data.targetRenderItems = renderItemBuilderFn([], newTargetSegment.elements);
      return true;
    },
    cellRenderer: 'segmentCellRenderer',
    cellEditor: 'segmentEditorComponent',
    editable: (params: EditableCallbackParams<PreparedTranslationUnit>) =>
      editable && !(params.data.isProtected && suppressProtectedEditing),
    resizable: true,
    suppressMovable: true,
    wrapText: true,
    autoHeight: true,
    width: hideProtected ? 319 : 303,
    cellClass: ['segment-cell', 'ag-cell--target-segment', 'cell-value-large'],
    cellEditorParams: {
      isTarget: true,
      originalSegmentGetter: (params: ICellEditorParams) => params.data.target,
      rightToLeftGetter: () => targetLanguage?.rightToLeft || false,
    },
    valueGetter: (params: GridColumnParams<PreparedTranslationUnit>) => params.data?.targetRenderItems,
    cellRendererParams: {
      rightToLeftGetter: () => targetLanguage?.rightToLeft || false,
    },
  },
  {
    ...COMMENT_COLUMN,
    valueGetter: (params: GridColumnParams<PreparedTranslationUnit>) => params.data?.hasComments,
  },
  {
    colId: 'position',
    headerName: 'Position',
    field: 'position',
    filter: ListFilterComponent.rendererName,
    filterParams: {
      useFilterFromRows: true,
      resetable: true,
      autoFilterApply: true,
    },
    sortable: true,
    resizable: true,
    comparator: (a: string, b: string): number => parseInt(a, 10) - parseInt(b, 10),
    width: 80,
    minWidth: 80,
    valueGetter: (params: GridColumnParams<PreparedTranslationUnit>) => params.data?.index + 1,
  },
  {
    colId: 'segmentStatus',
    headerName: 'Segment status',
    valueGetter: 'data?.properties?.status',
    sortable: true,
    filter: ListFilterComponent.rendererName,
    filterParams: {
      autoFilterApply: true,
      useFilterFromRows: true,
      resetable: true,
    },
    resizable: true,
    cellRenderer: gridCellRenderer,
    width: 80,
    minWidth: 80,
  },
  {
    colId: 'match',
    headerName: 'Match',
    valueGetter: (params: { data: { properties: TranslationUnitProperties } }) =>
      getMatchForRow(params?.data?.properties),
    headerClass: 'match-col-header',
    filter: ListFilterComponent.rendererName,
    filterParams: {
      autoFilterApply: true,
      useFilterFromRows: true,
      resetable: true,
    },
    sortable: true,
    resizable: true,
    comparator: (valueA: string, valueB: string) => getMatchForSorting(valueA) - getMatchForSorting(valueB),
    cellRenderer: gridMatchRenderer,
    width: 80,
    minWidth: 80,
  },
];
