<ng-select
  class="ng-select"
  [tabIndex]="tabIndex"
  [ngModel]="value"
  (ngModelChange)="valueChanged($event)"
  [searchable]="searchable"
  [placeholder]="placeholder"
  [bindLabel]="labelKey"
  [compareWith]="compareWith"
  [bindValue]="valueKey"
  [groupBy]="groupBy"
  [items]="displayedOptions"
  [multiple]="false"
  [disabled]="disabled"
  [addTag]="false"
  [clearable]="false"
  [appendTo]="appendTo"
>
  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-disabled not-found">No matches</div>
  </ng-template>
  <ng-template let-item="item" let-index="index" ng-option-tmp>
    <ng-container
      [ngTemplateOutlet]="optionTemplate ? optionTemplate : defaultOptionTemplate"
      [ngTemplateOutletContext]="{ item: item, index: index }"
    ></ng-container>
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ng-container
      [ngTemplateOutlet]="labelTemplate ? labelTemplate : defaultLabelTemplate"
      [ngTemplateOutletContext]="{ item: item, clear: clear }"
    ></ng-container>
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item">
    <div *ngIf="groupLabel(item)" class="ng-optgroup-label">{{ groupLabel(item) }}</div>
  </ng-template>
</ng-select>

<ng-template #defaultOptionTemplate let-item="item">
  <div [innerHTML]="labelGetter(item) | safe: 'html'"></div>
</ng-template>

<ng-template #defaultLabelTemplate ng-label-tmp let-item="item">
  <span class="ng-value-label" [innerHTML]="labelGetter(item) | safe: 'html'"></span>
</ng-template>
