import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, createSelector, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  InitSearchInReportFiles,
  RemoveFromSearchResultList,
  SearchInReportFiles,
  SearchInReportFilesFail,
  SearchInReportFilesSuccess,
  SetSearchInReportFilesFilter,
  SetSearchInReportFilesLoader,
  SetSearchResultList,
  UpdateSearchResultList,
  UpdateTranslationUnits,
  UpdateSearchResultCommentStatus,
  RemoveSearchResultByByFileId,
} from '@store/search-in-report-files/search-in-report-files.actions';
import { SearchInReportFilesFilter, SearchInReportFilesResultItem } from '@shared/models/search-in-report-files';
import { ProjectsState, ProjectState, ReportState } from '@store';
import { patch, removeItem } from '@ngxs/store/operators';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { SearchInProjectFilesResultItemMapper } from '@shared/mappers';
import { RenderItemBuilder } from '@shared/services';
import {
  AddTranslationUnitIdSearchCandidate,
  ResetTranslationUnitIdsSearchCandidates,
} from './search-in-report-files.actions';
import { QualityIssuesService, SearchMode, TranslationUnitsService } from '@generated/api';
import { Observable } from 'rxjs';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

export interface SearchInReportFilesStateModel {
  [projectId: string]: {
    searchResultItems: SearchInReportFilesResultItem[];
    filter: SearchInReportFilesFilter;
    translationUnitIdsSearchBuffer: string[];
    loader: boolean;
  };
}

@AsyncStorage
@StateRepository()
@State<SearchInReportFilesStateModel>({
  name: 'searchInReportFiles',
  defaults: {},
})
@Injectable()
export class SearchInReportFilesState implements NgxsAfterBootstrap {
  constructor(
    private store: Store,
    private searchService: TranslationUnitsService,
    private qualityIssuesService: QualityIssuesService,
    private mapper: SearchInProjectFilesResultItemMapper,
    private renderItemBuilder: RenderItemBuilder
  ) {}

  @Selector([SearchInReportFilesState, ProjectState.projectId])
  public static searchResult(state: SearchInReportFilesStateModel, projectId: string): SearchInReportFilesResultItem[] {
    return (state && state[projectId].searchResultItems) || [];
  }

  @Selector([SearchInReportFilesState.searchResult])
  public static count(state: SearchInReportFilesResultItem[]): number {
    return state?.length || 0;
  }

  @Selector([SearchInReportFilesState, ProjectState.projectId])
  public static filter(state: SearchInReportFilesStateModel, projectId: string): SearchInReportFilesFilter {
    return state[projectId]?.filter;
  }

  @Selector([SearchInReportFilesState, ProjectState.projectId])
  public static loader(state: SearchInReportFilesStateModel, projectId: string): boolean {
    return state[projectId]?.loader;
  }

  @Selector([SearchInReportFilesState, ProjectState.projectId])
  public static searchTranslationUnitIdsCandidates(state: SearchInReportFilesStateModel, projectId: string): string[] {
    return state[projectId]?.translationUnitIdsSearchBuffer || [];
  }

  public static getById(
    translationUnitId: string
  ): (state: SearchInReportFilesResultItem[]) => SearchInReportFilesResultItem {
    return createSelector([SearchInReportFilesState.searchResult], (state: SearchInReportFilesResultItem[]) =>
      state.find((searchResultItem) => searchResultItem.translationUnit.id === translationUnitId)
    );
  }

  ngxsAfterBootstrap(ctx: StateContext<SearchInReportFilesStateModel>): void {
    this.cleanStorage(ctx);
  }

  private cleanStorage(ctx: StateContext<SearchInReportFilesStateModel>): void {
    const currentStoredProjects = this.store.selectSnapshot(ProjectsState.storedProjects);
    const state = ctx.getState();
    const newState = currentStoredProjects.reduce<SearchInReportFilesStateModel>((acc, project) => {
      acc[project.id] = state[project.id];
      return acc;
    }, {});
    ctx.setState(newState);
  }

  @Action(InitSearchInReportFiles)
  public initSearchInReportFiles(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: InitSearchInReportFiles
  ): void {
    const projectState = ctx.getState()[action.projectId] || {
      searchResultItems: [],
      filter: {
        searchMode: SearchMode.SourceAndTarget,
        sourceOptions: null,
        targetOptions: null,
      },
      loader: false,
    };
    ctx.setState(
      patch({
        [action.projectId]: projectState,
      })
    );
  }

  @Action(SetSearchInReportFilesLoader)
  public setSearchInReportFilesLoader(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: SetSearchInReportFilesLoader
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          loader: action.loader,
        }),
      })
    );
  }

  @Action(SetSearchInReportFilesFilter)
  public setSearchInReportFilesFilter(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: SetSearchInReportFilesFilter
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          filter: patch({ ...action.filter }),
        }),
      })
    );
  }

  @Action(SetSearchResultList)
  public setSearchResultList(ctx: StateContext<SearchInReportFilesStateModel>, action: SetSearchResultList): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          searchResultItems: action.list,
        }),
      })
    );
  }

  @Action(RemoveFromSearchResultList)
  public removeFromSearchResultList(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: RemoveFromSearchResultList
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const idsMap: { [id: string]: boolean } = action.ids.reduce((map, id) => {
      map[id] = true;
      return map;
    }, {});
    ctx.setState(
      patch({
        [projectId]: patch({
          searchResultItems: removeItem<SearchInReportFilesResultItem>((item) => idsMap[item.id]),
        }),
      })
    );
  }

  @Action(RemoveSearchResultByByFileId)
  public removeByFileId(ctx: StateContext<SearchInReportFilesStateModel>, action: RemoveSearchResultByByFileId): void {
    const resultItems = this.store.selectSnapshot(SearchInReportFilesState.searchResult);
    const removeCandidateIds = resultItems
      .filter((item) => item.translationUnit.projectFileId === action.fileId)
      .map((item) => item.id);
    if (removeCandidateIds.length) {
      ctx.dispatch(new RemoveFromSearchResultList(removeCandidateIds));
    }
  }

  @Action(UpdateSearchResultList)
  public updateSearchResultList(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: UpdateSearchResultList
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    let resultItems = this.store.selectSnapshot(SearchInReportFilesState.searchResult);
    resultItems = resultItems.map((item) => {
      const updatedItem = action.list.find((i) => i.id === item.id);
      if (updatedItem) {
        return updatedItem;
      }
      return item;
    });
    ctx.setState(
      patch({
        [projectId]: patch({
          searchResultItems: resultItems,
        }),
      })
    );
  }

  @Action(SearchInReportFiles)
  public search(ctx: StateContext<SearchInReportFilesStateModel>): Observable<void> {
    ctx.dispatch(new SetSearchInReportFilesLoader(true));
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const searchResult = this.store.selectSnapshot(SearchInReportFilesState.searchResult);
    const alreadySearched = searchResult?.length;
    const filter = { ...this.store.selectSnapshot(SearchInReportFilesState.filter) };
    const searchCandidatesUnitIds = this.store.selectSnapshot(
      SearchInReportFilesState.searchTranslationUnitIdsCandidates
    );

    if (alreadySearched && searchCandidatesUnitIds.length) {
      filter.translationUnitIds ||= [];
      const alreadySearchedTranslationUnitIds = searchResult.map((r) => r.translationUnit.id);
      filter.translationUnitIds = [
        ...filter.translationUnitIds,
        ...searchCandidatesUnitIds,
        ...alreadySearchedTranslationUnitIds,
      ];
    }

    ctx.dispatch(new ResetTranslationUnitIdsSearchCandidates());
    if (!filter.sourceOptions?.pattern && !filter.targetOptions?.pattern) {
      return;
    }
    return this.searchService
      .apiTranslationUnitsSearchPost$Json({
        body: {
          projectId,
          ...filter,
        },
      })
      .pipe(
        mergeMap((response) => {
          ctx.dispatch(new SetSearchResultList(this.mapper.mapItems(response)));
          ctx.dispatch(new SetSearchInReportFilesLoader(false));
          return ctx.dispatch(new SearchInReportFilesSuccess());
        }),
        catchError((error) => {
          ctx.dispatch(new SetSearchInReportFilesLoader(false));
          ctx.dispatch(new SetSearchResultList([]));
          ctx.dispatch(new AddTranslationUnitIdSearchCandidate(searchCandidatesUnitIds));
          return ctx.dispatch(new SearchInReportFilesFail(error));
        })
      );
  }

  @Action(UpdateTranslationUnits)
  public updateTranslationUnits(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: UpdateTranslationUnits
  ): Observable<void> {
    const reportId = this.store.selectSnapshot(ReportState.reportId);
    const resultItems = this.store.selectSnapshot(SearchInReportFilesState.searchResult);
    const resultItemsMap = resultItems.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    const updatedCandidates: SearchInReportFilesResultItem[] = [];
    action.translationUnitUpdates.forEach((item) => {
      const newRenderItems = this.renderItemBuilder.build([], item.target.elements);
      if (resultItemsMap[item.id]) {
        updatedCandidates.push({
          ...resultItemsMap[item.id],
          translationUnit: {
            ...resultItemsMap[item.id].translationUnit,
            target: item.target,
          },
          targetRanges: [],
          targetRenderItems: newRenderItems,
        } as SearchInReportFilesResultItem);
      }
    });
    ctx.dispatch(new UpdateSearchResultList(updatedCandidates));
    return this.qualityIssuesService.apiQualityIssuesUpdateTranslationUnitsPost({
      body: {
        reportId,
        translationUnits: action.translationUnitUpdates,
      },
    });
  }

  @Action(AddTranslationUnitIdSearchCandidate)
  public addTranslationUnitIdSearchCandidate(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: AddTranslationUnitIdSearchCandidate
  ): SearchInReportFilesStateModel {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const translationUnitIds = [
      ...this.store.selectSnapshot(SearchInReportFilesState.searchTranslationUnitIdsCandidates),
      ...action.translationUnitIds,
    ];
    const uniqueTranslationIds = Array.from(new Set(translationUnitIds));
    return ctx.setState(
      patch({
        [projectId]: patch({
          translationUnitIdsSearchBuffer: uniqueTranslationIds,
        }),
      })
    );
  }

  @Action(ResetTranslationUnitIdsSearchCandidates)
  public resetTranslationUnitIdsSearchCandidates(
    ctx: StateContext<SearchInReportFilesStateModel>
  ): SearchInReportFilesStateModel {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const resetedValues = [];
    return ctx.setState(
      patch({
        [projectId]: patch({
          translationUnitIdsSearchBuffer: resetedValues,
        }),
      })
    );
  }

  @Action(UpdateSearchResultCommentStatus)
  public updateSearchCommentStatus(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: UpdateSearchResultCommentStatus
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const updatedItems = this.store.selectSnapshot(SearchInReportFilesState.searchResult).map((item) => {
      if (item.translationUnit.id === action.translationUnitId) {
        return {
          ...item,
          translationUnit: {
            ...item.translationUnit,
            hasComments: action.hasComments,
          },
        };
      }
      return item;
    });

    ctx.setState(
      patch({
        [projectId]: patch({
          searchResultItems: updatedItems,
        }),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(
    ctx: StateContext<SearchInReportFilesStateModel>,
    action: CleanStoredProjectInfo
  ): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
