import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@shared/components/tooltip';
import { SegmentEditorComponent } from './segment-editor.component';
import { CodemirrorWrapperComponent } from './codemirror-wrapper/codemirror-wrapper.component';
import { ClipboardBetweenEditorsService } from '@shared/components/segment-editor/clipboard-between-editors.service';
import { EditorHelperService } from '@shared/components/segment-editor/editor-helper.service';
import { TagSharingRoomService } from '@shared/components/segment-editor/tag-sharing-room.service';
import { TextNormalizationService } from '@report/services/text-normalization.service';
import { SegmentRendererModule } from '../segment-renderer';
import { SpecialSymbolsModule } from '../special-symbols/special-symbols.module';
import { DirectivesModule } from '@shared/directives';

@NgModule({
  imports: [CommonModule, TooltipModule, SegmentRendererModule, SpecialSymbolsModule, DirectivesModule],
  declarations: [SegmentEditorComponent, CodemirrorWrapperComponent],
  exports: [SegmentEditorComponent, CodemirrorWrapperComponent],
  providers: [ClipboardBetweenEditorsService, EditorHelperService, TagSharingRoomService, TextNormalizationService],
})
export class SegmentEditorModule {}
