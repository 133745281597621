import { SpinnerSize } from '@shared/components/loader/models/spinner-size.model';

/**
 * Configuration object for loading overlay
 */
export class LoaderConfig {
  /**
   * Optional text
   */
  text?: string;

  /**
   * Spinner and text size
   */
  size: SpinnerSize = SpinnerSize.Normal;
}
