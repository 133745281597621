import { Injectable } from '@angular/core';
import { TabActivationService } from '../../core/services';
import { LoggerService } from '../../core/logger';
import { AppService } from '@shared/services/app.service';
import * as signalR from '@microsoft/signalr';
import { filter, map, pairwise, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { CommonHubEvent } from '@shared/models';
import { Report, SocketService } from '@generated/api';
import { AuthService } from 'src/app/core/modules/auth';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { SelectOrganizationById } from '@store/organizations-store/organizations.actions';
import { ReloadProjects } from '@store/projects-store/projects.actions';

@Injectable({
  providedIn: 'root',
})
export class CommonHubService {
  private connection: signalR.HubConnection;
  private retryCount = 0;
  private lastUsedAccessToken: string;
  private projectId: string;

  private readonly messageStream$: Subject<CommonHubEvent> = new Subject<CommonHubEvent>();
  private readonly messageStreams: Record<string, Observable<CommonHubEvent>> = {};

  public connectionState$ = new BehaviorSubject(signalR.HubConnectionState.Connecting);
  public reconnected = this.connectionState$.pipe(
    pairwise(),
    filter(
      ([previousValue, value]) =>
        previousValue === signalR.HubConnectionState.Disconnected && value === signalR.HubConnectionState.Connected
    ),
    map((array) => array[1])
  );

  private get onlineAndTabVisible$(): Observable<boolean> {
    return combineLatest([this.tabActivationService.tabVisible$, this.appService.online$]).pipe(
      map(([visible, online]) => visible && online)
    );
  }

  constructor(
    private socketsService: SocketService,
    private tabActivationService: TabActivationService,
    private loggerService: LoggerService,
    private appService: AppService,
    private authService: AuthService,
    private readonly actions: Actions
  ) {
    this.initNewConnection();
    this.startConnection();
    this.onReconnected();
  }

  private onReconnected(): void {
    this.reconnected.subscribe(() => {
      if (this.projectId) {
        this.subscribeProjectEvents(this.projectId).subscribe();
      }
    });
  }

  private initNewConnection(): void {
    const userAuthFactory = firstValueFrom(
      this.authService.accessToken$.pipe(
        filter((token) => !!token),
        take(1),
        tap((token) => (this.lastUsedAccessToken = token))
      )
    );

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('/hubs/common', {
        accessTokenFactory: () => userAuthFactory,
      })
      .build();

    this.connection.onclose((error) => {
      this.connectionState$.next(this.connection.state);
      if (error) {
        this.loggerService.error(error);
      }
      this.scheduleNextRetry(error);
    });
  }

  public closeConnection(): void {
    this.connection.stop();
  }

  private startConnection(): void {
    this.connection
      .start()
      .then(() => {
        this.retryCount = 0;
        this.connectionState$.next(this.connection.state);
      })
      .catch((reason: Error) => {
        this.connectionState$.next(this.connection.state);
        this.loggerService.error(reason);
        this.scheduleNextRetry(reason);
      });
  }

  private reconnect(accessTokenChanged: boolean): void {
    if (this.lastUsedAccessToken !== this.authService.accessToken || accessTokenChanged) {
      this.initNewConnection();
    }
    this.startConnection();
  }

  private scheduleNextRetry(reasonPreviousFail: Error): void {
    this.retryCount++;
    setTimeout(() => {
      if (this.isUnauthorizedError(reasonPreviousFail)) {
        this.authService.accessToken$.pipe(take(1)).subscribe(() => {
          this.reconnect(true);
        });
        return;
      }

      this.onlineAndTabVisible$
        .pipe(
          takeWhile((value) => !value, true),
          take(1)
        )
        .subscribe(() => {
          this.reconnect(false);
        });
    }, this.calculateNextRetryDelay());
  }

  private calculateNextRetryDelay(): number {
    if (this.retryCount < 4) {
      return this.retryCount * 1000;
    }
    return 30000;
  }

  public watch<T extends CommonHubEvent>(event: T): Observable<T['payload']> {
    if (!this.messageStreams[event.type]) {
      this.connection.on(event.type, (data: T, params) => {
        if (event.skipCurrentTabEvent && params?.tabId === this.tabActivationService.tabId) {
          return;
        }
        this.messageStream$.next({ type: event.type, payload: data });
      });
      this.messageStreams[event.type] = this.messageStream$.pipe(
        filter((v) => v.type === event.type),
        map((t) => t.payload)
      );
    }
    return this.messageStreams[event.type];
  }

  public subscribeIfProjectChanged(newProjectId: string): Observable<Report | void> {
    if (this.projectId === newProjectId) {
      return of(null);
    }
    console.log(`Project changed -> subscribe, was: ${this.projectId}, new: ${newProjectId}`);
    return this.subscribeProjectEvents(newProjectId);
  }

  get untillOrganisationChanged$(): Observable<void> {
    return merge(
      this.actions.pipe(ofActionDispatched(SelectOrganizationById)),
      this.actions.pipe(ofActionDispatched(ReloadProjects))
    );
  }

  public subscribeProjectEvents(projectId: string): Observable<void> {
    this.projectId = projectId;
    return this.authService.accessToken$.pipe(
      filter((token) => !!token),
      take(1),
      takeUntil(this.untillOrganisationChanged$),
      switchMap(() =>
        this.connectionState$.pipe(
          filter((value) => value === signalR.HubConnectionState.Connected),
          take(1),
          takeUntil(this.untillOrganisationChanged$),
          switchMap(() =>
            this.socketsService.apiSocketsProjectSubscribePost({
              body: {
                projectId: this.projectId,
                connectionId: this.connection.connectionId,
              },
            })
          )
        )
      )
    );
  }

  private isUnauthorizedError(error?: Error): boolean {
    return error?.message?.includes('Unauthorized');
  }
}
