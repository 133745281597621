import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayService } from './overlay.service';
import { OverlayContainerComponent } from './overlay-container.component';
import { AdDirective } from './ad.directive';

@NgModule({
  declarations: [OverlayContainerComponent, AdDirective],
  imports: [CommonModule],
  providers: [OverlayService],
})
export class OverlayModule {}
