import { Account } from '@shared/models';

enum ActionTypes {
  AUTH = '[Auth] Auth',
  SET_ACCOUNT = '[Auth] Set account',
  LOGOUT = '[Auth] Logout',
  SET_ACCESS_TOKEN = '[Auth] Set access token',
}

export class Auth {
  static readonly type = ActionTypes.AUTH;
}
export class SetAccount {
  static readonly type = ActionTypes.SET_ACCOUNT;
  constructor(public account: Account) {}
}

export class Logout {
  static readonly type = ActionTypes.LOGOUT;
  constructor() {}
}

export class SetAccessToken {
  static readonly type = ActionTypes.SET_ACCESS_TOKEN;
  constructor(public accessToken: string) {}
}
