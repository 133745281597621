import { insertItem, updateItem, patch } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, NgxsOnInit, Selector, State } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CreateWordformsBase,
  LoadDefaultWordformsBase,
  LoadWordformsBases,
  UpdateWordformsBase,
} from './wordforms-base.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { WordformsBaseInfo, WordformsBasesService } from '@generated/api';

export interface WordformsBaseStateModel {
  wordformBases: WordformsBaseInfo[];
  default: WordformsBaseInfo;
}

const initialState = {
  wordformBases: [],
  default: null,
};

@AsyncStorage
@StateRepository()
@State<WordformsBaseStateModel>({
  name: 'wordformsBases',
  defaults: initialState,
})
@Injectable()
export class WordformsBaseState implements NgxsOnInit {
  constructor(private service: WordformsBasesService) {}

  @Selector()
  public static wordformsBases(state: WordformsBaseStateModel) {
    return state.wordformBases;
  }

  @Selector()
  public static default(state: WordformsBaseStateModel) {
    return state?.default;
  }

  ngxsOnInit(ctx?: StateContext<any>): void {
    this.loadInitialDefaultState(ctx);
  }

  private loadInitialDefaultState(ctx?: StateContext<any>): void {
    ctx.dispatch(new LoadDefaultWordformsBase());
  }

  @Action(LoadWordformsBases)
  public loadWordformsBases(ctx: StateContext<WordformsBaseStateModel>) {
    return this.service.apiWordformsBasesGet$Json().pipe(
      tap((response) => {
        ctx.setState(
          patch({
            wordformBases: response.data,
          })
        );
      })
    );
  }

  @Action(LoadDefaultWordformsBase)
  public loadDefaultWordformsBase(ctx: StateContext<WordformsBaseStateModel>) {
    return this.service
      .apiWordformsBasesDefaultGet$Json()
      .pipe()
      .subscribe((data) => {
        const state = ctx.getState();
        const stateMutation = state ? patch({ default: data }) : { ...initialState, default: data };
        ctx.setState(stateMutation);
      });
  }

  @Action(CreateWordformsBase)
  public create(ctx: StateContext<WordformsBaseStateModel>, action: CreateWordformsBase) {
    return this.service
      .apiWordformsBasesPost$Json({
        body: { name: action.name },
      })
      .pipe(
        tap((wordformsBase) => {
          ctx.setState(
            patch({
              wordformBases: insertItem(wordformsBase),
            })
          );
        })
      );
  }

  @Action(UpdateWordformsBase)
  public update(ctx: StateContext<WordformsBaseStateModel>, action: UpdateWordformsBase) {
    return this.service
      .apiWordformsBasesIdPut$Json({
        id: action.id,
        body: { name: action.name },
      })
      .pipe(
        tap((wordformsBase) => {
          ctx.setState(
            patch({
              wordformBases: updateItem<WordformsBaseInfo>((a) => a.id === action.id, wordformsBase),
            })
          );
        })
      );
  }
}
