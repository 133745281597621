import { PlanCategoryModel } from '@generated/payment-api';

export class LoadSubscriptionProducts {
  public static readonly type = '[Payment] Load Subscription Products';
  constructor() {}
}

export class SetSubscriptionPlan {
  public static readonly type = '[Payment] Set Subscription Plan';
  constructor(public readonly plan: PlanCategoryModel, public quantity: number) {}
}

export class LoadSubscriptionDetails {
  public static readonly type = '[Payment] Load Subscription Details';
  constructor() {}
}

export class LoadBalance {
  public static readonly type = '[Payment] Load Balance';
  constructor() {}
}

export class UpdatePaymentPlan {
  public static readonly type = '[Payment] Update Payment Plan';
  constructor(public readonly quantity: number) {}
}
