import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItem } from '@shared/models';

/**
 * Navigation
 * Show the list of navigation items.
 *
 *
 * Usage:
 * ````
 * <app-ui-nav [items]="navigation"></app-ui-nav>
 * <app-ui-nav [items]="navigation" direction="vertical"></app-ui-nav>
 * ````
 */

@Component({
  selector: 'app-ui-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
  @Input() items: NavItem[] = [];

  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
}
