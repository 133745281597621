import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextEditableComponent } from '@shared/components/text-editable/text-editable.component';
import { TooltipModule } from '@shared/components/tooltip';
import { DirectivesModule } from '../../directives';
import { PipesModule } from '@shared/pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    TooltipModule,
    PipesModule,
  ],
  declarations: [TextEditableComponent],
  exports: [TextEditableComponent],
})
export class TextEditableModule {}
