import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { CommentModel } from '@generated/api';
import { RenderItem } from '@shared/models';
import { dateTimeComparator } from '@shared/tools';

@Component({
  selector: 'app-comment-thread',
  templateUrl: './comment-thread.component.html',
  styleUrls: ['./comment-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentThreadComponent implements AfterViewInit {
  private _comments: CommentModel[] = [];

  @Input()
  expanded: boolean = true;

  @Input()
  time: string;

  @Input()
  quotes: RenderItem[][] = [];

  @Input() issueKindText: string;

  @Input()
  isResolved = false;

  @Input()
  isUserDefined = false;

  @Input()
  set comments(value: CommentModel[]) {
    if (value && value.length) {
      this._comments = value.slice().sort((a, b) => dateTimeComparator(a.createdOn, b.createdOn));
      return;
    }
    this._comments = [];
  }

  get comments(): CommentModel[] {
    return this._comments;
  }

  @Output()
  public readonly isResolvedChanged = new EventEmitter<boolean>();

  @Output()
  public readonly commentAdded = new EventEmitter<string>();

  @Output()
  public readonly commentDeleted = new EventEmitter<CommentModel>();

  @Output()
  public readonly commentEdited = new EventEmitter<CommentModel>();

  @Output()
  public readonly threadDeleted = new EventEmitter<void>();

  @Output()
  public readonly threadClicked = new EventEmitter();

  @Output()
  public readonly backFocus = new EventEmitter();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @ViewChild('commentInput', { static: false }) commentInput: ElementRef<HTMLHtmlElement>;

  public editableComment: string = '';

  constructor(private elementRef: ElementRef) {}

  get description(): string {
    return this.isUserDefined ? 'User-defined (within the project)' : this.issueKindText;
  }

  get visibleComments(): CommentModel[] {
    if (!this.comments) {
      return [];
    }
    return this.expanded ? this.comments : this.comments.slice(0, 1);
  }

  /*
   *  initial status, which means that the thread has been created, but without the comment text.
   */
  get initialThreadStatus(): boolean {
    return !this.comments?.length;
  }

  get inputPlaceholder(): string {
    if (this.isResolved) {
      return 'Reply will re-open this thread...';
    }

    return this.initialThreadStatus ? 'Comment...' : 'Reply...';
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.focusCommentInput());
  }

  private focusCommentInput(): void {
    this.commentInput?.nativeElement.focus();
  }

  public lostFocus(): void {
    this.commentInput.nativeElement.blur();
  }

  public resolveThread(): void {
    this.isResolved = true;
    this.updateThreadStatus();
  }

  public openThread(): void {
    this.isResolved = false;
    this.updateThreadStatus();
  }

  public deleteComment(comment: CommentModel, index: number): void {
    if (index === 0) {
      this.threadDeleted.emit();
    } else {
      this.commentDeleted.emit(comment);
    }
  }

  public editComment(comment: CommentModel, message: string): void {
    this.commentEdited.emit({
      ...comment,
      message,
    });
  }

  public sendComment(): void {
    const normalizedCommentText = this.editableComment?.trim();
    if (!normalizedCommentText) {
      return;
    }
    this.commentAdded.emit(normalizedCommentText);
    this.editableComment = '';
    if (this.isResolved) {
      this.reopenThread();
    }
    this.unfocusComment();
  }

  public unfocusComment(): void {
    this.backFocus.emit();
  }

  public onThreadClicked(): void {
    this.threadClicked.emit();
  }

  private reopenThread(): void {
    this.isResolved = false;
    this.updateThreadStatus();
  }

  private updateThreadStatus(): void {
    this.isResolvedChanged.emit(this.isResolved);
  }
}
