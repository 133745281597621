import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyDateTime',
  pure: false,
})
export class PrettyDateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value?: string | Date): string {
    if (!value) {
      return '';
    }

    const rawDate = new Date(value);
    return `${this.prettifyTime(rawDate)}, ${this.prettifyDate(rawDate)}`;
  }

  get now(): Date {
    return new Date();
  }

  private prettifyTime(d: Date): string {
    return this.datePipe.transform(d, 'HH:mm aaa');
  }

  private prettifyDate(d: Date): string {
    return this.isToday(d) ? 'Today' : this.datePipe.transform(d, 'MM/dd/YYYY');
  }

  private isToday(d: Date): boolean {
    return (
      d.getDate() === this.now.getDate() &&
      d.getMonth() === this.now.getMonth() &&
      d.getFullYear() === this.now.getFullYear()
    );
  }
}
