import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { LOG_APPENDER_CONFIG_TOKEN, LOG_APPENDER_TOKEN, LogAppenderConfig } from '../../log-appender';
import { SentryService } from './sentry.service';

@NgModule()
export class SentryModule {
  public static forRoot(configFactory: () => LogAppenderConfig): ModuleWithProviders<SentryModule> {
    return {
      ngModule: SentryModule,
      providers: [
        {
          provide: LOG_APPENDER_TOKEN,
          useClass: SentryService,
          multi: true,
        },
        {
          provide: LOG_APPENDER_CONFIG_TOKEN,
          useFactory: configFactory,
          multi: true,
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
        SentryService,
      ],
    };
  }
}
