import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslationUnitModel } from '@generated/api';
import { iconLock, iconLockShield, IconPayload, iconShieldBordered } from '@generated/icons/icons.model';
import { IconSize } from '@shared/components/icon';
import { QualityIssue } from '@shared/models';
import { ICellRendererAngularComp, ICellRendererParams } from 'src/types/ag-grid';

@Component({
  selector: 'app-lock-protection-cell-renderer',
  templateUrl: './lock-protection-cell-renderer.component.html',
  styleUrls: ['./lock-protection-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockProtectionCellRendererComponent implements ICellRendererAngularComp {
  static readonly rendererName = 'lockProtectionCellRenderer';

  public locked: boolean;
  public protected: boolean;
  public icon: IconPayload;
  public iconSize: IconSize;

  constructor() {}

  public agInit(params: ICellRendererParams<QualityIssue | TranslationUnitModel>): void {
    const translationUnit: TranslationUnitModel =
      (params.data as QualityIssue).translationUnit || (params.data as TranslationUnitModel);
    this.locked = translationUnit.properties.locked;
    this.protected = translationUnit.isProtected;
    this.icon = this.determineIcon();
    this.setIconSize();
  }

  private determineIcon(): IconPayload {
    if (this.locked && this.protected) {
      return iconLockShield.name;
    }
    if (this.locked) {
      return iconLock.name;
    }
    if (this.protected) {
      return iconShieldBordered.name;
    }
  }

  private setIconSize(): void {
    if (this.locked && this.protected) {
      this.iconSize = '26px';
      return;
    }
    this.iconSize = 'small';
  }

  public refresh(): boolean {
    return false;
  }
}
