import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Inject,
  Optional,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Injector,
} from '@angular/core';
import { TagView } from '@shared/models';
import { ColumnResizedEvent, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { deepClone } from '@shared/tools';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextViewerEvent } from './context-viewer-event';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AgGridColumn, CellClickedEvent, GridApi, GridOptions, GridReadyEvent } from 'src/types/ag-grid';
import { RenderItemBuilder } from '@shared/services';
import { buildContextViewerGridOptions } from './context-viewer.options';
import { buildContextViewerGridColumns } from './context-viewer.columns';
import { LanguageModel, TranslationUnitModel } from '@generated/api';
import { ReportGridService } from '@report/components/report-grid/services';
import { TableKeybindingEvent } from 'src/app/core/services/shortcuts';
import { BaseReportGridComponent } from '@report/modules/base/base-report-grid.component';
import { GridHeaderComponent } from '@shared/components/grid-components/grid-header/grid-header.component';

export interface ContextViewerComponentParams {
  focused?: string;
  tagView?: TagView;
  showInvisibles?: boolean;
  fileName?: string;
  sourceLanguage: LanguageModel;
  targetLanguage: LanguageModel;
  editable?: boolean;
  hideProtected?: boolean;
  suppressManualEditing?: boolean;
  onTargetSegmentUpdated?: (_: ContextViewerEvent) => void;

  loading$?: Observable<boolean>;
  offline$?: Observable<boolean>;
  loadingError$?: Observable<boolean>;
  translationUnits$?: Observable<TranslationUnitModel[]>;
}

/**
 * Usage with ng-template:
 * ```
 * // .html
 * <ng-template #modalExample>
 *    <app-ui-context-viewer [translationUnits]="units"></app-ui-context-viewer>
 *  </ng-template>
 *
 *  // .ts
 *  @ViewChild('modalExample', { static: true }) modalExample: TemplateRef<any>;
 *  public modal: MatDialogRef<any>;
 *  this.modal = this.dialog.open(this.modalExample, {});
 * ```
 *
 *
 * Usage with material dialog api:
 * ```
 *  // .ts
 *  public modal: MatDialogRef<any>;
 *  this.modal = this.dialog.open(ContextViewerComponent, {
 *    data: {
 *      // input params
 *      translationUnits: units
 *    }
 *  });
 * ```
 */
@Component({
  selector: 'app-ui-context-viewer',
  templateUrl: './context-viewer.component.html',
  styleUrls: ['./context-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportGridService],
})
export class ContextViewerComponent extends BaseReportGridComponent implements OnInit, OnDestroy, OnChanges {
  protected readonly uniqueTableId = 'Context viewer';
  protected readonly destroyed$: Subject<void> = new Subject();
  protected editColId: string = 'targetSegment';
  private readonly matInputProps: Array<keyof ContextViewerComponentParams> = [
    'focused',
    'tagView',
    'showInvisibles',
    'fileName',
    'sourceLanguage',
    'targetLanguage',
    'editable',
    'hideProtected',
    'suppressManualEditing',
    'onTargetSegmentUpdated',
    'suppressManualEditing',
  ];

  @Input() translationUnits = [];
  @Input() loadingError = false;
  @Input() offline = false;
  @Input() fileName: string;
  @Input() sourceLanguage: LanguageModel;
  @Input() targetLanguage: LanguageModel;
  @Input() editable = true;
  @Input() focused: string;
  @Input() tagView: TagView = TagView.Medium;
  @Input() showInvisibles = false;
  @Input() hideProtected = false;
  @Input() suppressManualEditing = false;
  @Output() targetSegmentUpdated = new EventEmitter<ContextViewerEvent>();

  gridApi: GridApi;
  columnDefs: Partial<AgGridColumn>[] = this.getColumnDef();

  protected eventActions: { [key in TableKeybindingEvent]?: () => void } = {
    ...super.baseEventActions,
    [TableKeybindingEvent.ENTER_SEGMENT_EDIT_MODE]: () => this.toggleEdit(),
    [TableKeybindingEvent.EXIT]: () => this.exitEditMode(),
  };

  public readonly defaultColDef = {
    suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
      return !params.editing;
    },
    headerComponent: GridHeaderComponent.rendererName,
  };
  public readonly modules = [ClientSideRowModelModule, SetFilterModule];

  public gridOptions: GridOptions;
  public readonly columnResized$: Subject<ColumnResizedEvent> = new Subject<ColumnResizedEvent>();

  get showLoader(): boolean {
    return this.loading && !this.loadingError && !this.offline;
  }

  get showGrid(): boolean {
    return this.gridOptions && !this.loading && !this.loadingError && !this.offline;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data: ContextViewerComponentParams,
    private changeDetectorRef: ChangeDetectorRef,
    private renderItemBuilder: RenderItemBuilder,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initMatData();
    this.initGridOptions();
    this.initGridContext();
    this.initSubscriptions();
    this.updateTableColumnDef();
    super.watchKeybindingEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceLanguageName || changes.targetLanguageName || changes.editable) {
      this.updateTableColumnDef();
      this.changeDetectorRef.detectChanges();
    }
    if (changes.focused) {
      this.scrollToFirstOrFocusedRow();
    }
    if (changes.showInvisibles) {
      this.gridOptions.context.showInvisibles = this.showInvisibles;
    }
    if (changes.tagView) {
      this.gridOptions.context.tagView = this.tagView;
    }
  }

  public firstDataRendered(): void {
    this.scrollToFirstOrFocusedRow();
  }

  private initMatData(): void {
    if (!this.data) {
      return;
    }
    this.matInputProps.forEach((k) => {
      if (this.data[k] === undefined || this.data[k] === null) {
        return;
      }
      this[k] = this.data[k];
    });
  }

  private initGridOptions(): void {
    this.gridOptions = buildContextViewerGridOptions(this.suppressManualEditing);
  }

  private initGridContext(): void {
    this.gridOptions.context = {
      ...(this.gridOptions.context || {}),
      showInvisibles: this.showInvisibles,
      tagView: this.tagView,
      multiline: true,
    };
  }

  private initSubscriptions(): void {
    this.watchLoading();
    this.watchLoadingError();
    this.watchOfflineStatus();
  }

  private watchLoading(): void {
    this.data.loading$?.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.loading = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  private watchLoadingError(): void {
    this.data.loadingError$?.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.loadingError = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  private watchOfflineStatus(): void {
    this.data.offline$?.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.offline = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  public cellValueChanged(event): void {
    this.targetSegmentUpdated.emit({
      translationUnitId: event.data.id,
      newTarget: event.data.target,
    });
    this.onTargetSegmentUpdated({
      translationUnitId: event.data.id,
      newTarget: event.data.target,
    });
  }

  public cellClicked(event: CellClickedEvent): void {
    if (this.suppressManualEditing && event.data.isProtected) {
      return;
    }
    if (event.colDef.colId !== 'targetSegment') {
      return;
    }
    const editingCells = event.api.getEditingCells();
    if (editingCells.length && editingCells[0].rowIndex === event.rowIndex) {
      return;
    }
    event.api.startEditingCell({
      rowIndex: event.rowIndex,
      colKey: 'targetSegment',
    });
    this.changeDetectorRef.detectChanges();
  }

  private handleTranslationUnit(): void {
    this.data.translationUnits$?.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe((data) => {
      this.translationUnits = deepClone(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTargetSegmentUpdated = (_: ContextViewerEvent): void => {};

  private updateTableColumnDef(): void {
    this.columnDefs = this.getColumnDef();
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
  }

  private getColumnDef(): Partial<AgGridColumn>[] {
    return buildContextViewerGridColumns({
      hideProtected: this.hideProtected,
      renderItemBuilderFn: this.renderItemBuilder.build.bind(this.renderItemBuilder),
      sourceLanguage: this.sourceLanguage,
      targetLanguage: this.targetLanguage,
      editable: this.editable,
      suppressProtectedEditing: this.suppressManualEditing,
    });
  }

  private scrollToFirstOrFocusedRow(): void {
    const id: string = this.focused || this.translationUnits[0]?.id || null;
    if (!id || !this.gridApi) {
      return;
    }
    const row = this.gridApi.getRowNode(id);
    if (!row) {
      return;
    }
    row.setSelected(true, true);
    this.scrollToRow(row, 'middle');
  }

  public onGridReady(event: GridReadyEvent): void {
    super.onGridReady(event);
    this.gridApi = event.api;
    this.updateTableColumnDef();
    this.handleTranslationUnit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
