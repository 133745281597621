export function getUnique<T>(arr: T[], propName?: string): T[] {
  const flags = new Set();
  return arr.filter((item) => {
    const uniqueValue = propName ? item[propName] : item;
    if (flags.has(uniqueValue)) {
      return false;
    }
    flags.add(uniqueValue);
    return true;
  });
}
