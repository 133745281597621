import { IconPayload } from '@generated/icons/icons.model';

export type IconSize = 'auto' | 'large' | 'normal' | 'small' | 'xs' | string;

export const iconColors = [
  'primary',
  'primary-light',
  'secondary',
  'gray',
  'inherit',
  'error',
  'white',
  'primary-dark',
  '',
];

export type IconColor = typeof iconColors[number] | string;

export interface IconDescription {
  name: IconPayload;
  color?: IconColor;
  size?: IconSize;
}
