import { Action, State, Selector, Store, Actions, ofActionDispatched } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import {
  AddProject,
  LoadProjects,
  PushProject,
  UpdateProjectName,
  UpdateProject,
  ResetProjects,
  ReloadProjects,
  ResetProjectsPagination,
  ShiftStoredProjects,
  CleanStoredProjectInfo,
} from './projects.actions';
import { tap, switchMap, takeUntil, filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { SetCurrentProject, TouchedProject } from '@store/project-store';
import { LoadQASettings } from '@store/qa-settings-store';
import { ResetLanguages } from '@store/language-store';
import { ResetProjectFiles } from '@store/project-files-store/project-files.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { ResetSourceTargetLanguages } from '@store/glossary-terms-store';
import { ProjectInfo, ProjectInfoDataSourceResponse, ProjectsService } from '@generated/api';
import { Observable, of, Subject } from 'rxjs';
import { CommonHubService } from '@shared/services';
import { ProjectCreatedEvent } from '@shared/models';
import { append, patch } from '@ngxs/store/operators';
import { environment } from 'src/environments/environment';
import { QualityIssueDAO } from 'src/app/core/daos';

interface ProjectsStateModel {
  projects: ProjectInfo[];
  storedProjects: ProjectInfo[];
  searchText?: string;
  loading: boolean;
  total?: number;
}

const defaultState: ProjectsStateModel = {
  projects: [],
  storedProjects: [],
  loading: false,
};

@AsyncStorage
@StateRepository()
@State<ProjectsStateModel>({
  name: 'projects',
  defaults: defaultState,
})
@Injectable()
export class ProjectsState implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private store: Store,
    private projectsService: ProjectsService,
    private commonHub: CommonHubService,
    private actions: Actions,
    private qualityIssueDAO: QualityIssueDAO
  ) {}

  ngxsOnInit(): void {
    this.watchProjectEvents();
    this.watchSelectedProject();
  }

  private watchProjectEvents(): void {
    this.commonHub
      .watch(ProjectCreatedEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.store.dispatch(new LoadProjects());
      });
  }

  private watchSelectedProject(): void {
    this.actions
      .pipe(
        ofActionDispatched(SetCurrentProject),
        filter((action) => action.project),
        takeUntil(this.destroyed$)
      )
      .subscribe((action: SetCurrentProject) => {
        this.store.dispatch(new ShiftStoredProjects(action.project));
      });
  }

  @Selector()
  public static projects(state: ProjectsStateModel): ProjectInfo[] {
    return [...state.projects].sort((a, b) => +new Date(b.modifiedOn) - +new Date(a.modifiedOn));
  }

  @Selector()
  public static total(state: ProjectsStateModel): number {
    return state.total;
  }

  @Selector()
  public static storedProjects(state: ProjectsStateModel): ProjectInfo[] {
    return state.storedProjects;
  }

  @Selector()
  public static loading(state: ProjectsStateModel): boolean {
    return state.loading;
  }

  @Action(LoadProjects)
  public loadProjects(ctx: StateContext<ProjectsStateModel>, action: LoadProjects): Observable<void> {
    this.setLoadingStatus(ctx, true);
    const handler = action.searchQuery ? this.#searchProjects : this.#loadProjects;
    return handler.bind(this)(ctx, action);
  }

  @Action(ReloadProjects)
  public reloadProjects(ctx: StateContext<ProjectsStateModel>): Observable<void> {
    this.setLoadingStatus(ctx, true);
    return this.#loadProjects(ctx, new LoadProjects()).pipe(
      switchMap((rspns) => ctx.dispatch(new TouchedProject(rspns.data[0]?.id)))
    );
  }

  // TODO: это должно быть частью запроса на выгрузку проектов.
  #loadProjects(
    ctx: StateContext<ProjectsStateModel>,
    action: LoadProjects
  ): Observable<ProjectInfoDataSourceResponse> {
    const firstLoad = action.pagination.page === 1;
    return this.projectsService.apiProjectsGet$Json(action.pagination).pipe(
      switchMap((rspns) => of(rspns)),
      tap((rspns: ProjectInfoDataSourceResponse) => {
        ctx.setState(
          patch({
            projects: firstLoad ? rspns.data : append(rspns.data),
            total: rspns.total,
            loading: false,
          })
        );
      })
    );
  }

  #searchProjects(ctx: StateContext<ProjectsStateModel>, action: LoadProjects): Observable<any> {
    const paginatableSearch = ctx.getState().searchText === action.searchQuery;
    ctx.setState(patch({ searchText: action.searchQuery }));
    return this.projectsService
      .apiProjectsSearchGet$Json({
        q: action.searchQuery,
        ...action.pagination,
      })
      .pipe(
        switchMap((rspns) => of(rspns)),
        tap((rspns: ProjectInfoDataSourceResponse) => {
          ctx.setState(
            patch({
              projects: paginatableSearch ? append(rspns.data) : rspns.data,
              total: rspns.total,
              loading: false,
            })
          );
        })
      );
  }

  private setLoadingStatus(ctx: StateContext<ProjectsStateModel>, loading: boolean): void {
    ctx.setState(patch({ loading }));
  }

  @Action(AddProject)
  public addProject(ctx: StateContext<ProjectsStateModel>, action: AddProject): Observable<ProjectInfo> {
    return this.projectsService
      .apiProjectsPost$Json({
        body: action,
      })
      .pipe(
        tap((project) => {
          ctx.setState(patch({ projects: append([project]) }));
        }),
        tap((project) => {
          ctx.dispatch(new SetCurrentProject(project));
        }),
        tap((project) => {
          ctx.dispatch(new ResetLanguages());
          ctx.dispatch(new ResetProjectFiles());
          ctx.dispatch(new ResetSourceTargetLanguages());
          ctx.dispatch(new LoadQASettings(project.qaSettingsId));
        })
      );
  }

  @Action(PushProject)
  public pushProject(ctx: StateContext<ProjectsStateModel>, action: PushProject): void {
    ctx.setState(
      patch({
        projects: append([action.project]),
      })
    );
  }

  @Action(UpdateProjectName)
  public updateProjectName(ctx: StateContext<ProjectsStateModel>, { id, name }: UpdateProjectName): void {
    const projects = ctx.getState().projects.map((item: ProjectInfo) => (item.id === id ? { ...item, name } : item));
    ctx.setState(patch({ projects }));
  }

  @Action(UpdateProject)
  public updateProject(ctx: StateContext<ProjectsStateModel>, { project }: UpdateProject): void {
    const projects = ctx.getState().projects.map((item: ProjectInfo) => (item.id === project.id ? project : item));
    ctx.setState(patch({ projects }));
  }

  @Action(ResetProjects)
  public resetProjects(ctx: StateContext<ProjectsStateModel>): void {
    ctx.setState(defaultState);
  }

  @Action(ResetProjectsPagination)
  public resetProjectsPagination(ctx: StateContext<ProjectsStateModel>): void {
    ctx.setState(patch({ searchText: null }));
  }

  @Action(ShiftStoredProjects)
  public shiftStoredProjects(ctx: StateContext<ProjectsStateModel>, { project }: ShiftStoredProjects): void {
    const storedProjects = ctx.getState().storedProjects;
    const alreadyStoredProject = storedProjects.find((p) => p.id === project.id);

    if (alreadyStoredProject) {
      return;
    }

    if (storedProjects.length >= environment.maxStoredProjects) {
      const cleanedProject = storedProjects.shift();
      ctx.dispatch(new CleanStoredProjectInfo(cleanedProject));
      this.qualityIssueDAO.dropReportDb(cleanedProject.id);
    }

    storedProjects.push(project);
    ctx.setState(patch({ storedProjects }));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
