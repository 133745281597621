<svg
  width="49.742mm"
  height="49.742mm"
  version="1.1"
  viewBox="0 0 49.742 49.742"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(-52.992 -67.266)" fill="currentColor">
    <g
      transform="matrix(.52917 0 0 .52917 51.405 65.679)"
      display="block"
      shape-rendering="auto"
    >
      <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
        <animate
          attributeName="opacity"
          begin="-0.875s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
      <g transform="rotate(45,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.75s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(90,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.625s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(135,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.5s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(180,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.375s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.25s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(-90,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="-0.125s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(-45,50,50)">
        <rect x="43" y="3" width="14" height="26" rx="3.12" ry="3.12">
          <animate
            attributeName="opacity"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
    </g>
  </g>
</svg>
