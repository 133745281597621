import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Account, AuthClaims } from '@shared/models';
import { AppService } from '@shared/services';
import { SetAccount } from '@store/auth/auth.actions';
import {
  AuthInfoUpdateAction,
  RefreshAccessTokenAction,
  WorkerAction,
  WorkerEventType,
  VerifyAuthInfoAction,
  VerifyUserAlreadySignedInAction,
  TokenChangedAction,
} from '@workers/worker.actions';
import {
  AuthConfig,
  EventType,
  OAuthErrorEvent,
  OAuthEvent,
  OAuthService,
  OAuthSuccessEvent,
  TokenResponse,
} from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  filter,
  from,
  Observable,
  takeUntil,
  tap,
  timer,
  distinctUntilChanged,
  take,
  Subject,
  switchMap,
  skip,
  finalize,
  of,
  firstValueFrom,
  pairwise,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { InviteTokenService, TabActivationService } from '../../services';
import { ClientSharedWorkerConnection, SharedWorkerService } from '../../services/shared-worker.service';
import { LoggerService } from '../../logger';
import { ResetPersistentData } from '@store/system';
import { OrganizationsState } from '@store/organizations-store/organizations.state';
import { TryAcceptInvite, SelectOrganizationById } from '@store/organizations-store/organizations.actions';
import { Router } from '@angular/router';
import { PROJECTS_ROUTE, SETTINGS_ROUTE } from 'src/app/app.routes';

interface AuthQueryState {
  registered?: boolean;
  inviteToken?: string;
}

@Injectable()
export class AuthService {
  private authSharedWorkerConn: ClientSharedWorkerConnection<WorkerAction>;
  private authConfig: AuthConfig;
  private authSharedWorkerReconnectTimeout: ReturnType<typeof setTimeout>;
  private readonly newRefreshTokenSetup$ = new Subject<void>();
  private readonly authInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private tokenAlreadyRefreshing: boolean = false;
  private alreadySignedIn$: Subject<boolean> = new Subject();

  private readonly registeredState = 'registered';

  public readonly accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentUser: Account;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly store: Store,
    private readonly sharedWorkerServie: SharedWorkerService,
    private readonly appService: AppService,
    private readonly tabActivationService: TabActivationService,
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly inviteTokenService: InviteTokenService
  ) {
    this.initAuthConfig();
    this.initSharedWorker();
    this.watchOauthEvents();
    this.configureOauthService();
    // DEBUG: fix/merge-auth-requests временные методы для отладки количества смены токенов
    this.watchAccessToken();
  }

  private tokenChanged = 0;

  private watchAccessToken(): void {
    this.accessToken$.pipe(distinctUntilChanged(), pairwise()).subscribe(([lastVal, newVal]) => {
      this.tokenChanged++;
      console.log(`[${new Date().toString()}] token changed ${this.tokenChanged} times!`);
      const prettyToken = (token: string): string => (token ? token.slice(0, 4) + '...' + token.slice(-4) : 'null');
      console.log('was: ', prettyToken(lastVal), 'became: ', prettyToken(newVal));
    });
  }

  private watchOfflineMode(): void {
    this.appService.online$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        filter((o) => o)
      )
      .subscribe(() => {
        this.setupTokenRefresh();
      });
  }

  private watchHybernationChanged(): void {
    this.tabActivationService.tabVisible$
      .pipe(
        distinctUntilChanged(),
        filter((v) => v)
      )
      .subscribe(() => {
        console.debug(`[${new Date().toString()}] Tab focused after invisible!`);

        if (!this.authInitialized$.getValue()) {
          console.debug(
            `[${new Date().toString()}] Do nothing after tab focused, cause application didnt verified yet`
          );
          return;
        }
        this.authSharedWorkerReconnectTimeout = setTimeout(() => {
          console.debug(
            `[${new Date().toString()}]` + 'Tab was hybernated and losted from shared worker connection scope'
          );
          this.initSharedWorker();
          this.setupTokenRefresh();
        }, 300);
        this.authSharedWorkerConn.emit(new VerifyAuthInfoAction(this.currentUser?.id));
      });
  }

  public get isAuthorized(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public forceRefreshToken(): void {
    console.debug('application already verified before token refreshed: ', this.authInitialized$.getValue());
    if (!this.authInitialized$.getValue()) {
      return;
    }
    this.invalidateAuthInfo();
    const noRefreshToken = !this.oauthService.getRefreshToken();
    if (noRefreshToken) {
      return;
    }
    this.safetyRefreshToken().subscribe();
  }

  /* We have already refreshed the token in another tab, so we just need to tell the auth service */
  /* This decision is inspired by https://github.com/manfredsteyer/angular-oauth2-oidc/issues/850#issuecomment-889921776 */
  private invalidateAuthInfo(): void {
    console.debug(`[${new Date().toString()}]` + 'RESTORE AUTH INFO UPDATED FROM ANOTHER TAB');
    (this.oauthService as any).eventsSubject.next(new OAuthSuccessEvent('token_received'));
    (this.oauthService as any).eventsSubject.next(new OAuthSuccessEvent('token_refreshed'));
    const e = new OAuthSuccessEvent('silently_refreshed');
    (this.oauthService as any).eventsSubject.next(e);
    this.accessToken$.next(this.accessToken);
    this.verifyLoggedInUser();
  }

  private checkCurrentOrganization(): void {
    const userClaims = this.oauthService.getIdentityClaims() as AuthClaims;
    console.log(`[${new Date().toString()}] user claims after organiation checked: `, userClaims);
    const organizationChanged =
      this.store.selectSnapshot(OrganizationsState.currentOrganization)?.id !== userClaims?.tid;
    console.log('PREVIOUS ORGANIZATION ID: ', this.store.selectSnapshot(OrganizationsState.currentOrganization)?.id);
    console.log('NEW ORGANIZATION ID: ', userClaims?.tid);

    if (organizationChanged) {
      this.mightBeChangeCurrentPage();
      this.store.dispatch(new SelectOrganizationById(userClaims?.tid));
    }
  }

  private mightBeChangeCurrentPage(): void {
    console.log('✎: [line 191][auth.service.ts] this.router.url: ', this.router.url);
    if (PROJECTS_ROUTE.match(this.router.url)) {
      this.router.navigateByUrl(SETTINGS_ROUTE.navigateUrl());
    }
  }

  private verifyLoggedInUser(): void {
    const userClaims = this.oauthService.getIdentityClaims() as AuthClaims;

    const userChanged = this.currentUser && userClaims?.sub !== this.currentUser?.id;

    if (userChanged || !userClaims) {
      this.tokenChanged = 0;
      console.debug(`[${new Date().toString()}]` + 'USER CHANGED');
      console.debug(`[${new Date().toString()}], new token changed count ${this.tokenChanged}`);
      this.refreshPage();
      return;
    }
    this.checkCurrentOrganization();

    this.currentUser = new Account(userClaims);
    this.store.dispatch(new SetAccount(this.currentUser));

    console.log(`${new Date().toString()} ✎: [line 150][auth.service.ts<2>] this.currentUser: `, this.currentUser);
  }

  private sharedTabAuthHandlers: { [key in WorkerEventType]?: (action?: WorkerAction) => void } = {
    [WorkerEventType.RefreshAccessToken]: () => {
      if (!this.authInitialized$.getValue()) {
        return;
      }
      console.debug(`[${new Date().toString()}]` + 'THIS TAB WILL REFRESH TOKEN!');
      this.forceRefreshToken();
    },
    [WorkerEventType.AuthInfoUpdated]: () => this.invalidateAuthInfo(),
    [WorkerEventType.VerifyAuthInfo]: () => this.preventSharedWorkerReconnect(),
    [WorkerEventType.VerifyUserAlreadySingedIn]: (action: VerifyUserAlreadySignedInAction) =>
      this.alreadySignedIn$.next(action.alreadySignedIn),
  };

  private initSharedWorker(): void {
    this.authSharedWorkerConn?.close();

    this.authSharedWorkerConn = this.sharedWorkerServie.registerSharedWorker('auth');

    this.authSharedWorkerConn.message$.pipe(takeUntil(this.authSharedWorkerConn.closed$)).subscribe((message) => {
      console.debug(
        `[${new Date().toString()}]` + '🦄: [line 71][auth.service.ts] message from shared worker: ',
        message
      );
      this.sharedTabAuthHandlers[message.type]?.(message);
    });

    this.authSharedWorkerConn.error$.pipe(takeUntil(this.authSharedWorkerConn.closed$)).subscribe((error) => {
      console.debug(`[${new Date().toString()}]` + '🦄: [line 76][auth.service.ts] error from shared worker: ', error);
      this.loggerService.error(error);
    });
  }

  private initAuthConfig(): void {
    console.log('✎: [line 255][auth.service.ts] environment: ', environment);
    this.authConfig = {
      issuer: environment.issuer,
      redirectUri: window.location.origin + '/',
      clientId: environment.clientId,
      scope: environment.scope,
      showDebugInformation: !environment.productionMode,
      responseType: 'code',
    };
  }

  private oauthHandlers: { [key in EventType]?: (arg0: OAuthEvent | OAuthSuccessEvent) => void } = {
    // TODO: VW-1209 Need to handle this situation
    discovery_document_load_error: () => console.warn('Could not load discovery document'),
    invalid_nonce_in_state: () => setTimeout(() => this.refreshPage(), 3000),
    token_validation_error: () => setTimeout(() => this.refreshPage(), 3000),
    token_refresh_error: () => this.verifyAlreadyExistingToken(),
  };

  private successfullyLoggedIn(): void {
    this.verifyLoggedInUser();
    this.accessToken$.next(this.accessToken);
    this.authSharedWorkerConn.emit(new TokenChangedAction(true));
    console.debug(
      `[${new Date().toString()}]` + '🦄: [line 126][auth.service.ts] this.accessToken after auth info updated: ',
      this.accessToken
    );
  }

  private watchOauthEvents(): void {
    this.oauthService.events.subscribe((e) => {
      console.debug(`[${new Date().toString()}]` + '🦄: [line 79][auth.service.ts] e: ', e);
      this.handleOauthEvent(e);
    });
  }

  private handleOauthEvent(event: OAuthEvent): void {
    const handler = this.oauthHandlers[event.type];
    handler?.(event);
    if (!handler && event instanceof OAuthErrorEvent) {
      console.error('OAuth error: ', event);
    }
  }

  private configureOauthService(): void {
    this.oauthService.configure(this.authConfig);
  }

  /** Verify if user is authorized after application start */
  public async init(): Promise<any> {
    console.debug('START VERIFY!!!!');

    await this.oauthService.loadDiscoveryDocument();

    console.log(`${new Date().toString()} ✎: [line 277][auth.service.ts<2>]url after redirect: `, window.location.href);
    // NOTE: Проверка на то, что входящий пользователь зарегестрирован в системе
    // до реальной обработки url параметров библиотекой для авторизации
    // т.к: 1) Библиотека обрабатывает параметры только после логина
    //      2) Операция по извлечению стейта проще чем верификация токенов.
    const params = new Proxy<any>(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });

    const isRegisteredUser = params.state?.split(';').find((param) => param === this.registeredState);
    console.log(
      `[${new Date().toString()}] is user registered? Should be true when we return from oidc auth page and false otherwise: `,
      isRegisteredUser
    );

    const isAnotherTabAlreadyRefreshedUser = await this.isAnotherTabAlreadyRefreshedUser();

    if (isAnotherTabAlreadyRefreshedUser && !isRegisteredUser) {
      console.log(`[${new Date().toString()}] Run flow for recover user from another tab after update`);
      this.runFlowForSyncingAuthInfo();
    } else {
      console.log(`[${new Date().toString()}] Run flow for getting user from auth code`);
      await this.runFlowForVerifyingAuthInfo();
    }

    this.watchOfflineMode();
    this.watchHybernationChanged();
    console.debug('VERIFIED!!!');
    console.debug('REFRESH TOKEN AFTER VERIFY: ', localStorage.getItem('refresh_token'));

    this.tryAcceptInviteToken().subscribe(() => {
      this.authInitialized$.next(true);
      this.setupTokenRefresh();
    });
  }

  private runFlowForSyncingAuthInfo(): void {
    this.invalidateAuthInfo();
  }

  private isAnotherTabAlreadyRefreshedUser(): Promise<boolean> {
    this.authSharedWorkerConn.emit(new VerifyUserAlreadySignedInAction());
    return firstValueFrom(this.alreadySignedIn$);
  }

  private async runFlowForVerifyingAuthInfo(): Promise<void> {
    await this.oauthService.tryLogin();

    console.debug('Is authorized after discovery document and try log in? ', this.isAuthorized);
    console.debug(
      'Has valid access token, id? :',
      this.oauthService.hasValidAccessToken(),
      this.oauthService.hasValidIdToken(),
      this.isAuthorized
    );

    if (!this.isAuthorized) {
      console.log('ANON START LOGGED IN');
      this.anonymousSignup();
      return;
    } else {
      this.successfullyLoggedIn();
      this.authSharedWorkerConn.emit(new AuthInfoUpdateAction(this.currentUser.id));
    }
  }

  public safetyRefreshToken(): Observable<TokenResponse> {
    console.log('Call refresh token http request!');
    if (this.tokenAlreadyRefreshing) {
      console.log('Someone wants to refresh token, but process already started!');
      return of(null);
    }
    this.tokenAlreadyRefreshing = true;
    return this.authInitialized$.pipe(
      take(1),
      switchMap(() => this.refreshToken()),
      finalize(() => (this.tokenAlreadyRefreshing = false))
    );
  }

  public joinOrganization(): void {
    this.refreshToken().subscribe();
  }

  private refreshToken(): Observable<any> {
    this.initAuthQueryParams();
    return from(this.oauthService.refreshToken()).pipe(
      tap((payload: TokenResponse) => {
        this.authSharedWorkerConn.emit(new AuthInfoUpdateAction(this.currentUser?.id));
        this.accessToken$.next(payload.access_token);
        this.setupTokenRefresh();
        this.checkCurrentOrganization();
        this.authSharedWorkerConn.emit(new TokenChangedAction(true));
        console.debug('TOKEN WAS UPDATED! NEW SETUP INITED');
      })
    );
  }

  // DETAILS: https://verifika.youtrack.cloud/issue/VW-1361/Organizacii-i-dobavlenie-novyh-polzovatelej#focus=Comments-83-26984.0-0
  private initAuthQueryParams(): void {
    this.oauthService.customQueryParams = {
      tenant: this.store.selectSnapshot(OrganizationsState.joinedOrganizationId),
    };
  }

  private setupTokenRefresh(): void {
    console.log('Setup token refresh');
    // NOTE: Ситуация при которой вторая таба очистила стейт перед аутентификацией
    const noRefreshToken = !this.oauthService.getRefreshToken();
    console.log('✎: [line 307][auth.service.ts] noRefreshToken: ', noRefreshToken);

    if (!this.authInitialized$.getValue() || noRefreshToken) {
      return;
    }
    this.newRefreshTokenSetup$.next();
    this.invalidateAuthInfo();
    const tokenExpiration = this.oauthService.getAccessTokenExpiration();
    console.debug(
      `[${new Date().toString()}]` + '🦄: [line 189][auth.service.ts] tokenExpiration: ',
      new Date(tokenExpiration)
    );
    const tokenRefreshTime = tokenExpiration;
    const timeBeforeTokenExpired = tokenRefreshTime - Date.now();
    const percentForRefresh = 0.8;
    const timeout = timeBeforeTokenExpired * percentForRefresh;

    if (timeBeforeTokenExpired <= 0) {
      console.debug('TIMEOUT LESS THEN 0!', timeout);
      this.anonymousSignup();
      return;
    }

    timer(timeout)
      .pipe(
        takeUntil(
          this.appService.online$.pipe(
            distinctUntilChanged(),
            filter((online) => !online)
          )
        ),
        takeUntil(this.newRefreshTokenSetup$)
      )
      .subscribe(() => {
        this.authSharedWorkerConn.emit(new RefreshAccessTokenAction());
        console.debug(`[${new Date().toString()}]` + 'Emit action for update token in another tabs');
      });
  }

  #login(params?: object, additionalState: AuthQueryState = {}): void {
    const stringifiedAdditionalState = JSON.stringify(additionalState);
    this.store.dispatch(new ResetPersistentData()).subscribe(() => {
      this.oauthService.initCodeFlow(stringifiedAdditionalState, params);
    });
  }

  public login(prompt = 'login'): void {
    this.authSharedWorkerConn.emit(new TokenChangedAction(false, true));
    const authQueryState = this.getAuthQueryStateForRegisteredUser();
    const inviteTokenQueryState = this.addOptionalInviteToken(authQueryState);
    this.#login({ prompt }, inviteTokenQueryState);
  }

  private addOptionalInviteToken(params: AuthQueryState = {}): AuthQueryState {
    this.inviteTokenService.initInviteToken();
    const inviteToken = this.store.selectSnapshot(OrganizationsState.inviteToken);
    return {
      ...params,
      inviteToken,
    };
  }

  private getAuthQueryStateForRegisteredUser(): AuthQueryState {
    const queryAuthState: AuthQueryState = {
      registered: true,
    };
    return queryAuthState;
  }

  private tryAcceptInviteToken(): Observable<void> {
    const additionalState = this.oauthService.state;
    if (!additionalState) {
      return of(null);
    }
    const state = JSON.parse(decodeURIComponent(additionalState)) as AuthQueryState;

    if (state.inviteToken) {
      return this.store.dispatch(new TryAcceptInvite(state.inviteToken));
    }
    return of(null);
  }

  private anonymousSignup(): void {
    this.clearAuthState();
    const additionalState = this.addOptionalInviteToken();
    const params = additionalState.inviteToken ? { prompt: 'login' } : { acr_values: 'anonymous:login' };

    console.debug(`[${new Date().toString()}]` + 'Real anonymous signup');
    this.#login(params, additionalState);
  }

  private verifyAlreadyExistingToken(): void {
    console.log('Verify already existing token and try update it');
    // NOTE: before we start refreshing the token we need to check if another tab has already accepted the new token
    // if its true anonymous sign up should not be called
    this.invalidateAuthInfo();
    this.anonymousSignup();
  }

  private preventSharedWorkerReconnect(): void {
    console.debug(`[${new Date().toString()}]` + 'Tab was hibernated and not left shared worker connection scope');
    clearTimeout(this.authSharedWorkerReconnectTimeout);
  }

  public logout(): void {
    this.clearAuthState();
    this.oauthService.logOut(true);
    this.store.dispatch(new ResetPersistentData()).subscribe(() => {
      console.debug('REFRESH TOKEN BEFORE LOGOUT: ', localStorage.getItem('refresh_token'));
      this.anonymousSignup();
    });
  }

  private clearAuthState(): void {
    this.authSharedWorkerConn.emit(new TokenChangedAction(false, true));
    console.debug(`[${new Date().toString()}]` + 'LOGOUT!');
  }

  private refreshPage(): void {
    console.log('REFRESH PAGE!');
    window.location.assign(SETTINGS_ROUTE.navigateUrl());
  }
}
