import { NgModule } from '@angular/core';
import { ActionIconModule } from '../action-icon';
import { ShortcutsHelperBtnComponent } from './shortcuts-helper-btn.component';

@NgModule({
  imports: [ActionIconModule],
  declarations: [ShortcutsHelperBtnComponent],
  exports: [ShortcutsHelperBtnComponent],
})
export class ShortcutsHelperBtnModule {}
