import { SegmentElementType, TextSegmentElement } from '@shared/models';
import { propComparator } from '@shared/tools/comparator';
import { checkRangesIntersection } from '@shared/tools/selection-range-extensions';
import { removeSubstring } from '@shared/tools/string';
import { deepClone } from '@shared/tools/deep-clone';
import { Segment, SegmentElement, SelectionRange } from '@generated/api';
import { isDelimiterChar } from '.';

export function insertSegment(baseSegment: Segment, segment: Segment, startIndex: number): Segment {
  const newSegmentElements = mergeSegmentElements(baseSegment.elements, segment.elements, startIndex);
  const text = newSegmentElements.map((item) => item.text).join('');
  const newSegment = {
    elements: newSegmentElements,
    hasChanges: false,
    text,
    originalText: text,
  };
  return newSegment;
}

export function removeSegment(segment: Segment, startIndex: number, length: number) {
  const newSegmentElements = removeSegmentElements(segment.elements, startIndex, length);
  const text = newSegmentElements.map((item) => item.text).join('');
  return {
    elements: newSegmentElements,
    hasChanges: false,
    text,
    originalText: text,
  } as Segment;
}

export function createNewSegmentFromSelection(segment: Segment, selection: SelectionRange): Segment {
  let newSegment = deepClone(segment);
  const newSegmentLength = newSegment.text.length;
  if (selection.end < newSegmentLength) {
    newSegment = removeSegment(newSegment, selection.end, newSegmentLength - selection.end);
  }
  if (selection.start > 0) {
    newSegment = removeSegment(newSegment, 0, selection.start);
  }
  return newSegment;
}

export function removeSegmentElements(
  elements: SegmentElement[],
  removeStartIndex: number,
  length: number
): SegmentElement[] {
  const sortedElements = elements.sort(propComparator('start'));
  const result: SegmentElement[] = [];
  const removeEndIndex = removeStartIndex + length;
  let newRemoveStartIndex = removeStartIndex;

  sortedElements.forEach((element) => {
    if (checkRangesIntersection([element.start, element.end], [removeStartIndex, removeEndIndex])) {
      const elementNewStart = result[result.length - 1]?.end || 0;
      const startIndexElementTextRemove = newRemoveStartIndex - element.start;
      let elementRemoveLength = removeEndIndex - newRemoveStartIndex;
      if (elementRemoveLength > element.length - startIndexElementTextRemove) {
        elementRemoveLength = element.length - startIndexElementTextRemove;
      }
      const elementNewText = removeSubstring(element.text, startIndexElementTextRemove, elementRemoveLength);
      if (elementNewText.length) {
        result.push({
          ...element,
          start: elementNewStart,
          end: elementNewStart + elementNewText.length,
          text: elementNewText,
          length: elementNewText.length,
        });
      }

      newRemoveStartIndex += elementRemoveLength;
    } else if (removeEndIndex <= element.start) {
      result.push({
        ...element,
        start: element.start - length,
        end: element.end - length,
      });
    } else {
      result.push(element);
    }
  });
  return result;
}

/*
 * Merge two segment elements lists into one.
 * Neighbor elements will be merged into one when this elements have the same type.
 */
export function mergeSegmentElements(
  baseList: SegmentElement[],
  list: SegmentElement[],
  startIndex: number
): SegmentElement[] {
  const length = list.reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);
  const resultList: SegmentElement[] = [];
  const newBaseSegmentElements = changeSegmentElementsPosition(baseList, startIndex, length);
  const newSegmentElements = changeSegmentElementsPosition(list, 0, startIndex);
  newBaseSegmentElements
    .concat(newSegmentElements)
    .sort(propComparator('start'))
    .forEach((element) => {
      const lastElement = resultList[resultList.length - 1];
      if (
        element.elementType === SegmentElementType.Text &&
        lastElement &&
        lastElement.elementType === SegmentElementType.Text
      ) {
        resultList[resultList.length - 1] = {
          elementType: SegmentElementType.Text,
          text: lastElement.text + element.text,
          start: lastElement.start,
          length: lastElement.length + element.length,
          end: lastElement.start + lastElement.length + element.length,
        } as TextSegmentElement;
      } else {
        resultList.push(element);
      }
    });
  return resultList;
}

function changeSegmentElementsPosition(
  segmentElement: SegmentElement[],
  startWithPosition: number,
  offset: number
): SegmentElement[] {
  const newElements: SegmentElement[] = [];
  segmentElement.forEach((element) => {
    if (element.start >= startWithPosition) {
      newElements.push({
        ...element,
        start: element.start + offset,
        end: element.end + offset,
      });
      return;
    }
    if (
      element.start < startWithPosition &&
      element.end > startWithPosition &&
      element.elementType === SegmentElementType.Text
    ) {
      const elementOffset = startWithPosition - element.start;
      newElements.push({
        elementType: SegmentElementType.Text,
        start: element.start,
        end: startWithPosition,
        text: element.text.substring(0, elementOffset),
        length: elementOffset,
      });
      newElements.push({
        elementType: SegmentElementType.Text,
        text: element.text.substring(elementOffset),
        start: element.start + elementOffset + offset,
        end: element.end + offset,
        length: element.length - elementOffset,
      });
      return;
    }
    newElements.push(element);
  });
  return newElements;
}

function extendTextSegmentElementsByAtomics(segmentElement: SegmentElement): SegmentElement[] {
  let startSplitPosition = 0;
  const extendedElements: SegmentElement[] = [];

  for (let i = 0; i < segmentElement.text.length; i++) {
    const ch: string = segmentElement.text[i];
    const endOfLine = i === segmentElement.text.length - 1;
    const isDelimiter = isDelimiterChar(ch);
    if (!isDelimiter && !endOfLine) {
      continue;
    }
    const endPos = isDelimiter ? i : i + 1;
    const text = segmentElement.text.slice(startSplitPosition, endPos);
    if (text.length) {
      extendedElements.push({
        elementType: SegmentElementType.Text,
        text,
        start: segmentElement.start + startSplitPosition,
        end: segmentElement.start + endPos,
        length: text.length,
      });
    }
    if (isDelimiter) {
      extendedElements.push({
        elementType: SegmentElementType.Text,
        text: ch,
        start: segmentElement.start + i,
        end: segmentElement.start + i + 1,
        length: 1,
      });
    }
    startSplitPosition = i + 1;
  }
  return extendedElements;
}
/*
 *  Split elements of segment by spaces.
 *  Be careful with this function. This function will mutates argument.
 */
export function extendSegmentByAtomics(segment: Segment): Segment {
  const extendedElements = segment.elements.reduce<SegmentElement[]>((elements, currentEl) => {
    if (currentEl.elementType === SegmentElementType.Tag) {
      return [...elements, currentEl];
    }
    return [...elements, ...extendTextSegmentElementsByAtomics(currentEl)];
  }, []);

  return { ...segment, elements: extendedElements };
}
