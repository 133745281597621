<div *ngFor="let group of groupedShortcutsReference | keyvalue: asInOrder" class="keygroup">
  <div class="keygroup-title">
    {{ group.key }}
  </div>

  <ng-container *ngFor="let subGroup of group.value" class="shortcut-description">
    <!-- NOTE:  component instead of ng-template cause has lack of typecheking -->
    <app-shortcut-preview [shortcutReferences]="subGroup" [groupLevel]="0"> </app-shortcut-preview>
  </ng-container>
</div>
