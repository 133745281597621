import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellEditingStoppedEvent, ColDef, ColumnApi, NewValueParams } from '@ag-grid-community/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AgGridColumn, GridApi, GridReadyEvent } from 'src/types/ag-grid';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  GridHeaderComponent,
  ListCellEditorComponent,
  MultilineCellRendererComponent,
  NoRowsOverlayComponent,
  SearchedCellRendererComponent,
} from '@shared/components/grid-components';

@Component({
  template: '',
})
export abstract class BaseSettingsGridComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {
  searchControl = new FormControl('');
  isEditing = false;
  isRowSelected: boolean = false;
  destroyed$ = new Subject<void>();
  gridApi: GridApi;
  columnApi: ColumnApi;
  _data: T[] = [];

  @Input() set data(value: T[]) {
    this.setData(value);
  }

  @Input() disabled: boolean;

  @Output() changeValue = new EventEmitter<T[]>();

  abstract columnDefs: Partial<AgGridColumn>[];
  abstract overlayNoRowsText: string;

  public modules = [ClientSideRowModelModule];
  public defaultColDef: ColDef<T> = {
    sortable: true,
    editable: true,
  };
  public gridComponents: { [k: string]: any } = {
    agColumnHeader: GridHeaderComponent,
    searchedRenderer: SearchedCellRendererComponent,
    customNoRowsOverlay: NoRowsOverlayComponent,
    multilineRenderer: MultilineCellRendererComponent,
    [ListCellEditorComponent.rendererName]: ListCellEditorComponent,
  };
  noRowsOverlayComponent = NoRowsOverlayComponent.rendererName;
  overlayText = 'No matches';

  public abstract add(): void;

  public abstract remove(): void;

  public abstract rowsIsEqual(aItem: T, bItem: T): boolean;

  public abstract onCellChanged(event: NewValueParams): void;

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.setRowData(this._data);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public editStarted(): void {
    this.isEditing = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public editStopped(event: CellEditingStoppedEvent<T>): void {
    this.isEditing = false;
  }

  public onRowSelected(): void {
    const rows = this.getSelected();
    this.isRowSelected = !!rows.length;
  }

  private initSubscriptions(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(300), distinctUntilChanged())
      .subscribe((text: string) => {
        this.gridApi.setQuickFilter(text);
      });
  }

  public getSelected(): T[] {
    if (!this.gridApi) {
      return [];
    }
    return this.gridApi.getSelectedRows() || [];
  }

  public startEdit(row: number, column: string): void {
    this.gridApi.setFocusedCell(row, column);
    this.gridApi.startEditingCell({
      rowIndex: row,
      colKey: column,
    });
  }

  private compareData(aData: T[], bData: T[]): boolean {
    if (!aData || !bData || !aData.length || !bData.length) {
      return false;
    }

    if (aData.length !== bData.length) {
      return false;
    }

    for (let i = 0; i < aData.length; i++) {
      const aItem: T = aData[i];
      const bItem: T = bData[i];
      if (this.rowsIsEqual(aItem, bItem)) {
        return true;
      }
    }
    return false;
  }

  private setData(value: T[]): void {
    if (this.compareData(this._data, value)) {
      return;
    }
    this._data = value || [];
    this.gridApi?.setRowData(this._data);
  }

  public propagateChanges(): void {
    this.changeValue.emit(this._data);
    this.onChange(this._data);
    this.onTouched();
  }

  // region ControlValueAccessor feature

  onChange: any = () => {};

  onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: T[]): void {
    this.setData(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // endregion
}
