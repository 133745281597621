<app-codemirror-wrapper
  [ngClass]="{ 'suppress-editing': suppressEditing }"
  [appContextClick]="contextMenuTemplate"
  [contextMenuOpenManual]="true"
  [rightToLeft]="rightToLeft"
  [readonly]="readonly || suppressEditing"
  [multiline]="multiline"
  [contextMenu]="enabledContextMenu"
  [selectable]="selectable"
  [showInvisibles]="showInvisibles"
  (editorReady)="editorReady($event)"
  (altKeyDown)="altKeyDownHandler()"
  (altKeyUp)="altKeyUpHandler()"
  (altKeyDownAndNumberKeyPressed)="altKeyPressedAndNumberKeyPressedHandler($event)"
  (enterKeyPressed)="enterKeyPressedHandler($event)"
  (keyDown)="editorKeydown($event)"
  (editorDragStart)="dragStartHandler($event)"
  (editorCopy)="copyHandler()"
  (editorCut)="cutHandler()"
  (editorBlue)="blurHandler()"
  (editorBeforeSelectionChange)="beforeSelectionChangeHandler($event)"
  (editorDrop)="dropHandler($event)"
  (editorBeforeChange)="beforeChangeHandler($event)"
  (editorChange)="changeHandler($event)"
  (contextMenuPressed)="handleContextMenu($event)"
  (editorFocus)="focusHandler()"
  (clicked)="clickedHandler()"
  (contextMenuClosed)="contextMenuClosed()"
></app-codemirror-wrapper>

<ng-template #contextMenuTemplate>
  <ng-container *ngIf="fixedRenderItems?.length; else specialSymbolsTemplate">
    <div class="autofix-description">{{ autofixDescription }}</div>
    <div
      *ngFor="let ri of fixedRenderItems; let i = index"
      [class.active]="i === activeAutofixIndex"
      class="autofix-body"
      (click)="acceptCurrentAutofix()"
    >
      <app-segment-renderer [renderItems]="ri" [showInvisible]="true" [disableErrorHighlight]="true">
      </app-segment-renderer>
    </div>
  </ng-container>

  <ng-template #specialSymbolsTemplate>
    <app-special-symbols (inserted)="insertSymbol($event)"></app-special-symbols>
  </ng-template>
</ng-template>
