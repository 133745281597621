import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: 'tooltip',
  exportAs: 'tooltip',
})
export class TooltipElementDirective {
  @HostBinding('style.display') hostDisplay: string;

  constructor(public elementRef: ElementRef<HTMLElement>) {
    this.hostDisplay = 'none';
  }
}
