<app-ui-modal [size]="'large'">
  <app-action-icon
    size="lg"
    class="back-btn"
    theme="subtle"
    icon="logout"
    (clickButton)="logoutBtnClickHandler()"
    appTooltip="Disconnect"
    appTooltipPlacement="bottom-right"
  ></app-action-icon>
  <div class="logo">
    <img [src]="logoPath" alt="logo" />
  </div>
  <div class="container">
    <div class="projects-list" *ngIf="hasProjects">
      <app-search-list
        [items]="projects"
        [selectedItem]="selectedProject"
        idKey="id"
        nameKey="name"
        (selected)="projectSelected($event)"
        placeholder="Search projects..."
        [listHeight]="210"
      ></app-search-list>
    </div>
    <div class="no-projects-placeholder" *ngIf="!hasProjects && !loading">
      <div class="no-projects-text">
        <app-ui-icon class="no-projects-icon" [icon]="'folder'" [size]="'small'"></app-ui-icon>
        No projects to add.
      </div>
      <div>Projects either have not been created or have limited access permission.</div>
    </div>
    <div class="vertical-line" *ngIf="hasProjects"></div>
    <div class="files-list" *ngIf="hasFiles">
      <app-search-list
        [items]="files"
        [selectedItems]="selectedFiles"
        [listHeight]="166"
        (selected)="filesSelected($event)"
        idKey="id"
        nameKey="name"
        selectMode="multiple"
        placeholder="Search project files..."
      ></app-search-list>
    </div>
    <div class="no-files-placeholder" *ngIf="!hasFiles && !loading && hasProjects">
      <app-ui-icon class="no-files-icon" [icon]="'insert-drive-file-mod'" [size]="'small'"></app-ui-icon>
      No files to add
    </div>
    <div class="loader" *ngIf="loading && (!hasProjects || !hasFiles)" [appLoader]="true"></div>
  </div>
  <div class="footer">
    <app-button
      class="add-btn"
      (clickButton)="addBtnClickHandler()"
      [disabled]="!selectedFiles?.length"
      theme="primary"
    >
      ADD TO VERIFIKA
    </app-button>
  </div>
</app-ui-modal>
