import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { SelectionRange, TagView } from '@shared/models';
import { SegmentEditorComponent } from '@shared/components/segment-editor';
import { ColumnApi } from '@ag-grid-community/core';
import { ICellEditorAngularComp, ICellEditorParams, RowNode, GridApi } from 'src/types/ag-grid';
import { Segment } from '@generated/api';
import { KeybindingGroups, KeyCodes, ShortcutsService } from 'src/app/core/services/shortcuts';

interface CellEditorParams extends ICellEditorParams {
  isTarget: boolean;
  // Временное решение, можно убрать когда редактор будет работать с RenderItem
  originalSegmentGetter: (params: ICellEditorParams) => Segment;
  originalSelectionRangesGetter: (params: ICellEditorParams) => SelectionRange[];
  rightToLeftGetter: (params: ICellEditorParams) => boolean;
  context: {
    tagView: TagView;
    showInvisibles: boolean;
  };
}

const MINIMAL_ROW_HEIGHT = 55;
const CELL_PADDING = 16;

@Component({
  selector: 'app-target-segment-editor',
  templateUrl: './segment-cell-editor.component.html',
  styleUrls: ['./segment-cell-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  isTarget = true;
  tagView: TagView = TagView.Medium;
  showInvisibles = false;
  gridApi: GridApi;
  rowNode: RowNode;
  segment: Segment;
  selectionRanges: SelectionRange[] = [];
  rightToLeft: boolean;
  lastPressedKeyEscape = false;
  initialCellHeight: number;
  editorHeight: number;
  private columnApi: ColumnApi;
  public editorsSharingRoomKey = 'context-viewer-editors';

  @ViewChild('editor', { static: true })
  editorComponent: SegmentEditorComponent;
  @ViewChild('wrapperDivElement', { static: true })
  htmlDivElement: ElementRef<HTMLDivElement>;

  public agInit(params: CellEditorParams): void {
    this.isTarget = params.isTarget;
    this.tagView = params.context.tagView;
    this.showInvisibles = params.context.showInvisibles;
    this.initialCellHeight = params.eGridCell.clientHeight;
    this.columnApi = params.columnApi;
    this.rowNode = params.node;
    this.gridApi = params.api;
    this.segment = params.originalSegmentGetter(params);
    this.rightToLeft = params.rightToLeftGetter(params);
    if (params.originalSelectionRangesGetter) {
      this.selectionRanges = params.originalSelectionRangesGetter(params);
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private shortcutsService: ShortcutsService) {}

  ngAfterViewInit(): void {
    if (this.isTarget) {
      setTimeout(() => {
        this.editorComponent.refreshEditorSize();
        this.editorComponent.focus();
        this.editorComponent.setCursor();
        this.setCellHeight();
        this.changeDetectorRef.detectChanges();
      }, 0);
    }
  }

  public getValue(): any {
    return this.segment;
  }

  public segmentChanged(segment: Segment): void {
    this.segment = segment;
    this.gridApi.stopEditing();
    this.focusActiveRow();
  }

  public targetChangesHandler(): void {
    this.gridApi.stopEditing();
  }

  public isCancelAfterEnd(): boolean {
    if (this.isTarget) {
      return this.lastPressedKeyEscape;
    }
    return true;
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.shortcutsService.handleKeydownEvent(KeybindingGroups.EDITOR, event, this.editorsSharingRoomKey);
    const pressedKey = event.code;
    this.lastPressedKeyEscape = pressedKey === KeyCodes.KEY_ESCAPE;
    if (this.lastPressedKeyEscape) {
      event.stopPropagation();
      this.gridApi.stopEditing();
      this.focusActiveRow();
    }
  }

  private focusActiveRow(): void {
    this.gridApi.setFocusedCell(this.rowNode.rowIndex, this.columnApi.getAllDisplayedColumns()[0]);
  }

  public setCellHeight(): void {
    if (this.htmlDivElement.nativeElement.clientHeight !== this.editorHeight) {
      this.editorHeight = this.htmlDivElement.nativeElement.clientHeight;
      const newHeight = this.editorHeight + CELL_PADDING;
      if (newHeight > MINIMAL_ROW_HEIGHT && newHeight > this.initialCellHeight) {
        this.rowNode.setRowHeight(newHeight);
        this.gridApi.onRowHeightChanged();
      }
    }
  }

  public onClickHandler(event: any): void {
    event.stopPropagation();
  }
}
