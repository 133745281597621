<header id="layout-header">
  <div class="header-nav">
    <a (click)="openSettings()" class="logo">
      <app-ui-icon size="auto" icon="logo"></app-ui-icon>
    </a>
    <nav class="header-menu">
      <ng-content></ng-content>
      <app-button
        *ngIf="!(app.online$ | async)"
        class="badge-offline menu-action-icon-btn"
        theme="outlined"
        prefixIcon="offline"
        [appTooltip]="offlineTooltipText"
        appTooltipPlacement="bottom"
        size="sm"
        >Offline</app-button
      >
      <app-button
        *ngIf="showUpdate && (app.online$ | async)"
        class="badge-update menu-action-icon-btn"
        theme="outlined"
        prefixIcon="check"
        appTooltip="New version of application available.&#13;You can reload application for update."
        appTooltipPlacement="bottom"
        size="sm"
        >Update available</app-button
      >
    </nav>
  </div>
  <div class="header-info">
    <div class="comment" *ngIf="isReportPage">
      <app-action-icon
        [active]="commentsPopupOpened"
        class="menu-action-icon-btn"
        theme="circle"
        (clickButton)="openComments()"
        icon="comment"
        size="xl"
      ></app-action-icon>
      <div *ngIf="hasOpenComments | async" class="ellipse"></div>
    </div>
    <div
      *whenRole="'anonymous'; else registeredUserTemplate"
      class="anonymous-user"
      [appTooltipTriggerFor]="anonymousTooltip"
    >
      <div>Anonymous</div>
      <app-button (clickButton)="login()" prefixIcon="enter"> Log in </app-button>
    </div>
    <ng-template #registeredUserTemplate>
      <app-ui-dropdown-menu #dropdownMenuComponent popupClass="organizations-popup">
        <app-button dropdown-trigger class="username-btn" theme="outlined" [active]="dropdownMenuComponent.isOpen">
          {{ (userInfo$ | async)?.name }}
          <div *ngIf="currentOrganization$ | async; else profileIconTemplate" class="organization-icon">
            {{ (currentOrganization$ | async).name | truncate: 40 }}
          </div>
          <ng-template #profileIconTemplate>
            <div class="profile-icon">
              <app-ui-icon icon="user" color="gray"></app-ui-icon>
            </div>
          </ng-template>
        </app-button>
        <div dropdown-content>
          <app-organizations-manager></app-organizations-manager>
        </div>
      </app-ui-dropdown-menu>
    </ng-template>

    <ng-content select="[rightActions]"></ng-content>
  </div>
</header>

<tooltip #anonymousTooltip="tooltip">
  <p>Anonymous users can use all the functionality of Verifika.</p>
  <p>
    Verifika stores your work for 32 hours. Then it is deleted. Reopen Verifika to extend your work session for another
    32 hours.
  </p>
</tooltip>
