import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BalanceModel, SubscriptionDetails, SubscriptionStatus } from '@generated/payment-api';
import { Select, Store } from '@ngxs/store';
import { PaymentState } from '@store/payment-store';
import { Observable, Subject, takeUntil } from 'rxjs';

type BadgeStatus = 'info' | 'warning' | 'error';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionStatusComponent implements OnInit, OnDestroy {
  @Input() type: 'short' | 'full' = 'full';

  @Input() showProgress: boolean = false;

  public readonly destroyed$ = new Subject<void>();
  public balance: BalanceModel;
  public subscriptionDetails: SubscriptionDetails;

  @Select(PaymentState.subscriptionDetails)
  public readonly subscriptionDetails$: Observable<SubscriptionDetails>;

  public readonly SubscriptionStatus = SubscriptionStatus;

  private readonly warningPercentResetProjects: number = 0.5;

  constructor(private readonly store: Store, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.watchBalance();
    this.watchSubscriptionStatus();
  }

  get subscriptionStatus(): BadgeStatus {
    if (this.showProgress) {
      return this.getBalanceStatus();
    }
    return this.getSubscriptionStatus();
  }

  private getBalanceStatus(): BadgeStatus {
    if (this.subscriptionDetails && this.subscriptionDetails.status !== SubscriptionStatus.Active) {
      return 'error';
    }
    if (this.balance.projectUsage >= this.balance.projectTotal) {
      return 'error';
    }
    const warningLevel = this.balance.projectUsage / this.balance.projectTotal >= this.warningPercentResetProjects;
    if (warningLevel) {
      return 'warning';
    }
    return 'info';
  }

  private getSubscriptionStatus(): BadgeStatus {
    if (!this.subscriptionDetails || this.subscriptionDetails?.status === SubscriptionStatus.Active) {
      return 'info';
    }
    return 'error';
  }

  private watchBalance(): void {
    this.store
      .select(PaymentState.balance)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((balance) => {
        this.balance = balance;
        this.cdr.detectChanges();
      });
  }

  private watchSubscriptionStatus(): void {
    this.subscriptionDetails$.pipe(takeUntil(this.destroyed$)).subscribe((subscriptionDetails) => {
      this.subscriptionDetails = subscriptionDetails;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
