import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-multiline-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!eachElementInNewLine; else eachElementInNewLineTemplate">
      <span
        *ngFor="let val of values; let isLast = last"
        [innerHTML]="(val | markFiltered: searchedValue) + (!isLast ? ', ' : '')"
      ></span>
    </ng-container>

    <ng-template #eachElementInNewLineTemplate>
      <div *ngFor="let val of values" [innerHTML]="val | markFiltered: searchedValue"></div>
    </ng-template>
  `,
})
export class MultilineCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  values: string[];
  searchedValue: string;
  eachElementInNewLine = false;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  public agInit(params: any): void {
    this.params = params;
    this.values = params.value;
    this.eachElementInNewLine = this.params.eachElementInNewLine;
    const search = this.params.searched();
    if ((search && typeof search === 'string') || !search) {
      this.searchedValue = search;
      return;
    }
    this.watchSearch(search as Observable<string>);
  }
  public refresh(): boolean {
    return false;
  }

  private watchSearch(search$: Observable<string>): void {
    search$.pipe(takeUntil(this.destroyed$)).subscribe((searchText) => {
      this.searchedValue = searchText || '';
      this.cdr.detectChanges();
    });
  }
}
