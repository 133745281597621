import { ProjectInfo } from '@generated/api';

export enum ActionTypes {
  SET_PROJECT = '[Project] Set Project',
  PATCH_PROJECT = '[Project] Patch Project',
  LOAD_PROJECT = '[Project] Load Project',
  LOAD_PROJECT_SUCCESS = '[Project] Load Project Success',
  LOAD_PROJECT_FAILURE = '[Project] Load Project Failure',

  TOUCH_PROJECT = '[Project] Touched Project',
  RENAME_PROJECT = '[Project] Rename Project',

  TOGGLE_PROJECT_QASETTINGS = '[Project] Toggle Project QASettings',
  TOGGLE_PROJECT_QASETTINGS_FAILURE = '[Project] Project Toggle QASettings Failure',
  TOGGLE_PROJECT_QASETTINGS_SUCCESS = '[Project] Project Toggle QASettings Success',
}

export class SetCurrentProject {
  static readonly type = ActionTypes.SET_PROJECT;
  constructor(public project: ProjectInfo) {}
}

export class PatchCurrentProject {
  static readonly type = ActionTypes.PATCH_PROJECT;
  constructor(public project: Partial<ProjectInfo>) {}
}

export class LoadProject {
  static readonly type = ActionTypes.LOAD_PROJECT;
  constructor(public id: string) {}
}

export class LoadProjectSuccess {
  static readonly type = ActionTypes.LOAD_PROJECT_SUCCESS;
  constructor(public project: ProjectInfo) {}
}

export class LoadProjectFail {
  static readonly type = ActionTypes.LOAD_PROJECT_FAILURE;
  constructor(public error: any) {}
}

export class TouchedProject {
  static readonly type = ActionTypes.TOUCH_PROJECT;
  constructor(public id: string) {}
}

export class RenameProject {
  static readonly type = ActionTypes.RENAME_PROJECT;
  constructor(public id: string, public name: string) {}
}

export class ToggleProjectQASettings {
  static readonly type = ActionTypes.TOGGLE_PROJECT_QASETTINGS;
  constructor(public id: string, public qaSettingsId: string) {}
}

export class ToggleProjectQASettingsSuccess {
  static readonly type = ActionTypes.TOGGLE_PROJECT_QASETTINGS_SUCCESS;
  constructor(public project: ProjectInfo) {}
}

export class ToggleProjectQASettingsFail {
  static readonly type = ActionTypes.TOGGLE_PROJECT_QASETTINGS_FAILURE;
  constructor(public error: any) {}
}

export type Actions =
  | SetCurrentProject
  | LoadProject
  | RenameProject
  | TouchedProject
  | ToggleProjectQASettings
  | ToggleProjectQASettingsSuccess
  | ToggleProjectQASettingsFail;
