<div class="special-symbols">
  <div class="title">{{ title }}</div>
  <div class="search-wrapper">
    <app-ui-search-input [formControl]="searchField" placeholder="Search..."></app-ui-search-input>
  </div>

  <div class="symbols-wrapper">
    <div class="header">
      <div class="tab" [class.active]="isFrequentTab" role="button" (click)="isFrequentTab = true">
        <app-ui-icon [color]="!isFrequentTab ? 'secondary' : 'primary'" icon="clock"></app-ui-icon>
        Frequent
      </div>
      <div class="tab" [class.active]="!isFrequentTab" role="button" (click)="isFrequentTab = false">
        <app-ui-icon [color]="isFrequentTab ? 'secondary' : 'primary'" icon="copyright"></app-ui-icon>
        Symbols
      </div>
    </div>

    <div class="body">
      <ng-container *ngIf="!isFrequentTab; else frequentTabTemplate">
        <div *ngIf="!symbolsGroups.length; else notFoundTemplate" class="no-symbols">No matches</div>
        <ng-template #notFoundTemplate>
          <div class="symbol-group" *ngFor="let key of symbolsGroups">
            <div class="group-title">{{ key }}</div>
            <div class="symbols">
              <div
                *ngFor="let s of filteredGroupedSpecSymbols.get(key)"
                class="symbol"
                (click)="selectSymbol(s)"
                [class.active]="selectedSymbol?.unicodeNumber === s.unicodeNumber"
              >
                {{ s.visual || s.symbol }}
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div class="footer">
      <div class="symbol-info">
        <span class="symbol-description">{{ selectedSymbol?.description }}</span>
      </div>
      <app-button theme="primary" (clickButton)="insertSymbol()" class="insert-btn" [disabled]="!selectedSymbol"
        >Insert</app-button
      >
    </div>
  </div>
</div>

<ng-template #frequentTabTemplate>
  <div *ngIf="!frequentSymbols.length; else frequentSymbolsTemplate" class="no-symbols">
    No frequently used symbols yet
  </div>

  <ng-template #frequentSymbolsTemplate>
    <div class="frequent-symbols">
      <div class="symbols">
        <div
          *ngFor="let cs of frequentSymbols"
          class="symbol"
          (click)="selectSymbol(cs.symbol)"
          [class.active]="selectedSymbol?.unicodeNumber === cs.symbol.unicodeNumber"
        >
          {{ cs.symbol.visual || cs.symbol.symbol }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
