import type { QASettingsWithTemplate } from '@shared/models';
import { State, Action, Selector, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  LoadQASettings,
  SetCurrentQASettings,
  LoadQASettingsSuccess,
  LoadQASettingsFail,
  RenameQASettings,
  PatchQASettings,
  ResetSettings,
  ClearSettings,
} from './qa-settings.actions';
import { tap, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import { Observable, of } from 'rxjs';
import { nameof } from '@shared/tools';
import { LoadLocaleQASettingsList } from '@store/locale-qa-settings-store/locale-qa-settings.actions';
import { LoadTemplateList, RenameTemplate } from '@store/template-list-store/template-list.actions';
import { QASettingsTemplatesState } from '@store/template-list-store/template-list.state';
import { LoadGlossaryList } from '@store/glossary-list-store/glossary-list.actions';
import { ProjectState } from '@store/project-store';
import { patch } from '@ngxs/store/operators';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { ResetSourceTargetLanguages } from '@store/glossary-terms-store';
import { Operation, QaSettingsInfo, QaSettingsModel, QaSettingsService } from '@generated/api';
import { CommonHubService } from '@shared/services';
import { QaSettingsChangedEvent } from '@shared/models';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

interface QASettingsStateModel {
  [projectId: string]: QaSettingsModel;
}

@AsyncStorage
@StateRepository()
@State<QASettingsStateModel>({
  name: 'qaSettings',
  defaults: {},
})
@Injectable()
export class QASettingsState {
  private inversePatches: Record<string, Patch> = {};
  private patches: Record<string, Operation> = {};

  constructor(private qaSettingsService: QaSettingsService, private store: Store, private commonHub: CommonHubService) {
    this.watchQaSettingsChangedEvent();
  }

  private watchQaSettingsChangedEvent(): void {
    this.commonHub.watch(QaSettingsChangedEvent).subscribe((data) => {
      const project = this.store.selectSnapshot(ProjectState.project);
      if (!project) {
        return;
      }
      if (project.qaSettingsId === data.id) {
        this.store.dispatch(new LoadQASettings(data.id));
        return;
      }
      this.store.dispatch(new LoadTemplateList());
    });
  }

  @Selector([QASettingsState, ProjectState.projectId])
  public static qaSettings(state: QASettingsStateModel, projectId: string): QaSettingsModel {
    return state && state[projectId];
  }

  @Selector([QASettingsState.qaSettings])
  public static qaSettingsId(qaSettings: QaSettingsModel): string | undefined {
    return qaSettings?.id;
  }

  @Selector([QASettingsState.qaSettings, QASettingsTemplatesState])
  public static qaSettingsWithTemplate(qaSettings: QaSettingsModel, list: QaSettingsInfo[]): QASettingsWithTemplate {
    if (!qaSettings) {
      return null;
    }
    const { templateId } = qaSettings;

    if (!templateId) {
      return qaSettings as QASettingsWithTemplate;
    }

    const template = list.find((a) => a.id === templateId);
    return {
      ...qaSettings,
      template,
    };
  }

  @Selector([QASettingsState.qaSettingsWithTemplate])
  public static qaSettingsHasChanges(settingsWithTemplate: QASettingsWithTemplate): boolean {
    if (!settingsWithTemplate || !settingsWithTemplate.template) {
      return false;
    }
    return settingsWithTemplate.checkSettingsHash !== settingsWithTemplate.template.checkSettingsHash;
  }

  @Action(SetCurrentQASettings)
  public setCurrentQASettings(ctx: StateContext<QASettingsStateModel>, action: SetCurrentQASettings): void {
    const state = ctx.getState() || {};
    const projectId = this.store.selectSnapshot(ProjectState.projectId);

    const projectSettings = state[projectId];
    ctx.patchState({
      [projectId]: {
        ...projectSettings,
        ...action.qaSettings,
      },
    });
  }

  @Action(LoadQASettings)
  public loadQASettings(ctx: StateContext<QASettingsStateModel>, action: LoadQASettings): Observable<void> {
    // TODO: VW-788 отделить locale qa settings и глоссарий
    // от загрузки настроек приложения, убрать вызов LoadQASettings
    // для !isOwner отчета
    ctx.dispatch(new LoadLocaleQASettingsList(action.id));
    ctx.dispatch(new LoadGlossaryList(action.id));
    return this.qaSettingsService.apiQaSettingsIdGet$Json({ id: action.id, 'api-version': '1.1' }).pipe(
      tap((qaSettings: QaSettingsModel) => {
        ctx.dispatch(new SetCurrentQASettings(qaSettings));
      }),
      mergeMap(() => ctx.dispatch(new LoadQASettingsSuccess())),
      catchError((error) => ctx.dispatch(new LoadQASettingsFail(error)))
    );
  }

  @Action(RenameQASettings)
  public renameQASettings(
    ctx: StateContext<QASettingsStateModel>,
    action: RenameQASettings
  ): Observable<QaSettingsInfo> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    return this.qaSettingsService
      .apiQaSettingsIdRenamePost$Json({
        'api-version': '1.1',
        id: action.qaSettingsId,
        body: { name: action.name },
      })
      .pipe(
        tap((qaSettingsInfo: QaSettingsInfo) => {
          ctx.setState(
            patch({
              [projectId]: patch({ ...qaSettingsInfo }),
            })
          );
          if (!qaSettingsInfo.templateId) {
            return;
          }

          const template = this.getTemplate(qaSettingsInfo.templateId);
          if (template) {
            ctx.dispatch(new RenameTemplate(template.id, qaSettingsInfo.name));
          }
        })
      );
  }

  @Action(ResetSettings)
  public resetSettings(ctx: StateContext<QASettingsStateModel>, { qaSettingsId }: ResetSettings): Observable<void> {
    return this.qaSettingsService.apiQaSettingsIdResetPost({ 'api-version': '1.1', id: qaSettingsId }).pipe(
      tap(() => {
        ctx.dispatch(new LoadQASettings(qaSettingsId));
        ctx.dispatch(new ResetSourceTargetLanguages());
      })
    );
  }

  @Action(ClearSettings)
  public clearSettings(ctx: StateContext<QASettingsStateModel>, { qaSettingsId }: ClearSettings): Observable<void> {
    return this.qaSettingsService.apiQaSettingsIdClearPost({ 'api-version': '1.1', id: qaSettingsId }).pipe(
      tap(() => {
        ctx.dispatch(new LoadQASettings(qaSettingsId));
        ctx.dispatch(new ResetSourceTargetLanguages());
      })
    );
  }

  @Action(PatchQASettings, { cancelUncompleted: true })
  public patch<T>(ctx: StateContext<QASettingsStateModel>, action: PatchQASettings<T>): Observable<any> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    let state = ctx.getState()[projectId];
    const [nextProjectSettings, patches, inversePatches] = produceWithPatches(state, (draft) => {
      this.applyPatch(action.pathFn, draft, action.value);
    });

    if (patches.length === 0) {
      return of(null);
    }

    patches.forEach((item) => {
      const path = '/' + item.path.join('/');
      this.patches[path] = {
        op: item.op,
        path,
        value: item.value,
      };
    });

    inversePatches.forEach((item) => {
      const path = '/' + item.path.join('/');
      this.inversePatches[path] = item;
    });

    ctx.setState(
      patch({
        [projectId]: patch(nextProjectSettings),
      })
    );

    if (patches.length === 0) {
      return of();
    }

    return this.qaSettingsService
      .apiQaSettingsIdPatch$Json({
        'api-version': '1.1',
        id: state.id,
        body: Object.values(this.patches),
      })
      .pipe(
        tap((qaSettingsInfo: QaSettingsInfo) => {
          this.patches = {};
          this.inversePatches = {};
          ctx.setState(
            patch({
              [projectId]: patch(qaSettingsInfo),
            })
          );
        }),
        switchMap(() => of(null)),
        catchError((err: Error) => {
          state = ctx.getState()[projectId];
          state = applyPatches(state, Object.values(this.inversePatches));
          ctx.setState(
            patch({
              [projectId]: patch(state),
            })
          );
          this.patches = {};
          this.inversePatches = {};
          throw err;
        })
      );
  }

  private applyPatch<T>(
    pathFn: ((obj: QaSettingsModel) => T) | string,
    qaSettings: QaSettingsModel,
    pathValue: T
  ): QaSettingsModel {
    const path = typeof pathFn === 'string' ? pathFn : nameof<QaSettingsModel>(pathFn as (obj: QaSettingsModel) => T);
    const vector = path.split('.');
    const propName = vector.pop();

    if (propName) {
      qaSettings = vector.reduce((it, prop) => it[prop], qaSettings);
      qaSettings[propName] = pathValue;
    }

    return qaSettings;
  }

  private getTemplate(id: string): QaSettingsInfo {
    return this.store.selectSnapshot(QASettingsTemplatesState.byId(id));
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<QASettingsStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
