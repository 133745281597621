/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConnectToSocketProjectGroupRequest } from '../models/connect-to-socket-project-group-request';

@Injectable({
  providedIn: 'root',
})
export class SocketService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSocketsProjectSubscribePost
   */
  static readonly ApiSocketsProjectSubscribePostPath = '/api/sockets/project/Subscribe';

  /**
   * Method for connect ProjectGroup in socket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSocketsProjectSubscribePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSocketsProjectSubscribePost$Response(params?: {
    'api-version'?: string;

    /**
     * ConnectId and ProjectId
     */
    body?: ConnectToSocketProjectGroupRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SocketService.ApiSocketsProjectSubscribePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Method for connect ProjectGroup in socket.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSocketsProjectSubscribePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSocketsProjectSubscribePost(params?: {
    'api-version'?: string;

    /**
     * ConnectId and ProjectId
     */
    body?: ConnectToSocketProjectGroupRequest
  }): Observable<void> {

    return this.apiSocketsProjectSubscribePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSocketsProjectUnsubscribePost
   */
  static readonly ApiSocketsProjectUnsubscribePostPath = '/api/sockets/project/Unsubscribe';

  /**
   * Method for remove ProjectGroup in socket.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSocketsProjectUnsubscribePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSocketsProjectUnsubscribePost$Response(params?: {
    'api-version'?: string;

    /**
     * ConnectId and ProjectId
     */
    body?: ConnectToSocketProjectGroupRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SocketService.ApiSocketsProjectUnsubscribePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Method for remove ProjectGroup in socket.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSocketsProjectUnsubscribePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSocketsProjectUnsubscribePost(params?: {
    'api-version'?: string;

    /**
     * ConnectId and ProjectId
     */
    body?: ConnectToSocketProjectGroupRequest
  }): Observable<void> {

    return this.apiSocketsProjectUnsubscribePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
