<div class="input-wrapper" [ngClass]="{ disabled: disabled, active: active }">
  <app-action-icon
    class="input-btn"
    size="sm"
    character="C"
    (clickButton)="changeCaseSensitive()"
    [active]="caseSensitive"
    appTooltip="Case-sensitive"
    [disabled]="disabled"
  ></app-action-icon>
  <app-action-icon
    class="input-btn"
    character="T"
    size="sm"
    (clickButton)="changeIncludeTags()"
    [active]="includeTags"
    appTooltip="Tags"
    [disabled]="disabled"
  ></app-action-icon>
  <app-action-icon
    class="input-btn"
    character="W"
    size="sm"
    (clickButton)="changeSearchType(searchTypes.WholeWords)"
    [active]="searchType === searchTypes.WholeWords"
    appTooltip="Whole words"
    [disabled]="disabled"
  ></app-action-icon>
  <app-action-icon
    class="input-btn"
    character="R"
    size="sm"
    (clickButton)="changeSearchType(searchTypes.Regex)"
    [active]="searchType === searchTypes.Regex"
    #regularExpressionTooltip="tooltip"
    [appTooltip]="regularExpressionPatternInvalid ? 'Invalid regular expression' : 'Regular expression'"
    [appTooltipAnimationDisable]="true"
    [disabled]="disabled"
  ></app-action-icon>
  <app-action-icon
    class="input-btn"
    character="B"
    size="sm"
    (clickButton)="changeBackreference()"
    [appTooltipTriggerFor]="crossSegmentsBackReferencesTooltipTemplate"
    [active]="crossSegmentsBackReferences"
    [disabled]="disabled"
  ></app-action-icon>
  <app-action-icon
    class="input-btn"
    character="P"
    size="sm"
    (clickButton)="changeSearchType(searchTypes.PowerSyntax)"
    [active]="searchType === searchTypes.PowerSyntax"
    #powerSearchTooltip="tooltip"
    [appTooltip]="powerSearchPatternInvalid ? 'Invalid power search request' : 'Power search'"
    [appTooltipAnimationDisable]="true"
    [disabled]="disabled"
  ></app-action-icon>

  <app-action-icon size="sm" theme="subtle" icon="clear" (click)="cancel()" class="search-cancel"></app-action-icon>

  <input
    #searchPatternInput
    [appContextClick]="specialSymbolsTemplate"
    [contextAttachToElement]="searchPatternInput"
    class="field"
    [formControl]="patternControl"
    (ngModelChange)="valueChanged()"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [id]="fieldId"
    [name]="fieldName"
    [autocomplete]="autocomplete"
    role="search"
    aria-label="Search"
    (keypress)="keyPressedHandler($event)"
    (focus)="focusInput(true, searchPatternInput)"
    (blur)="focusInput(false)"
  />
  <app-spinner *ngIf="loading" class="loader" [size]="SpinnerSize.Button"></app-spinner>
</div>

<tooltip  #crossSegmentsBackReferencesTooltipTemplate='tooltip'>
  <div class="tooltip-header">Cross segment back-reference</div>
  <div class="tooltip-text">
    When enabled, all back-references (\1) start to refer to the capturing groups in the
    <u>{{ crossSegmentsBackReferencesDestination }}</u> regular expression.
  </div>
</tooltip>

<ng-template #specialSymbolsTemplate>
  <app-special-symbols (inserted)="insertSymbol($event)"></app-special-symbols>
</ng-template>
