import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from '@shared/pipes';
import { SearchInputModule } from '../search-input';
import { SearchListComponent } from './search-list.component';
import { TooltipModule } from '@shared/components/tooltip';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { LoaderModule } from '../loader';

@NgModule({
  imports: [
    CommonModule,
    SearchInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    PipesModule,
    TooltipModule,
    LoaderModule,
    VirtualScrollerModule,
  ],
  declarations: [SearchListComponent],
  exports: [SearchListComponent],
})
export class SearchListModule {}
