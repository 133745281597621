import { NgxsSimpleChange } from '@ngxs/store';
import { AsyncStoragePluginInitEvent } from './async-storage-plugin-init-event';
import { META_KEY, NGXS_META_OPTIONS_KEY, StatesMap } from './symbols';
import { AsyncStorageEngine } from './async-engine';

let preFetchedStates: StatesMap;

export async function prepareStore(): Promise<void> {
  return new Promise((resolve) => {
    AsyncStorageEngine.prefetchStore()
      .then((result) => {
        preFetchedStates = result;
        resolve();
      })
      .catch(() => resolve());
  });
}

function updateMethod<T>(targetClass, methodName: string, callback: (value: T) => void, runBefore = false) {
  if (targetClass.hasOwnProperty(methodName)) {
    const baseFunction = targetClass[methodName];
    targetClass[methodName] = (change: T) => {
      if (runBefore) {
        callback(change);
        baseFunction(change);
        return;
      }
      callback(change);
      baseFunction(change);
    };
    return;
  }
  targetClass[methodName] = callback;
}

function updateOnChangesMethod(targetClass, callback: (change: NgxsSimpleChange) => void) {
  updateMethod(targetClass, 'ngxsOnChanges', callback);
}

export function AsyncStorage(stateClass) {
  const stateName: string = stateClass[NGXS_META_OPTIONS_KEY].name;
  const inheritedStateClass = stateClass.prototype;
  updateOnChangesMethod(inheritedStateClass, (newState) => {
    AsyncStorageEngine.putState(stateName, newState.currentValue);
  });
  AsyncStoragePluginInitEvent.subscribe(() => {
    if (preFetchedStates[stateName]) {
      const meta = stateClass[META_KEY];
      meta.defaults = preFetchedStates[stateName];
      delete preFetchedStates[stateName];
    }
  });
}
