import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, Selector, State, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddTerm,
  UpdateTerm,
  RemoveTerms,
  ResetSourceTargetLanguages,
  SelectSourceLanguage,
  SelectTargetLanguage,
  SetDefaultSourceTargetLanguages,
  LoadTerms,
  TermRemoved,
  TermUpdated,
  ChangeBulkSettings,
} from '@store/glossary-terms-store/glossary-terms.actions';
import { ProjectState } from '@store/project-store';
import { patch } from '@ngxs/store/operators';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { ChangeTermModel, LanguageModel, TermModel, TermModelDataSourceResponse, TermsService } from '@generated/api';
import { insertItem, updateItem } from '@ngxs/store/operators';
import { Observable, tap } from 'rxjs';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

const defaultProjectState = {
  sourceLanguage: null,
  targetLanguage: null,
  terms: [],
};

export class QASettingsGlossaryTermsStateModel {
  [projectId: string]: {
    /**
     * Selected source language
     */
    sourceLanguage: LanguageModel | undefined;

    /**
     * Selected target language
     */
    targetLanguage: LanguageModel | undefined;
    terms: TermModel[];
  };
}

@AsyncStorage
@StateRepository()
@State<QASettingsGlossaryTermsStateModel>({
  name: 'glossaryTerms',
  defaults: {},
})
@Injectable()
export class GlossaryTermsState {
  constructor(private termsService: TermsService, private store: Store) {}

  @Selector([GlossaryTermsState, ProjectState.projectId])
  public static sourceLanguage(state: QASettingsGlossaryTermsStateModel, projectId: string): LanguageModel | undefined {
    return state && state[projectId]?.sourceLanguage;
  }

  @Selector([GlossaryTermsState, ProjectState.projectId])
  public static targetLanguage(state: QASettingsGlossaryTermsStateModel, projectId: string): LanguageModel | undefined {
    return state && state[projectId]?.targetLanguage;
  }

  @Selector([GlossaryTermsState.sourceLanguage])
  public static sourceLanguageRtl(language: LanguageModel): boolean {
    return language?.rightToLeft || false;
  }

  @Selector([GlossaryTermsState.targetLanguage])
  public static targetLanguageRtl(language: LanguageModel): boolean {
    return language?.rightToLeft || false;
  }

  @Selector([GlossaryTermsState, ProjectState.projectId])
  public static glossaryTerms(state: QASettingsGlossaryTermsStateModel, projectId: string): TermModel[] {
    return state && state[projectId]?.terms;
  }

  @Action(SelectTargetLanguage)
  public selectTargetLanguage(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    { language }: { language: LanguageModel }
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState();
    ctx.setState({
      ...state,
      [projectId]: {
        ...state[projectId],
        targetLanguage: language,
      },
    });
  }

  @Action(SelectSourceLanguage)
  public selectSourceLanguage(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    { language }: { language: LanguageModel }
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState();
    ctx.setState({
      ...state,
      [projectId]: {
        ...state[projectId],
        sourceLanguage: language,
      },
    });
  }

  @Action(ResetSourceTargetLanguages)
  public resetSourceTargetLanguages(ctx: StateContext<ResetSourceTargetLanguages>): ResetSourceTargetLanguages {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    return ctx.setState(
      patch({
        [projectId]: defaultProjectState,
      })
    );
  }

  @Action(SetDefaultSourceTargetLanguages)
  public setDefaultSourceTargetLanguages(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    { sourceLanguages, targetLanguages }: SetDefaultSourceTargetLanguages
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const projectState = { ...ctx.getState()[projectId] } || defaultProjectState;
    // NOTE: checking that current selected language exists inside list of languages.
    const selectedSourceLanguageExist = sourceLanguages.find((l) => l.code === projectState.sourceLanguage?.code);
    const selectedTargetLanguageExist = targetLanguages.find((l) => l.code === projectState.targetLanguage?.code);

    if ((!selectedSourceLanguageExist || !projectState.sourceLanguage) && sourceLanguages?.length) {
      projectState.sourceLanguage = sourceLanguages[0];
    }

    if ((!selectedTargetLanguageExist || !projectState.targetLanguage) && targetLanguages?.length) {
      projectState.targetLanguage = targetLanguages[0];
    }

    ctx.setState(
      patch({
        [projectId]: projectState,
      })
    );
  }

  @Action(AddTerm)
  public addTerm(ctx: StateContext<QASettingsGlossaryTermsStateModel>, { termbaseId, term }: AddTerm): void {
    this.termsService
      .apiTermbasesTermbaseIdTermsPost$Json({
        termbaseId,
        body: term,
      })
      .subscribe({
        next: (rspns: ChangeTermModel) => {
          this.handleTermsMerging(ctx, rspns);
        },
        error: (err) => {
          this.store.dispatch(new TermRemoved(term));
          throw err;
        },
      });
  }

  private handleTermsMerging(ctx: StateContext<QASettingsGlossaryTermsStateModel>, rspns: ChangeTermModel): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const deletedTermId = rspns.deletedTermId;
    if (deletedTermId) {
      ctx.dispatch(new TermRemoved({ id: deletedTermId } as TermModel));
      ctx.dispatch(new TermUpdated(rspns.term));
    }

    const upsertFn = deletedTermId
      ? updateItem<TermModel>((t) => t.id === deletedTermId, rspns.term)
      : insertItem(rspns.term);
    ctx.setState(
      patch({
        [projectId]: patch({
          terms: upsertFn,
        }),
      })
    );
  }

  @Action(UpdateTerm)
  public patchTerm(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    { termbaseId, termId, updatedTerm }: UpdateTerm
  ): Observable<any> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({ terms: updateItem<TermModel>((t) => t.id === termId, updatedTerm) }),
      })
    );
    return this.termsService
      .apiTermbasesTermbaseIdTermsTermIdPut$Json({
        termId,
        termbaseId,
        body: updatedTerm,
      })
      .pipe(
        tap((rspns: ChangeTermModel) => {
          this.handleTermsMerging(ctx, rspns);
        })
      );
  }

  @Action(RemoveTerms)
  public removeTerms(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    { termbaseId, terms }: RemoveTerms
  ): Observable<boolean> {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const removedIds = terms.map((t) => t.id);
    const allTerms = ctx.getState()[projectId]?.terms;
    const termsAfterRemove = allTerms?.filter((t) => !removedIds.includes(t.id));
    ctx.setState(patch({ [projectId]: patch({ terms: termsAfterRemove }) }));
    return this.termsService.apiTermbasesTermbaseIdTermsDelete$Json({
      termbaseId,
      body: terms.map((t) => t.id),
    });
  }

  @Action(LoadTerms)
  public loadTerms(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    action: LoadTerms
  ): Observable<TermModelDataSourceResponse> {
    return this.termsService
      .apiTermbasesTermbaseIdTermsGet$Json({
        sourceLanguageId: action.sourceLanguageId,
        targetLanguageId: action.targetLanguageId,
        termbaseId: action.termbaseId,
      })
      .pipe(
        tap((rspns) => {
          const projectId = this.store.selectSnapshot(ProjectState.projectId);
          const state = ctx.getState();
          ctx.patchState({
            [projectId]: {
              ...state[projectId],
              terms: rspns.data,
            },
          });
        })
      );
  }

  @Action(ChangeBulkSettings)
  public changeBulkSettings(_: StateContext<QASettingsGlossaryTermsStateModel>, action: ChangeBulkSettings): void {
    this.termsService
      .apiTermbasesTermbaseIdTermsUpdateTermSettingsPost({
        termbaseId: action.termbaseId,
        sourceLanguageId: action.sourceLanguageId,
        targetLanguageId: action.targetLanguageId,
        term: action.term,
        body: action.options,
      })
      .subscribe();
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(
    ctx: StateContext<QASettingsGlossaryTermsStateModel>,
    action: CleanStoredProjectInfo
  ): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
