const regExpCharsRegExp = /[\\^$.*+?()[\]{}|]/g;

/**
 * Escapes the RegExp special characters.
 *
 * @example
 * `regExpEscape("[Verifika](https://verifika.com/)") // => "\[Verifika\]\(https://verifika\.com/\)"`
 */
export const regExpEscape = (input: string): string => {
  // NOTE: as for Sep.2020, the `RegExp.escape()` function in TC-39 stage 0 (strawman) proposal.
  //   {@see https://github.com/benjamingr/RegExp.escape} {@see https://esdiscuss.org/topic/regexp-escape}
  //   Once implemented, replace this function with polyfilled native version.

  return input ? input.replace(regExpCharsRegExp, '\\$&') : input || '';
};
