import { IssueStatusModel, IssueType, ReportProgressStatus } from '@generated/api';

enum ActionTypes {
  CHANGE_REPORT_PART_PROCESSING_STATUS = '[ReportProcessing] Change Report Part Processing Status',
  BULK_CHANGE_REPORT_PART_PROCESSING_STATUS = '[ReportProcessing] Bulk Change Report Processing Status',
  RESET_REPORT_PROCESSING_STATE = '[ReportProcessing] Reset Report Processing',
}

export class ChangeReportPartProcessingStatus {
  static readonly type = ActionTypes.CHANGE_REPORT_PART_PROCESSING_STATUS;
  constructor(public issueType: IssueType, public status: ReportProgressStatus) {}
}

export class BulkChangeReportPartProcessingStatus {
  static readonly type = ActionTypes.BULK_CHANGE_REPORT_PART_PROCESSING_STATUS;
  constructor(public statuses: IssueStatusModel[]) {}
}

export class ResetReportProcessingState {
  static readonly type = ActionTypes.RESET_REPORT_PROCESSING_STATE;
}
