<div class="organizations-manager">
  <div class="header">
    <div class="user-name">{{ (userInfo$ | async)?.name }}</div>
    <div class="user-email">{{ (userInfo$ | async)?.email }}</div>
  </div>
  <div class="body">
    <app-organizations-list
      [organizations]="organizations$ | async"
      [selectedOrganizationId]="(currentOrganization$ | async)?.id"
      [accountRoles]="(userInfo$ | async)?.organizationRoles"
      (create)="createOrganization()"
      (invite)="inviteUser($event)"
      (organizationSelect)="selectOrganization($event)"
    ></app-organizations-list>
  </div>
  <div class="line-divider">
    <hr />
  </div>
  <div class="footer">
    <div class="logout" role="button">
      <div (click)="logout()">
        <app-ui-icon icon="logout"></app-ui-icon>
        Back to anonymous
      </div>
    </div>
  </div>
</div>
