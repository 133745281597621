<div
  *ngIf="!icon; else iconHeaderTemplate"
  (click)="onSortRequested($event)"
  [ngClass]="{
    opened: filterOpened,
    sort: params.enableSorting
  }"
  class="grid-header"
>
  <ng-container>
    <div class="grid-header-label">
      {{ params.displayName }}
    </div>
    <div class="actions">
      <div *ngIf="params.enableSorting" class="sorting-icons" [class]="sortOrder">
        <app-ui-icon class="sorting-icon sorting-icon-desc" icon="sort-arrow-down" color="inherit"></app-ui-icon>
        <app-ui-icon class="sorting-icon sorting-icon-asc" icon="sort-arrow-up" color="inherit"> </app-ui-icon>
      </div>

      <div
        class="e-menu"
        *ngIf="params.enableMenu"
        (click)="$event.stopPropagation(); $event.preventDefault(); toggleFilter()"
      >
        <app-ui-icon icon="w-grid-menu" size="12px" color="inherit"></app-ui-icon>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #iconHeaderTemplate>
  <div
    class="icon-header"
    (click)="toggleFilter()"
    [ngClass]="{
      opened: filterOpened
    }"
  >
    <app-ui-icon [icon]="icon.name" [color]="icon.color" [size]="icon.size || 'small'"></app-ui-icon>
    <app-ui-icon class="menu-icon" icon="w-grid-menu" size="12px" color="inherit"></app-ui-icon>
  </div>
</ng-template>
