<ng-container *ngIf="imageUrl">
  <img class="no-rows-image" alt="no errors" [src]="imageUrl" [ngStyle]="{ width: imageWidth, height: imageHeight }" />
</ng-container>

<div class="empty-state-text">
  <app-ui-icon *ngIf="prefixIcon" [icon]="prefixIcon"></app-ui-icon>
  {{ message }}
</div>

<ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
