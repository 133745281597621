import { CreateWordFormRequest, UpdateWordFormRequest, WordFormModel } from '@generated/api';

enum ActionTypes {
  LOAD_WORDFORMS = '[Wordforms] Load wordforms',
  CREATE_WORDFORM = '[Wordforms] Create a new wordform',
  UPDATE_WORDFORM = '[Wordforms] Update a specific wordform',
  DELETE_WORDFORM = '[Wordforms] Delete a specific wordform',
  DELETE_WORDFORMS = '[Wordforms] Delete multiple wordforms',
  UPLOAD_WORDFORMS = '[Wordforms] Upload wordforms',
  WORDFORM_UPDATED = '[Wordforms] Wordform updated',
  WORDFORM_DELETED = '[Wordforms] Wordform deleted',
  WORDFORMS_UPLOADED = '[Wordforms] Wordforms uploaded',
}

export class LoadWordforms {
  static readonly type = ActionTypes.LOAD_WORDFORMS;
  constructor(public languageIds: number[]) {}
}

export class CreateWordform {
  static readonly type = ActionTypes.CREATE_WORDFORM;
  constructor(public wordform: CreateWordFormRequest) {}
}

export class UpdateWordForm {
  static readonly type = ActionTypes.UPDATE_WORDFORM;
  constructor(public id: string, public updatedWordForm: UpdateWordFormRequest) {}
}

export class DeleteWordform {
  static readonly type = ActionTypes.DELETE_WORDFORM;
  constructor(public id: string) {}
}

export class DeleteWordforms {
  static readonly type = ActionTypes.DELETE_WORDFORMS;
  constructor(public ids: string[]) {}
}

export class UploadWordforms {
  static readonly type = ActionTypes.UPLOAD_WORDFORMS;
  constructor(public wordformsBaseId: string, public file: File) {}
}

export class WordformsUploaded {
  static readonly type = ActionTypes.WORDFORMS_UPLOADED;
  constructor(public wordforms: WordFormModel[]) {}
}

export class WordformUpdated {
  static readonly type = ActionTypes.WORDFORM_UPDATED;
  constructor(public wordform: WordFormModel) {}
}

export class WordformDeleted {
  static readonly type = ActionTypes.WORDFORM_DELETED;
  constructor(public id: string) {}
}

// constructor(
//   public id: string,
//   public wordformsBaseId: string,
//   public languageId: number,
//   public word: string,
//   public wordForms: string[],
//   public incorrectForms: string[]
// ) {}
