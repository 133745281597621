import { ChangeDetectionStrategy, Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { AppService } from '@shared/services';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { merge, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProjectsState } from 'src/app/store/projects-store/projects.state';
import { ProjectState } from 'src/app/store/project-store/project.state';
import { RenameProject, TouchedProject } from 'src/app/store/project-store/project.actions';
import { FormControl, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { DropdownMenuComponent } from '@shared/components/dropdown-menu';
import { UploadingFilesState } from '@store/uploading-files';
import { ProjectInfo } from '@generated/api';
import { AddProject } from '@store/projects-store/projects.actions';

@Component({
  selector: 'app-header-projects',
  templateUrl: './header-projects.component.html',
  styleUrls: ['./header-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderProjectsComponent implements OnInit, OnDestroy {
  @Select(ProjectsState.projects) projects$: Observable<ProjectInfo[]>;

  @Select(UploadingFilesState.hasInProgressFiles) uploadingInPorgress$: Observable<boolean>;

  @ViewChild('projectDropDown') projectDropDown: DropdownMenuComponent;

  public popupWidth: number = 1160;
  public projectNameControl = new FormControl('', [this.nameValidator()]);

  private readonly destroyed$ = new Subject<void>();
  private readonly maxPopupWidth = 1160;
  private project: ProjectInfo | null = null;

  constructor(public app: AppService, private readonly store: Store, private readonly actions$: Actions) {}

  ngOnInit() {
    this.watchProjectChanged();
    this.watchProjectCreated();
    this.initPopupWidth();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.initPopupWidth();
  }

  private watchProjectChanged(): void {
    this.store
      .select(ProjectState.project)
      .pipe(
        filter((project) => !!project),
        takeUntil(this.destroyed$)
      )
      .subscribe((project) => {
        this.projectNameControl.setValue(project.name);
        this.project = project;
      });
  }

  private watchProjectCreated(): void {
    merge(this.actions$.pipe(ofActionSuccessful(AddProject)), this.actions$.pipe(ofActionSuccessful(TouchedProject)))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.projectDropDown.close();
      });
  }

  private initPopupWidth(): void {
    this.popupWidth = window.innerWidth > this.maxPopupWidth ? this.maxPopupWidth : window.innerWidth;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public renameProject(name: string): void {
    this.store.dispatch(new RenameProject(this.project.id, name?.trim()));
  }

  public projectSelected(): void {
    this.projectDropDown.close();
  }

  // TODO: вынести в валидаторы
  private nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const projects = this.store.selectSnapshot(ProjectsState.projects);

      if (!projects.length || !control.value) {
        return null;
      }

      const hasSameName = projects.some(
        ({ name, id }: ProjectInfo) => control.value === name && this.project?.id !== id
      );
      return hasSameName ? { exist: true } : null;
    };
  }
}
