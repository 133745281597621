<div class="grid-box">
  <div class="grid-header">
    <span class="grid-title" [class.title-disabled]="disabled">Measurement units</span>
    <div class="grid-actions">
      <app-action-icon
        [disabled]="isEditing || disabled || searchControl.value"
        icon="add"
        class="grid-add"
        appTooltip="Add new"
        (clickButton)="add()"
        size="xl"
      ></app-action-icon>
      <app-action-icon
        icon="delete-outline"
        class="grid-remove"
        appTooltip="Delete"
        (clickButton)="remove()"
        [disabled]="!isRowSelected || disabled"
        size="xl"
      ></app-action-icon>
      <app-ui-search-input
        size="small"
        [disabled]="disabled"
        class="grid-search"
        [formControl]="searchControl"
      ></app-ui-search-input>
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-settings measurement-grid"
    [class.table-disabled]="disabled"
    rowSelection="multiple"
    (gridReady)="onGridReady($any($event))"
    (cellEditingStarted)="editStarted()"
    (cellEditingStopped)="editStopped($event)"
    (cellValueChanged)="onCellChanged($event)"
    (rowSelected)="onRowSelected()"
    [stopEditingWhenCellsLoseFocus]="true"
    [suppressRowClickSelection]="true"
    [singleClickEdit]="true"
    [modules]="modules"
    [components]="gridComponents"
    [defaultColDef]="defaultColDef"
    [columnDefs]="$any(columnDefs)"
    [suppressClickEdit]="disabled"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="{
      notFound: this.searchControl.value,
      notFoundMessage: this.overlayText,
      noRowsMessage: this.overlayNoRowsText
    }"
  >
  </ag-grid-angular>
</div>

<ng-template #confirmDeleteModalTemplate>
  <app-ui-modal size="small">
    <div class="modal-title">The row will be deleted for all languages. Are you sure you want to delete it?</div>
    <div class="modal-footer">
      <app-button (clickButton)="confirm()" theme="primary" type="submit">Delete</app-button>
      <app-button (clickButton)="cancel()">Cancel</app-button>
    </div>
  </app-ui-modal>
</ng-template>
