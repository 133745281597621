import { TagSegmentElement, TagType } from '@shared/models';

export function getTagPair(
  tagsList: TagSegmentElement[],
  tag: TagSegmentElement
): TagSegmentElement | null {
  if (tag.tagType === TagType.Standalone) {
    return null;
  }
  const index = tagsList.findIndex(
    (t) => t.start === tag.start && t.tagType === tag.tagType
  );
  if (index === -1) {
    return null;
  }
  let newArray: TagSegmentElement[] = [];
  if (tag.tagType === TagType.Start) {
    newArray = tagsList.slice(index + 1);
  } else {
    newArray = tagsList.slice(0, index);
    newArray = newArray.reverse();
  }
  newArray = newArray.filter((t) => t.tagType !== TagType.Standalone);
  let stack = 0;
  for (const value of newArray) {
    if (value.tagType === tag.tagType) {
      stack++;
    } else {
      if (stack !== 0) {
        stack--;
      } else {
        return value;
      }
    }
  }
}

export function tagHasPair(
  tagsList: TagSegmentElement[],
  tag: TagSegmentElement
): boolean {
  return !!getTagPair(tagsList, tag);
}
