import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
  CommentsState,
  CommonReportViewState,
  ProjectFilesState,
  ProjectState,
  QASettingsState,
  QualityIssuesState,
  ReportGridsState,
  ReportViewState,
  SetReportDefaultReportView,
  SetTagView,
  ToggleMultiline,
  ToggleShowComment,
  ToggleShowInvisibles,
  TranslationUnitChangesState,
  ReportState,
} from '@store';
import { LoadProject } from 'src/app/store/project-store/project.actions';
import { AppService, CommonHubService } from '@shared/services';
import { BaseLayoutComponent } from '../base-layout';
import { first, take, takeUntil } from 'rxjs/operators';
import { LoadSpellCheckSupportedLanguages } from '@store/spell-check-store';
import { LoggerService } from '../../../core/logger';
import { InitSearchInReportFiles } from '@store/search-in-report-files';
import { LoadQualityIssueKinds } from '@store/quality-issue-kinds-store/quality-issue-kinds.actions';
import {
  LocalizationPlatformInfo,
  LocalizationPlatformsService,
  Project,
  QaSettingsModel,
  ReportModel,
} from '@generated/api';
import { IssueTypeToReportTab, QualityIssue, ReportExportCompleted, ReportTab, TagView } from '@shared/models';
import { reportNavRoutes } from '@report/report.nav-routes';
import { ShortcutsService } from 'src/app/core/services';
import { ControlPanelKeybindingEvent, KeybindingGroups } from 'src/app/core/services/shortcuts';
import { CreateReportLink, DownloadReportExportedFile, ExportReport } from '@store/report-store/report.actions';
import { NotificationService } from '@shared/components/notification';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ContextViewerService } from '@shared/services/context-viewer.service';
import { buildReportExportModel } from '@report/modules/export';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalizationPlatformsState, SetLocalizationPlatformConnection } from '@store/localization-platforms-store';
import { HttpErrorResponse } from '@angular/common/http';
import { isAuthenticationError } from '@shared/tools';
import { LPComponentsService } from '@shared/components/localization-platforms';

enum ExportingStages {
  NotRequested = 0,
  InProgress = 1,
  Ready = 2,
}

@Component({
  selector: 'app-report-layout',
  templateUrl: './report-layout.component.html',
  styleUrls: ['./report-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportLayoutComponent extends BaseLayoutComponent implements OnInit {
  @Select(ProjectState.project) project$: Observable<Project>;
  @Select(QASettingsState.qaSettings) qaSettings$: Observable<QaSettingsModel>;
  @Select(ReportState.report) report$: Observable<ReportModel>;
  @Select(ProjectFilesState.hasLocalizationPlatformFiles)
  hasLocalizationPlatformFiles: Observable<boolean>;
  exportingStage = ExportingStages.NotRequested;
  exportingStages = ExportingStages;
  controlPanelKeybindingEvent = ControlPanelKeybindingEvent;
  popupRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild('popupTemplate') popupTemplate: TemplateRef<any>;

  constructor(
    app: AppService,
    store: Store,
    cdr: ChangeDetectorRef,
    actions$: Actions,
    protected route: ActivatedRoute,
    loggerService: LoggerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public shortcutsService: ShortcutsService,
    private notificationService: NotificationService,
    private clipboard: Clipboard,
    private contextViewerService: ContextViewerService,
    private commonHubService: CommonHubService,
    private dialog: MatDialog,
    private lpComponentsService: LPComponentsService,
    private localizationPlatformsService: LocalizationPlatformsService
  ) {
    super(app, store, cdr, actions$, loggerService);
  }

  ngOnInit() {
    this.preloadData();
    this.watchCommentSelected();
    this.watchKeybindings();
    this.watchReportExport();
  }

  preloadData(): void {
    const routeParams = this.route.snapshot.params;
    const projectId = routeParams.projectId as string;
    this.store.dispatch(new LoadSpellCheckSupportedLanguages());
    this.store.dispatch(new LoadQualityIssueKinds());

    if (!this.store.selectSnapshot(ReportViewState.currentReportView)) {
      this.store.dispatch(new SetReportDefaultReportView(projectId));
    }

    this.store.dispatch(new InitSearchInReportFiles(projectId));

    this.store
      .dispatch(new LoadProject(projectId))
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.loadComplete();
        },
        error: (error: any) => {
          this.loggerService.error(error);
        },
      });
  }

  public handleGoSettings(): void {
    const report = this.store.selectSnapshot(ReportState.report);
    if (report && report.isOwner) {
      window.open(`/settings/files?projectId=${report.projectId}`, this.app.config.settingsNamePrefix);
    }
  }

  private watchCommentSelected() {
    this.store
      .select(ReportViewState.threadId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((threadId) => {
        if (!threadId) {
          return;
        }
        const thread = this.store.selectSnapshot(CommentsState.threadById(threadId));
        let navigateReportTab: ReportTab;
        if (thread.issueType !== null && thread.issueKind !== null) {
          const qualityIssue: QualityIssue = this.store.selectSnapshot(
            QualityIssuesState.findQualityIssue(
              thread.issueType,
              (qi) => qi.issueKindId === thread.issueKind && qi.translationUnit.id === thread.translationUnitId
            )
          );
          if (qualityIssue) {
            navigateReportTab = IssueTypeToReportTab[thread.issueType];
          }
        }
        if (!navigateReportTab) {
          const translationUnitChange = this.store.selectSnapshot(
            TranslationUnitChangesState.byTranslationUnitId(thread.translationUnitId)
          );
          if (translationUnitChange) {
            navigateReportTab = ReportTab.History;
          }
        }
        if (!navigateReportTab) {
          return;
        }
        const route = reportNavRoutes.find((item) => item.data.meta.reportGroup === navigateReportTab);
        this.router.navigate([route.path], { relativeTo: this.activatedRoute });
      });
  }

  public keydown(event: KeyboardEvent): void {
    if ((event.target as HTMLElement).tagName.toLowerCase() === 'input') {
      return;
    }
    this.shortcutsService.handleKeydownEvent(KeybindingGroups.CONTROL_PANEL, event);
  }

  copyReportLink() {
    const report = this.store.selectSnapshot(ReportState.report);
    this.store.dispatch(new CreateReportLink(report.id));
    this.notificationService.toast({
      text: 'Link to the report copied',
      icon: 'copy',
    });
    this.clipboard.copy(window.location.href);
  }

  private watchKeybindings() {
    this.shortcutsService
      .watchGroup(KeybindingGroups.CONTROL_PANEL)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        const keybindingEvents: { [key in ControlPanelKeybindingEvent]: () => void } = {
          [ControlPanelKeybindingEvent.SWITCH_TAGS_DISPLAY]: () => this.cycleSwitchTagsView(),
          [ControlPanelKeybindingEvent.COPY_LINK_TO_REPORT]: () => this.copyReportLink(),
          [ControlPanelKeybindingEvent.ADD_COMMENT]: () => this.toggleShowComments(),
          [ControlPanelKeybindingEvent.VIEW_SEGMENT_IN_CONTEXT]: () => this.openContextViewer(),
          [ControlPanelKeybindingEvent.DISPLAY_NON_PRINTING_CHARACTERS]: () => this.toggleShowInvisibles(),
          [ControlPanelKeybindingEvent.EXPORT_QA_REPORT_TO_EXCEL]: () => this.export(),
        };
        keybindingEvents[event]?.();
      });
  }

  cycleSwitchTagsView(): void {
    const currentTagView = this.store.selectSnapshot(CommonReportViewState.tagView);
    const nextTagView = currentTagView === TagView.Long ? TagView.Short : currentTagView + 1;
    this.store.dispatch(new SetTagView(nextTagView));
  }
  toggleShowInvisibles(): void {
    const currentValue = this.store.selectSnapshot(CommonReportViewState.showInvisibles);
    this.store.dispatch(new ToggleShowInvisibles(!currentValue));
  }
  toggleShowComments(): void {
    this.store.dispatch(new ToggleShowComment());
  }

  toggleMultiline(event: MatButtonToggleChange): void {
    this.store.dispatch(new ToggleMultiline(event.value as boolean));
  }

  openContextViewer() {
    this.contextViewerService.open();
  }

  export() {
    const gridsState = this.store.selectSnapshot(ReportGridsState.reportGridsState());
    const exportModel = buildReportExportModel(gridsState);
    this.store.dispatch(new ExportReport(exportModel));
  }

  watchReportExport() {
    this.actions$.pipe(ofActionSuccessful(ExportReport), takeUntil(this.destroyed$)).subscribe(() => {
      this.exportingStage = ExportingStages.InProgress;
      this.openPopup();
      this.commonHubService
        .watch(ReportExportCompleted)
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe((event) => {
          if (event.completed) {
            this.exportingStage = ExportingStages.Ready;
          } else {
            this.closePopup();
            this.notificationService.toast({ text: event.errors });
          }
          this.cdr.detectChanges();
        });
    });
  }

  download() {
    this.store.dispatch(new DownloadReportExportedFile()).subscribe(() => {
      this.closePopup();
    });
  }

  closePopup() {
    this.exportingStage = ExportingStages.NotRequested;
    this.popupRef.close();
    this.cdr.detectChanges();
  }

  openPopup() {
    this.popupRef = this.dialog.open(this.popupTemplate, {
      hasBackdrop: false,
      position: {
        top: '50px',
        right: '77px',
      },
    });
  }

  saveChanges() {
    const platform = this.store.selectSnapshot(ProjectFilesState.getLocalizationPlatform);
    if (!platform) {
      throw new Error('Platform not found');
    }
    const connectionId = this.store.selectSnapshot(LocalizationPlatformsState.connectionIdByPlatformId(platform.id));
    if (connectionId) {
      this.saveChangesWithExistConnection(platform, connectionId);
    } else {
      this.authAndSaveChanges(platform);
    }
  }

  authAndSaveChanges(platform: LocalizationPlatformInfo) {
    this.lpComponentsService.openAuthModal(platform.id).subscribe((newConnectionId) => {
      if (newConnectionId) {
        this.store.dispatch(new SetLocalizationPlatformConnection(platform.id, newConnectionId));
        this.saveChangesWithExistConnection(platform, newConnectionId);
      }
    });
  }

  saveChangesWithExistConnection(platform: LocalizationPlatformInfo, connectionId: string) {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    this.localizationPlatformsService
      .apiLocalizationPlatformsFileUploadPut({
        body: { connectionId, projectId },
      })
      .subscribe({
        error: (error: HttpErrorResponse) => {
          if (!isAuthenticationError(error)) {
            return;
          }
          this.authAndSaveChanges(platform);
        },
      });
  }
}
