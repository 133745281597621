/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeLanguagesRequest } from '../models/change-languages-request';
import { ChangeProjectFilesProtectionRequest } from '../models/change-project-files-protection-request';
import { CommitProjectFileRequest } from '../models/commit-project-file-request';
import { DeleteProjectFilesRequest } from '../models/delete-project-files-request';
import { DownloadProjectFilesRequest } from '../models/download-project-files-request';
import { Operation } from '../models/operation';
import { ProjectFileInfo } from '../models/project-file-info';

@Injectable({
  providedIn: 'root',
})
export class ProjectFilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProjectsProjectIdProjectFilesGet
   */
  static readonly ApiProjectsProjectIdProjectFilesGetPath = '/api/projects/{projectId}/projectFiles';

  /**
   * Get project files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesGet$Plain$Response(params: {

    /**
     * Project ID
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<ProjectFileInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectFileInfo>>;
      })
    );
  }

  /**
   * Get project files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesGet$Plain(params: {

    /**
     * Project ID
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<Array<ProjectFileInfo>> {

    return this.apiProjectsProjectIdProjectFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectFileInfo>>) => r.body as Array<ProjectFileInfo>)
    );
  }

  /**
   * Get project files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesGet$Json$Response(params: {

    /**
     * Project ID
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<ProjectFileInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectFileInfo>>;
      })
    );
  }

  /**
   * Get project files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesGet$Json(params: {

    /**
     * Project ID
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<Array<ProjectFileInfo>> {

    return this.apiProjectsProjectIdProjectFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectFileInfo>>) => r.body as Array<ProjectFileInfo>)
    );
  }

  /**
   * Path part for operation apiProjectsProjectIdProjectFilesIdGet
   */
  static readonly ApiProjectsProjectIdProjectFilesIdGetPath = '/api/projects/{projectId}/projectFiles/{id}';

  /**
   * Get a specific project file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesIdGet$Plain$Response(params: {

    /**
     * Project ID
     */
    projectId: string;

    /**
     * ProjectFile ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectFileInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectFileInfo>;
      })
    );
  }

  /**
   * Get a specific project file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesIdGet$Plain(params: {

    /**
     * Project ID
     */
    projectId: string;

    /**
     * ProjectFile ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectFileInfo> {

    return this.apiProjectsProjectIdProjectFilesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectFileInfo>) => r.body as ProjectFileInfo)
    );
  }

  /**
   * Get a specific project file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesIdGet$Json$Response(params: {

    /**
     * Project ID
     */
    projectId: string;

    /**
     * ProjectFile ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ProjectFileInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesIdGetPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectFileInfo>;
      })
    );
  }

  /**
   * Get a specific project file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProjectsProjectIdProjectFilesIdGet$Json(params: {

    /**
     * Project ID
     */
    projectId: string;

    /**
     * ProjectFile ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<ProjectFileInfo> {

    return this.apiProjectsProjectIdProjectFilesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectFileInfo>) => r.body as ProjectFileInfo)
    );
  }

  /**
   * Path part for operation apiProjectFilesIdDelete
   */
  static readonly ApiProjectFilesIdDeletePath = '/api/ProjectFiles/{id}';

  /**
   * Delete project files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesIdDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesIdDelete$Response(params: {

    /**
     * Project Id
     */
    id: string;
    'api-version'?: string;

    /**
     * DeleteProjectFilesRequest
     */
    body?: DeleteProjectFilesRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete project files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesIdDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesIdDelete(params: {

    /**
     * Project Id
     */
    id: string;
    'api-version'?: string;

    /**
     * DeleteProjectFilesRequest
     */
    body?: DeleteProjectFilesRequest
  }): Observable<void> {

    return this.apiProjectFilesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProjectFilesIdPatch
   */
  static readonly ApiProjectFilesIdPatchPath = '/api/ProjectFiles/{id}';

  /**
   * Partially update a specific Project File.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesIdPatch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesIdPatch$Response(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Partially update a specific Project File.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesIdPatch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesIdPatch(params: {
    id: string;
    'api-version'?: string;
    body: Array<Operation>
  }): Observable<void> {

    return this.apiProjectFilesIdPatch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProjectsProjectIdProjectFilesChangeLanguagesPost
   */
  static readonly ApiProjectsProjectIdProjectFilesChangeLanguagesPostPath = '/api/projects/{projectId}/projectFiles/changeLanguages';

  /**
   * Change Languages of Project Files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesChangeLanguagesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesChangeLanguagesPost$Plain$Response(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Requests.ChangeLanguagesRequest
     */
    body?: ChangeLanguagesRequest
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesChangeLanguagesPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Change Languages of Project Files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesChangeLanguagesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesChangeLanguagesPost$Plain(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Requests.ChangeLanguagesRequest
     */
    body?: ChangeLanguagesRequest
  }): Observable<Array<string>> {

    return this.apiProjectsProjectIdProjectFilesChangeLanguagesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Change Languages of Project Files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json$Response(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Requests.ChangeLanguagesRequest
     */
    body?: ChangeLanguagesRequest
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesChangeLanguagesPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Change Languages of Project Files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.Requests.ChangeLanguagesRequest
     */
    body?: ChangeLanguagesRequest
  }): Observable<Array<string>> {

    return this.apiProjectsProjectIdProjectFilesChangeLanguagesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation apiProjectsProjectIdProjectFilesProtectedPost
   */
  static readonly ApiProjectsProjectIdProjectFilesProtectedPostPath = '/api/projects/{projectId}/projectFiles/protected';

  /**
   * Change protection status of project files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesProtectedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesProtectedPost$Plain$Response(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.ChangeProjectFilesProtectionRequest
     */
    body?: ChangeProjectFilesProtectionRequest
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesProtectedPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Change protection status of project files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesProtectedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesProtectedPost$Plain(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.ChangeProjectFilesProtectionRequest
     */
    body?: ChangeProjectFilesProtectionRequest
  }): Observable<Array<string>> {

    return this.apiProjectsProjectIdProjectFilesProtectedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Change protection status of project files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectsProjectIdProjectFilesProtectedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesProtectedPost$Json$Response(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.ChangeProjectFilesProtectionRequest
     */
    body?: ChangeProjectFilesProtectionRequest
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectsProjectIdProjectFilesProtectedPostPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Change protection status of project files.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectsProjectIdProjectFilesProtectedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectsProjectIdProjectFilesProtectedPost$Json(params: {

    /**
     * ProjectID
     */
    projectId: string;
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.ChangeProjectFilesProtectionRequest
     */
    body?: ChangeProjectFilesProtectionRequest
  }): Observable<Array<string>> {

    return this.apiProjectsProjectIdProjectFilesProtectedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation apiProjectFilesUploadChunkFilePost
   */
  static readonly ApiProjectFilesUploadChunkFilePostPath = '/api/ProjectFiles/UploadChunkFile';

  /**
   * Upload chunk file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesUploadChunkFilePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProjectFilesUploadChunkFilePost$Response(params?: {
    'api-version'?: string;
    body?: {
'projectId'?: string;
'fileId'?: string;
'fileName'?: string;
'file'?: Blob;
'index'?: string;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesUploadChunkFilePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload chunk file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesUploadChunkFilePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiProjectFilesUploadChunkFilePost(params?: {
    'api-version'?: string;
    body?: {
'projectId'?: string;
'fileId'?: string;
'fileName'?: string;
'file'?: Blob;
'index'?: string;
}
  }): Observable<void> {

    return this.apiProjectFilesUploadChunkFilePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiProjectFilesCommitFilePost
   */
  static readonly ApiProjectFilesCommitFilePostPath = '/api/ProjectFiles/CommitFile';

  /**
   * Commit file. Run after uploads all chunks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesCommitFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesCommitFilePost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.CommitProjectFileRequest
     */
    body?: CommitProjectFileRequest
  }): Observable<StrictHttpResponse<ProjectFileInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesCommitFilePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectFileInfo>;
      })
    );
  }

  /**
   * Commit file. Run after uploads all chunks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesCommitFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesCommitFilePost$Plain(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.CommitProjectFileRequest
     */
    body?: CommitProjectFileRequest
  }): Observable<ProjectFileInfo> {

    return this.apiProjectFilesCommitFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectFileInfo>) => r.body as ProjectFileInfo)
    );
  }

  /**
   * Commit file. Run after uploads all chunks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesCommitFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesCommitFilePost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.CommitProjectFileRequest
     */
    body?: CommitProjectFileRequest
  }): Observable<StrictHttpResponse<ProjectFileInfo>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesCommitFilePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectFileInfo>;
      })
    );
  }

  /**
   * Commit file. Run after uploads all chunks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesCommitFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesCommitFilePost$Json(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Projects.Models.ProjectFiles.CommitProjectFileRequest
     */
    body?: CommitProjectFileRequest
  }): Observable<ProjectFileInfo> {

    return this.apiProjectFilesCommitFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectFileInfo>) => r.body as ProjectFileInfo)
    );
  }

  /**
   * Path part for operation apiProjectFilesDownloadPost
   */
  static readonly ApiProjectFilesDownloadPostPath = '/api/ProjectFiles/Download';

  /**
   * Downloads files for a specific project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProjectFilesDownloadPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesDownloadPost$Response(params?: {
    'api-version'?: string;

    /**
     * DownloadProjectCommand
     */
    body?: DownloadProjectFilesRequest
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFilesService.ApiProjectFilesDownloadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Downloads files for a specific project.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProjectFilesDownloadPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProjectFilesDownloadPost(params?: {
    'api-version'?: string;

    /**
     * DownloadProjectCommand
     */
    body?: DownloadProjectFilesRequest
  }): Observable<Blob> {

    return this.apiProjectFilesDownloadPost$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
