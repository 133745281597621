import { Injectable } from '@angular/core';
import { Action, createSelector, State } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { insertItem, updateItem, removeItem, append, patch } from '@ngxs/store/operators';
import { catchError, map, tap } from 'rxjs/operators';
import {
  CreateWordform,
  DeleteWordform,
  DeleteWordforms,
  LoadWordforms,
  UpdateWordForm,
  UploadWordforms,
  WordformDeleted,
  WordformsUploaded,
  WordformUpdated,
} from './wordforms.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { ChangeWordFormModel, WordFormModel, WordformsService } from '@generated/api';
import { Observable, throwError } from 'rxjs';

interface WordformsStateModel {
  wordforms: WordFormModel[];
}

@AsyncStorage
@State<WordformsStateModel>({
  name: 'wordforms',
  defaults: {
    wordforms: [],
  },
})
@Injectable()
export class WordformsState {
  constructor(private service: WordformsService) {}

  public static filter(
    predicate: (value: WordFormModel, index: number, array: WordFormModel[]) => boolean
  ): (state: WordformsStateModel) => WordFormModel[] {
    return createSelector([WordformsState], (state: WordformsStateModel) => [...state.wordforms.filter(predicate)]);
  }

  public static find(
    predicate: (value: WordFormModel, index: number, array: WordFormModel[]) => boolean
  ): (state: WordformsStateModel) => WordFormModel {
    return createSelector([WordformsState], (state: WordformsStateModel) => state.wordforms.find(predicate));
  }

  @Action(LoadWordforms)
  public loadWordforms(ctx: StateContext<WordformsStateModel>, action: LoadWordforms): Observable<WordFormModel[]> {
    return this.service
      .apiWordformsGet$Json({
        languageIds: action.languageIds,
      })
      .pipe(
        map((reponse) => reponse.data),
        tap((wordforms) => {
          const state = ctx.getState();

          const filteredWordforms = state.wordforms.filter((a) => action.languageIds.indexOf(a.languageId) === -1);

          ctx.setState(
            patch({
              wordforms: [...filteredWordforms, ...wordforms],
            })
          );
        })
      );
  }

  @Action(CreateWordform)
  public create(ctx: StateContext<WordformsStateModel>, action: CreateWordform): void {
    this.service
      .apiWordformsPost$Json({
        body: action.wordform,
      })
      .pipe(
        catchError((err) => {
          ctx.dispatch(new WordformDeleted(action.wordform.id));
          return throwError(() => err);
        })
      )
      .subscribe((rspns) => {
        this.mergeWordforms(ctx, rspns);
      });
  }

  private mergeWordforms(
    ctx: StateContext<WordformsStateModel>,
    { wordForm, deletedWordFormId }: ChangeWordFormModel
  ): void {
    if (deletedWordFormId) {
      ctx.dispatch(new WordformDeleted(deletedWordFormId));
      ctx.dispatch(new WordformUpdated(wordForm));
    }
    const upsertFn = deletedWordFormId
      ? updateItem<WordFormModel>((a) => a.id === wordForm.id, wordForm)
      : insertItem(wordForm);

    ctx.setState(
      patch({
        wordforms: upsertFn,
      })
    );
  }

  @Action(UpdateWordForm)
  public patch(ctx: StateContext<WordformsStateModel>, action: UpdateWordForm): Observable<WordFormModel> {
    return this.service
      .apiWordformsWordFormIdPut$Json({
        wordFormId: action.id,
        body: action.updatedWordForm,
      })
      .pipe(
        tap(({ wordForm }) => {
          ctx.setState(
            patch({
              wordforms: updateItem<WordFormModel>((a) => a.id === action.id, wordForm),
            })
          );
        }),
        map((rspns) => {
          this.mergeWordforms(ctx, rspns);
          return rspns.wordForm;
        })
      );
  }

  @Action(DeleteWordform)
  public delete(ctx: StateContext<WordformsStateModel>, action: DeleteWordform): Observable<boolean> {
    return this.service
      .apiWordformsIdDelete$Json({
        id: action.id,
      })
      .pipe(
        tap((data) => {
          if (data) {
            ctx.setState(
              patch({
                wordforms: removeItem<WordFormModel>((a) => a.id === action.id),
              })
            );
          }
        })
      );
  }

  @Action(DeleteWordforms)
  public deleteRange(ctx: StateContext<WordformsStateModel>, action: DeleteWordforms): Observable<boolean> {
    return this.service
      .apiWordformsDelete$Json({
        body: action.ids,
      })
      .pipe(
        tap((data) => {
          if (data) {
            const state = ctx.getState();
            const filteredState = state.wordforms.filter((a) => action.ids.indexOf(a.id) === -1);
            ctx.setState(
              patch({
                wordforms: filteredState,
              })
            );
          }
        })
      );
  }

  @Action(UploadWordforms)
  public upload(ctx: StateContext<WordformsStateModel>, action: UploadWordforms): void {
    this.service
      .apiWordformsUploadPost$Json({
        body: {
          file: action.file,
          wordformsBaseId: action.wordformsBaseId,
        },
      })
      .subscribe((data) => {
        ctx.setState(
          patch({
            wordforms: append(data),
          })
        );
        ctx.dispatch(new WordformsUploaded(data));
      });
  }
}
