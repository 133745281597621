import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { BaseTooltipTrigger } from '@shared/components/tooltip/base-tooltip-trigger';
import { TooltipService } from '@shared/components/tooltip/tooltip.service';

/**
 * Show tooltip only for truncated text
 */
@Directive({
  selector: '[appTooltipTruncated]',
  providers: [TooltipService],
})
export class TooltipTruncatedDirective extends BaseTooltipTrigger {
  @Input() set appTooltipTruncated(text: string) {
    this.tooltipText = text;
  }

  constructor(protected elementRef: ElementRef, public tooltipService: TooltipService) {
    super();
  }

  @HostListener('focusin', ['$event'])
  @HostListener('mouseenter', ['$event'])
  public onMouseEnter(event): void {
    if (!this.appTooltipDisplay) {
      return;
    }

    const element = this.elementRef?.nativeElement;
    if (!element) {
      return;
    }
    const hasNoScrollbar = element.scrollWidth <= element.clientWidth; // true if no scrollbar
    if (hasNoScrollbar) {
      return;
    }
    this.load(event);
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.appTooltipDisplay) {
      this.hideTooltip();
    }
  }
}
