export const getByPath = (obj: any, path: string): { [key: string]: any } => {
  // NOTE: this split is necessary to remove the optional chaining and non-null assertion operator
  const paths = path.replaceAll('?', '').replaceAll('!', '').split('.');
  let current;
  let i;

  for (i = 0; i < paths.length; i++) {
    current = obj[paths[i]];

    if (current !== undefined) {
      obj = current;
    } else {
      return undefined;
    }
  }

  return typeof current === 'object' ? { ...current } : current;
};
