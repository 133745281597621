import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';

@Component({
  selector: 'app-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyMessageComponent {
  @Input() imageUrl: string;
  @Input() message: string;

  @Input() imageWidth: string = '250px';
  @Input() imageHeight: string = '250px';

  @Input() prefixIcon: IconPayload;
  @Input() footerTemplate: TemplateRef<any>;

  constructor() {}
}
