import { Injector, NgModule } from '@angular/core';
import { AsyncStoragePluginInitEvent } from './async-storage-plugin-init-event';

@NgModule()
export class AsyncStoragePluginModule {
  public static injector: Injector | null = null;
  constructor(injector: Injector) {
    AsyncStoragePluginModule.injector = injector;
    AsyncStoragePluginInitEvent.next();
  }
}
