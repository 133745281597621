import { QualityIssue, TranslationUnitModel } from '@generated/api';
import { extractTranslationUnit } from '@shared/tools';
import { AgGridColumn, GridColumnParams } from '../../../../types/ag-grid';
import { ListFilterComponent } from '@shared/components/grid-filters';
import { LockProtectionCellRendererComponent } from '@report/components/report-grid/components/lock-protection-cell-renderer/lock-protection-cell-renderer.component';

type ColumnDepentOnTranslationUnit = GridColumnParams<{
  translationUnit: TranslationUnitModel;
}>;

export const protectedFilterValueAccessors = {
  Protected: (data: QualityIssue | TranslationUnitModel): boolean => extractTranslationUnit(data).isProtected,
  'Not protected': (data: QualityIssue | TranslationUnitModel): boolean => !extractTranslationUnit(data).isProtected,
  Locked: (data: QualityIssue | TranslationUnitModel): boolean => extractTranslationUnit(data).properties.locked,
  'Not locked': (data: QualityIssue | TranslationUnitModel): boolean => !extractTranslationUnit(data).properties.locked,
};

export const PROTECTION_STATUS_COLUMN: Partial<AgGridColumn> = {
  headerName: '',
  field: 'protected',
  filter: ListFilterComponent.rendererName,
  filterParams: {
    filterValueAccessors: protectedFilterValueAccessors,
    resetable: true,
    searchable: false,
  },
  suppressMovable: true,
  lockPosition: 'left',
  sortable: false,
  resizable: false,
  headerTooltip:
    'Segments marked with a shield are protected by Verifika settings.\nSegments marked with a lock are locked by CAT settings.',
  flex: 0,
  maxWidth: 36,
  minWidth: 36,
  width: 36,
  cellRenderer: LockProtectionCellRendererComponent.rendererName,
  suppressColumnsToolPanel: true,
  cellClass: ['report-protected-cell', 'cell-d-flex-center'],
  headerComponentParams: {
    iconGetter: () => ({ name: 'shield-bordered', color: 'primary' }),
  },
  tooltipComponent: 'gridTooltip',
  valueGetter: (params: ColumnDepentOnTranslationUnit) => !!params.data?.translationUnit.isProtected,
};
