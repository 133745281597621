<nav class="nav-{{ direction }}">
  <ng-container *ngFor="let item of items">
    <a
      *ngIf="!item.hidden"
      class="nav-item"
      [style.min-width]="item.width"
      routerLinkActive="active"
      [class.disabled]="item.disabled"
      [routerLink]="item.path"
      [attr.data-test-id]="'tab-' + item.path"
    >
      <app-ui-icon *ngIf="item.icon" [icon]="item.icon" size="xs" class="nav-badge"></app-ui-icon>
      <span class="nav-title" [ngClass]="{ iconified: !!item.icon }"> {{ item.title }} </span>
      <ng-container *ngIf="item.loading$ !== undefined">
        <div
          class="nav-badge"
          [class.error]="item.highlight"
          *ngIf="!(item.loading$ | async) && (item.count$ | async) > 0"
        >
          {{ item.count$ | async }}
        </div>
        <app-ui-icon
          class="nav-icon"
          *ngIf="!(item.loading$ | async) && !(item.count$ | async)"
          color="primary"
          icon="check"
          size="small"
        ></app-ui-icon>
        <app-spinner class="nav-spinner" *ngIf="item.loading$ | async"></app-spinner>
      </ng-container>
    </a>
  </ng-container>
</nav>
