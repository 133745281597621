import Dexie from 'dexie';
import { StatesMap } from './symbols';
import { PlainObject } from '@ngxs/store/internals';
interface StoreItem {
  key: string;
  state: PlainObject;
}

export class AsyncStorageEngine {
  private static dbPrefix = 'verifika';
  private static version = 1;
  private static databaseName = `${this.dbPrefix}.states`;
  private static _connection: Dexie = null;
  private static storeName = 'stores';

  private static get connection(): Dexie {
    if (!AsyncStorageEngine._connection) {
      this.createConnectionIfNotExist();
    }
    return AsyncStorageEngine._connection;
  }

  public static putState<T>(stateName: string, state: T) {
    AsyncStorageEngine.connection.table(AsyncStorageEngine.storeName).put(
      {
        key: stateName,
        state,
      },
      stateName
    );
  }

  public static async prefetchStore(): Promise<StatesMap> {
    const result: StoreItem[] = await AsyncStorageEngine.connection.table(AsyncStorageEngine.storeName).toArray();
    console.log('prefetched store', result);
    return result.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.key]: currentValue.state,
      }),
      {}
    );
  }

  private static createConnectionIfNotExist() {
    if (!AsyncStorageEngine._connection) {
      AsyncStorageEngine._connection = new Dexie(AsyncStorageEngine.databaseName);
      this.connection.version(AsyncStorageEngine.version).stores({
        [AsyncStorageEngine.storeName]: 'key',
      });
    }
  }
}
