<div>
  <span class="label">{{ sourceLabel }}</span>
  <app-search-pattern
    [formControl]="source"
    (enterKeyPressed)="enterKeyPressedHandler()"
    placeholder="Search in source"
    fieldId="sourceSearchId"
    fieldName="sourceSearch"
    crossSegmentsBackReferencesDestination="source"
    [isTooltipVisible]="isTooltipVisible"
    [crossSegmentsBackReferencesEnabled]="
      source.value?.searchType === searchType.Regex && target.value?.searchType === searchType.Regex
    "
  ></app-search-pattern>
</div>

<div>
  <span class="label">{{ targetLabel }}</span>
  <app-search-pattern
    [formControl]="target"
    (enterKeyPressed)="enterKeyPressedHandler()"
    placeholder="Search in target"
    fieldId="targetSearchId"
    fieldName="targetSearch"
    [isTooltipVisible]="isTooltipVisible"
    crossSegmentsBackReferencesDestination="target"
    [crossSegmentsBackReferencesEnabled]="
      source.value?.searchType === searchType.Regex && target.value?.searchType === searchType.Regex
    "
  ></app-search-pattern>
</div>
