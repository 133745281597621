import { Component, ElementRef, OnDestroy } from '@angular/core';
import { GridApi, RowNode } from 'src/types/ag-grid';

@Component({
  template: '',
})
export class GridPatchesComponent implements OnDestroy {
  protected viewportHeight: number;
  protected viewportScrollTop = 0;
  private api: GridApi;

  constructor(protected document: Document, protected elementRef: ElementRef) {}

  public onGridReady(params): void {
    this.api = params.api;
    this.getViewportHeight();
    this.api.addEventListener('bodyScroll', this.bodyScrollHandler);
  }

  // region Patch for scroll

  /**
   * In case of 'autoHeight=true'
   * when we call `gridApi.ensureIndexVisible(rowIndex)` row is not visible
   * because ag-grid first scrolls than changes visible rows height
   */

  private bodyScrollHandler = (event): void => {
    if (event.direction === 'vertical') {
      this.viewportScrollTop = event.top;
    }
  };

  protected getViewportHeight(): void {
    const headerElement = this.elementRef.nativeElement.querySelectorAll('.ag-header');
    const agGrid = this.elementRef.nativeElement.querySelectorAll('ag-grid-angular');
    this.viewportHeight = agGrid[0].offsetHeight - headerElement[0].offsetHeight;
  }

  public rowIsVisible(row: RowNode): boolean {
    return (
      row.rowTop >= this.viewportScrollTop && row.rowTop + row.rowHeight <= this.viewportHeight + this.viewportScrollTop
    );
  }

  public scrollToRow(row: RowNode, position?: 'top' | 'bottom' | 'middle'): void {
    let recursionCount = 0;
    const scroll = (): void => {
      recursionCount++;
      this.api.ensureIndexVisible(row.rowIndex, position);
      setTimeout(() => {
        if (!this.rowIsVisible(row) && recursionCount < 4) {
          scroll();
        }
      }, 500);
    };
    scroll();
  }

  // endregion

  ngOnDestroy() {
    this.api.removeEventListener('bodyScroll', this.bodyScrollHandler);
  }
}
