import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

let appendersModules = [];

if (environment.enableGoogleAnalytic) {
  appendersModules = [NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticKey), NgxGoogleAnalyticsRouterModule];
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ...appendersModules],
})
export class GoogleAnalyticsModule {}
