<div class="chip {{ theme }}" [ngClass]="{ 'chip-selected': selected, 'chip-rtl': rtl }">
  <div [appTooltipTruncated]="text" [appTooltipDisabled]="truncatedTooltipDisabled" class="chip-text">{{ text }}</div>
  <app-ui-icon
    (click)="iconClickHandler($event)"
    class="chip-icon"
    [icon]="icon"
    size="12px"
    color="inherit"
  ></app-ui-icon>
</div>
