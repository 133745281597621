import { InjectionToken } from '@angular/core';
import { LogLevel } from './log-level.enum';

export const LOG_APPENDER_TOKEN = new InjectionToken('LOG_APPENDER_TOKEN');
export const LOG_APPENDER_CONFIG_TOKEN = new InjectionToken('LOG_APPENDER_CONFIG_TOKEN');

export interface LogAppenderConfig {
  level: LogLevel;
  disabled?: boolean;
}

export interface LogAppender {
  config: LogAppenderConfig;

  log(level: LogLevel, message: any, ...optionalParams: any[]): void;
}
