import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { TagView } from '@shared/models';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { SetTagView, ToggleMultiline, ToggleShowInvisibles, ToggleShowComment } from './common-report-view.actions';

interface CommonReportViewStateModel {
  showInvisibles: boolean;
  showComment: boolean;
  tagView: TagView;
  multiline: boolean;
}

const booleanValueInverse: StateOperator<boolean> = (value) => !value;

const defaultState: CommonReportViewStateModel = {
  showInvisibles: false,
  showComment: false,
  multiline: true,
  tagView: TagView.Medium,
};

@AsyncStorage
@StateRepository()
@State<CommonReportViewStateModel>({
  name: 'commonReportView',
  defaults: defaultState,
})
@Injectable()
export class CommonReportViewState {
  @Selector([CommonReportViewState])
  public static showInvisibles(state: CommonReportViewStateModel): boolean {
    return state?.showInvisibles;
  }

  @Selector([CommonReportViewState])
  public static showComment(state: CommonReportViewStateModel): boolean {
    return state?.showComment;
  }

  @Selector([CommonReportViewState])
  public static tagView(state: CommonReportViewStateModel): TagView {
    return state?.tagView;
  }

  @Selector([CommonReportViewState])
  public static multiline(state: CommonReportViewStateModel): boolean {
    return !!state?.multiline;
  }

  @Action(ToggleMultiline)
  public toggleMultiline(ctx: StateContext<CommonReportViewStateModel>, action: ToggleMultiline): void {
    ctx.setState(
      patch({
        multiline: action.value,
      })
    );
  }

  @Action(SetTagView)
  public setTagFilter(ctx: StateContext<CommonReportViewStateModel>, { tagView }: SetTagView): void {
    ctx.setState(
      patch({
        tagView,
      })
    );
  }

  @Action(ToggleShowInvisibles)
  public toggleShowInvisibles(ctx: StateContext<CommonReportViewStateModel>, { status }: ToggleShowInvisibles): void {
    ctx.setState(
      patch({
        showInvisibles: status,
      })
    );
  }

  @Action(ToggleShowComment)
  public toggleShowComment(ctx: StateContext<CommonReportViewStateModel>, action: ToggleShowComment): void {
    const showComment = action.status ?? booleanValueInverse;

    ctx.setState(
      patch({
        showComment,
      })
    );
  }
}
