function cleanseAssertionOperators(parsedName: string): string {
  return parsedName.replace(/[?!]/g, '');
}

export function nameof<T extends Object>(
  nameFunction: ((obj: T) => any) | { new (...params: any[]): T }
): string {
  const fnStr = nameFunction.toString();

  if (fnStr.startsWith('class ') && !fnStr.startsWith('class =>')) {
    return cleanseAssertionOperators(
      fnStr.substring('class '.length, fnStr.indexOf(' {'))
    );
  }

  if (fnStr.includes('=>')) {
    return cleanseAssertionOperators(fnStr.substring(fnStr.indexOf('.') + 1));
  }
}
