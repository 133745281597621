import { ReportExportModel, ReportExportParameter } from '@generated/api';
import { ReportTypeColumnOptions, ReportType, ReportTypes } from '../configs';
import { mapFromColumnState, mapFromFilterState, ReportTypeToReportTabMap } from '../mappers';
import { ReportGridsStateModel, ReportGridState } from '@store/report-grids-store/report-grids-state.model';

/**
 * Builds report export request model
 */
export function buildReportExportModel(gridsState: ReportGridsStateModel): ReportExportModel {
  if (!gridsState) {
    return null;
  }

  const exportParameters = ReportTypes.map((reportType: ReportType) => {
    const tab = ReportTypeToReportTabMap[reportType];
    if (!tab || !gridsState[tab]) {
      return null;
    }

    return buildReportExportParameter(gridsState[tab], reportType);
  }).filter((param) => !!param);

  return {
    exportParameters,
  };
}

function buildReportExportParameter(gridState: ReportGridState, reportType: ReportType): ReportExportParameter {
  const columns = gridState.columns
    ?.filter((col) => !!ReportTypeColumnOptions[reportType]?.[col.colId])
    .map((col) => mapFromColumnState(reportType, col));
  const filters = gridState.filters
    ?.filter((col) => !!ReportTypeColumnOptions[reportType]?.[col.colId])
    .map((col) => mapFromFilterState(reportType, col));

  return { reportType, columns, filters };
}
