<svg-icon
  *ngIf="size === 'auto'; else withSizeTemplate"
  [key]="icon"
  [color]="innerColor"
  [ngClass]="className"
></svg-icon>

<ng-template #withSizeTemplate>
  <svg-icon
    [key]="icon"
    [size]="size"
    [color]="innerColor"
    [width]="containerSize"
    [height]="containerSize"
    [ngClass]="className"
  ></svg-icon>
</ng-template>
