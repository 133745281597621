import { Injectable } from '@angular/core';
import { SelectionRange } from '@shared/models';
import { htmlEncode } from '@shared/tools';

@Injectable({
  providedIn: 'root',
})
export class HighligthService {
  public highlight(text: string, selectionRanges: SelectionRange[]) {
    let html = '';
    let skip = 0;
    selectionRanges?.forEach(({ start, end, isFixAvailable }) => {
      if (isFixAvailable) {
        return;
      }

      html += htmlEncode(text.substring(skip, start));
      html += `<span class="vr-highlight">${htmlEncode(text.substring(start, end))}</span>`;
      skip = end;
    });

    if (text.length > skip) {
      html += htmlEncode(text.substring(skip));
    }

    return html;
  }
}
