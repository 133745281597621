import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon';
import { SearchInputModule } from '../search-input';
import { SpecialSymbolsComponent } from './special-symbols.component';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SearchInputModule, IconModule, ButtonModule],
  declarations: [SpecialSymbolsComponent],
  exports: [SpecialSymbolsComponent],
  providers: [],
})
export class SpecialSymbolsModule {}
