import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Organization } from '@generated/api';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsListComponent {
  @Input()
  public organizations: Organization[];

  @Input()
  public selectedOrganizationId: string;

  #accountRoles: string[];
  @Input()
  set accountRoles(accountRoles: string[]) {
    this.#accountRoles = accountRoles ?? [];
  }
  get roles(): string {
    return this.#accountRoles.join(', ');
  }

  @Output()
  private readonly create: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  private readonly organizationSelect: EventEmitter<Organization> = new EventEmitter<Organization>();

  @Output()
  private readonly invite: EventEmitter<Organization> = new EventEmitter<Organization>();

  constructor() {}

  public createOrganization(): void {
    this.create.emit();
  }

  public selectOrganization(organization: Organization): void {
    if (this.selectedOrganizationId && this.selectedOrganizationId === organization?.id) {
      return;
    }
    this.organizationSelect.emit(organization);
  }

  public inviteUser(organization: Organization): void {
    this.invite.emit(organization);
  }
}
