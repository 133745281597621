import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchPatternComponent } from './search-pattern.component';
import { TooltipModule } from '@shared/components/tooltip';
import { LoaderModule } from '@shared/components/loader';
import { SearchPatternValidatorService } from '@shared/components/search-pattern/search-pattern-validator.service';
import { DirectivesModule } from '@shared/directives';
import { SpecialSymbolsModule } from '@shared/components/special-symbols/special-symbols.module';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TooltipModule,
    LoaderModule,
    DirectivesModule,
    SpecialSymbolsModule,
    ActionIconModule,
  ],
  declarations: [SearchPatternComponent],
  exports: [SearchPatternComponent],
  providers: [SearchPatternValidatorService],
})
export class SearchPatternModule {}
