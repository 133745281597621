import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DigitCellEditorComponent, ListCellEditorComponent } from '@shared/components/grid-components';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseSettingsGridComponent } from '@shared/components/common';
import { stringComparator } from '@shared/tools';
import { ProfileCultureDigitsToText, ProfileLocalizedUnit } from '@shared/models';
import { AgGridColumn } from 'src/types/ag-grid';
import { SuppressKeyboardEventParams } from '@ag-grid-community/core/dist/cjs/es5/entities/colDef';
import { GetQuickFilterTextParams, NewValueParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-digits-to-text',
  templateUrl: './digit-to-text.component.html',
  styleUrls: ['./digit-to-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DigitsToTextComponent,
    },
  ],
})
export class DigitsToTextComponent extends BaseSettingsGridComponent<ProfileCultureDigitsToText> {
  columnDefs: Partial<AgGridColumn>[] = [
    {
      sortable: false,
      resizable: false,
      headerName: '',
      width: 36,
      flex: 0,
      editable: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressKeyboardEvent: (params): boolean => this.suppressTab(params),
      checkboxSelection: true,
      headerClass: 'checkbox-header',
      cellClass: 'checkbox-cell',
      getQuickFilterText: (): string => '',
    },
    {
      headerName: 'Digit',
      colId: 'digit',
      field: 'digit',
      editable: true,
      resizable: false,
      flex: 1,
      cellRenderer: 'searchedRenderer',
      cellEditor: 'digitEditor',
      cellRendererParams: { searched: (): string => this.searchControl.value },
      comparator: (a, b): number => a - b,
    },
    {
      headerName: 'Target forms',
      colId: 'values',
      field: 'forms',
      editable: true,
      resizable: false,
      flex: 2,
      autoHeight: true,
      wrapText: true,
      cellRenderer: 'multilineRenderer',
      cellEditor: ListCellEditorComponent.rendererName,
      headerClass: 'splitter-none',
      cellRendererParams: { searched: (): string => this.searchControl.value },
      comparator: (a = [], b = []): number => {
        const valueA = a[0] || '';
        const valueB = b[0] || '';

        return stringComparator(valueA + '', valueB + '');
      },
      getQuickFilterText: (params: GetQuickFilterTextParams<ProfileLocalizedUnit>): string => params.value?.join(),
      cellEditorParams: {
        createNewRow: this.add.bind(this),
      },
    },
  ];

  overlayNoRowsText = 'Click Plus button to add a new digit';

  constructor() {
    super();
    this.gridComponents.digitEditor = DigitCellEditorComponent;
  }

  public add(): void {
    const newItem: ProfileCultureDigitsToText = {
      digit: this.getNextNumber(),
      forms: [],
    };
    this.gridApi.applyTransaction({
      add: [newItem],
      addIndex: 0,
    });
    this.startEdit(0, 'digit');

    this._data.unshift(newItem);
    this.propagateChanges();
  }

  public remove(): void {
    const selectedRows = this.getSelected();
    const selectedDigits = selectedRows.map(({ digit }: ProfileCultureDigitsToText) => digit);
    this._data = this._data.filter((item) => !selectedDigits.includes(item.digit));

    this.gridApi.applyTransaction({
      remove: selectedRows,
    });
    this.propagateChanges();
  }

  public onCellChanged(event: NewValueParams): void {
    const existingRow = this._data.some((row) => row.digit === event.data.digit);
    if (existingRow) {
      this._data = this._data.map((item) => (item.digit === event.data.digit ? event.data : item));
    } else {
      this._data.unshift(event.data as ProfileCultureDigitsToText);
    }
    this.propagateChanges();
  }

  public rowsIsEqual(aItem: ProfileCultureDigitsToText, bItem: ProfileCultureDigitsToText): boolean {
    if (aItem.digit !== bItem.digit) {
      return false;
    }
    return aItem.forms?.join() === bItem.forms?.join();
  }

  private getNextNumber(): number {
    const value = this._data || [];
    const sorted = value.map(({ digit }: ProfileCultureDigitsToText) => digit).sort((a, b) => a - b);

    for (let i = 0, n = sorted[sorted.length - 1]; i < n; i++) {
      if (sorted[i] !== i) {
        return i;
      }
    }
    return sorted.length;
  }

  public suppressTab(params: SuppressKeyboardEventParams): boolean {
    const KEY_TAB = 'DOM_VK_TAB';
    return params.event.code === KEY_TAB;
  }
}
