/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CloneQaSettingsRequest } from '../models/clone-qa-settings-request';
import { CopyQaSettingsRequest } from '../models/copy-qa-settings-request';
import { CreateQaSettingsTemplateRequest } from '../models/create-qa-settings-template-request';
import { Operation } from '../models/operation';
import { ProfileReferenceDataModel } from '../models/profile-reference-data-model';
import { QaSettings } from '../models/qa-settings';
import { QaSettingsInfo } from '../models/qa-settings-info';
import { QaSettingsModel } from '../models/qa-settings-model';
import { RenameQaSettingsRequest } from '../models/rename-qa-settings-request';
import { UpdateQaSettingsRequest } from '../models/update-qa-settings-request';

@Injectable({
  providedIn: 'root',
})
export class QaSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiQaSettingsGet
   */
  static readonly ApiQaSettingsGetPath = '/api/QASettings';

  /**
   * Get profile info list for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsGet$Plain$Response(params: {

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<StrictHttpResponse<Array<QaSettingsInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsGetPath, 'get');
    if (params) {
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QaSettingsInfo>>;
      })
    );
  }

  /**
   * Get profile info list for user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsGet$Plain(params: {

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<Array<QaSettingsInfo>> {

    return this.apiQaSettingsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QaSettingsInfo>>) => r.body as Array<QaSettingsInfo>)
    );
  }

  /**
   * Get profile info list for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsGet$Json$Response(params: {

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<StrictHttpResponse<Array<QaSettingsInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsGetPath, 'get');
    if (params) {
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QaSettingsInfo>>;
      })
    );
  }

  /**
   * Get profile info list for user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsGet$Json(params: {

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<Array<QaSettingsInfo>> {

    return this.apiQaSettingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QaSettingsInfo>>) => r.body as Array<QaSettingsInfo>)
    );
  }

  /**
   * Path part for operation apiQaSettingsPost
   */
  static readonly ApiQaSettingsPostPath = '/api/QASettings';

  /**
   * Create QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsPost$Plain$Response(params: {
    'api-version': string;

    /**
     * CreateQASettingsTemplateRequest
     */
    body?: CreateQaSettingsTemplateRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Create QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsPost$Plain(params: {
    'api-version': string;

    /**
     * CreateQASettingsTemplateRequest
     */
    body?: CreateQaSettingsTemplateRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Create QA settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsPost$Json$Response(params: {
    'api-version': string;

    /**
     * CreateQASettingsTemplateRequest
     */
    body?: CreateQaSettingsTemplateRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Create QA settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsPost$Json(params: {
    'api-version': string;

    /**
     * CreateQASettingsTemplateRequest
     */
    body?: CreateQaSettingsTemplateRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Path part for operation apiQaSettingsSearchGet
   */
  static readonly ApiQaSettingsSearchGetPath = '/api/QASettings/Search';

  /**
   * Search QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsSearchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsSearchGet$Plain$Response(params: {

    /**
     * term
     */
    term?: string;

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<StrictHttpResponse<Array<QaSettingsInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsSearchGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QaSettingsInfo>>;
      })
    );
  }

  /**
   * Search QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsSearchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsSearchGet$Plain(params: {

    /**
     * term
     */
    term?: string;

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<Array<QaSettingsInfo>> {

    return this.apiQaSettingsSearchGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QaSettingsInfo>>) => r.body as Array<QaSettingsInfo>)
    );
  }

  /**
   * Search QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsSearchGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsSearchGet$Json$Response(params: {

    /**
     * term
     */
    term?: string;

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<StrictHttpResponse<Array<QaSettingsInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsSearchGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QaSettingsInfo>>;
      })
    );
  }

  /**
   * Search QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsSearchGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsSearchGet$Json(params: {

    /**
     * term
     */
    term?: string;

    /**
     * skip
     */
    skip?: number;

    /**
     * take
     */
    take?: number;
    'api-version': string;
  }): Observable<Array<QaSettingsInfo>> {

    return this.apiQaSettingsSearchGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QaSettingsInfo>>) => r.body as Array<QaSettingsInfo>)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdGet
   */
  static readonly ApiQaSettingsIdGetPath = '/api/QASettings/{id}';

  /**
   * Get a specific QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdGet$Plain$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Get a specific QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdGet$Plain(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Get a specific QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdGet$Json$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Get a specific QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdGet$Json(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdPut
   */
  static readonly ApiQaSettingsIdPutPath = '/api/QASettings/{id}';

  /**
   * Update a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPut$Plain$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * UpdateQASettingsRequest
     */
    body?: UpdateQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Update a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPut$Plain(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * UpdateQASettingsRequest
     */
    body?: UpdateQaSettingsRequest
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Update a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPut$Json$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * UpdateQASettingsRequest
     */
    body?: UpdateQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Update a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPut$Json(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * UpdateQASettingsRequest
     */
    body?: UpdateQaSettingsRequest
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdDelete
   */
  static readonly ApiQaSettingsIdDeletePath = '/api/QASettings/{id}';

  /**
   * Delete a specific QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdDelete$Response(params: {

    /**
     * QASettings ID
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a specific QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdDelete(params: {

    /**
     * QASettings ID
     */
    id: string;
    'api-version': string;
  }): Observable<void> {

    return this.apiQaSettingsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdPatch
   */
  static readonly ApiQaSettingsIdPatchPath = '/api/QASettings/{id}';

  /**
   * Partially update QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPatch$Plain$Response(params: {
    id: string;
    'api-version': string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Partially update QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPatch$Plain(params: {
    id: string;
    'api-version': string;
    body: Array<Operation>
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdPatch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Partially update QASettings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPatch$Json$Response(params: {
    id: string;
    'api-version': string;
    body: Array<Operation>
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Partially update QASettings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdPatch$Json(params: {
    id: string;
    'api-version': string;
    body: Array<Operation>
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdPatch$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Path part for operation apiQaSettingsDefaultGet
   */
  static readonly ApiQaSettingsDefaultGetPath = '/api/QASettings/default';

  /**
   * Get an default profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsDefaultGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsDefaultGet$Plain$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettings>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettings>;
      })
    );
  }

  /**
   * Get an default profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsDefaultGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsDefaultGet$Plain(params: {
    'api-version': string;
  }): Observable<QaSettings> {

    return this.apiQaSettingsDefaultGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettings>) => r.body as QaSettings)
    );
  }

  /**
   * Get an default profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsDefaultGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsDefaultGet$Json$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettings>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsDefaultGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettings>;
      })
    );
  }

  /**
   * Get an default profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsDefaultGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsDefaultGet$Json(params: {
    'api-version': string;
  }): Observable<QaSettings> {

    return this.apiQaSettingsDefaultGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettings>) => r.body as QaSettings)
    );
  }

  /**
   * Path part for operation apiQaSettingsBlankGet
   */
  static readonly ApiQaSettingsBlankGetPath = '/api/QASettings/blank';

  /**
   * Get an blank profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsBlankGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsBlankGet$Plain$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettings>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsBlankGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettings>;
      })
    );
  }

  /**
   * Get an blank profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsBlankGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsBlankGet$Plain(params: {
    'api-version': string;
  }): Observable<QaSettings> {

    return this.apiQaSettingsBlankGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettings>) => r.body as QaSettings)
    );
  }

  /**
   * Get an blank profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsBlankGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsBlankGet$Json$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettings>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsBlankGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettings>;
      })
    );
  }

  /**
   * Get an blank profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsBlankGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsBlankGet$Json(params: {
    'api-version': string;
  }): Observable<QaSettings> {

    return this.apiQaSettingsBlankGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettings>) => r.body as QaSettings)
    );
  }

  /**
   * Path part for operation apiQaSettingsReferencesGet
   */
  static readonly ApiQaSettingsReferencesGetPath = '/api/QASettings/references';

  /**
   * Get reference data (Apostrophes, Quotes, DecimalSeparators, GroupSeparators, NumberRanges).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsReferencesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsReferencesGet$Plain$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<ProfileReferenceDataModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsReferencesGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileReferenceDataModel>;
      })
    );
  }

  /**
   * Get reference data (Apostrophes, Quotes, DecimalSeparators, GroupSeparators, NumberRanges).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsReferencesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsReferencesGet$Plain(params: {
    'api-version': string;
  }): Observable<ProfileReferenceDataModel> {

    return this.apiQaSettingsReferencesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileReferenceDataModel>) => r.body as ProfileReferenceDataModel)
    );
  }

  /**
   * Get reference data (Apostrophes, Quotes, DecimalSeparators, GroupSeparators, NumberRanges).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsReferencesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsReferencesGet$Json$Response(params: {
    'api-version': string;
  }): Observable<StrictHttpResponse<ProfileReferenceDataModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsReferencesGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileReferenceDataModel>;
      })
    );
  }

  /**
   * Get reference data (Apostrophes, Quotes, DecimalSeparators, GroupSeparators, NumberRanges).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsReferencesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsReferencesGet$Json(params: {
    'api-version': string;
  }): Observable<ProfileReferenceDataModel> {

    return this.apiQaSettingsReferencesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileReferenceDataModel>) => r.body as ProfileReferenceDataModel)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdRenamePost
   */
  static readonly ApiQaSettingsIdRenamePostPath = '/api/QASettings/{id}/Rename';

  /**
   * Rename a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdRenamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdRenamePost$Plain$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * RenameQASettingsRequest
     */
    body?: RenameQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdRenamePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Rename a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdRenamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdRenamePost$Plain(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * RenameQASettingsRequest
     */
    body?: RenameQaSettingsRequest
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdRenamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Rename a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdRenamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdRenamePost$Json$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * RenameQASettingsRequest
     */
    body?: RenameQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdRenamePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Rename a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdRenamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdRenamePost$Json(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * RenameQASettingsRequest
     */
    body?: RenameQaSettingsRequest
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdRenamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdTouchedPost
   */
  static readonly ApiQaSettingsIdTouchedPostPath = '/api/QASettings/{id}/Touched';

  /**
   * Touch a specific project with settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdTouchedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdTouchedPost$Plain$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdTouchedPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Touch a specific project with settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdTouchedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdTouchedPost$Plain(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdTouchedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Touch a specific project with settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdTouchedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdTouchedPost$Json$Response(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdTouchedPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Touch a specific project with settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdTouchedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdTouchedPost$Json(params: {

    /**
     * id
     */
    id: string;
    'api-version': string;
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsIdTouchedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdResetPost
   */
  static readonly ApiQaSettingsIdResetPostPath = '/api/QASettings/{id}/Reset';

  /**
   * Reset a default profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdResetPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdResetPost$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdResetPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reset a default profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdResetPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdResetPost(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<void> {

    return this.apiQaSettingsIdResetPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdClearPost
   */
  static readonly ApiQaSettingsIdClearPostPath = '/api/QASettings/{id}/Clear';

  /**
   * Clear a default profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdClearPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdClearPost$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdClearPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Clear a default profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdClearPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdClearPost(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<void> {

    return this.apiQaSettingsIdClearPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdClonePost
   */
  static readonly ApiQaSettingsIdClonePostPath = '/api/QASettings/{id}/Clone';

  /**
   * Clone a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdClonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdClonePost$Plain$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * CloneQASettingsRequest
     */
    body?: CloneQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdClonePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Clone a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdClonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdClonePost$Plain(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * CloneQASettingsRequest
     */
    body?: CloneQaSettingsRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsIdClonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Clone a specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdClonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdClonePost$Json$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * CloneQASettingsRequest
     */
    body?: CloneQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdClonePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Clone a specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdClonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsIdClonePost$Json(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;

    /**
     * CloneQASettingsRequest
     */
    body?: CloneQaSettingsRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsIdClonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Path part for operation apiQaSettingsCopyPost
   */
  static readonly ApiQaSettingsCopyPostPath = '/api/QASettings/Copy';

  /**
   * Copying a specific profile to another specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsCopyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsCopyPost$Plain$Response(params: {
    'api-version': string;

    /**
     * CopyQASettingsRequest
     */
    body?: CopyQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsCopyPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Copying a specific profile to another specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsCopyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsCopyPost$Plain(params: {
    'api-version': string;

    /**
     * CopyQASettingsRequest
     */
    body?: CopyQaSettingsRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsCopyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Copying a specific profile to another specific profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsCopyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsCopyPost$Json$Response(params: {
    'api-version': string;

    /**
     * CopyQASettingsRequest
     */
    body?: CopyQaSettingsRequest
  }): Observable<StrictHttpResponse<QaSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsCopyPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsModel>;
      })
    );
  }

  /**
   * Copying a specific profile to another specific profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsCopyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQaSettingsCopyPost$Json(params: {
    'api-version': string;

    /**
     * CopyQASettingsRequest
     */
    body?: CopyQaSettingsRequest
  }): Observable<QaSettingsModel> {

    return this.apiQaSettingsCopyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsModel>) => r.body as QaSettingsModel)
    );
  }

  /**
   * Path part for operation apiQaSettingsIdSetupDefaultPost
   */
  static readonly ApiQaSettingsIdSetupDefaultPostPath = '/api/QASettings/{id}/SetupDefault';

  /**
   * Set as default.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsIdSetupDefaultPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdSetupDefaultPost$Response(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsIdSetupDefaultPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set as default.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsIdSetupDefaultPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQaSettingsIdSetupDefaultPost(params: {

    /**
     * Profile ID
     */
    id: string;
    'api-version': string;
  }): Observable<void> {

    return this.apiQaSettingsIdSetupDefaultPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiQaSettingsUploadPost
   */
  static readonly ApiQaSettingsUploadPostPath = '/api/QASettings/Upload';

  /**
   * Upload QA Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsUploadPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQaSettingsUploadPost$Plain$Response(params: {
    'api-version': string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Upload QA Settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsUploadPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQaSettingsUploadPost$Plain(params: {
    'api-version': string;
    body?: {
'file'?: Blob;
}
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsUploadPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

  /**
   * Upload QA Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQaSettingsUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQaSettingsUploadPost$Json$Response(params: {
    'api-version': string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<QaSettingsInfo>> {

    const rb = new RequestBuilder(this.rootUrl, QaSettingsService.ApiQaSettingsUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QaSettingsInfo>;
      })
    );
  }

  /**
   * Upload QA Settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQaSettingsUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQaSettingsUploadPost$Json(params: {
    'api-version': string;
    body?: {
'file'?: Blob;
}
  }): Observable<QaSettingsInfo> {

    return this.apiQaSettingsUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QaSettingsInfo>) => r.body as QaSettingsInfo)
    );
  }

}
