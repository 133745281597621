import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { enablePatches, setAutoFreeze } from 'immer';

import {
  ProjectsState,
  ProjectState,
  QASettingsTemplatesState,
  QASettingsState,
  LocaleQASettingsState,
  GlossaryListState,
  ProjectFilesState,
  AuthState,
  ReportViewState,
  LanguageState,
  GlossaryTermsState,
  TranslationUnitsState,
  ReportGridsState,
  ReferenceState,
  CommonReportViewState,
  SystemState,
  PaymentState,
} from 'src/app/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN, NGXS_DATA_STORAGE_PREFIX_TOKEN } from '@angular-ru/ngxs/storage';
import { ngxsConfig } from '../config';
import { AsyncStoragePluginModule } from './plugins/async-storage-plugin';
import { QualityIssueDAO, SystemDAO } from '../core/daos';
import { LocalizationPlatformsState } from '@store/localization-platforms-store';
import { AvailableLanguagesState } from './available-languages';
import { QualityIssueKindsState } from '@store/quality-issue-kinds-store/quality-issue-kinds.state';
import { ReportState } from './report-store/report.state';
import { UploadingFilesState } from '@store/uploading-files';
import { StateClass } from '@ngxs/store/internals';
import { SpecialSymbolsState } from './special-symbols-store/special-symbols.state';
import { PinnedProjectsState } from './projects-pins/projects-pin.state';
import { OrganizationsState } from './organizations-store/organizations.state';
import { PotentialUntranslatablesState } from '@store/potential-untranslatables';
import { environment } from 'src/environments/environment';
import { PaymentUsageLogState } from './payment-usage-log-store';

enablePatches();
setAutoFreeze(false);

@NgModule({
  imports: [
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.productionMode,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: true, // environment.production,
    }),
    NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]),
    AsyncStoragePluginModule,
    NgxsModule.forRoot(
      [
        ProjectsState,
        AuthState,
        ProjectState,
        ProjectFilesState,
        QASettingsTemplatesState,
        QASettingsState,
        LocaleQASettingsState,
        GlossaryListState,
        GlossaryTermsState,
        ReportViewState,
        ReportState,
        LanguageState,
        TranslationUnitsState,
        LocalizationPlatformsState,
        ReportGridsState,
        ReferenceState,
        AvailableLanguagesState,
        QualityIssueKindsState,
        UploadingFilesState,
        CommonReportViewState,
        SpecialSymbolsState,
        PinnedProjectsState,
        OrganizationsState,
        PotentialUntranslatablesState,
        SystemState,
        PaymentState,
        PaymentUsageLogState,
      ] as StateClass[],
      ngxsConfig
    ),
  ],
  providers: [{ provide: NGXS_DATA_STORAGE_PREFIX_TOKEN, useValue: '@verifika.store.' }, QualityIssueDAO, SystemDAO],
})
export class StoreModule {}
