export const htmlEncode = (html: string): string =>
  html.replace(/[&"'\<\>]/g, (c: string) => {
    switch (c) {
      case '&':
        return '&amp;';
      case '\'':
        return '&#39;';
      case '"':
        return '&quot;';
      case '<':
        return '&lt;';
      default:
        return '&gt;';
    }
  });
