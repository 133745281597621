import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

export interface ChipUpdateEvent {
  newValue: string;
  oldValue: string;
}

@Component({
  template: '',
})
export abstract class ChipListBaseComponent implements ControlValueAccessor, OnInit {
  @Input() headerTitle: string;
  @Input() disabled = false;
  @Input() inputPlaceholder = 'Enter or paste a list...';
  @Input() listPlaceholder = 'Enter below or import a list';

  protected list: string[] = [];
  public searchControl = new FormControl();
  public filteredList: string[] = [];
  protected abstract cdf: ChangeDetectorRef;

  get listIsEmpty(): boolean {
    return !this.list.length;
  }

  ngOnInit(): void {
    this.watchSearchController();
  }

  public onChange: (_: string[]) => void = () => {};

  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: string[]): void {
    this.list = value || [];
    this.listUpdated();
  }

  public deleteItems(items: string[]): void {
    this.list = this.list.filter((text) => !items.includes(text));
    this.listUpdated();
    this.propagateChanges();
  }

  public addNewItems(items: string[]): void {
    const filtered = items.filter((item) => !this.list.includes(item));
    this.list.push(...filtered);
    this.listUpdated();
    this.propagateChanges();
  }

  public updateItem(event: ChipUpdateEvent): void {
    if (this.list.includes(event.newValue)) {
      return;
    }
    this.list = this.list.map((text) => {
      if (text === event.oldValue) {
        return event.newValue;
      }
      return text;
    });
    this.listUpdated();
    this.propagateChanges();
  }

  public propagateChanges(): void {
    this.onChange(this.list);
  }

  private watchSearchController(): void {
    this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe((text: string) => {
      this.filterList(text);
    });
  }

  public listUpdated(): void {
    this.filterList(this.searchControl.value as string);
  }

  private filterList(text: string): void {
    const value: string = (text || '').toLowerCase();
    this.filteredList = this.list.filter((item) => item.toLowerCase().indexOf(value) !== -1);
    this.cdf.markForCheck();
  }
}
