export interface AppConfig {
  productionMode: boolean;
  environmentName: string;
  agGridLicenseKey: string;
  enableGoogleAnalytic: boolean;
  googleAnalyticKey: string;
  enableSentry: boolean;
  sentryDsn: string;
  sentryTracesRate: number;
  sentryReplaysErrorsRate: number;
  sentryReplaysSessionRate: number;
  releaseName: string;
  allowedUrls: string[];
  sendAccessToken: boolean;
  issuer: string;
  scope: string;
  clientId: string;
  maxStoredProjects: number;
}

export const APP_CONFIG: AppConfig = {
  productionMode: false,
  environmentName: 'local',
  agGridLicenseKey:
    'CompanyName=Palex Group Inc,LicensedApplication=Verifika,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-026202,ExpiryDate=18_March_2023_[v2]_MTY3OTA5NzYwMDAwMA==cefdbcf54d742178d27ac7932cf0176e',
  enableGoogleAnalytic: false,
  googleAnalyticKey: 'G-9BPC6GBGS9',
  enableSentry: false,
  sentryDsn: 'https://23f3c204b928438d84b4dea29a56091e@o1290494.ingest.sentry.io/6511593',
  sentryTracesRate: 1.0,
  sentryReplaysErrorsRate: 1.0,
  sentryReplaysSessionRate: 0,
  releaseName: '',
  // OpenID connect configuration
  // Slash is needed for local development
  allowedUrls: [
    'https://auth-dev.e-verifika.com',
    'http://localhost:4200',
    'http://localhost:4201',
    '/',
    'https://verifikasr-dev.service.signalr.net'
  ],
  sendAccessToken: true,
  issuer: 'https://auth-dev.e-verifika.com',
  scope: 'openid profile offline_access role api',
  clientId: 'verifika.web',
  maxStoredProjects: 5,
};
