import { Injectable } from '@angular/core';
import {
  EditorTagClipboard,
  PartOfSegment,
} from '@shared/components/segment-editor/models';

/**
 * Чтобы скопировать тег с source редактора на target редактор нужно знать мета данные тега.
 * Поэтому каждый раз при копировании редакторы записывают сюда данные.
 * Таргет редактор при вставлении проверят это сервис и если есть соответствующие данные тегов вставляет тег.
 */
@Injectable()
export class ClipboardBetweenEditorsService {
  private clipboard: EditorTagClipboard;

  constructor() {}

  setClipboardData(fileName: string, segment: PartOfSegment) {
    this.clipboard = {
      fileName,
      segment,
    };
  }

  getClipboardData(fileName: string): PartOfSegment {
    if (this.clipboard && this.clipboard.fileName === fileName) {
      return this.clipboard.segment;
    }
    return null;
  }

  hasClipboardData(): boolean {
    return !!this.clipboard;
  }

  // данные может принадлежат другому файлу.
  // Или после копирования в таргет редакторе,
  // пользователь скопировал другой текс из вне
  clipboardDataCanBeUsed(fileName: string, insertedText: string): boolean {
    return (
      this.clipboard.fileName === fileName &&
      this.clipboard.segment.text === insertedText
    );
  }
}
