import { Injectable } from '@angular/core';
import { KeepAliveAction, WorkerEventType } from '@workers/worker.actions';
import { Subject } from 'rxjs';

export class ClientSharedWorkerConnection<T> {
  constructor(private readonly sharedWorker: SharedWorker) {
    this.watchMessages();
  }

  public readonly message$: Subject<T> = new Subject();
  public readonly error$: Subject<MessageEvent> = new Subject();
  public readonly closed$: Subject<void> = new Subject();

  private watchMessages(): void {
    this.sharedWorker.port.onmessage = ({ data }: { data: T }): void => {
      const isKeepAliveAction = this.checkKeepAliveAction(data as unknown as KeepAliveAction);
      if (isKeepAliveAction) {
        return;
      }
      this.message$.next(data);
    };
    this.sharedWorker.port.onmessageerror = (error): void => {
      this.error$.next(error);
    };
  }

  private checkKeepAliveAction(data: KeepAliveAction): boolean {
    if (data.type === WorkerEventType.KeepAlive) {
      this.sharedWorker.port.postMessage(new KeepAliveAction(data.id));
      return true;
    }
  }

  public emit(data: T): void {
    this.sharedWorker.port.postMessage(data);
  }

  public close(): void {
    this.closed$.next();
    this.sharedWorker.port.close();
  }
}

type AvailableWorker = 'auth';

@Injectable()
export class SharedWorkerService {
  constructor() {}

  /**
   * Creates a new connection to a shared worker.
   *
   * @param workerName The name of the worker to connect to.
   */
  public registerSharedWorker<T = any>(workerName: AvailableWorker): ClientSharedWorkerConnection<T> {
    if (typeof SharedWorker === 'undefined') {
      console.warn('SharedWorker is not supported');
      return;
    }
    const sharedWorker = this.initSharedWorker(workerName);
    const sharedWorkerConnection = new ClientSharedWorkerConnection<T>(sharedWorker);
    return sharedWorkerConnection;
  }

  private initSharedWorker(workerName: AvailableWorker): SharedWorker {
    if (workerName === 'auth') {
      return new SharedWorker(new URL('../../../workers/auth.worker', import.meta.url));
    }
  }
}
