import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../modules/auth';

@Injectable()
export class TokenAwaitInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.issuer)) {
      return next.handle(req);
    }
    return this.handleRequestAfterValidToken(req, next);
  }

  private handleRequestAfterValidToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.accessToken$.pipe(
      filter((t) => !!t),
      take(1),
      switchMap(() => next.handle(req))
    );
  }
}
