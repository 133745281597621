import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileChooserComponent } from '@shared/components/file-chooser/file-chooser.component';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [FileChooserComponent],
  exports: [FileChooserComponent],
})
export class FileChooserModule {}
