import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes';
import { IconModule } from '../icon';
import { CommentThreadComponent } from './comment-thread.component';
import { CommentComponent } from './comment/comment.component';
import { SegmentRendererModule } from '@shared/components/segment-renderer';
import { TooltipModule } from '@shared/components/tooltip';
import { DropdownMenuModule } from '@shared/components/dropdown-menu';
import { ActionIconModule } from '@shared/components/action-icon';
import { ButtonModule } from '@shared/components/button';
import { TextFieldModule } from '@angular/cdk/text-field';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    FormsModule,
    PipesModule,
    SegmentRendererModule,
    TooltipModule,
    DropdownMenuModule,
    ActionIconModule,
    ButtonModule,
    TextFieldModule,
  ],
  declarations: [CommentThreadComponent, CommentComponent],
  exports: [CommentThreadComponent],
})
export class CommentModule {}
