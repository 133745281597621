import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';
import { TagSegmentElement } from '@shared/models';
import { CopiedTag } from '@shared/components/segment-editor/models';

/**
 * Used for sharing tags between source and target editor;
 */
interface TagSharingRoom {
  inTargetEditorAltPressed: BehaviorSubject<boolean>;
  clipboard: Subject<CopiedTag>;
  targetEditorHasSelected: boolean;
  /**
   * into this field target editor puts tag number which tag to be copied
   */
  tagNumberForCopy: BehaviorSubject<number>;
}

@Injectable()
export class TagSharingRoomService {
  tagSharingRooms: { [key: string]: TagSharingRoom } = {};

  public initSharingRoom(key: string): void {
    if (!this.tagSharingRooms[key]) {
      this.tagSharingRooms[key] = {
        inTargetEditorAltPressed: new BehaviorSubject<boolean>(false),
        clipboard: new Subject(),
        targetEditorHasSelected: false,
        tagNumberForCopy: new BehaviorSubject<number>(null),
      };
    }
  }

  public getTagSharingRoom(key: string): TagSharingRoom {
    return this.tagSharingRooms[key];
  }

  public setTagToClipboard(key: string, clickedTag: TagSegmentElement, tagPair?: TagSegmentElement): void {
    this.tagSharingRooms[key].clipboard.next({
      tag: clickedTag,
      tagPair,
    });
  }

  public getTagFromClipboard(key: string): Subject<CopiedTag> {
    return this.tagSharingRooms[key].clipboard;
  }

  public getAltPressed(key: string): BehaviorSubject<boolean> {
    return this.tagSharingRooms[key].inTargetEditorAltPressed;
  }

  public setAltPressed(key: string, value: boolean): void {
    this.tagSharingRooms[key].inTargetEditorAltPressed.next(value);
  }

  public getSelected(key: string): boolean {
    return this.tagSharingRooms[key].targetEditorHasSelected;
  }

  public setSelected(key: string, value: boolean): void {
    this.tagSharingRooms[key].targetEditorHasSelected = value;
  }

  public copyTag(key: string, tagNumber: number): void {
    if (tagNumber === null) {
      this.tagSharingRooms[key].tagNumberForCopy.next(null);
      return;
    }
    const currentTagNumber = this.tagSharingRooms[key].tagNumberForCopy.getValue();
    let newTagNumber: number;
    if (currentTagNumber) {
      newTagNumber = parseInt(currentTagNumber.toString() + tagNumber.toString(), 10);
    } else {
      newTagNumber = tagNumber;
    }
    this.tagSharingRooms[key].tagNumberForCopy.next(newTagNumber);
  }

  public getCopyTagNumber(key: string): Observable<number> {
    return this.tagSharingRooms[key].tagNumberForCopy.pipe(
      filter((value) => !!value),
      debounce((value) => timer(value <= 9 ? 250 : 0))
    );
  }
}
