/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeWordFormModel } from '../models/change-word-form-model';
import { CreateWordFormRequest } from '../models/create-word-form-request';
import { UpdateWordFormRequest } from '../models/update-word-form-request';
import { WordFormModel } from '../models/word-form-model';
import { WordFormModelDataSourceResponse } from '../models/word-form-model-data-source-response';

@Injectable({
  providedIn: 'root',
})
export class WordformsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiWordformsGet
   */
  static readonly ApiWordformsGetPath = '/api/Wordforms';

  /**
   * Get all word forms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsGet$Plain$Response(params?: {
    wordformsBaseId?: string;
    languageIds?: Array<number>;
    term?: string;
    suppressFullSearch?: boolean;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordFormModelDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsGetPath, 'get');
    if (params) {
      rb.query('wordformsBaseId', params.wordformsBaseId, {});
      rb.query('languageIds', params.languageIds, {});
      rb.query('term', params.term, {});
      rb.query('suppressFullSearch', params.suppressFullSearch, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordFormModelDataSourceResponse>;
      })
    );
  }

  /**
   * Get all word forms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsGet$Plain(params?: {
    wordformsBaseId?: string;
    languageIds?: Array<number>;
    term?: string;
    suppressFullSearch?: boolean;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<WordFormModelDataSourceResponse> {

    return this.apiWordformsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WordFormModelDataSourceResponse>) => r.body as WordFormModelDataSourceResponse)
    );
  }

  /**
   * Get all word forms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsGet$Json$Response(params?: {
    wordformsBaseId?: string;
    languageIds?: Array<number>;
    term?: string;
    suppressFullSearch?: boolean;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<WordFormModelDataSourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsGetPath, 'get');
    if (params) {
      rb.query('wordformsBaseId', params.wordformsBaseId, {});
      rb.query('languageIds', params.languageIds, {});
      rb.query('term', params.term, {});
      rb.query('suppressFullSearch', params.suppressFullSearch, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WordFormModelDataSourceResponse>;
      })
    );
  }

  /**
   * Get all word forms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsGet$Json(params?: {
    wordformsBaseId?: string;
    languageIds?: Array<number>;
    term?: string;
    suppressFullSearch?: boolean;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    'api-version'?: string;
  }): Observable<WordFormModelDataSourceResponse> {

    return this.apiWordformsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WordFormModelDataSourceResponse>) => r.body as WordFormModelDataSourceResponse)
    );
  }

  /**
   * Path part for operation apiWordformsPost
   */
  static readonly ApiWordformsPostPath = '/api/Wordforms';

  /**
   * Create a new word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * CreateWordFormRequest
     */
    body?: CreateWordFormRequest
  }): Observable<StrictHttpResponse<ChangeWordFormModel>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeWordFormModel>;
      })
    );
  }

  /**
   * Create a new word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * CreateWordFormRequest
     */
    body?: CreateWordFormRequest
  }): Observable<ChangeWordFormModel> {

    return this.apiWordformsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeWordFormModel>) => r.body as ChangeWordFormModel)
    );
  }

  /**
   * Create a new word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * CreateWordFormRequest
     */
    body?: CreateWordFormRequest
  }): Observable<StrictHttpResponse<ChangeWordFormModel>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeWordFormModel>;
      })
    );
  }

  /**
   * Create a new word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsPost$Json(params?: {
    'api-version'?: string;

    /**
     * CreateWordFormRequest
     */
    body?: CreateWordFormRequest
  }): Observable<ChangeWordFormModel> {

    return this.apiWordformsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeWordFormModel>) => r.body as ChangeWordFormModel)
    );
  }

  /**
   * Path part for operation apiWordformsDelete
   */
  static readonly ApiWordformsDeletePath = '/api/Wordforms';

  /**
   * Delete multiple wordforms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsDelete$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * WordForm IDs
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsDeletePath, 'delete');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete multiple wordforms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsDelete$Plain(params?: {
    'api-version'?: string;

    /**
     * WordForm IDs
     */
    body?: Array<string>
  }): Observable<boolean> {

    return this.apiWordformsDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete multiple wordforms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsDelete$Json$Response(params?: {
    'api-version'?: string;

    /**
     * WordForm IDs
     */
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsDeletePath, 'delete');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete multiple wordforms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsDelete$Json(params?: {
    'api-version'?: string;

    /**
     * WordForm IDs
     */
    body?: Array<string>
  }): Observable<boolean> {

    return this.apiWordformsDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiWordformsWordFormIdPut
   */
  static readonly ApiWordformsWordFormIdPutPath = '/api/Wordforms/{wordFormId}';

  /**
   * Update a specific word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsWordFormIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsWordFormIdPut$Plain$Response(params: {

    /**
     * WordForm ID
     */
    wordFormId: string;
    'api-version'?: string;

    /**
     * UpdateWordFormRequest
     */
    body?: UpdateWordFormRequest
  }): Observable<StrictHttpResponse<ChangeWordFormModel>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsWordFormIdPutPath, 'put');
    if (params) {
      rb.path('wordFormId', params.wordFormId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeWordFormModel>;
      })
    );
  }

  /**
   * Update a specific word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsWordFormIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsWordFormIdPut$Plain(params: {

    /**
     * WordForm ID
     */
    wordFormId: string;
    'api-version'?: string;

    /**
     * UpdateWordFormRequest
     */
    body?: UpdateWordFormRequest
  }): Observable<ChangeWordFormModel> {

    return this.apiWordformsWordFormIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeWordFormModel>) => r.body as ChangeWordFormModel)
    );
  }

  /**
   * Update a specific word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsWordFormIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsWordFormIdPut$Json$Response(params: {

    /**
     * WordForm ID
     */
    wordFormId: string;
    'api-version'?: string;

    /**
     * UpdateWordFormRequest
     */
    body?: UpdateWordFormRequest
  }): Observable<StrictHttpResponse<ChangeWordFormModel>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsWordFormIdPutPath, 'put');
    if (params) {
      rb.path('wordFormId', params.wordFormId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeWordFormModel>;
      })
    );
  }

  /**
   * Update a specific word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsWordFormIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWordformsWordFormIdPut$Json(params: {

    /**
     * WordForm ID
     */
    wordFormId: string;
    'api-version'?: string;

    /**
     * UpdateWordFormRequest
     */
    body?: UpdateWordFormRequest
  }): Observable<ChangeWordFormModel> {

    return this.apiWordformsWordFormIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeWordFormModel>) => r.body as ChangeWordFormModel)
    );
  }

  /**
   * Path part for operation apiWordformsUploadPost
   */
  static readonly ApiWordformsUploadPostPath = '/api/Wordforms/Upload';

  /**
   * Upload word forms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsUploadPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiWordformsUploadPost$Plain$Response(params?: {
    'api-version'?: string;
    body?: {
'wordformsBaseId': string;
'file': Blob;
}
  }): Observable<StrictHttpResponse<Array<WordFormModel>>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WordFormModel>>;
      })
    );
  }

  /**
   * Upload word forms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsUploadPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiWordformsUploadPost$Plain(params?: {
    'api-version'?: string;
    body?: {
'wordformsBaseId': string;
'file': Blob;
}
  }): Observable<Array<WordFormModel>> {

    return this.apiWordformsUploadPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WordFormModel>>) => r.body as Array<WordFormModel>)
    );
  }

  /**
   * Upload word forms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiWordformsUploadPost$Json$Response(params?: {
    'api-version'?: string;
    body?: {
'wordformsBaseId': string;
'file': Blob;
}
  }): Observable<StrictHttpResponse<Array<WordFormModel>>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsUploadPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WordFormModel>>;
      })
    );
  }

  /**
   * Upload word forms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiWordformsUploadPost$Json(params?: {
    'api-version'?: string;
    body?: {
'wordformsBaseId': string;
'file': Blob;
}
  }): Observable<Array<WordFormModel>> {

    return this.apiWordformsUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WordFormModel>>) => r.body as Array<WordFormModel>)
    );
  }

  /**
   * Path part for operation apiWordformsIdDelete
   */
  static readonly ApiWordformsIdDeletePath = '/api/Wordforms/{id}';

  /**
   * Delete a specific word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsIdDelete$Plain$Response(params: {

    /**
     * WordForm ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsIdDelete$Plain(params: {

    /**
     * WordForm ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiWordformsIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Delete a specific word form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWordformsIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsIdDelete$Json$Response(params: {

    /**
     * WordForm ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, WordformsService.ApiWordformsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete a specific word form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWordformsIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWordformsIdDelete$Json(params: {

    /**
     * WordForm ID
     */
    id: string;
    'api-version'?: string;
  }): Observable<boolean> {

    return this.apiWordformsIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
