<app-ui-modal [size]="'large'">
  <div class="modal-inner-container">
    <div class="logo">
      <img [src]="logoPath" alt="logo" />
      <app-single-select
        *ngIf="cloudRegions.length"
        [ngModel]="model.cloudRegion"
        (ngModelChange)="valueChanged('cloudRegion', $event)"
      >
        <app-option *ngFor="let region of cloudRegions" [value]="region">{{ cloudRegionsTitles[region] }}</app-option>
      </app-single-select>
    </div>
    <form class="form">
      <div class="form-group" *ngFor="let field of fields; let last = last" [class.has-error]="errorMessage | async">
        <div class="label">{{ field.label }}</div>
        <div class="input-group">
          <input
            class="input"
            [placeholder]="field.placeholder"
            [type]="field.type"
            [ngModel]="model[field.fieldName]"
            (ngModelChange)="valueChanged(field.fieldName, $event)"
            [autocomplete]="field.autocomplete"
            [name]="platformId + '_' + field.fieldName"
            [id]="platformId + '_' + field.fieldName"
          />
          <app-ui-icon class="suffix-icon" [icon]="field.icon"></app-ui-icon>
        </div>
        <div *ngIf="last" class="error-message">{{ errorMessage | async }}</div>
      </div>
      <app-button
        (clickButton)="submitBtnClick()"
        [disabled]="!modelIsValid"
        size="lg"
        class="submit-btn"
        type="submit"
        theme="primary"
        [loading]="loading | async"
      >
        {{ submitBtnLabel }}
      </app-button>
    </form>
    <div class="information">
      <div [innerHTML]="information | safe: 'html'"></div>
    </div>
  </div>
</app-ui-modal>
