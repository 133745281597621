<virtual-scroller
  *ngIf="list.length; else elseTemplate"
  #virtualScroll
  [items]="list"
  [bufferAmount]="30"
  [class.rtl]="rtl"
>
  <div *ngFor="let item of virtualScroll.viewPortItems" @insertTrigger class="chip-wrapper">
    <app-chip
      [selected]="selectedChipsMap[item]"
      (click)="toggleSelect(item)"
      (iconClicked)="deleteItems([item])"
      [text]="item"
      [rtl]="rtl"
    ></app-chip>
  </div>
</virtual-scroller>
<app-chips-input
  [disabled]="disabled"
  [placeholder]="inputPlaceholder"
  [selectedChips]="selectedChips"
  [rtl]="rtl"
  (add)="addNewItems($event)"
  (update)="updateSelectedItem($event)"
  (deleteSelectedChip)="deleteSelectedItems()"
></app-chips-input>

<ng-template #elseTemplate>
  <div class="placeholder-container">
    <ng-content select="[list-placeholder]"></ng-content>
  </div>
</ng-template>
