import { AccountRole } from '../role.model';

export interface AuthClaims {
  name: string;
  preferred_username: string;
  // TODO: нужен список реально доступных ролей
  role: AccountRole | AccountRole[];
  sub: string;
  tid: string;
}

export class Account {
  public readonly id: string;
  public readonly name: string;
  public readonly tenantId: string;
  public readonly roles: AccountRole[];
  public readonly email: string;
  public readonly organizationRoles: AccountRole[];
  private readonly systemRoles: AccountRole[] = ['anonymous', 'registered'];

  constructor(claims: AuthClaims) {
    this.id = claims.sub;
    this.name = claims.preferred_username;
    this.email = claims.name;
    this.tenantId = claims.tid;

    this.roles = this.#arrayifyRoles(claims.role);
    this.organizationRoles = this.roles.filter((role) => !this.systemRoles.includes(role));
  }

  #arrayifyRoles(role: AccountRole | AccountRole[]): AccountRole[] {
    if (!role) {
      return [];
    }
    return (typeof role === 'string' ? [role] : role).map((r) => r.toLowerCase() as AccountRole);
  }

  public hasRole(role: AccountRole | AccountRole[]): boolean {
    const currentRoles = this.#arrayifyRoles(role);
    return currentRoles.some((r: AccountRole) => this.roles.includes(r));
  }
}
