import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from '@ag-grid-enterprise/core';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';
import { AppModule } from './app/app.module';
import { prepareStore } from '@store/plugins/async-storage-plugin';
import { environment } from './environments/environment';
import { AppConfig } from './app/config';

function Run(): void {
  Promise.all([loadConfig(), prepareStore()]).then(([environmentConfig]) => {
    console.log('✎: [line 14][main.ts] environmentConfig: ', environmentConfig);
    Object.keys(environmentConfig).forEach((key) => {
      environment[key] = environmentConfig[key];
    });
    if (environment.productionMode) {
      enableProdMode();
    }
    if (environment.enableSentry) {
      initSentry();
    }
    registerAgGrid();

    platformBrowserDynamic()
      .bootstrapModule(AppModule, {})
      .catch((err) => console.error(err));
  });
}
Run();

async function loadConfig(): Promise<Record<string, AppConfig>> {
  const res = await fetch(`assets/config/${environment.configFile}`);
  return await res.json();
}

function initSentry(): void {
  Sentry.init({
    dsn: environment.sentryDsn,
    maxBreadcrumbs: 30,
    autoSessionTracking: true,
    integrations: [new BrowserTracing(), new Replay()],
    release: environment.releaseName,

    tracesSampleRate: environment.sentryTracesRate,
    replaysOnErrorSampleRate: environment.sentryReplaysErrorsRate,
    replaysSessionSampleRate: environment.sentryReplaysSessionRate,
  });
}

function registerAgGrid(): void {
  LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}
