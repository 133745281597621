import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for truncate big text by provided count of
 * symbols + ellipsis symbol as variable
 *
 * @example <caption>Truncate text by 15 symbols with ___ at the end</caption>
 * ````
 * <span>{{ longTextVariable | truncate:15:'___' }}</span>
 * ````
 */
@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(value: string, count: number, ellipsisSymbol: string = '...') {
    const truncateLength = count + ellipsisSymbol.length;

    if (!value?.length || value.length < truncateLength) {
      return value;
    }

    return `${value.slice(0, count)}${ellipsisSymbol}`;
  }
}
