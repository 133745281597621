import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, HostBinding } from '@angular/core';
import { dateTimeComparator } from '@shared/tools';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent {
  @Input()
  owner: string;

  @HostBinding('class.auto-height')
  @Input()
  autoHeight: boolean;

  @Input()
  createdOn: string;

  @Input()
  modifiedOn: string;

  @Input()
  message: string;

  @Input()
  enableActions: boolean = true;

  @Input()
  shortView: boolean = false;

  private _editable: boolean;
  @Input()
  set editable(status: boolean) {
    this._editable = status;
    if (!status) {
      this.stopEditing();
    }
  }

  get editable(): boolean {
    return this._editable;
  }

  public editableText: string = null;

  @Output()
  public readonly edited = new EventEmitter<string>();

  @Output()
  public readonly deleted = new EventEmitter<void>();

  @Output()
  public readonly payloadClicked = new EventEmitter<void>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  get modified(): boolean {
    return this.modifiedOn && dateTimeComparator(this.createdOn, this.modifiedOn) !== 0;
  }

  constructor() {}

  public updateMessage(): void {
    this.edited.emit(this.message);
  }

  public deleteComment(): void {
    this.deleted.emit();
  }

  public editComment(): void {
    this.editableText = this.message;
  }

  public saveComment(event?: MouseEvent): void {
    this.edited.emit(this.editableText);
    this.message = this.editableText;
    this.modifiedOn = new Date().toISOString();
    this.stopEditing(event);
  }

  public stopEditing(event?: MouseEvent): void {
    this.editableText = null;
    if (event) {
      event.stopPropagation();
    }
  }
}
