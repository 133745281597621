import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, Selector, State, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import {
  CreateGlossary,
  DeleteGlossary,
  LoadGlossaryList,
  LoadGlossaryListFail,
  LoadGlossaryListSuccess,
  RenameGlossary,
  SelectGlossary,
} from '@store/glossary-list-store/glossary-list.actions';
import { deepClone } from '@shared/tools';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { QASettingsState } from '@store/qa-settings-store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { CreateTermbaseRequest, TermbaseInfo, TermbasesService } from '@generated/api';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';
import { Observable } from 'rxjs';

export class QASettingsGlossaryStateModel {
  [qaSettingsId: string]: {
    glossaries: TermbaseInfo[];

    /**
     * Id of the selected Glossary
     */
    selected: string | undefined;
  };
}

@AsyncStorage
@StateRepository()
@State<QASettingsGlossaryStateModel>({
  name: 'glossaryList',
  defaults: {},
})
@Injectable()
export class GlossaryListState {
  constructor(private termbasesService: TermbasesService, private store: Store) {}

  @Selector([GlossaryListState, QASettingsState.qaSettingsId])
  public static glossaries(state: QASettingsGlossaryStateModel, settingsId): TermbaseInfo[] {
    if (!state) {
      return [];
    }
    return deepClone(state[settingsId]?.glossaries || []).sort(
      (a, b) => +new Date(a.modifiedOn) - +new Date(b.modifiedOn)
    );
  }

  @Selector([GlossaryListState, QASettingsState.qaSettingsId])
  public static selected(state: QASettingsGlossaryStateModel, settingsId): string | undefined {
    if (!state) {
      return null;
    }
    return state[settingsId]?.selected;
  }

  @Action(LoadGlossaryList)
  public loadGlossaryList(
    ctx: StateContext<QASettingsGlossaryStateModel>,
    { qaSettingsId }: LoadGlossaryList
  ): Observable<void> {
    return this.termbasesService.apiTermbasesGet$Json({ qaSettingsId }).pipe(
      tap((glossaryList: TermbaseInfo[]) => {
        const state = ctx.getState() || {};
        let selected: string = state[qaSettingsId]?.selected;
        if (!selected || !glossaryList?.some((g) => g.id === selected)) {
          selected = glossaryList?.[0]?.id;
        }
        ctx.patchState({
          [qaSettingsId]: {
            glossaries: glossaryList || [],
            selected: selected || undefined,
          },
        });
      }),
      mergeMap(() => ctx.dispatch(new LoadGlossaryListSuccess())),
      catchError((err) => ctx.dispatch(new LoadGlossaryListFail(err)))
    );
  }

  @Action(RenameGlossary, { cancelUncompleted: true })
  public renameGlossary(
    ctx: StateContext<QASettingsGlossaryStateModel>,
    { glossaryId, name }: RenameGlossary
  ): Observable<boolean> {
    const qaSettingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
    ctx.setState(
      patch({
        [qaSettingsId]: patch({
          glossaries: updateItem<TermbaseInfo>((g) => g.id === glossaryId, patch({ name })),
        }),
      })
    );

    return this.termbasesService
      .apiTermbasesIdPatch$Json({
        id: glossaryId,
        body: [
          {
            op: 'replace',
            path: '/name',
            value: name,
          },
        ],
      })
      .pipe(
        tap(() => {
          ctx.setState(
            patch({
              [qaSettingsId]: patch({
                glossaries: updateItem<TermbaseInfo>(
                  (g) => g.id === glossaryId,
                  patch({
                    name,
                  })
                ),
              }),
            })
          );
        })
      );
  }

  @Action(DeleteGlossary, { cancelUncompleted: true })
  public deleteGlossary(
    ctx: StateContext<QASettingsGlossaryStateModel>,
    { glossaryId }: DeleteGlossary
  ): Observable<boolean> {
    const qaSettingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
    const state = ctx.getState() || {};
    const selected = state[qaSettingsId]?.selected;
    const glossaries = state[qaSettingsId]?.glossaries || [];
    const itemToRemoveIndex = glossaries.findIndex((g) => g.id === glossaryId);
    const itemToRemove = glossaries[itemToRemoveIndex];

    if (selected === itemToRemove.id) {
      ctx.setState(
        patch({
          [qaSettingsId]: patch({
            selected: glossaries[itemToRemoveIndex + 1]?.id || glossaries[itemToRemoveIndex - 1]?.id || undefined,
          }),
        })
      );
    }
    ctx.setState(
      patch({
        [qaSettingsId]: patch({
          glossaries: removeItem<TermbaseInfo>((g: TermbaseInfo) => g.id === glossaryId),
        }),
      })
    );

    return this.termbasesService.apiTermbasesIdDelete$Json({ id: glossaryId });
  }

  @Action(CreateGlossary, { cancelUncompleted: true })
  public createGlossary(
    ctx: StateContext<QASettingsGlossaryStateModel>,
    { glossary }: { glossary: TermbaseInfo }
  ): Observable<TermbaseInfo> {
    const qaSettingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
    ctx.setState(
      patch({
        [qaSettingsId]: patch({
          glossaries: append([glossary]),
          selected: glossary.id,
        }),
      })
    );

    return this.termbasesService.apiTermbasesPost$Json({ body: glossary as CreateTermbaseRequest }).pipe(
      tap((newGlossary: TermbaseInfo) => {
        ctx.setState(
          patch({
            [qaSettingsId]: patch({
              glossaries: updateItem((g: TermbaseInfo) => g.id === newGlossary.id, newGlossary),
            }),
          })
        );
      })
    );
  }

  @Action(SelectGlossary)
  public selectGlossary(ctx: StateContext<QASettingsGlossaryStateModel>, { glossaryId }): void {
    const qaSettingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
    ctx.setState(
      patch({
        [qaSettingsId]: patch({
          selected: glossaryId,
        }),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<QASettingsGlossaryStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.qaSettingsId];
    ctx.setState(state);
  }
}
