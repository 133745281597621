import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LOG_APPENDER_CONFIG_TOKEN, LOG_APPENDER_TOKEN, LogAppenderConfig } from '../../log-appender';
import { ConsoleService } from './console.service';

@NgModule({
  imports: [CommonModule],
})
export class ConsoleModule {
  public static forRoot(configFactory: () => LogAppenderConfig): ModuleWithProviders<ConsoleModule> {
    return {
      ngModule: ConsoleModule,
      providers: [
        {
          provide: LOG_APPENDER_TOKEN,
          useClass: ConsoleService,
          multi: true,
        },
        {
          provide: LOG_APPENDER_CONFIG_TOKEN,
          useFactory: configFactory,
          multi: true,
        },
      ],
    };
  }
}
