import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Organization } from '@generated/api';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  CreateOrganization,
  JoinOrganization,
  OrganizationCreated,
  SelectOrganizationById,
} from '@store/organizations-store/organizations.actions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-organization-creator',
  templateUrl: './organization-creator.component.html',
  styleUrls: ['./organization-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationCreatorComponent implements OnInit, OnDestroy {
  public readonly organizationNameControl = new FormControl('');
  public loading: boolean;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<OrganizationCreatorComponent>,
    private readonly actions: Actions
  ) {}

  ngOnInit(): void {}

  public cancel(): void {
    this.dialogRef.close();
  }

  public createOrganization(): void {
    this.loading = true;
    this.store.dispatch(new CreateOrganization(this.organizationNameControl.value as string));
    this.waitOrganizationCreated();
  }

  private waitOrganizationCreated(): void {
    // TODO: Нужен обработчик внештатных ситуаций (и макеты).
    this.actions
      .pipe(ofActionSuccessful(OrganizationCreated), takeUntil(this.destroyed$))
      .subscribe((org: Organization) => {
        this.waitTillJoinOrganization(org);
        this.store.dispatch(new JoinOrganization(org.id));
      });
  }

  private waitTillJoinOrganization(org: Organization): void {
    this.actions.pipe(ofActionSuccessful(SelectOrganizationById), takeUntil(this.destroyed$)).subscribe(() => {
      this.dialogRef.close(org);
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
