import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportGridContext } from '@report/models';
import { ICellRendererParams, ICellRendererAngularComp } from 'src/types/ag-grid';
import { QualityIssue, RenderItem, TagView } from '@shared/models';

interface CellRendererParams extends ICellRendererParams<QualityIssue> {
  multiline?: boolean;
  context?: ReportGridContext;
  rightToLeftGetter: (params: CellRendererParams) => boolean;
}

@Component({
  selector: 'app-grid-segment-cell-renderer',
  template: `
    <app-segment-renderer
      [renderItems]="renderItems"
      [showInvisible]="showInvisibles"
      [multiline]="multiline"
      [widthChanged$]="widthChanged$"
      [tagView]="tagView"
      [rtl]="rtl"
    ></app-segment-renderer>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridSegmentCellRendererComponent implements ICellRendererAngularComp {
  static rendererName = 'segmentRenderer';

  public multiline = false;
  public showInvisibles = false;
  public renderItems: RenderItem[] = [];
  public tagView: TagView = TagView.Medium;
  public rtl = false;
  public widthChanged$: Observable<boolean>;

  agInit(params: CellRendererParams): void {
    this.tagView = params.context?.tagView ?? TagView.Medium;
    this.widthChanged$ = params.context?.widthChanged$;
    this.showInvisibles = params.context?.showInvisibles ?? false;
    this.multiline = params.context?.multiline || false;
    this.renderItems = params.value || [];
    this.rtl = params.rightToLeftGetter(params);
  }

  public refresh(): boolean {
    return false;
  }
}
