import { ExportFilterModel, ExportFilterType } from '@generated/api';
import { FilterState } from '@store/report-grids-store/report-grids-state.model';
import { ReportType, ReportTypeColumnOptions } from '../configs';

/**
 * Converts export filter request model from filter state
 */
export function mapFromFilterState(reportType: ReportType, state: FilterState): ExportFilterModel {
  const col = ReportTypeColumnOptions[reportType]?.[state.colId];
  if (!col) {
    return null;
  }

  return {
    field: col.field,
    type: ExportFilterType.Equal,
    value: col.valueGetter ? col.valueGetter(state.value) : state.value,
  };
}
