import { ReportTab } from '@shared/models';

enum ActionTypes {
  SET_REPORT_TAB = '[Report View] Set report tab',
  SET_GROUPS_COLLAPSED_STATE = '[Report View] Set groups collapsed state',
  TOGGLE_COLLAPSED_ALL_GROUPS = '[Report View] Toggle collapsed all groups',
  SET_SPELLING_POPUP_SHOWN = '[Report View] Set Spelling Popup Shown',
  SET_REPORT_DEFAULT_REPORT_VIEW = '[Report View] Set Report Default ReportView',
  CHANGE_SELECTED_COMMENT_THREAD_ID = '[Report View] Change Selected Comment Thread Id',
  SET_GROUPS_ORDER_STATE = '[Report View] Set Groups Order State',
  ADD_GROUPS_ORDER_STATE = '[Report View] Add Groups Order State',
  SET_SORTING_GROUPS = '[Report View] Set Sorting Groups',
  FOCUS_CURRENT_ROW = '[Report View] Focus Current Row',
}

export class SetReportTab {
  static readonly type = ActionTypes.SET_REPORT_TAB;
  constructor(public payload: ReportTab) {}
}

export class SetGroupsCollapsedState {
  static readonly type = ActionTypes.SET_GROUPS_COLLAPSED_STATE;
  constructor(public payload: Record<string, boolean>) {}
}

export class ToggleCollapsedAllGroups {
  static readonly type = ActionTypes.TOGGLE_COLLAPSED_ALL_GROUPS;
  constructor(public newState: boolean) {}
}

export class SetSpellingPopupShown {
  static readonly type = ActionTypes.SET_SPELLING_POPUP_SHOWN;
  constructor(public payload: boolean) {}
}

export class SetReportDefaultReportView {
  static readonly type = ActionTypes.SET_REPORT_DEFAULT_REPORT_VIEW;
  constructor(public projectId: string) {}
}

export class SetGroupsOrderState {
  static readonly type = ActionTypes.SET_GROUPS_ORDER_STATE;
  constructor(public reportTab: ReportTab, public order: Record<string, number>) {}
}

export class AddGroupsOrderState {
  static readonly type = ActionTypes.ADD_GROUPS_ORDER_STATE;
  constructor(public payload: string[]) {}
}

export class ChangeSelectedCommentThreadId {
  static readonly type = ActionTypes.CHANGE_SELECTED_COMMENT_THREAD_ID;
  constructor(public threadId: string) {}
}

export class FocusCurrentRow {
  static readonly type = ActionTypes.FOCUS_CURRENT_ROW;
}
