import { State, Selector, Action, StateContext, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';

import type { Account, AccountRole } from '@shared/models';
import { Auth, SetAccount, Logout } from './auth.actions';
import { AuthService } from 'src/app/core/modules/auth';

interface AccountStateModel {
  account: Account | null;
}

const initialState: AccountStateModel = {
  account: null,
};

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    ...initialState,
  },
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService) {}

  @Selector()
  public static account(state: AccountStateModel): Account | null {
    return state?.account;
  }

  public static hasRole(role: AccountRole | AccountRole[]): (account: Account) => boolean {
    return createSelector([AuthState.account], (account: Account | null) => {
      if (!account) {
        return false;
      }
      return account.hasRole(role);
    });
  }

  @Action(Auth)
  public auth(): void {
    this.authService.login();
  }

  @Action(SetAccount)
  public setAccount(ctx: StateContext<AccountStateModel>, { account }: SetAccount): void {
    ctx.setState(
      patch({
        account,
      })
    );
  }

  @Action(Logout)
  public logout(ctx: StateContext<AccountStateModel>): void {
    ctx.setState({ ...initialState });
    this.authService.logout();
  }
}
