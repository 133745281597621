<span
  [draggable]="draggable"
  [appTooltipTriggerFor]="tooltip"
  [appTooltipDisabled]="!text"
  appTooltipPlacement="bottom"
  class="vr-tag"
  [ngClass]="[viewClassName, positionClassName, colorClassName, isAutofixable ? 'vr-autofix' : '']"
  [class.selected]="selected"
  [class.drag]="drag"
  (dragstart)="dragstart($event)"
  (dragend)="dragend()"
>
  <span *ngIf="tagNumber" class="number">{{ tagNumber }}</span>

  <span *ngIf="tagView !== TagView.Long" class="tag-text">{{ displayedText }}</span>

  <span *ngIf="tagView === TagView.Long" class="tag-text" [innerHtml]="displayedText | safe: 'html'"> </span>
</span>
<tooltip #tooltip="tooltip"> {{ text }} </tooltip>

<div *ngIf="draggable" #dragPreview class="drag-preview">
  <span
    draggable="true"
    class="vr-tag selected"
    [ngClass]="[viewClassName, positionClassName, colorClassName, isAutofixable ? 'vr-autofix' : '']"
  >
    <span *ngIf="tagView !== TagView.Long" class="tag-text">{{ displayedText }}</span>
    <span *ngIf="tagView === TagView.Long" class="tag-text" [innerHtml]="displayedText | safe: 'html'"> </span>
  </span>
</div>
