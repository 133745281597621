import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LoadSpellCheckSupportedLanguages } from '@store/spell-check-store/spell-check.actions';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { SpellCheckService } from '@generated/api';

export interface SpellCheckStateModel {
  supportedLanguages: string[];
}

@AsyncStorage
@StateRepository()
@State<SpellCheckStateModel>({
  name: 'spellCheck',
  defaults: {
    supportedLanguages: [],
  },
})
@Injectable()
export class SpellCheckState {
  constructor(private spellCheckService: SpellCheckService) {}

  @Selector()
  public static supportedLanguages(state: SpellCheckStateModel) {
    return state.supportedLanguages;
  }

  @Action(LoadSpellCheckSupportedLanguages)
  public loadSupportedLanguages(ctx: StateContext<SpellCheckStateModel>) {
    return this.spellCheckService.apiReportsSpellCheckSupportedLanguagesGet$Json().pipe(
      tap((languagesCode) => {
        ctx.patchState({
          supportedLanguages: languagesCode,
        });
      })
    );
  }
}
