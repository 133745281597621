import { Injectable } from '@angular/core';
import { Dexie } from 'dexie';
import { forkJoin, from, Observable, switchMap } from 'rxjs';

@Injectable()
export class SystemDAO {
  /*
   * Clear all databases from indexedDB
   */
  public clearStore(): Observable<any> {
    return from(Dexie.getDatabaseNames()).pipe(
      switchMap((dbNames: string[]) => forkJoin(dbNames.map((dbName: string) => this.dropDatabase(dbName))))
    );
  }

  public dropDatabase(dbName: string): Observable<any> {
    return from(new Dexie(dbName).open()).pipe(switchMap((db: Dexie) => db.delete()));
  }
}
