import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@shared/services';
import { filter, mergeMap, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  private pendingRequests: Record<string, ReplaySubject<HttpEvent<any>>> = {};

  constructor(private appService: AppService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      // TODO: Для методов на мутацию, нужна более сложная логика
      // учитывающая последовательность обновлений, а так-же склеивающая
      // множественные мутации над однотипными сущностями.
      // На паузе, коррелирует с механизмом offline работы и crdt.
      return next.handle(req);
    }

    if (!this.appService.online) {
      return this.deferredGetRequest(req, next);
    }

    return next.handle(req);
  }

  private deferredGetRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.pendingRequests[req.urlWithParams]) {
      return this.pendingRequests[req.urlWithParams];
    }

    const broadcast = new ReplaySubject<HttpEvent<any>>(1);
    this.pendingRequests[req.urlWithParams] = broadcast;

    return this.appService.online$.asObservable().pipe(
      filter((online) => online),
      mergeMap(() =>
        next.handle(req).pipe(
          tap((res) => {
            broadcast.next(res);
            if (res.type !== HttpEventType.ResponseHeader) {
              return;
            }

            broadcast.complete();
            broadcast.unsubscribe();
            delete this.pendingRequests[req.urlWithParams];
          })
        )
      )
    );
  }
}
