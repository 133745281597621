import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaseCellEditorComponent } from '../../../common';
import { UnitModel } from '@shared/components/measurement-units';
import { takeUntil } from 'rxjs/operators';
import { PopoverComponent } from '@shared/components/popover';
import { GridHelperService } from '@shared/services/grid-helper.service';

@Component({
  selector: 'app-measurement-cell-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './measurement-cell-editor.component.html',
  styleUrls: ['./measurement-cell-editor.component.scss'],
})
export class MeasurementCellEditorComponent extends BaseCellEditorComponent<UnitModel> implements AfterViewInit {
  control = new FormControl(null, [Validators.required, this.sameMeasurementValidator()]);
  errorDescriptions = {
    required: 'Cannot be empty',
    sameExist: 'There is already such a measure in the table. Please select another one',
  };

  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
  @ViewChild('validationPopover', { static: true, read: PopoverComponent })
  validationPopover: PopoverComponent;

  errorMsg: string;

  constructor(protected readonly gridHelperService: GridHelperService) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  public initSubscriptions(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.errorMsg = this.control.invalid ? this.getError() : undefined;

      if (this.errorMsg) {
        this.validationPopover?.open();
      } else {
        this.validationPopover?.close();
      }
    });
  }

  public isCancelAfterEnd(): boolean {
    return this.control.invalid;
  }

  public isPopup(): boolean {
    return false;
  }

  private sameMeasurementValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const tableData = this.getTableDataExceptCurrentRow();

      if (!tableData.length || !control.value) {
        return null;
      }

      const hasSameName = tableData.some(
        ({ id, description }: UnitModel) => control.value === description && id !== this.rowData.id
      );
      return hasSameName ? { sameExist: true } : null;
    };
  }

  public onEnter(): void {
    this.gridHelperService.switchToNextEditor(this.rowNode, this.column.getColId());
  }
}
