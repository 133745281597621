import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';
import { SpinnerSize } from '@shared/components/loader';
import { IconColor } from '../icon';
import { BaseButtonComponent } from './base-button.component';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonTheme = 'outlined' | 'primary' | 'accent' | 'warn' | 'primary-text';
export type ButtonType = 'button' | 'submit' | 'reset';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseButtonComponent {
  @Input() prefixIcon: IconPayload;
  @Input() prefixIconColor: IconColor;
  @Input() suffixIcon: IconPayload;
  @Input() suffixIconColor: IconColor;
  @Input() size: ButtonSize = 'md';
  @Input() theme: ButtonTheme = 'outlined';
  @Input() type: ButtonType = 'button';
  @Input() loading = false;
  spinnerSize = SpinnerSize;
}
