import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilterIgnoreComponent } from './filter-ignore/filter-ignore.component';
import { FilterCommentComponent } from './filter-comment/filter-comment.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { PipesModule } from '@shared/pipes';
import { ActionIconModule } from '@shared/components/action-icon';
import { TooltipModule } from '@shared/components/tooltip';
import { SearchInputModule } from '@shared/components/search-input';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    PipesModule,
    ActionIconModule,
    TooltipModule,
    SearchInputModule,
    ButtonModule,
  ],
  declarations: [FilterIgnoreComponent, FilterCommentComponent, ListFilterComponent],
  exports: [FilterIgnoreComponent, FilterCommentComponent, ListFilterComponent],
})
export class GridFiltersModule {}
