import { Inject, Injectable, Optional } from '@angular/core';
import { LOG_APPENDER_TOKEN, LogAppender } from './log-appender';
import { LogLevel } from './log-level.enum';

@Injectable()
export class LoggerService {
  private readonly enabledAppenders: LogAppender[] = [];

  constructor(@Optional() @Inject(LOG_APPENDER_TOKEN) appenders: LogAppender[]) {
    if (!appenders?.length) {
      return;
    }
    this.enabledAppenders = appenders.filter((i) => !i.config.disabled);
  }

  public debug(message: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.log(LogLevel.DEBUG, message, ...(optionalParams as any));
  }

  public info(message: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.log(LogLevel.INFO, message, ...optionalParams);
  }

  public warn(message: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.log(LogLevel.WARN, message, ...optionalParams);
  }

  public error(message: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.log(LogLevel.ERROR, message, ...optionalParams);
  }

  public fatal(message: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.log(LogLevel.FATAL, message, ...optionalParams);
  }

  private log(level: LogLevel, message: any, ...optionalParams: any[]): void {
    this.enabledAppenders
      .filter((appender) => !this.checkLevel(level, appender.config.level))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .forEach((appender) => appender.log(level, message, ...optionalParams));
  }

  private checkLevel(eventLevel: LogLevel, appenderPermittedLevel: LogLevel): boolean {
    switch (appenderPermittedLevel) {
      case LogLevel.DEBUG: {
        return (
          eventLevel === LogLevel.DEBUG ||
          eventLevel === LogLevel.INFO ||
          eventLevel === LogLevel.WARN ||
          eventLevel === LogLevel.ERROR ||
          eventLevel === LogLevel.FATAL
        );
      }
      case LogLevel.INFO: {
        return (
          eventLevel === LogLevel.INFO ||
          eventLevel === LogLevel.WARN ||
          eventLevel === LogLevel.ERROR ||
          eventLevel === LogLevel.FATAL
        );
      }
      case LogLevel.WARN: {
        return eventLevel === LogLevel.WARN || eventLevel === LogLevel.ERROR || eventLevel === LogLevel.FATAL;
      }
      case LogLevel.ERROR: {
        return eventLevel === LogLevel.ERROR || eventLevel === LogLevel.FATAL;
      }
      case LogLevel.FATAL: {
        return eventLevel === LogLevel.FATAL;
      }
    }
  }
}
