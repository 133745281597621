import { Injectable } from '@angular/core';
import { QaSettingsInfo, QaSettingsModel } from '@generated/api';

@Injectable({ providedIn: 'root' })
export class QASettingsMapper {
  public formatToQASettingsInfo({
    id,
    name,
    lastTouchedOn,
    modifiedOn,
    templateId,
    projectId,
    createdOn,
    checkSettingsHash,
  }: QaSettingsModel): QaSettingsInfo {
    return {
      id,
      name,
      lastTouchedOn,
      modifiedOn,
      templateId,
      projectId,
      createdOn,
      checkSettingsHash,
      isDefault: false,
    };
  }
}
