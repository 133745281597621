import {
  Overlay,
  OverlayKeyboardDispatcher,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategyOptions,
  OverlayOutsideClickDispatcher,
} from '@angular/cdk/overlay';
import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  NgZone,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DynamicOverlayContainer } from './dynamic-overlay-container.service';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT, Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DynamicOverlay extends Overlay {
  private readonly dynamicOverlayContainer: DynamicOverlayContainer;
  private renderer: Renderer2;

  constructor(
    scrollStrategies: ScrollStrategyOptions,
    overlayContainer: DynamicOverlayContainer,
    componentFactoryResolver: ComponentFactoryResolver,
    positionBuilder: OverlayPositionBuilder,
    keyboardDispatcher: OverlayKeyboardDispatcher,
    injector: Injector,
    ngZone: NgZone,
    @Inject(DOCUMENT) document: any,
    directionality: Directionality,
    rendererFactory: RendererFactory2,
    location: Location,
    outsideClickDispatcher: OverlayOutsideClickDispatcher
  ) {
    super(
      scrollStrategies,
      overlayContainer,
      componentFactoryResolver,
      positionBuilder,
      keyboardDispatcher,
      injector,
      ngZone,
      document,
      directionality,
      location,
      outsideClickDispatcher
    );
    this.renderer = rendererFactory.createRenderer(null, null);

    this.dynamicOverlayContainer = overlayContainer;
  }

  private setContainerElement(containerElement: HTMLElement): void {
    this.renderer.setStyle(containerElement, 'transform', 'translateZ(0)');
    this.dynamicOverlayContainer.setContainerElement(containerElement);
  }

  public createWithDefaultConfig(containerElement: HTMLElement): OverlayRef {
    this.setContainerElement(containerElement);
    return super.create({
      positionStrategy: this.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
      backdropClass: 'app-loader-backdrop',
    });
  }
}
