import { HttpErrorResponse } from '@angular/common/http';

export enum ErrorCode {
  RefreshTokenExpired = 4001,
  UnsupportedFormat = 4152,
  MigratorByVersionNotFound = 4110,
  ProfileInvalidStructure = 4120,
  InviteTokenExpired = 4600,
  NotFound = 4004,
}

type Data = any;

// TODO: VW-758 спроектировать реально необходимую модель ошибок
// оставить только те, которые используются на клиенте
export interface APIError {
  code: ErrorCode;
  title: null;
  stackTrace: null;
  message: string;
  data: Data;
  innerException: null;
  helpLink: null;
  source: null;
  hResult: number;
}

export interface ApiHttpError extends Omit<HttpErrorResponse, 'error'> {
  error?: APIError;
}
