import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderConfig } from '../models/loader-config.model';

/**
 * Loader component. It's a component for rendering content of loader-overlay.
 * WARNING: DO NOT USE THIS COMPONENT IN OTHER PARTS OF APPLICATION
 */
@Component({
  // selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  constructor(readonly data: LoaderConfig) {
    this.data = this.data || new LoaderConfig();
  }
}
