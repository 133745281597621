<div class="searching-input-container">
  <app-ui-search-input
    size="large"
    [formControl]="searchControl"
    [appDisableControl]="listIsEmpty || disabled"
  ></app-ui-search-input>
</div>
<div class="header-container">
  <div class="header-title">
    <div class="count" *ngIf="filteredList.length">{{ filteredList.length }}</div>
    <div class="sort" (click)="toggleSortState()">
      <div class="title">{{ headerTitle }}</div>
      <app-ui-icon *ngIf="sortStatus === sortingTypes.Acs" icon="sort-arrow-up"></app-ui-icon>
      <app-ui-icon *ngIf="sortStatus === sortingTypes.Desc" icon="sort-arrow-down"></app-ui-icon>
    </div>
  </div>
  <div class="header-actions">
    <app-ui-file-chooser
      [disabled]="disabled"
      (selectFiles)="import($event)"
      icon="upload"
      theme="outlined"
      size="sm"
      class="import-btn"
      [accepted]="['.txt']"
      appTooltip="Import"
    ></app-ui-file-chooser>
    <app-action-icon
      icon="download"
      theme="circle"
      size="xl"
      [disabled]="disabled || !filteredList.length"
      (clickButton)="export()"
      appTooltip="Export"
    ></app-action-icon>
    <app-action-icon
      [disabled]="disabled || !filteredList.length"
      (clickButton)="clearList()"
      icon="clear"
      theme="circle"
      size="xl"
      appTooltip="Clear List"
    ></app-action-icon>
  </div>
</div>
<app-chip-list
  [ngModel]="filteredList"
  [disabled]="disabled"
  [inputPlaceholder]="inputPlaceholder"
  (added)="addNewItems($event)"
  (updated)="updateItem($event)"
  (removed)="deleteItems($event)"
>
  <ng-container list-placeholder>
    <ng-container *ngIf="!searchControl.value">
      <div>{{ listPlaceholder }}</div>
      <app-ui-icon icon="arrow-down-ward" size="normal"></app-ui-icon>
    </ng-container>
    <app-empty-message
      *ngIf="searchControl.value"
      message="No matches"
      imageUrl="assets/images/empty-match.svg"
      imageWidth="100px"
      imageHeight="100px"
    >
    </app-empty-message>
  </ng-container>
</app-chip-list>
