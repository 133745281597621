export function propComparator<T>(field: keyof T, reverse = false): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const result: number = comparator(a[field], b[field]);
    return reverse ? -result : result;
  };
}

export function comparator<T>(a: T, b: T): number {
  if (a === b) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return stringComparator(a, b);
  } else {
    return a > b ? 1 : -1;
  }
}

export function stringComparator(a = '', b = '') {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export function dateTimeComparator(a: string | Date, b: string | Date): number {
  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();
  if (aTime === bTime) {
    return 0;
  } else if (aTime > bTime) {
    return 1;
  } else {
    return -1;
  }
}
/*
 * Function for comparing elements with start and end positions
 * When the start positions is equal, then end position is compared
 */
export function rangeComparator(a: { start: number; end: number }, b: { start: number; end: number }): number {
  if (a.start === b.start) {
    return comparator(a.end, b.end);
  }
  return comparator(a.start, b.start);
}
