import { InjectionToken } from '@angular/core';
import { TooltipProperties } from './interfaces';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionToken used to import the config (initOptions) object, provided from the outside
 */
export const TooltipPropertiesService = new InjectionToken<TooltipProperties>(
  'TooltipProperties'
);
