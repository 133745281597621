import { Injectable } from '@angular/core';
import { htmlEncode } from '@shared/tools';

@Injectable()
export class TextNormalizationService {
  private readonly nonPrintableReplacers: { [regexp: string]: { regex: string; text: string } } = {
    space: {
      regex: '\u0020',
      text: '<span class="special-character special-character-space">$&</span>',
    },
    nonBreakSpace: {
      regex: '\u00A0',
      text: '<span class="special-character special-character-non-break-space">$&</span>',
    },
    tab: {
      regex: '\t',
      text: '<span class="special-character special-character-tab">$&</span>',
    },
    crlf: {
      regex: '(\r\n)',
      text: '<span class="special-character special-character-new-line special-character-crlf">$&</span>',
    },
    carriageReturn: {
      regex: '\r(?!\n)',
      text: '<span class="special-character special-character-new-line special-character-carriage-return">$&</span>',
    },
    lineFeed: {
      regex: '(?<!\r)\n',
      text: '<span class="special-character special-character-new-line special-character-line-feed">$&</span>',
    },
  };

  public replaceNonPrintableCharacters(text: string): string {
    const screenedText = htmlEncode(text);
    return Object.values(this.nonPrintableReplacers).reduce(
      (acc, character) => acc.replace(new RegExp(character.regex, 'gm'), character.text),
      screenedText
    );
  }
}
