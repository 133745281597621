import { Directive, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DynamicOverlay } from '@shared/components/loader/mat-extensions/dynamic-overlay';
import { LoaderComponent } from '@shared/components/loader/loader/loader.component';
import { SpinnerSize } from './models/spinner-size.model';
import { LoaderConfig } from './models/loader-config.model';

/**
 * Directive, that conditionally creates Overlay over host element, with a spinner in the middle.
 */
@Directive({
  selector: '[appLoader]',
})
export class LoaderOverlayDirective implements OnInit {
  /**
   * Show loader overlay if condition is truthy
   */
  @Input('appLoader') set condition(value: boolean) {
    this.conditionInternal = !!value;
    this.showOrHideOverlay();
  }

  /**
   * Optional spinner size
   */
  @Input('appLoaderSize') set size(value: SpinnerSize) {
    this.loaderConfig.size = value;
    this.showOrHideOverlay();
  }

  /**
   * Optional loader text
   */
  @Input('appLoaderText') set text(value: string) {
    this.loaderConfig.text = value;
    this.showOrHideOverlay();
  }

  private overlayRef: OverlayRef;

  private conditionInternal = false;

  private readonly loaderConfig = new LoaderConfig();

  constructor(
    private readonly host: ElementRef,
    private readonly dynamicOverlay: DynamicOverlay,
    private readonly parentInjector: Injector
  ) {}

  ngOnInit() {
    this.initOverlay();
    this.showOrHideOverlay();
  }

  initOverlay() {
    this.overlayRef = this.dynamicOverlay.createWithDefaultConfig(
      this.host.nativeElement
    );
  }

  showOrHideOverlay() {
    this.overlayRef?.detach();

    if (this.conditionInternal) {
      const injector = this.getInjector(this.parentInjector);
      const loaderPortal = new ComponentPortal(LoaderComponent, null, injector);
      this.overlayRef?.attach(loaderPortal);
    } else {
      this.overlayRef?.detach();
    }
  }

  getInjector(parentInjector: Injector): PortalInjector {
    const tokens = new WeakMap();
    tokens.set(LoaderConfig, this.loaderConfig);
    return new PortalInjector(parentInjector, tokens);
  }
}
