import { Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State, Action, Selector, Store, NgxsOnInit } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import {
  SetCurrentProject,
  RenameProject,
  TouchedProject,
  ToggleProjectQASettings,
  LoadProjectSuccess,
  LoadProjectFail,
  PatchCurrentProject,
} from './project.actions';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { LoadProject } from '@store/project-store/project.actions';
import { LoadQASettings } from '@store/qa-settings-store/qa-settings.actions';
import { LoadProjectFiles } from '@store/project-files-store/project-files.actions';
import { UpdateProject, UpdateProjectName } from '@store/projects-store/projects.actions';
import { patch } from '@ngxs/store/operators';
import { CommonHubService } from '@shared/services';
import { ProjectChangedEvent } from '@shared/models';
import { RestoreQualityIssues } from '@store/quality-issues-store/quality-issues.actions';
import { ProjectInfo, ProjectsService } from '@generated/api';
import { Observable } from 'rxjs';
import { LoadPotentialUntranslatablesCount } from '@store/potential-untranslatables/potential-untranslatables.actions';

export interface ProjectStateModel {
  currentProject: ProjectInfo;
  currentProjectId: string;
}

// NOTE: this state is preserved in session storage,
// cause active project can be different for each tab
@Persistence({
  existingEngine: sessionStorage,
})
@StateRepository()
@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    currentProject: null,
    // От currentProjectId зависит другие states. projectId доступно сразу при открытии страницы(берется с url), поэтому отдельное поле.
    currentProjectId: null,
  },
})
@Injectable()
export class ProjectState implements NgxsOnInit {
  constructor(private projectsService: ProjectsService, private store: Store, private commonHub: CommonHubService) {
    this.subscribeOnProjectChanges();
  }

  private subscribeOnProjectChanges(): void {
    this.commonHub.watch(ProjectChangedEvent).subscribe((data) => {
      this.store.dispatch(new LoadProject(data.id));
      this.store.dispatch(new RestoreQualityIssues());
    });
  }

  public ngxsOnInit(ctx?: StateContext<ProjectStateModel>): any {
    if (!ctx) {
      return;
    }
    const oldState = ctx.getState();
    if (oldState && oldState.currentProjectId) {
      this.commonHub.subscribeProjectEvents(oldState.currentProjectId).subscribe();
    }
  }

  @Selector([ProjectState])
  public static project(state: ProjectStateModel): ProjectInfo {
    return state?.currentProject;
  }

  @Selector([ProjectState])
  public static projectId(state: ProjectStateModel): string {
    return state?.currentProjectId;
  }

  @Action(SetCurrentProject)
  public setCurrentProject(ctx: StateContext<ProjectStateModel>, { project }: SetCurrentProject): void {
    ctx.patchState({
      currentProject: project,
      currentProjectId: project?.id,
    });
    if (!project) {
      return;
    }

    this.commonHub.subscribeIfProjectChanged(project.id).subscribe();
  }

  @Action(PatchCurrentProject)
  public patchCurrentProject(ctx: StateContext<ProjectStateModel>, { project }: SetCurrentProject): void {
    ctx.setState(
      patch({
        currentProject: patch({
          ...project,
        }),
      })
    );
  }

  @Action(LoadProject, { cancelUncompleted: true })
  public loadProject(ctx: StateContext<ProjectStateModel>, action: LoadProject): Observable<void | ProjectInfo> {
    ctx.patchState({
      currentProjectId: action.id,
    });
    return this.projectsService
      .apiProjectsIdGet$Json({
        id: action.id,
      })
      .pipe(
        tap((project: ProjectInfo) => {
          const currentProject = ctx.getState()?.currentProject;
          if (currentProject && currentProject.qaSettingsId !== project.qaSettingsId) {
            ctx.dispatch(new LoadQASettings(project.qaSettingsId));
          }
          ctx.dispatch(new SetCurrentProject(project));
        }),
        mergeMap((project) => ctx.dispatch(new LoadProjectSuccess(project))),
        catchError((error) => ctx.dispatch(new LoadProjectFail(error)))
      );
  }

  @Action(TouchedProject)
  public touchedProject(ctx: StateContext<ProjectStateModel>, action: TouchedProject): Observable<ProjectInfo> {
    return this.projectsService
      .apiProjectsIdTouchedPost$Json({
        id: action.id,
      })
      .pipe(
        tap((project: ProjectInfo) => {
          ctx.dispatch(new SetCurrentProject(project));
        }),
        tap((project: ProjectInfo) => {
          ctx.dispatch(new UpdateProject(project));
          ctx.dispatch(new LoadQASettings(project.qaSettingsId));
          ctx.dispatch(new LoadProjectFiles(project.id));
          ctx.dispatch(new LoadPotentialUntranslatablesCount());
        })
      );
  }

  @Action(RenameProject)
  public renameProject(ctx: StateContext<ProjectStateModel>, action: RenameProject): Observable<ProjectInfo> {
    const state = ctx.getState();
    const name = action.name?.trim();

    if (!name || state.currentProject.name === name) {
      return;
    }

    return this.projectsService
      .apiProjectsIdPut$Json({
        id: action.id,
        body: { name },
      })
      .pipe(
        tap(() => {
          ctx.dispatch(new UpdateProjectName(action.id, name));
          ctx.setState(
            patch({
              currentProject: patch({ name }),
            })
          );
        })
      );
  }

  @Action(ToggleProjectQASettings)
  public toggleProjectQASettings(
    ctx: StateContext<ProjectStateModel>,
    action: ToggleProjectQASettings
  ): Observable<void> {
    return this.projectsService
      .apiProjectsIdChangeQaSettingsPost$Json({
        id: action.id,
        body: action,
      })
      .pipe(
        tap((project) => {
          ctx.dispatch(new SetCurrentProject(project));
        }),
        mergeMap((project) => ctx.dispatch(new LoadQASettings(project.qaSettingsId)))
      );
  }
}
