export enum ProtectionSegmentStatus {
  NotTranslated = 0,
  Draft = 1,
  Translated = 2,
  TranslationRejected = 3,
  TranslationApproved = 4,
  SignOffRejected = 5,
  SignOff = 6,
  NotStarted = 7,
  PreTranslated = 8,
  Edited = 9,
  ConfirmByTranslator = 10,
  ConfirmByReviewer1 = 11,
  ConfirmByReviewer2 = 12,
  Unconfirmed = 13,
  Confirmed = 14,
}

export enum ProtectionSegmentMatch {
  PerfectMatch = 0,
  ContextMatch = 1,
  Match100 = 2,
  Match101 = 3,
}

export enum ProtectionFileType {
  SDLXLIFF = 'SDLXLIFF',
  MemoQXLIFF = 'MEMOQXLIFF',
  MemSourceXLIFF = 'MEMSOURCEXLIFF',
}
