import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSelectComponent } from './single-select';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@shared/components/icon';
import { MultiselectComponent } from './multiselect';
import { OptionComponent } from './option/option.component';
import { OptgroupComponent } from './optgroup/optgroup.component';
import { PipesModule } from '@shared/pipes';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  declarations: [SingleSelectComponent, MultiselectComponent, OptionComponent, OptgroupComponent],
  exports: [SingleSelectComponent, MultiselectComponent, OptionComponent, OptgroupComponent],
  imports: [CommonModule, NgSelectModule, FormsModule, IconModule, PipesModule, ActionIconModule],
})
export class SelectModule {}
