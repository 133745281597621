/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { CommentsService } from './services/comments.service';
import { InvitesService } from './services/invites.service';
import { LanguagesService } from './services/languages.service';
import { LocaleQaSettingsService } from './services/locale-qa-settings.service';
import { LocalizationPlatformsService } from './services/localization-platforms.service';
import { OrganizationsService } from './services/organizations.service';
import { PotentialUntranslatablesService } from './services/potential-untranslatables.service';
import { ProjectFilesService } from './services/project-files.service';
import { ProjectsService } from './services/projects.service';
import { QaSettingsService } from './services/qa-settings.service';
import { QualityIssuesService } from './services/quality-issues.service';
import { ReportsService } from './services/reports.service';
import { SocketService } from './services/socket.service';
import { SpellCheckService } from './services/spell-check.service';
import { TermbasesService } from './services/termbases.service';
import { TermsService } from './services/terms.service';
import { TranslationUnitChangesService } from './services/translation-unit-changes.service';
import { TranslationUnitsService } from './services/translation-units.service';
import { WordformsService } from './services/wordforms.service';
import { WordformsBasesService } from './services/wordforms-bases.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CommentsService,
    InvitesService,
    LanguagesService,
    LocaleQaSettingsService,
    LocalizationPlatformsService,
    OrganizationsService,
    PotentialUntranslatablesService,
    ProjectFilesService,
    ProjectsService,
    QaSettingsService,
    QualityIssuesService,
    ReportsService,
    SocketService,
    SpellCheckService,
    TermbasesService,
    TermsService,
    TranslationUnitChangesService,
    TranslationUnitsService,
    WordformsService,
    WordformsBasesService,
    ApiConfiguration
  ],
})
export class HttpApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<HttpApiModule> {
    return {
      ngModule: HttpApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: HttpApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('HttpApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
