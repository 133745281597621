import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';
import { ChipListComponent } from '@shared/components/chip-list/chip-list';
import { GridHelperService } from '@shared/services/grid-helper.service';
import { ICellEditorParams, ICellEditorAngularComp } from 'src/types/ag-grid';

interface CellEditorParams extends ICellEditorParams {
  rtlGetter: (params: CellEditorParams) => boolean;
  createNewRow: () => void;
}

const POPUP_PADDING = 16;

@Component({
  selector: 'app-list-cell-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './list-cell-editor.component.html',
  styleUrls: ['./list-cell-editor.component.scss'],
})
export class ListCellEditorComponent implements ICellEditorAngularComp, OnDestroy, AfterViewInit {
  public static rendererName = 'listCellEditorComponent';
  public readonly control = new FormControl(null, []);
  public popupWidth: number;
  @ViewChild(ChipListComponent, { static: true })
  private chipsComponent: ChipListComponent;

  public rtl = false;
  private columnId: string;
  private rowNodeId: string;
  private params: CellEditorParams;

  constructor(private readonly gridHelperService: GridHelperService) {}

  public agInit(params: CellEditorParams): void {
    this.gridHelperService.initApi(params.api, params.columnApi);
    this.params = params;
    this.columnId = params.column.getColId();
    this.rowNodeId = params.node.id;
    this.control.setValue(params.value);
    this.popupWidth = (params.column as any).actualWidth - POPUP_PADDING;
    if (params.rtlGetter) {
      this.rtl = params.rtlGetter(params);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.chipsComponent.focus();
    }, 0);
  }

  ngOnDestroy() {}

  public getValue(): string[] {
    return [...this.control.value];
  }

  public updateParams(params: { id: string } & any): void {
    if (params.id !== this.rowNodeId) {
      return;
    }
    this.control.setValue(params[this.columnId]);
  }

  public isCancelAfterEnd(): boolean {
    return this.control.invalid;
  }

  public isPopup(): boolean {
    return true;
  }

  public preventEvent(e: KeyboardEvent): void {
    if (e.code === 'Escape') {
      return;
    }
    if (e.code === 'Tab') {
      const switched = this.gridHelperService.switchToNextEditor(this.params.node, this.columnId);
      if (!switched && this.params.createNewRow) {
        this.params.createNewRow();
      }
    }
    e.stopPropagation();
  }

  // if we need popup at the bottom of control, we need this function with 'under' value
  public getPopupPosition(): string {
    return 'under';
  }
}
