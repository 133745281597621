import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizationPlatformsProject } from '@shared/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LPChooseFilesComponentParams } from '@shared/components/localization-platforms/models';
import { LPAssets } from '@shared/components/localization-platforms/constants/lp-assets';
import { takeUntil } from 'rxjs/operators';
import { LocalizationPlatformFileModel } from '@generated/api';

@Component({
  selector: 'app-lp-choose-files',
  templateUrl: './lp-choose-files.component.html',
  styleUrls: ['./lp-choose-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LPChooseFilesComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  projects: LocalizationPlatformsProject[] = [];
  files: LocalizationPlatformFileModel[] = [];
  platformAssets: LPAssets;
  logoutCallback = () => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filesSelectedCallback = (project: LocalizationPlatformsProject, files: LocalizationPlatformFileModel[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  projectSelectedCallback = (_: LocalizationPlatformsProject): void => {};
  selectedFiles: LocalizationPlatformFileModel[] = [];
  selectedProject: LocalizationPlatformsProject;
  loading: boolean = false;

  get logoPath(): string {
    return this.platformAssets.logoPath;
  }

  get hasProjects(): boolean {
    return !!this.projects.length;
  }

  get hasFiles(): boolean {
    return !!this.files.length;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: LPChooseFilesComponentParams,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.platformAssets = this.data.platformAssets;
    this.logoutCallback = this.data.logoutCallback;
    this.filesSelectedCallback = this.data.filesSelectedCallback;
    this.projectSelectedCallback = this.data.projectSelectedCallback;
    this.data.files.pipe(takeUntil(this.destroyed$)).subscribe((files) => {
      if (files && files.length) {
        this.selectedFiles = files;
        this.files = files;
      } else {
        this.selectedFiles = [];
        this.files = [];
      }
      this.changeDetectorRef.detectChanges();
    });
    this.data.projects.pipe(takeUntil(this.destroyed$)).subscribe((projects) => {
      if (projects && projects.length) {
        this.projects = projects;
        this.projectSelected(projects[0]);
      } else {
        this.projects = [];
        this.projectSelected(null);
      }
      this.changeDetectorRef.detectChanges();
    });
    this.data.loading.pipe(takeUntil(this.destroyed$)).subscribe((loading) => {
      this.loading = loading;
      this.changeDetectorRef.detectChanges();
    });
  }

  addBtnClickHandler() {
    this.filesSelectedCallback(this.selectedProject, this.selectedFiles);
  }

  logoutBtnClickHandler() {
    this.logoutCallback();
  }

  projectSelected(project: LocalizationPlatformsProject) {
    this.selectedProject = project;
    this.projectSelectedCallback(project);
  }

  filesSelected(files: LocalizationPlatformFileModel[]) {
    this.selectedFiles = files;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
