<div class="modal" [class.modal-with-header]="modalType === 'withHeader'">
  <div class="modal-body" [ngClass]="'modal-' + size">
    <app-action-icon
      *ngIf="!disableClose && size !== 'small'"
      mat-dialog-close
      icon="clear"
      class="modal-close-icon"
      data-test-id="close-modal"
      theme="subtle"
    ></app-action-icon>
    <ng-content></ng-content>
  </div>
</div>
