import { Injectable } from '@angular/core';
import { CONSISTENCY_CONSTANTS } from '@report/modules/consistency/constants/consistency-constants';
import { getMatchForRow } from '@report/utils';
import {
  AutofixType,
  ConsistencyQualityIssueAdditionalData,
  QualityIssue,
  RawQualityIssue,
  SegmentElementType,
  SelectionRange,
  SelectionType,
  UniversalSelectionRange,
} from '@shared/models';
import { AdditionalDataUnitsSelection } from '@shared/models';
import { RenderItemBuilder } from '@shared/services/render-item-builder.service';
import { TranslationDestination } from '@shared/models/search-in-report-files';
import { IssueType, QualityIssueModel, SegmentElement } from '@generated/api';
import { isDelimiterChar } from '@shared/tools';

function groupBy<T extends QualityIssue>(arr: T[], predicate: (a: T, b: T) => boolean): T[] {
  const result = arr.reduce((acc: T[], cur) => {
    const index = acc.findIndex((a) => predicate(a, cur));
    if (index === -1) {
      cur.identicalRows = [];
      acc.push(cur);
    } else {
      cur.parentTranslationUnitId = acc[index].translationUnit.id;
      acc[index].identicalRows.push(cur);
    }
    return acc;
  }, []);
  return result;
}

@Injectable()
export class QualityIssueMapper {
  constructor(private renderItemBuilder: RenderItemBuilder) {}

  public mapQualityIssuesByType(
    qualityIssues: RawQualityIssue[] | QualityIssue[],
    type: IssueType,
    suppressAutofix = false
  ): QualityIssue[] {
    if (type === IssueType.Consistency) {
      return this.mapConsistencyQualityIssues(qualityIssues, suppressAutofix);
    }
    return this.mapQualityIssues(qualityIssues as RawQualityIssue[], suppressAutofix);
  }

  public groupQualityIssuesByType(qualityIssues: QualityIssueModel[]): Record<IssueType, RawQualityIssue[]> {
    const groupedRawQualityIssues = {
      [IssueType.Formal]: [],
      [IssueType.Consistency]: [],
      [IssueType.Terminology]: [],
      [IssueType.Custom]: [],
      [IssueType.Grammar]: [],
      [IssueType.Spelling]: [],
    };
    qualityIssues.forEach((qi) => {
      groupedRawQualityIssues[qi.issueType].push(qi);
    });

    return groupedRawQualityIssues;
  }

  public mapQualityIssues(qualityIssues: RawQualityIssue[], suppressAutofix = false): QualityIssue[] {
    return qualityIssues.map((qi) => this.mapQualityIssue(qi, suppressAutofix));
  }

  public mapQualityIssue(qi: RawQualityIssue | QualityIssue, suppressAutofix = false): QualityIssue {
    const extendedTargetSelectionRanges = suppressAutofix
      ? this.normalizeRanges(qi.targetRanges as UniversalSelectionRange[], true)
      : this.extendRangesByAutofixes(qi.translationUnit.target.elements, qi.targetRanges as UniversalSelectionRange[]);

    const mappedQualityIssue = {
      ...qi,
      sourceRanges: qi.sourceRanges.map((s) => (s.range ? { ...s.range, fix: s.fix } : s)),
      targetRanges: extendedTargetSelectionRanges,
      translationUnit: qi.translationUnit,
      sourceRenderItems: this.renderItemBuilder.build(
        qi.sourceRanges as UniversalSelectionRange[],
        qi.translationUnit.source.elements
      ),
      targetRenderItems: this.renderItemBuilder.build(
        extendedTargetSelectionRanges,
        qi.translationUnit.target.elements
      ),
      match: getMatchForRow(qi.translationUnit.properties),
    } as QualityIssue;

    return mappedQualityIssue;
  }

  public mapConsistencyQualityIssues(
    qualityIssues: RawQualityIssue[] | QualityIssue[],
    suppressAutofix = false
  ): QualityIssue[] {
    const consistencyQualityIssues: QualityIssue[] = (qualityIssues as any)
      .map((qi: RawQualityIssue) => this.mapConsistencyQualityIssue(qi, suppressAutofix))
      .flat(1);

    return this.aggregateCommonConsistenctySubGroupFields(
      consistencyQualityIssues as QualityIssue<ConsistencyQualityIssueAdditionalData>[]
    );
  }

  public mapConsistencyQualityIssue(qi: RawQualityIssue, suppressAutofix = false): QualityIssue[] {
    const mappedRows: QualityIssue[] = [];
    const segment =
      qi.issueKind === CONSISTENCY_CONSTANTS.TARGET_INCONSISTENCY
        ? qi.translationUnit.source
        : qi.translationUnit.target;
    const subGroup = segment.elements
      .filter((e) => e.elementType === SegmentElementType.Text)
      .map((e) => e.text)
      .join('');

    const additionalData = qi.additionalData as ConsistencyQualityIssueAdditionalData;

    const referenceRow: QualityIssue = this.mapQualityIssue(
      {
        ...qi,
        subGroup,
      },
      suppressAutofix
    );

    mappedRows.push(referenceRow);

    additionalData.unitsSelection.forEach((item) => {
      const tu = item.checkedTranslationUnit;

      const sourceRanges = this.extractRanges(item, 'source');
      const targetRanges = this.extractRanges(item, 'target');

      const checkedRow = this.mapQualityIssue({
        ...qi,
        sourceRanges,
        targetRanges,
        subGroup,
        translationUnit: {
          ...tu,
        },
        translationUnitId: tu.id, // TODO: кажется это поле полностью бесполезно, учесть при переработке модели
        additionalData: null,
      });
      mappedRows.push(checkedRow);
    });

    const uniquedRows = groupBy(
      mappedRows,
      (a, b) =>
        a.translationUnit.source.text === b.translationUnit.source.text &&
        a.translationUnit.target.text === b.translationUnit.target.text
    );
    return uniquedRows;
  }

  private aggregateCommonConsistenctySubGroupFields(
    qualityIssues: QualityIssue<ConsistencyQualityIssueAdditionalData>[]
  ): QualityIssue<ConsistencyQualityIssueAdditionalData>[] {
    // NOTE: на данный момент бекенд гарантирует что consistency ошибки приходят пачкой по ws в 1 группе
    // это позволит вычислять статус защиты в сегменте и статус наличия в соседях тегов на этапе препроцессинга
    // В случае если данные будут приходить в произвольной последовательности этот метод нужно будет
    // перенести в рантайм при отрисовке строк таблицы, либо пересчитывать для всех consistency quality issues
    const presudoGroupedQualityIssues = qualityIssues.reduce<{
      [key: string]: { hasProtectedNeighbors: boolean; hasTagedNeighbors: boolean };
    }>((acc, qi) => {
      const hasProtectedNeighbors = qi.translationUnit.isProtected;
      const hasTagedNeighbors = qi.translationUnit.target.elements.some(
        (el) => el.elementType === SegmentElementType.Tag
      );

      acc[qi.id] = {
        hasProtectedNeighbors: acc[qi.id]?.hasProtectedNeighbors || hasProtectedNeighbors,
        hasTagedNeighbors: acc[qi.id]?.hasTagedNeighbors || hasTagedNeighbors,
      };

      return acc;
    }, {});

    return qualityIssues.map((qi) => ({
      ...qi,
      hasProtectedNeighbors: presudoGroupedQualityIssues[qi.id].hasProtectedNeighbors,
      hasTagedNeighbors: presudoGroupedQualityIssues[qi.id].hasTagedNeighbors,
    }));
  }

  public determineAutofixType(s: SelectionRange): AutofixType {
    if (s.fix == null) {
      return null;
    }
    if (s.start === s.end) {
      return AutofixType.Add;
    }
    if (!s.fix) {
      return AutofixType.Remove;
    }
    if (s.fix) {
      return AutofixType.Replace;
    }

    throw new Error(`Autofix type is unsupported for ${s.fix} suggestion with [${s.start}:${s.end}] range`);
  }

  private extractRanges(item: AdditionalDataUnitsSelection, key: TranslationDestination): SelectionRange[] {
    if (
      (key === 'source' && item.selectionType === SelectionType.Source) ||
      (key === 'target' && item.selectionType === SelectionType.Target)
    ) {
      return item.checkedSelections;
    }
    return [];
  }

  private normalizeRanges(selectionRange: UniversalSelectionRange[], suppressAutofix = false): SelectionRange[] {
    return selectionRange.map((range) => this.normalizeSelectionRange(range, suppressAutofix));
  }

  private extendRangesByAutofixes(
    elements: SegmentElement[],
    selectionRange: UniversalSelectionRange[]
  ): SelectionRange[] {
    const extendedSelectionRange = selectionRange.reduce<SelectionRange[]>(
      (extendedRanges: SelectionRange[], r: SelectionRange) => {
        const range = this.normalizeSelectionRange(r);

        // Когда нет фикса, либо когда фикс - замена (одиночное выделение без соседей),
        // то не пытаемся подсветить еще и соседей
        if (!range.isFixAvailable || range.autofixType === AutofixType.Replace) {
          return [...extendedRanges, range];
        }

        const newRange = this.buildWordBasedSelectionRange(range, elements);
        return [...extendedRanges, newRange];
      },
      []
    );

    const uniqueExtendedSelectionRange = extendedSelectionRange.reduce<SelectionRange[]>((acc, r) => {
      const alreadyExist = acc.find((ar) => ar.end === r.end && ar.start === r.start);
      if (!alreadyExist) {
        acc.push(r);
      }
      return acc;
    }, []);

    return uniqueExtendedSelectionRange;
  }

  private buildWordBasedSelectionRange(selectionRange: SelectionRange, elements: SegmentElement[]): SelectionRange {
    const entireText = elements.reduce((text, currentEl) => text + currentEl.text, '');
    const tagElements = elements.filter((el) => el.elementType === SegmentElementType.Tag);

    let neighborWordSelectionStart = 0;
    let neighborWordSelectionEnd = entireText.length;
    const firstRightTag = tagElements.find((t) => t.start >= selectionRange.end);
    const firstLeftTag = tagElements.reverse().find((t) => t.end <= selectionRange.start);
    // TODO: thinnk about minification for this  pair of code blocks
    // Find border of selection range from left
    for (let i = selectionRange.start; i >= 0; i--) {
      if (i === firstLeftTag?.end && i !== selectionRange.start) {
        neighborWordSelectionStart = i;
        break;
      }

      if (i === firstLeftTag?.end) {
        neighborWordSelectionStart = firstLeftTag.start;
        break;
      }
      if (i === selectionRange.start) {
        continue;
      }
      if (isDelimiterChar(entireText[i])) {
        neighborWordSelectionStart = i + 1;
        break;
      }
    }

    // Find border of selection range from right
    for (let i = selectionRange.end; i < entireText.length; i++) {
      if (firstRightTag?.start === i && i !== selectionRange.end) {
        neighborWordSelectionEnd = i;
        break;
      }
      if (firstRightTag?.start <= i) {
        neighborWordSelectionEnd = firstRightTag.end;
        break;
      }
      if (i === selectionRange.end) {
        continue;
      }
      if (isDelimiterChar(entireText[i])) {
        neighborWordSelectionEnd = i;
        break;
      }
    }

    const relativeStart = selectionRange.start - neighborWordSelectionStart;
    return {
      ...selectionRange,
      start: neighborWordSelectionStart,
      end: neighborWordSelectionEnd,
      length: neighborWordSelectionEnd - neighborWordSelectionStart,
      // We need to store relative positions to accept fix after editing
      autofixRange: {
        start: relativeStart,
        end: relativeStart + selectionRange.length,
        length: selectionRange.length,
      },
    };
  }

  private normalizeSelectionRange(r: UniversalSelectionRange, suppressAutofix = false): SelectionRange {
    const range: SelectionRange = {
      fix: suppressAutofix ? undefined : r.fix,
      isFixAvailable: suppressAutofix ? undefined : r.isFixAvailable,
      start: r.start ?? r.range.start,
      end: r.end ?? r.range.end,
      length: r.length ?? r.range.length,
    };
    range.autofixType = suppressAutofix ? null : this.determineAutofixType(range);

    if (suppressAutofix || range.fix == null) {
      return range;
    }

    // This is the relative position of the autofix. This value is necessary for correct positioning of
    // the autofix relative to after editing the handle
    range.autofixRange = {
      start: 0,
      end: range.end - range.start,
      length: range.length,
    };
    return range;
  }
}
