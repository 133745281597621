<ng-container *ngIf="app.loaded$ | async">
  <app-header
    offlineTooltipText="You can continue to correct and ignore errors.&#13;Changes will be saved once connection is restored."
    [isReportPage]="true"
  >
    <span (click)="handleGoSettings()" class="project-name cursor-pointer">
      {{ (project$ | async)?.name }}
    </span>
    <app-button
      *ngIf="hasLocalizationPlatformFiles | async"
      (clickButton)="saveChanges()"
      theme="outlined"
      class="save-changes-btn"
      size="sm"
      prefixIcon="outline-help"
    >
      Save changes
    </app-button>
    <app-action-icon
      size="md"
      icon="link"
      theme="subtle"
      [appTooltipTriggerFor]="copyLinkToReportTooltip"
      (clickButton)="copyReportLink()"
    ></app-action-icon>
    <ng-container *ngIf="(report$ | async)?.isOwner">
      <div class="header-delimiter"></div>
      <div class="project-name">{{ (qaSettings$ | async)?.name }}</div>
    </ng-container>

    <ng-container rightActions>
      <app-shortcuts-helper-btn class="menu-action-icon-btn"></app-shortcuts-helper-btn>
      <app-report-settings-btn class="menu-action-icon-btn"></app-report-settings-btn>
      <ng-container rightActions>
        <app-help-button></app-help-button>
      </ng-container>
    </ng-container>
  </app-header>
  <main role="main" (keydown)="keydown($event)">
    <router-outlet></router-outlet>
  </main>
</ng-container>

<tooltip #copyLinkToReportTooltip="tooltip">
  Copies link to QA report to clipboard ({{
    shortcutsService.eventShortuts[controlPanelKeybindingEvent.COPY_LINK_TO_REPORT] | prettyKey
  }})
</tooltip>

<ng-template #popupTemplate>
  <div class="export-popup">
    <div class="popup-text">
      <ng-container *ngIf="exportingStages.InProgress === exportingStage"
        >Preparing the QA report for download. Please wait...</ng-container
      >
      <ng-container *ngIf="exportingStages.Ready === exportingStage">QA report is ready for download.</ng-container>
    </div>
    <div class="popup-actions">
      <app-button theme="outlined" (clickButton)="closePopup()">CANCEL</app-button>
      <app-button *ngIf="exportingStages.Ready === exportingStage" theme="primary" (clickButton)="download()"
        >DOWNLOAD</app-button
      >
    </div>
  </div>
</ng-template>
