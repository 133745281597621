import { QaSettingsModel } from '@generated/api';

export enum ActionTypes {
  SET_CURRENT_QASETTINGS = '[QASettings] Set Current QASettings',
  RENAME_QASETTINGS = '[QASettings] Rename QASettings',
  PATCH_QASETTINGS = '[QASettings] PATCH QASettings',

  LOAD_QASETTINGS = '[QASettings] Load QASettings',
  LOAD_QASETTINGS_FAILURE = '[QASettings] Load QASettings Failure',
  LOAD_QASETTINGS_SUCCESS = '[QASettings] Load QASettings Success',

  RESET_SETTINGS = '[QASettings] Reset settings',
  CLEAR_SETTINGS = '[QASettings] Clear settings',
}

export class SetCurrentQASettings {
  static readonly type = ActionTypes.SET_CURRENT_QASETTINGS;
  constructor(public qaSettings: QaSettingsModel) {}
}

export class LoadQASettings {
  static readonly type = ActionTypes.LOAD_QASETTINGS;
  constructor(public id: string) {}
}

export class LoadQASettingsSuccess {
  static readonly type = ActionTypes.LOAD_QASETTINGS_SUCCESS;
  constructor() {}
}

export class LoadQASettingsFail {
  static readonly type = ActionTypes.LOAD_QASETTINGS_FAILURE;
  constructor(public error: any) {}
}

export class RenameQASettings {
  static readonly type = ActionTypes.RENAME_QASETTINGS;
  constructor(public qaSettingsId: string, public name: string) {}
}

export class PatchQASettings<T> {
  static readonly type = ActionTypes.PATCH_QASETTINGS;
  constructor(public pathFn: ((obj: QaSettingsModel) => T) | string, public value: T) {}
}

export class ResetSettings {
  static readonly type = ActionTypes.RESET_SETTINGS;
  constructor(public qaSettingsId: string) {}
}

export class ClearSettings {
  static readonly type = ActionTypes.CLEAR_SETTINGS;
  constructor(public qaSettingsId: string) {}
}

export type Actions = SetCurrentQASettings | LoadQASettings | LoadQASettingsSuccess | LoadQASettingsFail;
