const spaceCode = 32;
const noneBreakSpaceCode = 160;

/*
 *  Determine if the string is equal to whitespace-like character
 *  Whitespace-like characters codes: 160, 32
 */
export function isSpaceChar(val: string): boolean {
  if (!val || val.length !== 1) {
    return false;
  }
  const charCode = val.charCodeAt(0);
  return charCode === spaceCode || charCode === noneBreakSpaceCode;
}

/*
 *  Determine if the string is delimiter character
 *  List of delimiter unicode symbols:
 *
 *  https://www.compart.com/en/unicode/block/U+2000
 *  https://www.compart.com/en/unicode/block/U+3000
 *  https://www.compart.com/en/unicode/U+002C
 */
export function isDelimiterChar(val: string): boolean {
  if (!val || val.length !== 1) {
    return false;
  }
  return /[\u2000-\u206F\u3000-\u303F\u002C\u00A0\.,! \)\(;]/.test(val);
}
