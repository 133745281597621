import { IssueType } from '@generated/api';

export enum ReportTab {
  Formal = 'formal',
  Consistency = 'consistency',
  Terminology = 'terminology',
  Spelling = 'spelling',
  Custom = 'custom',
  Search = 'search',
  History = 'history',
}

export const ReportTabToIssueType = {
  [ReportTab.Formal]: IssueType.Formal,
  [ReportTab.Consistency]: IssueType.Consistency,
  [ReportTab.Terminology]: IssueType.Terminology,
  [ReportTab.Spelling]: IssueType.Spelling,
  [ReportTab.Custom]: IssueType.Custom,
};
