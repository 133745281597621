<app-ui-dropdown-menu
  #projectDropDown
  [disabled]="!(app.online$ | async)"
  triggerPlacement="left"
  xPosition="after"
  popupClass="header-projects-popup"
  data-test-id="projects-menu"
>
  <app-action-icon
    dropdown-trigger
    [active]="projectDropDown.isOpen"
    icon="more-vert"
    theme="circle"
    size="lg"
    class="trigger-icon"
  ></app-action-icon>

  <div dropdown-label>
    <app-ui-text-editable
      [appDisableControl]="!(app.online$ | async)"
      [formControl]="projectNameControl"
      [errors]="{
        exist: 'Project with such a name already exists. Please use another name.'
      }"
      class="dropdown-menu-text-editable"
      placeholder="Enter project name"
      size="lg"
      (submitText)="renameProject($event)"
    >
    </app-ui-text-editable>
  </div>

  <div dropdown-content class="container" [style.width.px]="popupWidth" appDropdownMenuPreventClick>
    <app-project-manager *ngIf="projectDropDown?.isOpen" (closed)="projectSelected()"></app-project-manager>
  </div>
</app-ui-dropdown-menu>
