import { ChangeDetectorRef, Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { FilesUpload } from '@shared/models/file';

/**
 * A directive that allows the Element to be a host for drag'n'drop files or folders.
 * Use `(fileDropped)` callback to handle files and folders.
 * The directive adds a "fileover" class to the Element when the user drags files over the element.
 * You need to implement this class by yourself.
 *
 * How to use:
 * ```
 *   <div appFilesDragAndDropHost (fileDropped)="uploadFiles($event)"></div>
 * ```
 * Where $event is {@see FilesUpload} object
 */
@Directive({
  selector: '[appFilesDragAndDropHost]',
})
export class FilesDragAndDropHostDirective {
  /**
   * Class indicating
   */
  @HostBinding('class.fileover') fileOver: boolean;

  @Input() disabled = false;

  /**
   * File(s)/directive(s) dropped callback.
   * {@see FilesUpload}
   */
  @Output() fileDropped = new EventEmitter<FilesUpload>();
  private leaveTO: ReturnType<typeof setTimeout> = null;

  constructor(protected cdr: ChangeDetectorRef) {}

  private static async getAllFileEntries(dataTransferItemList: DataTransferItemList): Promise<File[]> {
    const fileEntries: File[] = [];
    const queue: (FileSystemEntry | FileSystemDirectoryEntry)[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < dataTransferItemList.length; i++) {
      const dataTransferItem: DataTransferItem = dataTransferItemList[i];
      if (dataTransferItem.kind !== 'file') {
        continue;
      }
      if (typeof dataTransferItem.webkitGetAsEntry !== 'function') {
        fileEntries.push(await FilesDragAndDropHostDirective.getFile(dataTransferItem));
      } else {
        queue.push(dataTransferItem.webkitGetAsEntry());
      }
    }
    while (queue.length > 0) {
      const entry = queue.shift();
      if (entry.isFile) {
        fileEntries.push(await FilesDragAndDropHostDirective.getFile(entry));
      } else if (entry.isDirectory) {
        const reader = (entry as FileSystemDirectoryEntry).createReader();
        queue.push(...(await FilesDragAndDropHostDirective.readAllDirectoryEntries(reader)));
      }
    }
    return fileEntries;
  }

  private static async readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
    const entries = [];
    let readEntries = await FilesDragAndDropHostDirective.readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
      entries.push(...readEntries);
      readEntries = await FilesDragAndDropHostDirective.readEntriesPromise(directoryReader);
    }
    return entries;
  }

  private static async readEntriesPromise(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {}
  }

  private static async getFile(fileEntry): Promise<File> {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {}
  }

  // Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(evt: any) {
    if (this.disabled) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    if (this.leaveTO) {
      clearTimeout(this.leaveTO);
    }
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any) {
    if (this.disabled) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.leaveTO = setTimeout(() => {
      this.fileOver = false;
      this.cdr.detectChanges();
    }, 100);
  }

  @HostListener('drop', ['$event'])
  public async ondrop(evt) {
    if (this.disabled) {
      return;
    }
    let isFolder = false;
    let files: File[] = [];
    evt.preventDefault();
    evt.stopPropagation();
    document.body.click();
    this.fileOver = false;
    const items: DataTransferItemList = evt.dataTransfer.items;
    if (items) {
      const firstItem = items[0];
      if (typeof firstItem.webkitGetAsEntry === 'function' && firstItem.webkitGetAsEntry()?.isDirectory) {
        isFolder = true;
      }
      files = await FilesDragAndDropHostDirective.getAllFileEntries(items);
    } else {
      files = [...evt.dataTransfer.files];
    }
    if (files.length > 0 || isFolder) {
      this.fileDropped.emit({ files, isFolder });
    }
  }
}
