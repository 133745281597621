export enum SpecialSymbolGroup {
  SPECIAL_SYMBOLS = 'Special symbols',
}

export interface SpecSymbol {
  symbol?: string;
  searchQueries?: string[];
  altCode: string;
  unicodeNumber: string;
  description: string;
  group: string;
  visual?: string;
}

export const specialSymbols: SpecSymbol[] = [
  {
    group: 'Special symbols',
    description: 'Copyright Sign',
    altCode: '0169',
    searchQueries: ['(c)'],
    unicodeNumber: 'U+00A9',
  },
  {
    group: 'Special symbols',
    description: 'Registered Sign',
    altCode: '0174',
    searchQueries: ['(r)'],
    unicodeNumber: 'U+00AE',
  },
  {
    group: 'Special symbols',
    description: 'Trade Mark Sign',
    altCode: '0153',
    searchQueries: ['tm'],
    unicodeNumber: 'U+2122',
  },
  {
    group: 'Special symbols',
    description: 'Section Sign',
    altCode: '21 или 0167',
    unicodeNumber: 'U+00A7',
  },
  {
    group: 'Special symbols',
    description: 'Pilcrow Sign',
    searchQueries: ['paragraph'],
    altCode: '20 или 0182',
    unicodeNumber: 'U+00B6',
  },
  {
    group: 'Special symbols',
    description: 'Degree Celsius',
    altCode: '',
    unicodeNumber: 'U+2103',
  },
  {
    group: 'Special symbols',
    description: 'Degree Fahrenheit',
    altCode: '',
    unicodeNumber: 'U+2109',
  },
  {
    group: 'Dash',
    description: 'Non-Breaking Hyphen',
    altCode: '',
    unicodeNumber: 'U+2011',
  },
  {
    group: 'Dash',
    description: 'Hyphen',
    altCode: '',
    unicodeNumber: 'U+2010',
  },
  {
    group: 'Dash',
    description: 'Hyphen-Minus',
    altCode: '45',
    unicodeNumber: 'U+002D',
  },
  {
    group: 'Dash',
    description: 'En Dash',
    altCode: '0150',
    unicodeNumber: 'U+2013',
  },
  {
    group: 'Dash',
    description: 'Em Dash',
    altCode: '0151',
    unicodeNumber: 'U+2014',
  },
  {
    group: 'Dash',
    description: 'Tilde',
    altCode: '126',
    unicodeNumber: 'U+007E',
  },
  {
    group: 'Dash',
    description: 'Fullwidth Tilde',
    altCode: '',
    unicodeNumber: 'U+FF5E',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Quotation Mark',
    altCode: '',
    unicodeNumber: 'U+0022',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Left Double Quotation Mark',
    altCode: '0147',
    unicodeNumber: 'U+201C',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Right Double Quotation Mark',
    altCode: '0148',
    unicodeNumber: 'U+201D',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Double Low-9 Quotation Mark',
    altCode: '0132',
    unicodeNumber: 'U+201E',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Left Single Quotation Mark',
    altCode: '0145',
    unicodeNumber: 'U+2018',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Right Single Quotation Mark',
    altCode: '0146',
    unicodeNumber: 'U+2019',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Single Low-9 Quotation Mark',
    altCode: '0130',
    unicodeNumber: 'U+201A',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Left-Pointing Double Angle Quotation Mark',
    altCode: '174 или 0171',
    unicodeNumber: 'U+00AB',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Right-Pointing Double Angle Quotation Mark',
    altCode: '175 или 0187',
    unicodeNumber: 'U+00BB',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Apostrophe',
    altCode: '39',
    unicodeNumber: 'U+0027',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Reversed Double Prime Quotation Mark',
    altCode: '',
    unicodeNumber: 'U+301D',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Double Prime Quotation Mark',
    altCode: '',
    unicodeNumber: 'U+301E',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Low Double Prime Quotation Mark',
    altCode: '',
    unicodeNumber: 'U+301F',
  },
  {
    group: 'Quotation marks and apostrophes',
    description: 'Fullwidth Quotation Mark',
    altCode: '',
    unicodeNumber: 'U+FF02',
  },
  {
    group: 'Parentheses',
    description: 'Left Double Angle Bracket',
    altCode: '',
    unicodeNumber: 'U+300A',
  },
  {
    group: 'Parentheses',
    description: 'Right Double Angle Bracket',
    altCode: '',
    unicodeNumber: 'U+300B',
  },
  {
    group: 'Parentheses',
    description: 'Right Angle Bracket',
    altCode: '',
    unicodeNumber: 'U+3009',
  },
  {
    group: 'Parentheses',
    description: 'Left Angle Bracket',
    altCode: '',
    unicodeNumber: 'U+3008',
  },
  {
    group: 'Parentheses',
    description: 'Left Corner Bracket',
    altCode: '',
    unicodeNumber: 'U+300C',
  },
  {
    group: 'Parentheses',
    description: 'Right Corner Bracket',
    altCode: '',
    unicodeNumber: 'U+300D',
  },
  {
    group: 'Parentheses',
    description: 'Left White Corner Bracket',
    altCode: '',
    unicodeNumber: 'U+300E',
  },
  {
    group: 'Parentheses',
    description: 'Right White Corner Bracket',
    altCode: '',
    unicodeNumber: 'U+300F',
  },
  {
    group: 'Parentheses',
    description: 'Fullwidth Left Parenthesis',
    altCode: '',
    unicodeNumber: 'U+FF08',
  },
  {
    group: 'Parentheses',
    description: 'Fullwidth Right Parenthesis',
    altCode: '',
    unicodeNumber: 'U+FF09',
  },
  {
    group: 'Punctuation marks',
    description: 'Horizontal Ellipsis',
    altCode: '0133',
    unicodeNumber: 'U+2026',
  },
  {
    group: 'Punctuation marks',
    description: 'Inverted Question Mark',
    altCode: '168 или 0191',
    unicodeNumber: 'U+00BF',
  },
  {
    group: 'Punctuation marks',
    description: 'Inverted Exclamation Mark',
    altCode: '173 или 0161',
    unicodeNumber: 'U+00A1',
  },
  {
    group: 'Punctuation marks',
    description: 'Arabic Question Mark',
    altCode: '',
    unicodeNumber: 'U+061F',
  },
  {
    group: 'Punctuation marks',
    description: 'Ideographic Full Stop',
    altCode: '',
    unicodeNumber: 'U+3002',
  },
  {
    group: 'Punctuation marks',
    description: 'Fullwidth Exclamation Mark',
    altCode: '',
    unicodeNumber: 'U+FF01',
  },
  {
    group: 'Punctuation marks',
    description: 'Fullwidth Question Mark',
    altCode: '',
    unicodeNumber: 'U+FF1F',
  },
  {
    group: 'Punctuation marks',
    description: 'Arabic Full Stop',
    altCode: '',
    unicodeNumber: 'U+06D4',
  },
  {
    group: 'Spaces',
    description: 'No-Break Space',
    altCode: '255 или 0160',
    unicodeNumber: 'U+00A0',
    visual: '⚬',
  },
  {
    group: 'Greek letters',
    description: 'Greek Capital Letter Delta',
    altCode: '',
    unicodeNumber: 'U+0394',
  },
  {
    group: 'Greek letters',
    description: 'Greek Small Letter Alpha',
    altCode: '224',
    unicodeNumber: 'U+03B1',
  },
  {
    group: 'Greek letters',
    description: 'Greek Small Letter Beta',
    altCode: '225',
    unicodeNumber: 'U+03B2',
  },
  {
    group: 'Greek letters',
    description: 'Greek Small Letter Mu',
    altCode: '230 или 0181',
    unicodeNumber: 'U+03BC',
  },
  {
    group: 'Greek letters',
    description: 'Greek Small Letter Pi',
    altCode: '227',
    unicodeNumber: 'U+03C0',
  },
  {
    group: 'Mathematical symbols',
    description: 'Degree Sign',
    altCode: '0176',
    unicodeNumber: 'U+00B0',
  },
  {
    group: 'Mathematical symbols',
    description: 'Multiplication Sign',
    altCode: '0215',
    unicodeNumber: 'U+00D7',
  },
  {
    group: 'Mathematical symbols',
    description: 'Division Sign',
    altCode: '246 или 0247',
    unicodeNumber: 'U+00F7',
  },
  {
    group: 'Mathematical symbols',
    description: 'Plus Sign',
    altCode: '',
    unicodeNumber: 'U+002B',
  },
  {
    group: 'Mathematical symbols',
    description: 'Minus Sign',
    altCode: '',
    unicodeNumber: 'U+2212',
  },
  {
    group: 'Mathematical symbols',
    description: 'Plus minus symbol',
    altCode: '241 или 0177',
    unicodeNumber: 'U+00B1',
  },
  {
    group: 'Mathematical symbols',
    description: 'Less-Than or Equal To',
    altCode: '243',
    unicodeNumber: 'U+2264',
  },
  {
    group: 'Mathematical symbols',
    description: 'Greater-Than or Equal To',
    altCode: '242',
    unicodeNumber: 'U+2265',
  },
  {
    group: 'Mathematical symbols',
    description: 'Less-Than Sign',
    altCode: '',
    unicodeNumber: 'U+003C',
  },
  {
    group: 'Mathematical symbols',
    description: 'Greater-Than Sign',
    altCode: '',
    unicodeNumber: 'U+003E',
  },
  {
    group: 'Mathematical symbols',
    description: 'Not Equal To',
    altCode: '',
    unicodeNumber: 'U+2260',
  },
  {
    group: 'Mathematical symbols',
    description: 'Almost Equal To',
    altCode: '247',
    unicodeNumber: 'U+2248',
  },
  {
    group: 'Mathematical symbols',
    description: 'Superscript Two',
    altCode: '253 или 0178',
    searchQueries: ['2'],
    unicodeNumber: 'U+00B2',
  },
  {
    group: 'Mathematical symbols',
    description: 'Superscript Three',
    altCode: '0179',
    searchQueries: ['3'],
    unicodeNumber: 'U+00B3',
  },
  {
    group: 'Mathematical symbols',
    description: 'Square Root',
    altCode: '251',
    unicodeNumber: 'U+221A',
  },
  {
    group: 'Mathematical symbols',
    description: 'Vulgar Fraction One Quarter',
    altCode: '172 или 0188',
    unicodeNumber: 'U+00BC',
  },
  {
    group: 'Mathematical symbols',
    description: 'Vulgar Fraction One Half',
    altCode: '171 или 0189',
    unicodeNumber: 'U+00BD',
  },
  {
    group: 'Mathematical symbols',
    description: 'Latin Small Letter F with Hook',
    altCode: '159',
    searchQueries: ['function symbol'],
    unicodeNumber: 'U+0192',
  },
  {
    group: 'Mathematical symbols',
    description: 'N-Ary Summation',
    altCode: '228',
    searchQueries: ['summation sign', 'sum'],
    unicodeNumber: 'U+2211',
  },
  {
    group: 'Mathematical symbols',
    description: 'Integral',
    altCode: '',
    unicodeNumber: 'U+222B',
  },
  {
    group: 'Mathematical symbols',
    description: 'Diameter Sign',
    altCode: '0216',
    unicodeNumber: 'U+2300',
  },
  {
    group: 'Mathematical symbols',
    description: 'Empty Set',
    altCode: '',
    searchQueries: ['null set'],
    unicodeNumber: 'U+2205',
  },
  {
    group: 'Mathematical symbols',
    description: 'Angle',
    altCode: '',
    unicodeNumber: 'U+2220',
  },
  {
    group: 'Mathematical symbols',
    description: '" Infinity"',
    altCode: '236',
    unicodeNumber: 'U+221E',
  },
  {
    group: 'Mathematical symbols',
    description: 'Per Mille Sign',
    altCode: '0137',
    unicodeNumber: 'U+2030',
  },
  {
    group: 'Currency',
    description: 'Euro Sign',
    altCode: '0128',
    unicodeNumber: 'U+20AC',
  },
  {
    group: 'Currency',
    description: 'Pound Sign',
    altCode: '156 или 0163',
    unicodeNumber: 'U+00A3',
    searchQueries: ['pound sterling', 'irish punt', 'italian lira', 'turkish lira'],
  },
  {
    group: 'Currency',
    description: 'Yen Sign',
    altCode: '157 или 0165',
    searchQueries: ['yuan'],
    unicodeNumber: 'U+00A5',
  },
  {
    group: 'Currency',
    description: 'Dollar Sign',
    altCode: '36',
    searchQueries: ['milréis', 'escudo'],
    unicodeNumber: 'U+0024',
  },
  {
    group: 'Currency',
    description: 'Cent Sign',
    altCode: '155 или 0162',
    unicodeNumber: 'U+00A2',
  },
  {
    group: 'Currency',
    description: 'Currency Sign',
    altCode: '0164',
    unicodeNumber: 'U+00A4',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit One',
    altCode: '',
    searchQueries: ['1'],
    unicodeNumber: 'U+2460',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Two',
    altCode: '',
    searchQueries: ['2'],
    unicodeNumber: 'U+2461',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Three',
    altCode: '',
    searchQueries: ['3'],
    unicodeNumber: 'U+2462',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Four',
    altCode: '',
    searchQueries: ['4'],
    unicodeNumber: 'U+2463',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Five',
    searchQueries: ['5'],
    altCode: '',
    unicodeNumber: 'U+2464',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Six',
    searchQueries: ['6'],
    altCode: '',
    unicodeNumber: 'U+2465',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Seven',
    searchQueries: ['7'],
    altCode: '',
    unicodeNumber: 'U+2466',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Eight',
    searchQueries: ['8'],
    altCode: '',
    unicodeNumber: 'U+2467',
  },
  {
    group: 'Numbers',
    description: 'Circled Digit Nine',
    searchQueries: ['9'],
    altCode: '',
    unicodeNumber: 'U+2468',
  },
  {
    group: 'Numbers',
    description: 'Feminine Ordinal Indicator',
    altCode: '166',
    unicodeNumber: 'U+00AA',
  },
  {
    group: 'Numbers',
    description: 'Masculine Ordinal Indicator',
    altCode: '167',
    unicodeNumber: 'U+00BA',
  },
  {
    group: 'Arrows',
    description: 'Leftwards Arrow',
    altCode: '27',
    unicodeNumber: 'U+2190',
  },
  {
    group: 'Arrows',
    description: 'Rightwards Arrow',
    altCode: '26',
    unicodeNumber: 'U+2192',
  },
];

export const groupedSpecialSymbols: Map<string, SpecSymbol[]> = specialSymbols.reduce<Map<string, SpecSymbol[]>>(
  (acc, curr) => {
    const group = curr.group;
    if (!acc.has(group)) {
      acc.set(group, []);
    }
    curr.symbol = String.fromCharCode(parseInt(curr.unicodeNumber.slice(2), 16));
    acc.get(group).push(curr);
    return acc;
  },
  new Map<string, SpecSymbol[]>()
);
