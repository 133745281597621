import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

interface AppConfigs {
  reportNamePrefix: string;
  settingsNamePrefix: string;
}

@Injectable({ providedIn: 'root' })
export class AppService {
  // TODO: нужно как-то убрать этот костыль с установкой загрузки глобально и без счетчика.
  public loaded$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(true);
  public lastStartedRoute: string;

  get config(): AppConfigs {
    return {
      reportNamePrefix: 'verifika_report-',
      settingsNamePrefix: 'verifika_settings-',
    };
  }

  public online = false;
  public online$ = new BehaviorSubject<boolean>(true);
  public log = console.log;
  private router: Router;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.subscribeOnOnlineMode();
    this.watchRouteChanges();
  }

  private subscribeOnOnlineMode(): void {
    this.online = navigator.onLine;
    this.online$.next(this.online);
    const onlineEvent$ = fromEvent(window, 'online');
    const offlineEvent$ = fromEvent(window, 'offline');

    merge(onlineEvent$, offlineEvent$).subscribe(() => {
      this.online = navigator.onLine;
      this.online$.next(navigator.onLine);
    });
  }

  private watchRouteChanges(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.lastStartedRoute = event.url;
      }
    });
  }

  public restart(): void {
    window.location.href = '/';
  }
}
