import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '@shared/components/modal';
import { IconModule } from '@shared/components/icon';
import { PipesModule } from '@shared/pipes';
import { FormsModule } from '@angular/forms';
import { LPAuthComponent } from './lp-auth/lp-auth.component';
import { LPChooseFilesComponent } from './lp-choose-files/lp-choose-files.component';
import { SearchListModule } from '@shared/components/search-list/search-list.module';
import { LoaderModule } from '@shared/components/loader';
import { LPChooseFilesModalService } from '@shared/components/localization-platforms/lp-choose-files/lp-choose-files-modal.service';
import { LPAuthModalService } from '@shared/components/localization-platforms/lp-auth/lp-auth-modal.service';
import { SelectModule } from '@shared/components/select-v2';
import { ButtonModule } from '@shared/components/button';
import { ActionIconModule } from '@shared/components/action-icon';
import { TooltipModule } from '@shared/components/tooltip';

@NgModule({
  declarations: [LPAuthComponent, LPChooseFilesComponent],
  imports: [
    CommonModule,
    ModalModule,
    IconModule,
    PipesModule,
    FormsModule,
    SearchListModule,
    LoaderModule,
    SelectModule,
    ButtonModule,
    ActionIconModule,
    TooltipModule,
  ],
  providers: [LPChooseFilesModalService, LPAuthModalService],
})
export class LocalizationPlatformsModule {}
