import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppAnimationsModule } from './app-animations.module';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { ApplicationUpdateService } from './core/services';
import { environment } from 'src/environments/environment';
import { SignInCallbackComponent } from './core/components/sign-in-callback.component';

@NgModule({
  declarations: [AppComponent, SignInCallbackComponent],
  imports: [
    HttpClientModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    AppRoutes,
    AppAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
  ],
  providers: [
    ApplicationUpdateService,
    {
      provide: SwRegistrationOptions,
      useFactory: (): SwRegistrationOptions => ({
        enabled: environment.productionMode,
        registrationStrategy: 'registerWithDelay: 5000',
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
