<app-ui-modal [disableClose]="true">
  <div class="organization-creator" [ngClass]="{ loading: loading }">
    <app-spinner *ngIf="loading; else contentTemplate"> </app-spinner>
  </div>
</app-ui-modal>

<ng-template #contentTemplate>
  <div class="title">
    <app-ui-icon icon="home-work"></app-ui-icon>
    Create organization
  </div>
  <div class="description">
    Give your organization a name and gain access to effective tools for communication and collaboration.
  </div>
  <div class="modal-content">
    <label for="organization-name">Name</label>
    <input class="input" name="organization-name" type="text" [formControl]="organizationNameControl" />
  </div>
  <div class="footer">
    <app-button (clickButton)="cancel()">Cancel</app-button>
    <app-button (clickButton)="createOrganization()" [disabled]="!organizationNameControl.value" theme="primary"
      >Create</app-button
    >
  </div>
</ng-template>
