import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ListCellEditorComponent, MeasurementCellEditorComponent } from '@shared/components/grid-components';
import { BaseSettingsGridComponent } from '@shared/components/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uuid, stringComparator } from '@shared/tools';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfileLocalizedUnit } from '@shared/models';
import { SuppressKeyboardEventParams } from '@ag-grid-community/core/dist/cjs/es5/entities/colDef';
import { AgGridColumn } from 'src/types/ag-grid';
import { CellEditingStoppedEvent, GetQuickFilterTextParams, NewValueParams } from '@ag-grid-community/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-measurement-units',
  templateUrl: './measurement-units.component.html',
  styleUrls: ['./measurement-units.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MeasurementUnitsComponent,
    },
  ],
})
export class MeasurementUnitsComponent extends BaseSettingsGridComponent<ProfileLocalizedUnit> {
  private confirmDeleteModal: MatDialogRef<any>;

  columnDefs: Partial<AgGridColumn>[] = [
    {
      colId: 'id',
      field: 'id',
      hide: true,
      getQuickFilterText: (): string => '',
    },
    {
      sortable: false,
      resizable: false,
      headerName: '',
      width: 36,
      flex: 0,
      editable: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressKeyboardEvent: (params): boolean => this.suppressTab(params),
      checkboxSelection: true,
      headerClass: 'checkbox-header',
      cellClass: 'checkbox-cell',
      getQuickFilterText: (): string => '',
    },
    {
      headerName: 'Full unit name',
      colId: 'description',
      field: 'description',
      editable: true,
      resizable: false,
      flex: 1,
      cellRenderer: 'searchedRenderer',
      cellEditor: 'measureEditor',
      cellRendererParams: {
        searched: (): Observable<string> =>
          this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()),
      },
      comparator: (a: string, b: string): number => stringComparator(a, b),
    },
    {
      headerName: 'Target unit',
      colId: 'values',
      field: 'values',
      editable: true,
      resizable: false,
      flex: 1,
      autoHeight: true,
      wrapText: true,
      cellRenderer: 'multilineRenderer',
      cellEditor: ListCellEditorComponent.rendererName,
      headerClass: 'splitter-none',
      cellRendererParams: {
        searched: (): Observable<string> =>
          this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()),
      },
      comparator: (a: string[] = [], b: string[] = []): number => {
        const valueA = a[0] || '';
        const valueB = b[0] || '';

        return stringComparator(valueA, valueB);
      },
      getQuickFilterText: (params: GetQuickFilterTextParams<ProfileLocalizedUnit>): string => params.value?.join(),
      cellEditorParams: {
        createNewRow: this.add.bind(this),
      },
    },
  ];
  overlayNoRowsText = 'Click Plus button to add a new measurement unit';

  @ViewChild('confirmDeleteModalTemplate', { static: true })
  confirmDeleteModalTemplate: TemplateRef<any>;

  constructor(private dialog: MatDialog) {
    super();
    this.gridComponents.measureEditor = MeasurementCellEditorComponent;
  }

  public add(): void {
    const newItem: ProfileLocalizedUnit = {
      id: uuid(),
      description: '',
      values: [],
    };
    this.gridApi.applyTransaction({
      add: [newItem],
      addIndex: 0,
    });

    this.startEdit(0, 'description');
  }

  public remove(): void {
    this.confirmDeleteModal = this.dialog.open(this.confirmDeleteModalTemplate);
  }

  public confirm(): void {
    const selectedRows = this.getSelected();
    const selectedIds = selectedRows.map(({ id }: ProfileLocalizedUnit) => id);
    this._data = this._data.filter((item) => !selectedIds.includes(item.id));
    this.propagateChanges();

    this.gridApi.applyTransaction({
      remove: selectedRows,
    });
    this.confirmDeleteModal.close();
  }

  public cancel(): void {
    this.confirmDeleteModal.close();
  }

  public onCellChanged(event: NewValueParams): void {
    const existingRow = this._data.some((row) => row.id === event.data.id);
    if (existingRow) {
      this._data = this._data.map((item) => (item.id === event.data.id ? event.data : item));
    } else {
      this._data.unshift(event.data as ProfileLocalizedUnit);
    }
    this.propagateChanges();
  }

  public editStopped(event: CellEditingStoppedEvent<ProfileLocalizedUnit>): void {
    super.editStopped(event);
    // remove new empty row
    if (event.column.getColId() === 'description' && !event.valueChanged && !event.data.description) {
      this.gridApi.applyTransaction({
        remove: [event.data],
      });
    }
  }

  public rowsIsEqual(aItem: ProfileLocalizedUnit, bItem: ProfileLocalizedUnit): boolean {
    if (aItem.id !== bItem.id || aItem.description !== bItem.description) {
      return false;
    }
    return aItem.values?.join() === bItem.values?.join();
  }

  public suppressTab(params: SuppressKeyboardEventParams): boolean {
    const KEY_TAB = 'DOM_VK_TAB';
    return params.event.code === KEY_TAB;
  }
}
