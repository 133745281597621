import { IssueType } from '@generated/api';
import { FileId, TranslationUnitChange } from '@shared/models';

enum ActionTypes {
  LOAD_TRANSLATION_UNIT_CHANGES = '[TranslationUnitChanges] Load TranslationUnit Changes',
  LOAD_TRANSLATION_UNIT_CHANGES_SUCCESS = '[TranslationUnitChanges] Load TranslationUnit Changes Success',
  LOAD_TRANSLATION_UNIT_CHANGES_FAILURE = '[TranslationUnitChanges] Load TranslationUnit Changes Failure',

  ROLLBACK_TRANSLATION_UNIT_CHANGES = '[TranslationUnitChanges] Rollback TranslationUnit Changes',
  ROLLBACK_TRANSLATION_UNIT_CHANGES_SUCCESS = '[TranslationUnitChanges] Rollback TranslationUnit Changes Success',
  ROLLBACK_TRANSLATION_UNIT_CHANGES_FAILURE = '[TranslationUnitChanges] Rollback TranslationUnit Changes Failure',

  LOAD_TRANSLATION_UNIT_CHANGE = '[TranslationUnitChanges] Load TranslationUnit Change',
  ADD_TRANSLATION_UNIT_CHANGES = '[TranslationUnitChanges] Add TranslationUnit Changes',
  REMOVE_TRANSLATION_UNIT_CHANGES = '[TranslationUnitChanges] Remove TranslationUnit Changes',
  REMOVE_TRANSLATION_UNIT_CHANGES_BY_FILE_ID = '[TranslationUnitChanges] Remove TranslationUnit Changes By File Id',
  CLEAR_TRANSLATION_UNI_CHANGES = '[TranslationUnitChanges] Clear Translation Unit Changes',
}

export class LoadTranslationUnitChanges {
  static readonly type = ActionTypes.LOAD_TRANSLATION_UNIT_CHANGES;
  constructor(public projectId: string) {}
}

export class LoadTranslationUnitChangesSuccess {
  static readonly type = ActionTypes.LOAD_TRANSLATION_UNIT_CHANGES_SUCCESS;
  constructor(public translationUnitChanges: TranslationUnitChange[]) {}
}

export class LoadTranslationUnitChangesFail {
  static readonly type = ActionTypes.LOAD_TRANSLATION_UNIT_CHANGES_FAILURE;
  constructor(public error: any) {}
}

export class RollbackTranslationUnitChanges {
  static readonly type = ActionTypes.ROLLBACK_TRANSLATION_UNIT_CHANGES;
  constructor(
    public reportId: string,
    public translationUnitChanges: {
      id: string;
      translationUnitId: string;
      issueType: IssueType;
    }[]
  ) {}
}

export class RollbackTranslationUnitChangesSuccess {
  static readonly type = ActionTypes.ROLLBACK_TRANSLATION_UNIT_CHANGES_SUCCESS;
  constructor() {}
}

export class RollbackTranslationUnitChangesFail {
  static readonly type = ActionTypes.ROLLBACK_TRANSLATION_UNIT_CHANGES_FAILURE;
  constructor(public error: any) {}
}

export class AddTranslationUnitChanges {
  static readonly type = ActionTypes.ADD_TRANSLATION_UNIT_CHANGES;
  constructor(public translationUnitChanges: TranslationUnitChange[]) {}
}

export class RemoveTranslationUnitChanges {
  static readonly type = ActionTypes.REMOVE_TRANSLATION_UNIT_CHANGES;
  constructor(public ids: string[]) {}
}

export class RemoveTranslationUnitChangesByFileId {
  static readonly type = ActionTypes.REMOVE_TRANSLATION_UNIT_CHANGES_BY_FILE_ID;
  constructor(public projectId: string, public fileId: FileId) {}
}

export class LoadTranslationUnitChange {
  static readonly type = ActionTypes.LOAD_TRANSLATION_UNIT_CHANGE;
  constructor(public id: string) {}
}

export class ClearTranslationUniChanges {
  static readonly type = ActionTypes.CLEAR_TRANSLATION_UNI_CHANGES;
}
