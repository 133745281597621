import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

/**
 * Dropdown Menu.
 * Show dropdown with custom label and custom content in dropdown.
 *
 * Usage:
 * ````
 * <app-ui-dropdown-menu>
 *   <span dropdown-label>
 *     Label
 *   </span>
 *   <div dropdown-content>
 *     Content in Dropdown
 *   </ng-template>
 * </app-ui-dropdown-menu>
 * ````
 */

@Component({
  selector: 'app-ui-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent {
  isOpen: boolean = false;

  @Input() disabled: boolean = false;

  @Input() triggerPlacement: 'left' | 'right' = 'right';

  @Input() xPosition: 'before' | 'after' = 'before';

  @Input() yPosition: 'above' | 'below' = 'below';

  @Input() popupClass: string = '';

  @Output() menuOpened = new EventEmitter<void>();

  @Output() menuClosed = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public open(): void {
    if (this.disabled) {
      return;
    }
    this.trigger.openMenu();
  }

  public close(): void {
    this.trigger.closeMenu();
  }

  public toggle(): void {
    this.isOpen = this.trigger.menuOpen;
  }

  public opened(): void {
    this.isOpen = this.trigger.menuOpen;
    this.menuOpened.emit();
  }

  public closed(): void {
    this.isOpen = this.trigger.menuOpen;
    this.menuClosed.emit();
  }
}
