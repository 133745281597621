import { SelectionRange } from '@shared/models';
import { SuggestedWordForm } from '../terms';
import { RenderItem } from '@shared/models';
import { IssueType, QualityIssueModel as RawQualityIssue, TermModel, TranslationUnitModel } from '@generated/api';

export enum SelectionType {
  Source = 0,
  Target = 1,
}

export interface AdditionalDataUnitsSelection {
  checkedSelections: SelectionRange[];
  selectionType: SelectionType;
  checkedTranslationUnit: TranslationUnitModel;
  checkedTranslationUnitId: string;
}

interface BaseQualityIssueAdditionalData {
  issueType: IssueType;
}

export interface ConsistencyQualityIssueAdditionalData extends BaseQualityIssueAdditionalData {
  unitsSelection: AdditionalDataUnitsSelection[];
}
export interface TerminologyQualityIssueAdditionalData extends BaseQualityIssueAdditionalData {
  term: TermModel;
  potentialWordForm: SuggestedWordForm;
}

export interface SpellingQualityIssueAdditionalData extends BaseQualityIssueAdditionalData {
  suggestions: string[];
  word: string;
}

export type QualityIssueAdditionalData =
  | ConsistencyQualityIssueAdditionalData
  | TerminologyQualityIssueAdditionalData
  | SpellingQualityIssueAdditionalData;

export { RawQualityIssue };

export interface QualityIssue<T = QualityIssueAdditionalData>
  extends Omit<RawQualityIssue, 'sourceRanges' | 'targetRanges'> {
  // TODO: оставить только необходимые свойства, перед этим нужно изучить редактор
  sourceRenderItems: RenderItem[];
  targetRenderItems: RenderItem[];
  match: string;
  additionalData: T;

  // Consistency
  identicalRows?: QualityIssue[];
  subGroup?: string;
  sourceRanges: SelectionRange[];
  targetRanges: SelectionRange[];
  issueKind: string;

  isExpatendedIdenticalRows?: boolean;
  parentTranslationUnitId?: string;
  // Used for correct detection of 'apply' possibility inside ag grid
  hasProtectedNeighbors?: boolean;
  hasTagedNeighbors?: boolean;
}
