import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IssueTypeNames } from '@shared/models';
import { TranslationUnitChange } from '@shared/models/translation-unit-change';
import { RenderItemBuilder } from '@shared/services';
import { getMatchForRow } from '@report/utils';
import { TranslationUnitChangeModel } from '@generated/api';

@Injectable()
export class TranslationUnitChangeMapper {
  constructor(private renderItemBuilder: RenderItemBuilder, private store: Store) {}

  public mapTranslationUnitChanges(rawTranslationChanges: TranslationUnitChangeModel[]): TranslationUnitChange[] {
    return rawTranslationChanges.map((c) => this.mapTranslationUnitChange(c));
  }

  public mapTranslationUnitChange(rawTranslationChange: TranslationUnitChangeModel): TranslationUnitChange {
    const createdOn = new Date(rawTranslationChange.createdOn).toISOString();
    return {
      ...rawTranslationChange,
      createdOn,
      issueTypeName: IssueTypeNames[rawTranslationChange.issueType],
      translationUnit: rawTranslationChange.translationUnit,
      sourceRenderItems: this.renderItemBuilder.build([], rawTranslationChange.translationUnit.source.elements),
      targetRenderItems: this.renderItemBuilder.build(
        rawTranslationChange.originalTargetRanges || [],
        rawTranslationChange.originalTargetSegment.elements
      ),
      changedRenderItems: this.renderItemBuilder.build(
        rawTranslationChange.changedTargetRanges || [],
        rawTranslationChange.translationUnit.target.elements
      ),
      match: getMatchForRow(rawTranslationChange.translationUnit.properties),
    };
  }
}
