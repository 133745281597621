import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@shared/directives';
import { ReportSettingsBtnModule } from '@shared/layout/header/report-settings-btn/report-settings-btn.module';
import { PipesModule } from '@shared/pipes';
import { ActionIconModule } from '../action-icon';
import { ButtonModule } from '../button';
import { DropdownModule } from '../dropdown';
import { DropdownMenuModule } from '../dropdown-menu';
import { IconModule } from '../icon';
import { OrganizationsManagerModule } from '../organisation-manager/organizations-manager.module';
import { ShortcutsHelperBtnModule } from '../shortcuts-helper-btn/shortcuts-helper-btn.module';
import { TooltipModule } from '../tooltip';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    IconModule,
    ActionIconModule,
    ButtonModule,
    OrganizationsManagerModule,
    DropdownModule,
    ShortcutsHelperBtnModule,
    PipesModule,
    ReportSettingsBtnModule,
    DropdownMenuModule,
    DirectivesModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
