import { Inject, Injectable } from '@angular/core';
import {
  LOG_APPENDER_CONFIG_TOKEN,
  LogAppender,
  LogAppenderConfig,
} from '../../log-appender';
import { LogLevel } from '../../log-level.enum';

@Injectable()
export class ConsoleService implements LogAppender {
  constructor(
    @Inject(LOG_APPENDER_CONFIG_TOKEN) public config: LogAppenderConfig
  ) {}

  log(level: LogLevel, message: any, ...optionalParams: any[]) {
    switch (level) {
      case LogLevel.FATAL:
      case LogLevel.ERROR:
        console.error('CONSOLE LOGGER:', `[${level}]`, message, ...optionalParams);
        break;
      case LogLevel.WARN:
        console.warn('CONSOLE LOGGER:', `[${level}]`, message, ...optionalParams);
        break;
      case LogLevel.INFO:
        console.log('CONSOLE LOGGER:', `[${level}]`, message, ...optionalParams);
        break;
      case LogLevel.DEBUG:
        console.log('CONSOLE LOGGER:', `[${level}]`, message, ...optionalParams);
        break;
    }
  }
}
