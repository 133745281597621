import { StateRepository } from '@angular-ru/ngxs/decorators';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddGroupsOrderState,
  ChangeSelectedCommentThreadId,
  SetGroupsCollapsedState,
  SetGroupsOrderState,
  SetReportDefaultReportView,
  SetReportTab,
  SetSpellingPopupShown,
  ToggleCollapsedAllGroups,
} from './report-view.actions';
import { ReportTab } from '@shared/models';
import { deepClone, deepEqual, getUnique } from '@shared/tools';
import { patch } from '@ngxs/store/operators';
import { ProjectState } from '@store/project-store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

interface ProjectReportView {
  /**
   * Current Report Tab (just for tracking, if you change it - nothing will happens)
   */
  reportTab: ReportTab;

  /**
   * Report groups collapsed/expanded state
   */
  groupsCollapsedState: {
    [report in ReportTab]?: Record<string, boolean>;
  };

  /**
   * Report groups order state
   */
  groupsOrderState: {
    [report in ReportTab]?: Record<string, number>;
  };

  /**
   * Show "Report has unsupported languages" popup only once
   */
  spellingPopupShown: boolean;

  selectedCommentThreadId: string;
}

type ReportViewStateModel = {
  [projectId: string]: ProjectReportView;
};

const defaultReportView: ProjectReportView = {
  reportTab: null,
  spellingPopupShown: false,
  groupsCollapsedState: {
    formal: {},
    consistency: {},
    terminology: {},
    spelling: {},
    custom: {},
  },
  groupsOrderState: {
    formal: {},
    consistency: {},
    terminology: {},
    spelling: {},
    custom: {},
  },
  selectedCommentThreadId: null,
};

@AsyncStorage
@StateRepository()
@State<ReportViewStateModel>({
  name: 'reportView',
  defaults: {},
})
@Injectable()
export class ReportViewState {
  constructor(private store: Store) {}

  @Selector([ReportViewState, ProjectState.projectId])
  public static currentReportView(state: ReportViewStateModel, projectId: string): ProjectReportView {
    return state && state[projectId];
  }

  @Selector([ReportViewState.currentReportView])
  public static reportTab(state: ProjectReportView): ReportTab {
    return state?.reportTab;
  }

  @Selector([ReportViewState.currentReportView])
  public static currentGridGroupsCollapsedState(state: ProjectReportView): Record<string, boolean> {
    return state?.groupsCollapsedState?.[state.reportTab] || {};
  }

  @Selector([ReportViewState.currentReportView])
  public static spellingPopupShown(state: ProjectReportView): boolean {
    return !!state?.spellingPopupShown;
  }

  @Selector([ReportViewState.currentReportView])
  public static groupsOrderState(state: ProjectReportView): Record<string, number> {
    return state?.groupsOrderState?.[state.reportTab] || {};
  }

  @Selector([ReportViewState.currentReportView])
  public static threadId(state: ProjectReportView): string {
    return state?.selectedCommentThreadId;
  }

  @Action(SetReportDefaultReportView)
  public setReportDefaultReportView(ctx: StateContext<ReportViewStateModel>, action: SetReportDefaultReportView): void {
    ctx.patchState({
      [action.projectId]: defaultReportView,
    });
  }

  @Action(SetReportTab)
  public setReportTab(ctx: StateContext<ReportViewStateModel>, { payload }: SetReportTab): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [currentProjectId]: patch({
          reportTab: payload,
        }),
      })
    );
  }

  @Action(SetGroupsCollapsedState)
  public setGroupsCollapsedState(ctx: StateContext<ReportViewStateModel>, { payload }: SetGroupsCollapsedState): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState();
    const reportTab = state[currentProjectId]?.reportTab;

    if (!reportTab) {
      return;
    }

    const oldStates = state[currentProjectId]?.groupsCollapsedState?.[reportTab];

    // Stop circular updates
    if (deepEqual(oldStates, payload)) {
      return;
    }

    const newStates: Record<string, boolean> = {
      ...(oldStates ? oldStates : {}),
      ...(payload ? payload : {}),
    };

    ctx.setState(
      patch({
        [currentProjectId]: patch({
          groupsCollapsedState: patch({
            [reportTab]: newStates,
          }),
        }),
      })
    );
  }

  @Action(ToggleCollapsedAllGroups)
  public toggleCollapsedAllGroups(ctx: StateContext<ReportViewStateModel>, action: ToggleCollapsedAllGroups): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState()[currentProjectId];
    const reportTab = state.reportTab;
    const updatedState: Record<string, boolean> = {};
    Object.keys(state.groupsCollapsedState?.[reportTab] || {}).forEach((key) => {
      updatedState[key] = action.newState;
    });

    ctx.setState(
      patch({
        [currentProjectId]: patch({
          groupsCollapsedState: patch({
            [reportTab]: updatedState || [],
          }),
        }),
      })
    );
  }

  @Action(SetSpellingPopupShown)
  public setSpellingPopupShown(ctx: StateContext<ReportViewStateModel>, action: SetSpellingPopupShown): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    const isCurrentViewExist = !!ctx.getState()[currentProjectId];
    if (!isCurrentViewExist) {
      return;
    }

    ctx.setState(
      patch({
        [currentProjectId]: patch({
          spellingPopupShown: action.payload,
        }),
      })
    );
  }

  @Action(SetGroupsOrderState)
  public setGroupsOrderState(ctx: StateContext<ReportViewStateModel>, action: SetGroupsOrderState): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [currentProjectId]: patch({
          groupsOrderState: patch({
            [action.reportTab]: action.order,
          }),
        }),
      })
    );
  }

  @Action(AddGroupsOrderState)
  public AddGroupsOrderState(ctx: StateContext<ReportViewStateModel>, action: AddGroupsOrderState): void {
    if (!action.payload.length) {
      return;
    }
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    const currentTab = this.store.selectSnapshot(ReportViewState.reportTab);
    const currentOrder: Record<string, number> = this.store.selectSnapshot(ReportViewState.groupsOrderState);
    const uniqueKeys = getUnique(action.payload).filter((key) => currentOrder[key] === undefined);
    if (!uniqueKeys.length) {
      return;
    }

    const newOrder = deepClone(currentOrder);
    let length = Object.keys(currentOrder).length;
    uniqueKeys.forEach((key) => {
      newOrder[key] = length;
      length++;
    });

    ctx.setState(
      patch({
        [currentProjectId]: patch({
          groupsOrderState: patch({
            [currentTab]: newOrder,
          }),
        }),
      })
    );
  }

  @Action(ChangeSelectedCommentThreadId)
  public focusTranslationUnit(ctx: StateContext<ReportViewStateModel>, action: ChangeSelectedCommentThreadId): void {
    const currentProjectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [currentProjectId]: patch({
          selectedCommentThreadId: action.threadId,
        }),
      })
    );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<ReportViewStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
