import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { AdDirective } from './ad.directive';
import type { ComponentProperties, OverlayProperties } from './interfaces';
import { ContainerProperties } from './interfaces';
import { concat, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddRemoveAnimation } from './animation';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay-container.component.html',
  styleUrls: ['./overlay-container.component.scss'],
  animations: [AddRemoveAnimation],
})
export class OverlayContainerComponent<T> implements OnInit, OnDestroy, ContainerProperties {
  private destroyed$ = new Subject<void>();
  public componentRef: ComponentRef<T>;

  @ViewChild(AdDirective, { static: true }) adHost: AdDirective;

  @HostBinding('@addRemoveAnimation') animation = true;
  @HostBinding('@.disabled') get animationsDisabled(): boolean {
    return this.properties.animationDisable || false;
  }

  @Input() properties: OverlayProperties;
  @Output() overlayClose = new EventEmitter<void>();
  @Output() overlayMouseIn = new EventEmitter<void>();
  @Output() overlayMouseOut = new EventEmitter<void>();

  @HostBinding('style.width')
  private get hostWidth(): OverlayProperties['wrapperWidth'] {
    return this.properties.wrapperWidth;
  }

  @HostBinding('style.height')
  private get hostHeight(): OverlayProperties['wrapperHeight'] {
    return this.properties.wrapperHeight;
  }

  @HostListener('document:click', ['$event'])
  public onClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.hideOnClick !== false) {
        this.overlayClose.emit();
      }
    }
  }

  private get popoverClass(): any {
    if (this.properties.metadata) {
      return this.properties.metadata.popoverClass;
    }
  }

  get hideOnClick(): boolean {
    if (this.properties.metadata) {
      return this.properties.metadata.hideOnClick;
    }
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver, public elementRef: ElementRef) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadComponent(): void {
    const adItem = this.properties;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.mainComponent as Type<any>);
    const viewContainerRef = this.adHost.viewContainerRef;

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const componentRefElement = (componentRef.location as ComponentProperties).nativeElement;

    // Set styles
    componentRefElement.style.width = this.properties.width;
    componentRefElement.style.height = this.properties.height;
    componentRefElement.style.maxWidth = this.properties.maxWidth;
    componentRefElement.style.minWidth = this.properties.minWidth;

    // Set classes
    if (typeof this.popoverClass === 'string') {
      componentRefElement.classList.add(this.popoverClass);
    }
    if (typeof this.popoverClass === 'object') {
      this.popoverClass.forEach((className) => {
        componentRefElement.classList.add(className);
      });
    }

    // Pass properties
    (componentRef.instance as ComponentProperties).overlayProperties = this.properties;

    this.componentRef = componentRef;
    this.addEventListener();
  }

  private addEventListener(): void {
    const element: HTMLElement = this.componentRef.location.nativeElement;
    concat(fromEvent(element, 'mouseenter'), fromEvent(element, 'focusin'))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.overlayContentMouseIn());

    concat(fromEvent(element, 'mouseleave'), fromEvent(element, 'focusout'))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.overlayContentMouseOut());
  }

  private overlayContentMouseIn = (): void => {
    this.overlayMouseIn.emit();
  };

  private overlayContentMouseOut = (): void => {
    this.overlayMouseOut.emit();
  };
}
