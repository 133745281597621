import { TranslationUnitModel } from '@generated/api';
import { CommentCellRendererComponent } from '@shared/components/grid-components/comment-cell-renderer/comment-cell-renderer.component';
import { FilterCommentComponent } from '@shared/components/grid-filters';
import { AgGridColumn, GridColumnParams } from 'src/types/ag-grid';

type ColumnDepentOnTranslationUnit = GridColumnParams<{
  translationUnit: TranslationUnitModel;
}>;

export const COMMENT_COLUMN: Partial<AgGridColumn> = {
  headerName: 'Comment',
  colId: 'comment',
  sortable: false,
  cellRenderer: CommentCellRendererComponent.rendererName,
  cellClass: ['cell-d-flex-center'],
  headerComponentParams: {
    iconGetter: (filterValue) => {
      if (filterValue && !filterValue.value) {
        return { name: 'chat', color: '#d3d7db' };
      }
      return { name: 'chat', color: 'gray' };
    },
  },
  filter: FilterCommentComponent,
  maxWidth: 40,
  headerTooltip: 'Comment filter',
  tooltipComponent: 'gridTooltip',
  valueGetter: (params: ColumnDepentOnTranslationUnit) => params.data?.translationUnit?.hasComments,
};
