import * as CodeMirror from 'codemirror';
import { SelectionRange, TagSegmentElement, TagType } from '@shared/models';
import { TagMarkerAdditionalInfo, TagWithStatus } from '@shared/components/segment-editor/models';
import { tagHasPair } from '@shared/tools/tag-segment-element-extensions';
import { getSelectionRangesTotalLength, intersectSelectionRanges, normalizeSelectionRanges } from '@shared/tools';

const SpecialCharClasses: { [key: string]: any } = {
  20: { className: 'cm-whitespace', content: '\u0020' },
  a0: { className: 'cm-no-break-space', content: '\u00A0' },
};

export const DefaultSpecialChars = /(\u0020|\u00A0|\r)/gm;
export const EmptySpecialChars = new RegExp(/\t/);

export function specialCharPlaceholder(ch) {
  const charCode = ch.charCodeAt(0).toString(16);
  const obj = SpecialCharClasses[charCode];
  if (obj) {
    const token = elt('span', obj.content, obj.className);
    token.title = '\\u' + charCode;
    token.setAttribute('aria-label', token.title);
    return token;
  } else {
    return ch;
  }
}

export function elt(tag, content, className, style = null) {
  const e = document.createElement(tag);
  if (className) {
    e.className = className;
  }
  if (style) {
    e.style.cssText = style;
  }
  if (typeof content === 'string') {
    e.appendChild(document.createTextNode(content));
  } else if (content) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < content.length; ++i) {
      e.appendChild(content[i]);
    }
  }
  return e;
}

export function getTagsNumber(tags: TagSegmentElement[]): number[] {
  let currentNumber = 1;
  const stack = [];
  const result = [];
  tags.forEach((tag) => {
    if (tag.tagType === TagType.Standalone) {
      result.push(currentNumber++);
    } else if (tag.tagType === TagType.Start) {
      result.push(currentNumber);
      stack.push(currentNumber);
      currentNumber++;
    } else {
      result.push(stack.pop());
    }
  });
  return result;
}

export function hasHighlight(selections: SelectionRange[], startPos: number, endPos: number): boolean {
  return selections.some((s) => startPos < s.end && endPos > s.start);
}

export function checkTagStatus(tags: TagSegmentElement[], selectionRanges: SelectionRange[]): TagWithStatus[] {
  return tags.map((tag): TagWithStatus => {
    const intersectingSelectionRanges = intersectSelectionRanges(selectionRanges, tag);
    // Нормализуем диапазон выделений, сбрасываем на ноль
    const elementSelectionRanges = normalizeSelectionRanges(intersectingSelectionRanges, tag.start, 0, tag.length);
    const fullTagHighlighted = getSelectionRangesTotalLength(elementSelectionRanges) === tag.length;
    return {
      tag,
      selectionRanges: elementSelectionRanges,
      fullTagHighlighted,
      withoutPair: tag.tagType !== TagType.Standalone && !tagHasPair(tags, tag),
    };
  });
}

export function getMarkerAdditionalInfo(marker: CodeMirror.TextMarker): TagMarkerAdditionalInfo {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return marker.additionalInfo;
}

export function getCoords(e: MouseEvent | DragEvent) {
  return {
    left: e.clientX,
    top: e.clientY + window.pageYOffset || (document.documentElement || document.body).scrollTop,
  };
}

/**
 *  if aPosition greater then bPosition return 1;
 *  if bPosition greater then aPosition return -1;
 *  if Positions equal return 0;
 */
export function comparisonOfPosition(aPosition: CodeMirror.Position, bPosition: CodeMirror.Position): number {
  if (aPosition.line === bPosition.line && aPosition.ch === bPosition.ch) {
    return 0;
  }
  if (aPosition.line > bPosition.line || (aPosition.line === bPosition.line && aPosition.ch > bPosition.ch)) {
    return 1;
  }
  return -1;
}

export function addToPosition(targetPosition: CodeMirror.Position, offset: number): CodeMirror.Position {
  return {
    ...targetPosition,
    ch: targetPosition.ch + offset,
  };
}
