import { Injectable } from '@angular/core';
import { SearchType } from '@shared/components/search-pattern/search-type';
import { PowerSearchValidator } from '@shared/components/search-pattern/power-search-validator';
import { SearchOptions } from '@generated/api/models';

@Injectable()
export class SearchPatternValidatorService {
  private powerSearchValidator = new PowerSearchValidator();

  validate(value: SearchOptions): boolean {
    if (value && value.pattern) {
      if (value.searchType === SearchType.Regex) {
        return this.validateRegex(value.pattern);
      } else if (value.searchType === SearchType.PowerSyntax) {
        return this.powerSearchValidator.check(value.pattern);
      }
    }
    return true;
  }

  private validateRegex(pattern: string) {
    try {
      new RegExp(pattern);
      return true;
    } catch (e) {
      return false;
    }
  }
}
