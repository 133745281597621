import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TooltipService } from './tooltip.service';
import { BaseTooltipTrigger } from '@shared/components/tooltip/base-tooltip-trigger';
import { TooltipProperties } from '@shared/components/tooltip/interfaces';
import { TooltipElementDirective } from './tooltip-element.directive';

@Directive({
  selector: '[appTooltipTriggerFor], [appTooltip]',
  exportAs: 'tooltip',
  providers: [TooltipService],
})
export class TooltipTriggerDirective extends BaseTooltipTrigger {
  @Input() appTooltipTriggerFor: TooltipElementDirective;

  @Input() set appTooltip(text: string) {
    this.tooltipText = text;
  }

  constructor(protected elementRef: ElementRef, public tooltipService: TooltipService) {
    super();
  }

  @HostListener('click', ['$event'])
  public onClick(event): void {
    if (this.appTooltipTrigger !== 'click' || !this.appTooltipDisplay) {
      return;
    }

    this.load(event);
  }

  @HostListener('focusin', ['$event'])
  @HostListener('mouseenter', ['$event'])
  public onMouseEnter(event): void {
    if (this.appTooltipTrigger !== 'hover' || !this.appTooltipDisplay) {
      return;
    }
    this.load(event);
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.appTooltipTrigger !== 'hover') {
      return;
    }

    this.tooltipService.hideWithDelay();
  }

  protected getTooltipProperties(): TooltipProperties {
    return {
      ...super.getTooltipProperties(),
      element: this.getTooltipElement(),
    };
  }

  private getTooltipElement(): HTMLElement {
    if (typeof this.appTooltip === 'string') {
      return undefined;
    }

    return this.appTooltipTriggerFor?.elementRef?.nativeElement;
  }

  public show(message: string = ''): void {
    if (message) {
      this.appTooltip = message;
    }

    this.load({
      srcElement: this.elementRef.nativeElement,
      target: this.elementRef.nativeElement,
      type: 'Click',
    });
  }

  public hide(): void {
    this.tooltipService.hide();
  }
}
