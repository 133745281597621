import { Component, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { SidebarOptions, SidebarService } from '@shared/components/sidebar/sidebar.service';
import { take } from 'rxjs';
import { ReportSettingsComponent } from '@shared/layout/header/report-settings';

@Component({
  selector: 'app-report-settings-btn',
  templateUrl: './report-settings-btn.component.html',
  styleUrls: ['./report-settings-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsBtnComponent implements AfterViewInit {
  public opened = false;
  private sidebarOptions: SidebarOptions;

  constructor(private readonly sidebarService: SidebarService, private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.initSidebarOptions();
  }

  public toggleSideBar(): void {
    this.opened = !this.opened;
    const sidebarComponent = this.sidebarService.toggle(this.sidebarOptions);
    sidebarComponent?.instance.closed.pipe(take(1)).subscribe(() => {
      this.opened = false;
      this.cdr.markForCheck();
    });
  }

  private initSidebarOptions(): void {
    this.sidebarOptions = {
      title: 'Report settings',
      component: ReportSettingsComponent,
    };
  }
}
