import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { regExpEscape } from '@shared/tools';
import { IPageInfo, VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { SpinnerSize } from '../loader';

export interface PageInfo {
  page: number;
  pageSize: number;
}

export interface LoadDataQuery {
  pagination?: PageInfo;
  searchQuery?: string;
}

/**
 * Component for rendering big lists, with highlight
 * and selection support. It could be used as paginatable list.
 *
 * @example
 * <app-search-list
 *  [enableHighlight]="true"
 *  [items]="[{ label: "hi", id: 2 }, { label: "world", id: 4 }]"
 *  selectMode="multiple"
 *  nameKey="label"
 * ></app-search-list>
 */
@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  /** The minimal item rendered item height */
  @Input() itemSize = 32;
  @Input() listHeight: number = 200;
  @Input() placeholder: string;
  @Input() enableHighlight = true;
  @Input() selectMode: 'multiple' | 'single' = 'single';
  @Input() selectedItem: any; // Selected item for single selection only
  @Input() selecAllText: string = 'Select all';
  @Input() notFoundText = 'No matches';
  /** Template with a message when the list is empty */
  @Input() emptyListTemplate: TemplateRef<any>;
  /**  When search control is provided, external search will be used */
  @Input() searchControl: FormControl;
  @Input() searchEnabled: boolean = true;
  @Input() itemTemplate: TemplateRef<any>;
  /** Count of recrods below and above the viewport */
  @Input() pageSize = 10;
  /** The total count of items, need for paginatable lists */
  @Input() totalItems: number;
  /**
   * Milliseconds to delay refreshing viewport if user is scrolling quickly
   * (for performance reasons).
   */
  @Input() scrollDebounceTime = 50;
  @Input() private selectedItems: any[];
  /** Item index to scroll to */
  @Input() initialVisibleItemIndex: number;

  /** Server side search will be used when provided */
  @Input() externalSearch: boolean = false;

  private _items: any[] = [];
  @Input() set items(value: any[]) {
    this._items = value;
    if (this.searchText) {
      this.filterItems();
    } else {
      this.resetSearch();
    }
  }
  public get items(): any[] {
    return this._items;
  }
  @Input() nameKey: string;
  /** If provided will be used for indentify selected items */
  @Input() idKey: string;

  // NOTE: props for paginatable list
  /** Status is the list loading now? */
  @Input() loading = false;
  /** Total count of items */
  @Input() maxPage: number;

  @Output()
  public loadData: EventEmitter<LoadDataQuery> = new EventEmitter<LoadDataQuery>();

  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  get uniqueKey(): string | number {
    return this.idKey || this.nameKey;
  }

  get isAllSelected(): boolean {
    return Object.values(this.selectedItemsMap).filter((i) => !!i).length === this.items.length;
  }

  get someItemSelected(): boolean {
    return Object.values(this.selectedItemsMap).some((i) => !!i);
  }

  // Initial search text
  private _searchText: string = '';
  @Input()
  set searchText(text: string) {
    this._searchText = text || '';
    if (!text) {
      this.resetSearch();
      return;
    }
    this.filterItems();
  }

  get searchText(): string {
    return this._searchText;
  }

  @Output()
  public selected: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();

  public filteredItems: any[];

  // Map for O(1) read access
  public selectedItemsMap: Record<string, any> = {};
  public spinnerSizes = SpinnerSize;

  private destroyed$: Subject<void> = new Subject<void>();
  private loadedPage: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const selectedItemsChanged = changes.selectedItems?.previousValue !== changes.selectedItems?.currentValue;
    const idKeyChanged = changes.idKey?.previousValue !== changes.idKey?.currentValue;
    const nameIsKeyAndChanged = !this.idKey && changes.nameKey?.previousValue !== changes.nameKey?.currentValue;

    const shouldRefindSelectedItems = selectedItemsChanged || idKeyChanged || nameIsKeyAndChanged;

    if (shouldRefindSelectedItems) {
      this.optimizeSelectedItems();
    }
  }

  ngAfterViewInit() {
    this.maybeScrollToItem();
  }

  private maybeScrollToItem(): void {
    if (this.initialVisibleItemIndex == null) {
      return;
    }
    this.virtualScroller.scrollToIndex(this.initialVisibleItemIndex);
  }

  private optimizeSelectedItems(): void {
    if (!this.uniqueKey) {
      return;
    }
    if (!this.selectedItems?.length) {
      this.selectedItemsMap = {};
      return;
    }
    this.selectedItemsMap = this.selectedItems.reduce((acc, v) => {
      acc[v[this.uniqueKey]] = true;
      return acc;
    }, {});
  }

  ngOnInit(): void {
    this.initSearchControl();
    this.subscribeOnSearchInput();
  }

  private initSearchControl(): void {
    this.searchControl ??= new FormControl(this.searchText);
  }

  private subscribeOnSearchInput(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(50), distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((v) => {
        this.searchText = v;
        if (this.externalSearch) {
          this.resetLoadedPages();
          this.loadData.emit({ searchQuery: this.searchText });
          return;
        }
      });
  }

  private resetSearch(): void {
    this.filteredItems = [...this.items];
    this.cdr.markForCheck();
  }

  private filterItems(): void {
    this.filteredItems = this.items.filter((i) =>
      i[this.nameKey].toLocaleLowerCase().match(new RegExp(regExpEscape(this.searchText.toLocaleLowerCase())))
    );
    this.cdr.markForCheck();
  }

  public toggleAllSelection(): void {
    if (!this.isAllSelected) {
      this.selectedItemsMap = this.items.reduce((acc, v) => {
        acc[v[this.uniqueKey]] = true;
        return acc;
      }, {});
    } else {
      this.selectedItemsMap = Object.keys(this.selectedItemsMap).reduce((acc, k) => {
        acc[k] = false;
        return acc;
      }, {});
    }
    this.selectionChanged();
  }

  public onScrolledEnd(page: IPageInfo): void {
    const updateLimitReached = page.endIndex > this.items.length - this.pageSize;
    const maximumItemsReached = this.items.length >= this.totalItems;
    const pageAlreadyLoaded = this.loadedPage >= this.getNextPage();

    if (!pageAlreadyLoaded && !maximumItemsReached && updateLimitReached) {
      this.loadedPage = this.getNextPage();
      const loadDataQuery: LoadDataQuery = {
        pagination: {
          page: this.loadedPage,
          pageSize: this.pageSize,
        },
      };
      if (this.externalSearch && this.searchControl.value) {
        loadDataQuery.searchQuery = this.searchControl.value;
      }
      this.loadData.emit(loadDataQuery);
    }
  }

  private getNextPage(): number {
    return Math.ceil(this.items.length / this.pageSize) + 1;
  }

  public selectItem(item: any, matCheck?: { checked: boolean }): void {
    if (this.selectMode === 'single') {
      if (this.selectedItem && this.selectedItem[this.uniqueKey] === item[this.uniqueKey]) {
        return;
      }
      this.selectedItem = item;
    } else {
      if (this.selectedItemsMap[item[this.uniqueKey]] === matCheck?.checked) {
        return;
      }
      this.selectedItemsMap[item[this.uniqueKey]] = matCheck?.checked;
    }
    this.cdr.detectChanges();
    this.selectionChanged();
  }

  private selectionChanged(): void {
    if (this.selectMode === 'single') {
      this.selected.emit(this.selectedItem);
      return;
    }
    this.selected.emit(this.items.filter((i) => this.selectedItemsMap[i[this.uniqueKey]]));
  }

  private resetLoadedPages(): void {
    this.loadedPage = 0;
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
