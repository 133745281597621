import { ErrorLayoutComponent } from '@shared/layout/error-layout/error-layout.component';
import { NamedRoute } from '@shared/models/routes';

export const PROJECTS_ROUTE: NamedRoute = {
  path: 'projects/:projectId',
  name: 'projects',
  match: (url: string) => url.startsWith('/projects'),
  children: [
    {
      path: '',
      loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
    },
  ],
};

export const ORGANIZATION_DETAIL_ROUTE: NamedRoute = {
  path: 'organization',
  name: 'organizationDetail',
  navigateUrl: () => '/organization',
  loadChildren: () =>
    import('./organization-detail/organization-detail.module').then((m) => m.OrganizationDetailModule),
};

export const SETTINGS_ROUTE: NamedRoute = {
  path: 'settings',
  name: 'settings',
  navigateUrl: () => 'settings',
  children: [
    {
      path: '',
      loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    },
  ],
};

export const ERRORS_ROUTE: NamedRoute = {
  path: 'errors',
  component: ErrorLayoutComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('./core/modules/errors/errors.module').then((m) => m.ErrorsModule),
    },
  ],
};
