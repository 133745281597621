import { Component, HostBinding, TemplateRef } from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';
import { INoRowsOverlayAngularComp, INoRowsOverlayParams } from 'src/types/ag-grid';

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './no-rows-overlay.component.html',
  styleUrls: ['./no-rows-overlay.component.scss'],
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  static rendererName = 'noRowsOverlayRenderer';
  // TODO: Не нужно вызывать функции в темплейтах
  // наследие initial коммита file:/src/app/shared/components/wordforms/wordforms/wordforms-grid/wordforms-grid.component.ts:81
  // переделать передачу параметров в overlay
  public noRowsMessage: string | (() => string);
  public noRowsTemplate: TemplateRef<any>;
  public notFoundTemplate: TemplateRef<any>;
  public notFoundMessage: string;
  public filtersHideRows: boolean;
  public imageUrl?: string;
  public prefixIcon: IconPayload;
  public notFound: boolean;

  @HostBinding('class') get class(): string {
    return !this.notFound ? 'without-header' : '';
  }

  public agInit(params: INoRowsOverlayParams): void {
    this.notFound = params.notFound;
    this.noRowsMessage = params.noRowsMessage;
    this.noRowsTemplate = params.noRowsTemplate;
    this.notFoundMessage = params.notFoundMessage;
    this.imageUrl = params.imageUrl;
    this.prefixIcon = params.prefixIcon;
    this.notFoundTemplate = params.notFoundTemplate;
    const gridApi = params.api;
    this.filtersHideRows = !gridApi.getModel().isEmpty();
  }

  get isNoRowsFunction(): boolean {
    return typeof this.noRowsMessage === 'function';
  }
}
