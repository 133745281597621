import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class AuthStorage implements OAuthStorage {
  private readonly nonceKey = 'nonce';

  public getItem(key: string): string {
    const val = this.storageByKey(key).getItem(key);
    if (key === this.nonceKey) {
      console.log('✎: [line 10][auth.storage.ts] GET NONCE: ', val);
    }
    return val;
  }

  public clear(): void {
    localStorage.clear();
  }

  public setItem(key: string, value: string): void {
    if (key === this.nonceKey) {
      console.log('✎: [line 10][auth.storage.ts] SET NONCE: ', key, value);
    }
    return this.storageByKey(key).setItem(key, value);
  }

  public removeItem(key: string): void {
    return this.storageByKey(key).removeItem(key);
  }

  private storageByKey(key: string): OAuthStorage {
    if (key === this.nonceKey) {
      return sessionStorage;
    }
    return localStorage;
  }
}
