import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { FileId, PreparedTranslationUnit } from '@shared/models';
import {
  LoaderTranslationUnits,
  LoadTranslationUnits,
  SetLoadErrorTranslationUnits,
  SetTranslationUnits,
} from '@store/translation-units-store/translation-units.action';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { TranslationUnitMapper } from '@shared/mappers/translation-unit.mapper';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { TranslationUnitsService } from '@generated/api';

interface TranslationUnitsStateModel {
  loadingError: boolean;
  loader: boolean;
  translationUnitsMap: {
    [fileId: string]: PreparedTranslationUnit[];
  };
}

@AsyncStorage
@StateRepository()
@State<TranslationUnitsStateModel>({
  name: 'translationUnits',
  defaults: {
    loader: false,
    loadingError: false,
    translationUnitsMap: {},
  },
})
@Injectable()
export class TranslationUnitsState extends NgxsDataRepository<TranslationUnitsStateModel> {
  constructor(
    private translationUnitService: TranslationUnitsService,
    private translationUnitMapper: TranslationUnitMapper
  ) {
    super();
  }

  @Selector()
  static loader(state: TranslationUnitsStateModel) {
    return state.loader;
  }

  @Selector()
  static loadingError(state: TranslationUnitsStateModel) {
    return state.loadingError;
  }

  public static translationUnitsByFileId(fileId: FileId) {
    return createSelector(
      [TranslationUnitsState],
      (state: TranslationUnitsStateModel) => state.translationUnitsMap[fileId] || []
    );
  }

  @Action(SetTranslationUnits)
  public setTranslationUnits(ctx: StateContext<TranslationUnitsStateModel>, action: SetTranslationUnits) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        translationUnitsMap: {
          ...state.translationUnitsMap,
          [action.fileId]: this.translationUnitMapper.mapTranslationUnits(action.translationUnits),
        },
      })
    );
  }

  @Action(SetLoadErrorTranslationUnits)
  public setLoadTranslationUnitsError(
    ctx: StateContext<TranslationUnitsStateModel>,
    action: SetLoadErrorTranslationUnits
  ) {
    ctx.setState(patch({ loadingError: action.error }));
  }

  @Action(LoaderTranslationUnits)
  public loadingTranslationUnits(ctx: StateContext<TranslationUnitsStateModel>, action: LoaderTranslationUnits) {
    ctx.setState(patch({ loader: action.loader }));
  }

  @Action(LoadTranslationUnits)
  public loadTranslationUnits(ctx: StateContext<TranslationUnitsStateModel>, action: LoadTranslationUnits) {
    ctx.dispatch(new SetLoadErrorTranslationUnits(false));
    ctx.dispatch(new LoaderTranslationUnits(true));
    return this.translationUnitService
      .apiTranslationUnitsGet$Json({
        projectFileId: action.fileId,
      })
      .pipe(
        finalize(() => {
          ctx.dispatch(new LoaderTranslationUnits(false));
        }),
        mergeMap((translationUnits) => ctx.dispatch(new SetTranslationUnits(action.fileId, translationUnits))),
        catchError(() => ctx.dispatch(new SetLoadErrorTranslationUnits(true)))
      );
  }
}
