/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchResult } from '../models/search-result';
import { SearchTranslationUnitsRequest } from '../models/search-translation-units-request';
import { TranslationUnitModel } from '../models/translation-unit-model';
import { UpdateTranslationUnitTargetSegmentRequest } from '../models/update-translation-unit-target-segment-request';
import { UpdateTranslationUnitsRequest } from '../models/update-translation-units-request';

@Injectable({
  providedIn: 'root',
})
export class TranslationUnitsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTranslationUnitsGet
   */
  static readonly ApiTranslationUnitsGetPath = '/api/TranslationUnits';

  /**
   * Get translation units by project file ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitsGet$Plain$Response(params?: {

    /**
     * Project File ID
     */
    projectFileId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TranslationUnitModel>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsGetPath, 'get');
    if (params) {
      rb.query('projectFileId', params.projectFileId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TranslationUnitModel>>;
      })
    );
  }

  /**
   * Get translation units by project file ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitsGet$Plain(params?: {

    /**
     * Project File ID
     */
    projectFileId?: string;
    'api-version'?: string;
  }): Observable<Array<TranslationUnitModel>> {

    return this.apiTranslationUnitsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TranslationUnitModel>>) => r.body as Array<TranslationUnitModel>)
    );
  }

  /**
   * Get translation units by project file ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitsGet$Json$Response(params?: {

    /**
     * Project File ID
     */
    projectFileId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TranslationUnitModel>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsGetPath, 'get');
    if (params) {
      rb.query('projectFileId', params.projectFileId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TranslationUnitModel>>;
      })
    );
  }

  /**
   * Get translation units by project file ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitsGet$Json(params?: {

    /**
     * Project File ID
     */
    projectFileId?: string;
    'api-version'?: string;
  }): Observable<Array<TranslationUnitModel>> {

    return this.apiTranslationUnitsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TranslationUnitModel>>) => r.body as Array<TranslationUnitModel>)
    );
  }

  /**
   * Path part for operation apiTranslationUnitsSearchPost
   */
  static readonly ApiTranslationUnitsSearchPostPath = '/api/TranslationUnits/Search';

  /**
   * Finds translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsSearchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitsSearchPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * SearchTranslationUnitsRequest
     */
    body?: SearchTranslationUnitsRequest
  }): Observable<StrictHttpResponse<Array<SearchResult>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsSearchPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchResult>>;
      })
    );
  }

  /**
   * Finds translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsSearchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitsSearchPost$Plain(params?: {
    'api-version'?: string;

    /**
     * SearchTranslationUnitsRequest
     */
    body?: SearchTranslationUnitsRequest
  }): Observable<Array<SearchResult>> {

    return this.apiTranslationUnitsSearchPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchResult>>) => r.body as Array<SearchResult>)
    );
  }

  /**
   * Finds translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsSearchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitsSearchPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * SearchTranslationUnitsRequest
     */
    body?: SearchTranslationUnitsRequest
  }): Observable<StrictHttpResponse<Array<SearchResult>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsSearchPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchResult>>;
      })
    );
  }

  /**
   * Finds translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsSearchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitsSearchPost$Json(params?: {
    'api-version'?: string;

    /**
     * SearchTranslationUnitsRequest
     */
    body?: SearchTranslationUnitsRequest
  }): Observable<Array<SearchResult>> {

    return this.apiTranslationUnitsSearchPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SearchResult>>) => r.body as Array<SearchResult>)
    );
  }

  /**
   * Path part for operation apiTranslationUnitsIdTargetPost
   */
  static readonly ApiTranslationUnitsIdTargetPostPath = '/api/TranslationUnits/{id}/Target';

  /**
   * Changes the target segment of translation unit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsIdTargetPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiTranslationUnitsIdTargetPost$Response(params: {

    /**
     * Translation Unit ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTranslationUnitTargetSegmentRequest
     */
    body?: UpdateTranslationUnitTargetSegmentRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsIdTargetPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changes the target segment of translation unit.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsIdTargetPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiTranslationUnitsIdTargetPost(params: {

    /**
     * Translation Unit ID
     */
    id: string;
    'api-version'?: string;

    /**
     * UpdateTranslationUnitTargetSegmentRequest
     */
    body?: UpdateTranslationUnitTargetSegmentRequest
  }): Observable<void> {

    return this.apiTranslationUnitsIdTargetPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTranslationUnitsUpdateMorePost
   */
  static readonly ApiTranslationUnitsUpdateMorePostPath = '/api/TranslationUnits/UpdateMore';

  /**
   * Updates the target segment of translation units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitsUpdateMorePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiTranslationUnitsUpdateMorePost$Response(params?: {
    'api-version'?: string;

    /**
     * UpdateTranslationUnitsRequest
     */
    body?: UpdateTranslationUnitsRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitsService.ApiTranslationUnitsUpdateMorePostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates the target segment of translation units.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitsUpdateMorePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  apiTranslationUnitsUpdateMorePost(params?: {
    'api-version'?: string;

    /**
     * UpdateTranslationUnitsRequest
     */
    body?: UpdateTranslationUnitsRequest
  }): Observable<void> {

    return this.apiTranslationUnitsUpdateMorePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
