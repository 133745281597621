import { Component, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp, ICellEditorParams } from 'src/types/ag-grid';
import { GridHelperService } from '@shared/services/grid-helper.service';

export interface SimpleCellEditorParams {
  value: string;
  placeholder?: string;
  shouldPlaceholderLookLikeValue?: boolean;
}

@Component({
  selector: 'app-simple-cell-editor',
  templateUrl: './simple-cell-editor.component.html',
  styleUrls: ['./simple-cell-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleCellEditorComponent implements ICellEditorAngularComp {
  public static rendererName = 'simpleCellEditorRenderer';

  public textControl: FormControl = new FormControl('');
  public shouldPlaceholderLookLikeValue: boolean = false;
  public placeholder: string = '';
  private columnId: string;
  private params: ICellEditorParams;

  @ViewChild('textInput', { static: true })
  public inputElementRef: ElementRef<HTMLInputElement>;

  constructor(private readonly gridHelperService: GridHelperService) {}

  public agInit(params: ICellEditorParams & SimpleCellEditorParams): void {
    this.gridHelperService.initApi(params.api, params.columnApi);
    this.params = params;
    this.columnId = params.column.getColId();
    this.textControl.setValue(params.value);
    this.placeholder = params.placeholder || '';
    this.shouldPlaceholderLookLikeValue = !!params.shouldPlaceholderLookLikeValue;
    setTimeout(() => {
      this.inputElementRef.nativeElement.focus();
    });
  }

  public onEnter(): void {
    this.inputElementRef.nativeElement.blur();
    this.gridHelperService.switchToNextEditor(this.params.node, this.columnId);
  }

  public getValue(): string {
    return this.textControl.value;
  }
}
