<ng-template>
  <div
    class="popover-container"
    #focusTrapElement
    [ngClass]="classList"
    [@transformPopover]="getAnimation()"
    (@transformPopover.done)="onAnimationDone($event)"
  >
    <app-popover-container>
      <ng-content></ng-content>
    </app-popover-container>
  </div>
</ng-template>
