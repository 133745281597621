<app-button
  (clickButton)="input.click()"
  [disabled]="disabled"
  [prefixIcon]="icon"
  [size]="size"
  [loading]="loading"
  [theme]="theme"
>
  <ng-content></ng-content>
  <input
    type="file"
    hidden
    #input
    [disabled]="disabled"
    [accept]="accepted ? accepted.join(', ') : ''"
    [attr.webkitdirectory]="directory || null"
    [attr.directory]="directory || null"
    [multiple]="multiple"
    (change)="select($event)"
  />
</app-button>
