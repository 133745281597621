import { Organization } from '@generated/api';

export class LoadOrganizations {
  static readonly type = '[Organisations] Load organisations';
}

export class CreateOrganization {
  static readonly type = '[Organisations] Create organisation';
  constructor(public name: string) {}
}

export class SelectOrganization {
  static readonly type = '[Organisations] Select organisation';
  constructor(public organization: Organization) {}
}

export class SelectOrganizationById {
  static readonly type = '[Organisations] Select organisation by id';
  constructor(public id: string) {}
}

export class JoinOrganization {
  static readonly type = '[Organisations] Join organisation';
  constructor(public id: string) {}
}

export class InviteUsers {
  static readonly type = '[Organisations] Invite users';
  constructor(public emails: string[]) {}
}

export class TryAcceptInvite {
  static readonly type = '[Organisations] Try to accept invite';
  constructor(public inviteToken?: string) {}
}

export class OrganizationCreated {
  static readonly type = '[Organisations] Organisation created';
  constructor(public readonly name: string, public readonly id: string) {}
}
