/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateReportCommand } from '../models/create-report-command';
import { GenerateReportRequest } from '../models/generate-report-request';
import { ReportExportModel } from '../models/report-export-model';
import { ReportModel } from '../models/report-model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiReportsGet
   */
  static readonly ApiReportsGetPath = '/api/Reports';

  /**
   * Gets a specific report by project Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet$Plain$Response(params: {

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ReportModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModel>;
      })
    );
  }

  /**
   * Gets a specific report by project Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet$Plain(params: {

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<ReportModel> {

    return this.apiReportsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModel>) => r.body as ReportModel)
    );
  }

  /**
   * Gets a specific report by project Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet$Json$Response(params: {

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<ReportModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModel>;
      })
    );
  }

  /**
   * Gets a specific report by project Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsGet$Json(params: {

    /**
     * Project identifier
     */
    projectId: string;
    'api-version'?: string;
  }): Observable<ReportModel> {

    return this.apiReportsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModel>) => r.body as ReportModel)
    );
  }

  /**
   * Path part for operation apiReportsPost
   */
  static readonly ApiReportsPostPath = '/api/Reports';

  /**
   * Gets or creates report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * CreateReportCommand
     */
    body?: CreateReportCommand
  }): Observable<StrictHttpResponse<ReportModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModel>;
      })
    );
  }

  /**
   * Gets or creates report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * CreateReportCommand
     */
    body?: CreateReportCommand
  }): Observable<ReportModel> {

    return this.apiReportsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModel>) => r.body as ReportModel)
    );
  }

  /**
   * Gets or creates report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * CreateReportCommand
     */
    body?: CreateReportCommand
  }): Observable<StrictHttpResponse<ReportModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModel>;
      })
    );
  }

  /**
   * Gets or creates report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPost$Json(params?: {
    'api-version'?: string;

    /**
     * CreateReportCommand
     */
    body?: CreateReportCommand
  }): Observable<ReportModel> {

    return this.apiReportsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModel>) => r.body as ReportModel)
    );
  }

  /**
   * Path part for operation apiReportsIdGeneratePost
   */
  static readonly ApiReportsIdGeneratePostPath = '/api/Reports/{id}/Generate';

  /**
   * Runs a specific report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsIdGeneratePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsIdGeneratePost$Response(params: {
    id: string;
    'api-version'?: string;

    /**
     * GenerateReportRequest
     */
    body?: GenerateReportRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsIdGeneratePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Runs a specific report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsIdGeneratePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsIdGeneratePost(params: {
    id: string;
    'api-version'?: string;

    /**
     * GenerateReportRequest
     */
    body?: GenerateReportRequest
  }): Observable<void> {

    return this.apiReportsIdGeneratePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiReportsIdGenerateLinkPost
   */
  static readonly ApiReportsIdGenerateLinkPostPath = '/api/Reports/{id}/GenerateLink';

  /**
   * Generates a report link.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsIdGenerateLinkPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsIdGenerateLinkPost$Response(params: {

    /**
     * ReportId
     */
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsIdGenerateLinkPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Generates a report link.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsIdGenerateLinkPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsIdGenerateLinkPost(params: {

    /**
     * ReportId
     */
    id: string;
    'api-version'?: string;
  }): Observable<void> {

    return this.apiReportsIdGenerateLinkPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiReportsReportIdExportGet
   */
  static readonly ApiReportsReportIdExportGetPath = '/api/Reports/{reportId}/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsReportIdExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsReportIdExportGet$Response(params: {
    reportId: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsReportIdExportGetPath, 'get');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsReportIdExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsReportIdExportGet(params: {
    reportId: string;
    'api-version'?: string;
  }): Observable<Blob> {

    return this.apiReportsReportIdExportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiReportsReportIdExportPost
   */
  static readonly ApiReportsReportIdExportPostPath = '/api/Reports/{reportId}/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsReportIdExportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdExportPost$Response(params: {
    reportId: string;
    'api-version'?: string;
    body?: ReportExportModel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsReportIdExportPostPath, 'post');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsReportIdExportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsReportIdExportPost(params: {
    reportId: string;
    'api-version'?: string;
    body?: ReportExportModel
  }): Observable<void> {

    return this.apiReportsReportIdExportPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
