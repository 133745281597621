<!--
<div class="overlay-backdrop"
	*ngIf="properties.overlayBackdrop"
	(click)="onBackdropClick()"
	[ngClass]="properties.backdropClass"></div>
-->
<!--
<div class="overlay-container"
	[ngStyle]="containerStyles"
	[ngClass]="properties.overlayClass">

	<ng-template ad-host></ng-template>
</div>
-->

<ng-template ad-host></ng-template>
