import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

type SearchInputSize = 'small' | 'normal' | 'large';

interface ModelOption {
  debounce?: number;
}

/**
 * Component contain an input and a clearing action.
 * Supports 3 modes
 * - with two-way communication [(value)]
 * - with the callback (changed)
 * - like formControl - for this you need to add formControlName
 *
 * How to use:
 *
 * Using two way data binding
 * ```
 *  <app-ui-search-in-report-files-in-report-files-input
 * [(value)]="value"></app-ui-search-in-report-files-in-report-files-input>
 * ```
 *
 * Using two way data binding and the debounce time
 * ```
 * <app-ui-search-in-report-files-in-report-files-input
 * [(value)]="value" [ngModelOptions]="{debounce: 500}"></app-ui-search-in-report-files-in-report-files-input>
 * ```
 *
 * Using the callback function the debounce time
 * ```
 * <app-ui-search-in-report-files-in-report-files-input
 * [(value)]="value"
 * [ngModelOptions]="{debounce: 500}"
 * (changed)="change($event, 3)"></app-ui-search-in-report-files-in-report-files-input>
 * ```
 *
 * Form control
 * ```
 * <app-ui-search-in-report-files-in-report-files-input
 * formControlName="name"
 * [ngModelOptions]="{debounce: 500}"></app-ui-search-in-report-files-in-report-files-input>
 * ```
 */
@Component({
  selector: 'app-ui-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  @Input() placeholder = 'Search...';
  @Input() fieldName: string = 'search';
  @Input() size: SearchInputSize = 'normal';
  @Input() value: string;
  @Input() disabled = false;
  @Input() ngModelOptions: ModelOption;
  @Input() autocomplete: 'on' | 'off' = 'on';
  @Output() valueChange = new EventEmitter<string>();
  @Output() changed = new EventEmitter<string>();

  changed$ = new Subject<string>();
  inputDisable = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.checkChanges();
    this.inputDisable = this.disabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.disabled) {
      this.inputDisable = changes?.disabled.currentValue;
    }
  }

  private checkChanges(): void {
    this.changed$
      .asObservable()
      .pipe(debounceTime(this.ngModelOptions?.debounce || 0), distinctUntilChanged())
      .subscribe((value: string) => {
        this.changed.emit(value);
        this.valueChange.emit(value);
        this.onControlChange(value);
      });
  }

  ngOnDestroy() {
    this.changed$.complete();
  }

  public writeValue(newValue: string): void {
    if (newValue !== this.value) {
      this.value = newValue;
      this.ref.markForCheck();
    }
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onControlChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.inputDisable = !!isDisabled;
    this.ref.markForCheck();
  }

  public onControlChange: any = () => {};

  public onTouched: any = () => {};

  public handleClearValue(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.inputDisable) {
      this.value = '';
      this.handleChange();
    }
  }

  public handleChange(): void {
    this.changed$.next(this.value);
  }

  public clickHandler(): void {
    this.onTouched();
  }
}
