import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { IconPayload } from '@generated/icons/icons.model';

enum InputMode {
  Add,
  Edit,
  Delete,
}

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsInputComponent {
  #selectedChips: string[] = [];
  #initialInputValue = '';

  @Input() rtl = false;
  @Input() disabled = false;
  @Input() set selectedChips(value: string[]) {
    this.#selectedChips = value || [];
    if (this.#selectedChips.length === 0) {
      this.inputMode = this.inputModes.Add;
      this.inputValue = '';
      this.#initialInputValue = '';
    } else if (this.#selectedChips.length === 1) {
      this.inputMode = this.inputModes.Edit;
      this.inputValue = this.#selectedChips[0];
      this.#initialInputValue = this.#selectedChips[0];
    } else {
      this.inputMode = this.inputModes.Delete;
      this.inputValue = '';
      this.#initialInputValue = '';
    }
  }
  get selectedChips(): string[] {
    return this.#selectedChips;
  }

  @Input() placeholder: string;

  @Output() deleteSelectedChip = new EventEmitter<void>();
  @Output() update = new EventEmitter<string>();
  @Output() add = new EventEmitter<string[]>();
  @ViewChild('inputElement', { static: false }) public input: ElementRef;

  inputIcons: { [key: string]: IconPayload } = {
    [InputMode.Add]: 'add',
    [InputMode.Edit]: 'edit',
    [InputMode.Delete]: 'delete-outline',
  };

  inputMode = InputMode.Add;
  inputModes = InputMode;

  inputValue = '';
  #hasChanges = false;
  #splitPattern = /\r\n|\r|\n/;

  public get inputIconDisabled(): boolean {
    return this.disabled || (!this.#hasChanges && this.inputMode !== this.inputModes.Delete);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public keyDownHandler(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.#hasChanges) {
      setTimeout(() => {
        this.propagateChanges();
      });
    }
  }

  public propagateChanges(): void {
    if (this.inputMode === this.inputModes.Add) {
      this.add.emit([this.inputValue]);
    } else if (this.inputMode === this.inputModes.Edit) {
      this.update.emit(this.inputValue);
    } else if (this.inputMode === this.inputModes.Delete) {
      this.deleteSelectedChip.emit();
    }
    this.inputValue = '';
    this.#initialInputValue = '';
    this.#hasChanges = false;
    this.cdr.markForCheck();
  }

  public inputValueChanged(event: string): void {
    this.inputValue = event;
    this.#hasChanges = this.#initialInputValue !== this.inputValue;
  }

  public inputPasteHandler(event: ClipboardEvent): void {
    if (this.inputMode === InputMode.Edit) {
      return;
    }
    const pasteText = event.clipboardData.getData('text/plain');
    const filtered = pasteText
      .split(this.#splitPattern)
      .map((item) => item.trim())
      .filter((item) => item);

    if (filtered.length <= 1) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    this.add.emit(filtered);
  }

  public focus(): void {
    if (!this.input) {
      return;
    }
    this.input.nativeElement.focus();
  }
}
