import { ExportColumnModel, SortDirection } from '@generated/api';
import { ColumnState } from '@store/report-grids-store/report-grids-state.model';
import { ReportType, ReportTypeColumnOptions } from '../configs';

/**
 * Converts column state to export column request model
 */
export function mapFromColumnState(reportType: ReportType, col: ColumnState, order?: number): ExportColumnModel {
  const column = ReportTypeColumnOptions[reportType]?.[col.colId];
  if (!column) {
    return null;
  }

  return {
    field: column.field,
    sort: col.sort as SortDirection,
    sortIndex: col.sortIndex,
    visible: col.hide === undefined || !col.hide,
    order,
    highlight: column.highlight,
  };
}
