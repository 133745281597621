export enum ActionTypes {
  UPLOAD_TEMPLATE = '[TemplateList] Upload Template',

  LOAD_TEMPLATE_LIST = '[TemplateList] Load Template List',
  LOAD_TEMPLATE_LIST_FAILURE = '[TemplateList] Load Template List Failure',
  LOAD_TEMPLATE_LIST_SUCCESS = '[TemplateList] Load Template List Success',

  ADD_TEMPLATE = '[TemplateList] Add Template',
  ADD_TEMPLATE_FAILURE = '[TemplateList] Add Template Failure',
  ADD_TEMPLATE_SUCCESS = '[TemplateList] Add Template Success',

  UPDATE_TEMPLATE = '[TemplateList] Update Template',
  UPDATE_TEMPLATE_FAILURE = '[TemplateList] Update Template Failure',
  UPDATE_TEMPLATE_SUCCESS = '[TemplateList] Update Template Success',

  SET_DEFAULT = '[TemplateList] Set default',
  CLONE_TEMPLATE = '[TemplateList] Clone template',
  DELETE_TEMPLATE = '[TemplateList] Delete template',
  CREATE_TEMPLATE_FROM_PROJECT_PROFILE = '[TemplateList] Create template from project profile',

  RENAME_TEMPLATE = '[TemplateList] Rename Template',
  REMOVE_TEMPLATE = '[TemplateList] Remove Template',
}

export class LoadTemplateList {
  static readonly type = ActionTypes.LOAD_TEMPLATE_LIST;
  constructor() {}
}

export class LoadTemplateListSuccess {
  static readonly type = ActionTypes.LOAD_TEMPLATE_LIST_SUCCESS;
  constructor() {}
}

export class LoadTemplateListFail {
  static readonly type = ActionTypes.LOAD_TEMPLATE_LIST_FAILURE;
  constructor(public error: any) {}
}

export class AddTemplate {
  static readonly type = ActionTypes.ADD_TEMPLATE;
  constructor(public name: string, public toggleProject: boolean) {}
}

export class AddTemplateSuccess {
  static readonly type = ActionTypes.ADD_TEMPLATE_SUCCESS;
}

export class AddTemplateFail {
  static readonly type = ActionTypes.ADD_TEMPLATE_FAILURE;
  constructor(public error: any) {}
}

export class UpdateTemplate {
  static readonly type = ActionTypes.UPDATE_TEMPLATE;
  constructor(public fromQASettingsId: string, public toQASettingsId: string) {}
}

export class UpdateTemplateSuccess {
  static readonly type = ActionTypes.UPDATE_TEMPLATE_SUCCESS;
  constructor() {}
}

export class UpdateTemplateFail {
  static readonly type = ActionTypes.UPDATE_TEMPLATE_FAILURE;
  constructor(public error: any) {}
}

export class UploadTemplate {
  static readonly type = ActionTypes.UPLOAD_TEMPLATE;
  constructor(public file: File, public toggleProject: boolean) {}
}

export class SetDefault {
  static readonly type = ActionTypes.SET_DEFAULT;
  constructor(public templateId: string) {}
}

export class CloneTemplate {
  static readonly type = ActionTypes.CLONE_TEMPLATE;
  constructor(public templateId: string, public name: string = '') {}
}

export class DeleteTemplate {
  static readonly type = ActionTypes.DELETE_TEMPLATE;
  constructor(public templateId: string) {}
}

export class CreateTemplateFromProjectProfile {
  static readonly type = ActionTypes.CREATE_TEMPLATE_FROM_PROJECT_PROFILE;
  constructor(public qaSettingsId: string, public name: string) {}
}

export class RenameTemplate {
  static readonly type = ActionTypes.RENAME_TEMPLATE;
  constructor(public templateId: string, public name: string) {}
}

export class RemoveTemplate {
  static readonly type = ActionTypes.REMOVE_TEMPLATE;
  constructor(public templateId: string) {}
}

export type Actions =
  | LoadTemplateList
  | LoadTemplateListSuccess
  | LoadTemplateListFail
  | AddTemplate
  | AddTemplateSuccess
  | AddTemplateFail
  | SetDefault
  | CloneTemplate
  | DeleteTemplate;
