import { LanguageModel, ProjectFileInfo } from '@generated/api';
import { Languages } from '@shared/models';

enum ActionTypes {
  SET_DEFAULT_LANGUAGE = '[LANGUAGE] Update default language',
  SET_LANGUAGE = '[LANGUAGE] Set language',
  SET_LANGUAGES_FROM_FILES = '[LANGUAGE] Set languages from files',
  ADD_TEMPORARY_TARGET_LANGUAGE = '[LANGUAGE] Add temporary target language',
  ADD_TEMPORARY_SOURCE_LANGUAGE = '[LANGUAGE] Add temporary source language',
  SET_SOURCE_TARGET_LANGUAGE = '[LANGUAGE] Set source and target language',
  CLEAR_TEMPORARY_LANGUAGES = '[LANGUAGE] Clear temporary languages',
  CLEAR_SOURCE_TARGET_LANGUAGE = '[LANGUAGE] Clear source and target language',
  RESET = '[LANGUAGE] Reset languages',
}

export class SetDefaultLanguage {
  static readonly type = ActionTypes.SET_DEFAULT_LANGUAGE;
}

export class SetLanguage {
  static readonly type = ActionTypes.SET_LANGUAGE;

  constructor(public lang: LanguageModel) {}
}

export class SetLanguagesFromFiles {
  static readonly type = ActionTypes.SET_LANGUAGES_FROM_FILES;

  constructor(public projectFiles: ProjectFileInfo[]) {}
}

export class AddTemporaryTargetLanguage {
  static readonly type = ActionTypes.ADD_TEMPORARY_TARGET_LANGUAGE;

  constructor(public langCode: string) {}
}

export class AddTemporarySourceLanguage {
  static readonly type = ActionTypes.ADD_TEMPORARY_SOURCE_LANGUAGE;

  constructor(public langCode: string) {}
}

export class SetSourceTargetLanguage {
  static readonly type = ActionTypes.SET_SOURCE_TARGET_LANGUAGE;

  constructor(public source: Languages, public target: Languages) {}
}

export class ClearSourceTargetLanguage {
  static readonly type = ActionTypes.CLEAR_SOURCE_TARGET_LANGUAGE;
}

export class ClearTemporaryLanguages {
  static readonly type = ActionTypes.CLEAR_TEMPORARY_LANGUAGES;
}

export class ResetLanguages {
  static readonly type = ActionTypes.RESET;
}
