import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import CodeMirror from 'codemirror';
import { Subject } from 'rxjs';
import {
  DefaultSpecialChars,
  EmptySpecialChars,
  specialCharPlaceholder,
} from '@shared/components/segment-editor/utils';
import { KeyCodes } from 'src/app/core/services/shortcuts';

const lineSeparator = '\n';

@Component({
  selector: 'app-codemirror-wrapper',
  templateUrl: './codemirror-wrapper.component.html',
  styleUrls: ['./codemirror-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.single-line]': '!multiline',
    '[class.show-invisibles]': 'showInvisibles',
    '[class.unselectable]': '!selectable',
    '[class.readonly]': 'readonly',
  },
})
export class CodemirrorWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private _readonly = false;
  private _rightToLeft = false;
  private _showInvisibles = false;
  public altKeyPressed = false;

  @Input() contextMenu = false;
  @Input() multiline = false;
  @Input() selectable = true;
  @Input() preventEnter = false;

  @Input()
  set readonly(value) {
    this._readonly = value;
    if (this.editor) {
      this.editor.setOption('readOnly', this.readonly);
    }
  }

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set rightToLeft(value: boolean) {
    this._rightToLeft = value;
    if (this.editor) {
      this.editor.setOption('direction', this.rightToLeft ? 'rtl' : 'ltr');
    }
  }

  get rightToLeft(): boolean {
    return this._rightToLeft;
  }

  @Input()
  set showInvisibles(value: boolean) {
    this._showInvisibles = value;
    if (this.editor) {
      this.editor.setOption('specialChars', this.showInvisibles ? DefaultSpecialChars : EmptySpecialChars);
    }
    this.cdr.markForCheck();
  }

  get showInvisibles(): boolean {
    return this._showInvisibles;
  }

  @Output()
  editorReady: EventEmitter<CodeMirror.EditorFromTextArea> = new EventEmitter();
  @Output() editorDragStart: EventEmitter<DragEvent> = new EventEmitter();
  @Output() editorDrop: EventEmitter<DragEvent> = new EventEmitter();
  @Output() editorCopy: EventEmitter<ClipboardEvent> = new EventEmitter();
  @Output() editorCut: EventEmitter<ClipboardEvent> = new EventEmitter();
  @Output() editorBlue: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() editorFocus = new EventEmitter();
  @Output()
  editorChange: EventEmitter<CodeMirror.EditorChange> = new EventEmitter();
  @Output()
  editorBeforeChange: EventEmitter<CodeMirror.EditorChangeCancellable> = new EventEmitter();
  @Output()
  editorBeforeSelectionChange: EventEmitter<CodeMirror.EditorSelectionChange> = new EventEmitter();
  @Output()
  keyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  outsideClicked = new EventEmitter<void>();
  @Output()
  altKeyDown = new EventEmitter<void>();
  @Output()
  altKeyUp = new EventEmitter<void>();
  @Output()
  altKeyDownAndNumberKeyPressed = new EventEmitter<number>();

  @Output()
  contextMenuPressed = new EventEmitter<{ event: PointerEvent; markers: CodeMirror.TextMarker[] }>();

  @Output()
  public readonly enterKeyPressed = new EventEmitter<KeyboardEvent>();

  @Output()
  public readonly clicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('editor', { static: false }) textArea: ElementRef<HTMLTextAreaElement>;
  editor: CodeMirror.EditorFromTextArea;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initEditor();
  }

  public getMarkersUnderMouse(e: MouseEvent): CodeMirror.TextMarker[] {
    const lineCh = this.editor?.coordsChar({ left: e.clientX, top: e.clientY });
    const markers = this.editor?.findMarksAt(lineCh);
    return markers;
  }

  private initEditor(): void {
    this.editor = CodeMirror.fromTextArea(this.textArea.nativeElement, this.getEditorOptions());
    this.editorReady.emit(this.editor);
    this.toggleEventHandlers(true);
  }

  private getEditorOptions(): CodeMirror.EditorConfiguration {
    const options: CodeMirror.EditorConfiguration = {
      lineWiseCopyCut: false,
      mode: 'vr-mode',
      lineNumbers: false,
      lineWrapping: true,
      tabSize: 2,
      dragDrop: true,
      readOnly: this._readonly,
      scrollbarStyle: this.multiline ? 'native' : 'null',
      historyEventDelay: 1,
      direction: this.rightToLeft ? 'rtl' : 'ltr',
      lineSeparator,
      specialChars: this.showInvisibles ? DefaultSpecialChars : EmptySpecialChars,
      specialCharPlaceholder: specialCharPlaceholder.bind(this),
      styleSelectedText: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      singleCursorHeightPerLine: false,
    };

    const keysOptions: CodeMirror.EditorConfiguration = {
      extraKeys: {
        'Shift-Ctrl-Space': (cm: CodeMirror.Editor) => {
          cm.replaceRange(' ', cm.getCursor('from'));
        },
        'Ctrl-Alt--': (cm: CodeMirror.Editor) => {
          cm.replaceRange('—', cm.getCursor('from'));
        },
        'Shift-Ctrl--': (cm: CodeMirror.Editor) => {
          cm.replaceRange('-', cm.getCursor('from'));
        },
        'Ctrl--': (cm: CodeMirror.Editor) => {
          cm.replaceRange('–', cm.getCursor('from'));
        },
      },
    };

    return { ...options, ...keysOptions };
  }

  private toggleEventHandlers(on: boolean): void {
    let func = on ? this.editor.on : this.editor.off;
    func = func.bind(this.editor);
    func('keydown', this.keyDownHandler);
    func('keyup', this.keyUpHandler);
    func('contextmenu', this.contextmenuHandler);
    func('mousedown', (cm) => this.mousedownHandler(cm));
    func('dragstart', this.dragStartHandler);
    func('copy', this.copyHandler);
    func('cut', this.cutHandler);
    func('blur', this.blurHandler);
    func('beforeSelectionChange', this.beforeSelectionChangeHandler);
    func('drop', this.dropHandler);
    func('beforeChange', this.beforeChangeHandler);
    func('change', this.changeHandler);
    func('focus', this.focusHandler);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.toggleEventHandlers(false);
  }

  private dragStartHandler = (cm: CodeMirror.Editor, dragEvent: DragEvent): void => {
    cm.focus();
    this.editorDragStart.emit(dragEvent);
  };

  private dropHandler = (cm: CodeMirror.Editor, dragEvent: DragEvent): void => {
    if (!this.readonly) {
      cm.focus();
      this.editorDrop.emit(dragEvent);
    }
  };

  private copyHandler = (_: CodeMirror.Editor, e: ClipboardEvent): void => {
    this.editorCopy.emit(e);
  };

  private cutHandler = (_: CodeMirror.Editor, e: ClipboardEvent): void => {
    this.editorCut.emit(e);
  };

  private changeHandler = (_: CodeMirror.Editor, e: CodeMirror.EditorChange): void => {
    if (!this.readonly) {
      this.editorChange.emit(e);
    }
  };

  private beforeChangeHandler = (_: CodeMirror.Editor, e: CodeMirror.EditorChangeCancellable): void => {
    if (this.altKeyPressed && e.origin === '+input') {
      e.cancel();
      return;
    }
    if (!this.readonly) {
      this.editorBeforeChange.emit(e);
    }
  };

  private beforeSelectionChangeHandler = (_: CodeMirror.Editor, e: CodeMirror.EditorSelectionChange): void => {
    if (this.selectable) {
      this.editorBeforeSelectionChange.emit(e);
    }
  };

  private keyDownHandler = (_: CodeMirror.Editor, event: KeyboardEvent): void => {
    if (!this.preventEnter && (event.code === KeyCodes.KEY_ENTER || event.code === KeyCodes.KEY_NUMPAD_ENTER)) {
      this.enterKeyPressed.emit(event);
    }

    if ([KeyCodes.KEY_DOWN, KeyCodes.KEY_UP].includes(event.code as KeyCodes)) {
      event.preventDefault();
    }

    if ((event.code === KeyCodes.KEY_ALT || event.code === KeyCodes.KEY_ALT_RIGHT) && !this.altKeyPressed) {
      this.altKeyPressed = true;
      this.altKeyDown.emit();
    }
    const altDigit = this.extractDigitFromKeybindingEvent(event);
    if (altDigit) {
      this.altKeyDownAndNumberKeyPressed.emit(altDigit);
    }

    this.keyDown.emit(event);
    event.stopPropagation();
  };

  private extractDigitFromKeybindingEvent(event: KeyboardEvent): number {
    const value = event.code;
    const regExp = /^Digit(\d+)$/;
    const matchedNumber = regExp.exec(value);
    if (matchedNumber) {
      const parsedNumber = parseInt(matchedNumber[1], 10);
      return parsedNumber;
    }
  }

  private keyUpHandler = (_: CodeMirror.Editor, event: KeyboardEvent): void => {
    if (this.altKeyPressed && (event.code === KeyCodes.KEY_ALT || event.code === KeyCodes.KEY_ALT_RIGHT)) {
      event.preventDefault();
      this.altKeyPressed = false;
      this.altKeyUp.emit();
    }
  };

  private contextmenuHandler = (cm: CodeMirror.Editor, e: PointerEvent): void => {
    const markers = this.getMarkersUnderMouse(e);
    this.contextMenuPressed.emit({ event: e, markers });

    if (!this.contextMenu) {
      e.preventDefault();
      e.stopPropagation();
    }
    cm.focus();
  };

  private mousedownHandler(cm: CodeMirror.Editor): void {
    cm.focus();
    this.clicked.emit();
  }

  private blurHandler = (_: CodeMirror.Editor, e: FocusEvent): void => {
    this.editorBlue.emit(e);
  };

  private focusHandler = (_: CodeMirror.Editor, e: FocusEvent): void => {
    this.editorFocus.emit(e);
  };
}
