import { Pipe, PipeTransform } from '@angular/core';
import { NavigatorService } from 'src/app/core/services';

const keyRegexp = /^Key([A-Z])/;
const leftKeysRegexp = /^Left([a-zA-Z]+)|([a-zA-Z]+)Left?/;
const arrowRegexp = /Arrow/;

@Pipe({
  name: 'prettyKey',
})
export class PrettyKeyPipe implements PipeTransform {
  constructor(private navigatorService: NavigatorService) {}

  private tryPrettifyArrowKey(key: string): string {
    const prettyArrows = { ArrowUp: '↑', ArrowDown: '↓', ArrowLeft: '←', ArrowRight: '→' };
    return prettyArrows[key] || key;
  }

  private prettifyModifierKey(key: string): string {
    if (key.endsWith('Control')) {
      return 'Ctrl';
    }
    return key;
  }

  private prettifyModifierKeysForMacOs(keys: string): string {
    const prettyKeys = {
      Alt: '⌥',
      Meta: '⌘',
    };
    return prettyKeys[keys] || keys;
  }

  private prettifyCommonKey(key: string): string {
    // NOTE: order of key check is important

    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const isKey = key.match(keyRegexp);
    if (isKey) {
      return isKey[1];
    }

    if (arrowRegexp.test(key)) {
      return this.tryPrettifyArrowKey(key);
    }
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const isLeftKey = key.match(leftKeysRegexp);
    if (isLeftKey) {
      return this.prettifyModifierKey(isLeftKey[1] || isLeftKey[2]);
    }

    return key;
  }

  private prettifyKey(key: string): string {
    const commonPrettyKey = this.prettifyCommonKey(key);

    if (this.navigatorService.isMac) {
      return this.prettifyModifierKeysForMacOs(commonPrettyKey);
    }

    return commonPrettyKey;
  }

  private prettifyKeys(keys: string[], separator = ' + '): string {
    const prettyKeys = keys.map((key) => this.prettifyKey(key));
    const concatedPrettyKeys = prettyKeys.join(separator);
    return concatedPrettyKeys;
  }

  transform(value: string | string[]): string {
    if (!value) {
      return value as string;
    }

    if (typeof value === 'string') {
      return this.prettifyKey(value);
    }

    return this.prettifyKeys(value);
  }
}
