<div class="grid-box">
  <div class="grid-header">
    <span class="grid-title" [class.title-disabled]="disabled">Digit to text</span>
    <div class="grid-actions">
      <app-action-icon
        [disabled]="isEditing || disabled || searchControl.value"
        icon="add"
        class="grid-add"
        appTooltip="Add new"
        (clickButton)="add()"
        size="xl"
      ></app-action-icon>
      <app-action-icon
        icon="delete-outline"
        class="grid-remove"
        appTooltip="Delete"
        (clickButton)="remove()"
        [disabled]="!isRowSelected || disabled"
        size="xl"
      ></app-action-icon>
      <app-ui-search-input
        size="small"
        [appDisableControl]="disabled"
        class="grid-search"
        [formControl]="searchControl"
      ></app-ui-search-input>
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-settings digit-text-grid"
    [class.table-disabled]="disabled"
    rowSelection="multiple"
    (gridReady)="onGridReady($any($event))"
    (cellEditingStarted)="editStarted()"
    (cellEditingStopped)="editStopped($event)"
    (cellValueChanged)="onCellChanged($event)"
    (rowSelected)="onRowSelected()"
    [stopEditingWhenCellsLoseFocus]="true"
    [suppressRowClickSelection]="true"
    [singleClickEdit]="true"
    [modules]="modules"
    [components]="gridComponents"
    [defaultColDef]="defaultColDef"
    [columnDefs]="$any(columnDefs)"
    [suppressClickEdit]="disabled"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="{
      notFound: this.searchControl.value,
      notFoundMessage: this.overlayText,
      noRowsMessage: this.overlayNoRowsText
    }"
  >
  </ag-grid-angular>
</div>
