import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { DropdownComponent } from './dropdown.component';
import { LoaderModule } from '../loader';
import { TooltipModule } from '@shared/components/tooltip';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule, MatMenuModule, LoaderModule, ButtonModule],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
})
export class DropdownModule {}
