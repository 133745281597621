import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TooltipProperties, TooltipState } from './interfaces';
import { TooltipService } from './tooltip.service';
import { uuid } from '@shared/tools';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseTooltipTrigger implements OnInit, OnDestroy {
  /**
   * Classes to be passed to the tooltip.
   */
  @Input() appTooltipClass: TooltipProperties['tooltipClass'];

  /**
   * The delay in ms before removing the tooltip.
   */
  @Input() appTooltipHideDelay: TooltipProperties['hideDelay'];

  /**
   * The delay in ms before showing the tooltip.
   */
  @Input() appTooltipShowDelay: TooltipProperties['showDelay'] = 500;

  /**
   * Specifies how the tooltip is triggered.
   * Control the closing time with "hide-delay".
   */
  @Input() appTooltipTrigger: TooltipProperties['trigger'] = 'hover';

  /**
   * Defines whether or not an element reacts to pointer events.
   */
  @Input() appTooltipPointerEvents: TooltipProperties['pointerEvents'];

  /**
   * Tooltip availability for display.
   */
  @Input() appTooltipDisplay: TooltipProperties['display'] = true;

  @Input() appTooltipHideOnClick: TooltipProperties['hideOnClick'];

  // Animation

  /**
   * Animation of showing and hiding tooltip.
   */
  @Input() appTooltipAnimationDisable: TooltipProperties['animationDisable'];

  // Position

  /**
   * The position of the tooltip.
   */
  @Input() appTooltipPlacement: TooltipProperties['placement'];

  /**
   * Place the tooltip so that it does not go beyond the borders of the browser window.
   */
  @Input() appTooltipAutoPlacement: TooltipProperties['autoPlacement'];

  /**
   * The tooltip coordinates relative to the browser window.
   */
  @Input() appTooltipPosition: TooltipProperties['position'];

  /**
   * Z-index of the tooltip.
   */
  @Input() appTooltipZIndex: TooltipProperties['zIndex'];

  // Sizes

  /**
   * Width of the tooltip.
   */
  @Input() appTooltipWidth: TooltipProperties['width'];

  /**
   * Height of the tooltip.
   */
  @Input() appTooltipHeight: TooltipProperties['height'];

  /**
   * Maximum width of the tooltip.
   */
  @Input() appTooltipMaxWidth: TooltipProperties['maxWidth'];

  /**
   * Minimum width of the tooltip.
   */
  @Input() appTooltipMinWidth: TooltipProperties['minWidth'];

  @Input() appTooltipWhiteSpace: TooltipProperties['whiteSpace'];

  // Styles
  /**
   * Theme of tooltip background and text.
   */
  @Input() appTooltipTheme: TooltipProperties['theme'];

  /**
   * Offset the tooltip relative to the item.
   */
  @Input() appTooltipOffset: TooltipProperties['offset'];

  /**
   * Tooltip padding.
   */
  @Input() appTooltipPadding: TooltipProperties['padding'];

  /**
   * Hide arrow tooltip.
   */
  @Input() appTooltipNoArrow: TooltipProperties['noArrow'];

  /**
   * Tooltip border radius
   */
  @Input() appTooltipBorderRadius: TooltipProperties['borderRadius'];

  /**
   * Shadow of the tooltip.
   */
  @Input() appTooltipShadow: TooltipProperties['shadow'];

  @Input() appTooltipFontSize: TooltipProperties['fontSize'];

  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  protected tooltipId = uuid();
  protected tooltipText: string;
  protected destroyed$: Subject<void> = new Subject<void>();

  protected abstract readonly elementRef: ElementRef;
  protected abstract readonly tooltipService: TooltipService;

  @Input() set appTooltipDisabled(disabled: boolean) {
    this.appTooltipDisplay = !disabled;
  }

  ngOnInit() {
    this.watchTooltipState();
  }

  ngOnDestroy() {
    this.tooltipService.hide();
    this.clearSubscriptions();
  }

  private watchTooltipState(): void {
    this.tooltipService.tooltipState$.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((state) => {
      if (state === TooltipState.Opened) {
        this.opened.emit();
        return;
      }
      if (state === TooltipState.Closed) {
        this.closed.emit();
        return;
      }
    });
  }

  protected load(event): void {
    this.tooltipService.show({
      ...this.getTooltipProperties(),
      event,
    });
  }

  protected getTooltipProperties(): TooltipProperties {
    return {
      id: this.tooltipId,
      tooltipText: this.tooltipText,
      width: this.appTooltipWidth,
      height: this.appTooltipHeight,
      maxWidth: this.appTooltipMaxWidth,
      minWidth: this.appTooltipMinWidth,
      placement: this.appTooltipPlacement,
      autoPlacement: this.appTooltipAutoPlacement,
      tooltipClass: this.appTooltipClass,
      theme: this.appTooltipTheme,
      offset: this.appTooltipOffset,
      animationDisable: this.appTooltipAnimationDisable,
      padding: this.appTooltipPadding,
      zIndex: this.appTooltipZIndex,
      noArrow: this.appTooltipNoArrow,
      hideDelay: this.appTooltipHideDelay,
      showDelay: this.appTooltipShowDelay,
      trigger: this.appTooltipTrigger,
      position: this.appTooltipPosition,
      whiteSpace: this.appTooltipWhiteSpace,
      borderRadius: this.appTooltipBorderRadius,
      shadow: this.appTooltipShadow,
      pointerEvents: this.appTooltipPointerEvents,
      fontSize: this.appTooltipFontSize,
      hideOnClick: this.appTooltipHideOnClick,
    };
  }

  protected hideTooltip(): void {
    this.tooltipService.hideWithDelay();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
