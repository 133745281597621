import { Component, ChangeDetectionStrategy, Input, ContentChildren, QueryList } from '@angular/core';
import { OptionComponent } from '@shared/components/select-v2/option';

@Component({
  selector: 'app-optgroup',
  templateUrl: './optgroup.component.html',
  styleUrls: ['./optgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptgroupComponent {
  @Input() label: string;

  @ContentChildren(OptionComponent) public optionComponents: QueryList<OptionComponent>;
}
