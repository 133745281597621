import { Injectable } from '@angular/core';
import { ColumnApi } from '@ag-grid-community/core';
import { GridApi, RowNode } from 'src/types/ag-grid';

class StopIterationException extends Error {
  constructor() {
    super('StopIterationException');
  }
}

interface Context {
  invisibleGroupsFields: string[];
}

@Injectable()
export class GridHelperService<T = any> {
  protected gridApi: GridApi<T>;
  private columnApi: ColumnApi;
  private context: Context;

  constructor() {}

  public initApi(api: GridApi, columnApi: ColumnApi, context?: Context & any): void {
    this.gridApi = api;
    this.columnApi = columnApi;
    this.context = context;
  }

  public getSelected(): RowNode<T> | null {
    const selectedNodes = this.gridApi.getSelectedNodes();
    return selectedNodes && selectedNodes.length === 1 ? selectedNodes[0] : null;
  }

  public getRowsByGroup(parentId: string): RowNode<T>[] {
    return this.getFilteredRows().filter((row: RowNode<T>) => row.parent.id === parentId);
  }

  public selectById(id: string): void {
    const node = this.gridApi.getRowNode(id);
    this.selectRow(node);
  }

  public selectNext(currentRow?: RowNode<T> | null): void {
    currentRow ||= this.getSelected();
    if (!currentRow) {
      return;
    }
    const nextRow = this.getNextRow(currentRow.rowIndex);
    this.selectRow(nextRow);
  }

  public selectPrevious(currentRow?: RowNode<T> | null): void {
    currentRow ||= this.getSelected();
    if (!currentRow) {
      return;
    }

    const previousRow = this.getPreviousRow(currentRow);
    this.selectRow(previousRow);
  }

  public isEmptyList(): boolean {
    return this.gridApi.getDisplayedRowCount() === 0;
  }

  private getFilteredRows(): RowNode<T>[] {
    const rows = [];
    this.gridApi.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
      rows.push(node);
    });
    return rows;
  }

  protected getRows(): RowNode<T>[] {
    const rows = [];
    this.gridApi.forEachNode((node: RowNode<T>) => {
      rows.push(node);
    });
    return rows;
  }

  public selectRow(row: RowNode<T>): void {
    if (!row) {
      return;
    }
    if (row.rowIndex === null) {
      let parent = row.parent;
      while (parent && (!parent.expanded || this.isInvisibleGroup(parent))) {
        parent.setExpanded(true);
        parent = parent.parent;
      }
      row = this.gridApi.getRowNode(row.id);
    }
    row.setSelected(true);
    this.gridApi.ensureIndexVisible(row.rowIndex);
    this.focusRow(row);
  }

  public focusRow(row: RowNode<T>): void {
    this.gridApi.setFocusedCell(row.rowIndex, this.columnApi.getAllDisplayedColumns()[0]);
  }

  public selectFirstNonGroupRow(row: RowNode<T>): void {
    const firstChild = row.childrenAfterSort?.[0];
    if (!firstChild) {
      return;
    }
    if (firstChild.group && !firstChild.expanded && !this.isInvisibleGroup(firstChild)) {
      firstChild.setSelected(true);
      return;
    }
    if (firstChild.group) {
      this.selectFirstNonGroupRow(firstChild);
      return;
    }
    firstChild.setSelected(true);
  }

  public getPreviousRow(currentRow?: RowNode<T>): RowNode<T> {
    currentRow ||= this.getSelected();
    const index = currentRow.rowIndex;

    const previous = this.findPreviousNode(index, (node: RowNode<T>) => {
      const firstRealGroup = this.findFirstRealGroup(node);
      return (
        !this.isInvisibleGroup(node) && ((node.group && !node.expanded) || (!node.group && firstRealGroup.expanded))
      );
    });

    const parent = previous?.parent;
    if (parent && !parent.expanded) {
      parent.setExpanded(true);
    }
    return previous;
  }

  public switchToNextEditor(node: RowNode, currentColumnId: string): boolean {
    this.gridApi.stopEditing();
    const columns = this.columnApi.getAllDisplayedColumns();
    const currentColumnIndex = columns.findIndex((column) => column.getColId() === currentColumnId);
    const nextFocusColumn = columns.find((column, index) => column.getColDef().editable && index > currentColumnIndex);
    if (nextFocusColumn) {
      this.gridApi.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: nextFocusColumn.getColId(),
      });
      return true;
    }
    return false;
  }

  private isInvisibleGroup(node: RowNode<any>): boolean {
    return this.context.invisibleGroupsFields?.includes(node.field);
  }

  private findPreviousNode(rowIndex: number, isFound: (node: RowNode<T>) => boolean): RowNode<T> {
    let foundNode: RowNode<T>;
    let findLastItem = false;

    this.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
      if (node.rowIndex === rowIndex && foundNode && !findLastItem) {
        return true;
      }
      if (node.rowIndex === rowIndex && !foundNode) {
        findLastItem = true;
      }
      if (isFound(node)) {
        foundNode = node;
      }
    });

    return foundNode;
  }

  private forEachNodeAfterFilterAndSort(stopIterationCallback: (node: RowNode<T>) => boolean): void {
    try {
      this.gridApi.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
        if (stopIterationCallback(node)) {
          throw new StopIterationException();
        }
      });
    } catch (e) {
      if (e instanceof StopIterationException) {
        return;
      }
      throw e;
    }
  }

  public getNextRow(rowIndex?: number): RowNode<T> {
    rowIndex ||= this.getSelected()?.rowIndex;

    if (rowIndex == null) {
      return;
    }

    return this.findNextNode(rowIndex, (node: RowNode<T>) => {
      if (this.isInvisibleGroup(node)) {
        return;
      }
      const firstRealGroup = this.findFirstRealGroup(node);
      const underRootNode = firstRealGroup.level === -1;
      const underExpandedGroup = !node.group && firstRealGroup.expanded;
      const collapsedGroup = node.group && !node.expanded;
      return underRootNode || underExpandedGroup || collapsedGroup;
    });
  }

  public findNextNotGroupedNodeByPredicate(rowIndex: number, predicate?: (row: RowNode<T>) => boolean): RowNode<T> {
    return this.findNextNode(rowIndex, (node: RowNode<T>) => !node.group && (!predicate || predicate(node)));
  }

  public findNextNode(rowIndex: number, isFound: (node: RowNode<T>) => boolean): RowNode<T> {
    let foundNode: RowNode<T>;
    let firstFoundNode: RowNode<T>;
    let nextByRowIndex = false;

    this.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
      const satisfiedNode = isFound(node);
      if (satisfiedNode) {
        firstFoundNode = firstFoundNode ?? node;
      }
      if (satisfiedNode && nextByRowIndex) {
        foundNode = node;
        return true;
      }
      if (node.rowIndex !== null && node.rowIndex >= rowIndex) {
        nextByRowIndex = true;
      }
    });

    return foundNode ?? firstFoundNode;
  }

  /**
   * Focus on the first real row. Any groups are ignored.
   */
  public focusFirstRealRow(): void {
    this.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
      if (node.group) {
        return;
      }
      this.focusRow(node);
      this.selectRow(node);
      return true;
    });
  }

  public collapseAllChildren(node: RowNode<T>): void {
    const groups = node.childrenAfterGroup.filter((child) => {
      if (!child.group) {
        return;
      }
      const currentColID = child.rowGroupColumn?.getColDef().colId;
      const groupExcludedFromCollapseList =
        currentColID && this.context.invisibleGroupsFields?.find((g) => g === currentColID);

      return child.group && !groupExcludedFromCollapseList;
    });
    groups.forEach((child) => {
      child.setExpanded(false);
    });
  }

  public applyCollapsedState(groups: Record<string, boolean>): void {
    if (!groups) {
      return;
    }
    let wasGrouping = false;
    this.gridApi.forEachNode((node) => {
      if (node.group) {
        const collapsed = groups[node.key];
        if (collapsed !== undefined && !!collapsed !== !node.expanded) {
          node.expanded = !collapsed;
          wasGrouping = true;
        }
      }
    });
    if (wasGrouping) {
      this.gridApi.onGroupExpandedOrCollapsed();
    }
  }

  public expandCurrentGroup(row?: RowNode<T>): void {
    row = row || this.getSelected();
    if (row.expanded) {
      return;
    }
    row.setExpanded(true);
    this.selectFirstNonGroupRow(row);
  }

  public expandAllGroups(): void {
    this.gridApi.expandAll();
  }

  public collapseAllGroups(maxGroupLevel = 0): void {
    this.gridApi.forEachNode((node) => {
      if (!node.group || node.level < maxGroupLevel) {
        return;
      }
      if (this.isInvisibleGroup(node)) {
        return;
      }
      node.expanded = false;
    });
    this.gridApi.onGroupExpandedOrCollapsed();
    this.selectFirstTopLevelGroup();
  }

  public focusFirstGroup(): void {
    this.forEachNodeAfterFilterAndSort((node: RowNode<T>) => {
      if (!node.group) {
        return;
      }
      this.focusRow(node);
      this.selectRow(node);
      return true;
    });
  }

  private selectFirstTopLevelGroup(): void {
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      if (node.group && node.level === 0) {
        node.setSelected(true);
        this.focusRow(node);
        return;
      }
    });
  }

  public collapseCurrentGroup(row?: RowNode<T>): void {
    row = row || this.getSelected();
    if (row.expanded && row.group && !this.isInvisibleGroup(row)) {
      this.selectRow(row);
      row.setExpanded(false);
      return;
    }
    if (!row.parent || row.parent.level === -1) {
      return;
    }
    this.collapseCurrentGroup(row.parent);
  }

  public findFirstRealGroup<T2 = T>(row: RowNode<T2>): RowNode<T2> {
    if (!row.group || this.isInvisibleGroup(row)) {
      return this.findFirstRealGroup(row.parent);
    }
    return row;
  }

  public scrollToSelectedRow(): void {
    const selectedRow = this.getSelected();
    if (!selectedRow) {
      return;
    }
    this.gridApi.ensureIndexVisible(selectedRow.rowIndex);
  }
}
