import { Injectable } from '@angular/core';
import { AutofixType, SelectionRange } from '@shared/models';
import {
  DeleteSegmentAction,
  InsertSegmentAction,
  SegmentActionsTypes,
  SegmentAction,
} from '@shared/components/segment-editor/models';
import { deepClone, propComparator } from '@shared/tools';
import { SegmentElementType, TagSegmentElement } from '@shared/models';
import { Segment, SegmentElement } from '@generated/api';
import { createNewSegmentFromSelection, insertSegment, removeSegment } from '@shared/tools/segment';

interface EditorChange {
  actions: SegmentAction[];
  selectionRange: SelectionRange[];
}

@Injectable()
export class SegmentHelperService {
  private originSegment: Segment;
  private changes: EditorChange[] = [];
  private undone: EditorChange[] = [];
  private segment: Segment;

  #selectionRange: SelectionRange[] = [];

  constructor() {}

  hasChanges(): boolean {
    return !!this.changes.length;
  }

  getSegment(): Segment {
    return this.segment;
  }

  /*
   * Method for preserve selection range.
   * Allow to get actual selection position after mutations
   */
  public setSelections(selectionRange: SelectionRange[]): void {
    this.#selectionRange = selectionRange;
  }

  get selectionRange() {
    return this.#selectionRange;
  }

  getTagElements(): TagSegmentElement[] {
    if (!this.segment) {
      return [];
    }
    return this.segment.elements.filter(
      (element) => element.elementType === SegmentElementType.Tag
    ) as TagSegmentElement[];
  }

  getTextValue(): string {
    if (!this.segment) {
      return '';
    }
    return this.segment.text;
  }

  initSegment(segment: Segment) {
    if (segment) {
      this.originSegment = deepClone(segment);
      this.segment = deepClone(segment);
    } else {
      this.originSegment = null;
      this.segment = null;
    }
    this.changes = [];
    this.undone = [];
  }

  public dispatch(actions: SegmentAction[], ignoreUndo = false) {
    if (!ignoreUndo) {
      const editorChange: EditorChange = { actions, selectionRange: deepClone(this.#selectionRange) };
      this.changes.push(editorChange);
    }
    this.segment = this.applyChanges(this.segment, [actions]);
  }

  undo() {
    if (!this.changes.length) {
      return;
    }
    const change = this.changes.pop();
    this.undone.push(change);
    this.segment = deepClone(this.originSegment);
    this.segment = this.applyChanges(
      this.segment,
      this.changes.map((c) => c.actions)
    );
    this.#selectionRange = deepClone(change.selectionRange);
  }

  redo() {
    if (!this.undone.length) {
      return;
    }

    const undone = this.undone.pop();
    this.changes.push(undone);
    this.segment = deepClone(this.originSegment);
    this.segment = this.applyChanges(this.segment, [undone.actions]);
  }

  insertText(text: string, start: number) {
    this.dispatch([
      new InsertSegmentAction(
        {
          text,
          tags: [],
        },
        start
      ),
    ]);
  }

  getNewSegmentFromSelection(selection: SelectionRange): Segment {
    return createNewSegmentFromSelection(this.segment, selection);
  }

  private applyChanges(segment: Segment, changes: SegmentAction[][]): Segment {
    let updatedSegment = segment;
    changes.forEach((change) => {
      change.forEach((action) => {
        if (action.type === SegmentActionsTypes.Insert) {
          updatedSegment = this.applyInsertAction(updatedSegment, action as InsertSegmentAction);
        } else if (action.type === SegmentActionsTypes.Delete) {
          updatedSegment = this.applyDeleteAction(updatedSegment, action as DeleteSegmentAction);
        }
      });
    });
    return updatedSegment;
  }

  private applyInsertAction(segment: Segment, action: InsertSegmentAction): Segment {
    this.#selectionRange = this.calcSelectionRangePosition(
      this.#selectionRange || [],
      action.start,
      action.partOfSegments.text.length
    );

    const additionalSegment: Segment = {
      text: action.partOfSegments.text,
      hasChanges: false,
      originalText: action.partOfSegments.text,
      elements: this.getAllSegmentElements(action.partOfSegments.text, action.partOfSegments.tags),
    };
    const newSegmentElements = insertSegment(segment, additionalSegment, action.start);

    return newSegmentElements;
  }

  private applyDeleteAction(segment: Segment, action: DeleteSegmentAction): Segment {
    this.#selectionRange = this.calcSelectionRangePosition(this.#selectionRange, action.start, -action.length);
    return removeSegment(segment, action.start, action.length);
  }
  removeSegmentElements(elements: SegmentElement[], removeStartIndex: number, removeLength: number): SegmentElement[] {
    const sortedElements = elements.sort(propComparator('start'));
    const result: SegmentElement[] = [];
    const removeEndIndex = removeStartIndex + removeLength;
    let newRemoveStartIndex = removeStartIndex;

    sortedElements.forEach((element) => {
      if (this.checkRangesIntersection([element.start, element.end], [removeStartIndex, removeEndIndex])) {
        const newRemoveLength = removeEndIndex - newRemoveStartIndex;
        const elementNewStart = result[result.length - 1]?.end || 0;
        const startIndexElementTextRemove = newRemoveStartIndex - element.start;
        let endIndexElementTextRemove = startIndexElementTextRemove + newRemoveLength;
        if (endIndexElementTextRemove > element.length) {
          endIndexElementTextRemove = element.length;
        }
        const elementCutOffCharsCount = endIndexElementTextRemove - startIndexElementTextRemove;
        const elementNewText = this.removeSubstring(
          element.text,
          startIndexElementTextRemove,
          endIndexElementTextRemove
        );
        if (elementNewText.length) {
          result.push({
            ...element,
            start: elementNewStart,
            end: elementNewStart + elementNewText.length,
            text: elementNewText,
            length: elementNewText.length,
          });
        }

        newRemoveStartIndex += elementCutOffCharsCount;
      } else if (removeEndIndex <= element.start) {
        result.push({
          ...element,
          start: element.start - removeLength,
          end: element.end - removeLength,
        });
      } else {
        result.push(element);
      }
    });
    return result;
  }

  private calcSelectionRangePosition(
    selectionRanges: SelectionRange[],
    startWithPosition: number,
    offset: number
  ): SelectionRange[] {
    const recalculatedSelectionRange = selectionRanges
      // We need to delete autofix highlight when user edit inside such highlighting
      .filter((selectionRange) => {
        const isAutofixWithoutPairs = selectionRange.autofixType === AutofixType.Replace;
        if (!selectionRange.isFixAvailable || isAutofixWithoutPairs) {
          return true;
        }
        const isEditingOutsideFixRange =
          startWithPosition <= selectionRange.start || startWithPosition >= selectionRange.end;
        return isEditingOutsideFixRange;
      })
      .map((selectionRange) => {
        // NOTE: данный метод не учитывает редактирование внутри сегмента подсветки ошибки,
        // а так же в диапазоне связанных элементов. Данный подход доступен в draft/smart-autofix ветке
        if (selectionRange.start >= startWithPosition) {
          return {
            ...selectionRange,
            start: selectionRange.start + offset,
            end: selectionRange.end + offset,
            length: selectionRange.end - selectionRange.start,
          };
        }
        return selectionRange;
      });

    return recalculatedSelectionRange;
  }

  private removeSubstring(text: string, startIndex: number, endIndex: number): string {
    if (endIndex < startIndex) {
      startIndex = endIndex;
    }

    const a = text.substring(0, startIndex);
    const b = text.substring(endIndex);

    return a + b;
  }

  private getAllSegmentElements(text: string, tags: TagSegmentElement[]) {
    const resultElements: SegmentElement[] = [];
    const sortedTags = tags.sort(propComparator('start'));
    let previousTagEndIndex = 0;
    sortedTags.forEach((tag) => {
      if (previousTagEndIndex < tag.start) {
        const subString = text.slice(previousTagEndIndex, tag.start);
        resultElements.push({
          text: subString,
          start: previousTagEndIndex,
          end: tag.start,
          elementType: SegmentElementType.Text,
          length: subString.length,
        });
      }
      resultElements.push(tag);
      previousTagEndIndex = tag.end;
    });
    if (previousTagEndIndex < text.length) {
      const subString = text.slice(previousTagEndIndex, text.length);
      resultElements.push({
        text: subString,
        start: previousTagEndIndex,
        end: text.length,
        elementType: SegmentElementType.Text,
        length: subString.length,
      });
    }
    return resultElements.sort(propComparator('start'));
  }

  private checkRangesIntersection(aRange: [number, number], bRange: [number, number]): boolean {
    const pointInRange = (range, point): boolean => range[0] < point && range[1] > point;
    return (
      pointInRange(aRange, bRange[0]) ||
      pointInRange(aRange, bRange[1]) ||
      pointInRange(bRange, aRange[0]) ||
      pointInRange(bRange, aRange[1]) ||
      (bRange[0] === aRange[0] && bRange[1] === aRange[1])
    );
  }
}
