import { LocalizationPlatformFileModel, LocalizationPlatformProjectModel } from '@generated/api';

enum ActionTypes {
  LOAD_LOCALIZATION_PLATFORMS_LIST = '[LocalizationPlatforms] Load Localization Platforms',
  SET_PLATFORM_CONNECTION = '[LocalizationPlatforms] Set Localization Platform Connection',
  DELETE_PLATFORM_CONNECTION = '[LocalizationPlatforms] Delete Localization Platform Connection',
  SET_LOCALIZATION_PLATFORM_PROJECTS = '[LocalizationPlatforms] Set Localization Platform Project',
  SET_LOCALIZATION_PLATFORM_PROJECT_FILES = '[LocalizationPlatforms] Set Localization Platform Project Files',
  LOCALIZATION_PLATFORM_FILES_IS_ATTACHED = '[LocalizationPlatforms] Localization Platform Files Is Attached',
}

export class LoadLocalizationPlatformsList {
  static readonly type = ActionTypes.LOAD_LOCALIZATION_PLATFORMS_LIST;

  constructor() {}
}

export class SetLocalizationPlatformConnection {
  static readonly type = ActionTypes.SET_PLATFORM_CONNECTION;

  constructor(public platformId: string, public connectionId: string) {}
}

export class DeleteLocalizationPlatformConnection {
  static readonly type = ActionTypes.DELETE_PLATFORM_CONNECTION;

  constructor(public platformId: string) {}
}

export class SetLocalizationPlatformProjects {
  static readonly type = ActionTypes.SET_LOCALIZATION_PLATFORM_PROJECTS;

  constructor(public connectionId: string, public projects: LocalizationPlatformProjectModel[]) {}
}

export class SetLocalizationPlatformProjectFiles {
  static readonly type = ActionTypes.SET_LOCALIZATION_PLATFORM_PROJECT_FILES;

  constructor(public projectId: string, public files: LocalizationPlatformFileModel[]) {}
}

export class LocalizationPlatformFilesIsAttached {
  static readonly type = ActionTypes.LOCALIZATION_PLATFORM_FILES_IS_ATTACHED;

  constructor(public payload: boolean) {}
}
