import { Injectable } from '@angular/core';

import { TagSegmentElement, TagType } from '@shared/models';
import { propComparator } from '@shared/tools';

/**
 * Validity checking of tag`s list. Invalid tags are tags without opened (or closed)
 * pairs (except standalone tag`s type). This implementation partially based on
 * balanced bracket sequence algorithm.
 */
@Injectable()
export class TagsValidationService {
  /*
   * This method mutates the input object, marking invalid tags as `{ ..., invalid: true }`
   */
  public validateTags(tags: TagSegmentElement[]): TagSegmentElement[] {
    const tagsStack: { [key: string]: TagSegmentElement[] } = {};

    tags
      .sort(propComparator('start'))
      .filter((t) => t.tagType !== TagType.Standalone)
      .forEach((t) => {
        const tag = { ...t };
        const rawTag = this.getRawTag(tag.rawText);
        if (!tagsStack[rawTag]) {
          tagsStack[rawTag] = [];
        }

        const tagStack = tagsStack[rawTag];

        if (tag.tagType === TagType.End && !tagStack.length) {
          tag.invalid = true;
          return;
        }

        if (tag.tagType === TagType.End) {
          tagStack.pop();
          return;
        }

        tagStack.push(tag);
      });

    Object.values(tagsStack)
      .filter((t) => t.length)
      .reduce((acc, t) => [...acc, ...t], [])
      .forEach((t) => {
        t.invalid = true;
      });

    return tags;
  }

  private getRawTag(tag: string): string {
    const slashPosition = 1;

    if (tag[slashPosition] === '/') {
      return tag.replace('/', '');
    }

    return tag;
  }
}
