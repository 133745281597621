import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuDirective } from './context-menu.directive';
import { DisableControlDirective } from './disable-control';
import { FilesDragAndDropHostDirective } from './files-drag-and-drop-host';
import { RoleDirective } from './role.directive';

const SHARED_DIRECTIVES = [FilesDragAndDropHostDirective, DisableControlDirective, ContextMenuDirective, RoleDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...SHARED_DIRECTIVES, ContextMenuDirective],
  exports: [...SHARED_DIRECTIVES],
})
export class DirectivesModule {}
