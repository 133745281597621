import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RadioButtonComponent } from './radio-button.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { TooltipModule } from '@shared/components/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    TooltipModule,
    DirectivesModule,
  ],
  declarations: [RadioButtonComponent],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
