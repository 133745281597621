import { Injectable } from '@angular/core';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  AddTemporarySourceLanguage,
  AddTemporaryTargetLanguage,
  ClearSourceTargetLanguage,
  ClearTemporaryLanguages,
  ResetLanguages,
  SetDefaultLanguage,
  SetLanguage,
  SetLanguagesFromFiles,
  SetSourceTargetLanguage,
} from './language.actions';
import { getUnique, propComparator } from '@shared/tools';
import type { LanguageStateModel, ProjectLanguageState } from './language.state.model';
import { SetDefaultSourceTargetLanguages } from '@store/glossary-terms-store';
import { ProjectState } from '@store/project-store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { AvailableLanguagesState } from '@store/available-languages';
import { LanguageModel, ProjectFileInfo } from '@generated/api';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';
import { Observable } from 'rxjs';

const defaultProjectState: ProjectLanguageState = {
  currentLanguage: null,
  source: {},
  target: {},
  temporarySource: {},
  temporaryTarget: {},
};

@AsyncStorage
@StateRepository()
@State<LanguageStateModel>({
  name: 'language',
  defaults: {},
})
@Injectable()
export class LanguageState {
  constructor(private store: Store) {}

  @Selector([LanguageState, ProjectState.projectId])
  public static currentLanguage(state: LanguageStateModel, projectId: string): LanguageModel {
    return state && state[projectId]?.currentLanguage;
  }

  @Selector([LanguageState, ProjectState.projectId])
  public static sourceLanguages(state: LanguageStateModel, projectId: string): LanguageModel[] {
    if (!state) {
      return null;
    }
    const langs = Object.values({
      ...state[projectId]?.source,
      ...state[projectId]?.temporarySource,
    });

    return getUnique<LanguageModel>(langs, 'code').sort(propComparator('name'));
  }

  @Selector([LanguageState, ProjectState.projectId])
  public static targetLanguages(state: LanguageStateModel, projectId: string): LanguageModel[] {
    if (!state) {
      return [];
    }

    const langs = Object.values({
      ...state[projectId]?.target,
      ...state[projectId]?.temporaryTarget,
    });

    return getUnique<LanguageModel>(langs, 'code').sort(propComparator('name'));
  }

  @Action(SetDefaultLanguage)
  public setDefaultLanguage(): Observable<any> {
    const currentLanguage = this.store.selectSnapshot(LanguageState.currentLanguage);
    if (currentLanguage) {
      return;
    }
    const targetLangs = this.store.selectSnapshot(LanguageState.targetLanguages);
    const sourceLangs = this.store.selectSnapshot(LanguageState.sourceLanguages);
    if (targetLangs.length) {
      return this.store.dispatch(new SetLanguage(targetLangs[0]));
    } else if (sourceLangs.length) {
      return this.store.dispatch(new SetLanguage(sourceLangs[0]));
    }
  }

  @Action(SetLanguage)
  public setLanguage(ctx: StateContext<LanguageStateModel>, { lang: currentLanguage }: SetLanguage): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: patch({
          currentLanguage,
        }),
      })
    );
  }

  @Action(SetLanguagesFromFiles)
  public setLanguagesFromFiles(ctx: StateContext<LanguageStateModel>, { projectFiles }: SetLanguagesFromFiles): void {
    if (!projectFiles || !projectFiles.length) {
      ctx.dispatch(new ClearSourceTargetLanguage());
      return;
    }

    const source = {};
    const target = {};

    projectFiles.forEach((file: ProjectFileInfo) => {
      const languagePair = file.languagePair;

      if (!languagePair) {
        return;
      }

      if (languagePair.source?.code) {
        const fileSource: LanguageModel = file.languagePair.source;
        source[fileSource.code] = fileSource;
      }

      if (languagePair.target?.code) {
        const fileTarget: LanguageModel = file.languagePair.target;
        target[fileTarget.code] = fileTarget;
      }
    });

    ctx.dispatch(new SetSourceTargetLanguage(source, target));
    ctx.dispatch(new SetDefaultLanguage());
  }

  @Action(SetSourceTargetLanguage)
  public setSourceTargetLanguage(
    ctx: StateContext<LanguageStateModel>,
    { source, target }: SetSourceTargetLanguage
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const previousState = ctx.getState() || {};

    // TODO: VW-794 tmp, use patchOrCreate operator after it'll be ready!
    // Init project id if not exist,
    if (!previousState || !previousState[projectId]) {
      ctx.setState(patch({ [projectId]: {} as any }));
    }

    ctx.setState(
      patch({
        [projectId]: patch({
          source,
          target,
        }),
      })
    );

    const sourceLanguages = this.store.selectSnapshot(LanguageState.sourceLanguages);
    const targetLanguages = this.store.selectSnapshot(LanguageState.targetLanguages);
    this.store.dispatch(new SetDefaultSourceTargetLanguages(sourceLanguages, targetLanguages));
  }

  @Action(AddTemporaryTargetLanguage)
  public addTemporaryTargetLanguage(
    ctx: StateContext<LanguageStateModel>,
    { langCode }: AddTemporaryTargetLanguage
  ): void {
    this.addTemporaryLanguageByDirection(ctx, langCode, 'temporaryTarget');
  }

  @Action(AddTemporarySourceLanguage)
  public addTemporarySourceLanguage(
    ctx: StateContext<LanguageStateModel>,
    { langCode }: AddTemporarySourceLanguage
  ): void {
    this.addTemporaryLanguageByDirection(ctx, langCode, 'temporarySource');
  }

  private addTemporaryLanguageByDirection(
    ctx: StateContext<LanguageStateModel>,
    langCode: string,
    direction: 'temporaryTarget' | 'temporarySource'
  ): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    if (!langCode) {
      return;
    }

    const code = this.getShortCode(langCode);
    const lang = this.store.selectSnapshot(AvailableLanguagesState.languageByCode(code));

    if (!lang) {
      return;
    }

    ctx.setState(
      patch({
        [projectId]: patch<ProjectLanguageState>({
          [direction]: patch({
            [code]: lang,
          }),
        }),
      })
    );

    const sourceLanguages = this.store.selectSnapshot(LanguageState.sourceLanguages);
    const targetLanguages = this.store.selectSnapshot(LanguageState.targetLanguages);
    this.store.dispatch(new SetDefaultSourceTargetLanguages(sourceLanguages, targetLanguages));
  }

  @Action(ClearSourceTargetLanguage)
  public clearSourceTargetLanguage(ctx: StateContext<LanguageStateModel>): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState() || {};
    const projectState = state[projectId] || defaultProjectState;
    ctx.setState(
      patch({
        [projectId]: {
          ...defaultProjectState,
          currentLanguage: projectState.currentLanguage,
        },
      })
    );
    this.store.dispatch(new SetDefaultSourceTargetLanguages([], []));
  }

  @Action(ClearTemporaryLanguages)
  public clearSpecificLanguages(ctx: StateContext<LanguageStateModel>): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    const state = ctx.getState() || {};
    const projectState = state[projectId] || defaultProjectState;
    ctx.setState(
      patch({
        [projectId]: {
          ...projectState,
          temporarySource: {},
          temporaryTarget: {},
        },
      })
    );
    const sourceLanguages = this.store.selectSnapshot(LanguageState.sourceLanguages);
    const targetLanguages = this.store.selectSnapshot(LanguageState.targetLanguages);
    this.store.dispatch(new SetDefaultSourceTargetLanguages(sourceLanguages, targetLanguages));
  }

  @Action(ResetLanguages)
  public resetLanguages(ctx: StateContext<LanguageStateModel>): void {
    const projectId = this.store.selectSnapshot(ProjectState.projectId);
    ctx.setState(
      patch({
        [projectId]: defaultProjectState,
      })
    );
  }

  private getShortCode(langCode: string): string {
    let code: string = langCode.toUpperCase();
    const langCodesParts = code.split('-');

    if (langCodesParts.length === 2 && langCodesParts[0] === langCodesParts[1]) {
      code = langCodesParts[0];
    }
    return code;
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<LanguageStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.id];
    ctx.setState(state);
  }
}
