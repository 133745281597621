import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  constructor() {}

  get isMac(): boolean {
    const platform = navigator?.userAgentData?.platform || navigator?.platform;
    return platform.toLowerCase().indexOf('MAC') > -1 || !!/(Mac|iPhone|iPod|iPad)/i.test(platform);
  }
}
