import { NgModule } from '@angular/core';
import { MarkFilteredPipe } from './mark-filtered.pipe';
import { SortByPipe } from './sort-by.pipe';
import { TrimPipe } from './trim.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DatePipe } from '@angular/common';
import { PrettyDateTimePipe } from './pretty-date-time.pipe';
import { PrettyKeyPipe } from './pretty-key.pipe';
import { DynamicPipe } from './dynamic.pipe';
import { SubscriptionPrettyStatusPipe } from './subscription-pretty-status.pipe';

export const COMMON_PIPES = [
  TruncatePipe,
  MarkFilteredPipe,
  SortByPipe,
  TrimPipe,
  SafePipe,
  PrettyDateTimePipe,
  PrettyKeyPipe,
  DynamicPipe,
  SubscriptionPrettyStatusPipe,
];

@NgModule({
  declarations: [...COMMON_PIPES],
  exports: [...COMMON_PIPES],
  providers: [DatePipe, ...COMMON_PIPES],
})
export class PipesModule {}
