import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from '@shared/layout';
import { ERRORS_ROUTE, ORGANIZATION_DETAIL_ROUTE, PROJECTS_ROUTE, SETTINGS_ROUTE } from './app.routes';
import { OrganizationAcceptInviteComponent } from './core/components/organization-accept-invite/organization-accept-invite.component';
import { SignInCallbackComponent } from './core/components/sign-in-callback.component';

// TODO: неудачная структура. Нужно декомпозировать каждый маршрут
// для того чтобы иметь возможность четко определять текущую страницу для
// последующих перенаправлений, а так же для повышения читабельности кода, убирая вложенность маршрутов.
// Использовать пример PROJECTS_ROUTE. Посмотреть нет ли лишних зависимостей как было с report layout
const routes: Routes = [
  {
    path: '',
    component: WrapperComponent,
    canActivate: [],
    children: [
      {
        path: 'invite/accept',
        component: OrganizationAcceptInviteComponent,
      },
      {
        path: 'signin-callback',
        component: SignInCallbackComponent,
      },
      ERRORS_ROUTE,
      SETTINGS_ROUTE,
      PROJECTS_ROUTE,
      ORGANIZATION_DETAIL_ROUTE,
      {
        path: '**',
        redirectTo: '/settings/files',
      },
    ],
  },
];

// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, {
  initialNavigation: 'enabled',
});
