import { Injectable } from '@angular/core';
import { forkJoin, from, merge, Observable, of } from 'rxjs';
import Dexie, { IndexableType } from 'dexie';

import { IssueTypeNames, QualityIssue } from '@shared/models';
import { BaseDAO } from './base.dao';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { IssueType } from '@generated/api';

@Injectable()
export class QualityIssueDAO extends BaseDAO {
  private connections: { [key: string]: Dexie } = {};
  private readonly storePrefix = 'qualityIssues';
  private readonly storeSchema = 'id';
  protected dbPrefix: string = `${this.dbPrefix}.project`;

  private readonly storeNames = Object.keys(IssueTypeNames).map((name) => `qualityIssues.${name}`);
  private readonly qualityIssuesStores = this.storeNames.reduce((acc, k) => {
    acc[k] = this.storeSchema;
    return acc;
  }, {});
  private readonly metaStoreSchema = 'key';
  private readonly metaUpdatedKey = 'updated';
  private readonly metaStoreName = 'meta';
  private readonly databaseExpirationDays = 7;

  constructor() {
    super();
  }

  public bulkCreateOrUpdate(
    projectId: string,
    issueType: IssueType,
    qualityIssues: QualityIssue[]
  ): Observable<IndexableType> {
    const conn = this.getConnection(projectId);
    const storeName = this.getStoreName(issueType);
    return from(conn.table(storeName).bulkPut(qualityIssues)).pipe(
      tap(async () => {
        await this.updateMeta(conn);
      })
    );
  }

  public bulkRemove(projectId: string, issueType: IssueType, qualityIssuesIds: string[]): Observable<void> {
    const conn = this.getConnection(projectId);
    const storeName = this.getStoreName(issueType);
    return from(conn.table(storeName).bulkDelete(qualityIssuesIds)).pipe(
      tap(async () => {
        await this.updateMeta(conn);
      })
    );
  }

  public clearIssues(projectId: string): Observable<void> {
    const conn = this.getConnection(projectId);
    const deleteSubjects$ = this.storeNames.map((name) => from(conn.table(name).clear()));
    return merge(...deleteSubjects$);
  }

  public count(projectId: string, issueType: IssueType): Observable<number> {
    const conn = this.getConnection(projectId);
    const storeName = this.getStoreName(issueType);
    return from(conn.table(storeName).count());
  }

  public getProjectQualityIssues(projectId: string): Observable<{ [key in IssueType]: QualityIssue[] }> {
    const conn = this.getConnection(projectId);
    const qualityIssues$ = Object.keys(IssueTypeNames).map((issueType) => {
      const storeName = this.getStoreName(+issueType as IssueType);
      return from(conn.table(storeName).toArray()).pipe(map((r) => ({ [issueType]: r })));
    });
    return forkJoin(qualityIssues$).pipe(
      map((qi: Array<{ [key in IssueType]: QualityIssue[] }>) =>
        qi.reduce((acc, o) => ({ ...acc, ...o }), {} as { [key in IssueType]: QualityIssue[] })
      )
    );
  }

  public update(projectId: string, qualityIssue: QualityIssue): Observable<number> {
    const conn = this.getConnection(projectId);
    const storeName = this.getStoreName(qualityIssue.issueType);
    return from(conn.table(storeName).update(qualityIssue.id, qualityIssue)).pipe(
      tap(async () => {
        await this.updateMeta(conn);
      })
    );
  }

  public deleteExpired(): Observable<void[]> {
    const dbNamePattern = /^verifika.project\.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/;
    const clearDelay = 5000; // Timeout for non priority task

    return from(Dexie.getDatabaseNames()).pipe(
      delay(clearDelay),
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
      map((dbNames) => dbNames.filter((dbName) => dbName.match(dbNamePattern))),
      switchMap((dbNames) =>
        forkJoin(
          dbNames.map((dbName) =>
            from(this.isDatabaseExpired(dbName)).pipe(
              switchMap((isExpired: boolean) => {
                if (isExpired) {
                  return Dexie.delete(dbName);
                }
                return of(null);
              })
            )
          )
        )
      )
    );
  }

  public dropReportDb(projectId: string): Observable<void> {
    const conn = this.getConnection(projectId);
    return from(conn.delete());
  }

  private getConnection(projectId: string): Dexie {
    const connectionName = this.getDbName(projectId);
    const conn = this.createConnectionIfNotExist(connectionName);
    return conn;
  }

  private createConnectionIfNotExist(dbName: string): Dexie {
    if (!this.connections[dbName]) {
      const conn = new Dexie(dbName);
      this.applyDbSchema(conn);
      this.connections[dbName] = conn;
    }
    return this.connections[dbName];
  }

  private applyDbSchema(conn: Dexie): void {
    conn.version(this.version).stores({ ...this.qualityIssuesStores, meta: this.metaStoreSchema });
  }

  private getDbName(projectId: string): string {
    return `${this.dbPrefix}.${projectId}`;
  }

  private getStoreName(issueType: IssueType): string {
    return `${this.storePrefix}.${issueType}`;
  }

  private updateMeta(conn: Dexie): Promise<any> {
    return conn.table(this.metaStoreName).put({
      key: this.metaUpdatedKey,
      value: new Date(),
    });
  }

  private async isDatabaseExpired(dbName: string): Promise<boolean> {
    const conn = this.createConnectionIfNotExist(dbName);
    const updatedInfo = await conn.table(this.metaStoreName).get(this.metaUpdatedKey);

    if (!updatedInfo) {
      await this.updateMeta(conn);
      return false;
    }
    const lastUpdated: Date = updatedInfo.value;
    const now = new Date();
    lastUpdated.setDate(lastUpdated.getDate() + this.databaseExpirationDays);
    return now > lastUpdated;
  }
}
