<div class="slider">
  <mat-slide-toggle [ngModel]="showInvisibles" (ngModelChange)="changeShowInvisibles($event)"
    >Show non-printing characters</mat-slide-toggle
  >
</div>

<div class="settings-section">
  <div class="settings-subtitle">Tags length</div>
  <mat-radio-group [ngModel]="tagView" (ngModelChange)="changeTagsView($event)">
    <mat-radio-button [value]="view.Short">Small</mat-radio-button>
    <mat-radio-button [value]="view.Medium">Medium</mat-radio-button>
    <mat-radio-button [value]="view.Long">Large</mat-radio-button>
  </mat-radio-group>
</div>

<div class="settings-section">
  <div class="settings-subtitle">Segment display mode</div>
  <mat-radio-group [ngModel]="multiline" (ngModelChange)="changeMultiline($event)">
    <mat-radio-button [value]="true">Wrap text</mat-radio-button>
    <mat-radio-button [value]="false">Clip text</mat-radio-button>
  </mat-radio-group>
</div>

<div class="divider"></div>

<div class="export-title">Report export</div>

<app-button
  class="export-btn"
  theme="outlined"
  prefixIcon="download"
  [appTooltipTriggerFor]="exportTooltip"
  (clickButton)="export()"
  >Download as Excel</app-button
>
<tooltip #exportTooltip="tooltip">
  Exports QA report to Excel ({{
    shortcutsService.eventShortuts[controlPanelKeybindingEvent.EXPORT_QA_REPORT_TO_EXCEL] | prettyKey
  }})
</tooltip>
