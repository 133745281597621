/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AcceptInviteRequest } from '../models/accept-invite-request';
import { AcceptInviteResult } from '../models/accept-invite-result';
import { CreateInviteRequest } from '../models/create-invite-request';

@Injectable({
  providedIn: 'root',
})
export class InvitesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiInvitesPost
   */
  static readonly ApiInvitesPostPath = '/api/invites';

  /**
   * Creates and sends invitations to one or more emails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvitesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesPost$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateInviteRequest
     */
    body?: CreateInviteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InvitesService.ApiInvitesPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates and sends invitations to one or more emails.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvitesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesPost(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateInviteRequest
     */
    body?: CreateInviteRequest
  }): Observable<void> {

    return this.apiInvitesPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiInvitesAcceptPost
   */
  static readonly ApiInvitesAcceptPostPath = '/api/invites/accept';

  /**
   * Accepts an invitation to join an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvitesAcceptPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesAcceptPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.AcceptInviteRequest
     */
    body?: AcceptInviteRequest
  }): Observable<StrictHttpResponse<AcceptInviteResult>> {

    const rb = new RequestBuilder(this.rootUrl, InvitesService.ApiInvitesAcceptPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AcceptInviteResult>;
      })
    );
  }

  /**
   * Accepts an invitation to join an organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvitesAcceptPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesAcceptPost$Plain(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.AcceptInviteRequest
     */
    body?: AcceptInviteRequest
  }): Observable<AcceptInviteResult> {

    return this.apiInvitesAcceptPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AcceptInviteResult>) => r.body as AcceptInviteResult)
    );
  }

  /**
   * Accepts an invitation to join an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvitesAcceptPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesAcceptPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.AcceptInviteRequest
     */
    body?: AcceptInviteRequest
  }): Observable<StrictHttpResponse<AcceptInviteResult>> {

    const rb = new RequestBuilder(this.rootUrl, InvitesService.ApiInvitesAcceptPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AcceptInviteResult>;
      })
    );
  }

  /**
   * Accepts an invitation to join an organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInvitesAcceptPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInvitesAcceptPost$Json(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.AcceptInviteRequest
     */
    body?: AcceptInviteRequest
  }): Observable<AcceptInviteResult> {

    return this.apiInvitesAcceptPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AcceptInviteResult>) => r.body as AcceptInviteResult)
    );
  }

}
