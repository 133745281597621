import { LPAuthFormField } from '@shared/components/localization-platforms/models';
import { CloudRegion } from '@generated/api';

export interface LPAssets {
  fields: LPAuthFormField[];
  logoPath: string;
  submitBtnLabel: string;
  information: string;
  errorMessage: string;
  cloudRegions: CloudRegion[];
}

export const memsourceAssets: LPAssets = {
  fields: [
    {
      label: 'Username',
      placeholder: 'Enter a username...',
      icon: 'user',
      type: 'text',
      fieldName: 'login',
      autocomplete: false,
    },
    {
      label: 'Password',
      placeholder: 'Enter a password...',
      icon: 'password',
      type: 'password',
      fieldName: 'password',
      autocomplete: false,
    },
  ],
  logoPath: '/assets/images/memsource-logo.png',
  submitBtnLabel: 'connect to memsource',
  information: '',
  errorMessage: 'Invalid username or password.',
  cloudRegions: [CloudRegion.Europe, CloudRegion.USA],
};

export const smartcatAssets: LPAssets = {
  fields: [
    {
      label: 'Account ID',
      placeholder: 'Enter an Account ID...',
      icon: 'user',
      type: 'text',
      fieldName: 'login',
      autocomplete: false,
    },
    {
      label: 'API key',
      placeholder: 'Enter an API Key...',
      icon: 'password',
      type: 'password',
      fieldName: 'password',
      autocomplete: false,
    },
  ],
  logoPath: '/assets/images/smartcat-logo.png',
  submitBtnLabel: 'connect to smartcat',
  information: `To learn how to get access to the API, please check out the Smartcat Help Center following this
    <a target="_blank" href="https://help.smartcat.com/hc/en-us/articles/115002475012-Getting-started-with-Smartcat-API">link</a>. `,
  errorMessage: 'Invalid Account ID or API Key or Region',
  cloudRegions: [CloudRegion.Europe, CloudRegion.USA, CloudRegion.Asia],
};

export const crowdinAssets: LPAssets = {
  fields: [
    {
      label: 'Personal Access Token ',
      placeholder: 'Paste your personal access token here...',
      icon: 'user',
      type: 'text',
      fieldName: 'token',
      autocomplete: true,
    },
  ],
  logoPath: '/assets/images/crowdin-logo.png',
  submitBtnLabel: 'connect to crowdin',
  information: `To learn where to find your personal access token, please check out the Crowdin knowledge base following
    <a target="_blank" href="https://support.crowdin.com/account-settings/#api">this link</a>.<br/> Verifika works with Crowdin API v2.`,
  errorMessage: 'Invalid token.',
  cloudRegions: [],
};

export const transifexAssets: LPAssets = {
  fields: [
    {
      label: 'API Token',
      placeholder: 'Paste your API token here...',
      icon: 'user',
      type: 'text',
      fieldName: 'token',
      autocomplete: true,
    },
  ],
  logoPath: '/assets/images/transifex-logo.png',
  submitBtnLabel: 'connect to transifex',
  information: `To learn how to get the API token, please check out the Transifex Help Center following
    <a target="_blank" href="https://docs.transifex.com/account/authentication">this link</a>.`,
  errorMessage: 'Invalid token.',
  cloudRegions: [],
};

export const phraseAssets: LPAssets = {
  fields: [
    {
      label: 'Access Token',
      placeholder: 'Paste your access token here...',
      icon: 'user',
      type: 'text',
      fieldName: 'token',
      autocomplete: true,
    },
  ],
  logoPath: '/assets/images/phrase-logo.png',
  submitBtnLabel: 'connect to phrase',
  information: `To learn how to get the access token, please check out the Phrase Help Center following
    <a target="_blank" href="https://help.phrase.com/help/access-tokens">this link</a>.
    Note that your token must have READ and WRITE access scope to be used in Verifika.`,
  errorMessage: 'Invalid token.',
  cloudRegions: [CloudRegion.Europe, CloudRegion.USA],
};

export const lokaliseAssets: LPAssets = {
  fields: [
    {
      label: 'API Token',
      placeholder: 'Paste your API token here...',
      icon: 'user',
      type: 'text',
      fieldName: 'token',
      autocomplete: true,
    },
  ],
  logoPath: '/assets/images/lokalise-logo.png',
  submitBtnLabel: 'connect to lokalise',
  information: `To learn how to get the API token, please check out the Lokalise Documentation following
    <a target="_blank" href="https://docs.lokalise.com/en/articles/1929556-api-tokens">this link</a>.`,
  errorMessage: 'Invalid token.',
  cloudRegions: [],
};

export const lpAssets: { [key: string]: LPAssets } = {
  '3c55f12c-3eee-4b5a-a88c-fa44600eef40': memsourceAssets,
  '7e630686-7b0c-4a54-93a5-4c69720e027e': smartcatAssets,
  'a8d38947-6e0b-4e3c-b928-1c60e5efc922': crowdinAssets,
  '20e828d5-29e0-453e-a00a-e9bdecf5c1d7': transifexAssets,
  '0d1dc0ae-e145-47c8-9320-2ede4c9fbfe9': phraseAssets,
  'dae58bce-485d-45cf-b533-b178c88a4c0b': lokaliseAssets,
};

export const cloudRegionTitles: Record<CloudRegion, string> = {
  [CloudRegion.Europe]: 'Europe',
  [CloudRegion.USA]: 'USA',
  [CloudRegion.Asia]: 'Asia',
};
