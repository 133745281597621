import { TermbaseInfo } from '@generated/api';

enum ActionTypes {
  LOAD_GLOSSARY_LIST = '[GlossaryList] Load Glossary List',
  LOAD_GLOSSARY_LIST_FAILURE = '[GlossaryList] Load Glossary List Failure',
  LOAD_GLOSSARY_LIST_SUCCESS = '[GlossaryList] Load Glossary List Success',
  RENAME_GLOSSARY = '[GlossaryList] Rename Glossary',
  DELETE_GLOSSARY = '[GlossaryList] Delete Glossary',
  CREATE_GLOSSARY = '[GlossaryList] Create Glossary',
  SELECT_GLOSSARY = '[GlossaryList] Select Glossary',
}

export class LoadGlossaryList {
  static readonly type = ActionTypes.LOAD_GLOSSARY_LIST;
  constructor(public qaSettingsId: string) {}
}

export class LoadGlossaryListSuccess {
  static readonly type = ActionTypes.LOAD_GLOSSARY_LIST_SUCCESS;
  constructor() {}
}

export class LoadGlossaryListFail {
  static readonly type = ActionTypes.LOAD_GLOSSARY_LIST_FAILURE;
  constructor(public error: any) {}
}

export class RenameGlossary {
  static readonly type = ActionTypes.RENAME_GLOSSARY;
  constructor(public glossaryId: string, public name: string) {}
}

export class DeleteGlossary {
  static readonly type = ActionTypes.DELETE_GLOSSARY;
  constructor(public glossaryId: string) {}
}

export class CreateGlossary {
  static readonly type = ActionTypes.CREATE_GLOSSARY;
  constructor(public glossary: TermbaseInfo) {}
}

export class SelectGlossary {
  static readonly type = ActionTypes.SELECT_GLOSSARY;
  constructor(public glossaryId: string) {}
}
