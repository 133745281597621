import { Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';
import { ContextMenuService } from '@shared/directives/context-menu.service';
import { BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';

/*
 * Service for providing information about the last
 * focused input or textarea for the correct symbol insertion
 * and actions for insert new symbols
 */
@Injectable()
export class SpecialSymbolService implements OnDestroy {
  public readonly symbolInserted$ = new Subject<string>();
  readonly #insertAvailable$ = new Subject<boolean>();

  public readonly insertAvailable$ = this.#insertAvailable$.pipe(debounceTime(200));

  private readonly lastFocusedInput$: BehaviorSubject<HTMLInputElement | HTMLTextAreaElement> = new BehaviorSubject(
    null
  );
  private lastFocusedFormControl: FormControl;
  private contextMenuDirective: ContextMenuDirective;
  private lastInputCaretPosition: number;
  private readonly destroyed$ = new Subject<void>();

  constructor(private contextMenutService: ContextMenuService) {
    this.watchSelectionEvent();
  }

  get lastFocusedInput(): HTMLInputElement | HTMLTextAreaElement {
    return this.lastFocusedInput$.getValue();
  }

  private watchSelectionEvent(): void {
    fromEvent(document, 'selectionchange')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this.lastFocusedInput) {
          return;
        }
        this.lastInputCaretPosition = this.lastFocusedInput.selectionEnd;
      });
  }

  public insertSymbol(symbol: string): void {
    this.lastFocusedInput?.focus();
    if (this.lastFocusedFormControl) {
      const updatedValue =
        this.lastFocusedFormControl.value.slice(0, this.lastInputCaretPosition) +
        symbol +
        this.lastFocusedFormControl.value.slice(this.lastInputCaretPosition);
      this.lastFocusedFormControl.setValue(updatedValue);
      this.lastInputCaretPosition++;
      this.lastFocusedInput?.setSelectionRange(this.lastInputCaretPosition, this.lastInputCaretPosition);
    }
    this.symbolInserted$.next(symbol);
  }

  public focusInput(
    contextMenuDirective: ContextMenuDirective,
    input?: HTMLInputElement | HTMLTextAreaElement,
    formControl?: FormControl
  ): void {
    if (this.contextMenutService.isOpened) {
      return;
    }

    this.lastFocusedInput$.next(input);
    this.lastFocusedFormControl = formControl;
    this.contextMenuDirective = contextMenuDirective;
    this.#insertAvailable$.next(!!this.lastFocusedInput);
  }

  public openInsertSymbolsDialog(): void {
    this.contextMenuDirective.open();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
