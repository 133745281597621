import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { NavComponent } from './nav';
import { ModalModule } from './modal';
import { NavbarComponent } from './navbar';
import { FileChooserModule } from './file-chooser';
import { TextEditableModule } from './text-editable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives';
import { SpecificLanguageModule } from './specific-language';
import { MatMenuModule } from '@angular/material/menu';
import { MeasurementUnitsModule } from './measurement-units';
import { DigitToTextModule } from './digit-to-text';
import { RadioButtonModule } from '@shared/components/radio-button/radio-button.module';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { SearchPatternModule } from '@shared/components/search-pattern';
import { LoaderModule } from '@shared/components/loader';
import { IconModule } from '@shared/components/icon';
import { NumberBoxModule } from '@shared/components/number-box';
import { DropdownModule } from '@shared/components/dropdown';
import { MatChipsModule } from '@angular/material/chips';
import { DropdownMenuModule } from '@shared/components/dropdown-menu/dropdown-menu.module';
import { PopoverModule } from '@shared/components/popover';
import { CollectionModule } from '@shared/components/collection/collection.module';
import { TooltipModule } from '@shared/components/tooltip';
import { DialogConfirmationModule } from '@shared/components/dialog/confirmation';
import { SearchListModule } from './search-list/search-list.module';
import { SourceTargetPatternsComponent } from './source-target-patterns';
import { CommentModule } from './comment-thread/comment-thread.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarService } from './sidebar/sidebar.service';
import { ShortcutsReferenceComponent } from './shortcuts-reference/shortcuts-reference.component';
import { ShortcutPreviewComponent } from './shortcuts-reference/shortcut-preview/shortcut-preview.component';
import { PipesModule } from '@shared/pipes';
import { ActionIconModule } from '@shared/components/action-icon';
import { ButtonModule } from '@shared/components/button';
import { EmptyMessageModule } from './empty-message/empty-message.module';

const SHARED_COMPONENTS = [
  NavComponent,
  NavbarComponent,
  SourceTargetPatternsComponent,
  SidebarComponent,
  ShortcutsReferenceComponent,
];

const SHARED_MODULES = [
  DirectivesModule,
  SpecificLanguageModule,
  RadioButtonModule,
  NumberBoxModule,
  SearchInputModule,
  LoaderModule,
  IconModule,
  DropdownModule,
  DropdownMenuModule,
  TextEditableModule,
  FileChooserModule,
  ModalModule,
  CollectionModule,
  PopoverModule,
  TooltipModule,
  DialogConfirmationModule,
  SearchListModule,
  CommentModule,
  ActionIconModule,
  ButtonModule,
  EmptyMessageModule,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatChipsModule,
    MatMenuModule,
    SearchPatternModule,
    MeasurementUnitsModule,
    DigitToTextModule,
    PipesModule,
    ...SHARED_MODULES,
  ],
  declarations: [...SHARED_COMPONENTS, ShortcutPreviewComponent],
  exports: [...SHARED_COMPONENTS, ...SHARED_MODULES],
  providers: [SidebarService],
})
export class ComponentsModule {}
