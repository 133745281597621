import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  BulkChangeReportPartProcessingStatus,
  ChangeReportPartProcessingStatus,
  ResetReportProcessingState,
} from './report-processing.actions';
import { IssueTypeToReportTab, RecieveProgressingStateEvent } from '@shared/models';
import { CommonHubService } from '@shared/services';
import { patch } from '@ngxs/store/operators';
import { IssueType, ReportProgressStatus } from '@generated/api';
import { SetGroupsOrderState } from '@store/report-view-store';

export interface ReportPartProcessingStateModel {
  status: ReportProgressStatus;
}

export interface ReportProcessingStateModel {
  parts: {
    [key in IssueType]?: ReportPartProcessingStateModel;
  };
  count: number;
}

export const defaultProcessingState = {
  parts: {
    [IssueType.Formal]: {
      status: ReportProgressStatus.InProgress,
    },
    [IssueType.Consistency]: {
      status: ReportProgressStatus.InProgress,
    },
    [IssueType.Terminology]: {
      status: ReportProgressStatus.InProgress,
    },
    [IssueType.Spelling]: {
      status: ReportProgressStatus.InProgress,
    },
  },
  count: 0,
};

@State<ReportProcessingStateModel>({
  name: 'reportProcessing',
  defaults: defaultProcessingState,
})
@Injectable()
export class ReportProcessingState {
  constructor(private commonHub: CommonHubService, private store: Store) {
    this.commonHub.watch(RecieveProgressingStateEvent).subscribe((data) => {
      this.store.dispatch(new ChangeReportPartProcessingStatus(data.data.issueType, data.status));
    });
  }

  public static getIsLoading(issueType: IssueType) {
    return createSelector([ReportProcessingState], (state: ReportProcessingStateModel) => {
      // DEBUG: VW-1009 delete it
      if (!state) {
        console.log('🦄: [line 59][report-processing.state.ts] [35mState selector: ', state);
      }

      return state.parts[issueType]?.status !== ReportProgressStatus.Completed;
    });
  }

  static reportPartProcessing(issueType: IssueType) {
    return createSelector(
      [ReportProcessingState],
      (state: ReportProcessingStateModel): ReportProgressStatus => state.parts[issueType]?.status
    );
  }

  @Action(ChangeReportPartProcessingStatus)
  public changePartStatus(
    ctx: StateContext<ReportProcessingStateModel>,
    { issueType, status }: ChangeReportPartProcessingStatus
  ) {
    const state = ctx.getState();
    ctx.patchState({
      parts: {
        ...state.parts,
        [issueType]: {
          ...state.parts[issueType],
          status,
        },
      },
    });
  }

  @Action(BulkChangeReportPartProcessingStatus)
  public bulkChangeReportPartProcessingStatus(
    ctx: StateContext<ReportProcessingStateModel>,
    action: BulkChangeReportPartProcessingStatus
  ) {
    const updatedPartStatuses = {};

    action.statuses.forEach((s) => {
      updatedPartStatuses[s.issueType] = {
        status: s.status,
      };

      if (s.status === ReportProgressStatus.InProgress) {
        ctx.dispatch(new SetGroupsOrderState(IssueTypeToReportTab[s.issueType], {}));
      }
    });

    ctx.setState(
      patch({
        parts: updatedPartStatuses,
      })
    );
  }

  @Action(ResetReportProcessingState)
  public resetReportProcessingState(ctx: StateContext<ReportProcessingStateModel>) {
    ctx.setState(defaultProcessingState);
  }
}
