import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from '@shared/pipes';
import { ComponentsModule } from '@shared/components';
import { TagsValidationService } from '@shared/services';
import { TranslationUnitPermissions } from './services/translation-unit.permissions';
import { QualityIssueMapper } from './mappers/quality-issue.maper';
import { RenderItemBuilder } from './services/render-item-builder.service';
import { TranslationUnitChangeMapper } from './mappers';
import { TranslationUnitMapper } from './mappers/translation-unit.mapper';
import { ContextViewerService } from './services/context-viewer.service';
import { BlobDownloadService } from './services/blob-download.service';
import { SpecialSymbolService } from './services/special-symbol.service';

const appServices = [
  TranslationUnitPermissions,
  TagsValidationService,
  RenderItemBuilder,
  QualityIssueMapper,
  TranslationUnitChangeMapper,
  TranslationUnitMapper,
  ContextViewerService,
  BlobDownloadService,
  SpecialSymbolService,
];

@NgModule({
  imports: [PipesModule, ComponentsModule],
  providers: appServices,
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return { ngModule: SharedModule };
  }
}
