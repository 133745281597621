import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AppService } from '@shared/services';
import { tap, mergeMap, takeUntil, filter, take } from 'rxjs/operators';
import { Store, Actions, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadProjects } from 'src/app/store/projects-store/projects.actions';
import { LoadTemplateList } from 'src/app/store/template-list-store/template-list.actions';
import { ProjectsState } from 'src/app/store/projects-store/projects.state';
import { TouchedProject } from '@store/project-store/project.actions';
import { BaseLayoutComponent } from '../base-layout';
import { LoggerService } from '../../../core/logger';
import { combineLatest, Observable, of } from 'rxjs';
import { AvailableLanguagesState } from '@store/available-languages';
import { ProjectInfo } from '@generated/api';
import { LoadBalance, LoadSubscriptionDetails, PaymentState, ProjectState } from '@store/index';
import { SubscriptionDetails } from '@generated/payment-api';

@Component({
  selector: 'app-settings-layout',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsLayoutComponent extends BaseLayoutComponent {
  @Select(ProjectsState.projects)
  public readonly projects$: Observable<ProjectInfo[]>;

  @Select(PaymentState.subscriptionDetails)
  public readonly subscriptionDetails$: Observable<SubscriptionDetails>;

  constructor(
    app: AppService,
    store: Store,
    cdr: ChangeDetectorRef,
    actions$: Actions,
    protected route: ActivatedRoute,
    private router: Router,
    loggerService: LoggerService
  ) {
    super(app, store, cdr, actions$, loggerService);
  }

  // TODO: recover from indexed db. Add deferred request for get real data
  private readonly projectLoaded$ = this.store.select(ProjectsState.projects).pipe(
    filter((projects) => projects.length > 0),
    take(1),
    mergeMap(() => {
      const projects = this.store.selectSnapshot(ProjectsState.projects);
      if (projects.length > 0) {
        let projectId: string = this.route.snapshot.queryParams.projectId;
        if (!projectId || !projects.some((project) => project.id === projectId)) {
          projectId = projects[0].id;
        } else {
          // Clear query params from route
          this.router.navigate([], { relativeTo: this.route });
        }
        if (this.store.selectSnapshot(ProjectState.projectId) === projectId) {
          return of(null);
        }
        return this.store.dispatch(new TouchedProject(projectId));
      }
    }),
    tap(() => {
      this.store.dispatch(LoadTemplateList);
    }),
    takeUntil(this.destroyed$)
  );

  private readonly languagesLoaded$ = this.store.select(AvailableLanguagesState.languages).pipe(filter((l) => !!l));

  public preloadData(): void {
    this.store.dispatch(new LoadSubscriptionDetails());
    this.store.dispatch(new LoadProjects());
    this.store.dispatch(new LoadBalance());
    // TODO: переработать loader для полноценной интеграции с оффлайн режимом
    //  проект брать из стейта (предполагаем что раз есть кеш значит есть и проект)
    //  Либо добавляем задачу на создание нового в очередь
    combineLatest([this.projectLoaded$, this.languagesLoaded$]).subscribe({
      next: () => {
        this.loadComplete();
      },
      error: (error: any) => {
        this.loggerService.error(error);
      },
    });
  }
}
