enum Terminals {
  LeftParenthesis = '(',
  RightParenthesis = ')',
  Or = 'or',
  OrSymbol = '|',
  And = 'and',
  AndSymbol = '&',
  Not = 'not',
  NotSymbol = '~',
  S = 'S',
}

export class PowerSearchValidator {

  /**
   * S -> (S) .
   * S -> S OR S .
   * S -> S | S .
   * S -> S AND S .
   * S -> S & S .
   * S -> NOT S .
   * S -> ~ S .
   * S -> text .
   * S -> S S .
   */
  private productions = [
    [Terminals.LeftParenthesis, Terminals.S, Terminals.RightParenthesis],
    [Terminals.S, Terminals.Or, Terminals.S],
    [Terminals.S, Terminals.OrSymbol, Terminals.S],
    [Terminals.S, Terminals.And, Terminals.S],
    [Terminals.S, Terminals.AndSymbol, Terminals.S],
    [Terminals.Not, Terminals.S],
    [Terminals.NotSymbol, Terminals.S],
    [Terminals.S, Terminals.S],
  ];

  private tokens = [
    { terminal: Terminals.LeftParenthesis, str: Terminals.LeftParenthesis },
    { terminal: Terminals.RightParenthesis, str: Terminals.RightParenthesis },
    { terminal: Terminals.Or, str: ' ' + Terminals.Or + ' ' },
    { terminal: Terminals.OrSymbol, str: ' ' + Terminals.OrSymbol + ' ' },
    { terminal: Terminals.And, str: ' ' + Terminals.And + ' ' },
    { terminal: Terminals.AndSymbol, str: ' ' + Terminals.AndSymbol + ' ' },
    { terminal: Terminals.Not, str: Terminals.Not + ' ' },
    { terminal: Terminals.NotSymbol, str: Terminals.NotSymbol + ' ' },
  ];

  public check(str: string): boolean {
    try {
      if (!str) {
        return false;
      }
      let tokenizedString = this.tokenize(str);
      let replacement = false;
      do {
        replacement = false;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.productions.length; i++) {
          const production = this.productions[i];
          const replace = this.replaceInArr(
            tokenizedString,
            production,
            Terminals.S
          );
          if (tokenizedString !== replace) {
            replacement = true;
            tokenizedString = replace;
            break;
          }
        }
      } while (replacement);
      return tokenizedString.length === 1 && tokenizedString[0] === Terminals.S;
    } catch (error) {
      console.error(error);
      return false;
    }

  }

  private tokenize(str: string): Array<Terminals> {
    let i = 0;
    const result: Array<Terminals> = [];
    let acc = '';
    let skipToken = false;
    while (i < str.length) {
      const token = this.tokens.find(
        (t) => str.substr(i, t.str.length).toLowerCase() === t.str.toLowerCase()
      );
      if (!skipToken && token) {
        if (acc.length > 0) {
          result.push(Terminals.S);
          acc = '';
        }
        result.push(token.terminal);
        i = i + token.str.length;
      } else {
        acc = acc + str.substr(i, 1);
        skipToken = acc.endsWith('\\');
        i++;
      }
    }
    if (acc.length > 0) {
      result.push(Terminals.S);
    }
    return result;
  }

  private replaceInArr<T>(array: T[], subarray: T[], replacement: T): T[] {
    if (subarray.length > array.length) {
      return array;
    }
    for (let i = 0; i < array.length; i++) {
      if (this.arraysEqual(array.slice(i, i + subarray.length), subarray)) {
        const newArr = array.slice();
        newArr.splice(i, subarray.length, replacement);
        return newArr;
      }
    }
    return array;
  }

  private arraysEqual<T>(a: T[], b: T[]): boolean {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}
