import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DirectivesModule } from '@shared/directives';
import { ComponentsModule } from '@shared/components';
import { PipesModule } from '@shared/pipes';
import { components, HeaderProfileModule, HeaderProjectsComponent } from './header';
import { WrapperComponent } from './wrapper';
import { ReportLayoutComponent } from './report-layout';
import { SettingsLayoutComponent } from './settings-layout';
import { TooltipModule } from '@shared/components/tooltip';
import { SelectModule } from '@shared/components/select-v2';
import { ReportSettingsComponent } from './header/report-settings';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { ProjectManagerModule } from '@shared/components/project-manager/project-manager.module';
import { OrganizationsManagerModule } from '@shared/components/organisation-manager/organizations-manager.module';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { HeaderModule } from '@shared/components/header';
import { ShortcutsHelperBtnModule } from '@shared/components/shortcuts-helper-btn/shortcuts-helper-btn.module';
import { ReportSettingsBtnModule } from './header/report-settings-btn/report-settings-btn.module';
import { HelpButtonModule } from '@shared/components/help-button/help-button.module';
import { SubscriptionStatusModule } from '@shared/components/subscription-status/subscription-status.module';

// TODO: Этот модуль необходимо удалить. Все подмодули должны быть изолиррованы в
// слоях предметной области. На текущий момент он тянет куски отчетов в настройки
// и куски настроек в отчеты.
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    TooltipModule,
    DirectivesModule,
    HeaderProfileModule,
    HeaderModule,
    SelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    ProjectManagerModule,
    OrganizationsManagerModule,
    ShortcutsHelperBtnModule,
    ReportSettingsBtnModule,
    HelpButtonModule,
    SubscriptionStatusModule,
  ],
  declarations: [
    WrapperComponent,
    ReportLayoutComponent,
    SettingsLayoutComponent,
    ...components,
    ReportSettingsComponent,
    ErrorLayoutComponent,
  ],
  exports: [WrapperComponent, HeaderProjectsComponent, HeaderProfileModule],
  providers: [MatDialogRef],
})
export class LayoutModule {}
