import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon.component';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { iconSizes } from './icon-size.constant';
import { completeIconSet } from '@generated/icons/icons.model';

@NgModule({
  imports: [
    CommonModule,
    SvgIconsModule.forRoot({
      icons: completeIconSet,
      sizes: iconSizes,
      defaultSize: 'medium',
    }),
  ],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {}
