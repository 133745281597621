import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderOverlayDirective } from './loader-overlay.directive';
import { SpinnerComponent } from '@shared/components/loader/spinner/spinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DynamicOverlayContainer } from '@shared/components/loader/mat-extensions/dynamic-overlay-container.service';
import { DynamicOverlay } from '@shared/components/loader/mat-extensions/dynamic-overlay';
import { LoaderComponent } from '@shared/components/loader/loader/loader.component';

@NgModule({
  imports: [CommonModule, FormsModule, OverlayModule],
  declarations: [SpinnerComponent, LoaderOverlayDirective, LoaderComponent],
  exports: [SpinnerComponent, LoaderOverlayDirective, LoaderComponent],
  providers: [DynamicOverlayContainer, DynamicOverlay],
  entryComponents: [LoaderComponent, SpinnerComponent],
})
export class LoaderModule {}
