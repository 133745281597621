import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiHttpError, ErrorCode } from '@shared/models';
import { NotificationService } from '@shared/components/notification';
import { Router } from '@angular/router';
import { FORBIDDEN_ERROR_ROUTE } from '../modules/errors/errors.routes';

/**
 * Interceptor for handle all errors, except unauthorized
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private skipErrorCodes = {
    [ErrorCode.MigratorByVersionNotFound]: true,
    [ErrorCode.ProfileInvalidStructure]: true,
    [ErrorCode.NotFound]: true,
  };

  constructor(private notificationService: NotificationService, private readonly router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: ApiHttpError) => this.handleError(request, error)));
  }

  private handleError(request: HttpRequest<any>, errorResponse: ApiHttpError): Observable<null | never> {
    if (!this.skipErrorCodes[errorResponse.error?.code]) {
      this.showError(request, errorResponse);
    }
    this.checkOrganizationAccessError(errorResponse);
    return throwError(() => errorResponse);
  }

  // NOTE: пока нет способа определять права доступа к проектам организации.
  // Не учитывает вариации ошибки для анона и для страницы работы с отчетами
  // т.к. список проектов для работы приложения не нужен
  private checkOrganizationAccessError(error: ApiHttpError): void {
    // NOTE: доступ к списку проектов так же проверяет права на организацию
    // и статус доступности приложения
    const isPorjectsEndpoint = error.url.startsWith('/api/projects');
    if (isPorjectsEndpoint && error.status === 403) {
      this.router.navigateByUrl(FORBIDDEN_ERROR_ROUTE.navigateUrl());
    }
  }

  private showError(request: HttpRequest<any>, errorResponse: ApiHttpError): void {
    const notificationMessage = this.extractNotificationMessage(request, errorResponse);
    if (!notificationMessage) {
      return;
    }
    this.notificationService.toast({
      text: notificationMessage,
      icon: 'alert',
      wrap: true,
    });
  }

  private extractNotificationMessage(request: HttpRequest<any>, errorResponse: ApiHttpError): string {
    return errorResponse.error?.message;
  }
}
