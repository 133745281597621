import { CommentModel } from '@generated/api';
import { CommentCreateModel, CommentThread, CommentThreadCreateModel } from '@shared/models';

enum ActionTypes {
  LOAD_PROJECT_COMMENT_THREADS = '[Comments] Project Comment Threads',
  COMMENT_THREAD_LOAD = '[Comments] Comment Thread Load',
  COMMENT_THREAD_ADD = '[Comments] Comment Thread Add',
  COMMENT_THREAD_REMOVE = '[Comments] Comment Thread Remove',
  COMMENT_THREAD_REMOVED = '[Comments] Comment Thread Removed',
  COMMENT_THREAD_RESOLVE = '[Comments] Comment Thread Resolve',
  COMMENT_THREAD_RESOLVED = '[Comments] Comment Thread Resolved',
  COMMENT_THREAD_RESTORE = '[Comments] Comment Thread Restore',
  COMMENT_LOAD = '[Comments] Comment Load',
  COMMENT_ADD_OR_UPDATE = '[Comments] Comment Add Or Update',
  COMMENT_CREATE = '[Comments] Comment Create',
  COMMENT_UPDATE = '[Comments] Comment Update',
  COMMENT_REMOVE = '[Comments] Comment Remove',
  COMMENT_REMOVED = '[Comments] Comment Removed',
  COMMENT_RESTORE = '[Comments] Comment Restore',
  COMMENT_THREAD_REMOVE_BY_TRANSLATION_UNIT_ID = '[Comments] Comment Thread Remove By Translation Unit Id',
}

export class LoadProjectCommentThreads {
  static readonly type = ActionTypes.LOAD_PROJECT_COMMENT_THREADS;
  constructor(public projectId: string) {}
}

export class CommentThreadLoad {
  static readonly type = ActionTypes.COMMENT_THREAD_LOAD;
  constructor(public threadId: string) {}
}

export class CommentThreadAdd {
  static readonly type = ActionTypes.COMMENT_THREAD_ADD;
  constructor(public thread: CommentThread) {}
}

export class CommentThreadResolve {
  static readonly type = ActionTypes.COMMENT_THREAD_RESOLVE;
  constructor(public threadId: string, public isResolved = true) {}
}

export class CommentThreadResolved {
  static readonly type = ActionTypes.COMMENT_THREAD_RESOLVED;
  constructor(public threadId: string, public isResolved: boolean) {}
}

export class CommentThreadRemove {
  static readonly type = ActionTypes.COMMENT_THREAD_REMOVE;
  constructor(public threadId: string) {}
}

export class CommentThreadRemoved {
  static readonly type = ActionTypes.COMMENT_THREAD_REMOVED;
  constructor(public threadId: string) {}
}

export class CommentThreadRestore {
  static readonly type = ActionTypes.COMMENT_THREAD_RESTORE;
  constructor(public thread: CommentThread) {}
}

export class CommentLoad {
  static readonly type = ActionTypes.COMMENT_LOAD;
  constructor(public threadId: string, public commentId: string, public force: boolean) {}
}

export class CommentAddOrUpdate {
  static readonly type = ActionTypes.COMMENT_ADD_OR_UPDATE;
  constructor(public comment: CommentModel) {}
}

export class CommentCreate {
  static readonly type = ActionTypes.COMMENT_CREATE;
  constructor(public comment: CommentCreateModel | CommentThreadCreateModel) {}
}

export class CommentUpdate {
  static readonly type = ActionTypes.COMMENT_UPDATE;
  constructor(public threadId: string, public commentId: string, public newMessage: string) {}
}

export class CommentRemove {
  static readonly type = ActionTypes.COMMENT_REMOVE;
  constructor(public threadId: string, public commentId: string) {}
}

export class CommentRemoved {
  static readonly type = ActionTypes.COMMENT_REMOVED;
  constructor(public threadId: string, public commentId: string) {}
}

export class CommentRestore {
  static readonly type = ActionTypes.COMMENT_RESTORE;
  constructor(public threadId: string, public comment: CommentModel) {}
}

export class CommentThreadRemoveByTranslationUnitId {
  static readonly type = ActionTypes.COMMENT_THREAD_REMOVE_BY_TRANSLATION_UNIT_ID;
  constructor(public projectId: string, public translationUnitIds: string[]) {}
}
