<div class="input-group">
  <app-ui-icon class="prefix-icon" icon="search" date-test-id="search-icon"></app-ui-icon>
  <input
    appTooltipInput
    type="text"
    class="input"
    [(ngModel)]="value"
    (input)="handleChange()"
    (click)="clickHandler()"
    [placeholder]="placeholder"
    date-test-id="search-input"
    [disabled]="inputDisable"
    [autocomplete]="autocomplete"
    [name]="fieldName"
    [id]="fieldName"
    role="search"
    aria-label="Search"
  />
  <app-action-icon
    [disabled]="inputDisable"
    [icon]="value ? 'clear' : null"
    theme="subtle"
    class="suffix-icon search-clear"
    (clickButton)="handleClearValue($event)"
    [class.hidden]="!value"
    data-test-id="search-clear"
  ></app-action-icon>
</div>
