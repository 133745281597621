import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ChipListBaseComponent } from '../chip-list-base.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileService, ImportConfig, ImportError, KB_SIZE } from '@shared/services/file.service';
import { FilesUpload } from '@shared/models';
import { NotificationService } from '@shared/components/notification';
import { stringComparator } from '@shared/tools';
import { ChipListComponent } from '@shared/components/chip-list/chip-list';

enum SortingTypes {
  None,
  Acs,
  Desc,
}

const IMPORT_CONFIG: ImportConfig = {
  sizeLimit: KB_SIZE * KB_SIZE, // 1mb,
  types: ['text/plain'],
};

@Component({
  selector: 'app-chip-list-advanced',
  templateUrl: './chip-list-advanced.component.html',
  styleUrls: ['./chip-list-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipListAdvancedComponent),
      multi: true,
    },
  ],
})
export class ChipListAdvancedComponent extends ChipListBaseComponent implements OnInit {
  @Input() exportFileName = 'list.txt';

  public sortStatus: SortingTypes = SortingTypes.None;
  public sortingTypes = SortingTypes;
  private splitPattern = /\r\n|\r|\n/;
  @ViewChild(ChipListComponent) public chipListComponent: ChipListComponent;

  constructor(
    protected cdf: ChangeDetectorRef,
    private fileService: FileService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public listUpdated(): void {
    const value: string = this.searchControl.value || '';
    const list = this.list.filter((item) => item.indexOf(value) !== -1);
    if (this.sortStatus === SortingTypes.Acs) {
      this.filteredList = list.sort(stringComparator);
    } else if (this.sortStatus === SortingTypes.Desc) {
      this.filteredList = list.sort(stringComparator).reverse();
    } else {
      this.filteredList = list;
    }
    this.cdf.markForCheck();
  }

  public export(): void {
    const text = this.filteredList.join('\n');
    if (!text) {
      return;
    }
    this.fileService.exportTxtFile(text, this.exportFileName);
  }

  public import(filesUpload: FilesUpload): void {
    this.searchControl.setValue('');

    this.fileService
      .importFile(filesUpload, IMPORT_CONFIG)
      .then((text: string) => {
        if (text) {
          const newArray = text
            .split(this.splitPattern)
            .map((item) => item.trim())
            .filter((item) => !!item);

          if (newArray.length) {
            this.addNewItems(newArray);
          }

          this.cdf.markForCheck();
        }
      })
      .catch((error: ImportError) => {
        this.showUploadErrorToast(this.getErrorText(error, IMPORT_CONFIG));
      });
  }

  private showUploadErrorToast(error: string): void {
    this.notificationService.toast({ text: error, icon: 'alert', cancelable: true });
  }

  private getErrorText(error: ImportError, config: ImportConfig): string {
    const errorMessages = {
      [ImportError.FILE_COUNT_ERROR]: 'Only 1 file can be selected',
      [ImportError.FILE_SIZE_ERROR]: `File size should not exceed ${Math.round(config.sizeLimit / KB_SIZE)}Kb`,
      [ImportError.FILE_TYPE_ERROR]: `Only ${config.types.join(',')} files are allowed`,
      [ImportError.ANOTHER_ERROR]: 'Failed to import text from the file. Please try again',
    };

    return errorMessages[error] || '';
  }

  public toggleSortState(): void {
    if (this.disabled) {
      return;
    }
    const numStates = 3; // there are three sorting states
    this.sortStatus = (this.sortStatus + 1) % numStates;
    this.listUpdated();
  }

  public clearList(): void {
    const listClone = [...this.list];
    this.list = [];
    this.listUpdated();
    this.propagateChanges();

    this.notificationService
      .toast({
        text: `${listClone.length} untanslatables deleted. `,
        cancelable: true,
        actionBtn: {
          title: 'Undo',
          icon: 'undo',
        },
      })
      .onAction()
      .subscribe(() => {
        this.list = listClone;
        this.listUpdated();
        this.propagateChanges();
      });
  }

  public addNewItemsAndScroll(list: string[]): void {
    this.addNewItems(list);
    this.chipListComponent.scrollToBottom();
  }
}
