import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  forwardRef,
} from '@angular/core';
import { ChipListBaseComponent } from '../chip-list-base.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-chip-list-popup',
  templateUrl: './chip-list-popup.component.html',
  styleUrls: ['./chip-list-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipListPopupComponent),
      multi: true,
    },
  ],
})
export class ChipListPopupComponent extends ChipListBaseComponent implements OnInit {
  @Input() tooltip: string;

  @Output() popupClosed = new EventEmitter<string[]>();

  constructor(protected cdf: ChangeDetectorRef) {
    super();
  }

  public onClosed(): void {
    this.popupClosed.emit(this.list);
  }
}
