<app-action-icon
  [active]="flag === false"
  class="has-error"
  theme="subtle"
  icon="error-marker"
  (clickButton)="toggle(false)"
></app-action-icon>
<app-action-icon
  [active]="flag"
  class="ignore-error"
  theme="subtle"
  icon="error-marker"
  (clickButton)="toggle(true)"
></app-action-icon>
