import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ShortcutsReference } from 'src/app/core/services/shortcuts';

@Component({
  selector: 'app-shortcut-preview',
  templateUrl: './shortcut-preview.component.html',
  styleUrls: ['./shortcut-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutPreviewComponent implements OnInit {
  @Input() shortcutReferences: ShortcutsReference[];
  /*
   * Group level for nested visual offset.
   */
  @Input() groupLevel: number = 0;

  constructor() {}

  ngOnInit(): void {}

  public isString(value: any): boolean {
    return typeof value === 'string';
  }
}
