import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Organization } from '@generated/api';
import { Select } from '@ngxs/store';
import { OrganizationsState } from '@store/organizations-store/organizations.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-forbidden-error',
  templateUrl: './forbidden-error.component.html',
  styleUrls: ['./forbidden-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenErrorComponent implements OnInit {
  @Select(OrganizationsState.currentOrganization)
  public readonly currentOrganization$: Observable<Organization>;

  constructor() {}

  ngOnInit(): void {}
}
