/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RollbackTranlsationUnitChangesRequest } from '../models/rollback-tranlsation-unit-changes-request';
import { TranslationUnitChangeModel } from '../models/translation-unit-change-model';

@Injectable({
  providedIn: 'root',
})
export class TranslationUnitChangesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTranslationUnitChangesGet
   */
  static readonly ApiTranslationUnitChangesGetPath = '/api/TranslationUnitChanges';

  /**
   * Gets translation unit changes by project ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitChangesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesGet$Plain$Response(params?: {

    /**
     * Project Id
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TranslationUnitChangeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitChangesService.ApiTranslationUnitChangesGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TranslationUnitChangeModel>>;
      })
    );
  }

  /**
   * Gets translation unit changes by project ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitChangesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesGet$Plain(params?: {

    /**
     * Project Id
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<Array<TranslationUnitChangeModel>> {

    return this.apiTranslationUnitChangesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TranslationUnitChangeModel>>) => r.body as Array<TranslationUnitChangeModel>)
    );
  }

  /**
   * Gets translation unit changes by project ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitChangesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesGet$Json$Response(params?: {

    /**
     * Project Id
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<TranslationUnitChangeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitChangesService.ApiTranslationUnitChangesGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TranslationUnitChangeModel>>;
      })
    );
  }

  /**
   * Gets translation unit changes by project ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitChangesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesGet$Json(params?: {

    /**
     * Project Id
     */
    projectId?: string;
    'api-version'?: string;
  }): Observable<Array<TranslationUnitChangeModel>> {

    return this.apiTranslationUnitChangesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TranslationUnitChangeModel>>) => r.body as Array<TranslationUnitChangeModel>)
    );
  }

  /**
   * Path part for operation apiTranslationUnitChangesIdGet
   */
  static readonly ApiTranslationUnitChangesIdGetPath = '/api/TranslationUnitChanges/{id}';

  /**
   * Get a specific translation unit change.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitChangesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesIdGet$Plain$Response(params: {
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TranslationUnitChangeModel>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitChangesService.ApiTranslationUnitChangesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TranslationUnitChangeModel>;
      })
    );
  }

  /**
   * Get a specific translation unit change.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitChangesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesIdGet$Plain(params: {
    id: string;
    'api-version'?: string;
  }): Observable<TranslationUnitChangeModel> {

    return this.apiTranslationUnitChangesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TranslationUnitChangeModel>) => r.body as TranslationUnitChangeModel)
    );
  }

  /**
   * Get a specific translation unit change.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitChangesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesIdGet$Json$Response(params: {
    id: string;
    'api-version'?: string;
  }): Observable<StrictHttpResponse<TranslationUnitChangeModel>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitChangesService.ApiTranslationUnitChangesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TranslationUnitChangeModel>;
      })
    );
  }

  /**
   * Get a specific translation unit change.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitChangesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTranslationUnitChangesIdGet$Json(params: {
    id: string;
    'api-version'?: string;
  }): Observable<TranslationUnitChangeModel> {

    return this.apiTranslationUnitChangesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TranslationUnitChangeModel>) => r.body as TranslationUnitChangeModel)
    );
  }

  /**
   * Path part for operation apiTranslationUnitChangesRollbackPost
   */
  static readonly ApiTranslationUnitChangesRollbackPostPath = '/api/TranslationUnitChanges/Rollback';

  /**
   * Rollback a specific translation unit change.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTranslationUnitChangesRollbackPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitChangesRollbackPost$Response(params?: {
    'api-version'?: string;
    body?: RollbackTranlsationUnitChangesRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TranslationUnitChangesService.ApiTranslationUnitChangesRollbackPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Rollback a specific translation unit change.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTranslationUnitChangesRollbackPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTranslationUnitChangesRollbackPost(params?: {
    'api-version'?: string;
    body?: RollbackTranlsationUnitChangesRequest
  }): Observable<void> {

    return this.apiTranslationUnitChangesRollbackPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
