import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppService } from '@shared/services';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import { CommentsState, ChangeSelectedCommentThreadId, ReportState } from '@store';
import { MatDialog } from '@angular/material/dialog';
import { CommentsPopupComponent } from '@shared/layout/header/comments-popup/comments-popup.component';
import { Account, CommentThread } from '@shared/models';
import { OrganizationsState } from '@store/organizations-store/organizations.state';
import { Organization } from '@generated/api';
import { Auth } from '@store/auth/auth.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Select(AuthState.account)
  public userInfo$: Observable<Account>;

  @Select(CommentsState.projectHasOpenComments)
  public hasOpenComments: Observable<boolean>;

  @Select(OrganizationsState.currentOrganization)
  public readonly currentOrganization$: Observable<Organization>;

  @Input()
  offlineTooltipText: string;

  @Input()
  isReportPage = false;

  public commentsPopupOpened = false;
  public showUpdate = false;

  constructor(public app: AppService, private store: Store, private dialog: MatDialog) {}

  public login(): void {
    this.store.dispatch(new Auth());
  }

  public openSettings(): void {
    const report = this.store.selectSnapshot(ReportState.report);
    if (report && report.isOwner) {
      window.open(`/settings/files?projectId=${report.projectId}`, this.app.config.settingsNamePrefix);
    }
  }

  public openComments(): void {
    this.commentsPopupOpened = true;
    this.dialog
      .open(CommentsPopupComponent, {
        position: {
          top: '40px',
          right: '0px',
        },
        backdropClass: 'transparent-backdoor',
        maxHeight: 'calc(100% - 40px)',
      })
      .afterClosed()
      .subscribe((selectedThread?: CommentThread) => {
        this.commentsPopupOpened = false;
        if (selectedThread) {
          this.store.dispatch(new ChangeSelectedCommentThreadId(selectedThread.id));
        }
      });
  }
}
