import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '@shared/services';
import { DropdownMenuComponent } from '@shared/components/dropdown-menu';
import { ClearSettings, QASettingsState, RenameQASettings, ResetSettings } from '@store/qa-settings-store';
import {
  AddTemplate,
  CloneTemplate,
  CreateTemplateFromProjectProfile,
  DeleteTemplate,
  QASettingsTemplatesState,
  SetDefault,
  UpdateTemplate,
  UploadTemplate,
} from '@store/template-list-store';
import { ToggleProjectQASettings } from '@store/project-store/project.actions';
import { ProjectState } from '@store/project-store/project.state';
import { TooltipTriggerDirective } from '@shared/components/tooltip';
import { QaSettingsInfo, QaSettingsModel } from '@generated/api';
import { SpinnerSize } from '@shared/components/loader';
import { FilesUpload, QASettingsWithTemplate } from '@shared/models';

const UNTITLED_PROFILE = 'Untitled profile';

@Component({
  selector: 'app-header-profiles',
  templateUrl: './header-profiles.component.html',
  styleUrls: ['./header-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderProfilesComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  public spinnerSize = SpinnerSize;
  public profileChanging = false;
  popupWidth: number = 400;
  qaSettings: QASettingsWithTemplate;
  qaSettingsList: QaSettingsInfo[];
  profileNameControl = new FormControl('', [this.sameNameValidator()]);
  profileSearchControl = new FormControl('');
  isShowAll$ = new BehaviorSubject<boolean>(false);
  qaSettingsListView$ = new BehaviorSubject<QaSettingsInfo[]>([]);

  @ViewChild('profileDropdown', { static: false, read: DropdownMenuComponent })
  dropDown: DropdownMenuComponent;

  @ViewChild('profileDropdown', { static: false, read: ElementRef })
  dropDownRef: ElementRef;

  @ViewChild('textEditable', { read: TooltipTriggerDirective }) settingsNameTooltip: TooltipTriggerDirective;

  @Select(QASettingsTemplatesState.sortedList)
  qaSettingsList$: Observable<QaSettingsInfo[]>;

  @Select(QASettingsState.qaSettingsHasChanges)
  qaSettingsHasChanges: Observable<boolean>;

  constructor(public app: AppService, private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public menuOpened(): void {
    this.popupWidth = this.dropDownRef.nativeElement.clientWidth;
  }

  public menuClosed(): void {
    this.profileSearchControl.setValue('');
    this.isShowAll$.next(false);
    this.settingsNameTooltip?.hide();
  }

  public renameProfile(name: string): void {
    const newName = name.trim();
    const oldName = this.store.selectSnapshot(QASettingsState.qaSettings)?.name;

    if (newName !== oldName) {
      this.store.dispatch(new RenameQASettings(this.qaSettings.id, newName));
    }
  }

  public createNew(): void {
    this.store.dispatch(new AddTemplate(UNTITLED_PROFILE, true));
    this.closeDropdown();
  }

  public upload(event: FilesUpload): void {
    const files = event.files;
    const file: File = files[0];

    if (!file || file.name.lastIndexOf('.vprofile') !== file.name.length - 9) {
      this.closeDropdown();
      return;
    }
    this.uploadProfile(file);
    this.closeDropdown();
  }

  public save(): void {
    const templateId = this.qaSettings.templateId;
    const template = this.qaSettingsList.find((item) => item.id === templateId);

    if (template) {
      this.updateTemplate(this.qaSettings);
    } else {
      this.createTemplate(this.qaSettings.name);
    }
  }

  public resetSettings(): void {
    this.store.dispatch(new ResetSettings(this.qaSettings.id));
  }

  public clearAllSettings(): void {
    this.store.dispatch(new ClearSettings(this.qaSettings.id));
  }

  public select(template: QaSettingsInfo): void {
    if (this.profileChanging) {
      return;
    }
    const project = this.store.selectSnapshot(ProjectState.project);
    this.profileChanging = true;
    const currentName = this.profileNameControl.value;
    this.profileNameControl.setValue(template.name);
    this.store
      .dispatch(new ToggleProjectQASettings(project.id, template.id))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.profileChanging = false;
          this.cdr.detectChanges();
        },
        error: () => {
          this.profileNameControl.setValue(currentName);
          this.profileChanging = false;
          this.cdr.detectChanges();
        },
      });
    this.closeDropdown();
  }

  public preventClick(e: MouseEvent): void {
    e.stopPropagation();
  }

  public toggleShowAll(): void {
    this.isShowAll$.next(!this.isShowAll$.getValue());
  }

  public setAsDefault(data: QaSettingsModel): void {
    this.store.dispatch(new SetDefault(data.id));
  }

  public makeCopy(data: QaSettingsModel): void {
    this.store.dispatch(new CloneTemplate(data.id, data.name));
  }

  public delete(data: QaSettingsModel): void {
    this.store.dispatch(new DeleteTemplate(data.id));
  }

  public startEditingName(): void {
    this.settingsNameTooltip?.hide();
  }

  private initSubscriptions(): void {
    this.store
      .select(QASettingsState.qaSettingsWithTemplate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((qaSettings) => {
        this.profileNameControl.setValue(qaSettings?.name);
        this.qaSettings = { ...this.qaSettings, ...qaSettings };
      });

    this.qaSettingsList$.pipe(takeUntil(this.destroyed$)).subscribe((list) => {
      this.qaSettingsList = list;
      this.qaSettingsListView$.next(list);
    });

    combineLatest([this.profileSearchControl.valueChanges, this.qaSettingsList$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([searchValue, qaSettingsList]: [string, QaSettingsInfo[]]) => {
        const filtered = !searchValue
          ? qaSettingsList
          : qaSettingsList.filter((item) => (item.name || '').toLowerCase().includes(searchValue.toLowerCase()));

        this.qaSettingsListView$.next(filtered);
      });
  }

  private sameNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const profiles = this.store.selectSnapshot(QASettingsTemplatesState.sortedList);
      const qaSettings = this.store.selectSnapshot(QASettingsState.qaSettings);

      if (!profiles.length || !control.value) {
        return null;
      }

      const hasSameName = profiles.some(
        ({ name, id }: QaSettingsInfo) => control.value === name && qaSettings?.templateId !== id
      );
      return hasSameName ? { exist: true } : null;
    };
  }

  private uploadProfile(file: File): void {
    this.store.dispatch(new UploadTemplate(file, true));
  }

  private updateTemplate(qaSettings: QaSettingsModel): void {
    this.store.dispatch(new UpdateTemplate(qaSettings.id, qaSettings.templateId));
  }

  private createTemplate(name: string): void {
    const qaSettings = this.store.selectSnapshot(QASettingsState.qaSettings);
    this.store.dispatch(new CreateTemplateFromProjectProfile(qaSettings.id, name));
  }

  private closeDropdown(): void {
    this.dropDown.close();
  }
}
