import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GridApi, ICellRendererAngularComp, ICellRendererParams } from 'src/types/ag-grid';

/*
 *  This is a component for fake group rendering for target language.
 *  Its a crutch, cause ag grid has lack of vertical grouping functionality
 *
 *  This component should be placed on the first column position, cause it has css overlay for the right side
 *  to emulate start of the grouping selection with a border
 */
@Component({
  selector: 'app-target-language-cell-renderer',
  template: '{{ displayedValue }}',
  styleUrls: ['./target-language-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetLanguageCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  static readonly rendererName = 'targetLanguageCellRenderer';
  public displayedValue = '';
  private value = '';
  private gridApi: GridApi;
  private nodeId: string;
  private asyncTransactionsFlushedEventListener = (): void => {
    this.displayCellWhenFirstGroupValue();
    this.cdr.detectChanges();
  };

  constructor(private cdr: ChangeDetectorRef) {}

  public agInit(params: ICellRendererParams): void {
    this.nodeId = params.node.id;
    this.gridApi = params.api;
    this.value = params.value;
    this.displayCellWhenFirstGroupValue();
    this.gridApi.addEventListener('asyncTransactionsFlushed', this.asyncTransactionsFlushedEventListener);
  }

  public refresh(): boolean {
    return false;
  }

  private displayCellWhenFirstGroupValue(): void {
    const node = this.gridApi.getRowNode(this.nodeId);
    this.displayedValue = node.firstChild ? this.value : '';
  }

  ngOnDestroy() {
    if (this.gridApi) {
      this.gridApi.removeEventListener('asyncTransactionsFlushed', this.asyncTransactionsFlushedEventListener);
    }
  }
}
