<div *ngIf="balance && (subscriptionDetails$ | async)" class="subscription-status">
  <div *ngIf="type === 'full' && (subscriptionDetails$ | async)?.name" class="plan-name">
    {{ (subscriptionDetails$ | async).name }}
  </div>

  <div class="subscription-badge" [ngClass]="subscriptionStatus">
    <app-ui-icon *ngIf="subscriptionStatus === 'warning'" icon="error" color="inherit"></app-ui-icon>
    <app-ui-icon *ngIf="subscriptionStatus === 'info'" icon="star" color="inherit"></app-ui-icon>
    <app-ui-icon *ngIf="subscriptionStatus === 'error'" icon="outline-alert" color="inherit"></app-ui-icon>

    <ng-container
      *ngIf="
        ((subscriptionDetails$ | async)?.product && !showProgress) ||
          (subscriptionDetails$ | async)?.status !== SubscriptionStatus.Active;
        else porjectsProgressTemplate
      "
    >
      {{ (subscriptionDetails$ | async)?.status | subscriptionPrettyStatus }}
    </ng-container>

    <ng-template #porjectsProgressTemplate>
      <span class="projects-count">{{ balance.projectUsage }}/{{ balance.projectTotal }}</span>
      <span *ngIf="!(subscriptionDetails$ | async)?.product" class="badge-text">trial projects</span>
    </ng-template>
  </div>
</div>
