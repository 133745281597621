import { StateRepository } from '@angular-ru/ngxs/decorators';
import { State, Action, createSelector, Store, Selector } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PatchLocaleQASettings, AddLocaleQASettings, LoadLocaleQASettingsList } from './locale-qa-settings.actions';
import { tap, catchError } from 'rxjs/operators';
import { produceWithPatches } from 'immer';
import { nameof } from '@shared/tools/nameof';
import { Observable, of, throwError } from 'rxjs';
import { QASettingsState, SetCurrentQASettings } from '@store/qa-settings-store';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { LanguageState } from '@store/language-store';
import { LanguageModel, LocaleQaSettingsModel, LocaleQaSettingsService, QaSettingsInfo } from '@generated/api';
import { HttpErrorResponse } from '@angular/common/http';
import { CleanStoredProjectInfo } from '@store/projects-store/projects.actions';

interface LocaleQASettingsMap {
  [languageCode: string]: LocaleQaSettingsModel;
}

interface LocaleQASettingsStateModel {
  [qaSettingsId: string]: LocaleQASettingsMap;
}

@AsyncStorage
@StateRepository()
@State<LocaleQASettingsStateModel>({
  name: 'localeQaSettings',
  defaults: {},
})
@Injectable()
export class LocaleQASettingsState {
  constructor(private localeQASettings: LocaleQaSettingsService, private store: Store) {}

  @Selector([LocaleQASettingsState, QASettingsState.qaSettingsId])
  public static localeQaSettingsMap(state: LocaleQASettingsStateModel, qaSettingsId: string): LocaleQASettingsMap {
    return state?.[qaSettingsId] || {};
  }

  public static localeQaSettingsByLangCode(
    languageCode: string
  ): (state: LocaleQASettingsStateModel) => LocaleQaSettingsModel {
    return createSelector(
      [LocaleQASettingsState.localeQaSettingsMap],
      (localeQASettingsMap: LocaleQASettingsMap) => localeQASettingsMap[languageCode]
    );
  }

  @Selector([LocaleQASettingsState.localeQaSettingsMap, LanguageState.currentLanguage])
  public static currentLocaleQaSettings(
    localeQASettingsMap: LocaleQASettingsMap,
    language: LanguageModel
  ): LocaleQaSettingsModel {
    if (!language) {
      return null;
    }
    return localeQASettingsMap[language.code];
  }

  public static getLocaleQaSettingsById(id: string): (state: LocaleQASettingsStateModel) => LocaleQaSettingsModel {
    return createSelector([LocaleQASettingsState.localeQaSettingsMap], (localeQASettingsMap: LocaleQASettingsMap) =>
      Object.values(localeQASettingsMap).find((item) => item.id === id)
    );
  }

  @Action(LoadLocaleQASettingsList)
  public loadList(ctx: StateContext<LocaleQASettingsState>, action: LoadLocaleQASettingsList): Observable<any> {
    return this.localeQASettings
      .apiLocaleQaSettingsIdGet$Json({
        id: action.qaSettingsId,
      })
      .pipe(
        tap((localeQASettingsList) => {
          const map: LocaleQaSettingsModel = {};
          localeQASettingsList.forEach((item) => (map[item.languageCode] = item));
          ctx.patchState({
            [action.qaSettingsId]: map,
          });
        })
      );
  }

  @Action(AddLocaleQASettings)
  public add(ctx: StateContext<LocaleQASettingsStateModel>, action: AddLocaleQASettings): Observable<any> {
    return this.localeQASettings
      .apiLocaleQaSettingsPost$Json({
        body: action,
      })
      .pipe(
        tap((localeQASettings) => {
          const state = ctx.getState() || {};
          const settingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
          ctx.setState({
            ...state,
            [settingsId]: {
              ...state[settingsId],
              [action.languageCode]: localeQASettings,
            },
          });
        })
      );
  }

  @Action(PatchLocaleQASettings)
  public patch<T>(ctx: StateContext<LocaleQASettingsStateModel>, action: PatchLocaleQASettings<T>): Observable<any> {
    const state = ctx.getState() || {};
    const qaSettingsId = this.store.selectSnapshot(QASettingsState.qaSettingsId);
    const localeQaSettings = Object.values(state[qaSettingsId] || {}).find(({ id }) => id === action.id);

    if (!localeQaSettings) {
      return throwError(() => 'Locale qa settings not found');
    }

    const [nextState, patches] = produceWithPatches(localeQaSettings, (draft) => {
      this.applyPatch(action.pathFn, draft, action.value);
    });

    const request = patches.map((a) => ({
      op: a.op,
      path: '/' + a.path.join('/'),
      value: a.value,
    }));

    if (patches.length === 0) {
      return of(null);
    }

    const prevQaSettingsState = state[qaSettingsId];

    ctx.setState({
      ...state,
      [qaSettingsId]: {
        ...state[qaSettingsId],
        [localeQaSettings.languageCode]: nextState,
      },
    });

    return this.localeQASettings
      .apiLocaleQaSettingsIdPatch$Json({
        id: nextState.id,
        body: request,
      })
      .pipe(
        tap((qaSettingsInfo: QaSettingsInfo) => {
          ctx.dispatch(new SetCurrentQASettings(qaSettingsInfo));
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            ctx.setState({
              ...state,
              [qaSettingsId]: prevQaSettingsState,
            });

            return of(null);
          }
        })
      );
  }

  @Action(CleanStoredProjectInfo)
  public cleanStoredProjectInfo(ctx: StateContext<LocaleQASettingsStateModel>, action: CleanStoredProjectInfo): void {
    const state = ctx.getState();
    delete state[action.project.qaSettingsId];
    ctx.setState(state);
  }

  private applyPatch<T>(
    pathFn: ((obj: LocaleQaSettingsModel) => T) | string,
    settings: LocaleQaSettingsModel,
    pathValue: T
  ): LocaleQaSettingsModel {
    const path =
      typeof pathFn === 'string' ? pathFn : nameof<LocaleQaSettingsModel>(pathFn as (obj: LocaleQaSettingsModel) => T);
    const vector = path.split('.');
    const propName = vector.pop();

    if (propName) {
      settings = vector.reduce((it, prop) => it[prop], settings);
      settings[propName] = pathValue;
    }

    return settings;
  }
}
