import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilesUpload } from '@shared/models';
import { ButtonSize, ButtonTheme } from '@shared/components/button';
import { IconPayload } from '@generated/icons/icons.model';

@Component({
  selector: 'app-ui-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileChooserComponent {
  @Input() icon: IconPayload;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() theme: ButtonTheme = 'primary';
  @Input() size: ButtonSize = 'md';
  @Input() accepted: string[] = [];
  @Input() directory = false;
  @Input() multiple = false;
  @Output()
  selectFiles: EventEmitter<FilesUpload> = new EventEmitter<FilesUpload>();

  constructor() {}

  public select(event: any): void {
    this.selectFiles.emit({
      files: [...event.target.files],
      isFolder: this.directory,
    });
    event.target.value = null;
  }
}
