<div
  *ngIf="!(isEdit$ | async)"
  class="text-editable"
  data-test-id="change-dropdown-title"
  (click)="startEdit()"
  [appTooltipTruncated]="valueControl.value"
  [ngClass]="{
    disabled: valueControl.disabled,
    'with-marker': showMarker
  }"
>
  {{ valueControl.value | trim }}
</div>

<input
  *ngIf="isEdit$ | async"
  #inputControl
  [class.invalid]="ngControl?.invalid"
  [formControl]="valueControl"
  [style.width.px]="textWidth"
  [placeholder]="placeholder"
  [appTooltipShowDelay]="0"
  [appTooltipTrigger]="null"
  (blur)="blur()"
  (keyup.enter)="submit()"
  (keyup.escape)="escape()"
  appTooltip
  appTooltipPlacement="bottom-right"
  maxlength="255"
  data-test-id="change-dropdown-title-input"
/>

<span #hiddenText class="text-editable text-hidden">{{ textHiddenContent || placeholder }}</span>
