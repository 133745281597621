<div class="app-digit-cell-editor">
  <app-number-box
    #numberBox
    appPopoverAnchor
    #popoverAnchor="popoverAnchor"
    [hideLabel]="true"
    [fullWidth]="true"
    [formControl]="control"
    (keydown.enter)="onEnter()"
  >
  </app-number-box>

  <app-popover #validationPopover [anchor]="popoverAnchor" horizontalAlign="after" verticalAlign="center">
    {{ errorMsg }}
  </app-popover>
</div>
