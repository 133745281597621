export enum ReportReturnMode {
  /// <summary>
  /// Indicates that errors should be removed and then added.
  /// </summary>
  Default = 0,

  /// <summary>
  /// Specifies that only new or changed errors should be returned.
  /// </summary>
  OnlyChanged = 1
}
