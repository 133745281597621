import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-organization-invite',
  templateUrl: './organization-invite.component.html',
  styleUrls: ['./organization-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationInviteComponent implements OnInit {
  @Input()
  public organizationId: string;

  public readonly emails = new FormArray([]);
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  public selectedEmailIndex: number;

  @ViewChild('emailInput', { static: true })
  private readonly emailInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly dialogRef: MatDialogRef<OrganizationInviteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organizationId: string }
  ) {}

  ngOnInit(): void {
    this.organizationId = this.data.organizationId;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public invite(): void {
    if (!this.emails.length || this.emails.invalid) {
      return;
    }
    this.dialogRef.close(this.emails.value);
  }

  public addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.emails.push(new FormControl(value, [Validators.email]));
    }

    event.chipInput.clear();
  }

  public removeEmail(index: number): void {
    this.emails.removeAt(index);
    this.resetSelectedEmailIndex();
    setTimeout(() => this.emailInput.nativeElement.focus());
  }

  public resetSelectedEmailIndex(): void {
    this.selectedEmailIndex = null;
  }

  public trySelectPreviousEmail(): void {
    if (this.emailInput.nativeElement.value) {
      return;
    }
    this.selectLastEmailChip();
  }

  private selectLastEmailChip(): void {
    this.selectedEmailIndex = this.emails.length - 1;
  }
}
