import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import {
  DeleteLocalizationPlatformConnection,
  LoadLocalizationPlatformsList,
  LocalizationPlatformFilesIsAttached,
  SetLocalizationPlatformConnection,
  SetLocalizationPlatformProjectFiles,
  SetLocalizationPlatformProjects,
} from '@store/localization-platforms-store/localization-platforms.actions';
import { LocalizationPlatformsProject } from '@shared/models';
import { patch } from '@ngxs/store/operators';
import { deepClone } from '@shared/tools';
import {
  LocalizationPlatformFileModel,
  LocalizationPlatformModel,
  LocalizationPlatformProjectModel,
  LocalizationPlatformsService,
} from '@generated/api';

interface LocalizationPlatformConnectionsMap {
  [platformId: string]: string;
}

interface LocalizationPlatformsStateModel {
  platformsList: LocalizationPlatformModel[];
  connections: LocalizationPlatformConnectionsMap;
  projects: { [connectionId: string]: LocalizationPlatformProjectModel[] };
  files: { [projectId: string]: LocalizationPlatformFileModel[] };
  attaching: boolean;
}

@AsyncStorage
@State<LocalizationPlatformsStateModel>({
  name: 'localizationPlatforms',
  defaults: {
    platformsList: [],
    connections: {},
    projects: {},
    files: {},
    attaching: false,
  },
})
@Injectable()
export class LocalizationPlatformsState {
  constructor(private service: LocalizationPlatformsService) {}

  @Selector()
  public static platformsList(state: LocalizationPlatformsStateModel) {
    return state?.platformsList || [];
  }

  @Selector()
  public static attaching(state: LocalizationPlatformsStateModel) {
    return state?.attaching;
  }

  @Selector()
  public static connections(state: LocalizationPlatformsStateModel) {
    return state?.connections || {};
  }

  public static connectionIdByPlatformId(platformId: string) {
    return createSelector(
      [LocalizationPlatformsState.connections],
      (connections: LocalizationPlatformConnectionsMap) => connections[platformId]
    );
  }

  @Selector()
  public static projects(state: LocalizationPlatformsStateModel) {
    return state?.projects || {};
  }

  @Selector()
  public static files(state: LocalizationPlatformsStateModel) {
    return state?.files || {};
  }

  public static projectsByConnection(connectionId: string) {
    return createSelector(
      [LocalizationPlatformsState.projects],
      (projectsMap: { [connectionId: string]: LocalizationPlatformsProject[] }) => projectsMap[connectionId] || []
    );
  }

  public static filesByProject(projectId: string) {
    return createSelector(
      [LocalizationPlatformsState.files],
      (filesMap: { [connectionId: string]: LocalizationPlatformFileModel[] }) => filesMap[projectId] || []
    );
  }

  @Action(LoadLocalizationPlatformsList)
  public loadLocalizationPlatformsList(ctx: StateContext<LocalizationPlatformsStateModel>) {
    return this.service.apiLocalizationPlatformsGet$Json().pipe(
      tap((list: LocalizationPlatformModel[]) => {
        ctx.patchState({
          platformsList: list,
        });
      })
    );
  }

  @Action(SetLocalizationPlatformConnection)
  public setLocalizationPlatformConnection(
    ctx: StateContext<LocalizationPlatformsStateModel>,
    action: SetLocalizationPlatformConnection
  ) {
    ctx.setState(
      patch({
        connections: patch({
          [action.platformId]: action.connectionId,
        }),
      })
    );
  }

  @Action(DeleteLocalizationPlatformConnection)
  public deleteLocalizationPlatformConnection(
    ctx: StateContext<LocalizationPlatformsStateModel>,
    action: DeleteLocalizationPlatformConnection
  ) {
    const state = ctx.getState();
    const connectionId = state?.connections?.[action.platformId];
    const projects = deepClone(state?.projects || {});
    const files = deepClone(state?.files || {});
    if (connectionId) {
      projects[connectionId]?.forEach((project) => {
        delete files[project.id];
      });
      delete projects[connectionId];
    }
    ctx.setState(
      patch({
        connections: patch({
          [action.platformId]: null,
        }),
        projects,
        files,
      })
    );
  }

  @Action(SetLocalizationPlatformProjects)
  public loadLocalizationPlatformProjects(
    ctx: StateContext<LocalizationPlatformsStateModel>,
    action: SetLocalizationPlatformProjects
  ) {
    const projects = ctx.getState()?.projects;
    ctx.patchState({
      projects: {
        ...projects,
        [action.connectionId]: action.projects,
      },
    });
  }

  @Action(SetLocalizationPlatformProjectFiles)
  public loadLocalizationPlatformProjectFiles(
    ctx: StateContext<LocalizationPlatformsStateModel>,
    action: SetLocalizationPlatformProjectFiles
  ) {
    const files = ctx.getState()?.files;
    ctx.patchState({
      files: {
        ...files,
        [action.projectId]: action.files,
      },
    });
  }

  @Action(LocalizationPlatformFilesIsAttached)
  public localizationPlatformFilesIsAttached(
    ctx: StateContext<LocalizationPlatformsStateModel>,
    action: LocalizationPlatformFilesIsAttached
  ) {
    ctx.patchState({
      attaching: action.payload,
    });
  }
}
