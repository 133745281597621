import { FixableSelectionRange, SelectionRange } from '@shared/models';
import { SegmentElement } from '@generated/api';

export enum TagType {
  Start = 0,
  End = 1,
  Standalone = 2,
}

export enum TagView {
  Short = 1,
  Medium = 2,
  Long = 3,
}

export enum SegmentElementType {
  None = 0,
  Text = 1,
  Tag = 2,
}

export class TagSegmentElement implements SegmentElement {
  public elementType = SegmentElementType.Tag;
  public shortText: string;
  public tagType: TagType;
  public isValueTag: boolean;
  public isFormattingTag: boolean;
  public id: string;
  public rawText: string;
  public isComment: boolean;
  public isPartial: boolean;
  public invalid?: boolean;

  text?: string;
  end?: number;
  length?: number;
  start?: number;
}

export class TextSegmentElement {
  public elementType = SegmentElementType.Text;
  public text?: string;
  public end?: number;
  public length?: number;
  public start?: number;
}

export enum RenderItemType {
  RAW_HTML = 'rawHtml',
  TAG = 'tag',
  HIGHLIGHT = 'highlight',
}

export interface RenderItemAutofix {
  fixSuggestion?: string;
  fixRange?: number[];
  isAutofixable?: boolean;
}

export interface RenderItem {
  type: RenderItemType;
  content: RenderItemContent;
}

export type RenderItemContent = HtmlRenderItemContent | TagRenderItemContent;

interface BaseRenderItemContent extends FixableSelectionRange {
  text: string;
  start: number;
  end: number;
  length: number;
}

export type HtmlRenderItemContent = BaseRenderItemContent;

export interface TagRenderItemContent extends BaseRenderItemContent, RenderItemAutofix {
  shortText: string;
  tagType: TagType;
  withoutPair: boolean;
  fullTagHighlighted: boolean; // used with Long Tags
  selectionRanges: SelectionRange[];
}
