import { ReportType } from './report-type';

export interface ColumnOptions {
  // key of ag grid colid
  [key: string]: {
    field: string;
    valueGetter?: (value: any) => any;
    highlight?: boolean;
  };
}

export type ReportTypeColumnOptions = Record<ReportType, ColumnOptions>;

/**
 * Exported report column configuration
 */
const baseColumnOptions: ColumnOptions = {
  isIgnored: {
    field: 'ErrorStatus',
    valueGetter: (value) => (value ? '0' : '1'),
  },
  protected: {
    field: 'IsProtected',
    valueGetter: (value) => (value ? 'Yes' : 'No'),
  },
  source: {
    field: 'SourceSegment',
    highlight: true,
  },
  target: {
    field: 'TargetSegment',
    highlight: true,
  },
  fileName: {
    field: 'FileName',
  },
  match: {
    field: 'Match',
  },
  position: {
    field: 'Position',
  },
  status: {
    field: 'SegmentStatus',
  },
  sourceLanguage: {
    field: 'SourceLanguage',
  },
  targetLanguage: {
    field: 'TargetLanguage',
  },
  singleTargetLanguage: {
    field: 'TargetLanguage',
  },
  comment: {
    field: 'Comments',
  },
  resource: {
    field: 'ResourceName',
  },
  recordId: {
    field: 'Id',
  },
  issueKind: {
    field: 'ErrorType',
  },
  createdOn: {
    field: 'CreatedDate',
  },
};

const terminologyColumnOptions = {
  sourceTerm: {
    field: 'SourceTerm',
  },
  'additionalData.potentialWordForm': {
    field: 'SuggestedTermForm',
  },
  'additionalData.term.name': {
    field: 'GlossaryName',
  },
};

const historyOfChangesColumnOptions = {
  source: {
    field: 'SourceSegment',
    highlight: false,
  },
  changedRenderItems: {
    field: 'CorrectedTargetSegment',
    highlight: true,
  },
};

export const ReportTypeColumnOptions: ReportTypeColumnOptions = {
  Formal: baseColumnOptions,
  Consistency: baseColumnOptions,
  Terminology: { ...baseColumnOptions, ...terminologyColumnOptions },
  Spelling: baseColumnOptions,
  Custom: baseColumnOptions,
  HistoryOfChanges: { ...baseColumnOptions, ...historyOfChangesColumnOptions },
};
