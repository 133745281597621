/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrganizationRequest } from '../models/create-organization-request';
import { Organization } from '../models/organization';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiOrganizationsGet
   */
  static readonly ApiOrganizationsGetPath = '/api/Organizations';

  /**
   * Gets available organizations to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationsGet$Plain$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.ApiOrganizationsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Gets available organizations to the user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationsGet$Plain(params?: {
    'api-version'?: string;
  }): Observable<Array<Organization>> {

    return this.apiOrganizationsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Gets available organizations to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationsGet$Json$Response(params?: {
    'api-version'?: string;
  }): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.ApiOrganizationsGetPath, 'get');
    if (params) {
      rb.query('api-version', params['api-version'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Gets available organizations to the user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationsGet$Json(params?: {
    'api-version'?: string;
  }): Observable<Array<Organization>> {

    return this.apiOrganizationsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Path part for operation apiOrganizationsPost
   */
  static readonly ApiOrganizationsPostPath = '/api/Organizations';

  /**
   * Creates a new organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationsPost$Plain$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateOrganizationRequest
     */
    body?: CreateOrganizationRequest
  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.ApiOrganizationsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationsPost$Plain(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateOrganizationRequest
     */
    body?: CreateOrganizationRequest
  }): Observable<Organization> {

    return this.apiOrganizationsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationsPost$Json$Response(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateOrganizationRequest
     */
    body?: CreateOrganizationRequest
  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.ApiOrganizationsPostPath, 'post');
    if (params) {
      rb.query('api-version', params['api-version'], {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationsPost$Json(params?: {
    'api-version'?: string;

    /**
     * Verifika.API.Organizations.Models.CreateOrganizationRequest
     */
    body?: CreateOrganizationRequest
  }): Observable<Organization> {

    return this.apiOrganizationsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

}
