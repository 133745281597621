import { ElementRef, Injectable, NgZone, OnDestroy, Optional, ViewContainerRef } from '@angular/core';
import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategy,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { Direction, Directionality } from '@angular/cdk/bidi';
import { ESCAPE } from '@angular/cdk/keycodes';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { PopoverComponent } from './popover.component';
import type { PopoverHorizontalAlign, PopoverOpenOptions, PopoverScrollStrategy, PopoverVerticalAlign } from './types';

/**
 * Configuration provided by the popover for the anchoring service
 * to build the correct overlay config.
 */
interface PopoverConfig {
  horizontalAlign: PopoverHorizontalAlign;
  verticalAlign: PopoverVerticalAlign;
  hasBackdrop: boolean;
  backdropClass: string;
  scrollStrategy: PopoverScrollStrategy;
  forceAlignment: boolean;
  lockAlignment: boolean;
}

@Injectable()
export class PopoverAnchoringService implements OnDestroy {
  /** Emits when the popover is opened. */
  popoverOpened = new Subject<void>();

  /** Emits when the popover is closed. */
  popoverClosed = new Subject<void>();

  /** Reference to the overlay containing the popover component. */
  overlayRef: OverlayRef;

  /** Reference to the target popover. */
  private popover: PopoverComponent;

  /** Reference to the view container for the popover template. */
  private viewContainerRefover: ViewContainerRef;

  /** Reference to the anchor element. */
  private anchorElement: HTMLElement;

  /** Reference to a template portal where the overlay will be attached. */
  private portal: TemplatePortal<any>;

  /** Single subscription to notifications service events. */
  private notificationsSubscription: Subscription;

  /** Single subscription to position changes. */
  private positionChangeSubscription: Subscription;

  /** Whether the popover is presently open. */
  private popoverOpen = false;

  /** Emits when the service is destroyed. */
  private destroyed$ = new Subject<void>();

  constructor(private overlay: Overlay, private ngZone: NgZone, @Optional() private directionality: Directionality) {}

  ngOnDestroy() {
    // Destroy popover before terminating subscriptions so that any resulting
    // detachments update 'closed state'
    this.destroyPopover();

    // Terminate subscriptions
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
    if (this.positionChangeSubscription) {
      this.positionChangeSubscription.unsubscribe();
    }
    this.destroyed$.next();
    this.destroyed$.complete();

    this.popoverOpened.complete();
    this.popoverClosed.complete();
  }

  /** Anchor a popover instance to a view and connection element. */
  anchor(popover: PopoverComponent, viewContainerRef: ViewContainerRef, anchor: ElementRef | HTMLElement): void {
    // If we're just changing the anchor element and the overlayRef already exists,
    // simply update the existing overlayRef's anchor.
    if (this.popover === popover && this.viewContainerRefover === viewContainerRef && this.overlayRef) {
      this.anchorElement = anchor instanceof ElementRef ? anchor.nativeElement : anchor;
      const config = this.overlayRef.getConfig();
      const strategy = config.positionStrategy as FlexibleConnectedPositionStrategy;
      strategy.setOrigin(this.anchorElement);
      this.overlayRef.updatePosition();
      return;
    }

    // Destroy any previous popovers
    this.destroyPopover();

    // Assign local refs
    this.popover = popover;
    this.viewContainerRefover = viewContainerRef;
    this.anchorElement = anchor instanceof ElementRef ? anchor.nativeElement : anchor;
  }

  /** Gets whether the popover is presently open. */
  isPopoverOpen(): boolean {
    return this.popoverOpen;
  }

  /** Toggles the popover between the open and closed states. */
  togglePopover(): void {
    return this.popoverOpen ? this.closePopover() : this.openPopover();
  }

  /** Opens the popover. */
  openPopover(options: PopoverOpenOptions = {}): void {
    if (!this.popoverOpen) {
      this.applyOpenOptions(options);
      this.createOverlay();
      this.subscribeToBackdrop();
      this.subscribeToEscape();
      this.subscribeToDetachments();
      this.saveOpenedState();
    }
  }

  /** Closes the popover. */
  closePopover(value?: any): void {
    if (this.overlayRef) {
      this.saveClosedState(value);
      this.overlayRef.detach();
    }
  }

  /** TODO: implement when the overlay's position can be dynamically changed */
  repositionPopover(): void {
    this.updatePopoverConfig();
  }

  /** TODO: when the overlay's position can be dynamically changed, do not destroy */
  updatePopoverConfig(): void {
    this.destroyPopoverOnceClosed();
  }

  /** Realign the popover to the anchor. */
  realignPopoverToAnchor(): void {
    if (this.overlayRef) {
      const config = this.overlayRef.getConfig();
      const strategy = config.positionStrategy as FlexibleConnectedPositionStrategy;
      strategy.reapplyLastPosition();
    }
  }

  /** Get a reference to the anchor element. */
  getAnchorElement(): HTMLElement {
    return this.anchorElement;
  }

  /** Apply behavior properties on the popover based on the open options. */
  private applyOpenOptions(options: PopoverOpenOptions): void {
    // Only override restoreFocus as `false` if the option is explicitly `false`
    const restoreFocus = options.restoreFocus !== false;
    this.popover.restoreFocusOverride = restoreFocus;

    // Only override autoFocus as `false` if the option is explicitly `false`
    const autoFocus = options.autoFocus !== false;
    this.popover.autoFocusOverride = autoFocus;
  }

  /** Create an overlay to be attached to the portal. */
  private createOverlay(): OverlayRef {
    // Create overlay if it doesn't yet exist
    if (!this.overlayRef) {
      this.portal = new TemplatePortal(this.popover.templateRef, this.viewContainerRefover);

      const popoverConfig = {
        horizontalAlign: this.popover.horizontalAlign,
        verticalAlign: this.popover.verticalAlign,
        hasBackdrop: this.popover.hasBackdrop,
        backdropClass: this.popover.backdropClass,
        scrollStrategy: this.popover.scrollStrategy,
        forceAlignment: this.popover.forceAlignment,
        lockAlignment: this.popover.lockAlignment,
      };

      const overlayConfig = this.getOverlayConfig(popoverConfig, this.anchorElement);

      this.subscribeToPositionChanges(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);

      this.overlayRef = this.overlay.create(overlayConfig);
    }

    // Actually open the popover
    this.overlayRef.attach(this.portal);
    return this.overlayRef;
  }

  /** Removes the popover from the DOM. Does NOT update open state. */
  private destroyPopover(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  /**
   * Destroys the popover immediately if it is closed, or waits until it
   * has been closed to destroy it.
   */
  private destroyPopoverOnceClosed(): void {
    if (this.isPopoverOpen() && this.overlayRef) {
      this.overlayRef
        .detachments()
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(() => this.destroyPopover());
    } else {
      this.destroyPopover();
    }
  }

  /** Close popover when backdrop is clicked. */
  private subscribeToBackdrop(): void {
    this.overlayRef
      .backdropClick()
      .pipe(
        tap(() => this.popover.backdropClicked.emit()),
        filter(() => this.popover.interactiveClose),
        takeUntil(this.popoverClosed),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.closePopover());
  }

  /** Close popover when escape keydown event occurs. */
  private subscribeToEscape(): void {
    this.overlayRef
      .keydownEvents()
      .pipe(
        tap((event) => this.popover.overlayKeydown.emit(event)),
        filter((event) => event.keyCode === ESCAPE),
        filter(() => this.popover.interactiveClose),
        takeUntil(this.popoverClosed),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.closePopover());
  }

  /** Set state back to closed when detached. */
  private subscribeToDetachments(): void {
    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.saveClosedState());
  }

  /** Save the opened state of the popover and emit. */
  private saveOpenedState(): void {
    if (!this.popoverOpen) {
      this.popover.isOpenInternal = this.popoverOpen = true;

      this.popoverOpened.next();
      this.popover.opened.emit();
    }
  }

  /** Save the closed state of the popover and emit. */
  private saveClosedState(value?: any): void {
    if (this.popoverOpen) {
      this.popover.isOpenInternal = this.popoverOpen = false;

      this.popoverClosed.next(value);
      this.popover.closed.emit(value);
    }
  }

  /** Gets the text direction of the containing app. */
  private getDirection(): Direction {
    return this.directionality && this.directionality.value === 'rtl' ? 'rtl' : 'ltr';
  }

  /** Create and return a config for creating the overlay. */
  private getOverlayConfig(config: PopoverConfig, anchor: HTMLElement): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.getPositionStrategy(
        config.horizontalAlign,
        config.verticalAlign,
        config.forceAlignment,
        config.lockAlignment,
        anchor
      ),
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass || 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.getScrollStrategyInstance(config.scrollStrategy),
      direction: this.getDirection(),
    });
  }

  /**
   * Listen to changes in the position of the overlay and set the correct alignment classes,
   * ensuring that the animation origin is correct, even with a fallback position.
   */
  private subscribeToPositionChanges(position: FlexibleConnectedPositionStrategy): void {
    if (this.positionChangeSubscription) {
      this.positionChangeSubscription.unsubscribe();
    }

    this.positionChangeSubscription = position.positionChanges.pipe(takeUntil(this.destroyed$)).subscribe((change) => {
      // Position changes may occur outside the Angular zone
      this.ngZone.run(() => {
        this.popover.setAlignmentClasses(
          this.getHorizontalPopoverAlignment(change.connectionPair.overlayX),
          this.getVerticalPopoverAlignment(change.connectionPair.overlayY)
        );
      });
    });
  }

  /** Map a scroll strategy string type to an instance of a scroll strategy. */
  private getScrollStrategyInstance(strategy: PopoverScrollStrategy): ScrollStrategy {
    switch (strategy) {
      case 'block':
        return this.overlay.scrollStrategies.block();
      case 'reposition':
        return this.overlay.scrollStrategies.reposition();
      case 'close':
        return this.overlay.scrollStrategies.close();
      case 'noop':
      default:
        return this.overlay.scrollStrategies.noop();
    }
  }

  /** Create and return a position strategy based on config provided to the component instance. */
  private getPositionStrategy(
    horizontalTarget: PopoverHorizontalAlign,
    verticalTarget: PopoverVerticalAlign,
    forceAlignment: boolean,
    lockAlignment: boolean,
    anchor: HTMLElement
  ): FlexibleConnectedPositionStrategy {
    // Attach the overlay at the preferred position
    const targetPosition = this.getPosition(horizontalTarget, verticalTarget);
    const positions = [targetPosition];

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(anchor)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withViewportMargin(0)
      .withLockedPosition(lockAlignment);

    // Unless the alignment is forced, add fallbacks based on the preferred positions
    if (!forceAlignment) {
      const fallbacks = this.getFallbacks(horizontalTarget, verticalTarget);
      positions.push(...fallbacks);
    }

    return strategy.withPositions(positions);
  }

  /** Get fallback positions based around target alignments. */
  private getFallbacks(hTarget: PopoverHorizontalAlign, vTarget: PopoverVerticalAlign): ConnectionPositionPair[] {
    // Determine if the target alignments overlap the anchor
    const horizontalOverlapAllowed = hTarget !== 'before' && hTarget !== 'after';
    const verticalOverlapAllowed = vTarget !== 'above' && vTarget !== 'below';

    // If a target alignment doesn't cover the anchor, don't let any of the fallback alignments
    // cover the anchor
    const possibleHorizontalAlignments: PopoverHorizontalAlign[] = horizontalOverlapAllowed
      ? ['before', 'start', 'center', 'end', 'after']
      : ['before', 'after'];
    const possibleVerticalAlignments: PopoverVerticalAlign[] = verticalOverlapAllowed
      ? ['above', 'start', 'center', 'end', 'below']
      : ['above', 'below'];

    // Create fallbacks for each allowed prioritized fallback alignment combo
    const fallbacks: ConnectionPositionPair[] = [];
    this.prioritizeAroundTarget(hTarget, possibleHorizontalAlignments).forEach((h) => {
      this.prioritizeAroundTarget(vTarget, possibleVerticalAlignments).forEach((v) => {
        fallbacks.push(this.getPosition(h, v));
      });
    });

    // Remove the first item since it will be the target alignment and isn't considered a fallback
    return fallbacks.slice(1, fallbacks.length);
  }

  /** Helper function to get a cdk position pair from Popover alignments. */
  private getPosition(h: PopoverHorizontalAlign, v: PopoverVerticalAlign): ConnectionPositionPair {
    const { originX, overlayX } = this.getHorizontalConnectionPosPair(h);
    const { originY, overlayY } = this.getVerticalConnectionPosPair(v);
    return new ConnectionPositionPair({ originX, originY }, { overlayX, overlayY });
  }

  /** Helper function to convert an overlay connection position to equivalent popover alignment. */
  private getHorizontalPopoverAlignment(h: HorizontalConnectionPos): PopoverHorizontalAlign {
    if (h === 'start') {
      return 'after';
    }

    if (h === 'end') {
      return 'before';
    }

    return 'center';
  }

  /** Helper function to convert an overlay connection position to equivalent popover alignment. */
  private getVerticalPopoverAlignment(v: VerticalConnectionPos): PopoverVerticalAlign {
    if (v === 'top') {
      return 'below';
    }

    if (v === 'bottom') {
      return 'above';
    }

    return 'center';
  }

  /** Helper function to convert alignment to origin/overlay position pair. */
  private getHorizontalConnectionPosPair(h: PopoverHorizontalAlign): {
    originX: HorizontalConnectionPos;
    overlayX: HorizontalConnectionPos;
  } {
    switch (h) {
      case 'before':
        return { originX: 'start', overlayX: 'end' };
      case 'start':
        return { originX: 'start', overlayX: 'start' };
      case 'end':
        return { originX: 'end', overlayX: 'end' };
      case 'after':
        return { originX: 'end', overlayX: 'start' };
      default:
        return { originX: 'center', overlayX: 'center' };
    }
  }

  /** Helper function to convert alignment to origin/overlay position pair. */
  private getVerticalConnectionPosPair(v: PopoverVerticalAlign): {
    originY: VerticalConnectionPos;
    overlayY: VerticalConnectionPos;
  } {
    switch (v) {
      case 'above':
        return { originY: 'top', overlayY: 'bottom' };
      case 'start':
        return { originY: 'top', overlayY: 'top' };
      case 'end':
        return { originY: 'bottom', overlayY: 'bottom' };
      case 'below':
        return { originY: 'bottom', overlayY: 'top' };
      default:
        return { originY: 'center', overlayY: 'center' };
    }
  }

  /**
   * Helper function that takes an ordered array options and returns a reordered
   * array around the target item. e.g.:
   *
   * target: 3; options: [1, 2, 3, 4, 5, 6, 7];
   *
   * return: [3, 4, 2, 5, 1, 6, 7]
   */
  private prioritizeAroundTarget<T>(target: T, options: T[]): T[] {
    const targetIndex = options.indexOf(target);

    // Set the first item to be the target
    const reordered = [target];

    // Make left and right stacks where the highest priority item is last
    const left = options.slice(0, targetIndex);
    const right = options.slice(targetIndex + 1, options.length).reverse();

    // Alternate between stacks until one is empty
    while (left.length && right.length) {
      reordered.push(right.pop());
      reordered.push(left.pop());
    }

    // Flush out right side
    while (right.length) {
      reordered.push(right.pop());
    }

    // Flush out left side
    while (left.length) {
      reordered.push(left.pop());
    }

    return reordered;
  }
}
