import { Injectable } from '@angular/core';
import { Segment, SegmentElement } from '@generated/api';
import {
  RenderItem,
  RenderItemAutofix,
  RenderItemContent,
  RenderItemType,
  SegmentElementType,
  SelectionRange,
  TagSegmentElement,
  TextSegmentElement,
  UniversalSelectionRange,
} from '@shared/models';
import {
  rangeComparator,
  getSelectionRangesTotalLength,
  intersectSelectionRanges,
  normalizeSelectionRanges,
} from '@shared/tools';
import { TagsValidationService } from './tags-validation.service';

@Injectable()
export class RenderItemBuilder {
  constructor(private tagsValidationService: TagsValidationService) {}

  // TODO: Заменить целиком на ast с возможностью применять транзакции (частично заимствует функционал segment helper.)
  public build(selectionRange: UniversalSelectionRange[], elements: SegmentElement[]): RenderItem[] {
    selectionRange ||= [];
    let tags = this.findTagElements(elements);
    tags = this.defineValidateTags(tags);
    const texts = this.findTextElements(elements);
    // TODO: Чтобы избежать фильтрации и повторного сливания элементов тега и элементов текста,
    // можно доработать сервис валидации тегов. Сервис валидации элементов принимает коллекцию элементов, валидирует
    // и возвращает валидные элементы без сливания элементов
    const validatedElements = [...texts, ...tags];
    const mappedRenderItems = this.mapToRenderItems(
      // TODO(низкий приоритет): убрать каст типов, разобраться в каком месте опциональные аргументы start/end range из сваггер схемы
      // становятся обязательными.
      validatedElements.sort((a, b) =>
        rangeComparator(a as Required<TagSegmentElement>, b as Required<TagSegmentElement>)
      ),
      selectionRange.sort((a, b) => {
        if (a.range && b.range) {
          return rangeComparator(a.range as Required<SelectionRange>, b.range as Required<SelectionRange>);
        }
        return rangeComparator(a as Required<SelectionRange>, b as Required<SelectionRange>);
      })
    );
    const sortedRenderItems = mappedRenderItems.sort((p, n) => rangeComparator(p.content, n.content));
    return sortedRenderItems;
  }

  /*
   *  Build render items list. Extract items that occur to start-end range.
   *  This method could be helpful for visualize some data that didn't allow
   *  render item structure. Like CodeMirror for example.
   */
  public buildFromRange(
    segment: Segment,
    selectionRange: SelectionRange[],
    start: number,
    end: number,
    includeNeighbors: boolean = false
  ): RenderItem[] {
    const rangeCrossingFilter = ({ content }: RenderItem) =>
      includeNeighbors
        ? (start >= content.start && start <= content.end) || (end >= content.start && end <= content.end)
        : start === content.start && end <= content.end;
    const renderItems = this.build(selectionRange, segment.elements);
    return renderItems.filter(rangeCrossingFilter);
  }
  /**
   * Finds tag elements from segment elements
   */
  private findTagElements(elements: SegmentElement[]): TagSegmentElement[] {
    return elements.filter((e) => e.elementType === SegmentElementType.Tag) as TagSegmentElement[];
  }

  /**
   * Finds text elements from segment elements
   */
  private findTextElements(elements: SegmentElement[]): TextSegmentElement[] {
    return elements.filter((e) => e.elementType === SegmentElementType.Text) as TextSegmentElement[];
  }

  /**
   * Validates tags and sets the validity status
   */
  private defineValidateTags(tags: TagSegmentElement[]): TagSegmentElement[] {
    return this.tagsValidationService.validateTags(tags);
  }

  /**
   * Converts a SegmentElement array to RenderItem array
   */
  private mapToRenderItems(items: SegmentElement[], selectionRanges: UniversalSelectionRange[]): RenderItem[] {
    let elementOffset = 0;

    return items.flatMap((element) => {
      const renderItems: RenderItem[] = [];
      // Получаем пересекающие границы подсветки в рамках конкретного элемента
      const intersectingSelectionRanges = intersectSelectionRanges(selectionRanges, element);
      // Нормализуем диапазон выделений, сбрасываем на ноль
      const elementSelectionRanges = normalizeSelectionRanges(
        intersectingSelectionRanges,
        element.start,
        0,
        element.length
      );

      if (element.elementType === SegmentElementType.Text) {
        // Текстовый сегмент разбиваем на highlight и html, если есть диапазон выделений
        if (elementSelectionRanges.length) {
          renderItems.push(
            ...this.convertToRenderItems(element as TextSegmentElement, elementSelectionRanges, elementOffset)
          );
        } else {
          renderItems.push(this.createHtmlRenderItem(element, element.text));
        }
      } else if (element.elementType === SegmentElementType.Tag) {
        renderItems.push(this.createTagRenderItem(element as TagSegmentElement, elementSelectionRanges));
      } else {
        throw new Error(`Element type ${element.elementType} not implemented`);
      }
      elementOffset += element.text.length;
      return renderItems;
    });
  }

  /**
   * Creates a render item
   */
  private createRenderItem(type: RenderItemType, content: RenderItemContent): RenderItem {
    return { type, content };
  }

  /**
   * Creates a html render item
   */
  private createHtmlRenderItem(element: SegmentElement, text: string, autofix: RenderItemAutofix = {}): RenderItem {
    return this.createRenderItem(RenderItemType.RAW_HTML, { ...element, text, ...autofix } as RenderItemContent);
  }

  /**
   * Creates a highlight render item
   */
  private createHighlightRenderItem(
    element: SegmentElement,
    text: string,
    autofix: RenderItemAutofix = {}
  ): RenderItem {
    return this.createRenderItem(RenderItemType.HIGHLIGHT, {
      ...element,
      fullTagHighlighted: true,
      text,
      ...autofix,
    } as RenderItemContent);
  }

  /**
   * Creates a tag render item
   */
  private createTagRenderItem(element: TagSegmentElement, selectionRanges: SelectionRange[]): RenderItem {
    // Сравниваем длину элемента с длиной диапазона подсветки.
    // Если равно, отмечаем как fullTagHighlighted: true.
    // Используется для полного выделения тега
    const isFullTagHighlight = getSelectionRangesTotalLength(selectionRanges) === element.length;
    const isAutofixable = !!selectionRanges.find((r) => r.isFixAvailable);

    return this.createRenderItem(RenderItemType.TAG, {
      ...element,
      fullTagHighlighted: isFullTagHighlight,
      selectionRanges,
      withoutPair: !!element.invalid,
      isAutofixable,
    } as RenderItemContent);
  }

  /**
   * Converts a tag segment element to html and highlight render items
   */
  private convertToRenderItems(
    element: SegmentElement,
    selectionRanges: SelectionRange[],
    offset: number
  ): RenderItem[] {
    const renderItems: RenderItem[] = [];
    let previousEndPos = 0;
    selectionRanges.forEach((r) => {
      if (r.start > 0) {
        renderItems.push(
          this.createHtmlRenderItem(
            { ...element, start: offset + previousEndPos, end: offset + r.start, length: r.start - previousEndPos },
            element.text.substring(previousEndPos, r.start)
          )
        );
      }
      renderItems.push(
        this.createHighlightRenderItem(
          { ...element, start: offset + r.start, end: offset + r.end, length: r.end - r.start },
          element.text.substring(r.start, r.end)
        )
      );
      previousEndPos = r.end;
    });

    if (element.length > previousEndPos) {
      renderItems.push(
        this.createHtmlRenderItem(
          {
            ...element,
            start: offset + previousEndPos,
            end: offset + element.text.length,
            length: element.text.length - previousEndPos,
          },
          element.text.substring(previousEndPos)
        )
      );
    }

    return renderItems.reduce<RenderItem[]>((items, currentItem) => {
      if (
        !items.find((i) => i.content.start === currentItem.content.start && i.content.end === currentItem.content.end)
      ) {
        items.push(currentItem);
      }
      return items;
    }, []);
  }
}
