import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import CodeMirror from 'codemirror';
import 'codemirror/addon/selection/mark-selection.js';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
  AutofixType,
  FixableSelectionRange,
  RenderItem,
  RenderItemType,
  SelectionRange,
  TagSegmentElement,
  TagView,
} from '@shared/models';
import { addToPosition, checkTagStatus, comparisonOfPosition, getCoords, getMarkerAdditionalInfo } from './utils';
import { TagsValidationService } from '@shared/services/tags-validation.service';
import { ClipboardBetweenEditorsService } from '@shared/components/segment-editor/clipboard-between-editors.service';
import { EditorHelperService } from '@shared/components/segment-editor/editor-helper.service';
import { SegmentHelperService } from '@shared/components/segment-editor/segment-helper.service';
import {
  SegmentAction,
  DeleteSegmentAction,
  TagMarkerAdditionalInfo,
  TagWithStatus,
  PartOfSegment,
  InsertSegmentAction,
} from '@shared/components/segment-editor/models';
import { TagSharingRoomService } from '@shared/components/segment-editor/tag-sharing-room.service';
import { getTagPair } from '@shared/tools/tag-segment-element-extensions';
import { RenderItemBuilder } from '@shared/services';
import { CodemirrorWrapperComponent } from './codemirror-wrapper/codemirror-wrapper.component';
import { MarkerRange, Position } from 'codemirror';
import { Segment } from '@generated/api';
import { isSpaceChar } from '@shared/tools';
import { extendSegmentByAtomics } from '@shared/tools/segment';
import { ShortcutsService } from 'src/app/core/services';
import { EditorKeybindingEvent, KeybindingGroups } from 'src/app/core/services/shortcuts';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';

@Component({
  selector: 'app-segment-editor',
  templateUrl: './segment-editor.component.html',
  styleUrls: ['./segment-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TagsValidationService, EditorHelperService, SegmentHelperService],
})
export class SegmentEditorComponent implements OnInit, OnDestroy {
  private _selections: SelectionRange[] = [];
  private tagWithStatuses: TagWithStatus[] = [];
  private _tagView: TagView = TagView.Medium;
  private destroyed$ = new Subject<void>();
  public activeAutofixIndex: number;

  @Input() sharingRoomKey: string;
  @Input() isTarget = false;
  @Input() rightToLeft: boolean;
  @Input() readonly: boolean = false;
  @Input() showInvisibles = false;
  @Input() fileName: string;
  @Input() selectable = true;
  @Input() multiline = true;
  @Input() enabledContextMenu = true;
  // This field is very similar to readonly, but it is also used for visual indication
  @Input() suppressEditing: boolean = false;

  @Input() set selection(value: SelectionRange[]) {
    this._selections = value || [];
    this.update();
  }

  get selection(): SelectionRange[] {
    return this._selections || [];
  }

  @Input() set segment(segment: Segment) {
    this.segmentHelperService.initSegment(segment);
    this.update();
  }

  @Input()
  set tagView(value: TagView) {
    this._tagView = value;
    if (this.editor) {
      this.editorHelperService.changeTagView(this._tagView);
    }
  }

  @Output()
  public readonly editing = new EventEmitter<boolean>();

  @Output()
  public segmentChange = new EventEmitter<Segment>();

  @Output()
  public outsideClicked = new EventEmitter<void>();

  @Output()
  public keyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  public readonly focusReturned = new EventEmitter<void>();

  @ViewChild(ContextMenuDirective) private contextMenu: ContextMenuDirective;
  @ViewChild(CodemirrorWrapperComponent) private codemirrorWrapperComponent: CodemirrorWrapperComponent;

  public availableFix: { fix: string; fixStart: number; fixEnd: number; rangeStart: number; rangeEnd: number };
  public fixedRenderItems: RenderItem[][];
  public autofixType: AutofixType;
  private autofixMark: CodeMirror.TextMarker;
  public currentAutofixDescription: string;
  public selectionAfterFix: SelectionRange[] = [];

  public editor: CodeMirror.EditorFromTextArea;
  @Output()
  public readonly selectionChanged = new EventEmitter<Segment[]>();

  get autofixDescription(): string {
    if (!this.availableFix || !this.autofixType) {
      return;
    }
    if (this.autofixType === AutofixType.Replace) {
      return this.autofixType;
    }
    const fixableSymbol =
      this.autofixType === AutofixType.Add
        ? this.availableFix.fix
        : this.segmentHelperService.getTextValue().slice(this.availableFix.fixStart, this.availableFix.fixEnd);

    const fixTargetName = isSpaceChar(fixableSymbol) ? 'space' : 'punctuation';
    return `${this.autofixType} ${fixTargetName}`;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public clipboardBetweenEditorsService: ClipboardBetweenEditorsService,
    public editorHelperService: EditorHelperService,
    public tagSharingRoomService: TagSharingRoomService,
    private segmentHelperService: SegmentHelperService,
    private renderItemBuilder: RenderItemBuilder,
    private shortcutsService: ShortcutsService
  ) {}

  ngOnInit() {
    this.watchEditorKeybindings();
    this.tagSharingRoomService.initSharingRoom(this.sharingRoomKey);
    this.watchTagContextMenuClicked();
  }

  private watchEditorKeybindings(): void {
    if (this.readonly) {
      return;
    }
    this.shortcutsService
      .watchGroupWithMeta(KeybindingGroups.EDITOR)
      .pipe(
        debounceTime(50),
        filter(({ id }) => id === this.sharingRoomKey),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ event, originalEvent }) => {
        const editorKeybindingEvents: { [key in EditorKeybindingEvent]?: () => void } = {
          [EditorKeybindingEvent.OPEN_AUTOFIX_MENU]: () => this.openCycleAutofixMenu(),
          [EditorKeybindingEvent.OPEN_SPECIAL_SYMBOLS_MENU]: () => this.showSpecialSymbols(),
          [EditorKeybindingEvent.EXIT]: () =>
            (this.fixedRenderItems ? this.closeContextMenu : this.exitFromEditor).bind(this)(),
          [EditorKeybindingEvent.CONFIRM_EDIT]: () => {
            originalEvent.preventDefault();
            this.confirmAction();
          },
          [EditorKeybindingEvent.APPLY_AUTOFIX]: () => {
            originalEvent.preventDefault();
            this.confirmAction();
          },
          [EditorKeybindingEvent.SELECT_NEXT_AUTOFIX_OPTION]: () => {
            this.cycleAutofixNavigate(true, originalEvent);
          },
          [EditorKeybindingEvent.SELECT_PREVIOUS_AUTOFIX_OPTION]: () => {
            this.cycleAutofixNavigate(false, originalEvent);
          },
        };
        editorKeybindingEvents[event]?.();
      });
  }

  private confirmAction(): void {
    if (this.fixedRenderItems) {
      this.acceptCurrentAutofix();
      return;
    }
    this.maybeSegmentChanged();
  }

  private openCycleAutofixMenu(): void {
    const allMarks = this.codemirrorWrapperComponent.editor.getAllMarks();

    const autofixMarks = allMarks.filter((mark: CodeMirror.TextMarker) => mark.attributes.fix != null);
    if (autofixMarks.length === 0) {
      return;
    }
    if (!this.autofixMark) {
      this.autofixMark = autofixMarks[0];
    } else {
      const currentOpenedAutofixPosition = autofixMarks.findIndex(
        (mark: CodeMirror.TextMarker) => (mark as any).id === (this.autofixMark as any).id
      );
      const nextAutofixPosition = (currentOpenedAutofixPosition + 1) % autofixMarks.length;
      this.autofixMark = autofixMarks[nextAutofixPosition];
    }

    this.handleContextMenu({ markers: [this.autofixMark] });
  }

  private closeContextMenu(): void {
    this.contextMenu.close();
    this.contextMenuClosed();
  }

  public contextMenuClosed(): void {
    this.activeAutofixIndex = null;
    this.fixedRenderItems = null;
  }

  private exitFromEditor(): void {
    this.focusReturned.emit();
  }

  public focus(): void {
    this.editor.focus();
  }

  public moveCursorEndOfLine(): void {
    this.editor.setCursor(this.editor.lineCount(), 0);
  }

  public setCursor(position?: CodeMirror.Position): void {
    if (position) {
      this.editor.setSelection(position);
    } else {
      this.editor.setSelection({ line: 1, ch: 0 });
    }
  }

  public focusFirstHighlightMarker(): void {
    this.editorHelperService.focusFirstHighlightMarker();
  }

  public update(clearHistory: boolean = true): void {
    if (!this.editor) {
      return;
    }
    this.tagWithStatuses = checkTagStatus(this.segmentHelperService.getTagElements(), this.selection);
    const val = this.segmentHelperService.getTextValue();

    this.editor.setValue(val);

    this.editorHelperService.setRtl(this.rightToLeft);
    this.editorHelperService.applyTags(this.tagWithStatuses, this._tagView);
    this.segmentHelperService.setSelections(this.selection);
    this.editorHelperService.applyHighlighting(this.selection);
    if (clearHistory) {
      this.editor.clearHistory();
    }
    this.cdr.detectChanges();
  }

  public editorReady(editor: CodeMirror.EditorFromTextArea): void {
    this.editor = editor;
    this.editorHelperService.initEditor(this.editor);

    this.update();
    if (this.isTarget) {
      this.subscribeClipboard();
    } else {
      this.subscribeAltPressed();
      this.subscribeCopyTagNumber();
    }
    this.subscribeMarkerEvents();
  }

  public subscribeMarkerEvents(): void {
    this.editorHelperService.onMarkerClick(this.markerClickHandler);
    this.editorHelperService.onMarkerContextMenu(this.markerClickHandler);
  }

  private subscribeClipboard(): void {
    this.tagSharingRoomService
      .getTagFromClipboard(this.sharingRoomKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (!data || this.readonly) {
          return;
        }
        const insertSegmentActions = this.editorHelperService.insertCopiedTags(data, this._tagView);
        this.segmentHelperService.dispatch(insertSegmentActions);
      });
  }

  private subscribeAltPressed(): void {
    this.tagSharingRoomService
      .getAltPressed(this.sharingRoomKey)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((altPressed) => {
        this.editorHelperService.showOrHideTagNumber(
          altPressed,
          this.tagSharingRoomService.getSelected(this.sharingRoomKey)
        );
      });
  }

  private subscribeCopyTagNumber(): void {
    this.tagSharingRoomService
      .getCopyTagNumber(this.sharingRoomKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.tagSharingRoomService.copyTag(this.sharingRoomKey, null);
        const tag = this.editorHelperService.getTagByNumber(value);
        if (tag) {
          this.tagSharingRoomService.setTagToClipboard(
            this.sharingRoomKey,
            tag,
            getTagPair(this.segmentHelperService.getTagElements(), tag)
          );
        }
      });
  }

  private maybeSegmentChanged(): void {
    if (this.segmentHelperService.hasChanges()) {
      this.segmentChanged();
    }
  }

  public segmentChanged(): void {
    this.segmentChange.emit(this.segmentHelperService.getSegment());
  }

  public copyHandler(): void {
    this.clipboardBetweenEditorsService.setClipboardData(this.fileName, this.editorHelperService.getPartOfSegment());
  }

  public cutHandler(): void {
    this.clipboardBetweenEditorsService.setClipboardData(this.fileName, this.editorHelperService.getPartOfSegment());
  }

  public beforeSelectionChangeHandler(obj: CodeMirror.EditorSelectionChange): void {
    const somethingSelected = this.editorHelperService.checkSomethingSelectedInSelectionChange(obj);
    this.tagSharingRoomService.setSelected(this.sharingRoomKey, somethingSelected);
    this.editorHelperService.changeTagColorIfSelection(obj.ranges, somethingSelected);

    if (!somethingSelected) {
      this.selectionChanged.emit([]);
      return;
    }
    const selections: Segment[] = obj.ranges
      .map((range) => {
        const startIndex = this.editor.indexFromPos(range.from());
        const endIndex = this.editor.indexFromPos(range.to());
        return new SelectionRange(startIndex, endIndex);
      })
      .map((selectionRange) => this.segmentHelperService.getNewSegmentFromSelection(selectionRange));
    this.selectionChanged.emit(selections);
  }

  public dragStartHandler(e: DragEvent): void {
    let rangesToClean: Position[][] = [];
    let partOfSegment: PartOfSegment;
    const doc = this.editor.getDoc();
    if (doc.somethingSelected()) {
      partOfSegment = this.editorHelperService.getPartOfSegment();
      rangesToClean = this.editor.listSelections().map((range) => [range.from(), range.to()]);
    } else {
      // если пользователь пытается перетащить тегов без предварительного выделения
      const tagPosition = this.editor.coordsChar(getCoords(e));
      if (!tagPosition) {
        return;
      }
      const marker = this.editor.findMarksAt(tagPosition);
      if (!marker || !marker.length) {
        return;
      }
      const markerMetaInfo = getMarkerAdditionalInfo(marker[0]);
      const tag: TagSegmentElement = {
        ...markerMetaInfo.tagWithStatus.tag,
        start: 0,
        end: markerMetaInfo.tagWithStatus.tag.length,
      };
      partOfSegment = {
        text: tag.text,
        tags: [tag],
      };
      const markerRange = marker[0].find() as MarkerRange;
      rangesToClean = [[markerRange.from, markerRange.to]];
    }
    e.dataTransfer.setData('dragDropPartOfSegment', JSON.stringify(partOfSegment));
    e.dataTransfer.setData('dragDropRangesToClean', JSON.stringify(rangesToClean));
  }

  public dropHandler(e: DragEvent): void {
    e.preventDefault();
    const partOfSegmentRow = e.dataTransfer.getData('dragDropPartOfSegment');
    const rangesToCleanRow = e.dataTransfer.getData('dragDropRangesToClean');
    const insertPosition = this.editor.coordsChar(getCoords(e));
    if (partOfSegmentRow && partOfSegmentRow !== 'null') {
      const actions: SegmentAction[] = [];
      const partOfSegment = JSON.parse(partOfSegmentRow) as PartOfSegment;
      const textLength = partOfSegment.text.length;
      const insertSegmentAction = this.editorHelperService.insertPartOfSegment(
        insertPosition,
        partOfSegment,
        this._tagView
      );
      actions.push(insertSegmentAction);
      if (rangesToCleanRow && rangesToCleanRow !== 'null') {
        const rangesToClean = JSON.parse(rangesToCleanRow) as Position[][];
        rangesToClean.forEach((range) => {
          let startPosition = range[0];
          let endPosition = range[1];
          if (comparisonOfPosition(range[0], insertPosition) === 1) {
            startPosition = addToPosition(startPosition, textLength);
            endPosition = addToPosition(endPosition, textLength);
          }
          const deleteAction = this.editorHelperService.deleteRange(startPosition, endPosition);
          actions.push(deleteAction);
        });
      }
      this.segmentHelperService.dispatch(actions);
    }
  }

  /**
   * нативное событие change имеет баг.
   * Если поставить несколько курсоров и вставить текст сначала вставляется
   * текста во всех местах курсора, потом испускается события с неправильными координатами
   * По этой причине ловим события paste на этапе beforeChange, отменяем его и вручную вставляем то что нужно
   */
  public beforeChangeHandler(e: CodeMirror.EditorChangeCancellable): void {
    if (e.origin === 'paste') {
      e.cancel();
      const text: string = e.text.join('\r\n');
      let partOfSegment: PartOfSegment = {
        text,
        tags: [],
      };
      if (
        this.clipboardBetweenEditorsService.hasClipboardData() &&
        this.clipboardBetweenEditorsService.clipboardDataCanBeUsed(this.fileName, text)
      ) {
        partOfSegment = this.clipboardBetweenEditorsService.getClipboardData(this.fileName);
      }
      if (e.from && e.to && comparisonOfPosition(e.from, e.to) !== 0) {
        const start: number = this.editor.indexFromPos(e.from);
        const end: number = this.editor.indexFromPos(e.to);
        this.editorHelperService.deleteRange(e.from, e.to);
        this.segmentHelperService.dispatch([new DeleteSegmentAction(start, end - start)]);
      }
      const insertSegmentAction = this.editorHelperService.insertPartOfSegment(e.from, partOfSegment, this._tagView);
      this.segmentHelperService.dispatch([insertSegmentAction]);
    }
  }

  public changeHandler(editorChange: CodeMirror.EditorChange): void {
    switch (editorChange.origin) {
      case '+delete':
      case 'cut':
      case '+clearOnDrop': {
        const start: number = this.editor.indexFromPos(editorChange.from);
        const removedText: string = editorChange.removed.join('\r\n');
        this.segmentHelperService.dispatch([new DeleteSegmentAction(start, removedText.length)]);
        this.refreshEditorMarkers();
        break;
      }
      case '+input': {
        const start: number = this.editor.indexFromPos(editorChange.from);
        const text: string = editorChange.text.join('\r\n');
        if (editorChange.removed.length) {
          const removedText: string = editorChange.removed.join('\r\n');
          this.segmentHelperService.dispatch([
            new DeleteSegmentAction(start, removedText.length),
            new InsertSegmentAction(
              {
                text,
                tags: [],
              },
              start
            ),
          ]);
          this.refreshEditorMarkers();
        } else {
          this.segmentHelperService.insertText(text, start);
        }
        break;
      }
      case 'undo': {
        this.segmentHelperService.undo();
        break;
      }
      case 'redo': {
        this.segmentHelperService.redo();
        break;
      }
    }
  }

  private refreshEditorMarkers(): void {
    this.editorHelperService.clearHighlight(this.segmentHelperService.selectionRange);
  }

  public blurHandler(): void {
    this.tagSharingRoomService.setAltPressed(this.sharingRoomKey, false);
    this.editing.emit(false);
  }

  public focusHandler(): void {
    this.editing.emit(true);
  }

  public clickedHandler(): void {
    this.contextMenu.close();
  }

  public altKeyDownHandler(): void {
    if (this.isTarget) {
      this.tagSharingRoomService.setAltPressed(this.sharingRoomKey, true);
    }
  }

  public enterKeyPressedHandler(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public altKeyUpHandler(): void {
    if (this.isTarget) {
      this.tagSharingRoomService.setAltPressed(this.sharingRoomKey, false);
    }
  }

  private watchTagContextMenuClicked(): void {
    this.editorHelperService.tagContextMenuClicked$.pipe(takeUntil(this.destroyed$)).subscribe(([e]) => {
      const markers = this.codemirrorWrapperComponent.getMarkersUnderMouse(e);
      this.handleContextMenu({ event: e, markers });
      e.preventDefault();
    });
  }

  public handleContextMenu({ event, markers }: { event?: MouseEvent; markers: CodeMirror.TextMarker[] }): void {
    if (this.readonly || this.suppressEditing) {
      return;
    }
    event?.preventDefault();

    const autofixSuggestionFound = markers?.find((m) => m.attributes?.fix != null);
    if (autofixSuggestionFound) {
      this.showAutofix(autofixSuggestionFound);
      return;
    }
    this.showSpecialSymbols(event);
  }

  private showAutofix(marker: CodeMirror.TextMarker): void {
    const [rangeStart, rangeEnd] = this.getMarkerPosition(marker);
    const [fixStart, fixEnd] = this.findRealAutofixPositionByMarker(marker);
    this.prepareAutofixData(fixStart, fixEnd, rangeStart, rangeEnd, marker.attributes);
    this.contextMenu.open({ coords: this.calculateMarkerCoords(marker) });
    this.cdr.markForCheck();
  }

  public showSpecialSymbols(event?: MouseEvent): void {
    const cursorCoords = this.editor.cursorCoords();
    const coords: [number, number] = event ? undefined : [cursorCoords.left, cursorCoords.top];

    this.contextMenu.open({ event, coords });
    this.cdr.markForCheck();
  }

  private calculateMarkerCoords(marker: CodeMirror.TextMarker): [number, number] {
    const [start] = this.getMarkerPosition(marker);
    const startPos = this.editor.posFromIndex(start);
    const startCoords = this.editor.charCoords(startPos, 'page');
    return [startCoords.left, startCoords.top];
  }

  private getMarkerPosition(
    marker: CodeMirror.TextMarker<CodeMirror.MarkerRange | CodeMirror.Position>
  ): [number, number] {
    const markerPosition = marker.find() as CodeMirror.MarkerRange;
    return [markerPosition.from.ch, markerPosition.to.ch];
  }

  private findRealAutofixPositionByMarker(marker: CodeMirror.TextMarker): [number, number] {
    const { relativeEnd, relativeStart } = marker.attributes;
    const markerPosition = marker.find() as CodeMirror.MarkerRange;
    return [markerPosition.from.ch + +relativeStart, markerPosition.from.ch + +relativeEnd];
  }

  private prepareAutofixData(
    fixStart: number,
    fixEnd: number,
    rangeStart: number,
    rangeEnd: number,
    { fix, autofixType }: FixableSelectionRange
  ): void {
    this.availableFix = { fix, fixStart, fixEnd, rangeStart, rangeEnd };

    this.autofixType = autofixType;

    const segmentAfterFix = this.forecastSegmentAfterFix();

    const lengthOffset = segmentAfterFix.text.length - this.segmentHelperService.getTextValue().length;

    const offsetAfterEditMapper = (e: SelectionRange): SelectionRange => {
      if (e.start >= fixStart) {
        const updatedEl = {
          ...e,
          start: e.start === fixStart ? e.start : e.start + lengthOffset,
          end: e.end + lengthOffset,
          length: e.end - e.start,
        };
        if (e.autofixRange) {
          updatedEl.autofixRange = offsetAfterEditMapper(updatedEl.autofixRange);
        }
        return updatedEl;
      }
      return e;
    };

    const selectionWithOffset = this.segmentHelperService.selectionRange.map(offsetAfterEditMapper);

    const filterFixRange = (s: SelectionRange): boolean => s.end !== rangeEnd && s.start !== rangeStart;

    this.selectionAfterFix = selectionWithOffset.filter(filterFixRange);
    const cuttedSegmentElements = extendSegmentByAtomics(segmentAfterFix);

    this.fixedRenderItems = [
      this.autofixType !== AutofixType.Replace
        ? this.renderItemBuilder.buildFromRange(
            cuttedSegmentElements,
            selectionWithOffset,
            fixStart,
            fixEnd + lengthOffset,
            true
          )
        : [
            {
              type: RenderItemType.RAW_HTML,
              content: {
                text: fix,
                start: 0,
                end: fix.length,
                length: fix.length,
              },
            },
          ],
    ];
  }

  private forecastSegmentAfterFix(): Segment {
    const currentSegment = this.segmentHelperService.getSegment();

    const segmentHelper = new SegmentHelperService();
    segmentHelper.initSegment(currentSegment);
    const { fixStart, fixEnd, fix } = this.availableFix;

    if (this.autofixType === AutofixType.Replace) {
      segmentHelper.dispatch([
        new DeleteSegmentAction(fixStart, fixEnd - fixStart),
        new InsertSegmentAction(
          {
            text: this.availableFix.fix,
            tags: [],
          },
          fixStart
        ),
      ]);
    }

    if (this.autofixType === AutofixType.Remove) {
      segmentHelper.dispatch([new DeleteSegmentAction(fixStart, fixEnd - fixStart)]);
    }

    if (this.autofixType === AutofixType.Add) {
      segmentHelper.dispatch([new InsertSegmentAction({ text: fix }, fixStart)]);
    }

    return segmentHelper.getSegment();
  }

  public acceptCurrentAutofix(): void {
    if (this.autofixType === AutofixType.Replace) {
      this.segmentHelperService.dispatch([
        new DeleteSegmentAction(this.availableFix.fixStart, this.availableFix.fixEnd - this.availableFix.fixStart),
        new InsertSegmentAction(
          {
            text: this.availableFix.fix,
          },
          this.availableFix.fixStart
        ),
      ]);
    }
    if (this.autofixType === AutofixType.Remove) {
      this.segmentHelperService.dispatch([
        new DeleteSegmentAction(this.availableFix.fixStart, this.availableFix.fixEnd - this.availableFix.fixStart),
      ]);
    }
    if (this.autofixType === AutofixType.Add) {
      this.segmentHelperService.dispatch([
        new InsertSegmentAction({ text: this.availableFix.fix }, this.availableFix.fixStart),
      ]);
    }
    this._selections = this.selectionAfterFix;
    this.update(false);

    this.closeContextMenu();

    this.confirmSegmentChangedWhenLastAutofix();
  }

  private cycleAutofixNavigate(directly: boolean, event: KeyboardEvent): void {
    if (!this.fixedRenderItems) {
      return;
    }
    event.preventDefault();
    this.chooseNextAutofixOption(directly);
    this.cdr.detectChanges();
  }

  private chooseNextAutofixOption(directly: boolean): void {
    if (!this.activeAutofixIndex || (directly && this.fixedRenderItems.length - 1 === this.activeAutofixIndex)) {
      this.activeAutofixIndex = 0;
      return;
    }
    if (directly && this.activeAutofixIndex < this.fixedRenderItems.length - 1) {
      ++this.activeAutofixIndex;
      return;
    }
    if (!directly && this.activeAutofixIndex === this.fixedRenderItems.length - 1) {
      this.activeAutofixIndex = this.fixedRenderItems.length - 1;
      return;
    }
    --this.activeAutofixIndex;
  }

  private confirmSegmentChangedWhenLastAutofix(): void {
    const noMoreFixes = !this.editorHelperService.isAutofixable();
    if (noMoreFixes) {
      this.segmentChanged();
    }
  }

  public editorKeydown(event: KeyboardEvent): void {
    this.keyDown.emit(event);
  }

  public altKeyPressedAndNumberKeyPressedHandler(value: number): void {
    if (this.isTarget) {
      this.tagSharingRoomService.copyTag(this.sharingRoomKey, value);
    }
  }

  markerClickHandler = (tagMarkerAdditionalInfo: TagMarkerAdditionalInfo, event: MouseEvent): void => {
    if (this.isTarget) {
      this.editorHelperService.setSelectionOnTag(event);
    } else {
      this.tagSharingRoomService.setTagToClipboard(
        this.sharingRoomKey,
        tagMarkerAdditionalInfo.tagWithStatus.tag,
        getTagPair(this.segmentHelperService.getTagElements(), tagMarkerAdditionalInfo.tagWithStatus.tag)
      );
      event.stopPropagation();
    }
  };

  markerContextmenuHandler = (_tagMarkerAdditionalInfo: TagMarkerAdditionalInfo, event: MouseEvent): void => {
    if (!this.enabledContextMenu) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.editorHelperService.setSelectionOnTag(event);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.editor.display.input.onContextMenu(e); // context menu with items Copy, Cut and Paste
  };

  public refreshEditorSize(): void {
    // если передать null то редактор возьмет новые данные c блока враппера
    this.editor.setSize(null, null);
  }

  public insertSymbol(symbol: string): void {
    this.focus();
    this.editor.replaceSelection(symbol);
    this.contextMenu.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
