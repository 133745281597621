import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { IconModule } from '../icon';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notificaiton.service';
import { ButtonModule } from '@shared/components/button';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, IconModule, ButtonModule],
  declarations: [NotificationComponent],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 },
    },
    NotificationService,
  ],
})
export class NotificationModule {}
