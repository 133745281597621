import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionCompleted } from '@ngxs/store';
import { TryAcceptInvite } from '@store/organizations-store/organizations.actions';
import { Subject, takeUntil } from 'rxjs';
import { SETTINGS_ROUTE } from 'src/app/app.routes';
import { InviteTokenService } from '../../services';

@Component({
  selector: 'app-organization-accept-invite',
  templateUrl: './organization-accept-invite.component.html',
  styleUrls: ['./organization-accept-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationAcceptInviteComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly actions: Actions,
    private readonly inviteTokenService: InviteTokenService
  ) {}

  ngOnInit(): void {
    this.awaitInviteAccept();
    this.acceptInvite();
  }

  private acceptInvite(): void {
    const inviteToken = this.inviteTokenService.initInviteToken();
    if (!inviteToken) {
      this.redirectToHomePage();
      return;
    }
  }

  private awaitInviteAccept(): void {
    this.actions.pipe(ofActionCompleted(TryAcceptInvite), takeUntil(this.destroyed$)).subscribe(() => {
      this.redirectToHomePage();
    });
  }

  private redirectToHomePage(): void {
    this.router.navigateByUrl(SETTINGS_ROUTE.navigateUrl());
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
