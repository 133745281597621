import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@shared/services';
import { TranslationUnitsState } from '@store/translation-units-store/translation-units.state';
import { LoadTranslationUnits } from '@store/translation-units-store/translation-units.action';
import {
  ContextViewerComponent,
  ContextViewerComponentParams,
} from '@shared/components/context-viewer/context-viewer.component';
import { ContextViewerEvent } from '@shared/components/context-viewer/context-viewer-event';
import { Store } from '@ngxs/store';
import { RecheckTranslationUnit } from '@store/quality-issues-store/quality-issues.actions';
import { ProjectFilesState } from '@store/project-files-store/project-files.state';
import { ContextViewerPayload } from '@shared/models';
import { ReportState } from '@store/report-store/report.state';
import { QASettingsState } from '@store/qa-settings-store';
import { UpdateTranslationUnits } from '@store/search-in-report-files/search-in-report-files.actions';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonReportViewState } from '@store/common-report-view-store';

@Injectable()
export class ContextViewerService {
  constructor(public dialog: MatDialog, private appService: AppService, private store: Store) {}

  public readonly contextViewerAvailable$ = new BehaviorSubject<boolean>(false);

  private readonly callContextViewer$ = new Subject<ContextViewerPayload | void>();

  public readonly contextViewerCalled$ = this.callContextViewer$.pipe(
    debounceTime(50),
    withLatestFrom(this.contextViewerAvailable$),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter(([_, allowed]) => allowed),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([val, _]) => val)
  );

  public setAvailableStatus(status: boolean): void {
    this.contextViewerAvailable$.next(status);
  }

  public open(payload?: ContextViewerPayload): void {
    this.callContextViewer$.next(payload);
  }

  public show(payload: ContextViewerPayload): void {
    const file = this.store.selectSnapshot(ProjectFilesState.fileById(payload.fileId));
    const translationUnitId = payload.translationUnitId;
    const reportId = this.store.selectSnapshot(ReportState.report).id;
    this.store.dispatch(new LoadTranslationUnits(file.id));
    const settings = this.store.selectSnapshot(QASettingsState.qaSettings);
    const showInvisibles = this.store.selectSnapshot(CommonReportViewState.showInvisibles);
    const tagView = this.store.selectSnapshot(CommonReportViewState.tagView);

    const data: ContextViewerComponentParams = {
      editable: !(payload.editable === false),
      hideProtected: payload.hideProtected,
      translationUnits$: this.store.select(TranslationUnitsState.translationUnitsByFileId(file.id)),
      loading$: this.store.select(TranslationUnitsState.loader),
      loadingError$: this.store.select(TranslationUnitsState.loadingError),
      offline$: this.appService.online$.pipe(map((online) => !online)),
      focused: translationUnitId,
      fileName: file.name,
      sourceLanguage: file.languagePair.source,
      targetLanguage: file.languagePair.target,
      suppressManualEditing: settings.checkSettings?.protection?.suppressManualEditing,
      showInvisibles,
      tagView,
      onTargetSegmentUpdated: (event: ContextViewerEvent) => {
        const action =
          payload.issueType != null
            ? new RecheckTranslationUnit(reportId, payload.issueType, event.translationUnitId, event.newTarget)
            : new UpdateTranslationUnits([
                {
                  id: event.translationUnitId,
                  target: event.newTarget,
                },
              ]);
        this.store.dispatch(action);
      },
    };

    this.dialog.open(ContextViewerComponent, { data });
  }
}
