import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TryAcceptInvite } from '@store/organizations-store/organizations.actions';

@Injectable()
export class InviteTokenService {
  constructor(private readonly store: Store, private readonly router: Router) {}

  public initInviteToken(): string {
    const params = new Proxy<any>(new URLSearchParams(window.location.search), {
      get: (queryParams, prop) => queryParams.get(prop as string),
    });

    const inviteToken = params.token as string;

    if (inviteToken) {
      this.store.dispatch(new TryAcceptInvite(inviteToken));
      this.router.navigateByUrl('/');
    }
    return inviteToken;
  }
}
