<div class="comment-thread" [ngClass]="{ resolved: isResolved }">
  <div *ngIf="initialThreadStatus" class="thread-main-point">
    <ng-container
      [ngTemplateOutlet]="threadMainPointTemplate"
      [ngTemplateOutletContext]="{ short: true }"
    ></ng-container>
  </div>
  <div class="body" (click)="onThreadClicked()">
    <app-comment
      *ngFor="let comment of visibleComments; let i = index"
      [createdOn]="comment.createdOn"
      [modifiedOn]="comment.modifiedOn"
      [owner]="comment.createdBy?.userName"
      [message]="comment.message"
      [editable]="!isResolved"
      [shortView]="!expanded || initialThreadStatus"
      [enableActions]="!isResolved || i === 0"
      [autoHeight]="expanded"
      (deleted)="deleteComment(comment, i)"
      (edited)="editComment(comment, $event)"
    >
      <div meta *ngIf="i === 0">
        <ng-container
          [ngTemplateOutlet]="threadMainPointTemplate"
          [ngTemplateOutletContext]="{ short: !expanded }"
        ></ng-container>
      </div>

      <div actions *ngIf="i === 0 && isResolved" (click)="openThread()" class="list-item" role="button">
        <app-ui-icon class="list-item__icon" icon="undo" size="small"> </app-ui-icon>
        <span>Re-open</span>
      </div>

      <ng-content *ngIf="i === 0" meta></ng-content>

      <app-button
        header-action
        class="resolve-btn"
        *ngIf="i === 0 && !isResolved"
        (click)="resolveThread()"
        theme="outlined"
        size="sm"
        >Resolve</app-button
      >
    </app-comment>
  </div>

  <div *ngIf="expanded" class="footer">
    <textarea
      #commentInput
      cdkTextareaAutosize
      class="reply-editor"
      (keydown.enter)="$event.preventDefault(); sendComment()"
      (keydown.escape)="$event.preventDefault(); unfocusComment()"
      (keydown)="$event.stopPropagation()"
      [(ngModel)]="editableComment"
      [placeholder]="inputPlaceholder"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
    ></textarea>
    <app-action-icon
      class="reply-editor-btn"
      (clickButton)="sendComment()"
      icon="send"
      theme="subtle"
    ></app-action-icon>
  </div>
</div>

<ng-template #threadMainPointTemplate let-short="short">
  <div class="description">{{ description }}</div>
  <div
    *ngFor="let quote of quotes | slice: 0:(short ? 3 : quotes.length)"
    [class.ellipsis]="quotes.length > 3 && short"
    class="segment-quote"
  >
    <app-segment-renderer [renderItems]="quote"></app-segment-renderer>
  </div>
</ng-template>
