import { StateRepository } from '@angular-ru/ngxs/decorators';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SpecSymbol } from '@shared/components/special-symbols/special-symbols';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { AddFrequentSymbol } from './special-symbols.actions';

type FrequentUsedSymbolStatistic = {
  symbol: SpecSymbol;
  countOfUsage: number;
};

export interface SpecialSymbolsStateModel {
  frequentSymbols: FrequentUsedSymbolStatistic[];
}

@AsyncStorage
@StateRepository()
@State<SpecialSymbolsStateModel>({
  name: 'specialSymbols',
  defaults: {
    frequentSymbols: [],
  },
})
@Injectable()
export class SpecialSymbolsState {
  private readonly frequentSymbolsLimit = 20;

  constructor() {}

  @Selector()
  public static frequentSymbols(state: SpecialSymbolsStateModel): FrequentUsedSymbolStatistic[] {
    return state.frequentSymbols;
  }

  @Action(AddFrequentSymbol)
  public addFrequentSymbol(ctx: StateContext<SpecialSymbolsStateModel>, action: AddFrequentSymbol): void {
    const frequentSymbols = [...ctx.getState().frequentSymbols];
    const currentSymbolIndex = frequentSymbols.findIndex((s) => s.symbol.symbol === action.symbol.symbol);
    const foundSymbol =
      currentSymbolIndex === -1
        ? {
            symbol: action.symbol,
            countOfUsage: 1,
          }
        : {
            ...frequentSymbols[currentSymbolIndex],
            countOfUsage: frequentSymbols[currentSymbolIndex].countOfUsage + 1,
          };

    if (currentSymbolIndex === -1) {
      frequentSymbols.unshift(foundSymbol);
    } else {
      frequentSymbols[currentSymbolIndex] = foundSymbol;
    }
    ctx.patchState({
      frequentSymbols: frequentSymbols
        .sort((a, b) => b.countOfUsage - a.countOfUsage)
        .slice(0, this.frequentSymbolsLimit),
    });
  }
}
