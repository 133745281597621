import { Injectable } from '@angular/core';
import {
  BalanceModel,
  CheckoutService,
  ProductModel,
  SubscriptionDetails,
  SubscriptionsService,
} from '@generated/payment-api';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { BalanceChangedEvent, SubscriptionChangedEvent } from '@shared/models';
import { CommonHubService } from '@shared/services';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { finalize, Observable, of, tap } from 'rxjs';
import { AuthState } from '..';
import {
  LoadBalance,
  LoadSubscriptionDetails,
  LoadSubscriptionProducts,
  SetSubscriptionPlan,
  UpdatePaymentPlan,
} from './payment.actions';

interface PaymentStateModel {
  products: ProductModel[];
  subscriptionDetails: SubscriptionDetails;
  balance: BalanceModel;
  balanceLoaded: boolean;
  subscriptionDetailsLoaded: boolean;
}

@AsyncStorage
@State<PaymentStateModel>({
  name: 'paymentState',
  defaults: {
    products: [],
    subscriptionDetails: null,
    balance: null,
    balanceLoaded: false,
    subscriptionDetailsLoaded: false,
  },
})
@Injectable()
export class PaymentState {
  constructor(
    private readonly subscriptionService: SubscriptionsService,
    private readonly store: Store,
    private readonly checkoutService: CheckoutService,
    private readonly commonHub: CommonHubService
  ) {
    this.watchCommonHubEvents();
  }

  private watchCommonHubEvents(): void {
    this.commonHub.watch(SubscriptionChangedEvent).subscribe(() => {
      this.store.dispatch(new LoadSubscriptionDetails());
    });

    this.commonHub.watch(BalanceChangedEvent).subscribe(() => {
      this.store.dispatch(new LoadBalance());
    });
  }

  @Selector()
  public static products(state: PaymentStateModel): ProductModel[] {
    return state.products;
  }

  @Selector()
  public static subscriptionDetails(state: PaymentStateModel): SubscriptionDetails {
    return state.subscriptionDetails;
  }

  @Selector()
  public static balance(state: PaymentStateModel): BalanceModel {
    return state.balance;
  }

  @Selector()
  public static balanceLoaded(state: PaymentStateModel): boolean {
    return state.balanceLoaded;
  }

  @Selector()
  public static subscriptionDetailsLoaded(state: PaymentStateModel): boolean {
    return state.subscriptionDetailsLoaded;
  }

  @Action(LoadSubscriptionProducts)
  public loadSubscriptionProducts(ctx: StateContext<PaymentStateModel>): Observable<any> {
    return this.subscriptionService
      .apiSubscriptionProductsGet$Json()
      .pipe(tap((products) => ctx.setState(patch({ products }))));
  }

  @Action(SetSubscriptionPlan)
  public setSubscriptionPlan(ctx: StateContext<PaymentStateModel>, { plan, quantity }: SetSubscriptionPlan): void {
    this.checkoutService.checkoutUrlPost$Json({ body: { planId: plan.id, quantity } }).subscribe((rspns) => {
      window.location.href = rspns.checkoutUrl;
    });
  }

  @Action(LoadSubscriptionDetails)
  public loadSubscriptionDetails(ctx: StateContext<PaymentStateModel>): Observable<any> {
    return this.subscriptionService.apiSubscriptionDetailsGet$Json().pipe(
      tap((details) => {
        ctx.setState(patch({ subscriptionDetails: details }));
      }),
      finalize(() => ctx.setState(patch({ subscriptionDetailsLoaded: true })))
    );
  }

  @Action(LoadBalance)
  public loadBalance(ctx: StateContext<PaymentStateModel>): Observable<any> {
    const isRegisteredUser = this.store.selectSnapshot(AuthState.hasRole('registered'));

    if (!isRegisteredUser) {
      return of(null);
    }

    return this.subscriptionService.apiSubscriptionBalanceGet$Json().pipe(
      tap((balance) => {
        ctx.setState(patch({ balance }));
      }),
      finalize(() => ctx.setState(patch({ balanceLoaded: true })))
    );
  }

  @Action(UpdatePaymentPlan)
  public updatePaymentPlan(ctx: StateContext<PaymentStateModel>, { quantity }: UpdatePaymentPlan): Observable<any> {
    return this.subscriptionService.apiSubscriptionChangeQuantityPost({
      body: {
        quantity,
      },
    });
  }
}
