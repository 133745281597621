import { FileUploadState } from '@shared/models';

enum ActionTypes {
  SET_UPLOADING_FILES = '[UploadingFiles] Set Uploading Files',
  ADD_OR_UPDATE_UPLOADING_FILE = '[UploadingFiles] Add or Update Uploading File',
  REMOVE_UPLOADING_FILE = '[UploadingFiles] Remove Uploading Files',
  CANCEL_UPLOADING_ALL_FILES = '[UploadingFiles] Cansel Uploading All Files',
}

export class SetUploadingFiles {
  static readonly type = ActionTypes.SET_UPLOADING_FILES;
  constructor(public files: FileUploadState[]) {}
}

export class AddOrUpdateUploadingFile {
  static readonly type = ActionTypes.ADD_OR_UPDATE_UPLOADING_FILE;
  constructor(public file: FileUploadState) {}
}

export class RemoveUploadingFile {
  static readonly type = ActionTypes.REMOVE_UPLOADING_FILE;
  constructor(public fileId: string) {}
}

export class CancelUploadingAllFiles {
  static readonly type = ActionTypes.CANCEL_UPLOADING_ALL_FILES;
}
