<ng-container *ngIf="notFound; else foundTemplate">
  <div class="not-found-text" *ngIf="notFoundMessage; else notFoundContainerTemplate">{{ notFoundMessage }}</div>
  <ng-template #notFoundContainerTemplate>
    <ng-container *ngTemplateOutlet="notFoundTemplate"></ng-container>
  </ng-template>
</ng-container>

<ng-template #foundTemplate>
  <ng-container *ngIf="filtersHideRows; else noErrorsTemplate">
    <app-empty-message
      [prefixIcon]="prefixIcon"
      imageWidth="160px"
      imageHeight="160px"
      message="No matches found"
      imageUrl="assets/images/empty-match.svg"
    ></app-empty-message>
  </ng-container>
</ng-template>

<ng-template #noErrorsTemplate>
  <ng-container *ngIf="noRowsTemplate; else nativeNoRowsTemplate">
    <ng-container *ngTemplateOutlet="noRowsTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #nativeNoRowsTemplate>
  <app-empty-message
    [prefixIcon]="prefixIcon"
    [message]="isNoRowsFunction ? $any(noRowsMessage)() : noRowsMessage"
    [imageUrl]="imageUrl"
  >
  </app-empty-message>
</ng-template>
