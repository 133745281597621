import { ColumnState as GridColumnState } from '@ag-grid-community/core/dist/cjs/es6/columns/columnModel';
import { ColumnState } from '@store/report-grids-store/report-grids-state.model';

/**
 * Converts ag grid column state model to column state
 */
export function mapToColumnState(columnState: GridColumnState): ColumnState {
  return {
    colId: columnState.colId,
    hide: columnState.hide,
    sort: columnState.sort,
    sortIndex: columnState.sortIndex,
  };
}
