import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { SearchableGridContext } from '@shared/models';
import { ContextViewerService } from '@shared/services/context-viewer.service';
import { NO_VALUE_VIEW } from '@shared/tools';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ICellRendererAngularComp, ICellRendererParams } from 'src/types/ag-grid';

@Component({
  selector: 'app-context-viewer-renderer',
  templateUrl: './context-viewer-renderer.component.html',
  styleUrls: ['./context-viewer-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO VW-1262: перенести компонент в обобщение, используется для файлов и репортов
export class ContextViewerRendererComponent implements ICellRendererAngularComp, OnDestroy {
  // TODO VW-1263: Сделать такой энтрипоинт для всех renderer компонентов AgGrid,
  // сейчас везде прописывается просто строка которая чувствительна к опечаткам.
  static rendererName = 'contextViewerRenderer';
  private destroyed$: Subject<void> = new Subject<void>();

  public isLongFileName: boolean;
  public markedText: string = '';
  public params: ICellRendererParams<any, SearchableGridContext>;

  public value: string;

  constructor(
    private contextViewerService: ContextViewerService,
    private cdr: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {}

  private checkIsLongFileName(): void {
    this.isLongFileName = this.params.eParentOfValue.offsetWidth <= this.hostElement.nativeElement.offsetWidth;
  }

  public agInit(params: ICellRendererParams<any, SearchableGridContext>): void {
    this.params = params;
    this.watchSearch(params.context?.search$);
    this.value = params.value ?? NO_VALUE_VIEW;
    setTimeout(() => this.checkIsLongFileName());
  }

  public refresh(): boolean {
    return false;
  }

  public openContextViewer(): void {
    this.contextViewerService.open({
      fileId: this.params?.data?.translationUnit?.projectFile?.id,
      translationUnitId: this.params?.data?.translationUnit?.id,
    });
  }

  private watchSearch(search$?: Subject<string> | Observable<string>): void {
    search$?.pipe(takeUntil(this.destroyed$)).subscribe((searchText) => {
      this.markedText = searchText || '';
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private clearSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
