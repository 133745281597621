import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';

import { concat, interval, merge, Observable, Subject } from 'rxjs';
import { first, takeUntil, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationUpdateService {
  private stopWatch$: Subject<void> = new Subject<void>();
  private readonly checkUpdateInterval = 30 * 1000; // 30 sec
  private readonly unrecoverableChanges$: Subject<UnrecoverableStateEvent> = new Subject<UnrecoverableStateEvent>();

  public updateAvailable = false;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {}

  /**
   * Periodically watch for new updates
   * after deploy new bundles. Could be stopped
   * by {@link stopWatchingOnApplicationUpdates}
   */
  public watchApplicationUpdates(): void {
    if (!environment.productionMode) {
      return;
    }
    this.stopWatchingOnApplicationUpdates();

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const checkInterval$ = interval(this.checkUpdateInterval);
    const schedule$ = concat(appIsStable$, checkInterval$);

    schedule$.pipe(takeUntil(this.stopWatch$)).subscribe(() => {
      this.updates.checkForUpdate();
    });
  }

  /**
   * Watch for new updates loaded
   */
  public watchAvaiableUpdates(): Observable<VersionEvent> {
    return this.updates.versionUpdates.pipe(
      filter((event) => event.type === 'VERSION_READY'),
      tap(() => {
        this.updateAvailable = true;
      })
    );
  }

  /**
   * Register error about incompatible changes
   */
  public registerUnrecoverableChanges(reason: string): void {
    this.unrecoverableChanges$.next({ type: 'UNRECOVERABLE_STATE', reason });
  }

  /**
   * Watch for broken build!
   */
  public watchUnrecoverableUpdates(): Observable<UnrecoverableStateEvent> {
    return merge(this.updates.unrecoverable, this.unrecoverableChanges$);
  }

  /**
   * Apply last update if exist.
   */
  public applyUpdate(): Promise<void> {
    if (!this.updateAvailable) {
      return;
    }
    return this.updates.activateUpdate().then(() => {
      this.updateAvailable = false;
    });
  }

  public forceApplyUpdate(): Promise<void> {
    return this.updates.activateUpdate().then(() => {
      this.updateAvailable = false;
    });
  }

  /**
   * Unsubscribing from new bundle updates.
   */
  public stopWatchingOnApplicationUpdates(): void {
    this.stopWatch$.next();
  }
}
