import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsoleModule } from './appenders/console/console.module';
import { LogLevel } from './log-level.enum';
import { LoggerService } from './logger.service';
import { SentryModule } from './appenders/sentry/sentry.module';
import { environment } from 'src/environments/environment';

const appendersModules = [
  ConsoleModule.forRoot(() => ({
    level: environment.productionMode ? LogLevel.WARN : LogLevel.DEBUG,
  })),
  SentryModule.forRoot(() => ({
    level: LogLevel.WARN,
    disabled: !environment.enableSentry,
  })),
];

@NgModule({
  imports: [CommonModule, ...appendersModules],
  providers: [LoggerService],
})
export class LoggerModule {}
