import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionIconModule } from '@shared/components/action-icon';

@NgModule({
  imports: [CommonModule, MatDialogModule, ActionIconModule],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalModule {}
