import { TranslationUnitModel } from '@generated/api';
import { FileId } from '@shared/models';

export enum ActionTypes {
  LOAD_TRANSLATION_UNITS = '[TranslationUnits] Load TranslationUnits',
  SET_TRANSLATION_UNITS = '[TranslationUnits] Set TranslationUnits',
  SET_LOAD_ERROR_TRANSLATION_UNITS = '[TranslationUnits] Set Load Error TranslationUnits',
  LOADER_TRANSLATION_UNITS = '[TranslationUnits] Loader TranslationUnits',
}

export class LoadTranslationUnits {
  static readonly type = ActionTypes.LOAD_TRANSLATION_UNITS;
  constructor(public fileId: FileId) {}
}

export class SetTranslationUnits {
  static readonly type = ActionTypes.SET_TRANSLATION_UNITS;
  constructor(public fileId: FileId, public translationUnits: TranslationUnitModel[]) {}
}

export class SetLoadErrorTranslationUnits {
  static readonly type = ActionTypes.SET_LOAD_ERROR_TRANSLATION_UNITS;
  constructor(public error: boolean) {}
}

export class LoaderTranslationUnits {
  static readonly type = ActionTypes.LOADER_TRANSLATION_UNITS;
  constructor(public loader: boolean) {}
}
